import React from 'react'
import Publication_Page_header from './Publication_Page_header';
import Publication_Page_Homecontainer from './Publication_Page_Homecontainer';
import Publication_Page_Book_page from './Publication_Page_Book_page';
import Publication_Page_NewArrivals_page from './Publication_Page_NewArrivals_page';
import Publication_Page_footer from './Publication_Page_footer';
import "./styles/Publication_Page_structure.css";
import "./styles/Publication_Page_header.css";
import "./styles/Publication_Page_Homecontainer.css";
import "./styles/Publication_Page_NewArrivals_page.css";
import "./styles/Publication_Page_about_page.css";
import "./styles/Publication_Page_footer.css";
import "./styles/Publication_Page_Book_page.css";
import Publication_Page_about_page from './Publication_Page_about_page';
import Footer from '../../components/UG/Footer2';
const Publication_Page_structure = () => {
  return (
    <>
      <Publication_Page_header />
      <Publication_Page_Homecontainer />
      <Publication_Page_Book_page />
      <Publication_Page_NewArrivals_page />
      <Publication_Page_about_page />
      {/* <Publication_Page_footer /> */}
      <Footer />
    </>
  );
}

export default Publication_Page_structure
