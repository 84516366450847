import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { IoMdHome } from "react-icons/io";
import Footer from "../../../../UG/Footer2";
import { Link } from 'react-router-dom';
import onlinets1 from '../../../../UG/infographic/image-removebg-preview (10).png';
import onlinets from '../../../../UG/infographic/onlinets.png';
import { Nav } from '../../../../UG/Nav';
import './Pg_Mock_Tests_popup.css';
const PG_iit_jam_Mock_Tests_static_page = () => {

  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const availableRoutes = [
'/StudentFormandMail_IITJAM_Biotechnology/1',
'/StudentFormandMail_IITJAM_Biotechnology/2',
'/StudentFormandMail_IITJAM_Biotechnology/3',
'/StudentFormandMail_IITJAM_Chemistry/1',
'/StudentFormandMail_IITJAM_Chemistry/2',
'/StudentFormandMail_IITJAM_Chemistry/3',
'/StudentFormandMail_IITJAM_Economics/1',
'/StudentFormandMail_IITJAM_Economics/2',
'/StudentFormandMail_IITJAM_Economics/3',
'/StudentFormandMail_IITJAM_geology/1',
'/StudentFormandMail_IITJAM_geology/2',
'/StudentFormandMail_IITJAM_geology/3',
"/StudentFormandMail_IITJAM_MATHS/1",
"/StudentFormandMail_IITJAM_MATHS/2",
"/StudentFormandMail_IITJAM_MATHS/3",
"/StudentFormandMail_IITJAM_MATHS_STATISTICS/1",
"/StudentFormandMail_IITJAM_MATHS_STATISTICS/2",
"/StudentFormandMail_IITJAM_MATHS_STATISTICS/3",
"/StudentFormandMail_IITJAM_PHYSICS/1",
"/StudentFormandMail_IITJAM_PHYSICS/2",
"/StudentFormandMail_IITJAM_PHYSICS/3",




  ];




  const isRouteAvailable = (path) => {
    return availableRoutes.includes(path);
  };
  
  const handleClick = (path, event) => {
    event.preventDefault(); // Prevent the default link behavior
  
    if (isRouteAvailable(path)) {
      navigate(path); // Navigate to the route if available
    } else {
      setShowPopup(true); // Show the custom popup if route is not available
    }
  };
  
  const handleClosePopup = () => {
    setShowPopup(false); // Hide the popup
  };
  
  return (
    <div>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <span className="close-popup" onClick={handleClosePopup}>&times;</span>
            <p>This mock test will be available soon.</p>
          </div>
        </div>
      )}
     
      <div>
        <div className="ugexam_header">
          {Nav.map((NavData, index) => {
            return (
              <div className="header ug_exam_header" key={index}>
                <div className={NavData.logo_img_container}>
                  <Link to={"/"}>
 
                    <img src={NavData.logo} alt="" />
                  </Link>
                </div>
 
 
                <div className="exam_login_menu  ">
                  <li>
                    <Link to='/PgHome' className={NavData.navlist} id='exam_login_menu_home'>
                      <IoMdHome /> {NavData.link1}
                    </Link>
                  </li>
                  {/* <a
                    href="https://online-ug.egradtutor.in"
                    target='_blank'
                    className={NavData.login}
                  >
                    {NavData.btn1}
                  </a> */}
 
                </div>
              </div>
 
            );
          })}
 
 
        </div>
      </div>
 
      <div>
        {/* IIT-JAM MOCK TESTS CONTAINER */}
        <div className='Mock_Tests_Heading'>
 
        <h2>IIT JAM MOCK TEST</h2>
     
       
        </div>
 
 
<div className="jeesection " id="PracticeMockTest">
        <div className="MockTests_sub_heading">
        <h3>Biotechnology (BT)</h3>
       
 
        </div>
        <div className="PracticeMockTest">
          <div className="PracticeMockTest_Card PMT_1">
            <span className="mockImg">
              <img src={onlinets1} alt="" />
            </span>
            <h2>BT MOCK TEST-1</h2>
 
            <div>
              <p>60 Questions 3 Hour 100 Marks</p>
            </div>
            <span className="PracticeMockTest_Container">
              <a href="/StudentFormandMail_IITJAM_Biotechnology/1" onClick={(e) => handleClick("/StudentFormandMail_IITJAM_Biotechnology/1", e)}>Take Test</a>
            </span>
          </div>
 
          <div className="PracticeMockTest_Card PMT_2">
            <span className="mockImg">
              <img src={onlinets1} alt="" />
            </span>
            <h2>BT MOCK TEST-2</h2>
 
            <div>
              <p>60 Questions 3 Hour 100 Marks</p>
            </div>
            <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_IITJAM_Biotechnology/2" onClick={(e) => handleClick("/StudentFormandMail_IITJAM_Biotechnology/2", e)}>Take Test</a>

            </span>
          </div>
 
          <div className="PracticeMockTest_Card PMT_3">
            <span className="mockImg">
              <img src={onlinets1} alt="" />
            </span>
            <h2>BT MOCK TEST-3</h2>
 
            <div>
              <p>60 Questions 3 Hour 100 Marks</p>
            </div>
            <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_IITJAM_Biotechnology/3" onClick={(e) => handleClick("/StudentFormandMail_IITJAM_Biotechnology/3", e)}>Take Test</a>

            </span>
          </div>
        </div>
     
      </div>
 
 
 
 
      {/*Chemistry (CY) SUBJECT MOCK TESTS */}
 
      <div className="jeesection " id="PracticeMockTest">
        <div className="MockTests_sub_heading">
        <h3>Chemistry (CY)</h3>
       
 
        </div>
        <div className="PracticeMockTest">
          <div className="PracticeMockTest_Card PMT_1">
            <span className="mockImg">
              <img src={onlinets} alt="" />
            </span>
            <h2>CY MOCK TEST-1</h2>
 
            <div>
              <p>60 Questions 3 Hour 100 Marks</p>
            </div>
            <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_IITJAM_Chemistry/1" onClick={(e) => handleClick("/StudentFormandMail_IITJAM_Chemistry/1", e)}>Take Test</a>

            </span>
          </div>
 
          <div className="PracticeMockTest_Card PMT_2">
            <span className="mockImg">
              <img src={onlinets} alt="" />
            </span>
            <h2>CY MOCK TEST-2</h2>
 
            <div>
              <p>60 Questions 3 Hour 100 Marks</p>
            </div>
            <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_IITJAM_Chemistry/2" onClick={(e) => handleClick("/StudentFormandMail_IITJAM_Chemistry/2", e)}>Take Test</a>

            </span>
          </div>
 
          <div className="PracticeMockTest_Card PMT_3">
            <span className="mockImg">
              <img src={onlinets} alt="" />
            </span>
            <h2>CY MOCK TEST-3</h2>
 
            <div>
              <p>60 Questions 3 Hour 100 Marks</p>
            </div>
            <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_IITJAM_Chemistry/3" onClick={(e) => handleClick("/StudentFormandMail_IITJAM_Chemistry/3", e)}>Take Test</a>

            </span>
          </div>
        </div>
     
      </div>
 
 
 
   {/* Economics (EN) SUBJECT MOCK TESTS */}
 
   <div className="jeesection " id="PracticeMockTest">
        <div className="MockTests_sub_heading">
        <h3>Economics (EN)</h3>
     
 
        </div>
        <div className="PracticeMockTest">
          <div className="PracticeMockTest_Card PMT_1">
            <span className="mockImg">
              <img src={onlinets1} alt="" />
            </span>
            <h2>EN MOCK TEST-1</h2>
 
            <div>
              <p>60 Questions 3 Hour 100 Marks</p>
            </div>
            <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_IITJAM_Economics/1" onClick={(e) => handleClick("/StudentFormandMail_IITJAM_Economics/1", e)}>Take Test</a>

            </span>
          </div>
 
          <div className="PracticeMockTest_Card PMT_2">
            <span className="mockImg">
              <img src={onlinets1} alt="" />
            </span>
            <h2>EN MOCK TEST-2</h2>
 
            <div>
              <p>60 Questions 3 Hour 100 Marks</p>
            </div>
            <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_IITJAM_Economics/2" onClick={(e) => handleClick("/StudentFormandMail_IITJAM_Economics/2", e)}>Take Test</a>

            </span>
          </div>
 
          <div className="PracticeMockTest_Card PMT_3">
            <span className="mockImg">
              <img src={onlinets1} alt="" />
            </span>
            <h2>EN MOCK TEST-3</h2>
 
            <div>
              <p>60 Questions 3 Hour 100 Marks</p>
            </div>
            <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_IITJAM_Economics/3" onClick={(e) => handleClick("/StudentFormandMail_IITJAM_Economics/3", e)}>Take Test</a>

            </span>
          </div>
        </div>
     
      </div>
 
 
 
   {/* Geology SUBJECT MOCK TESTS */}
 
   <div className="jeesection " id="PracticeMockTest">
        <div className="MockTests_sub_heading">
        <h3>Geology (GG) </h3>
 
        </div>
        <div className="PracticeMockTest">
          <div className="PracticeMockTest_Card PMT_1">
            <span className="mockImg">
              <img src={onlinets} alt="" />
            </span>
            <h2>GG MOCK TEST-1</h2>
 
            <div>
              <p>65 Questions 3 Hour 100 Marks</p>
            </div>
            <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_IITJAM_geology/1" onClick={(e) => handleClick("/StudentFormandMail_IITJAM_geology/1", e)}>Take Test</a>

            </span>
          </div>
 
          <div className="PracticeMockTest_Card PMT_2">
            <span className="mockImg">
              <img src={onlinets} alt="" />
            </span>
            <h2>GG MOCK TEST-2</h2>
 
            <div>
              <p>60 Questions 3 Hour 100 Marks</p>
            </div>
            <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_IITJAM_geology/2" onClick={(e) => handleClick("/StudentFormandMail_IITJAM_geology/2", e)}>Take Test</a>

            </span>
          </div>
 
          <div className="PracticeMockTest_Card PMT_3">
            <span className="mockImg">
              <img src={onlinets} alt="" />
            </span>
            <h2>GG MOCK TEST-3</h2>
 
            <div>
              <p>60 Questions 3 Hour 100 Marks</p>
            </div>
            <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_IITJAM_geology/3" onClick={(e) => handleClick("/StudentFormandMail_IITJAM_geology/3", e)}>Take Test</a>

            </span>
          </div>
        </div>
     
      </div>
 
   {/* Mathematics (MA) SUBJECT MOCK TESTS */}
 
   <div className="jeesection " id="PracticeMockTest">
        <div className="MockTests_sub_heading">
        <h3>Mathematics (MA)</h3>
 
        </div>
        <div className="PracticeMockTest">
          <div className="PracticeMockTest_Card PMT_1">
            <span className="mockImg">
              <img src={onlinets1} alt="" />
            </span>
            <h2>MA  MOCK TEST-1</h2>
 
            <div>
              <p>60 Questions 3 Hour 100 Marks</p>
            </div>
            <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_IITJAM_MATHS/1" onClick={(e) => handleClick("/StudentFormandMail_IITJAM_MATHS/1", e)}>Take Test</a>

            </span>
          </div>
 
          <div className="PracticeMockTest_Card PMT_2">
            <span className="mockImg">
              <img src={onlinets1} alt="" />
            </span>
            <h2>MA  MOCK TEST-2</h2>
 
            <div>
              <p>60 Questions 3 Hour 100 Marks</p>
            </div>
            <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_IITJAM_MATHS/2" onClick={(e) => handleClick("/StudentFormandMail_IITJAM_MATHS/2", e)}>Take Test</a>

            </span>
          </div>
 
          <div className="PracticeMockTest_Card PMT_3">
            <span className="mockImg">
              <img src={onlinets1} alt="" />
            </span>
            <h2>MA  MOCK TEST-3</h2>
 
            <div>
              <p>60 Questions 3 Hour 100 Marks</p>
            </div>
            <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_IITJAM_MATHS/3" onClick={(e) => handleClick("/StudentFormandMail_IITJAM_MATHS/3", e)}>Take Test</a>

            </span>
          </div>
        </div>
     
      </div>
 
 
         {/* Mathematical Statistics (MS) SUBJECT MOCK TESTS */}
 
   <div className="jeesection " id="PracticeMockTest">
        <div className="MockTests_sub_heading">
        <h3>Mathematical Statistics (MS)</h3>
 
     
 
        </div>
        <div className="PracticeMockTest">
          <div className="PracticeMockTest_Card PMT_1">
            <span className="mockImg">
              <img src={onlinets} alt="" />
            </span>
            <h2>MS MOCK TEST-1</h2>
 
            <div>
              <p>60 Questions 3 Hour 100 Marks</p>
            </div>
            <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_IITJAM_MATHS_STATISTICS/1" onClick={(e) => handleClick("/StudentFormandMail_IITJAM_MATHS_STATISTICS/1", e)}>Take Test</a>

            </span>
          </div>
 
          <div className="PracticeMockTest_Card PMT_2">
            <span className="mockImg">
              <img src={onlinets} alt="" />
            </span>
            <h2>MS MOCK TEST-2</h2>
 
            <div>
              <p>60 Questions 3 Hour 100 Marks</p>
            </div>
            <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_IITJAM_MATHS_STATISTICS/2" onClick={(e) => handleClick("/StudentFormandMail_IITJAM_MATHS_STATISTICS/2", e)}>Take Test</a>

            </span>
          </div>
 
          <div className="PracticeMockTest_Card PMT_3">
            <span className="mockImg">
              <img src={onlinets} alt="" />
            </span>
            <h2>MS  MOCK TEST-3</h2>
 
            <div>
              <p>60 Questions 3 Hour 100 Marks</p>
            </div>
            <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_IITJAM_MATHS_STATISTICS/3" onClick={(e) => handleClick("/StudentFormandMail_IITJAM_MATHS_STATISTICS/3", e)}>Take Test</a>

            </span>
          </div>
        </div>
     
      </div>
 
              {/*Physics (PH) SUBJECT MOCK TESTS */}
 
   <div className="jeesection " id="PracticeMockTest">
        <div className="MockTests_sub_heading">
        <h3>Physics (PH)</h3>
 
     
 
        </div>
        <div className="PracticeMockTest">
          <div className="PracticeMockTest_Card PMT_1">
            <span className="mockImg">
              <img src={onlinets1} alt="" />
            </span>
            <h2>PH  MOCK TEST-1</h2>
 
            <div>
              <p>60 Questions 3 Hour 100 Marks</p>
            </div>
            <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_IITJAM_PHYSICS/1" onClick={(e) => handleClick("/StudentFormandMail_IITJAM_PHYSICS/1", e)}>Take Test</a>

            </span>
          </div>
 
          <div className="PracticeMockTest_Card PMT_2">
            <span className="mockImg">
              <img src={onlinets1} alt="" />
            </span>
            <h2>PH MOCK TEST-2</h2>
 
            <div>
              <p>60 Questions 3 Hour 100 Marks</p>
            </div>
            <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_IITJAM_PHYSICS/2" onClick={(e) => handleClick("/StudentFormandMail_IITJAM_PHYSICS/2", e)}>Take Test</a>

            </span>
          </div>
 
          <div className="PracticeMockTest_Card PMT_3">
            <span className="mockImg">
              <img src={onlinets1} alt="" />
            </span>
            <h2>PH MOCK TEST-3</h2>
 
            <div>
              <p>60 Questions 3 Hour 100 Marks</p>
            </div>
            <span className="PracticeMockTest_Container">
            <a href="" onClick={(e) => handleClick("", e)}>Take Test</a>

            </span>
          </div>
        </div>
     
      </div>
 
      </div>
      <Footer />
 
    </div>
  )
}
 
export default PG_iit_jam_Mock_Tests_static_page