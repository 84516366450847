import React, { useState } from "react";
import mammoth from "mammoth";
import cheerio from 'cheerio';

function CovertJson() {
  const [subjects, setSubjects] = useState([]);
  const [jsonData, setJsonData] = useState(null); // Define jsonData state
  const [error, setError] = useState(null);

  const addSubject = () => {
    setSubjects([...subjects, { SubjectName: "", sections: [] }]);
  };

  const removeSubject = (subjectIndex) => {
    const newSubjects = subjects.filter((_, index) => index !== subjectIndex);
    setSubjects(newSubjects);
    setJsonData(newSubjects); // Update jsonData state
  };

  const addSection = (subjectIndex) => {
    const newSubjects = [...subjects];
    newSubjects[subjectIndex].sections.push({
      SectionName: "",
      start: "",
      end: "",
      questions: [],
    });
    setSubjects(newSubjects);
    setJsonData(newSubjects); // Update jsonData state
  };

  const removeSection = (subjectIndex, sectionIndex) => {
    const newSubjects = [...subjects];
    newSubjects[subjectIndex].sections = newSubjects[
      subjectIndex
    ].sections.filter((_, index) => index !== sectionIndex);
    setSubjects(newSubjects);
    setJsonData(newSubjects); // Update jsonData state
  };

  const handleInputChange = (subjectIndex, sectionIndex, e) => {
    const { name, value } = e.target;
    const newSubjects = [...subjects];
    if (sectionIndex === null) {
      newSubjects[subjectIndex][name] = value;
    } else {
      newSubjects[subjectIndex].sections[sectionIndex][name] = value;
    }
    setSubjects(newSubjects);
    setJsonData(newSubjects); // Update jsonData state
  };

  const handleFileUpload = async (e, subjectIndex, sectionIndex) => {
    const uploadedFile = e.target.files[0];

    if (!uploadedFile) {
      setError("No file uploaded.");
      return;
    }

    try {
      const arrayBuffer = await uploadedFile.arrayBuffer();
      const htmlResult = await mammoth.convertToHtml({ arrayBuffer });
      const htmlContent = htmlResult.value;
      const textResult = await mammoth.extractRawText({ arrayBuffer });
      const textContent = textResult.value;
      const textSections = textContent.split("\n\n");

      const $ = cheerio.load(htmlContent);

      const images = [];
      $("img").each(function (i, element) {
        const base64Data = $(this)
          .attr("src")
          .replace(/^data:image\/\w+;base64,/, "");
        images.push(`data:image/png;base64,${base64Data}`);
      });

      const newSubjects = [...subjects];
      const currentSection = newSubjects[subjectIndex].sections[sectionIndex];

      let currentQuestion = null;
      let imageIndex = 0;
      let questionIdCounter = currentSection.questions.length + 1;
      let optionIdCounter = 1;

      for (let i = 0; i < textSections.length; i++) {
        const section = textSections[i];
        if (section.includes("[Q]")) {
          if (currentQuestion) {
            currentSection.questions.push(currentQuestion);
          }
          currentQuestion = {
            question_id: questionIdCounter++,
            questionImgName: images[imageIndex++] || null,
            document_name: uploadedFile.name,
            options: [],
            qtype: null,
            answer: null,
            marks: null,
            sortid: null,
            solution: null,
          };
        } else if (currentQuestion) {
          if (section.includes("[ans]")) {
            currentQuestion.answer = section.replace("[ans]", "").trim();
          } else if (section.includes("[Marks]")) {
            currentQuestion.marks = section.replace("[Marks]", "").trim();
          } else if (section.includes("[sortid]")) {
            currentQuestion.sortid = section.replace("[sortid]", "").trim();
          } else if (section.includes("[qtype]")) {
            const qtypeText = section.replace("[qtype]", "").trim();
            currentQuestion.qtype = qtypeText;
          } else if (section.includes("(a)")) {
            currentQuestion.options.push({
              option_id: optionIdCounter++,
              option_index: "a",
              optionImgName: images[imageIndex++] || null,
            });
          } else if (section.includes("(b)")) {
            currentQuestion.options.push({
              option_id: optionIdCounter++,
              option_index: "b",
              optionImgName: images[imageIndex++] || null,
            });
          } else if (section.includes("(c)")) {
            currentQuestion.options.push({
              option_id: optionIdCounter++,
              option_index: "c",
              optionImgName: images[imageIndex++] || null,
            });
          } else if (section.includes("(d)")) {
            currentQuestion.options.push({
              option_id: optionIdCounter++,
              option_index: "d",
              optionImgName: images[imageIndex++] || null,
            });
          } else if (section.includes("(e)")) {
            currentQuestion.options.push({
              option_id: optionIdCounter++,
              option_index: "e",
              optionImgName: images[imageIndex++] || null,
            });
          } else if (section.includes("[soln]")) {
            currentQuestion.solution = images[imageIndex++] || null;
          }
        }
      }

      if (currentQuestion) {
        currentSection.questions.push(currentQuestion);
      }

      setSubjects(newSubjects);
      setError(null);
      setJsonData(newSubjects); // Update jsonData state whenever a file is uploaded and processed
    } catch (error) {
      console.error("Error processing file:", error);
      setError("Error processing file. Please try again.");
    }
  };

  const downloadJSON = () => {
    const blob = new Blob([JSON.stringify(subjects, null, 2)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `documents.json`;
    link.click();
  };

  return (
    <div>
      <button onClick={addSubject}>Add Subject</button>
      {subjects.map((subject, subjectIndex) => (
        <div key={subjectIndex}>
          <label>Subject Name:</label>
          <input
            type="text"
            name="SubjectName"
            value={subject.SubjectName}
            onChange={(e) => handleInputChange(subjectIndex, null, e)}
          />
          <button onClick={() => removeSubject(subjectIndex)}>
            Remove Subject
          </button>
          {subject.sections.map((section, sectionIndex) => (
            <div key={sectionIndex}>
              <label>Section Name:</label>
              <input
                type="text"
                name="SectionName"
                value={section.SectionName}
                onChange={(e) =>
                  handleInputChange(subjectIndex, sectionIndex, e)
                }
              />
              <label>Section Start:</label>
              <input
                type="number"
                name="start"
                value={section.start}
                onChange={(e) =>
                  handleInputChange(subjectIndex, sectionIndex, e)
                }
              />
              <label>Section End:</label>
              <input
                type="number"
                name="end"
                value={section.end}
                onChange={(e) =>
                  handleInputChange(subjectIndex, sectionIndex, e)
                }
              />
              <input
                type="file"
                accept=".docx"
                onChange={(e) =>
                  handleFileUpload(e, subjectIndex, sectionIndex)
                }
              />
              <button onClick={() => removeSection(subjectIndex, sectionIndex)}>
                Remove Section
              </button>
            </div>
          ))}
          <button onClick={() => addSection(subjectIndex)}>Add Section</button>
        </div>
      ))}
      {jsonData && (
        <div>
          <h2>Converted JSON Data</h2>
          <pre>{JSON.stringify(jsonData, null, 2)}</pre>
          <button onClick={downloadJSON}>Download JSON</button>
        </div>
      )}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
}

export default CovertJson;
