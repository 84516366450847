import React, { useState } from 'react'
import newarrivals from "./asserts/newarrivals.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { AiFillStar } from "react-icons/ai";
import { BsStarHalf } from "react-icons/bs";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { Publication_Page_arrivals_Book_pageData, Publication_Page_arrivals_Book_pageData1, Publication_Page_arrivals_Book_pageData2 } from './Publication_PageData/Publication_Page_Book_page';
const Publication_Page_NewArrivals_page = () => {
    const [visibleCountUG, setVisibleCountUG] = useState(5);
  return (
    <>
      <div className="Publication_Page_parentNewArrivals_page">
        <h3>New Arrivals</h3>
        <div className="Publication_Page_NewArrivals_page">
          <div className="Publication_Page_NewArrivals_pageimg">
            <img src={newarrivals} alt="" />
          </div>
          <div className="Publication_Page_NewArrivals_page_banners">
            <Carousel>
              {/* <div className="Publication_Page_Book_page_rowarrivailsconatiner"> */}
              {Publication_Page_arrivals_Book_pageData1.slice(
                0,
                visibleCountUG
              ).map((Book, id) => (
                <div key={id} className="Publication_Page_Book_page_bookcard">
                  <div className="Publication_Page_Book_page_bookcardcomingsoon">
                    coming soon
                  </div>
                  <div className="Publication_Page_Book_page_bookcardimg">
                    <img src={Book.BOOKImg} alt="" />
                  </div>
                  <div className="Publication_Page_Book_page_bookcard_detiles">
                    <div>
                      <h6>{Book.BOOKtitle}</h6>
                      <p>ISBN : {Book.BOOKISBN}</p>
                    </div>

                    <div className="Publication_Page_Book_page_bookcard_detilesstars">
                      <span>
                        <AiFillStar />
                      </span>
                      <span>
                        <AiFillStar />
                      </span>
                      <span>
                        <AiFillStar />
                      </span>
                      <span>
                        <BsStarHalf />
                      </span>
                    </div>
                    <div className="Publication_Page_Book_page_bookcard_detiles_B_btn_d_btn">
                      <div className="Publication_Page_Book_page_bookcard_detiles_B_btn_d_btn_sty">
                        <span>
                          <FaIndianRupeeSign />
                          {Book.BOOKprice}
                        </span>
                        <button>Buy Now</button>
                      </div>
                      <button>Details</button>
                    </div>
                  </div>
                </div>
              ))}
              {/* </div> */}
              {/* <div className="Publication_Page_Book_page_rowarrivailsconatiner">
              {Publication_Page_arrivals_Book_pageData2.slice(
                0,
                visibleCountUG
              ).map((Book, id) => (
                <div key={id} className="Publication_Page_Book_page_bookcard">
                  <div className="Publication_Page_Book_page_bookcardimg">
                    <img src={Book.BOOKImg} alt="" />
                  </div>
                  <div className="Publication_Page_Book_page_bookcard_detiles">
                    <div>
                      <h6>{Book.BOOKtitle}</h6>
                      <p>ISBN : {Book.BOOKISBN}</p>
                    </div>

                    <div className="Publication_Page_Book_page_bookcard_detilesstars">
                      <span>
                        <AiFillStar />
                      </span>
                      <span>
                        <AiFillStar />
                      </span>
                      <span>
                        <AiFillStar />
                      </span>
                      <span>
                        <BsStarHalf />
                      </span>
                    </div>
                    <div className="Publication_Page_Book_page_bookcard_detiles_B_btn_d_btn">
                      <div className="Publication_Page_Book_page_bookcard_detiles_B_btn_d_btn_sty">
                        <span>
                          <FaIndianRupeeSign />
                          {Book.BOOKprice}
                        </span>
                        <button>Buy Now</button>
                      </div>
                      <button>Details</button>
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
}

export default Publication_Page_NewArrivals_page
