import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
// import ScrollToTop from 'react-router-scroll-top';
import Footer from "./components/UG/Footer2.jsx";
import GATE_OTS from "./components/PG/GATE_OTS";
import { GateOts } from "./components/PG/GateOts";

// ========================================================= IMPORTED TERMS AND CONDITIONS PAGES ===========================================================
import { TERMsCONDITIONS } from "./components/UG/TERMsCONDITIONS";
import { Privacypolicy } from "./components/UG/Privacypolicy";

// ========================================================= IMPORTED LANDING PAGE ===========================================================
import { Egate } from "./Landing_PAGE/Egate";

// ========================================================= IMPORTED UG FILES ===========================================================
import UG_HOME from "./components/ug_homepage/UG_HOME";
import { IITJEE_EXAM } from "./components/UG/IITJEE_EXAM";
import OTS from "./components/UG/OTS";
import { JeeOts } from "./components/UG/JeeOts";
import OLVC from "./components/UG/IITJEE_OLVC";
import { NeetExam } from "./components/UG/NeetExam";
import { NeetOtc } from "./components/UG/NeetOtc";
import { NeetOLVC } from "./components/UG/NeetOLVC";
import { BitsatExam } from "./components/UG/BitsatExam";
import { BitsatOTC } from "./components/UG/BitsatOTC";
import { BitsatOLVC } from "./components/UG/BitsatOLVC";
import { ViteeeExam } from "./components/UG/ViteeeExam";
import { ApEamcetExam } from "./components/UG/ApEamcetExam";
import { TsEamcetExam } from "./components/UG/TsEamcetExam";
import { MhcetExam } from "./components/UG/MhcetExam";
import { KcetExam } from "./components/UG/KcetExam";
import { WbjeeExam } from "./components/UG/WbjeeExam";
import { KeamExam } from "./components/UG/KeamExam";
import { SrmJeeExam } from "./components/UG/SrmJeeExam";
import AeeeOTS from "./components/UG/AeeeOTS.jsx";

// ========================================================= IMPORTED PG FILES ===========================================================
import { Pg_home_page } from "./components/PG/PG_homePage/Pg_home_page";
import { PGGATE_Exam } from "./components/PG/PGGATE_Exam.jsx";
import { PGIITJAMExam } from "./components/PG/PGIITJAMExam.jsx";
import { Ese_ExamPage } from "./components/PG/Ese_ExamPage";
import { Pg_Tifr_ExamPage } from "./components/PG/Pg_Tifr_ExamPage";
import { Pg_Isro_ExamPage } from "./components/PG/Pg_Isro_ExamPage";

import { Pg_BARC_Exam_Page } from "./components/PG/Pg_BARC_Exam_Page";

// ========================================================= IMPORTED MBA FILES ===========================================================
import { MbaHome } from "./components/MBA/MbaHome";

// ========================================================= UNUSED IMPORTED FILES ===========================================================
import Landingpage from "../src/Landingpage";
import NEET from "./components/UG/NEET";
import { Landing } from "./Landing";
import { Ug_About } from "./components/UG/Ug_homePage_k/Ug_About/Ug_About";
import { Jee_ots } from "./components/UG/Ug ots pages/jee ots olvc/jee ots/Jee_ots";
import { PgHome } from "./components/PG/PgHome";
import LandingPage from "./NewLanding_Page/LandingPage.jsx";
import ScrollToTop from "./Landing_PAGE/ScrollToTop.js";
import Examheader from "./components/UG/Examheader.jsx";
import { JeeOTSPage } from "./components/UG/new UG OTS PAGE/JeeOTSPage.jsx";
import { ViteeeOTSPage } from "./components/UG/new UG OTS PAGE/ViteeeOTSPage.jsx";
import { Ap_EapcetOTSPage } from "./components/UG/new UG OTS PAGE/Ap_EapcetOTSPage.jsx";
import { TS_EamcetOTSPAge } from "./components/UG/new UG OTS PAGE/TS_EamcetOTSPAge.jsx";
import { MhcetOTSPage } from "./components/UG/new UG OTS PAGE/MhcetOTSPage.jsx";
import { KcetOTSPage } from "./components/UG/new UG OTS PAGE/KcetOTSPage.jsx";
import { WbjeeOTSPage } from "./components/UG/new UG OTS PAGE/WbjeeOTSPage.jsx";
import { KeamOTSPage } from "./components/UG/new UG OTS PAGE/KeamOTSPage.jsx";
import { SrmjeeOTSPage } from "./components/UG/new UG OTS PAGE/SrmjeeOTSPage.jsx";
import { State } from "./components/PG/context/State.jsx";

import Payu from "./components/Payu/Payu.jsx";
import Buttonpayu from "./components/Payu/Buttonpayu.jsx";

import AeeeExam from "./components/UG/AeeeExam.jsx";
import Metexam from "./components/UG/Metexam.jsx";
import Ap_EapcetOLVC from "./components/UG/Ap_EapcetOLVC.jsx";
import WbjeeOLVC from "./components/UG/WbjeeOLVC.jsx";
import Ts_EamcetOLVC from "./components/UG/Ts_EamcetOLVC.jsx";
import MhcetOLVC from "./components/UG/MhcetOLVC.jsx";
import Kcet_OLVC from "./components/UG/Kcet_OLVC.jsx";
import KeamOLVC from "./components/UG/KeamOLVC.jsx";
import Srmjee_OLVC from "./components/UG/Srmjee_OLVC.jsx";
import Aeee_OLVC from "./components/UG/Aeee_OLVC.jsx";
import Met_OLVC from "./components/UG/Met_OLVC.jsx";
import { Met_OTS } from "./components/UG/Met_OTS.jsx";



import CancellationRefundPolicy from "./components/UG/CancellationRefundPolicy.jsx";

// import Payubutton from "./components/Payu/Payubutton.jsx"

// import Payu from "./components/Payu/Payu.jsx";


import { ViteeeOLVC } from "./components/UG/ViteeeOLVC.jsx";
import Pg_JEST_ExamPage from "./components/PG/Pg_JEST_ExamPage.jsx";
import { GATE_OTS_PAGE } from "./components/PG/PG_homePage/Pg_Explor_Exam/PG_OTS_PAGS/GATE_OTS_PAGE.jsx";
import GATE_OLVC_Page from "./components/PG/PG_homePage/Pg_Explor_Exam/PG_OLVC_PAGES/GATE_OLVC_Page.jsx";
import { IIT_JAM_OTS_PAGE } from "./components/PG/PG_homePage/Pg_Explor_Exam/PG_OTS_PAGS/IIT_JAM_OTS_PAGE.jsx";
import IIT_JAM_OLVC from "./components/PG/PG_homePage/Pg_Explor_Exam/PG_OLVC_PAGES/IIT_JAM_OLVC.jsx";
import { EST_OTS_PAGE } from "./components/PG/PG_homePage/Pg_Explor_Exam/PG_OTS_PAGS/EST_OTS_PAGE.jsx";
import { TIFR_OTS_PAGE } from "./components/PG/PG_homePage/Pg_Explor_Exam/PG_OTS_PAGS/TIFR_OTS_PAGE.jsx";
import { ISRO_OTS_PAGE } from "./components/PG/PG_homePage/Pg_Explor_Exam/PG_OTS_PAGS/ISRO_OTS_PAGE.jsx";
import { BARC_OTS_PAGE } from "./components/PG/PG_homePage/Pg_Explor_Exam/PG_OTS_PAGS/BARC_OTS_PAGE.jsx";
import { JEST_OTS_PAGE } from "./components/PG/PG_homePage/Pg_Explor_Exam/PG_OTS_PAGS/JEST_OTS_PAGE.jsx";
import Contact from "./components/ug_homepage/components/Contact/Contact.js";
import { Pg_Countact } from "./components/PG/PG_homePage/Pg_Countact_Page/Pg_Countact.jsx";
import Faq from "./components/ug_homepage/components/Faqs/Faq.js";
import FaqPG from "./components/PG/Faqs/Faq.js";
import Pricing_Policy from "./components/UG/Pricing_Policy.jsx";
import { PracticesCode } from "./components/MBA/Practices code/PracticesCode.jsx";

import Publication_Page_structure from "./publication_component/publication_component/Publication_Page_structure.jsx";

import RazorpayForm from "./components/Payu/RazorpayForm.jsx";


import Download from "./components/ug_homepage/components/Downloads/Download.jsx"
import AP_EAPCETDownloadPage from './components/ug_homepage/components/Downloads/AP_EAPCETDownloadPage.jsx';
import IitjeeDownloadPage from "./components/ug_homepage/components/Downloads/IitjeeDownloadPage.jsx";

// import IitjeeDownloadPage_new from './components/ug_homepage/components/Downloads/IitjeeDownloadPage_new.jsx'
import JeeAdvancedDownloadPage from './components/ug_homepage/components/Downloads/JeeAdvancedDownloadPage.jsx';
import KcetDownloadPage from './components/ug_homepage/components/Downloads/KcetDownloadPage.jsx';
import MhcetDownloadPage from './components/ug_homepage/components/Downloads/MhcetDownloadPage.jsx';
import NeetDownloadPage from './components/ug_homepage/components/Downloads/NeetDownloadPage.jsx';
import TS_EAMCETDownloadPage from './components/ug_homepage/components/Downloads/TS_EAMCETDownloadPage.jsx';

import WbjeeDownloadPage from './components/ug_homepage/components/Downloads/WbjeeDownloadPage.jsx';
import About_us from "./components/ug_homepage/components/About_section/About_us.jsx";





// ******** NEWLY ADDED FROM STATIC MOCK TEST *****************//
import PG_GATE_Mock_Tests_static_page from '../src/components/PG/PG_homePage/Pg_Explor_Exam/PG_OTS_PAGS/PG_GATE_Mock_Tests_static_page.jsx'
import PG_iit_jam_Mock_Tests_static_page from "../src/components/PG/PG_homePage/Pg_Explor_Exam/PG_OTS_PAGS/PG_iit_jam_Mock_Tests_static_page.jsx";

import IITJAM_MockTest_1 from './PGMockTest/IITJAM/IITJAM_MockTest_1.jsx'
import CovertJson from "./MockTest/BITSAT/CovertJson.jsx";
import Groupcode from "./MockTest/NEET/Groupcode.jsx";



import StudentFormandMailGate_ME from './PGMockTest/GATE/Subjects/Gate_ME_Subject/StudentFormandMailGate_ME.jsx';
import StudentFormandMailGate_AE from './PGMockTest/GATE/Subjects/Gate_AE_Subject/StudentFormandMailGate_AE.jsx';
import StudentFormandMailGate_XE from './PGMockTest/GATE/Subjects/GATE_XE_Subject/StudentFormandMailGate_XE.jsx';
import StudentFormandMailGate_MATHS from './PGMockTest/GATE/Subjects/Gate_Maths_Subject/StudentFormandMailGate_MATHS.jsx';
import StudentFormandMailGate_DA from './PGMockTest/GATE/Subjects/Gate_DA_Subject/StudentFormandMailGate_DA.jsx';
import StudentFormandMailGate_MT from './PGMockTest/GATE/Subjects/Gate_MT_Subject/StudentFormandMailGate_MT.jsx';
import PG_General_Instructions_Page from './PGMockTest/PG_General_Instructions_Page.jsx';
import IITJAM_Instructions_page from "./PGMockTest/IITJAM/IITJAM_Instructions_page.jsx";

import GATE_Exam_Instructions from './PGMockTest/GATE/GATE_Exam_Instructions.jsx';

import StudentFormandMailGate from './PGMockTest/GATE/StudentFormandMailGate.jsx';
import Gate_ME_Instructions from './PGMockTest/GATE/Subjects/Gate_ME_Subject/Gate_ME_Instructions.jsx';
import Gate_ME_General_Instructions from './PGMockTest/GATE/Subjects/Gate_ME_Subject/Gate_ME_General_Instructions.jsx';

import Gate_AE_Instructions from './PGMockTest/GATE/Subjects/Gate_AE_Subject/Gate_AE_Instructions.jsx';
import Gate_AE_General_Instructions from './PGMockTest/GATE/Subjects/Gate_AE_Subject/Gate_AE_General_Instructions.jsx';

import StudentFormandMailIITJAM from './PGMockTest/IITJAM/StudentFormandMailIITJAM.jsx';
import GATE_MockTest_1 from "./PGMockTest/GATE/GATE_MockTest_1.jsx";



import Gate_XE_General_Instructions from './PGMockTest/GATE/Subjects/GATE_XE_Subject/Gate_XE_General_Instructions.jsx';

// import Gate_XE_Instructions from "./PGMockTest/GATE/Subjects/GATE_XE_Subject/Gate_XE_Instructions.jsx";

import StudentFormandMail_GATE_Physics from './PGMockTest/GATE/Subjects/Gate_Physics_Subject/StudentFormandMail_GATE_Physics.jsx';

import Gate_Physics_Instructions from './PGMockTest/GATE/Subjects/Gate_Physics_Subject/Gate_Physics_Instructions.jsx';
import Gate_Physics_General_Instructions from './PGMockTest/GATE/Subjects/Gate_Physics_Subject/Gate_Physics_General_Instructions.jsx';

import Gate_DA_Instructions from './PGMockTest/GATE/Subjects/Gate_DA_Subject/Gate_DA_Instructions.jsx';
import Gate_MATHS_Instructions from './PGMockTest/GATE/Subjects/Gate_Maths_Subject/Gate_MATHS_Instructions.jsx';

import Gate_MATHS_General_Instructions from './PGMockTest/GATE/Subjects/Gate_Maths_Subject/Gate_MATHS_General_Instructions.jsx';

import Gate_MT_Instructions from './PGMockTest/GATE/Subjects/Gate_MT_Subject/Gate_MT_Instructions.jsx';

import Gate_MT_General_Instructions from './PGMockTest/GATE/Subjects/Gate_MT_Subject/Gate_MT_General_Instructions.jsx';
import Gate_XE_Instructions from "./PGMockTest/GATE/Subjects/GATE_XE_Subject/Gate_XE_Instructions.jsx"
import Gate_DA_General_Instructions from "./PGMockTest/GATE/Subjects/Gate_DA_Subject/Gate_DA_General_Instructions.jsx";
// import IIT_JAM_PHYSICS_Instructions from "./PGMockTest/IITJAM/Subjects/Physics/IIT_JAM_PHYSICS_Instructions.JSX";

import StudentFormandMail_IITJAM_Biotechnology from './PGMockTest/IITJAM/Subjects/Biotechnology/StudentFormandMail_IITJAM_Biotechnology.jsx';

import IITJAM_Biotechnology_General_Instructions from './PGMockTest/IITJAM/Subjects/Biotechnology/IITJAM_Biotechnology_General_Instructions.jsx';

import IIT_JAM_Biotechnology_Instructions from './PGMockTest/IITJAM/Subjects/Biotechnology/IIT_JAM_Biotechnology_Instructions.jsx';

import StudentFormandMail_IITJAM_Chemistry from './PGMockTest/IITJAM/Subjects/Chemistry/StudentFormandMail_IITJAM_Chemistry.jsx';


import IITJAM_Chemistry_General_Instructions from './PGMockTest/IITJAM/Subjects/Chemistry/IITJAM_Chemistry_General_Instructions.jsx';

import IIT_JAM_Chemistry_Instructions from './PGMockTest/IITJAM/Subjects/Chemistry/IIT_JAM_Chemistry_Instructions.jsx';

import StudentFormandMail_IITJAM_Economics from './PGMockTest/IITJAM/Subjects/Economics/StudentFormandMail_IITJAM_Economics.jsx';

import IITJAM_Economics_General_Instructions from './PGMockTest/IITJAM/Subjects/Economics/IITJAM_Economics_General_Instructions.jsx';

import IIT_JAM_Economics_Instructions from './PGMockTest/IITJAM/Subjects/Economics/IIT_JAM_Economics_Instructions.jsx';

import StudentFormandMail_IITJAM_geology from './PGMockTest/IITJAM/Subjects/Geology/StudentFormandMail_IITJAM_geology.jsx';

import IITJAM_Geology_General_Instructions from './PGMockTest/IITJAM/Subjects/Geology/IITJAM_Geology_General_Instructions.jsx';

import IIT_JAM_Geology_Instructions from './PGMockTest/IITJAM/Subjects/Geology/IIT_JAM_Geology_Instructions.jsx';
import StudentFormandMail_IITJAM_MATHS from './PGMockTest/IITJAM/Subjects/Mathematics/StudentFormandMail_IITJAM_MATHS.jsx';

import IITJAM_MATHS_General_Instructions from './PGMockTest/IITJAM/Subjects/Mathematics/IITJAM_MATHS_General_Instructions.jsx';

import IIT_JAM_MATHS_Instructions from './PGMockTest/IITJAM/Subjects/Mathematics/IIT_JAM_MATHS_Instructions.jsx';
import StudentFormandMail_IITJAM_MATHS_STATISTICS from './PGMockTest/IITJAM/Subjects/Mathematical Statistics/StudentFormandMail_IITJAM_MATHS_STATISTICS.jsx';


import IITJAM_MATHS_STATISTICS_General_Instructions from './PGMockTest/IITJAM/Subjects/Mathematical Statistics/IITJAM_MATHS_STATISTICS_General_Instructions.jsx';
import IIT_JAM_MATHS_STATISTICS_Instructions from './PGMockTest/IITJAM/Subjects/Mathematical Statistics/IIT_JAM_MATHS_STATISTICS_Instructions.jsx';
import StudentFormandMail_IITJAM_PHYSICS from './PGMockTest/IITJAM/Subjects/Physics/StudentFormandMail_IITJAM_PHYSICS.jsx';
import IITJAM_PHYSICS_General_Instructions from "./PGMockTest/IITJAM/Subjects/Physics/IITJAM_PHYSICS_General_Instructions.jsx";
import IIT_JAM_PHYSICS_Instructions from "./PGMockTest/IITJAM/Subjects/Physics/IIT_JAM_PHYSICS_Instructions.jsx";

import JEE_MandAMockTest from "./components/UG/new UG OTS PAGE/UgMockTests/JEE_MandAMockTest.jsx";
import NEET_MockTest from "./components/UG/new UG OTS PAGE/UgMockTests/NEET_MockTest.jsx";
import BITSAT_MockTest from "./components/UG/new UG OTS PAGE/UgMockTests/BITSAT_MockTest.jsx";
import VITEEE_MockTest from "./components/UG/new UG OTS PAGE/UgMockTests/VITEEE_MockTest.jsx";
import MocktestBitsat from "./MockTest/MocktestBitsat.jsx";
import InstructionPage from "./MockTest/BITSAT/InstructionPage.jsx";
import SubmitFormAndMail from "./MockTest/BITSAT/Submitformandmail.jsx";
import Genralinstructions from "./MockTest/BITSAT/Genralinstructions.jsx";
import Mocktest_1 from "./MockTest/BITSAT/Mocktest_1.jsx";
import SubmitFormAndMailNEET from "./MockTest/NEET/SubmitFormAndMailNEET.jsx";
import NEETInstructionPage from "./MockTest/NEET/NEETInstructionPage.jsx";
import NEETGenralinstructions from "./MockTest/NEET/NEETGenralinstructions.jsx";
import NEETMocktest_1 from "./MockTest/NEET/NEETMocktest_1.jsx";
import BITSATMocktest_1 from "./MockTest/BITSAT/BITSATMocktest_1.jsx";
import VITEEMocktest_1 from "./MockTest/VITEEE/VITEEMocktest_1.jsx";
import Jee_mains_1 from "./MockTest/JEE(MAINS)/Jee_mains_1.jsx";
import Jee_advance_1 from "./MockTest/JEE(ADVANCE)/Jee_advance_1.jsx";
import VITEEESubmitFormandmail from "./MockTest/VITEEE/VITEEESubmitFormandmail.jsx";
import VITEEInstructionsPage1 from "./MockTest/VITEEE/VITEEInstructionPage1.jsx";
import Instructionjee from "./MockTest/JEE(MAINS)/Instructionjee.jsx"
import JEE_Advance_general_instructions from "./MockTest/JEE(ADVANCE)/JEE_Advance_general_instructions.jsx";
import JEE_MAINS_General_instructions from "./MockTest/JEE(MAINS)/JEE_MAINS_General_instructions.jsx";
import VITEEEGenralinstructions from "./MockTest/VITEEE/VITEEEGenralinstructions.jsx";
import StudentFormandMailjee from "./MockTest/JEE(MAINS)/StudentFormandMailjee.jsx";
import JeeMainsMocktest from "./MockTest/JEE(MAINS)/JeeMainsMocktest.jsx";
import StudentFormandMailjeeAdvance from "./MockTest/JEE(ADVANCE)/StudentFormandMailjeeAdvance.jsx";
import InstructionjeeAdvance from "./MockTest/JEE(ADVANCE)/InstructionjeeAdvance.jsx"
import JeeAdvanceMocktest from "./MockTest/JEE(ADVANCE)/JeeAdvanceMocktest.jsx";
import PG_DOWNLOAD from "./components/PG/PG DOWNLOADS/PG_DOWNLOAD.jsx";
import GateDownloadPage from "./components/PG/PG DOWNLOADS/GateDownloadPage.jsx";
import IitjamDownloadPage from "./components/PG/PG DOWNLOADS/IitjamDownloadPage.jsx";
import EseDownloadPage from "./components/PG/PG DOWNLOADS/EseDownloadPage.jsx";
import TifrDownloadPage from "./components/PG/PG DOWNLOADS/TifrDownloadPage.jsx";
import IsroDownloadPage from "./components/PG/PG DOWNLOADS/IsroDownloadPage.jsx";
import BarcDownloadPage from "./components/PG/PG DOWNLOADS/BarcDownloadPage.jsx";
import JestDownloadPage from "./components/PG/PG DOWNLOADS/JestDownloadPage.jsx";
import New_MockTestform_file_Topic_wise from './MockTest/JEE(MAINS)/New_MockTestform_file_Topic_wise.jsx';
import New_UG_OTS_Home_Page from './components/UG/new UG OTS PAGE/New_UG_OTS_Home_Page.jsx';
import NEETMockTest from "./MockTest/JEE(MAINS)/NEETMockTest.jsx";



const App = () => {
  return (
    <State>
      <Router>
        <ScrollToTop />
        <Routes>

          {/* ======================================================== LANDING PAGE ================================================================ */}
          {/* This is a link for landing page */}
          <Route path="/" element={<Egate />} />

          {/* ======================================================== PUBLICATION PAGE ================================================================ */}
          {/* This is a link for landing page */}
          <Route path="/PublicationPage" element={<Publication_Page_structure />} />
          {/* ======================================================== ALL HOME PAGES ================================================================ */}

          {/* These are the links for all Home pages */}

          {/* This is a link for UG Home page */}
          <Route path="/home" element={<UG_HOME />} />
          <Route path="/download" element={<Download />} />
          <Route path="/AP_EAPCETDownloadPage" element={<AP_EAPCETDownloadPage />} />
          <Route path="/IitjeeDownloadPage" element={<IitjeeDownloadPage />} />
          <Route path="/JeeAdvancedDownloadPage" element={<JeeAdvancedDownloadPage />} />
          <Route path="/KcetDownloadPage" element={<KcetDownloadPage />} />
          <Route path="/MhcetDownloadPage" element={<MhcetDownloadPage />} />
          <Route path="/NeetDownloadPage" element={<NeetDownloadPage />} />
          <Route path="/TS_EAMCETDownloadPage" element={<TS_EAMCETDownloadPage />} />
          <Route path="/WbjeeDownloadPage" element={<WbjeeDownloadPage />} />

          {/* This ia a link for PG Home page */}
          <Route path="/PgHome" element={<Pg_home_page />} />

          {/* This is a link for MBA Home page */}
          <Route path="/MbaHome" element={<MbaHome />} />


          {/* ======================================================== CONTACT US PAGES ============================================================== */}
          {/* This is a link for UG CONTACT US PAGE */}
          <Route path="/contactUs" element={<Contact />} />

          <Route path="/About_us" element={<About_us />} />

          {/* This is a link for PG CONTACT US PAGE */}
          <Route path="/PgcontactUs" element={<Pg_Countact />} />

          {/* ======================================================== FAQ PAGES ============================================================== */}
          {/* This is a link for UG FAQ PAGE */}
          <Route path="/faq" element={<Faq />} />

          {/* This is a link for PG FAQ PAGE */}
          <Route path="/faqpg" element={<FaqPG />} />


          {/* ======================================================== ALL UG EXAM PAGES(INCLUDING OTS AND OLVC PAGES) ================================================================ */}

          {/* These are the links for all UG Exam pages  */}

          {/* This is the EXAM page OTS Page and OLVC page for IIT-JEE */}

          {/* This is the EXAM page for IIT-JEE */}
          <Route path="/iitjeeExam" element={<IITJEE_EXAM />} />

          {/* This is the OTS page for IIT-JEE */}
          <Route path="/iitjee_otc" element={<JeeOTSPage />} />

          {/* <Route path="/iitjee" element={<OTS />} /> */}
          {/* <Route path="/iitjee_otc" element={<JeeOts />} /> */}

          {/* This is the OLVC page for IIT-JEE */}
          <Route path="/iitjee_olvc" element={<OLVC />} />


          {/* This is the EXAM page OTS Page and OLVC page for NEET */}

          {/* This is the EXAM page for NEET */}
          <Route path="/neetexam" element={<NeetExam />} />

          {/* This is the OTS page for NEET */}
          <Route path="/neetotc" element={<NeetOtc />} />

          {/* This is the OLVC page for NEET */}
          <Route path="/neet_olvc" element={<NeetOLVC />} />

          {/* This is the EXAM page OTS Page and OLVC page for BITSAT */}

          {/* This is the EXAM page for BITSAT */}
          <Route path="/bitsatexam" element={<BitsatExam />} />

          {/* This is the OTS page for BITSAT */}
          <Route path="/bitsatots" element={<BitsatOTC />} />

          {/* This is the OLVC page for BITSAT */}
          <Route path="/bitsat_olvc" element={<BitsatOLVC />} />


          {/* This is the EXAM page OTS Page and OLVC page for VITEEE */}

          {/* This is the EXAM page for VITEEE */}
          <Route path="/viteeeexam" element={<ViteeeExam />} />

          {/* This is the OTS page for VITEEE */}
          <Route path="/VITEEE_OTS_PAGE" element={<ViteeeOTSPage />} />

          {/* This is the OLVC page for VITEEE */}
          <Route path="/VITEEE_olvc_PAGE" element={<ViteeeOLVC />} />


          {/* This is the EXAM page OTS Page and OLVC page for AP-EAPCET */}

          {/* This is the EXAM page for AP-EAPCET */}
          <Route path="/apeamcetexam" element={<ApEamcetExam />} />

          {/* This is the OTS page for AP-EAPCET */}
          <Route path="/AP-EAPCET_OTS_PAGE" element={<Ap_EapcetOTSPage />} />

          {/* This is the OLVC page for AP-EAPCET */}
          <Route path="/ApEapcetOLVC" element={<Ap_EapcetOLVC />} />


          {/* This is the EXAM page OTS Page and OLVC page for AP-EAPCET */}

          {/* This is the EXAM page for TS-EAMCET */}
          <Route path="/tseamcetexam" element={<TsEamcetExam />} />

          {/* This is the OTS page for TS-EAMCET */}
          <Route path="/TS-EMACET_OTS_PAGE" element={<TS_EamcetOTSPAge />} />

          {/* This is the OLVC page for TS-EAMCET */}
          <Route path="/TS_EamcetOLVC" element={<Ts_EamcetOLVC />} />


          {/* This is the EXAM page OTS Page and OLVC page for MHCET */}

          {/* This is the EXAM page for MHCET */}
          <Route path="/mhcetexam" element={<MhcetExam />} />

          {/* This is the OTS page for MHCET */}
          <Route path="/MHCET_OTS_PAGE" element={<MhcetOTSPage />} />

          {/* This is the OLVC page for MHCET */}
          <Route path="/Mhcet_OLVC" element={<MhcetOLVC />} />


          {/* This is the EXAM page OTS Page and OLVC page for KCET */}

          {/* This is the EXAM page for KCET */}
          <Route path="/kcetexam" element={<KcetExam />} />

          {/* This is the OTS page for KCET */}
          <Route path="/KCET_OTS_PAGE" element={<KcetOTSPage />} />

          {/* This is the OLVC page for KCET */}
          <Route path="/Kcet_OLVC" element={<Kcet_OLVC />} />


          {/* This is the EXAM page OTS Page and OLVC page for WBJEE */}

          {/* This is the EXAM page for WBJEE */}
          <Route path="/wbjeeexam" element={<WbjeeExam />} />

          {/* This is the OTS page for WBJEE */}
          <Route path="/WBJEE_OTS_PAGE" element={<WbjeeOTSPage />} />

          {/* This is the OLVC page for WBJEE */}
          <Route path="/WbjeeOLVC" element={<WbjeeOLVC />} />


          {/* This is the EXAM page OTS Page and OLVC page for KEAM */}

          {/* This is the EXAM page for KEAM */}
          <Route path="/keamexam" element={<KeamExam />} />

          {/* This is the OTS page for KEAM */}
          <Route path="/KEAM_OTS_PAGE" element={<KeamOTSPage />} />

          {/* This is the OLVC page for KEAM */}
          <Route path="/Keam_OLVC" element={<KeamOLVC />} />


          {/* This is the EXAM page OTS Page and OLVC page for SRMJEE */}

          {/* This is the EXAM page for SRMJEE */}
          <Route path="/srmjeeexam" element={<SrmJeeExam />} />

          {/* This is the OTS page for SRMJEE */}
          <Route path="/SRMJEE_OTS_PAGE" element={<SrmjeeOTSPage />} />

          {/* This is the OLVC page for SRMJEE */}
          <Route path="/Srmjee_OLVC" element={<Srmjee_OLVC />} />

          {/* This is the EXAM page OTS Page and OLVC page for AEEE */}

          {/* This is the EXAM page for AEEE */}
          <Route path="/AeeeExam" element={<AeeeExam />} />

          {/* This is the OTS page for AEEE */}
          <Route path="/AEEE_OTS_PAGE" element={<AeeeOTS />} />

          {/* This is the OLVC page for AEEE */}
          <Route path="/Aeee_OLVC" element={<Aeee_OLVC />} />


          {/* This is the EXAM page OTS Page and OLVC page for MET */}

          {/* This is the EXAM page for MET */}
          <Route path="/Metexam" element={<Metexam />} />

          {/* This is the OTS page for MET */}
          <Route path="/Met_ots" element={<Met_OTS />} />

          {/* This is the OLVC page for MET */}
          <Route path="/Met_OLVC" element={<Met_OLVC />} />


          {/* ========================================================= END OF UG EXAM LINKS ====================================================================== */}



          {/* ======================================================== ALL PG EXAM PAGES(INCLUDING OTS AND OLVC PAGES) ================================================================ */}

          {/* These are the links for all PG Exam pages  */}


          {/* This is the EXAM page OTS Page and OLVC page for GATE */}

          {/* This is the EXAM page for GATE */}
          <Route path="/pggateexam" element={<PGGATE_Exam />} />

          {/* This is the OTS page for GATE */}
          <Route path="/Gate_ots" element={<GATE_OTS_PAGE />} />

          {/* This is the OLVC page for GATE */}
          <Route path="/Gate_OLVC" element={<GATE_OLVC_Page />} />


          {/* This is the EXAM page OTS Page and OLVC page for IITJAM */}

          {/* This is the EXAM page for IITJAM */}
          <Route path="/pgiitjamexam" element={<PGIITJAMExam />} />

          {/* This is the OTS page for IITJAM */}
          <Route path="/iitjam_ots" element={<IIT_JAM_OTS_PAGE />} />

          {/* This is the OLVC page for IITJAM */}
          <Route path="/iitjam_olvc" element={<IIT_JAM_OLVC />} />


          {/* This is the EXAM page OTS Page and OLVC page for ESE */}

          {/* This is the EXAM page for ESE */}
          <Route path="/pgEse_exam" element={<Ese_ExamPage />} />

          {/* This is the OTS page for ESE */}
          <Route path="/est_ots" element={<EST_OTS_PAGE />} />


          {/* This is the EXAM page OTS Page and OLVC page for TIFR */}

          {/* This is the EXAM page for TIFR */}
          <Route path="/Ug_Tifr_ExamPage" element={<Pg_Tifr_ExamPage />} />

          {/* This is the OTS page for TIFR */}
          <Route path="/tifr_ots" element={<TIFR_OTS_PAGE />} />


          {/* This is the EXAM page OTS Page and OLVC page for ISRO */}

          {/* This is the EXAM page for ISRO */}
          <Route path="/isro_examPage" element={<Pg_Isro_ExamPage />} />

          {/* This is the OTS page for ISRO */}
          <Route path="/isro_ots" element={<ISRO_OTS_PAGE />} />


          {/* This is the EXAM page OTS Page and OLVC page for ISRO */}

          {/* This is the EXAM page for BARC */}
          <Route path="/barc_examPage" element={<Pg_BARC_Exam_Page />} />

          {/* This is the OTS page for BARC */}
          <Route path="/barc_ots" element={<BARC_OTS_PAGE />} />


          {/* This is the EXAM page OTS Page and OLVC page for JEST */}

          {/* This is the EXAM page for JEST */}
          <Route path="/jest_examPage" element={<Pg_JEST_ExamPage />} />

          {/* This is the OTS page for JEST */}
          <Route path="/jest_ots" element={<JEST_OTS_PAGE />} />



          {/* ========================================================= END OF PG EXAM LINKS ====================================================================== */}



          <Route path="/terms" element={<TERMsCONDITIONS />} />
          <Route path="/CancellationRefundPolicy" element={<CancellationRefundPolicy />} />
          <Route path="/policy" element={<Privacypolicy />} />
          <Route path="/pricingpolicy" element={<Pricing_Policy />} />

          {/* <Route path="/PayU" element={<Payu />} />
          <Route path='/payubutton' element={<Buttonpayu />} /> */}
          <Route path='/paymentegt' element={<RazorpayForm />} />
         

          {/* <Route path="/IitjeeDownloadPage_new" element={<IitjeeDownloadPage_new />} /> */}



          {/************************ NEWLY ADDED FROM STATIC PAGE BRANCH ***************************************/}
          {/* ********************************************************************************************** */}

          <Route path="/PG_GATE_Mock_Tests_static_page" element={<PG_GATE_Mock_Tests_static_page />} />
          <Route path="/PG_iit_jam_Mock_Tests_static_page" element={<PG_iit_jam_Mock_Tests_static_page />} />





          {/* ##################################################################################################################################################### */}
          <Route path = '/New_MockTestform_file_Topic_wise' element = {<New_MockTestform_file_Topic_wise />} />
          {/* IITJAM MOCK TEST  */}
          <Route path="/IITJAM_MockTest_1/:mockId" element={<IITJAM_MockTest_1 />} />


          <Route path="/CovertJson" element={<CovertJson />} />

          <Route path="/Groupcode" element={<Groupcode />} />


          <Route path='/PG_General_Instructions_Page/:mockId' element={<PG_General_Instructions_Page />} />
          <Route path="/IITJAM_Instructions_page" element={<IITJAM_Instructions_page />} />
          <Route path="/GATE_Exam_Instructions/:mockId" element={<GATE_Exam_Instructions />} />
          <Route path="/GATE_MockTest_1/:mockId" element={<GATE_MockTest_1 />} />
          <Route path="/egradPgmocktest" element={<StudentFormandMailGate />} />

          {/* GATE-ME_SUBJECT_JSX_FILES */}

          <Route path='/StudentFormandMailGate_ME/:mockId' element={<StudentFormandMailGate_ME />} />

          <Route path='/Gate_ME_Instructions/:mockId' element={<Gate_ME_Instructions />} />

          <Route path="/Gate_ME_General_Instructions/:mockId" element={<Gate_ME_General_Instructions />} />
          {/* GATE-ME_SUBJECT_JSX_FILES_ END*/}


          {/* GATE-AE_SUBJECT_JSX_FILES */}
          <Route path="/StudentFormandMailGate_AE/:mockId" element={<StudentFormandMailGate_AE />} />
          <Route path="/Gate_AE_Instructions/:mockId" element={<Gate_AE_Instructions />} />
          <Route path="/Gate_AE_General_Instructions/:mockId" element={<Gate_AE_General_Instructions />} />
          {/* GATE-AE_SUBJECT_JSX_FILES END */}

          {/* GATE-XE_SUBJECT_JSX_FILES */}

          <Route path="/StudentFormandMailGate_XE/:mockId" element={<StudentFormandMailGate_XE />} />
          <Route path='/Gate_XE_General_Instructions/:mockId' element={<Gate_XE_General_Instructions />} />
          <Route path="/Gate_XE_Instructions/:mockId" element={<Gate_XE_Instructions />} />
          {/* GATE-XE_SUBJECT_JSX_FILES END */}

          {/* GATE_Physics_SUBJECT_JSX_FILES */}

          <Route path="/StudentFormandMail_GATE_Physics/:mockId" element={<StudentFormandMail_GATE_Physics />} />

          <Route path="/Gate_Physics_Instructions/:mockId" element={<Gate_Physics_Instructions />} />

          <Route path="/Gate_Physics_General_Instructions/:mockId" element={<Gate_Physics_General_Instructions />} />

          {/* GATE_Physics_SUBJECT_JSX_FILES END*/}


          {/* GATE_Mathematics_SUBJECT_JSX_FILES */}

          <Route path='/StudentFormandMailGate_MATHS/:mockId' element={<StudentFormandMailGate_MATHS />} />

          <Route path='/Gate_MATHS_Instructions/:mockId' element={<Gate_MATHS_Instructions />} />

          <Route path="/Gate_MATHS_General_Instructions/:mockId" element={<Gate_MATHS_General_Instructions />} />

          {/* GATE_Mathematics_SUBJECT_JSX_FILES END*/}

          {/* GATE_DA_SUBJECT_JSX_FILES */}
          <Route path="/StudentFormandMailGate_DA/:mockId" element={<StudentFormandMailGate_DA />} />

          <Route path="/Gate_DA_Instructions/:mockId" element={<Gate_DA_Instructions />} />


          <Route path="/Gate_DA_General_Instructions/:mockId" element={<Gate_DA_General_Instructions />} />
          {/* GATE_DA_SUBJECT_JSX_FILES END*/}

          {/* GATE_MT_SUBJECT_JSX_FILES */}
          <Route path="/StudentFormandMailGate_MT/:mockId" element={<StudentFormandMailGate_MT />} />

          <Route path="/Gate_MT_Instructions/:mockId" element={<Gate_MT_Instructions />} />

          <Route path="/Gate_MT_General_Instructions/:mockId" element={<Gate_MT_General_Instructions />} />

          {/* GATE_MT_SUBJECT_JSX_FILES END*/}





          {/* IITJAM BIOTECHNOLOGY SUBJECTS FILES */}

          <Route path="/StudentFormandMail_IITJAM_Biotechnology/:mockId" element={<StudentFormandMail_IITJAM_Biotechnology />} />

          <Route path="/IITJAM_Biotechnology_General_Instructions/:mockId" element={<IITJAM_Biotechnology_General_Instructions />} />

          <Route path="/IIT_JAM_Biotechnology_Instructions/:mockId" element={<IIT_JAM_Biotechnology_Instructions />} />
          {/* IITJAM BIOTECHNOLOGY SUBJECTS FILES END*/}


          {/* IITJAM CHEMISTRY SUBJECTS FILES */}
          <Route path="/StudentFormandMail_IITJAM_Chemistry/:mockId" element={<StudentFormandMail_IITJAM_Chemistry />} />

          <Route path="/IITJAM_Chemistry_General_Instructions/:mockId" element={<IITJAM_Chemistry_General_Instructions />} />

          <Route path="/IIT_JAM_Chemistry_Instructions/:mockId" element={<IIT_JAM_Chemistry_Instructions />} />



          {/* IITJAM CHEMISTRY SUBJECTS FILES END*/}

          {/* IITJAM ECONOMICS SUBJECTS FILES */}

          <Route path="/StudentFormandMail_IITJAM_Economics/:mockId" element={<StudentFormandMail_IITJAM_Economics />} />

          <Route path="/IITJAM_Economics_General_Instructions/:mockId" element={<IITJAM_Economics_General_Instructions />} />

          <Route path="/IIT_JAM_Economics_Instructions/:mockId" element={<IIT_JAM_Economics_Instructions />} />


          {/* IITJAM ECONOMICS SUBJECTS FILES END*/}

          {/* IITJAM GEOLOGY SUBJECTS FILES */}
          <Route path="/StudentFormandMail_IITJAM_geology/:mockId" element={<StudentFormandMail_IITJAM_geology />} />

          <Route path="/IITJAM_Geology_General_Instructions/:mockId" element={<IITJAM_Geology_General_Instructions />} />

          <Route path="/IIT_JAM_Geology_Instructions/:mockId" element={<IIT_JAM_Geology_Instructions />} />

          {/* IITJAM GEOLOGY SUBJECTS FILES END*/}



          {/* IITJAM MATHS SUBJECTS FILES */}
          <Route path="/StudentFormandMail_IITJAM_MATHS/:mockId" element={<StudentFormandMail_IITJAM_MATHS />} />


          <Route path="/IITJAM_MATHS_General_Instructions/:mockId" element={<IITJAM_MATHS_General_Instructions />} />

          <Route path="/IIT_JAM_MATHS_Instructions/:mockId" element={<IIT_JAM_MATHS_Instructions />} />

          {/* IITJAM MATHS SUBJECTS FILES END*/}


          {/* IITJAM MATHEMATICS_STATISTICS SUBJECTS FILES */}

          <Route path="/StudentFormandMail_IITJAM_MATHS_STATISTICS/:mockId" element={<StudentFormandMail_IITJAM_MATHS_STATISTICS />} />

          <Route path="/IITJAM_MATHS_STATISTICS_General_Instructions/:mockId" element={<IITJAM_MATHS_STATISTICS_General_Instructions />} />

          <Route path="/IIT_JAM_MATHS_STATISTICS_Instructions/:mockId" element={<IIT_JAM_MATHS_STATISTICS_Instructions />} />

          {/* IITJAM MATHEMATICS_STATISTICS SUBJECTS FILES END*/}


          {/* IITJAM PHYSICS SUBJECTS FILES */}

          <Route path="/StudentFormandMail_IITJAM_PHYSICS/:mockId" element={<StudentFormandMail_IITJAM_PHYSICS />} />
          <Route path="/IITJAM_PHYSICS_General_Instructions/:mockId" element={<IITJAM_PHYSICS_General_Instructions />} />
          <Route path='/IIT_JAM_PHYSICS_Instructions/:mockId' element={<IIT_JAM_PHYSICS_Instructions />} />
          {/* IITJAM PHYSICS SUBJECTS FILES END*/}


          <Route path="/PG_GATE_Mock_Tests_static_page" element={<PG_GATE_Mock_Tests_static_page />} />

          <Route path="/PG_iit_jam_Mock_Tests_static_page" element={<PG_iit_jam_Mock_Tests_static_page />} />



          <Route path="/StudentFormandMailIITJAM/:mockId" element={<StudentFormandMailIITJAM />} />


          <Route path='/UgJEE_MandAMockTest' element={<JEE_MandAMockTest />} />
          <Route path="/UgNEET_MockTest" element={<NEET_MockTest />} />
          <Route path="/UgBITSAT_MockTest" element={<BITSAT_MockTest />} />
          <Route path="/UgVITEEE_MockTest" element={<VITEEE_MockTest />} />




          <Route path="/mocktest" element={<MocktestBitsat />} />
          <Route path="/Mocktest_1" element={<Mocktest_1 />} />

          {/* BITSAT MOCKTEST */}
          <Route path="/SubmitFormAndMail/:mockId" element={<SubmitFormAndMail />} />
          <Route path="/Genralinstructions/:examId/:mockTestId/:mockTestTypeId" element={<Genralinstructions />} />
          <Route path="/InstructionPage/:examId/:mockTestId/:mockTestTypeId" element={<InstructionPage />} />
          <Route path="/BITSATMocktest_1/:examId/:mockTestId/:mockTestTypeId" element={<BITSATMocktest_1 />} />



          {/* JEE MAINS & ADVANCE */}
          <Route path="/egradmocktest" element={<StudentFormandMailjee />} />
          <Route path="/Instructionjee/:examId/:mockTestId/:mockTestTypeId" element={<Instructionjee />} />
          <Route path="/JEE_MAINS_General_instructions/:examId/:mockTestId/:mockTestTypeId" element={<JEE_MAINS_General_instructions />} />
          <Route path="/Jee_mains_1/:examId/:mockTestId/:mockTestTypeId" element={<JeeMainsMocktest />} />
          <Route path="/Jee_mains_1" element={<Jee_mains_1 />} />


          {/*  jee Advance */}
          <Route path="/StudentFormandMailjeeAdvance/:mockId" element={<StudentFormandMailjeeAdvance />} />
          <Route path="/InstructionjeeAdvance/:mockId" element={<InstructionjeeAdvance />} />
          <Route path="/JEE_Advance_general_instructions/:mockId" element={<JEE_Advance_general_instructions />} />
          <Route path="/JeeAdvanceMocktest/:mockId" element={<JeeAdvanceMocktest />} />
          <Route path="/Jee_advance_1" element={<Jee_advance_1 />} />
          {/* END */}

          {/* NEET MOCK TEST  */}
          <Route path="/SubmitFormAndMailNEET/:mockId" element={<SubmitFormAndMailNEET />} />
          <Route path="/NEETInstructionPage/:examId/:mockTestId/:mockTestTypeId" element={<NEETInstructionPage />} />
          <Route path="/NEETGenralinstructions/:examId/:mockTestId/:mockTestTypeId" element={<NEETGenralinstructions />} />
          <Route path="/NEETMocktest_1/:examId/:mockTestId/:mockTestTypeId" element={<NEETMocktest_1 />} />
          <Route path="/NEETMockTest/:examId/:mockTestId/:mockTestTypeId" element={<NEETMockTest />} />

          {/* VITEE MOCK TEST */}
          <Route path="/VITEEESubmitFormandmail/:mockId" element={<VITEEESubmitFormandmail />} />
          <Route path="/VITEEInstructionsPage1/:mockId" element={<VITEEInstructionsPage1 />} />
          <Route path="/VITEEEGenralinstructions/:mockId" element={<VITEEEGenralinstructions />} />
          <Route path="/VITEEMocktest_1/:mockId" element={<VITEEMocktest_1 />} />



 {/* ======================================================== PG DOWNLOADS PAGE ============================================================================= */}
 <Route path="/pgdownload" element={<PG_DOWNLOAD />} />
          <Route path="/gatedownload" element={<GateDownloadPage />} />
          <Route path="/iitjamdownload" element={<IitjamDownloadPage />} />
          <Route path="/esedownload" element={<EseDownloadPage />} />
          <Route path="/tifrdownload" element={<TifrDownloadPage/>} />
          <Route path="/isrodownload" element={<IsroDownloadPage/>} />
          <Route path="/barcdownload" element={<BarcDownloadPage/>} />
          <Route path="/jestdownload" element={<JestDownloadPage/>} />


<Route path = "/New_UG_OTS_Home_Page" element = {<New_UG_OTS_Home_Page />} />



        </Routes>
        {/* <Footer /> */}
        {/* <LandingPage /> */}
        {/* <PracticesCode /> */}
      </Router>
    </State>
  );
};

export default App;
