import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';
import TermsandConditions from "../TermsandConditions.pdf";
import { IoIosArrowBack } from "react-icons/io";

const NEETGenralinstructions = () => {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const { examId, mockTestId, mockTestTypeId } = useParams();

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // Function to navigate to instructions page
  const handleClick = () => {
    navigate(`/NEETInstructionPage/${examId}/${mockTestId}/${mockTestTypeId}`);
  };

  // Function to handle the button click
  const handleBegin = () => {
    if (isChecked) {
      const url = `/NEETMockTest/${examId}/${mockTestId}/${mockTestTypeId}`;
      navigate(url, { state: { examId, mockTestId } });
    } else {
      alert("Please accept all the terms and conditions!");
    }
  };

  return (
    <div>
      <div className="remaining">
        <div className="bitsatMockHeader">
          <img
            src="https://www.egradtutor.in/static/media/egate%20logo%201.8b1eb31347d60945925f.png"
            alt="Egrad Tutor Logo"
          />
        </div>
        <h3 className='SubmitFormAndMail_h2'>GENERAL INSTRUCTIONS</h3>
      </div>

      <div className="Genralinstructions_container">
        <div className='Neet_general_instructions_sub_container'>
          <h3 className='NEW_SubmitFormAndMail_h2'>GENERAL INSTRUCTIONS</h3>
          <h2 className="NEW_ee_mains_general_instructions_sub_heading">
            The following are the instructions to be followed for this NEET Online Test:
          </h2>
          <ul className="Genralinstructions_list">
            <li>Duration of the Test: 3 hours 20 minutes (200 minutes)</li>
            <li>All the questions in this test are Multiple Choice Questions (MCQ) type.</li>
            <li>A total of 200 questions will be given out of which, you will have to answer 180 questions.</li>
            <li>Total marks for this test: 720 marks.</li>
            <li>4 marks will be awarded for each correct answer, -1 mark will be awarded for each wrong answer, zero marks will be awarded for unanswered questions.</li>
            <p style={{ fontWeight: "bold" }}>This test is divided into the following Sections:</p>
            <li><b>Physics Section A</b>: You need to answer 35 questions out of all 35 questions.</li>
            <li><b>Physics Section B</b>: You need to answer any 10 questions out of 15 questions.</li>
            <li><b>Chemistry Section A</b>: You need to answer 35 questions out of all 35 questions.</li>
            <li><b>Chemistry Section B</b>: You need to answer any 10 questions out of 15 questions.</li>
            <li><b>Botany Section A</b>: You need to answer 35 questions out of all 35 questions.</li>
            <li><b>Botany Section B</b>: You need to answer any 10 questions out of 15 questions.</li>
            <li><b>Zoology Section A</b>: You need to answer 35 questions out of all 35 questions.</li>
            <li><b>Zoology Section B</b>: You need to answer any 10 questions out of 15 questions.</li>
          </ul>
        </div>

        <div className='neet_container_top_space'>
          <div style={{ fontWeight: 900, color: "blue", marginLeft: "0.5rem", display: "flex", gap: "0.5rem" }}>
            <input type="checkbox" onChange={handleCheckboxChange} className="checkbox" />
            <span style={{ fontWeight: 500, color: "black" }}>
              I accept all the
              <a href={TermsandConditions} target="_blank" rel="noopener noreferrer" style={{ fontWeight: 900, color: "blue", marginLeft: "0.5rem" }}>
                terms & conditions.
              </a>
            </span>
          </div>

          <div className="intro_next_btn__Jee_Neet_container">
            <div>
              <button className="previous-button" onClick={handleClick}>
                <IoIosArrowBack /> Previous
              </button>
            </div>
            <button
              className='ug_mocktest_start_button'
              onClick={handleBegin}
              disabled={!isChecked}
              style={{
                backgroundColor: isChecked ? 'skyblue' : 'white',
                color: isChecked ? 'white' : 'black',
                border: '1px solid #76cbe6',
                cursor: isChecked ? 'pointer' : 'not-allowed'
              }}
              title={!isChecked ? 'Please accept the terms' : ''}
            >
              I am ready to begin <AiOutlineArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NEETGenralinstructions;
