// import React, { useState } from 'react';
// import '../SubmitFormAndMail.css';
// import logo from '../../egate logo 1.png';
// import { useNavigate, useParams } from 'react-router-dom';
// const StudentFormandMailGate = () => {
//   const navigate = useNavigate();
//   const { mockId } = useParams();
//   const [showAlert, setShowAlert] = useState(false);
//   const [formData, setFormData] = useState({
//     studentName: '',
//     contactNumber: '',
//     email: '',
//     // address: ''
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     // Create a FormData object from the form data
//     const data = new FormData(e.target);

//     fetch('https://formspree.io/f/mayrzyep', {
//         method: 'POST',
//         body: data,
//         headers: {
//             'Accept': 'application/json'
//         }
//     })
//     .then(response => {
//         if (response.ok) {
//             setShowAlert(true);

//             // Reset form
//             setFormData({
//                 studentName: '',
//                 contactNumber: '',
//                 email: '',
//                 // address: ''
//             });

//             // Close alert after 3 seconds
//             setTimeout(() => {
//               setShowAlert(false);
//               setTimeout(() => {
//                   window.location.href = '/iitjee_otc'; 
//               }, 500);
//           }, 3000);

//             const screenWidth = window.screen.width;
//             const screenHeight = window.screen.height;

//             // Open new window to InstructionPage
//             const newWinRef = window.open(`/PG_General_Instructions_Page/${mockId}`, 'InstructionPage', `width=${screenWidth},height=${screenHeight},fullscreen=yes`);

//             if (!newWinRef) {
//                 alert('Please allow popups for this website');
//             }
//         } else {
//             alert('There was an error submitting the form.');
//         }
//     })
//     .catch(error => {
//         alert('There was an error submitting the form.');
//         console.error('Form submission error:', error);
//     });
// };
  


//   return (
//     <div className="SubmitFormAndMail_container">
//       <div className="SubmitFormAndMail_header">
//         <img src={logo} alt="Logo" className="SubmitFormAndMail_logo" />
//         <h2>GATE MOCK TEST</h2>
//       </div>
//       <form onSubmit={handleSubmit}>
//         <div className="form-group">
//           <label className='SubmitFormAndMail_label'>
//             Student Name:
//             <input className='SubmitFormAndMail_input'
//               type="text"
//               name="studentName"
//               value={formData.studentName}
//               onChange={handleChange}
//               required
//             />
//           </label>
//         </div>
//         <div className="form-group">
//           <label className='SubmitFormAndMail_label'>
//             Contact Number:
//             <input className='SubmitFormAndMail_input'
//               type="tel"
//               name="contactNumber"
//               value={formData.contactNumber}
//               onChange={handleChange}
//               required
//             />
//           </label>
//         </div>
//         <div className="form-group">
//           <label className='SubmitFormAndMail_label'>
//             Email:
//             <input className='SubmitFormAndMail_input'
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               required
//             />
//           </label>
//         </div>
//         {/* <div className="form-group">
//           <label className='SubmitFormAndMail_label'>
//             Address:
//             <input className='SubmitFormAndMail_input'
//               type="text"
//               name="address"
//               value={formData.address}
//               onChange={handleChange}
//               required
//             />
//           </label>
//         </div> */}
//         <div className="form-group">
//         <button type="submit" className='SubmitFormAndMail_button'>Submit</button>
       
//           </div> 
//       </form>
//     </div>
//   );
// };

// export default StudentFormandMailGate;



import React, { useState } from "react";
import "../SubmitFormAndMail.css";
import logo from "../../egate logo 1.png";
import { useNavigate, useParams } from "react-router-dom";
import { Nav } from "../.././components/UG/Nav";
import { Link } from "react-router-dom";
import { IoMdHome } from "react-icons/io";
import Footer from "../../components/ug_homepage/components/Footer/Footer";

const examMockTests = {
  "GATE": ["Mock Test-1", "Mock Test-2", "Mock Test-3"],
  "IIT-JAM": ["Mock Test-1", "Mock Test-2", "Mock Test-3"],
};

const StudentFormandMailjee = () => {
  const navigate = useNavigate();
  const { mockId } = useParams();
  const [showAlert, setShowAlert] = useState(false);
  const [formData, setFormData] = useState({
    studentName: "",
    contactNumber: "",
    email: "",
    selectedExam: "",
    selectedMockTest: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "studentName") {
      const filteredValue = value.replace(/[^a-zA-Z\s/.]/g, ""); // Only letters and spaces
      setFormData({ ...formData, [name]: filteredValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a FormData object from the form data
    const data = new FormData(e.target);

    fetch("https://formspree.io/f/xwperaen", {
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          setShowAlert(true);

          // Reset form
          setFormData({
            studentName: "",
            contactNumber: "",
            email: "",
            selectedExam: "",
            selectedMockTest: "",
          });

          // Show "coming soon" popup
          setTimeout(() => {
            setShowAlert(false);
            // alert("We are coming soon!");
          }, 3000);
        } else {
          alert("There was an error submitting the form.");
        }
      })
      .catch((error) => {
        alert("There was an error submitting the form.");
        console.error("Form submission error:", error);
      });
  };

  return (
    <div>
      <div>
        <div className="ugexam_header">
          {Nav.map((NavData, index) => {
            return (
              <div className="header ug_exam_header" key={index}>
                <div className={NavData.logo_img_container}>
                  <Link to={"/"}>
                    <img src={NavData.logo} alt="" />
                  </Link>
                </div>

                <div className="exam_login_menu">
                  <li>
                    <Link
                      to="/home"
                      className={NavData.navlist}
                      id="exam_login_menu_home"
                    >
                      <IoMdHome /> {NavData.link1}
                    </Link>
                  </li>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="SubmitFormAndMail_container">
        <div className="SubmitFormAndMail_header1">
          <h2>MOCK TEST</h2>
        </div>
        <form onSubmit={handleSubmit} className="studentformmocktest">
          <div className="form-group">
            <label className="SubmitFormAndMail_label">Select Exam:</label>
            <select
              className="SubmitFormAndMail_input"
              name="selectedExam"
              value={formData.selectedExam}
              onChange={handleChange}
              required
            >
              <option value="">Select an Exam</option>
              {Object.keys(examMockTests).map((exam) => (
                <option key={exam} value={exam}>
                  {exam}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label className="SubmitFormAndMail_label">Select Mock Test:</label>
            <select
              className="SubmitFormAndMail_input"
              name="selectedMockTest"
              value={formData.selectedMockTest}
              onChange={handleChange}
              required
            >
              <option value="">Select a Mock Test</option>
              {formData.selectedExam &&
                examMockTests[formData.selectedExam].map((mockTest) => (
                  <option key={mockTest} value={mockTest}>
                    {mockTest}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group">
            <label className="SubmitFormAndMail_label">Student Name:</label>
            <input
              className="SubmitFormAndMail_input"
              type="text"
              name="studentName"
              placeholder="Enter Name"
              value={formData.studentName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label className="SubmitFormAndMail_label">Contact Number:</label>
            <input
              className="SubmitFormAndMail_input"
              type="number"
              placeholder="Enter Mobile No"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label className="SubmitFormAndMail_label">Email:</label>
            <input
              className="SubmitFormAndMail_input"
              type="email"
              placeholder="Enter E-mail"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group1">
            <button type="submit" className="SubmitFormAndMail_button">
              Submit
            </button>
          </div>
        </form>
      </div>

      <Footer />

      {/* Popup Modal */}
      {showAlert && (
        <div className="popup">
          <div className="popup-content">
            <h3>We are coming soon!</h3>
            <button onClick={() => setShowAlert(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentFormandMailjee;

