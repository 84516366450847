import React from 'react'

const EseImportantDates = () => {
  return (
    <div>
        <table className='examtbl'>
            <tr>
                <th>ESE(Preliminary) Exam Events</th>
                <th>Dates</th>
            </tr>
            <tr>
                <td>ESE 2024 Notification Release Date</td>
                <td>18<sup>th</sup> September, 2024</td>
            </tr>
            <tr>
                <td>Last date for receipt of Application</td>
                <td>08<sup>th</sup> October, 2024</td>
            </tr>
            <tr>
                <td>Date of Commencement of Examination</td>
                <td>09<sup>th</sup> February, 2025</td>
            </tr>
            <tr>
                <td>Duration of Examination</td>
                <td>One Day</td>
            </tr>
            <tr>
                <td>Engineering Services (Main) Examination, 2025</td>
                <td>22<sup>nds</sup> June, 2025</td>
            </tr>
        </table>
    </div>
  )
}

export default EseImportantDates