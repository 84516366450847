import React, { useState } from "react";
import "./styles/Publication_Page_Book_page.css";
import { AiFillStar } from "react-icons/ai";
import { BsStarHalf } from "react-icons/bs";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { DotSpinner } from "@uiball/loaders";
import { FaChevronDown } from "react-icons/fa";
import { HiXMark } from "react-icons/hi2";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { IoReload } from "react-icons/io5";
import {
  Publication_Page_Book_pageData,
  Publication_Page_Book_pageData1,
  Publication_Page_Book_pageData2,
} from "./Publication_PageData/Publication_Page_Book_page";

const Publication_Page_Book_page = () => {
  const [visibleCountUG, setVisibleCountUG] = useState(4);
  const [visibleCountPG, setVisibleCountPG] = useState(4);
  const [isLoadingUG, setIsLoadingUG] = useState(false);
  const [isLoadingPG, setIsLoadingPG] = useState(false);
    const [isOpenpublicationcaterogry, setIsOpenpublicationcaterogry] =
      useState(false);
const openpublicaationcaterogy = () => {
// setIsOpenpublicationcaterogry(!isOpenpublicationcaterogry)
setIsOpenpublicationcaterogry((prevState) => !prevState);
};

const Publication_Page_header_Book_drop_dnclose = () => {
setIsOpenpublicationcaterogry(!isOpenpublicationcaterogry)
};




  const handleViewMoreUG = () => {
    setIsLoadingUG(true);
    setTimeout(() => {
      setVisibleCountUG((prevCount) => prevCount + 4);
      setIsLoadingUG(false);
    }, 1000); // Simulating a delay for the loading animation
  };

  const handleViewMorePG = () => {
    setIsLoadingPG(true);
    setTimeout(() => {
      setVisibleCountPG((prevCount) => prevCount + 4);
      setIsLoadingPG(false);
    }, 1000); // Simulating a delay for the loading animation
  };

  return (
    <div>
      {/* <div className="Publication_Page_Book_page_conatiner">
        <div className="Publication_Page_Book_page_subconatiner">
          <h3>UG</h3>
          <div className="Publication_Page_Book_page_rowconatiner">
            {Publication_Page_Book_pageData1.slice(0, visibleCountUG).map(
              (Book, id) => (
                <div key={id} className="Publication_Page_Book_page_bookcard">
                  <div className="Publication_Page_Book_page_bookcardimg">
                    <img src={Book.BOOKImg} alt="" />
                  </div>
                  <div className="Publication_Page_Book_page_bookcard_detiles">
                    <div>
                      <h6>{Book.BOOKtitle}</h6>
                      <p>ISBN : {Book.BOOKISBN}</p>
                    </div>

                    <div className="Publication_Page_Book_page_bookcard_detilesstars">
                      <span>
                        <AiFillStar />
                      </span>
                      <span>
                        <AiFillStar />
                      </span>
                      <span>
                        <AiFillStar />
                      </span>
                      <span>
                        <BsStarHalf />
                      </span>
                    </div>
                    <div className="Publication_Page_Book_page_bookcard_detiles_B_btn_d_btn">
                      <div className="Publication_Page_Book_page_bookcard_detiles_B_btn_d_btn_sty">
                        <span>
                          <FaIndianRupeeSign />
                          {Book.BOOKprice}
                        </span>
                        <button>Buy Now</button>
                      </div>
                      <button>Details</button>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          {isLoadingUG ? (
            <div className="Publication_Page_Book_page_loading">
              <div className="Publication_Page_Book_page_loading_loader">
                <DotSpinner size={50} speed={0.5} color="black" />
              </div>
            </div>
          ) : (
            visibleCountUG < Publication_Page_Book_pageData1.length && (
              <div className="Publication_Page_Book_pageData_viewmore">
                <button onClick={handleViewMoreUG}>View More</button>
              </div>
            )
          )}
        </div>
        <div className="Publication_Page_Book_page_subconatiner">
          <h3>PG</h3>
          <div className="Publication_Page_Book_page_rowconatiner">
            {Publication_Page_Book_pageData2.slice(0, visibleCountPG).map(
              (Book, id) => (
                <div key={id} className="Publication_Page_Book_page_bookcard">
                  <div className="Publication_Page_Book_page_bookcardimg">
                    <img src={Book.BOOKImg} alt="" />
                  </div>
                  <div className="Publication_Page_Book_page_bookcard_detiles">
                    <div>
                      <h6>{Book.BOOKtitle}</h6>
                      <p>ISBN : {Book.BOOKISBN}</p>
                    </div>

                    <div className="Publication_Page_Book_page_bookcard_detilesstars">
                      <span>
                        <AiFillStar />
                      </span>
                      <span>
                        <AiFillStar />
                      </span>
                      <span>
                        <AiFillStar />
                      </span>
                      <span>
                        <BsStarHalf />
                      </span>
                    </div>
                    <div className="Publication_Page_Book_page_bookcard_detiles_B_btn_d_btn">
                      <div className="Publication_Page_Book_page_bookcard_detiles_B_btn_d_btn_sty">
                        <span>
                          <FaIndianRupeeSign />
                          {Book.BOOKprice}
                        </span>
                        <button>Buy Now</button>
                      </div>
                      <button>Details</button>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          {isLoadingPG ? (
            <div className="Publication_Page_Book_page_loading">
              <div className="Publication_Page_Book_page_loading_loader">
                <DotSpinner size={50} speed={0.5} color="black" />
              </div>
            </div>
          ) : (
            visibleCountPG < Publication_Page_Book_pageData2.length && (
              <div className="Publication_Page_Book_pageData_viewmore">
                <button onClick={handleViewMorePG}>View More</button>
              </div>
            )
          )}
        </div>
      </div> */}
      <div className="Publication_Page_Book_page_mianconatiner">
        <h3>Latest Book</h3>
        <div className="Publication_Page_Book_page_conatiner">
          <div
            // className="Publication_Page_Book_page_leftconatiner "
            className={
              isOpenpublicationcaterogry
                ? "Publication_Page_Book_page_leftconatiner Publication_Page_Book_page_leftconatinermobile"
                : "Publication_Page_Book_page_leftconatiner"
            }
          >
            <div className="Publication_Page_Book_page_leftconatinerlist">
              <h4>categories</h4>
              <span
                className="Publication_Page_header_Book_drop_dnclosepublication"
                onClick={Publication_Page_header_Book_drop_dnclose}
              >
                <HiXMark />
              </span>
              <ul>
                <li>
                  category
                  <span>
                    <FaChevronDown />
                  </span>
                </li>
                <li>
                  category
                  <span>
                    <FaChevronDown />
                  </span>
                </li>
                <li>
                  category
                  <span>
                    <FaChevronDown />
                  </span>
                </li>
                <li>
                  category
                  <span>
                    <FaChevronDown />
                  </span>
                </li>
                <li>
                  category
                  <span>
                    <FaChevronDown />
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="Publication_Page_Book_page_rightconatiner">
            <div className="Publication_Page_Book_page_subconatiner">
              <div className="Publication_Page_Book_page_headerconatiner">
                <span
                  className="openpublicaationcaterogy"
                  onClick={openpublicaationcaterogy}
                >
                  <AiOutlineMenuUnfold />
                </span>{" "}
                <h3>Books</h3>
                <div className="Publication_Page_Book_page_inputconatiner">
                  <input type="text" />
                </div>
              </div>
              <div className="Publication_Page_Book_page_rowconatiner">
                {Publication_Page_Book_pageData1.slice(0, visibleCountUG).map(
                  (Book, id) => (
                    <div
                      key={id}
                      className="Publication_Page_Book_page_bookcard"
                    >
                      <div className="Publication_Page_Book_page_bookcardcomingsoon">
                        coming soon
                      </div>
                      <div className="Publication_Page_Book_page_bookcardimg">
                        <img src={Book.BOOKImg} alt="" />
                      </div>
                      <div className="Publication_Page_Book_page_bookcard_detiles">
                        <div>
                          <h6>{Book.BOOKtitle}</h6>
                          <p>ISBN : {Book.BOOKISBN}</p>
                        </div>

                        <div className="Publication_Page_Book_page_bookcard_detilesstars">
                          <span>
                            <AiFillStar />
                          </span>
                          <span>
                            <AiFillStar />
                          </span>
                          <span>
                            <AiFillStar />
                          </span>
                          <span>
                            <BsStarHalf />
                          </span>
                        </div>
                        <div className="Publication_Page_Book_page_bookcard_detiles_B_btn_d_btn">
                          <div className="Publication_Page_Book_page_bookcard_detiles_B_btn_d_btn_sty">
                            <span>
                              <FaIndianRupeeSign />
                              {Book.BOOKprice}
                            </span>
                            <button>Buy Now</button>
                          </div>
                          <button>Details</button>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              {isLoadingUG ? (
                <div className="Publication_Page_Book_page_loading">
                  <div className="Publication_Page_Book_page_loading_loader">
                    <DotSpinner size={50} speed={0.5} color="black" />
                  </div>
                </div>
              ) : (
                visibleCountUG < Publication_Page_Book_pageData1.length && (
                  <div className="Publication_Page_Book_pageData_viewmore">
                    <button onClick={handleViewMoreUG}>
                      View More <IoReload />
                    </button>
                  </div>
                )
              )}
            </div>
            <div className="Publication_Page_Book_page_subconatiner">
              <div className="Publication_Page_Book_page_rowconatiner">
                {Publication_Page_Book_pageData2.slice(0, visibleCountPG).map(
                  (Book, id) => (
                    <div
                      key={id}
                      className="Publication_Page_Book_page_bookcard"
                    >
                      <div className="Publication_Page_Book_page_bookcardcomingsoon">
                        coming soon
                      </div>
                      <div className="Publication_Page_Book_page_bookcardimg">
                        <img src={Book.BOOKImg} alt="" />
                      </div>
                      <div className="Publication_Page_Book_page_bookcard_detiles">
                        <div>
                          <h6>{Book.BOOKtitle}</h6>
                          <p>ISBN : {Book.BOOKISBN}</p>
                        </div>

                        <div className="Publication_Page_Book_page_bookcard_detilesstars">
                          <span>
                            <AiFillStar />
                          </span>
                          <span>
                            <AiFillStar />
                          </span>
                          <span>
                            <AiFillStar />
                          </span>
                          <span>
                            <BsStarHalf />
                          </span>
                        </div>
                        <div className="Publication_Page_Book_page_bookcard_detiles_B_btn_d_btn">
                          <div className="Publication_Page_Book_page_bookcard_detiles_B_btn_d_btn_sty">
                            <span>
                              <FaIndianRupeeSign />
                              {Book.BOOKprice}
                            </span>
                            <button>Buy Now</button>
                          </div>
                          <button>Details</button>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              {isLoadingPG ? (
                <div className="Publication_Page_Book_page_loading">
                  <div className="Publication_Page_Book_page_loading_loader">
                    <DotSpinner size={50} speed={0.5} color="black" />
                  </div>
                </div>
              ) : (
                visibleCountPG < Publication_Page_Book_pageData2.length && (
                  <div className="Publication_Page_Book_pageData_viewmore">
                    <button onClick={handleViewMorePG}>
                      View More <IoReload />
                    </button>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Publication_Page_Book_page;
