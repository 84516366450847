// import React, { useState } from "react";
// import "../SubmitFormAndMail.css";
// import '../ResponsiveForMockExams.css';
// import { useNavigate } from "react-router-dom";
// import { Nav } from "../../components/UG/Nav";
// import { Link } from "react-router-dom";
// import { IoMdHome } from "react-icons/io";
// import Footer from "../../components/ug_homepage/components/Footer/Footer";
// import banners_image_one from '../../components/UG/Images/web-banners-14.jpg';
// // Mock Data for Exams and Mock Tests
// const examMockTests = {
//   "JEE Mains": {
//     "id": 101,
//     "subjects": {
//       "subject_One": {
//         "id": 201,
//         "topics": {
//           "topic_One": {
//             "id":301,
//             "mockTests": [
//               { "id": 401, "name": "Mock Test-1" },
//               { "id": 402, "name": "Mock Test-2" },
//               { "id": 403, "name": "Mock Test-3" }
//             ]
//           },
//           "topic_Two": {
//             "id": 302,
//             "mockTests": [
//               { "id": 404, "name": "Mock Test-4" },
//               { "id": 405, "name": "Mock Test-5" },
//               { "id": 406, "name": "Mock Test-6" }
//             ]
//           }
//         }
//       },
//       "subject_Two": {
//         "id": 202,
//         "topics": {
//           "topic_One": {
//             "id":303 ,
//             "mockTests": [
//               { "id": 407, "name": "Mock Test-1" },
//               { "id": 408, "name": "Mock Test-2" },
//               { "id": 409, "name": "Mock Test-3" }
//             ]
//           },
//           "topic2": {
//             "id": 304,
//             "mockTests": [
//               { "id": 410, "name": "Mock Test-4" },
//               { "id": 411, "name": "Mock Test-5" },
//               { "id": 412, "name": "Mock Test-6" }
//             ]
//           }
//         }
//       },
//       "subject_Three": {
//         "id": 203,
//         "topics": {
//           "topic_One": {
//             "id": 305,
//             "mockTests": [
//               { "id": 413, "name": "Mock Test-1" },
//               { "id": 414, "name": "Mock Test-2" },
//               { "id": 415, "name": "Mock Test-3" }
//             ]
//           },
//           "topic2": {
//             "id":306,
//             "mockTests": [
//               { "id": 416, "name": "Mock Test-4" },
//               { "id": 417, "name": "Mock Test-5" },
//               { "id": 418, "name": "Mock Test-6" }
//             ]
//           }
//         }
//       },
//       "Full Mock Test": {
//         "id": 106,
//         "mockTests": [
//           { "id": 501, "name": "Mock Test-1" },
//           { "id": 502, "name": "Mock Test-2" },
//           { "id": 503, "name": "Mock Test-3" }
//         ]
//       }
//     },
//   },

//   "JEE ADVANCED": {
//     "id": 102,
//     "subjects": {
//       "subject_One": {
//         "id": 204,
//         "topics": {
//           "topic_One": {
//             "id":307,
//             "mockTests": [
//               { "id": 419, "name": "Mock Test-1" },
//               { "id": 420, "name": "Mock Test-2" },
//               { "id": 421, "name": "Mock Test-3" }
//             ]
//           },
//           "topic2": {
//             "id": 308,
//             "mockTests": [
//               { "id": 422, "name": "Mock Test-4" },
//               { "id": 423, "name": "Mock Test-5" },
//               { "id": 424, "name": "Mock Test-6" }
//             ]
//           }
//         }
//       },
//       "subject_Two": {
//         "id": 205,
//         "topics": {
//           "topic_One": {
//             "id":309 ,
//             "mockTests": [
//               { "id": 425, "name": "Mock Test-1" },
//               { "id": 426, "name": "Mock Test-2" },
//               { "id": 427, "name": "Mock Test-3" }
//             ]
//           },
//           "topic2": {
//             "id": 310,
//             "mockTests": [
//               { "id": 428, "name": "Mock Test-4" },
//               { "id": 429, "name": "Mock Test-5" },
//               { "id": 430, "name": "Mock Test-6" }
//             ]
//           }
//         }
//       },
//       "subject_Three": {
//         "id": 206,
//         "topics": {
//           "topic_One": {
//             "id": 311,
//             "mockTests": [
//               { "id": 431, "name": "Mock Test-1" },
//               { "id": 432, "name": "Mock Test-2" },
//               { "id": 433, "name": "Mock Test-3" }
//             ]
//           },
//           "topic2": {
//             "id":312,
//             "mockTests": [
//               { "id": 434, "name": "Mock Test-4" },
//               { "id": 435, "name": "Mock Test-5" },
//               { "id": 436, "name": "Mock Test-6" }
//             ]
//           }
//         }
//       }
//     },
//   },

//   "NEET": {
//     "id": 103,
//     "subjects": {
//       "subject_One": {
//         "id": 207,
//         "topics": {
//           "topic_One": {
//             "id":313,
//             "mockTests": [
//               { "id": 437, "name": "Mock Test-1" },
//               { "id": 438, "name": "Mock Test-2" },
//               { "id": 439, "name": "Mock Test-3" }
//             ]
//           },
//           "topic2": {
//             "id": 314,
//             "mockTests": [
//               { "id": 440, "name": "Mock Test-4" },
//               { "id": 441, "name": "Mock Test-5" },
//               { "id": 442, "name": "Mock Test-6" }
//             ]
//           }
//         }
//       },
//       "subject_Two": {
//         "id": 208,
//         "topics": {
//           "topic_One": {
//             "id":315 ,
//             "mockTests": [
//               { "id": 443, "name": "Mock Test-1" },
//               { "id": 444, "name": "Mock Test-2" },
//               { "id": 445, "name": "Mock Test-3" }
//             ]
//           },
//           "topic2": {
//             "id": 316,
//             "mockTests": [
//               { "id": 446, "name": "Mock Test-4" },
//               { "id": 447, "name": "Mock Test-5" },
//               { "id": 448, "name": "Mock Test-6" }
//             ]
//           }
//         }
//       },
//       "subject_Three": {
//         "id": 209,
//         "topics": {
//           "topic_One": {
//             "id": 317,
//             "mockTests": [
//               { "id": 449, "name": "Mock Test-1" },
//               { "id": 450, "name": "Mock Test-2" },
//               { "id": 451, "name": "Mock Test-3" }
//             ]
//           },
//           "topic2": {
//             "id":318,
//             "mockTests": [
//               { "id": 452, "name": "Mock Test-4" },
//               { "id": 453, "name": "Mock Test-5" },
//               { "id": 454, "name": "Mock Test-6" }
//             ]
//           }
//         }
//       }
//     },
//   },

//   "BITSAT": {
//     "id": 104,
//     "subjects": {
//       "subject_One": {
//         "id": 210,
//         "topics": {
//           "topic_One": {
//             "id":319,
//             "mockTests": [
//               { "id": 455, "name": "Mock Test-1" },
//               { "id": 456, "name": "Mock Test-2" },
//               { "id": 457, "name": "Mock Test-3" }
//             ]
//           },
//           "topic2": {
//             "id": 320,
//             "mockTests": [
//               { "id": 458, "name": "Mock Test-4" },
//               { "id": 459, "name": "Mock Test-5" },
//               { "id": 460, "name": "Mock Test-6" }
//             ]
//           }
//         }
//       },
//       "subject_Two": {
//         "id": 211,
//         "topics": {
//           "topic_One": {
//             "id":321 ,
//             "mockTests": [
//               { "id": 461, "name": "Mock Test-1" },
//               { "id": 462, "name": "Mock Test-2" },
//               { "id": 463, "name": "Mock Test-3" }
//             ]
//           },
//           "topic2": {
//             "id": 322,
//             "mockTests": [
//               { "id": 464, "name": "Mock Test-4" },
//               { "id": 465, "name": "Mock Test-5" },
//               { "id": 466, "name": "Mock Test-6" }
//             ]
//           }
//         }
//       },
//       "subject_Three": {
//         "id": 212,
//         "topics": {
//           "topic_One": {
//             "id": 323,
//             "mockTests": [
//               { "id": 467, "name": "Mock Test-1" },
//               { "id": 468, "name": "Mock Test-2" },
//               { "id": 469, "name": "Mock Test-3" }
//             ]
//           },
//           "topic2": {
//             "id":324,
//             "mockTests": [
//               { "id": 470, "name": "Mock Test-4" },
//               { "id": 471, "name": "Mock Test-5" },
//               { "id": 472, "name": "Mock Test-6" }
//             ]
//           }
//         }
//       }
//     },
//   },

//   "VITEEE": {
//     "id": 105,
//     "subjects": {
//       "subject_One": {
//         "id": 213,
//         "topics": {
//           "topic_One": {
//             "id":325,
//             "mockTests": [
//               { "id": 473, "name": "Mock Test-1" },
//               { "id": 474, "name": "Mock Test-2" },
//               { "id": 475, "name": "Mock Test-3" }
//             ]
//           },
//           "topic2": {
//             "id": 326,
//             "mockTests": [
//               { "id": 476, "name": "Mock Test-4" },
//               { "id": 477, "name": "Mock Test-5" },
//               { "id": 478, "name": "Mock Test-6" }
//             ]
//           }
//         }
//       },
//       "subject_Two": {
//         "id": 214,
//         "topics": {
//           "topic_One": {
//             "id":327 ,
//             "mockTests": [
//               { "id": 479, "name": "Mock Test-1" },
//               { "id": 480, "name": "Mock Test-2" },
//               { "id": 481, "name": "Mock Test-3" }
//             ]
//           },
//           "topic2": {
//             "id": 328,
//             "mockTests": [
//               { "id": 482, "name": "Mock Test-4" },
//               { "id": 483, "name": "Mock Test-5" },
//               { "id": 484, "name": "Mock Test-6" }
//             ]
//           }
//         }
//       },
//       "subject_Three": {
//         "id": 215,
//         "topics": {
//           "topic_One": {
//             "id": 329,
//             "mockTests": [
//               { "id": 485, "name": "Mock Test-1" },
//               { "id": 486, "name": "Mock Test-2" },
//               { "id": 487, "name": "Mock Test-3" }
//             ]
//           },
//           "topic2": {
//             "id":330,
//             "mockTests": [
//               { "id": 488, "name": "Mock Test-4" },
//               { "id": 489, "name": "Mock Test-5" },
//               { "id": 460, "name": "Mock Test-6" }
//             ]
//           }
//         }
//       }
//     },
//   }

// };

// const New_MockTestform_file_Topic_wise = () => {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     studentName: "",
//     contactNumber: "",
//     email: "",
//     selectedExam: "",
//     selectedSubject: "",
//     selectedTopic: "",
//     selectedMockTest: "",
//   });

//   const [exams, setExams] = useState(Object.keys(examMockTests));
//   const [subjects, setSubjects] = useState([]);
//   const [topics, setTopics] = useState([]);
//   const [mockTests, setMockTests] = useState([]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });

//     if (name === "selectedExam") {
//       const exam = examMockTests[value];
//       setSubjects(Object.keys(exam.subjects));
//       setTopics([]);
//       setMockTests([]);
//     } else if (name === "selectedSubject") {
//       const exam = examMockTests[formData.selectedExam];
//       const subject = exam.subjects[value];
//       setTopics(Object.keys(subject.topics));
//       setMockTests([]);
//     } else if (name === "selectedTopic") {
//       const exam = examMockTests[formData.selectedExam];
//       const subject = exam.subjects[formData.selectedSubject];
//       const topic = subject.topics[value];
//       setMockTests(topic.mockTests);
//     }
//   };

//   // const handleSubmit = (e) => {
//   //   e.preventDefault();

//   //   const { selectedExam, selectedMockTest } = formData;
//   //   const exam = examMockTests[selectedExam];
//   //   const examId = exam ? exam.id : null;
//   //   const mockTest = exam
//   //     ? Object.values(exam.subjects).flatMap(subject =>
//   //         Object.values(subject.topics).flatMap(topic => topic.mockTests)
//   //       ).find(mockTest => mockTest.name === selectedMockTest)
//   //     : null;
//   //   const mockTestId = mockTest ? mockTest.id : null;

//   //   console.log("Form Data on Submit:", formData);
//   //   console.log("Selected Exam ID:", examId);
//   //   console.log("Selected Mock Test ID:", mockTestId);
//   //   console.log("Selected Mock Test ID:", mockTestId);

//   //   console.log("Selected Mock Test ID:", mockTestId);

//   //   const data = new FormData(e.target);

//   //   fetch("https://formspree.io/f/xwperaen", {
//   //     method: "POST",
//   //     body: data,
//   //     headers: {
//   //       Accept: "application/json",
//   //     },
//   //   })
//   //     .then((response) => {
//   //       if (response.ok) {
//   //         console.log("Form submitted successfully!");

//   //         setFormData({
//   //           studentName: "",
//   //           contactNumber: "",
//   //           email: "",
//   //           selectedExam: "",
//   //           selectedSubject: "",
//   //           selectedTopic: "",
//   //           selectedMockTest: "",
//   //         });

//   //         let redirectUrl = null;
//   //         if (examId === 101) {
//   //           if ([401, 402, 403,404,405,406,407,408,409,410,411,].includes(mockTestId)) {
//   //             redirectUrl = `/Instructionjee`;
//   //           }
//   //         } else if (examId === 102) {
//   //           if ([201, 202, 203].includes(mockTestId)) {
//   //             redirectUrl = `/InstructionjeeAdvance`;
//   //           }
//   //         } else if (examId === 103) {
//   //           if ([301, 302, 303].includes(mockTestId)) {
//   //             redirectUrl = `/NEETInstructionPage`;
//   //           }
//   //         } else if (examId === 104) {
//   //           if ([401, 402, 403].includes(mockTestId)) {
//   //             redirectUrl = `/InstructionPage`;
//   //           }
//   //         } else if (examId === 105) {
//   //           if ([501, 502, 503].includes(mockTestId)) {
//   //             redirectUrl = `/VITEEInstructionPage1`;
//   //           }
//   //         } else {
//   //           console.error("No valid examId found.");
//   //         }

//   //         if (redirectUrl) {
//   //           window.open(
//   //             redirectUrl,
//   //             "_blank",
//   //             `width=${window.screen.width},height=${window.screen.height},fullscreen=yes`
//   //           );
//   //         }
//   //       } else {
//   //         console.error("There was an error submitting the form.");
//   //       }
//   //     })
//   //     .catch((error) => {
//   //       console.error("Form submission error:", error);
//   //     });
//   // };
//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const { selectedExam, selectedSubject, selectedTopic, selectedMockTest } = formData;
//     const exam = examMockTests[selectedExam];
//     const examId = exam ? exam.id : null;
//     const subject = exam ? exam.subjects[selectedSubject] : null;
//     const subjectId = subject ? subject.id : null;
//     const topic = subject ? subject.topics[selectedTopic] : null;
//     const topicId = topic ? topic.id : null;
//     const mockTest = exam
//       ? Object.values(exam.subjects).flatMap(subject =>
//           Object.values(subject.topics).flatMap(topic => topic.mockTests)
//         ).find(mockTest => mockTest.name === selectedMockTest)
//       : null;
//     const mockTestId = mockTest ? mockTest.id : null;

//     console.log("Form Data on Submit:", formData);
//     console.log("Selected Exam ID:", examId);
//     console.log("Selected Subject ID:", subjectId);
//     console.log("Selected Topic ID:", topicId);
//     console.log("Selected Mock Test ID:", mockTestId);

//     // Determine redirect URL based on the conditions
//     let redirectUrl = null;

//     if (examId === 101) {
//       if ([201, 202, 203].includes(subjectId) &&
//           [301, 302,303,304,305,306].includes(topicId) &&
//           mockTestId >= 401 && mockTestId <= 460) {
//         redirectUrl = `/Instructionjee/${examId}/${subjectId}/${topicId}/${mockTestId}`;
//       }
//     } else if (examId === 102) {
//       if ([201, 202, 203].includes(subjectId) &&
//           [301, 302].includes(topicId) &&
//           mockTestId >= 401 && mockTestId <= 450) {
//         redirectUrl = `/InstructionjeeAdvance`;
//       }
//     } else if (examId === 103) {
//       if ([201, 202, 203].includes(subjectId) &&
//           [301, 302].includes(topicId) &&
//           mockTestId >= 401 && mockTestId <= 450) {
//         redirectUrl = `/NEETInstructionPage`;
//       }
//     } else if (examId === 104) {
//       if ([201, 202, 203].includes(subjectId) &&
//           [301, 302].includes(topicId) &&
//           mockTestId >= 401 && mockTestId <= 450) {
//         redirectUrl = `/InstructionPage`;
//       }
//     } else if (examId === 105) {
//       if ([201, 202, 203].includes(subjectId) &&
//           [301, 302].includes(topicId) &&
//           mockTestId >= 401 && mockTestId <= 450) {
//         redirectUrl = `/VITEEInstructionPage1`;
//       } else if (examId === 106) {
//         if ([501, 502, 503].includes(mockTestId)) {
//         redirectUrl = `/InstructionPage`;
//         // Your code to execute when mockTestId is in the list
//         }
//     }

//     }
//     else {

//       console.error("No valid examId found.");
//     }

//     if (redirectUrl) {
//       window.open(
//         redirectUrl,
//         "_blank",
//         `width=${window.screen.width},height=${window.screen.height},fullscreen=yes`
//       );
//     } else {
//       console.error("No valid redirect URL found.");
//     }

//     // Optionally reset the form data here if needed
//     setFormData({
//       studentName: "",
//       contactNumber: "",
//       email: "",
//       selectedExam: "",
//       selectedSubject: "",
//       selectedTopic: "",
//       selectedMockTest: "",
//     });

//     // Send the form data to Formspree
//     const data = new FormData(e.target);

//     fetch("https://formspree.io/f/xwperaen", {
//       method: "POST",
//       body: data,
//       headers: {
//         Accept: "application/json",
//       },
//     })
//       .then((response) => {
//         if (response.ok) {
//           console.log("Form submitted successfully!");
//         } else {
//           console.error("There was an error submitting the form.");
//         }
//       })
//       .catch((error) => {
//         console.error("Form submission error:", error);
//       });
//   };

//   return (
//     <div>
//       <div className="mock_test_header_container">
//         <div className="header ug_exam_header">
//           <div className="logo_img_container">
//             <Link to={"/"}>
//               <img src={Nav[0].logo} alt="" />
//             </Link>
//           </div>

//           <div className="exam_login_menu">
//             <li>
//               <Link to="/home" className="navlist" id="exam_login_menu_home">
//                 <IoMdHome /> Home
//               </Link>
//             </li>
//           </div>
//         </div>
//       </div>
//       <div className="Mock_test_form_and_banners_container">
//         <div className="Banner_container">
//           <img src={banners_image_one} alt="Banner" />
//         </div>

//         <div className="SubmitFormAndMail_container">
//           <div className="SubmitFormAndMail_header1">
//             <h2>MOCK TEST</h2>
//             <div className="Mock_test_header_item">
//               <img src={Nav[0].logo} alt="Logo" />
//             </div>
//           </div>

//           <form onSubmit={handleSubmit} className="studentformmocktest">
//             <div className="form-group">
//               <label>
//                 Exam:
//                 <select name="selectedExam" value={formData.selectedExam} onChange={handleChange}>
//                   <option value="">Select Exam</option>
//                   {exams.map((exam) => (
//                     <option key={examMockTests[exam].id} value={exam}>
//                       {exam}
//                     </option>
//                   ))}
//                 </select>
//               </label>

//               {formData.selectedExam && (
//                 <label>
//                   Subject:
//                   <select name="selectedSubject" value={formData.selectedSubject} onChange={handleChange}>
//                     <option value="">Select Subject</option>
//                     {subjects.map((subject) => (
//                       <option key={examMockTests[formData.selectedExam].subjects[subject].id} value={subject}>
//                         {subject}
//                       </option>
//                     ))}
//                   </select>
//                 </label>
//               )}

//               {formData.selectedSubject && (
//                 <label>
//                   Topic:
//                   <select name="selectedTopic" value={formData.selectedTopic} onChange={handleChange}>
//                     <option value="">Select Topic</option>
//                     {topics.map((topic) => (
//                       <option key={examMockTests[formData.selectedExam].subjects[formData.selectedSubject].topics[topic].id} value={topic}>
//                         {topic}
//                       </option>
//                     ))}
//                   </select>
//                 </label>
//               )}

//               {formData.selectedTopic && (
//                 <label>
//                   Mock Test:
//                   <select name="selectedMockTest" value={formData.selectedMockTest} onChange={handleChange}>
//                     <option value="">Select Mock Test</option>
//                     {mockTests.map((mockTest) => (
//                       <option key={mockTest.id} value={mockTest.name}>
//                         {mockTest.name}
//                       </option>
//                     ))}
//                   </select>
//                 </label>
//               )}

//               <label>
//                 Student Name:
//                 <input
//                   type="text"
//                   name="studentName"
//                   value={formData.studentName}
//                   onChange={handleChange}
//                 />
//               </label>
//               <label>
//                 Contact Number:
//                 <input
//                   type="text"
//                   name="contactNumber"
//                   value={formData.contactNumber}
//                   onChange={handleChange}
//                 />
//               </label>
//               <label>
//                 Email:
//                 <input
//                   type="email"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                 />
//               </label>
//             </div>

//             <button type="submit">Submit</button>
//           </form>
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default New_MockTestform_file_Topic_wise;

import { IoMdHome } from "react-icons/io";
import banners_image_one from "../../components/UG/Images/banners_image_one.png";
import { React, useState, useEffect } from "react";
import { Nav } from "../../components/UG/Nav";
import { Link } from "react-router-dom";
import "../SubmitFormAndMail.css";

// import Footer from "../../components/ug_homepage/components/Footer/Footer";
import Footer2 from "../../components/UG/Footer2";
import Mock_Test_miniBanner_Two from "../../components/UG/Images/Mock_Test_miniBanner_Two.jpeg";
import mobile_view_image_one from "../../components/UG/Images/Neet_Chemistry_image_Two.jpeg";
import mobile_view_image_Two from "../../components/UG/Images/Neet_main_image_one.jpeg";

const examMockTests = {
  "JEE Mains": {
    id: 101,
    "MOCK TEST TYPE": {
      "FULL MOCK TEST": {
        id: 201,
        mockTests: [
          { id: 301, name: "Mock Test-1" },
          { id: 302, name: "Mock Test-2" },
          { id: 303, name: "Mock Test-3" },
        ],
      },
      // SUBJECT_WISE_MOCK_TEST: {
      //   id: 202,
      //   mockTests: [
      //     { id: 304, name: "Physics Mock Test-1" },
      //     { id: 305, name: "Physics Mock Test-2" },
      //     { id: 306, name: "Physics Mock Test-3" },
      //     { id: 307, name: "Chemistry Mock Test-1" },
      //     { id: 308, name: "Chemistry Mock Test-2" },
      //     { id: 309, name: "Chemistry Mock Test-3" },
      //     { id: 310, name: "Maths Mock Test-1" },
      //     { id: 311, name: "Maths Mock Test-2" },
      //     { id: 312, name: "Maths Mock Test-3" },
      //   ],
      // },
      // TOPIC_WISE_MOCKTEST: {
      //   id: 203,
      //   mockTests: [
      //     { id: 313, name: "Physics Topic1 Mock Test" },
      //     { id: 314, name: "Physics Topic2 Mock Test" },
      //     { id: 315, name: "Physics Topic3 Mock Test" },
      //     { id: 316, name: "Chemistry Topic1 Mock Test" },
      //     { id: 317, name: "Chemistry Topic2 Mock Test" },
      //     { id: 318, name: "Chemistry Topic3 Mock Test" },
      //     { id: 319, name: "Maths Topic1 Mock Test" },
      //     { id: 320, name: "Maths Topic2 Mock Test" },
      //     { id: 321, name: "Maths Topic3 Mock Test" },
      //   ],
      // },
    },
  },

  // "JEE ADVANCED": {
  //   id: 102,
  //   "MOCK TEST TYPE": {
  //     "FULL MOCK TEST": {
  //       id: 204,
  //       mockTests: [
  //         { id: 401, name: "Mock Test-1" },
  //         { id: 402, name: "Mock Test-2" },
  //         { id: 403, name: "Mock Test-3" },
  //       ],
  //     },
  //     SUBJECT_WISE_MOCK_TEST: {
  //       id: 205,
  //       mockTests: [
  //         { id: 404, name: "Physics Mock Test-1" },
  //         { id: 405, name: "Physics Test-2" },
  //         { id: 406, name: "Physics Test-3" },
  //         { id: 407, name: "Chemistry Mock Test-1" },
  //         { id: 408, name: "Chemistry Mock Test-2" },
  //         { id: 409, name: "Chemistry Mock Test-3" },
  //         { id: 410, name: "Maths Mock Test-1" },
  //         { id: 411, name: "Maths Mock Test-2" },
  //         { id: 412, name: "Maths Mock Test-3" },
  //       ],
  //     },
  //     TOPIC_WISE_MOCKTEST: {
  //       id: 206,
  //       mockTests: [
  //         { id: 413, name: "Physics Topic1 Mock Test" },
  //         { id: 414, name: "Physics Topic2 Mock Test" },
  //         { id: 415, name: "Physics Topic3 Mock Test" },
  //         { id: 416, name: "Chemistry Topic1 Mock Test" },
  //         { id: 417, name: "Chemistry Topic2 Mock Test" },
  //         { id: 418, name: "Chemistry Topic3 Mock Test" },
  //         { id: 419, name: "Maths Topic1 Mock Test" },
  //         { id: 420, name: "Maths Topic2 Mock Test" },
  //         { id: 421, name: "Maths Topic3 Mock Test" },
  //       ],
  //     },
  //   },
  // },

  NEET: {
    id: 103,
    "MOCK TEST TYPE": {
      "FULL MOCK TEST": {
        id: 207,
        mockTests: [
          { id: 501, name: "Mock Test-1" },
          { id: 502, name: "Mock Test-2" },
          { id: 503, name: "Mock Test-3" },
        ],
      },
      // SUBJECT_WISE_MOCK_TEST: {
      //   id: 208,
      //   mockTests: [
      //     { id: 504, name: "Physics Mock Test-1" },
      //     { id: 505, name: "Physics Mock Test-2" },
      //     { id: 506, name: "Physics Mock Test-3" },
      //     { id: 507, name: "Chemistry  Mock Test-1" },
      //     { id: 508, name: "Chemistry  Mock Test-2" },
      //     { id: 509, name: "Chemistry  Mock Test-3" },
      //     { id: 510, name: "Zoology   Mock Test-1" },
      //     { id: 511, name: "Zoology   MockTest-2" },
      //     { id: 512, name: "Zoology   Mock Test-3" },
      //     { id: 513, name: "Botany   Mock Test-1" },
      //     { id: 514, name: "Botany   MockTest-2" },
      //     { id: 515, name: "Botany   Mock Test-3" },
      //   ],
      // },
      // TOPIC_WISE_MOCKTEST: {
      //   id: 209,
      //   mockTests: [
      //     { id: 516, name: "Physics Topic1 Mock Test" },
      //     { id: 517, name: "Physics Topic2 Mock Test" },
      //     { id: 518, name: "Physics Topic3 Mock Test" },
      //     { id: 519, name: "Physics Topic4 Mock Test" },
      //     { id: 520, name: "Chemistry Topic1 Mock Test" },
      //     { id: 521, name: "Chemistry Topic2 Mock Test" },
      //     { id: 522, name: "Chemistry Topic3 Mock Test" },
      //     { id: 523, name: "Chemistry Topic4 Mock Test" },
      //     { id: 524, name: "Zoology  Topic1 Mock Test" },
      //     { id: 525, name: "Zoology  Topic2 Mock Test" },
      //     { id: 526, name: "Zoology  Topic3 Mock Test" },
      //     { id: 527, name: "Zoology  Topic4 Mock Test" },
      //     { id: 528, name: "Botany  Topic1 Mock Test" },
      //     { id: 529, name: "Botany  Topic2 Mock Test" },
      //     { id: 530, name: "Botany  Topic3 Mock Test" },
      //     { id: 531, name: "Botany  Topic4 Mock Test" },
      //   ],
      // },
    },
  },

  BITSAT: {
    id: 104,
    "MOCK TEST TYPE": {
      "FULL MOCK TEST": {
        id: 210,
        mockTests: [
          { id: 601, name: "Mock Test-1" },
          { id: 602, name: "Mock Test-2" },
          { id: 603, name: "Mock Test-3" },
        ],
      },
      // "SUBJECT WISE MOCK TEST": {
      //   id: 211,
      //   mockTests: [
      //     { id: 604, name: "Physics Mock Test-1" },
      //     { id: 605, name: "Physics Test-2" },
      //     { id: 606, name: "Physics Test-3" },
      //     { id: 607, name: "Chemistry Mock Test-1" },
      //     { id: 608, name: "Chemistry Test-2" },
      //     { id: 609, name: "Chemistry Test-3" },
      //     { id: 610, name: "English Proficiency Mock Test-1" },
      //     { id: 611, name: "English Proficiency Test-2" },
      //     { id: 612, name: "English Proficiency Test-3" },

      //     { id: 613, name: "Logical Reasoning Mock Test-1" },
      //     { id: 614, name: "Logical Reasoning Test-2" },
      //     { id: 615, name: "Logical Reasoning Test-3" },

      //     { id: 616, name: "Mathematics Mock Test-1" },
      //     { id: 617, name: "MathematicsTest-2" },
      //     { id: 618, name: "Mathematics Test-3" },
      //   ],
      // },
      // "TOPIC WISE MOCKTEST": {
      //   id: 212,
      //   mockTests: [
      //     { id: 619, name: "Physics Topic1 Mock Test" },
      //     { id: 620, name: "Physics Topic2 Mock Test" },
      //     { id: 621, name: "Physics Topic3 Mock Test" },
      //     { id: 622, name: "Physics Topic4 Mock Test" },
      //     { id: 623, name: "Chemistry Topic1 Mock Test" },
      //     { id: 624, name: "Chemistry Topic2 Mock Test" },
      //     { id: 625, name: "Chemistry Topic3 Mock Test" },
      //     { id: 626, name: "Chemistry Topic4 Mock Test" },
      //     { id: 527, name: "Mathematics Topic1 Mock Test" },
      //     { id: 628, name: "Mathematics Topic2 Mock Test" },
      //     { id: 629, name: "Mathematics Topic3 Mock Test" },

      //     { id: 530, name: " English Proficiency Topic1 Mock Test" },
      //     { id: 631, name: " English Proficiency Topic2 Mock Test" },
      //     { id: 632, name: " English Proficiency Topic3 Mock Test" },
      //     { id: 633, name: " English Proficiency Topic4 Mock Test" },
      //     { id: 534, name: " Logical Reasoning Topic1 Mock Test" },
      //     { id: 635, name: "Logical Reasoning Topic2 Mock Test" },
      //     { id: 636, name: "Logical Reasoning Topic3 Mock Test" },
      //     { id: 637, name: "Logical Reasoning Topic4 Mock Test" },
      //   ],
      // },
    },
  },
  // VITEEE: {
  //   id: 105,
  //   "MOCK TEST TYPE": {
  //     "FULL MOCK TEST": {
  //       id: 213,
  //       mockTests: [
  //         { id: 701, name: "Mock Test-1" },
  //         { id: 702, name: "Mock Test-2" },
  //         { id: 703, name: "Mock Test-3" },
  //       ],
  //     },
  //     SUBJECT_WISE_MOCK_TEST: {
  //       id: 214,
  //       mockTests: [
  //         { id: 704, name: "Physics Mock Test-1" },
  //         { id: 705, name: "Physics Test-2" },
  //         { id: 706, name: "Physics Test-3" },
  //         { id: 707, name: "Physics Mock Test-1" },
  //         { id: 708, name: "Physics Test-2" },
  //         { id: 709, name: "Physics Test-3" },
  //         { id: 710, name: "Physics Mock Test-1" },
  //         { id: 711, name: "Physics Test-2" },
  //         { id: 712, name: "Physics Test-3" },
  //       ],
  //     },
  //     TOPIC_WISE_MOCKTEST: {
  //       id: 215,
  //       mockTests: [
  //         { id: 713, name: "Physics Topic1 Mock Test" },
  //         { id: 714, name: "Physics Topic2 Mock Test" },
  //         { id: 715, name: "Physics Topic3 Mock Test" },
  //         { id: 716, name: "Chemistry Topic1 Mock Test" },
  //         { id: 717, name: "Chemistry Topic2 Mock Test" },
  //         { id: 718, name: "Chemistry Topic3 Mock Test" },
  //         { id: 719, name: "Maths Topic1 Mock Test" },
  //         { id: 720, name: "Maths Topic2 Mock Test" },
  //         { id: 721, name: "Maths Topic3 Mock Test" },
  //       ],
  //     },
  //   },
  // },
};

const New_MockTestform_file_Topic_wise = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const banners = [
    { src: banners_image_one, alt: "Banner 1" },
    { src: Mock_Test_miniBanner_Two, alt: "Banner 2" },
  ];
  const banners_Two = [
    { src: mobile_view_image_one, alt: "Banner 3" },
    { src: mobile_view_image_Two, alt: "Banner 4" },
  ];

  const [TwoCurrentIndex, setTwoCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setTwoCurrentIndex((prevIndex) => (prevIndex + 1) % banners_Two.length);
    }, 3000); // Change every 3 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
    }, 10000); // Change banner every 10 seconds

    return () => clearInterval(interval);
  }, []);

  const [selectedExam, setSelectedExam] = useState("");
  const [selectedMockTestType, setSelectedMockTestType] = useState("");
  const [mockTests, setMockTests] = useState([]);
  const [formData, setFormData] = useState({
    studentName: "",
    contactNumber: "",
    email: "",
    selectedExam: "",
    selectedMockTestType: "",
    selectedMockTest: "",
  });

  const handleExamChange = (e) => {
    const exam = e.target.value;
    setSelectedExam(exam);
    setSelectedMockTestType("");
    setMockTests([]);
    setFormData({ ...formData, selectedExam: exam });
  };

  const handleMockTestTypeChange = (e) => {
    const mockTestType = e.target.value;
    setSelectedMockTestType(mockTestType);
    const selectedExamData = examMockTests[selectedExam];
    if (selectedExamData && selectedExamData["MOCK TEST TYPE"]) {
      setMockTests(
        selectedExamData["MOCK TEST TYPE"][mockTestType]?.mockTests || []
      );
    }
    setFormData({ ...formData, selectedMockTestType: mockTestType });
  };

  const handleMockTestChange = (e) => {
    setFormData({ ...formData, selectedMockTest: e.target.value });
  };

  const [errors, setErrors] = useState({
    studentName: "",
    contactNumber: "",
    email: "",
  });
  const handleStudentNameChange = (e) => {
    const value = e.target.value;
    // Regular expression for alphabets and special characters
    const regex = /^[a-zA-Z\s!@#$%^&*()_+[\]{};':"\\|,.<>?`~]*$/;
    if (regex.test(value)) {
      setFormData({ ...formData, studentName: value });
      setErrors({ ...errors, studentName: "" });
    } else {
      setErrors({
        ...errors,
        studentName: "Only alphabets and special characters are allowed.",
      });
    }
  };

  const handleContactNumberChange = (e) => {
    const value = e.target.value;
    // Allow only digits and limit to 10 digits
    const regex = /^[0-9]*$/;
    if (regex.test(value) && value.length <= 10) {
      setFormData({ ...formData, contactNumber: value });
      setErrors({ ...errors, contactNumber: "" });
    } else {
      setErrors({ ...errors, contactNumber: "Only 10 digits are allowed." });
    }
  };

  const handleEmailChange = (e) => {
    setFormData({ ...formData, email: e.target.value });
    // HTML5 validation will handle this in the form submission
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const { selectedExam, selectedMockTestType, selectedMockTest } = formData;
    const exam = examMockTests[selectedExam];
    const examId = exam ? exam.id : null;
    const mockTestTypeId =
      (exam && exam["MOCK TEST TYPE"][selectedMockTestType]?.id) || null;
    const mockTestId =
      exam && selectedMockTestType
        ? exam["MOCK TEST TYPE"][selectedMockTestType]?.mockTests.find(
            (test) => test.id == selectedMockTest
          )?.id
        : null;
    // Create a FormData object from the form data
    const data = new FormData(e.target);

    fetch("https://formspree.io/f/xwperaen", {
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log("Form submitted successfully!");

          // Reset form
          setFormData({
            studentName: "",
            contactNumber: "",
            email: "",
            selectedExam: "",
            selectedMockTestType: "",
            selectedMockTest: "",
          });
          setSelectedExam("");
          setSelectedMockTestType("");
          setMockTests([]);

          // Determine redirect URL based on examId and mockTestId
          let redirectUrl = null;
          if (
            examId === 101 &&
            (mockTestTypeId === 201 ||
              mockTestTypeId === 202 ||
              mockTestTypeId === 203) &&
            mockTestId >= 301 &&
            mockTestId <= 321
          ) {
            redirectUrl = `/Instructionjee/${examId}/${mockTestId}/${mockTestTypeId}`;
          } else if (
            examId === 102 &&
            (mockTestTypeId === 204 ||
              mockTestTypeId === 205 ||
              mockTestTypeId === 206) &&
            mockTestId >= 401 &&
            mockTestId <= 421
          ) {
            redirectUrl = `/InstructionjeeAdvance/${examId}/${mockTestId}/${mockTestTypeId}`;
          } else if (
            examId === 103 &&
            (mockTestTypeId === 207 ||
              mockTestTypeId === 208 ||
              mockTestTypeId === 209) &&
            mockTestId >= 501 &&
            mockTestId <= 531
          ) {
            // redirectUrl = `/Instructionjee/${examId}/${mockTestId}/${mockTestTypeId}`;
            redirectUrl = `/NEETInstructionPage/${examId}/${mockTestId}/${mockTestTypeId}`;
          } else if (
            examId === 104 &&
            (mockTestTypeId === 210 ||
              mockTestTypeId === 211 ||
              mockTestTypeId === 212) &&
            mockTestId >= 601 &&
            mockTestId <= 637
          ) {
            redirectUrl = `/InstructionPage/${examId}/${mockTestId}/${mockTestTypeId}`;
          } else if (
            examId === 105 &&
            (mockTestTypeId === 213 ||
              mockTestTypeId === 214 ||
              mockTestTypeId === 215) &&
            mockTestId >= 701 &&
            mockTestId <= 721
          ) {
            redirectUrl = `/VITEEInstructionPage1/${examId}/${mockTestId}/${mockTestTypeId}`;
          }

          if (redirectUrl) {
            const screenWidth = window.screen.width;
            const screenHeight = window.screen.height;

            window.open(
              redirectUrl,
              "_blank",
              `width=${screenWidth},height=${screenHeight},fullscreen=yes`
            );
          }
        } else {
          console.error("There was an error submitting the form.");
        }
      })
      .catch((error) => {
        console.error("Form submission error:", error);
      });
  };

  return (
    <div>
      <div className="mock_test_header_container">
        {Nav.map((NavData, index) => (
          <div className="header ug_exam_header" key={index}>
            <div className={NavData.logo_img_container}>
              <Link to={"/"}>
                <img src={NavData.logo} alt="" />
              </Link>
            </div>
            <div className="exam_login_menu">
              <li>
                <Link
                  to="/home"
                  className={NavData.navlist}
                  id="exam_login_menu_home"
                >
                  <IoMdHome /> {NavData.link1}
                </Link>
              </li>
            </div>
          </div>
        ))}
      </div>

      <div className="Mock_test_form_and_banners_container">
        <div className="Banner_container">
          {banners.map((banner, index) => (
            <img
              key={index}
              className={`Banner_image ${
                index === currentIndex ? "fade-in" : "fade-out"
              }`}
              src={banner.src}
              alt={banner.alt}
            />
          ))}
          <div className="Mobile_view_banner_container">
            <div className="slider">
              <img
                className="new_img"
                src={mobile_view_image_one}
                alt="Image One"
              />
              <img
                className="new_img"
                src={mobile_view_image_Two}
                alt="Image Two"
              />
              <img
                className="new_img"
                src={mobile_view_image_one}
                alt="Image One"
              />
              <img
                className="new_img"
                src={mobile_view_image_Two}
                alt="Image Two"
              />
            </div>
          </div>
        </div>

        <div className="SubmitFormAndMail_container">
          <div className="SubmitFormAndMail_header1">
            <div>
              <h2>MOCK TEST</h2>
            </div>
            {/* <div> */}
            {/* {Nav.map((NavData, index) => (
                <div key={index} className="Mock_test_header_item">
                  <img src={NavData.logo} alt="" />
                </div>
              ))} */}
            {/* </div> */}
          </div>

          <form onSubmit={handleSubmit} className="studentformmocktest">
            {/* Dropdown for selecting exam */}
            <div className="form-group">
              <label
                className="SubmitFormAndMail_label"
                htmlFor="SubmitFormAndMail_label"
              >
                Select Exam:
              </label>
              <select
                className="SubmitFormAndMail_input"
                value={selectedExam}
                onChange={handleExamChange}
                required
              >
                <option value="">Select Exam</option>
                {Object.keys(examMockTests).map((examKey) => (
                  <option key={examMockTests[examKey].id} value={examKey}>
                    {examKey}
                  </option>
                ))}
              </select>
            </div>

            {/* Dropdown for selecting mock test type */}
            <div className="form-group">
              <label
                className="SubmitFormAndMail_label"
                htmlFor="SubmitFormAndMail_label"
              >
                Select Mock Test Type:
              </label>
              <select
                className="SubmitFormAndMail_input"
                value={selectedMockTestType}
                onChange={handleMockTestTypeChange}
                required
                disabled={!selectedExam} // Disable until an exam is selected
              >
                <option value="">Select Mock Test Type</option>
                {selectedExam &&
                  Object.keys(
                    examMockTests[selectedExam]["MOCK TEST TYPE"]
                  ).map((type) => (
                    <option
                      key={
                        examMockTests[selectedExam]["MOCK TEST TYPE"][type].id
                      }
                      value={type}
                    >
                      {type}
                    </option>
                  ))}
              </select>
            </div>

            {/* Dropdown for selecting mock test */}
            <div className="form-group">
              <label
                className="SubmitFormAndMail_label"
                htmlFor="SubmitFormAndMail_label"
              >
                Select Mock Test:
              </label>
              <select
                className="SubmitFormAndMail_input"
                value={formData.selectedMockTest}
                required
                onChange={handleMockTestChange}
                disabled={!selectedMockTestType} // Disable until a mock test type is selected
              >
                <option value="">Select Mock Test</option>
                {selectedMockTestType &&
                  mockTests.map((test) => (
                    <option key={test.id} value={test.id}>
                      {test.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="form-group">
              <label className="SubmitFormAndMail_label">Student Name:</label>
              <input
                className="SubmitFormAndMail_input"
                type="text"
                name="studentName"
                placeholder="Enter Name"
                value={formData.studentName}
                onChange={handleStudentNameChange}
                required
              />
            </div>
            <div className="form-group">
              <label className="SubmitFormAndMail_label">Contact Number:</label>
              <input
                className="SubmitFormAndMail_input"
                type="text"
                name="contactNumber"
                pattern="\d{10}"
                title="Contact number must be exactly 10 digits."
                placeholder="Enter Mobile No"
                value={formData.contactNumber}
                onChange={handleContactNumberChange}
                required
              />
            </div>
            <div className="form-group">
              <label className="SubmitFormAndMail_label">Email:</label>
              <input
                className="SubmitFormAndMail_input"
                type="email"
                name="email"
                placeholder="Enter Email"
                value={formData.email}
                onChange={handleEmailChange}
                required
              />
            </div>
            <div className="submit_btn_container">
              <button
                className="mock_test_new_submit_button SubmitFormAndMail_submitButton"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer2 />
    </div>
  );
};

export default New_MockTestform_file_Topic_wise;
