import React from 'react'
import aboutlogo from "./asserts/caplogo.jpeg";
const Publication_Page_about_page = () => {
  return (
    <div>
      <div className="Publication_Page_about_page">
        <h3>About Us</h3>
        <div className="Publication_Page_about_page_sub_part">
          <div className="Publication_Page_about_page_logo">
            <img src={aboutlogo} alt="" />
          </div>
          <div className="Publication_Page_about_page_details">
            <p>
              eGRADTutor is an Edtech firm started with a vision to blend the
              boundaries in education and take best-in-class courses and
              teachers virtually to every corner of the country. Our courses are
              developed by the alumni of IIT's/IISc with an intent to enhance
              the student's abilities to face some of the toughest exams of the
              country. Our offerings not just provide a deeper understanding of
              the subjects to the students, but they also make them exam ready.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Publication_Page_about_page
