import React from 'react'
import './JESTEligibility.css'; 

const JESTEligibility = () => {
  return (
    <div>
 <b>
        <a className='SYLLABUS_pdf' href="https://www.jest.org.in/eligibility" target='_blank'>Eligibility Information <i class="fa-solid fa-download"></i></a>
      </b>
    </div>
  )
}

export default JESTEligibility