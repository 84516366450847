




export const Contact_data = [
    {
        map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15229.163610218158!2d78.5120985!3d17.3978217!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9994cb7e0b83%3A0x4090f289cc85203c!2seGATE%20Tutor%20Academy!5e0!3m2!1sen!2sin!4v1688728318933!5m2!1sen!2sin",

        contact_title: "CONTACT US",
        adress: "ADDRESS",
        // ad1:"2nd floor, eGATE Tutor Academy,",    
        // add_1:'R.K Nivas, 2-2-1132/10/C,',
        // add_2:'Shivam Rd, opposite BackersQ,,',

        // add_3:'New Nallakunta',
        // add_4:'Hyderabad, Telangana 500044.',


        ad1: " eGATETutor Academy,",
        add_1: 'R.K Nivas, 2-2-1132/10/C,',
        add_2: 'Shivam Rd, Prashanti Nagar,',

        add_3: 'New Nallakunta,',
        add_4: 'Hyderabad, Telangana - 500044.',
        add_5: "Landmark : Opposite BackersQ",
        // ad2:" ",
        mobile: "+91- 7993270532",
        email: "info@egradtutor.in",
        category: "--category--",
        op1: "General Enquery",
        op2: "Exams Enquery",
        op3: "Courses Enquery",
        op4: "Payment Related Issues",
        // contact_btn:"SEND MESSAGE"



        contact_sub_contcainer: "contact_sub_contcainer",
        contact_box: 'contact_box',
        mpa_adresss: "mpa_adresss",
        adres: "adres"




    }
]