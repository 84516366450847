import maths1 from "../asserts/Books/maths1.png";
import maths2 from "../asserts/Books/maths 2.png";

import maths3 from "../asserts/Books/Bio A.png";

import maths4 from "../asserts/Books/Bio B.png";


export const Publication_Page_Book_pageData1 = [
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths2,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths3,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths4,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths2,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths3,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths4,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths2,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths3,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths4,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths2,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths3,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths4,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths2,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths3,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths4,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
];


export const Publication_Page_Book_pageData2 = [
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
];


export const Publication_Page_arrivals_Book_pageData1 = [
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
 
];

export const Publication_Page_arrivals_Book_pageData2 = [
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
  {
    BOOKImg: maths1,
    BOOKtitle: "title",
    BOOKISBN: "xxxx",
    BOOKprice: "xxx",
  },
];
