import React, { useState, useEffect } from "react";
import "./NEET/NEET.css";
import BITSATquestionsData from "./BITSAT/BITSATMocktest_3.json";
import BITSATExtraquetions from "./BITSAT/BITSATExtraquetion_1.json";
function BITSATMocktest_3() {
  const [selectedSubject, setSelectedSubject] = useState(
    BITSATquestionsData.length > 0 ? BITSATquestionsData[0].SubjectName : null
  );
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responses, setResponses] = useState({});
  const [viewedQuestions, setViewedQuestions] = useState({});
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [markedForReview, setMarkedForReview] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [remainingTime, setRemainingTime] = useState(3 * 60 * 60);
  const getQuestionKey = (subject, sectionIndex, questionId) => {
    return `${subject}-${sectionIndex}-${questionId}`;
  };

  const handleOptionChange = (option) => {
    if (currentQuestion) {
      const key = getQuestionKey(
        selectedSubject,
        currentSectionIndex,
        currentQuestion.question_id
      );
      setResponses({ ...responses, [key]: option });
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1); // Decrease remaining time by 1 second every second
    }, 1000);

    // Clear the interval when component unmounts or remainingTime reaches 0
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (remainingTime <= 0) {
      alert("Time's up!");
      // Optionally, you can trigger submission logic here
    }
  }, [remainingTime]);

  const clearResponse = () => {
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );
    setResponses({ ...responses, [key]: null });
    setViewedQuestions({ ...viewedQuestions, [key]: true });
    setAnsweredQuestions({ ...answeredQuestions, [key]: false });
  };

  const moveToQuestion = (newSectionIndex, newQuestionIndex) => {
    const key = getQuestionKey(
      selectedSubject,
      newSectionIndex,
      currentSubject.sections[newSectionIndex].questions[newQuestionIndex].question_id
    );
    setViewedQuestions({ ...viewedQuestions, [key]: true });
    setCurrentSectionIndex(newSectionIndex);
    setCurrentQuestionIndex(newQuestionIndex);
  };
 
  const nextQuestion = () => {
    const currentSection = currentSubject.sections[currentSectionIndex];
    if (currentQuestionIndex < currentSection.questions.length - 1) {
      moveToQuestion(currentSectionIndex, currentQuestionIndex + 1);
    } else {
      const currentSubjectIndex = BITSATquestionsData.findIndex(subject => subject.SubjectName === selectedSubject);
      if (currentSubjectIndex < BITSATquestionsData.length - 1) {
        const nextSubject = BITSATquestionsData[currentSubjectIndex + 1];
        setSelectedSubject(nextSubject.SubjectName);
        setCurrentSectionIndex(0);
        setCurrentQuestionIndex(0);
      }
    }
  };
 
  const previousQuestion = () => {
    if (currentQuestionIndex > 0) {
      moveToQuestion(currentSectionIndex, currentQuestionIndex - 1);
    } else {
      const currentSubjectIndex = BITSATquestionsData.findIndex(subject => subject.SubjectName === selectedSubject);
      if (currentSubjectIndex > 0) {
        const prevSubject = BITSATquestionsData[currentSubjectIndex - 1];
        setSelectedSubject(prevSubject.SubjectName);
        const lastSectionIndex = prevSubject.sections.length - 1;
        setCurrentSectionIndex(lastSectionIndex);
        setCurrentQuestionIndex(prevSubject.sections[lastSectionIndex].questions.length - 1);
      }
    }
  };
 
  const saveAndNext = () => {
    const currentSection = currentSubject.sections[currentSectionIndex];
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );
    if (currentQuestionIndex < currentSection.questions.length - 1) {
      if (responses[key] !== undefined) {
        setAnsweredQuestions({ ...answeredQuestions, [key]: true });
      }
      nextQuestion();
    } else {
      const currentSubjectIndex = BITSATquestionsData.findIndex(subject => subject.SubjectName === selectedSubject);
      if (currentSubjectIndex < BITSATquestionsData.length - 1) {
        const nextSubject = BITSATquestionsData[currentSubjectIndex + 1];
        setSelectedSubject(nextSubject.SubjectName);
        setCurrentSectionIndex(0);
        setCurrentQuestionIndex(0);
      }
    }
  };


  const goToQuestion = (index) => {
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentSection.questions[index].question_id
    );
    setViewedQuestions({ ...viewedQuestions, [key]: true });
    setCurrentQuestionIndex(index);
  };

  const handleSubjectChange = (subjectName) => {
    setSelectedSubject(subjectName);
    setCurrentSectionIndex(0);
    setCurrentQuestionIndex(0);
  };

  const handleSectionChange = (index) => {
    setCurrentSectionIndex(index);
    setCurrentQuestionIndex(0);
  };

  const calculateMarks = () => {
    let totalMarks = 0;
    BITSATquestionsData.forEach((subject) => {
      subject.sections.forEach((section, sectionIndex) => {
        section.questions.forEach((question) => {
          const key = getQuestionKey(
            subject.SubjectName,
            sectionIndex,
            question.question_id
          );
          if (responses[key] !== undefined) {
            if (question.answer_text === responses[key]) {
              totalMarks += parseInt(question.marks_text, 10);
            } else {
              totalMarks -= 1;
            }
          }
        });
      });
    });
    return totalMarks;
  };

  const calculateCounts = () => {
    let answeredCount = 0;
    let notAnsweredCount = 0;
    let markedForReviewCount = 0;
    let correctAnswersCount = 0;
    let wrongAnswersCount = 0;
    let notVisitedCount = 0; // Initialize the count of not visited questions

    BITSATquestionsData.forEach((subject) => {
      subject.sections.forEach((section, sectionIndex) => {
        section.questions.forEach((question) => {
          const key = getQuestionKey(
            subject.SubjectName,
            sectionIndex,
            question.question_id
          );
          if (responses[key] !== undefined) {
            if (responses[key] !== null) {
              answeredCount++;
              if (question.answer_text === responses[key]) {
                correctAnswersCount++;
              } else {
                wrongAnswersCount++;
              }
            } else {
              notAnsweredCount++;
            }
          } else {
            notAnsweredCount++; // If response is undefined, count as not answered
            if (!viewedQuestions[key]) {
              notVisitedCount++; // If question hasn't been viewed yet, count as not visited
            }
          }
          if (markedForReview[key]) {
            markedForReviewCount++;
          }
        });
      });
    });

    return {
      answeredCount,
      notAnsweredCount,
      markedForReviewCount,
      correctAnswersCount,
      wrongAnswersCount,
      notVisitedCount, // Include not visited count in the returned object
    };
  };

  const handleSubmit = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    window.location.href = "/bitsatots";
    window.close();
  };

  const currentSubject = BITSATquestionsData.find(
    (subject) => subject.SubjectName === selectedSubject
  );
  const currentSection = currentSubject?.sections[currentSectionIndex];
  const currentQuestion = currentSection?.questions[currentQuestionIndex];

  const counts = calculateCounts();
  return (
    <div>
      <div className="mocktestparent_conatiner">
        {/* -------------------header------- */}
        <div>
          <div className="remaining">
            <div className="bitsatMockHeader">
              <img
                src="https://www.egradtutor.in/static/media/egate%20logo%201.8b1eb31347d60945925f.png"
                alt=""
              />
            </div>

            <h4>
              <b>BITSAT Mock test 1</b>
            </h4>

            <p>
              Time remaining:{" "}
              {`${Math.floor(remainingTime / 3600)}:${Math.floor(
                (remainingTime % 3600) / 60
              )}:${remainingTime % 60}`}
            </p>
          </div>
        </div>
        {/* -------------------interface------- */}

        <div>
          <div className="mocktest_conatiner_testwithpallet">
            <div className="mocktest_conatiner_testwithpalletquizcontainer">
              <div className="mocktest_conatiner_testwithpalletquizcontainersub">
                {/* -------------------sectionbtns------- */}
                <div className="sectionbtnmock">
                  {BITSATquestionsData.map((subject) => (
                    <button
                      key={subject.SubjectName}
                      onClick={() => handleSubjectChange(subject.SubjectName)}
                      className={
                        selectedSubject === subject.SubjectName ? "active" : ""
                      }
                    >
                      {subject.SubjectName}
                    </button>
                  ))}
                </div>
                <div className="mocktest_conatiner_testwithpalletquizcontainersubintopart">
                  {selectedSubject && currentQuestion && (
                    <div>
                      <p>Question {currentQuestion.question_id}:</p>
                      <img src={currentQuestion.questionImgName} alt="" />

                      <div className="optionimginmock">
                        <div className="optionimginmock">
                          {currentQuestion.options.map((option) => (
                            <label key={option.option_id} className="option">
                              <input
                                type="radio"
                                id={`option-${option.option_id}`}
                                name={`question-${currentQuestion.question_id}`}
                                checked={
                                  responses[
                                    getQuestionKey(
                                      selectedSubject,
                                      currentSectionIndex,
                                      currentQuestion.question_id
                                    )
                                  ] === option.option_index
                                }
                                onChange={() =>
                                  handleOptionChange(option.option_index)
                                }
                              />
                              <span htmlFor={`option-${option.option_id}`}>
                                {option.option_index}
                              </span>
                              <img src={option.optionImgName} alt="" />
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* -------------------------------------------buttons Option container-------------- */}
                <div>
                  <div class="mocktest_conatiner_testwithpalletbuttons">
                    {" "}
                    <div>
                      <button
                        class="Quiz_clearResponse Quiz_mockbtns Quiz_mockbtnsclear"
                        onClick={clearResponse}
                      >
                        Clear Response
                      </button>
                      <button
                        class="quizsave_next previous_next_btn Quiz_mockbtns"
                        onClick={saveAndNext}
                      >
                        Save &amp; Next
                      </button>
                    </div>
                    <div>
                      <button
                        class="Quiz_mockbtns Quiz_mockbtnspre"
                        onClick={previousQuestion}
                      >
                        Previous
                      </button>
                      <button
                        class="Quiz_mockbtns Quiz_mockbtnsnext"
                        onClick={nextQuestion}
                      >
                        Next
                      </button>
                      <button
                        class="Quiz_mockbtns Quiz_mockbtnssubmit"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="numberpalette">
                <div class="rightSidebar-topHeader">
                  <img
                    title="Guest"
                    src="/static/media/guesticon.00ad06aaa8a7af8eb6c5.jpg"
                    alt="guesticon"
                  />
                  <p>Candidate Name: Guest</p>
                </div>
                <div className="yoursection_inMockTest">
                  <span>
                    You are viewing{" "}
                    <label htmlFor="">{currentSubject.SubjectName}</label>
                    Section{" "}
                  </span>
                  <b>Question Palette</b>
                </div>
                <div className="question-number-palette question-numberpalette question-palette">
                  {currentSection.questions.map((question, index) => {
                    const key = getQuestionKey(
                      selectedSubject,
                      currentSectionIndex,
                      question.question_id
                    );
                    return (
                      <button
                        key={question.question_id}
                        className={`
                    ${index === currentQuestionIndex ? "answered-red" : ""}
                    ${
                      answeredQuestions[key] && !markedForReview[key]
                        ? "answered"
                        : ""
                    }
                    ${viewedQuestions[key] && !responses[key] ? "viewed" : ""}
                    ${
                      markedForReview[key]
                        ? responses[key]
                          ? "marked-answered"
                          : "marked"
                        : ""
                    }
                    ${
                      responses[key] !== undefined && !answeredQuestions[key]
                        ? "answered-red"
                        : ""
                    }
                  `}
                        onClick={() => goToQuestion(index)}
                      >
                        {index + 1}
                      </button>
                    );
                  })}
                </div>
                <div className="sidebar-footer">
                  <h4 className="sidebar-footer-header">Legend:</h4>
                  <div className="footer-btns">
                    <div className="inst-btns">
                      {/* <img src={grayBox} alt="Not Visited" /> */}
                      <span className="NotVisited">
                        {counts.notVisitedCount}
                      </span>

                      <label htmlFor="">Not Visited</label>
                    </div>
                    <div className="inst-btns">
                      {/* <img src={greenBox} alt="Answered" /> */}
                      <span className="Answered">{counts.answeredCount}</span>
                      <label htmlFor="">Answered</label>
                    </div>
                    <div className="inst-btns">
                      {/* <img src={orangeBox} alt="Not Answered" /> */}
                      <span className="NotAnsweredlegend">
                        {" "}
                        {counts.notAnsweredCount}
                      </span>
                      <label htmlFor="">Not Answered</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="scorecardforexam">
        {showPopup && (
          <div className="popup">
            <div className="popup-content">
              <p>You have successfully submitted your test.</p>
              <p>
                <img
                  src="/static/media/prize.e48700d2bb773b1eda58.jpg"
                  alt="Congratulations"
                />
                <span>
                  <h2>Congratulations!</h2>
                  <span class="mockscore">Your score: {calculateMarks()}</span>
                </span>
              </p>

              <ul class="allscorecountingmock">
                <li>
                  <b>Answered:</b> {counts.answeredCount}
                </li>
                <li>
                  <b>Not Answered:</b> {counts.notAnsweredCount}
                </li>
                <li>
                  <b>Right Answered:</b>
                  {counts.correctAnswersCount}
                </li>
                <li>
                  <b>Wrong Answered:</b> {counts.wrongAnswersCount}
                </li>
              </ul>
              <button onClick={closePopup}>Close</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BITSATMocktest_3;
