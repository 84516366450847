// import bg from "./Banners/landingbanner.png";
// import bg from "./Banners/new landingbanner 1.png";

import { useState } from "react";

import bg from "./Banners/new landingbanner.png";
import b1 from "./Banners/banner1.png";
import b2 from "./Banners/banner2.png";
// import b3 from "./Banners/banner3.png";
import b3 from "./Banners/BITSAT ONLINE TEST - WEB BANNER.jpg";
import b4 from "./Banners/banner4.png";
import b5 from "./Banners/banner5.png";
// import b6 from "./Banners/banner6.png";
// import b6 from "./Banners/JEE 2024 5.png";
import b6 from "../../components/Home_Section/Banners/Jee Mains 2024 new.png";
// import b7 from "../../components/Home_Section/Banners/Jee Mains + Advanced2.png";
import b7 from "./Banners/NEET Banner - 1.jpg"
import b8 from "./Banners/NEET Banner - 2.jpg"
import img1 from "./Banners/img1.jpg";


import b9 from "./Banners/JEE ADVANCED MATHEMATICS WEB BANNER 1.jpg";
import b10 from "./Banners/JEE MAINS AND ADVANCED BANNER.jpg"
import b11 from "./Banners/BITSAT CRASH COURSE BANNER.jpg"
export const HomeDATA = [
  {
    // // -------------------------home classs ---------------------------------
    // home:'home',
    // banner_container:'banner_container',
    // // banner:'banner_ug',
    // banner:'ug_banner_ug',
    // hero:'hero',
    // explore_btn:'explore_btn',
    // downarrow:'downarrow',
    // downarrow_container:'downarrow-container',
    // -------------------------------------------- data ---------------------------------
    scrollbar:
      "All tests will be LIVE according to the detailed schedule given. Do not confuse to the Total No. of tests in the test cards while buying as they show the number of tests LIVE at the moment..",
    // -----------------------------------banners------------------------------------------------------
    // b1: [b1],
    // b2: [b2],
    b3: [b3],
    // b4: [b4],
    // b5: [b5],
    // b6: [b6],
    b7: [b7],
    b8: [b8],
    img1: [img1],
    bg: [bg],
    b9: [b9],
    b10: [b10],
    b11: [b11],



  },
];


export const myBanners = [
  // { img: "img2.gif", url: [b2] },
  { img: "img3.gif", url: [b3] },
  // { img: "img4.gif", url: [b4] },
  // { img: "img4.gif", url: [b5] },
  // { img: "img5.gif", url: [b6] },
  { img: "img6.gif", url: [b7] },
  { img: "img7.gif", url: [b8] },
  // { img: "img8.gif", url: [b1] },
  { img: "img5.gif", url: [b9] },
  { img: "img6.gif", url: [b10] },
  { img: "img7.gif", url: [b11] },

];

