import React, { useEffect, useState } from "react";
import publicationlogo from "./asserts/egate logo (2).png";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { HiXMark } from "react-icons/hi2";
const Publication_Page_header = () => {
  const [showpublicationheaderdropdown,setShowpublicationheaderdropdown]=useState("")
const Publication_Page_header_Book_drop_dn = () => {
  setShowpublicationheaderdropdown((prevState) => !prevState);
};

const Publication_Page_header_Book_drop_dnclose = () => {
  setShowpublicationheaderdropdown(showpublicationheaderdropdown);
};



  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="Publication_Page_header">
      <div className="Publication_Page_header-Pt-1">
        <div className="Publication_Page_header_logo">
          <img src={publicationlogo} alt="Logo" />
        </div>
        <a href="#">Contact Us</a>
        <div className="Publication_Page_navbar-toggle" onClick={toggleNavbar}>
          <i className={isOpen ? "fas fa-times" : "fas fa-bars"}>
            <AiOutlineMenuUnfold />
          </i>
        </div>
      </div>
      {/* <div className="Publication_Page_header-Pt-2"> */}
      <div
        className={
          isOpen
            ? "Publication_Page_header-Pt-2 Publication_Page_header-Pt-2mobile"
            : "Publication_Page_header-Pt-2"
        }
      >
        <ul>
          <li>
            <a href="#">Home</a>
          </li>
          <li>
            <a href="#">About</a>
          </li>
          <li
            className="Publication_Page_header_Book_drop_dn"
            onClick={Publication_Page_header_Book_drop_dn}
          >
            Book
            <MdOutlineArrowDropDown />
            {showpublicationheaderdropdown ? (
              <div className="Publication_Page_header_Book_drop_dnmenu">
                <span
                  className="Publication_Page_header_Book_drop_dnclose"
                  onClick={Publication_Page_header_Book_drop_dnclose}
                >
                  <HiXMark />
                </span>
                <ul>
                  <li>category1</li>
                  <li>category2</li>
                  <li>category3</li>
                  <li>category4</li>
                </ul>
              </div>
            ) : null}
          </li>
          <li>
            <a href="#">New Arrivals</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Publication_Page_header;
