import React from 'react'
import '../../Exams.css'

export const Bitsat_ImpDates = () => {
  return (
    <div>

      {/* <table className='examtbl exam_tbl'>
        <thead>
          <tr>
            <th>Important Events</th>
            <th>Dates</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><b>Start of BITSAT-2024 Application</b></td>
            <td><b>14<sup>th</sup> January, 2024 </b></td>
          </tr>
          <tr>
            <td>Deadline to apply online for BITSAT-2024 for Session-1 or Both the Sessions</td>
            <td>11<sup>th</sup> April 2024</td>
          </tr>
          <tr>
            <td>Revision/editing (online) in the application form by candidates</td>
            <td>15<sup>th</sup> April, 2024 to 19<sup>th</sup> April, 2024</td>
          </tr>
          <tr>
            <td>Test center allotment and announcement to candidates</td>
            <td>1<sup>st</sup> May, 2024</td>
          </tr>
          <tr>
            <td>Candidates to reserve Test date and slot</td>
            <td>06<sup>th</sup> May, 2024 to 10<sup>th</sup> May, 2024</td>
          </tr>
          <tr>
            <td>Candidates to download Hall tickets with instructions (for session-1)</td>
            <td>15<sup>th</sup> May, 2024 to till the exam date</td>
          </tr>
          <tr>
            <td><b>BITSAT-2024 Online Test Session-1</b></td>
            <td><b>19<sup>th</sup> May, 2024 to 24<sup>th</sup> May, 2024</b></td>
          </tr>
          <tr>
            <td>The application window to apply for BITSAT-2024 Session-2 only</td>
            <td>22<sup>nd</sup> May, 2024 to 10<sup>th</sup> June, 2024</td>
          </tr>
          <tr>
            <td>Revision/editing (online) in the application form by candidates</td>
            <td>11<sup>th</sup> June, 2024 to 12<sup>th</sup> June, 2024</td>
          </tr>
          <tr>
            <td>Test center allotment and announcement to candidates</td>
            <td>13<sup>th</sup> June, 2024</td>
          </tr>
          <tr>
            <td>Candidates to reserve Test date and slot</td>
            <td>15<sup>th</sup> June, 2024 to 17<sup>th</sup> June, 2024</td>
          </tr>
          <tr>
            <td>Candidates to download the Hall tickets with instructions</td>
            <td>19<sup>th</sup> June, 2024 to till the exam date</td>
          </tr>
          <tr>
            <td>BITSAT-2024 Online Test Session-2</td>
            <td>22<sup>nd</sup> June, 2024  to 26<sup>th</sup> June, 2024</td>
          </tr>
          <tr>
            <td>Apply for admission with 12th Marks and Preferences of Programmes</td>
            <td>1<sup>st</sup> June, 2024 to 28<sup>th</sup> June, 2024</td>
          </tr>
          <tr>
            <td>Editing of Marks/Preferences in Application form</td>
            <td>29<sup>th</sup> June, 2024 to 30<sup>th</sup> June, 2024</td>
          </tr>
          <tr>
            <td><b>Admit list and Waitlist announcement after Iteration I.</b></td>
            <td><b>3<sup>rd</sup> July, 2024</b></td>
          </tr>
          <tr>
            <td>Deadline for accepting the offer along with payment of fee/advance fees</td>
            <td>08<sup>th</sup> July, 2024</td>
          </tr>
          <tr>
            <td>Deadline for candidates who wish to withdraw their admission to submit a withdrawal request online using required credentials</td>
            <td>13<sup>th</sup> July, 2024 to 16<sup>th</sup> July, 2024</td>
          </tr>
          <tr>
            <td><b>Announcement of admission assignment after Iteration III.</b></td>
            <td><b>19<sup>th</sup> July, 202</b></td>
          </tr>
          <tr>
            <td>Deadline for payment of balance fees by candidates selected for admission from the waiting list in Iteration III.</td>
            <td>22<sup>nd</sup> July, 2024</td>
          </tr>
          <tr>
            <td>Deadline for candidates who wish to withdraw their admission to submit a withdrawal request online using required credentials.</td>
            <td>24<sup>th</sup> July, 2024 to 25<sup>th</sup> July, 2024</td>
          </tr>
          <tr>
            <td><b>Reporting of candidates selected for admission in Iterations I, II, and III at their respective campuses (other than the BITS-RMIT Academy students) and their Document verification and completion of other Admission formalities.</b></td>
            <td><b>30<sup>th</sup> July, 2024(09:00 AM)</b></td>
          </tr>
          <tr>
            <td><b>Fresh-students Orientation Programme who have reported and completed their Admission formalities on July 30, 2024, at respective Campuses. Detailed information will be provided by the respective Campus.</b></td>
            <td><b>31<sup>st</sup> July, 2024</b></td>
          </tr>
          <tr>
            <td><b>First Semester begins</b></td>
            <td><b>01<sup>st</sup> August, 2024</b></td>
          </tr>
          <tr>
            <td><b>Registration for all students at the respective Campuses</b></td>
            <td><b>01<sup>st</sup> August, 2024</b></td>
          </tr>
          <tr>
            <td><b>Class-work begins at the respective Campuses</b></td>
            <td><b>02<sup>nd</sup> August, 2024</b></td>
          </tr>
          <tr>
            <td><b>Announcement of admission assignment after Iteration IV to VI and subsequent deadlines for payment of balance fees by candidates and their reporting at the respective Campuses</b></td>
            <td><b>02<sup>nd</sup> August, 2024 to 21<sup>st</sup> August, 2024</b></td>
          </tr>
        </tbody>
      </table> */}

<table className='examtbl exam_tbl'>
        <thead>
          <tr>
            <th>Important Events</th>
            <th>Dates</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><b>Start of application form</b></td>
            <td><b> January 15, 2024 </b></td>
          </tr>
          <tr>
            <td>Deadline to apply online for BITSAT-2024 for Session-1 or Both the sessions</td>
            <td><b>April 16, 2024</b></td>
          </tr>
          <tr>
            <td>Revision/editing (online) in the application form by candidates</td>
            <td><b>April 20-22, 2024</b></td>
          </tr>
          <tr>
            <td>Test center allotment and announcement to candidates</td>
            <td><b>May 1, 2024</b></td>
          </tr>
          <tr>
            <td>Candidates to reserve Test date and slot. </td>
            <td><b>May 6-10, 2024</b></td>
          </tr>
          <tr>
            <td>Candidates to download Hall tickets with instructions (for Session-1)</td>
            <td><b>May 15, 2024 to till the exam date</b></td>
          </tr>
          <tr>
            <td><b>BITSAT-2024 Online Test Session-1</b></td>
            <td><b> May 20-24, 2024</b></td>
          </tr>
          <tr>
            <td>The application window to apply for BITSAT-2024 Session-2 only</td>
            <td><b>May 22 to June 10, 2024</b></td>
          </tr>
          <tr>
            <td>Revision/editing (online) in the application form by candidates</td>
            <td><b>June 11-12, 2024</b></td>
          </tr>
          <tr>
            <td>Test center allotment and announcement to candidates</td>
            <td><b> June 13, 2024</b></td>
          </tr>
          <tr>
            <td>Candidates to reserve Test date and slot</td>
            <td><b>June 15-17, 2024</b></td>
          </tr>
          <tr>
            <td>Candidates to download the Hall tickets with instructions</td>
            <td><b>June 19, 2024 to till the exam date</b></td>
          </tr>
          <tr>
            <td><b>BITSAT-2024 Online Test Session-2</b></td>
            <td><b>June 24-28, 2024</b></td>
          </tr>
          <tr>
            <td>Apply for admission with 12th Marks and Preferences of Programmes</td>
            <td><b>	 June 1-28, 2024</b></td>
          </tr>
          <tr>
            <td>Editing of Marks/Preferences in Application form</td>
            <td><b>June 29-30, 2024</b></td>
          </tr>
          <tr>
            <td><b>Admit list and Waitlist announcement after Iteration I.</b></td>
            <td><b>July 3, 2024</b></td>
          </tr>
          <tr>
            <td>Deadline for accepting the offer along with payment of fee/advance fees</td>
            <td><b>July 08, 2024</b></td>
          </tr>

<tr>
  <td><b>Announcement of admission assignment after Iteration II.</b></td>
  <td><b>July 11, 2024</b></td>
</tr>


          <tr>
            <td>Deadline for payment of balance fees by candidates selected for admission from the waiting list in Iteration II.</td>
            <td><b>July 15, 2024</b></td>
          </tr>


<tr>
  <td>Deadline for candidates who wish to withdraw their admission to submit a withdrawal request online using required credentials.</td>
  <td><b>July 13-16, 2024</b></td>
</tr>


          <tr>
            <td><b>Announcement of admission assignment after Iteration III.</b></td>
            <td><b> July 19, 2024</b></td>
          </tr>
          <tr>
            <td>Deadline for payment of balance fees by candidates selected for admission from the waiting list in Iteration III.</td>
            <td><b>July 22, 2024</b></td>
          </tr>
          <tr>
            <td>Deadline for candidates who wish to withdraw their admission to submit a withdrawal request online using required credentials.</td>
            <td><b>July 24-25, 2024</b></td>
          </tr>
          <tr>
            <td><b>Reporting of candidates selected for admission in Iterations I, II, and III at their respective campuses (other than the BITS-RMIT Academy students) and their Document verification and completion of other Admission formalities.</b></td>
            <td><b> July 30, 2024 (9:00 AM)</b></td>
          </tr>
          <tr>
            <td><b>Fresh-students Orientation Programme who have reported and completed their Admission formalities on July 30, 2024, at respective Campuses. Detailed information will be provided by the respective Campus.</b></td>
            <td><b> July 31, 2024</b></td>
          </tr>
          <tr>
            <td>Fresh-students Orientation at the respective Campuses</td>
            <td><b>July 31, 2024</b></td>
          </tr>
          <tr>
            <td>First Semester begins</td>
            <td><b> August 01, 2024</b></td>
          </tr>
          <tr>
            <td>Registration for all students at the respective Campuses</td>
            <td><b> August 01, 2024</b></td>
          </tr>

          <tr>
            <td>Class-work begins at the respective Campuses</td>
            <td><b> August 02, 2024</b></td>
          </tr>
          <tr>
            <td><b>Announcement of admission assignment after Iteration IV to VI and subsequent deadlines for payment of balance fees by candidates and their reporting at the respective Campuses.</b></td>
            <td><b>August 02-21, 2024</b></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
