import React from 'react'

const IITJAMEligibility = () => {
  return (
    <div>
      <div >
        <a className='exambtn ' id="jeeExamLink"><a target='_blank' href="https://jam2025.iitd.ac.in/eligibility.php" >Eligibility Requirements(ERs) for Admission </a>
        </a>
      </div>
    </div>
  )
}

export default IITJAMEligibility