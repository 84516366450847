import React, { useState, useEffect, useRef } from "react";
import "./Jee_mains.css";
import Jee_mains_mocktest1 from "./Jee_mains.json";
import Jee_mains_mocktest2 from "./Jee_mains_mocktest2.json";
import Jee_mains_mocktest3 from "./Jee_mains_mocktest3.json";
import { useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import { HiDownload } from "react-icons/hi";
import jsPDF from "jspdf";
import logo from "./logoegrad.png";
// chart imports
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

// Register necessary components for Chart.js
ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);

const mockTestsData = {
  301: Jee_mains_mocktest1,
  302: Jee_mains_mocktest2,
  303: Jee_mains_mocktest3,
};

function JeeAdvanceMocktest() {
  const { mockTestId } = useParams();
  const questionsData = mockTestsData[mockTestId];
  const [inputValues, setInputValues] = useState({});
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responses, setResponses] = useState({});
  const [viewedQuestions, setViewedQuestions] = useState({});
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [markedForReview, setMarkedForReview] = useState({});
  const [remainingTime, setRemainingTime] = useState(10800);
  const [isAutoSubmit, setIsAutoSubmit] = useState(false);
  const [answeredCount, setAnsweredCount] = useState(0);
  const [notAnsweredCount, setNotAnsweredCount] = useState(0);
  const [showSolution, setShowSolution] = useState(false);
  const [currentSolution, setCurrentSolution] = useState(null);
  const [activeButton, setActiveButton] = useState("performance");
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [showEvaluvationPopup, setShowEvaluvationPopup] = useState(false);
  const [showPopupscore, setShowPopupscore] = useState(false);
  const [awardedMarks, setAwardedMarks] = useState({});
  const [rightAnsweredCount, setRightAnsweredCount] = useState(0);
  const [wrongAnsweredCount, setWrongAnsweredCount] = useState(0);
  const [sectionBAnsweredCount, setSectionBAnsweredCount] = useState(0);
  const [totalMarks, setTotalMarks] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [isTimerRunningquestion, setIsTimerRunningquestion] = useState(false);
  const [timer, setTimer] = useState(null);
  const duration = 180;
  // const [questionTimes, setQuestionTimes] = useState(
  //   Array(questionsData.length).fill(0) // Initialize time spent on each question as 0
  // );

  // useEffect(() => {
  //   const newTotalMarks = calculateCorrectTotalMarks();
  //   console.log("Updated Total Marks:", newTotalMarks);
  //   setTotalMarks(Math.max(newTotalMarks, 0));
  // }, []); // Empty dependency array means this runs once when the component mounts

  // Function to determine the message based on the score
  // const getResultMessage = (marks) => {
  //   if (marks <= 25) {
  //     return { message: "Do more practice!", className: "practice" };
  //   } else if (marks <= 60) {
  //     return { message: "Keep going!", className: "keep-going" };
  //   } else if (marks <= 80) {
  //     return { message: "Well done!", className: "well-done" };
  //   } else {
  //     return { message: "Congratulations!", className: "congratulations" };
  //   }
  // };
  // const getResultMessage = (marks) => {
  //   if (marks <= 99) {
  //     return { message: "Good", className: "good" };
  //   } else if (marks <= 179) {
  //     return { message: "Very Good", className: "very-good" };
  //   } else if (marks <= 239) {
  //     return { message: "Excellent", className: "excellent" };
  //   } else {
  //     return { message: "Outstanding", className: "outstanding" };
  //   }
  // };

  // // Get the message and class based on the current totalMarks
  // const result = getResultMessage(totalMarks);
  // const message = result.message;
  // const className = result.className;

  const getQuestionKey = (subject, sectionIndex, questionId, inputValue) => {
    return `${subject}-${sectionIndex}-${questionId}-${inputValue}`;
  };
  const [selectedSubject, setSelectedSubject] = useState(
    questionsData.length > 0 ? questionsData[0].SubjectName : null
  );
  const updateInputValue = (
    selectedSubject,
    currentSectionIndex,
    questionId,
    num
  ) => {
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      questionId
    );
    const currentValue = getInputValue(
      selectedSubject,
      currentSectionIndex,
      questionId
    );

    let newValue = currentValue; // Initialize newValue with currentValue

    // If 'DEL' button is clicked and current value is not empty, remove the last character
    if (num === "DEL" && currentValue.length > 0) {
      newValue = currentValue.slice(0, -1);
    } else if (num !== "DEL") {
      // Only concatenate num if it's not "DEL"
      // Concatenate the new number to the current value for other cases
      newValue += num;
    }

    // Update input values with the new value
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [key]: newValue,
    }));
  };

  const getInputValue = (selectedSubject, currentSectionIndex, questionId) => {
    // Get the key using the provided parameters
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      questionId
    );
    // console.log("Getting input value for key:", key); // <-- Move this line here

    // Retrieve the input value for the specified key from the inputValues state
    return inputValues[key] || "";
  };
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    if (window.innerWidth <= 600) {
      setIsMenuVisible((prevState) => !prevState);
      setIsMenuOpen((prevState) => !prevState);
    }
  };
  useEffect(() => {
    let count = 0;
    questionsData.forEach((subject) => {
      subject.sections.forEach((section) => {
        count += section.questions.length;
      });
    });
    setNotAnsweredCount(count);
  }, []);
  const handleOptionChange = (option) => {
    console.log("Selected option:", option);
    if (currentQuestion) {
      const key = getQuestionKey(
        selectedSubject,
        currentSectionIndex,
        currentQuestion.question_id
      );
      console.log("Key:", key);
      setResponses({ ...responses, [key]: option });
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    if (remainingTime === 0) {
      setIsAutoSubmit(true);
      setShowPopup(true);
      // handleAutoSubmit();
    }
  }, [remainingTime]);

  // Function to clear response for the current question
  const clearResponse = () => {
    // Construct the key for the current question
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    // Get the current input value
    const valueToClear = getInputValue(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    // Log the current value before clearing it
    console.log("Value before clearing:", valueToClear);

    // Call updateInputValue to clear the input value
    updateInputValue(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id,
      "" // Pass an empty string to clear the input value
    );

    // Log the updated input values after clearing
    console.log("Updated input values:", inputValues);

    // Other operations in clearResponse function...
    // setInputValues({
    //   ...inputValues,
    //   [currentQuestion.question_id]: "", // Clear input value in state
    //   [currentSectionIndex]: "", // Clear other related values if needed
    //   [selectedSubject]: "", // Clear other related values if needed
    // });
    setInputValues({
      ...inputValues,
      [key]: [], // Clear other related values if needed
    });

    // Clear response, mark the question as viewed but not answered, and decrement answered count
    setResponses({ ...responses, [key]: [] }); // Set response to an empty array

    // Mark the question as viewed but not answered, and decrement answered count
    setViewedQuestions({ ...viewedQuestions, [key]: true });
    setAnsweredQuestions({ ...answeredQuestions, [key]: false });
    // setAnsweredCount((prevAnsweredCount) => prevAnsweredCount - 1);
    // Decrease the count only if the question was previously answered
    if (answeredCount > 0) {
      setAnsweredCount((prevAnsweredCount) => prevAnsweredCount - 1);
    }
  };

  const moveToQuestion = (newSectionIndex, newQuestionIndex) => {
    const key = getQuestionKey(
      selectedSubject,
      newSectionIndex,
      currentSubject.sections[newSectionIndex].questions[newQuestionIndex]
        .question_id
    );
    setViewedQuestions({ ...viewedQuestions, [key]: true });
    setCurrentSectionIndex(newSectionIndex);
    setCurrentQuestionIndex(newQuestionIndex);
    // stopTimer(); // Stop current timer
    setActiveQuestion(newQuestionIndex); // Set the clicked question as active
    setIsTimerRunningquestion(true); // Start timer for the new active question
  };

  const nextQuestion = () => {
    const currentSection = currentSubject.sections[currentSectionIndex];
    if (currentQuestionIndex < currentSection.questions.length - 1) {
      moveToQuestion(currentSectionIndex, currentQuestionIndex + 1);
    } else {
      const currentSubjectIndex = questionsData.findIndex(
        (subject) => subject.SubjectName === selectedSubject
      );
      const nextSectionIndex = currentSectionIndex + 1;
      if (nextSectionIndex < currentSubject.sections.length) {
        setCurrentSectionIndex(nextSectionIndex);
        setCurrentQuestionIndex(0);
      } else if (currentSubjectIndex < questionsData.length - 1) {
        const nextSubject = questionsData[currentSubjectIndex + 1];
        setSelectedSubject(nextSubject.SubjectName);
        setCurrentSectionIndex(0);
        setCurrentQuestionIndex(0);
      }
    }
  };

  const previousQuestion = () => {
    if (currentQuestionIndex > 0) {
      moveToQuestion(currentSectionIndex, currentQuestionIndex - 1);
    } else {
      const currentSubjectIndex = questionsData.findIndex(
        (subject) => subject.SubjectName === selectedSubject
      );
      const prevSectionIndex = currentSectionIndex - 1;
      if (prevSectionIndex >= 0) {
        setCurrentSectionIndex(prevSectionIndex);
        const prevSection = currentSubject.sections[prevSectionIndex];
        setCurrentQuestionIndex(prevSection.questions.length - 1);
      } else if (currentSubjectIndex > 0) {
        const prevSubject = questionsData[currentSubjectIndex - 1];
        setSelectedSubject(prevSubject.SubjectName);
        const lastSectionIndex = prevSubject.sections.length - 1;
        setCurrentSectionIndex(lastSectionIndex);
        setCurrentQuestionIndex(
          prevSubject.sections[lastSectionIndex].questions.length - 1
        );
      }
    }
  };

  //with natd range
  // const saveAndNext = () => {
  //   // stopTimer(); // Stop the current timer
  //   // setIsTimerRunningquestion(false); // Pause timer to prevent immediate start
  //   if (activeQuestion < questionsData.length - 1) {
  //     setActiveQuestion((prev) => prev + 1); // Move to next question
  //     // setIsTimerRunningquestion(true); // Start timer for the new question
  //   }

  //   // Retrieve current question details
  //   const currentSection = currentSubject.sections[currentSectionIndex];
  //   const key = getQuestionKey(
  //     selectedSubject,
  //     currentSectionIndex,
  //     currentQuestion.question_id
  //   );
  //   const inputValue = getInputValue(
  //     selectedSubject,
  //     currentSectionIndex,
  //     currentQuestion.question_id
  //   )
  //     ?.trim()
  //     .toLowerCase();

  //   // Log key and input value for debugging
  //   console.log(`Key for question ${currentQuestion.question_id}:`, key);
  //   console.log(
  //     `Retrieved input value for question ${currentQuestion.question_id}:`,
  //     inputValue
  //   );

  //   // Determine if the question was previously answered
  //   const wasPreviouslyAnswered = awardedMarks[key] !== undefined;
  //   const wasPreviouslyCorrect = awardedMarks[key] && awardedMarks[key] > 0;
  //   const previousMarks = awardedMarks[key] || 0;

  //   // Check if a response exists for the current question
  //   if (responses[key] !== undefined || inputValue !== "") {
  //     // Set the question as answered
  //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });

  //     // Extract correct answer and marks from JSON data
  //     const correctAnswer = currentQuestion.answer?.trim().toLowerCase();
  //     const marks = currentQuestion.marks;

  //     console.log("Correct Answer:", correctAnswer);
  //     console.log("User Response for options selected:", responses[key]);
  //     console.log("User Response entered value:", inputValue);

  //     if (marks) {
  //       const marksArray = marks.split(",");
  //       const correctMark = parseInt(marksArray[0], 10);
  //       const wrongMark = -1;

  //       const userResponse =
  //         responses[key] !== undefined
  //           ? responses[key].toString().trim().toLowerCase()
  //           : "";

  //       console.log("User Response:", userResponse);

  //       let isCorrectResponse = false;
  //       let newMarksAwarded = 0;

  //       if (
  //         ["MCQ4", "MCQ5", "MSQ", "MSQN", "NATI"].includes(
  //           currentQuestion.qtype
  //         )
  //       ) {
  //         isCorrectResponse =
  //           userResponse === correctAnswer ||
  //           (inputValue !== "" && inputValue === correctAnswer);
  //         newMarksAwarded = isCorrectResponse ? correctMark : wrongMark;
  //       } else if (currentQuestion.qtype === "NATD") {
  //         const [lowerRange, upperRange] = correctAnswer
  //           .split("-")
  //           .map((range) => parseFloat(range));
  //         console.log("Lower Range:", lowerRange);
  //         console.log("Upper Range:", upperRange);

  //         const isWithinRange =
  //           inputValue !== "" &&
  //           correctAnswer.includes("-") &&
  //           inputValue >= lowerRange &&
  //           inputValue <= upperRange;
  //         console.log("Is Within Range:", isWithinRange);

  //         newMarksAwarded = isWithinRange ? correctMark : wrongMark;
  //       }

  //       console.log("New Marks Awarded:", newMarksAwarded);

  //       // Update awarded marks for this question
  //       awardedMarks[key] = newMarksAwarded;

  //       // Recalculate total marks from scratch, considering only the best 5 marks in Section B
  //       const newTotalMarks = calculateCorrectTotalMarks();
  //       console.log("Updated Total Marks:", newTotalMarks);
  //       setTotalMarks(Math.max(newTotalMarks, 0));
  //       const calculatedPercentage = calculatePercentage(newTotalMarks);
  //       setPercentage(calculatedPercentage);
  //       console.log("Updated Percentage:", calculatedPercentage);
  //       // Update counts based on correctness of the response
  //       if (isCorrectResponse) {
  //         if (!wasPreviouslyCorrect) {
  //           setRightAnsweredCount((prevCount) => prevCount + 1);
  //           if (previousMarks < 0)
  //             setWrongAnsweredCount((prevCount) => prevCount - 1);
  //         }
  //       } else {
  //         if (wasPreviouslyCorrect) {
  //           setRightAnsweredCount((prevCount) => prevCount - 1);
  //         }
  //         setWrongAnsweredCount((prevCount) => prevCount + 1);
  //       }

  //       console.log("Updated awardedMarks:", awardedMarks);
  //     }

  //     // Update counts for answered questions
  //     if (!wasPreviouslyAnswered) {
  //       setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
  //       setNotAnsweredCount((prevCount) => prevCount - 1);
  //     }

  //     // Increment the count for section-B questions answered
  //     if (currentSectionIndex === 1) {
  //       setSectionBAnsweredCount((prevCount) => prevCount + 1);
  //     }

  //     // Move to the next question if available, else move to the next section or subject
  //     if (currentQuestionIndex < currentSection.questions.length - 1) {
  //       nextQuestion();
  //     } else {
  //       const mockTestsArray = Object.values(mockTestsData);
  //       const currentSubjectIndex = mockTestsArray.findIndex(
  //         (subject) => subject.SubjectName === selectedSubject
  //       );
  //       const nextSectionIndex = currentSectionIndex + 1;

  //       // Check if there is a next section in the current subject
  //       if (nextSectionIndex < currentSubject.sections.length) {
  //         // Move to the next section
  //         setCurrentSectionIndex(nextSectionIndex);
  //         setCurrentQuestionIndex(0); // Start from the first question in the next section
  //       } else {
  //         if (currentSubjectIndex < mockTestsData.length - 1) {
  //           const nextSubject = mockTestsData[currentSubjectIndex + 1];
  //           setSelectedSubject(nextSubject.SubjectName);
  //           setCurrentSectionIndex(0); // Start from the first section of the next subject
  //           setCurrentQuestionIndex(0); // Start from the first question in the first section
  //         }
  //       }
  //     }
  //   } else {
  //     // If the response is undefined, prompt the user to answer the question before proceeding
  //     alert("Please answer the current question before proceeding.");
  //   }
  // };

  //with natd range
  const saveAndNext = () => {
    // stopTimer(); // Stop the current timer
    // setIsTimerRunningquestion(false); // Pause timer to prevent immediate start

    if (activeQuestion < questionsData.length - 1) {
      setActiveQuestion((prev) => prev + 1); // Move to next question
      // setIsTimerRunningquestion(true); // Start timer for the new question
    }

    // Retrieve current question details
    const currentSection = currentSubject.sections[currentSectionIndex];
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    let inputValue = getInputValue(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    // Ensure inputValue is a string
    inputValue =
      typeof inputValue === "string" ? inputValue.trim().toLowerCase() : "";

    // Log key and input value for debugging
    console.log(`Key for question ${currentQuestion.question_id}:`, key);
    console.log(
      `Retrieved input value for question ${currentQuestion.question_id}:`,
      inputValue
    );

    // Determine if the question was previously answered
    const wasPreviouslyAnswered = awardedMarks[key] !== undefined;
    const wasPreviouslyCorrect = awardedMarks[key] && awardedMarks[key] > 0;
    const previousMarks = awardedMarks[key] || 0;

    // Check if a response exists for the current question
    if (responses[key] !== undefined || inputValue !== "") {
      // Set the question as answered
      setAnsweredQuestions({ ...answeredQuestions, [key]: true });

      // Extract correct answer and marks from JSON data
      const correctAnswer = currentQuestion.answer?.trim().toLowerCase();
      const marks = currentQuestion.marks;

      console.log("Correct Answer:", correctAnswer);
      console.log("User Response for options selected:", responses[key]);
      console.log("User Response entered value:", inputValue);

      if (marks) {
        const marksArray = marks.split(",");
        const correctMark = parseInt(marksArray[0], 10);
        const wrongMark = -1;

        const userResponse =
          responses[key] !== undefined
            ? responses[key].toString().trim().toLowerCase()
            : "";

        console.log("User Response:", userResponse);

        let isCorrectResponse = false;
        let newMarksAwarded = 0;

        if (
          ["MCQ4", "MCQ5", "MSQ", "MSQN", "NATI"].includes(
            currentQuestion.qtype
          )
        ) {
          isCorrectResponse =
            userResponse === correctAnswer ||
            (inputValue !== "" && inputValue === correctAnswer);
          newMarksAwarded = isCorrectResponse ? correctMark : wrongMark;
        } else if (currentQuestion.qtype === "NATD") {
          const [lowerRange, upperRange] = correctAnswer
            .split("-")
            .map((range) => parseFloat(range));
          console.log("Lower Range:", lowerRange);
          console.log("Upper Range:", upperRange);

          const isWithinRange =
            inputValue !== "" &&
            correctAnswer.includes("-") &&
            inputValue >= lowerRange &&
            inputValue <= upperRange;
          console.log("Is Within Range:", isWithinRange);

          newMarksAwarded = isWithinRange ? correctMark : wrongMark;
        }

        console.log("New Marks Awarded:", newMarksAwarded);

        // Update awarded marks for this question
        awardedMarks[key] = newMarksAwarded;

        // Recalculate total marks from scratch, considering only the best 5 marks in Section B
        const newTotalMarks = calculateCorrectTotalMarks();
        console.log("Updated Total Marks:", newTotalMarks);
        setTotalMarks(Math.max(newTotalMarks, 0));
        const calculatedPercentage = calculatePercentage(newTotalMarks);
        setPercentage(calculatedPercentage);
        console.log("Updated Percentage:", calculatedPercentage);
        // Update counts based on correctness of the response
        if (isCorrectResponse) {
          if (!wasPreviouslyCorrect) {
            setRightAnsweredCount((prevCount) => prevCount + 1);
            if (previousMarks < 0)
              setWrongAnsweredCount((prevCount) => prevCount - 1);
          }
        } else {
          if (wasPreviouslyCorrect) {
            setRightAnsweredCount((prevCount) => prevCount - 1);
          }
          setWrongAnsweredCount((prevCount) => prevCount + 1);
        }

        console.log("Updated awardedMarks:", awardedMarks);
      }

      // Update counts for answered questions
      if (!wasPreviouslyAnswered) {
        setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
        setNotAnsweredCount((prevCount) => prevCount - 1);
      }

      // Increment the count for section-B questions answered
      if (currentSectionIndex === 1) {
        setSectionBAnsweredCount((prevCount) => prevCount + 1);
      }

      // Move to the next question if available, else move to the next section or subject
      if (currentQuestionIndex < currentSection.questions.length - 1) {
        nextQuestion();
      } else {
        const mockTestsArray = Object.values(mockTestsData);
        const currentSubjectIndex = mockTestsArray.findIndex(
          (subject) => subject.SubjectName === selectedSubject
        );
        const nextSectionIndex = currentSectionIndex + 1;

        // Check if there is a next section in the current subject
        if (nextSectionIndex < currentSubject.sections.length) {
          // Move to the next section
          setCurrentSectionIndex(nextSectionIndex);
          setCurrentQuestionIndex(0); // Start from the first question in the next section
        } else {
          if (currentSubjectIndex < mockTestsData.length - 1) {
            const nextSubject = mockTestsData[currentSubjectIndex + 1];
            setSelectedSubject(nextSubject.SubjectName);
            setCurrentSectionIndex(0); // Start from the first section of the next subject
            setCurrentQuestionIndex(0); // Start from the first question in the first section
          }
        }
      }
    } else {
      // If the response is undefined, prompt the user to answer the question before proceeding
      alert("Please answer the current question before proceeding.");
    }
  };

  const goToQuestion = (index) => {
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentSection.questions[index].question_id
    );
    setViewedQuestions({ ...viewedQuestions, [key]: true });
    setCurrentQuestionIndex(index);
  };

  const handleSubjectChange = (subjectName) => {
    setSelectedSubject(subjectName);
    setCurrentSectionIndex(0);
    setCurrentQuestionIndex(0);
  };

  const handleSectionChange = (index) => {
    setCurrentSectionIndex(index);
    setCurrentQuestionIndex(0);
  };

  const calculateCounts = () => {
    let answeredCount = 0;
    let markedForReviewCount = 0;
    let notVisitedCount = 0;

    questionsData.forEach((subject) => {
      subject.sections.forEach((section, sectionIndex) => {
        section.questions.forEach((question) => {
          const key = getQuestionKey(
            subject.SubjectName,
            sectionIndex,
            question.question_id
          );
          if (responses[key] !== undefined) {
            if (responses[key] !== null) {
              answeredCount++;
            } else {
              if (!viewedQuestions[key] && !markedForReview[key]) {
                notVisitedCount++; // If not answered, not marked for review, and not visited yet
              }
            }
          } else {
            if (!viewedQuestions[key] && !markedForReview[key]) {
              notVisitedCount++; // If not answered, not marked for review, and not visited yet
            }
          }
          if (markedForReview[key]) {
            markedForReviewCount++;
          }
        });
      });
    });

    const totalQuestions = questionsData.reduce(
      (acc, subject) =>
        acc +
        subject.sections.reduce((acc, sec) => acc + sec.questions.length, 0),
      0
    );

    const notAnsweredCount =
      totalQuestions - answeredCount - markedForReviewCount - notVisitedCount;

    return {
      answeredCount,
      markedForReviewCount,
      notVisitedCount,
      notAnsweredCount,
    };
  };

  const [countDown, setCountDown] = useState(duration * 60);
  const [timeSpent, setTimeSpent] = useState(0);
  const timerId = useRef(null);

  // Countdown logic
  useEffect(() => {
    if (countDown > 0) {
      timerId.current = setInterval(() => {
        setCountDown((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timerId.current);
    }
  }, [countDown]);
  // Calculate the time spent based on the initial duration and current countdown
  useEffect(() => {
    const initialDurationInSeconds = duration * 60;
    setTimeSpent(initialDurationInSeconds - countDown);
  }, [countDown, duration]);

  // Convert seconds into hours, minutes, and seconds
  const hours = Math.floor(countDown / 3600);
  const minutes = Math.floor((countDown % 3600) / 60);
  const seconds = countDown % 60;

  // Calculate time spent in hours, minutes, and seconds
  const spentHours = Math.floor(timeSpent / 3600);
  const spentMinutes = Math.floor((timeSpent % 3600) / 60);
  const spentSeconds = timeSpent % 60;

  // State variable to store the formatted time spent
  const [formattedTimeSpent, setFormattedTimeSpent] = useState("");

  // Function to format time as HH:MM:SS
  const formatTime = (hours, minutes, seconds) => {
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  // Convert total duration to seconds
  const totalDurationInSeconds = duration * 60;

  // Convert formatted time spent to seconds for remaining time calculation
  const formattedTimeParts = formattedTimeSpent.split(":").map(Number);
  const formattedTimeInSeconds =
    formattedTimeParts[0] * 3600 +
    formattedTimeParts[1] * 60 +
    formattedTimeParts[2];

  // Calculate remaining time display
  const remainingTimeInSeconds = duration * 60 - formattedTimeInSeconds;

  // Convert remaining time back to HH:MM:SS format
  const remainingHours = Math.floor(remainingTimeInSeconds / 3600);
  const remainingMinutes = Math.floor((remainingTimeInSeconds % 3600) / 60);
  const remainingSeconds = remainingTimeInSeconds % 60;

  const remainingTimeDisplay = `${remainingHours
    .toString()
    .padStart(2, "0")}:${remainingMinutes
    .toString()
    .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;

  // console.log(
  //   "TimeSpent,remainingTimedisplay",
  //   formattedTimeSpent,
  //   remainingTimeDisplay
  // );

  // Calculate percentage of time spent and remaining time
  const percentageSpent =
    (formattedTimeInSeconds / totalDurationInSeconds) * 100;
  const percentageRemaining =
    ((totalDurationInSeconds - formattedTimeInSeconds) /
      totalDurationInSeconds) *
    100;

  // console.log("hiiiiiiiiiiiiiii");
  // console.log(percentageSpent, percentageRemaining);

  const handleSubmit = () => {
    const formattedTime = formatTime(spentHours, spentMinutes, spentSeconds);
    setFormattedTimeSpent(formattedTime);
    setIsAutoSubmit(false);
    setShowPopupscore(true);
    setShowEvaluvationPopup(false);
  };

  const handlebuttoNo = () => {
    setShowPopup(false);
    setIsTimerRunning(true);
  };

  const handlebuttoYes = () => {
    setShowPopup(true);
    setShowPopupscore(false);
    setIsTimerRunning(false);
  };

  const handleShowEvaluvationPopup = () => {
    const formattedTime = formatTime(spentHours, spentMinutes, spentSeconds);
    setFormattedTimeSpent(formattedTime);
    setShowEvaluvationPopup(true);
    setShowPopup(false);
    setShowPopupscore(false);
    setIsTimerRunning(false);
  };

  const closePopup = () => {
    setShowEvaluvationPopup(false);
    setShowPopup(false);
    setShowPopupscore(false);
    window.location.href = "/bitsatots";
    window.close();
  };

  const currentSubject = questionsData.find(
    (subject) => subject.SubjectName === selectedSubject
  );
  const currentSection = currentSubject?.sections[currentSectionIndex];
  const currentQuestion = currentSection?.questions[currentQuestionIndex];

  const counts = calculateCounts();

  useEffect(() => {
    // Set the viewed status of the first question of the selected subject to true
    const firstQuestionKey = getQuestionKey(
      selectedSubject,
      0,
      currentSubject.sections[0].questions[0].question_id
    );
    setViewedQuestions({ ...viewedQuestions, [firstQuestionKey]: true });
  }, [selectedSubject]);

  const handleCheckboxChange = (optionIndex) => {
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    const selectedOptions = responses[key] || [];
    const selectedIndex = selectedOptions.indexOf(optionIndex);

    if (selectedIndex === -1) {
      // If option is not selected, add it to the array
      setResponses({
        ...responses,
        [key]: [...selectedOptions, optionIndex],
      });
    } else {
      // If option is already selected, remove it from the array
      selectedOptions.splice(selectedIndex, 1);
      setResponses({
        ...responses,
        [key]: selectedOptions,
      });
    }

    // Log the selected index
    // console.log("Selected Index:", optionIndex);
  };

  // ******************************solution code******************
  // const handleShowSolution = (solution) => {
  //   setCurrentSolution(solution);
  //   setShowSolution(true);
  // };
  const handleShowSolution = (solution) => {
    // Check if the current solution is the one being clicked
    if (currentSolution === solution) {
      // Toggle the visibility
      setShowSolution(!showSolution);
    } else {
      // Show the new solution
      setCurrentSolution(solution);
      setShowSolution(true);
    }
  };
  // useEffect(() => {
  //   if (isTimerRunningquestion) {
  //     startTimer();
  //   }

  //   return () => stopTimer(); // Cleanup timer on unmount or when question changes
  // }, [activeQuestion, isTimerRunningquestion]);
  // const startTimer = () => {
  //   stopTimer(); // Ensure any existing timer is stopped
  //   setTimer(
  //     setInterval(() => {
  //       setQuestionTimes((prevTimes) => {
  //         const newTimes = [...prevTimes];
  //         newTimes[activeQuestion] += 1; // Increment time for the active question
  //         return newTimes;
  //       });
  //     }, 1000) // Increment every second
  //   );
  // };

  // const stopTimer = () => {
  //   if (timer) {
  //     clearInterval(timer);
  //     setTimer(null);
  //   }
  // };

  const calculateCorrectTotalMarks = () => {
    let totalMarks = 0;

    console.log("Awarded Marks:", awardedMarks); // Debugging line to check contents

    // Calculate total marks for sections other than Section B
    for (const key in awardedMarks) {
      if (!key.includes("-1-")) {
        const mark = awardedMarks[key];
        if (typeof mark === "number" && !isNaN(mark)) {
          totalMarks += mark;
        } else {
          console.warn(`Invalid mark found for key ${key}:`, mark);
        }
      }
    }

    console.log("Total Marks after sections other than B:", totalMarks); // Debugging

    // Calculate total marks for Section B
    const sectionBMarks = [];
    for (const key in awardedMarks) {
      if (key.includes("-1-")) {
        const mark = awardedMarks[key];
        if (typeof mark === "number" && !isNaN(mark)) {
          sectionBMarks.push(mark);
        } else {
          console.warn(`Invalid mark found for key ${key}:`, mark);
        }
      }
    }

    console.log("Section B Marks:", sectionBMarks); // Debugging

    // Sort Section B marks in descending order and take the top 4
    sectionBMarks.sort((a, b) => b - a);
    totalMarks += sectionBMarks
      .slice(0, 4)
      .reduce((acc, mark) => acc + mark, 0);

    console.log("Total Marks after including Section B:", totalMarks); // Debugging

    return totalMarks;
  };

  const calculatePercentage = (totalMarks) => {
    const totalPossibleMarks = 300; // Define the total possible marks
    const percentage = (totalMarks / totalPossibleMarks) * 100; // Calculate the percentage
    return percentage.toFixed(2); // Return percentage rounded to 2 decimal places
  };

  // Use useEffect to update total marks and percentage when needed
  useEffect(() => {
    if (Object.keys(awardedMarks).length > 0) {
      const totalMarksCalculated = calculateCorrectTotalMarks();
      setTotalMarks(totalMarksCalculated); // Update state with total marks

      const calculatedPercentage = calculatePercentage(totalMarksCalculated);
      const finalPercentage =
        calculatedPercentage > 0 ? calculatedPercentage : 0;
      setPercentage(finalPercentage); // Update state with the calculated percentage

      console.log("User's Total Marks:", totalMarksCalculated);
      console.log("User's Percentage:", calculatedPercentage);
    }
  }, [awardedMarks]);

  const pieData = {
    labels: ["Correct Answers", "Wrong Answers"],
    datasets: [
      {
        label: "Score Distribution",
        data: [rightAnsweredCount, wrongAnsweredCount],
        backgroundColor: ["#4CAF50", "#F44336"], // Colors for correct and wrong answers
        borderColor: ["#4CAF50", "#F44336"],
        borderWidth: 1,
      },
    ],
  };

  const RightAnsweredMarks= rightAnsweredCount * 4
  const wrongAnsweredMarks = wrongAnsweredCount *1
  // Pie Chart Options Configuration
  const pieOptions = {
    plugins: {
      datalabels: {
        color: "#fff", // Data label color
        formatter: (value, context) => {
          const label = context.chart.data.labels[context.dataIndex];
          return `${label}: ${value}`; // Display label and value
        },
      },
      legend: {
        position: "top",
        labels: {
          generateLabels: (chart) => {
            return chart.data.datasets[0].data.map((value, index) => ({
              text: `${chart.data.labels[index]}: ${value}`, // Label with data value
              fillStyle: chart.data.datasets[0].backgroundColor[index],
              hidden: !chart.data.datasets[0].data[index],
              index,
            }));
          },
        },
      },
    },
  };

  // Calculate time in HH:MM:SS format
  // const formatTime = (timeInSeconds) => {
  //   const hours = Math.floor(timeInSeconds / 3600);
  //   const minutes = Math.floor((timeInSeconds % 3600) / 60);
  //   const seconds = timeInSeconds % 60;
  //   return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
  //     .toString()
  //     .padStart(2, "0")}`;
  // };

  // const data = questionTimes.map((time, index) => ({
  //   name: `Q${index + 1}`,
  //   Time: time,
  // }));
  const [showPerformance, setShowPerformance] = useState(true);
  const handleShowPerformance = () => {
    setShowPerformance(true);
    setActiveButton("performance");
  };

  const handleShowSolutions = () => {
    setShowPerformance(false);
    setActiveButton("solutions");
  };

  const handleSubjectChangemenu = (event) => {
    setSelectedSubject(event.target.value);
  };

  const selectedSubjectData = questionsData.find(
    (subject) => subject.SubjectName === selectedSubject
  );

  // const handleDownloadPdf = () => {
  //   // Select the individual chart or component elements
  //   const elementsToCapture = [
  //     document.querySelector(".downloadscslassname"),
  //     document.querySelector(".Congratulationssubdiv"),
  //     document.querySelector(".CongratulationsTimeSpent"),
  //     document.querySelector(".Chart_container1 .pieContainer"),
  //     document.querySelector(".Chart_container1 .circularProgressBarContainer"),
  //   ];

  //   const pdf = new jsPDF("p", "mm", "a4");
  //   const pageWidth = 210; // A4 page width in mm
  //   const pageHeight = 297; // A4 page height in mm
  //   const margin = 10;
  //   const contentMargin = 10; // Margin between border and content
  //   const topBottomMargin = 15; // Additional space from top and bottom borders
  //   let yOffset = margin + topBottomMargin; // Initial offset including top margin

  //   // Text descriptions for each image
  //   const textDescriptions = [
  //     "eGRAD Tutor Mock Test Report",
  //     "Score Section",
  //     "Time Spent Analysis",
  //     "Correct/Wrong Answers Pie Chart",
  //     "Total Percentage",
  //   ];

  //   // Function to capture individual elements and add them to the PDF
  //   const captureAndAddToPdf = (elementIndex) => {
  //     if (elementIndex >= elementsToCapture.length) {
  //       // Save the PDF once all elements have been added
  //       pdf.save("charts.pdf");
  //       return;
  //     }

  //     const element = elementsToCapture[elementIndex];
  //     const description = textDescriptions[elementIndex];
  //     const scale = element.classList.contains("downloadscslassname") ? 3 : 2; // Zoom out for '.downloadscslassname'

  //     if (element.classList.contains("downloadscslassname")) {
  //       // Temporarily increase font size for '.downloadscslassname'
  //       element.style.fontSize = "18px"; // Adjust as needed
  //     }

  //     html2canvas(element, { scale: scale, useCORS: true }).then((canvas) => {
  //       const imgData = canvas.toDataURL("image/png");

  //       // Add description text inside the border
  //       pdf.setFontSize(12);
  //       pdf.text(description, margin + contentMargin, yOffset + contentMargin);
  //       yOffset += contentMargin + 10; // Add some space below the text

  //       // Calculate dimensions while maintaining aspect ratio
  //       const imgWidth = pageWidth - margin * 2 - 2 * contentMargin;
  //       const imgHeight = (canvas.height * imgWidth) / canvas.width;

  //       // Ensure the image fits within the page height
  //       const adjustedHeight =
  //         imgHeight > pageHeight - yOffset - margin - topBottomMargin
  //           ? pageHeight - yOffset - margin - topBottomMargin
  //           : imgHeight;
  //       const adjustedWidth = (canvas.width * adjustedHeight) / canvas.height;

  //       // Draw border around the content area
  //       pdf.rect(
  //         margin,
  //         margin,
  //         pageWidth - 2 * margin,
  //         pageHeight - 2 * margin,
  //         "S"
  //       );

  //       // Add the image to the PDF at the current offset, considering the border and content margin
  //       pdf.addImage(
  //         imgData,
  //         "PNG",
  //         margin + contentMargin,
  //         yOffset + contentMargin,
  //         adjustedWidth,
  //         adjustedHeight
  //       );

  //       yOffset += adjustedHeight + contentMargin + margin; // Update the offset for the next image

  //       // Check if a new page is needed
  //       if (yOffset + adjustedHeight + margin > pageHeight - topBottomMargin) {
  //         pdf.addPage(); // Add a new page
  //         yOffset = margin + topBottomMargin; // Reset the offset for the new page
  //       }

  //       // Revert font size change for '.downloadscslassname'
  //       if (element.classList.contains("downloadscslassname")) {
  //         element.style.fontSize = ""; // Revert to original font size
  //       }

  //       // Capture the next element
  //       captureAndAddToPdf(elementIndex + 1);
  //     });
  //   };

  //   // Start capturing elements
  //   captureAndAddToPdf(0);
  // };

  // const handleDownloadPdf = () => {
  //   const elementsToCapture = [
  //     document.querySelector(".downloadscslassname"),
  //     document.querySelector(".Congratulationssubdiv"),
  //     document.querySelector(".CongratulationsTimeSpent"),
  //     document.querySelector(".Chart_container1 .pieContainer"),
  //     document.querySelector(".Chart_container1 .circularProgressBarContainer"),
  //   ];

  //   const pdf = new jsPDF("p", "mm", "a4");
  //   const pageWidth = 210; // A4 page width in mm
  //   const pageHeight = 297; // A4 page height in mm
  //   const margin = 10;
  //   const contentMargin = 10; // Margin between border and content
  //   const topBottomMargin = 15; // Additional space from top and bottom borders
  //   let yOffset = margin + topBottomMargin; // Initial offset including top margin

  //   const textDescriptions = [
  //     "eGRAD Tutor Mock Test Report",
  //     "Score Section",
  //     "Time Spent Analysis",
  //     "Correct/Wrong Answers Pie Chart",
  //     "Total Percentage",
  //   ];

  //   // Function to load the logo as base64
  //   const addLogo = (callback) => {
  //     const image = new Image();
  //     image.onload = function () {
  //       const canvas = document.createElement("canvas");
  //       canvas.width = this.naturalWidth;
  //       canvas.height = this.naturalHeight;
  //       canvas.getContext("2d").drawImage(this, 0, 0);
  //       const base64data = canvas.toDataURL("image/png");

  //       const logoWidth = 80; // Adjust width of logo
  //       const logoHeight = 30; // Adjust height of logo
  //       const logoX = 10; // X position of logo
  //       const logoY = 10; // Y position of logo

  //       // Add the logo to the PDF
  //       pdf.addImage(base64data, "PNG", logoX, logoY, logoWidth, logoHeight);

  //       // Adjust yOffset to position the text below the logo
  //       const padding = 10; // Add some space between the logo and the text
  //       yOffset = logoY + logoHeight + padding; // Adjust yOffset for the text placement

  //       // Add the text below the logo
  //       pdf.setFontSize(16);
  //       pdf.text(textDescriptions[0], margin + contentMargin, yOffset);

  //       callback(); // Proceed to capture other elements
  //     };
  //     image.onerror = function (error) {
  //       console.error("Error loading logo image:", error);
  //       callback(); // Proceed even if the logo fails to load
  //     };
  //     image.src = logo; // Use the imported image
  //   };

  //   const captureAndAddToPdf = (elementIndex) => {
  //     if (elementIndex >= elementsToCapture.length) {
  //       pdf.save("charts.pdf");
  //       return;
  //     }

  //     const element = elementsToCapture[elementIndex];
  //     const description = textDescriptions[elementIndex];
  //     const scale = element.classList.contains("downloadscslassname") ? 3 : 2;

  //     if (element.classList.contains("downloadscslassname")) {
  //       element.style.fontSize = "18px"; // Adjust font size temporarily
  //     }

  //     html2canvas(element, { scale: scale, useCORS: true }).then((canvas) => {
  //       const imgData = canvas.toDataURL("image/png");

  //       pdf.setFontSize(12);
  //       pdf.text(description, margin + contentMargin, yOffset + contentMargin);
  //       yOffset += contentMargin + 10;

  //       const imgWidth = pageWidth - margin * 2 - 2 * contentMargin;
  //       const imgHeight = (canvas.height * imgWidth) / canvas.width;

  //       const adjustedHeight =
  //         imgHeight > pageHeight - yOffset - margin - topBottomMargin
  //           ? pageHeight - yOffset - margin - topBottomMargin
  //           : imgHeight;
  //       const adjustedWidth = (canvas.width * adjustedHeight) / canvas.height;

  //       pdf.addImage(
  //         imgData,
  //         "PNG",
  //         margin + contentMargin,
  //         yOffset + contentMargin,
  //         adjustedWidth,
  //         adjustedHeight
  //       );

  //       yOffset += adjustedHeight + contentMargin + margin;

  //       if (yOffset + adjustedHeight + margin > pageHeight - topBottomMargin) {
  //         pdf.addPage();
  //         yOffset = margin + topBottomMargin;
  //       }

  //       if (element.classList.contains("downloadscslassname")) {
  //         element.style.fontSize = "";
  //       }

  //       captureAndAddToPdf(elementIndex + 1);
  //     });
  //   };

  //   // First add the logo and title, then capture the elements
  //   addLogo(() => {
  //     captureAndAddToPdf(1); // Start capturing elements from index 1, since the first text is already added
  //   });
  // };

  // MAIN WORKING dOWNLOAD FUNCTIONALITY

  const handleDownloadPdf = () => {
    const elementsToCapture = [
      document.querySelector(".downloadscslassname"),
      document.querySelector(".Congratulationssubdiv"),
      document.querySelector(".CongratulationsTimeSpent"),
      document.querySelector(".Chart_container1 .pieContainer"),
      document.querySelector(".Chart_container1 .circularProgressBarContainer"),
    ];

    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = 210; // A4 page width in mm
    const pageHeight = 297; // A4 page height in mm
    const margin = 10;
    const contentMargin = 10; // Margin between border and content
    const topBottomMargin = 15; // Additional space from top and bottom borders
    let yOffset = margin + topBottomMargin; // Initial offset including top margin

    const textDescriptions = [
      "eGRAD Tutor Mock Test Report",
      "Score Section",
      "Time Spent Analysis",
      "Correct/Wrong Answers Pie Chart",
      "Total Percentage",
    ];

    // Function to load the logo as base64
    const addLogo = (callback) => {
      const image = new Image();
      image.onload = function () {
        const canvas = document.createElement("canvas");
        canvas.width = this.naturalWidth;
        canvas.height = this.naturalHeight;
        canvas.getContext("2d").drawImage(this, 0, 0);
        const base64data = canvas.toDataURL("image/png");

        const logoWidth = 80; // Adjust width of logo
        const logoHeight = 30; // Adjust height of logo
        const logoX = 10; // X position of logo
        const logoY = 10; // Y position of logo

        pdf.addImage(base64data, "PNG", logoX, logoY, logoWidth, logoHeight);

        const padding = 10; // Add some space between the logo and the text
        yOffset = logoY + logoHeight + padding;

        pdf.setFontSize(16);
        pdf.text(textDescriptions[0], margin + contentMargin, yOffset);

        callback();
      };
      image.onerror = function (error) {
        console.error("Error loading logo image:", error);
        callback(); // Proceed even if the logo fails to load
      };
      image.src = logo; // Use the imported image
    };

    const captureAndAddToPdf = (elementIndex) => {
      if (elementIndex >= elementsToCapture.length) {
        pdf.save("charts.pdf");
        return;
      }

      const element = elementsToCapture[elementIndex];
      const description = textDescriptions[elementIndex];
      const scale = element.classList.contains("downloadscslassname") ? 3 : 2;

      if (element.classList.contains("downloadscslassname")) {
        element.style.fontSize = "18px";
      }

      html2canvas(element, { scale: scale, useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        // Calculate image dimensions
        const imgWidth = pageWidth - margin * 2 - 2 * contentMargin;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        // Adjust image height and width if necessary
        let adjustedHeight = imgHeight;
        let adjustedWidth = imgWidth;

        if (
          yOffset + adjustedHeight + contentMargin + margin >
          pageHeight - topBottomMargin
        ) {
          // If content does not fit on the current page, add a new page
          pdf.addPage();
          yOffset = margin + topBottomMargin; // Reset yOffset for the new page
        }

        // Add text description
        pdf.setFontSize(12);
        pdf.text(description, margin + contentMargin, yOffset + contentMargin);
        yOffset += contentMargin + 10;

        // Add image
        pdf.addImage(
          imgData,
          "PNG",
          margin + contentMargin,
          yOffset + contentMargin,
          adjustedWidth,
          adjustedHeight
        );

        // Update yOffset for the next element
        yOffset += adjustedHeight + contentMargin + margin;

        if (element.classList.contains("downloadscslassname")) {
          element.style.fontSize = "";
        }

        captureAndAddToPdf(elementIndex + 1);
      });
    };

    // Start the process by adding the logo and then capturing other elements
    addLogo(() => {
      captureAndAddToPdf(1); // Start capturing elements from index 1
    });
  };

  return (
    <div>
      <div className="mocktestparent_conatiner">
        {/* -------------------header------- */}
        <div>
          <div className="remaining">
            <div className="bitsatMockHeader">
              <img
                src="https://www.egradtutor.in/static/media/egate%20logo%201.8b1eb31347d60945925f.png"
                alt=""
              />
            </div>

            <div className="JEE_MAINS_MOCKTEST_HEADING">
              <h4>{questionsData[0].Test_Name}</h4>
            </div>

            {/* <h4>{Jee_mains_mocktest1.Title}</h4> */}
          </div>
        </div>
        {/* -------------------interface------- */}
        <div>
          <div className="mocktest_conatiner_testwithpallet">
            <div className="mocktest_conatiner_testwithpalletquizcontainer quizPagewatermark">
              <div className="mocktest_conatiner_testwithpalletquizcontainersub ">
                {/* -------------------sectionbtns------- */}
                <div className="sectionbtnmock">
                  <div className="Jee_Mains_Exam_Subject_names_container">
                    {questionsData.map((subject) => (
                      <button
                        key={subject.SubjectName}
                        onClick={() => handleSubjectChange(subject.SubjectName)}
                        className={
                          selectedSubject === subject.SubjectName
                            ? "active"
                            : ""
                        }
                      >
                        {subject.SubjectName}
                      </button>
                    ))}
                  </div>
                </div>

                {/* <div className="sectionbtnmock_jee">
                  {selectedSubject && (
                    <div className="section-selection">
                      {currentSubject.sections.map((section, index) => (
                        <button
                          key={section.SectionName}
                          onClick={() => handleSectionChange(index)}
                          className={
                            currentSectionIndex === index ? "active" : ""
                          }
                        >
                          {section.SectionName}
                        </button>
                      ))}
                    </div>
                  )}


                  
                </div> */}

                <div className="sectionbtnmock_jee">
                  {selectedSubject && (
                    <div className="section-selection">
                      {currentSubject.sections.map((section, index) => (
                        <button
                          id="jee_advance_section_button"
                          key={section.SectionName}
                          onClick={() => handleSectionChange(index)}
                          className={
                            currentSectionIndex === index ? "active" : ""
                          }
                        >
                          {section.SectionName}
                        </button>
                      ))}
                    </div>
                  )}
                </div>

                <div className="Jee_mains_exam_Q.s_Type_container">
                  <div>
                    <p>Question Type: {currentQuestion.qtype}</p>
                  </div>
                  <div className="Jee_Mains_Timing_container">
                    <p className="Jee_Mains_Timing_paragraph">
                      {/* <LuAlarmClock /> Time Left: {formatTime(remainingTime)} */}
                      Time Left: {hours.toString().padStart(2, "0")}:
                      {minutes.toString().padStart(2, "0")}:
                      {seconds.toString().padStart(2, "0")}
                      {/* {`${Math.floor(remainingTime / 3600)}:${Math.floor(
                        (remainingTime % 3600) / 60
                      )}:${remainingTime % 60}`} */}
                    </p>
                    {/* <p>Time Spent:{spentHours.toString().padStart(2, "0")}
                                  :{spentMinutes.toString().padStart(2, "0")}:
                                  {spentSeconds.toString().padStart(2, "0")}</p> */}
                  </div>
                </div>
                <div className="Jee_mains_exam_questions_btn_container">
                  <div className="mocktest_conatiner_testwithpalletquizcontainersubintopart tabs_view_css">
                    {selectedSubject && currentQuestion && (
                      <div className="Jee_mains_Questions&_options_container">
                        <p>Question {currentQuestion.question_id}:</p>
                        <img src={currentQuestion.questionImgName} alt="" />

                        <div className="optionimginmock">
                          {(currentQuestion.qtype === "MCQ4" ||
                            currentQuestion.qtype === "MCQ5") && (
                            <div className="optionimginmock">
                              {/* <p>Options:</p> */}
                              {currentQuestion.options.map((option) => (
                                <label
                                  key={option.option_id}
                                  className="option"
                                >
                                  <input
                                    type="radio"
                                    id={`option-${option.option_id}`}
                                    name={`question-${currentQuestion.question_id}`}
                                    checked={
                                      responses[
                                        getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        )
                                      ] === option.option_index
                                    }
                                    onChange={() =>
                                      handleOptionChange(option.option_index)
                                    }
                                  />
                                  <span htmlFor={`option-${option.option_id}`}>
                                    ({option.option_index})
                                  </span>
                                  <img src={option.optionImgName} alt="" />
                                </label>
                              ))}
                            </div>
                          )}
                          {(currentQuestion.qtype === "MSQ" ||
                            currentQuestion.qtype === "MSQN") && (
                            <div className="optionimginmock">
                              {currentQuestion.options.map((option) => (
                                <label
                                  key={option.option_id}
                                  className="option"
                                >
                                  <input
                                    type="checkbox"
                                    id={`option-${option.option_id}`}
                                    checked={
                                      responses[
                                        getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        )
                                      ] &&
                                      responses[
                                        getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        )
                                      ].includes(option.option_index)
                                    }
                                    onChange={() =>
                                      handleCheckboxChange(option.option_index)
                                    }
                                  />
                                  <span htmlFor={`option-${option.option_id}`}>
                                    ({option.option_index})
                                  </span>
                                  <img src={option.optionImgName} alt="" />
                                </label>
                              ))}
                            </div>
                          )}

                          {currentQuestion.qtype === "NATI" && (
                            <div className="calculator">
                              <div className="display">
                                <input
                                  type="text"
                                  value={getInputValue(
                                    selectedSubject,
                                    currentSectionIndex,
                                    currentQuestion.question_id
                                  )}
                                  readOnly // Make the input read-only to prevent direct editing
                                  placeholder="Enter your answer"
                                />
                              </div>

                              {/* Button for DEL */}
                              <div>
                                <input
                                  type="button"
                                  value="DEL"
                                  onClick={() => {
                                    const key = getQuestionKey(
                                      selectedSubject,
                                      currentSectionIndex,
                                      currentQuestion.question_id
                                    );
                                    const currentValue =
                                      getInputValue(
                                        currentQuestion.question_id
                                      ) || "";
                                    updateInputValue(
                                      selectedSubject,
                                      currentSectionIndex,
                                      currentQuestion.question_id,
                                      (getInputValue(
                                        currentQuestion.question_id
                                      ) || "") + "DEL"
                                    );
                                  }}
                                />
                              </div>

                              {/* Buttons for numbers */}
                              <div>
                                <div>
                                  {[7, 8, 9].map((num) => (
                                    <input
                                      key={num}
                                      type="button"
                                      value={num}
                                      onClick={() => {
                                        const key = getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        );
                                        const currentValue =
                                          getInputValue(
                                            currentQuestion.question_id
                                          ) || "";
                                        updateInputValue(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id,
                                          (getInputValue(
                                            currentQuestion.question_id
                                          ) || "") + num
                                        );
                                      }}
                                    />
                                  ))}
                                </div>
                                <div>
                                  {[4, 5, 6].map((num) => (
                                    <input
                                      key={num}
                                      type="button"
                                      value={num}
                                      onClick={() => {
                                        const key = getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        );
                                        const currentValue =
                                          getInputValue(
                                            currentQuestion.question_id
                                          ) || "";
                                        updateInputValue(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id,
                                          (getInputValue(
                                            currentQuestion.question_id
                                          ) || "") + num
                                        );
                                      }}
                                    />
                                  ))}
                                </div>
                                <div>
                                  {[1, 2, 3].map((num) => (
                                    <input
                                      key={num}
                                      type="button"
                                      value={num}
                                      onClick={() => {
                                        const key = getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        );
                                        const currentValue =
                                          getInputValue(
                                            currentQuestion.question_id
                                          ) || "";
                                        updateInputValue(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id,
                                          (getInputValue(
                                            currentQuestion.question_id
                                          ) || "") + num
                                        );
                                      }}
                                    />
                                  ))}
                                </div>
                                <div>
                                  {[0].map((num) => (
                                    <input
                                      key={num}
                                      type="button"
                                      value={num}
                                      onClick={() => {
                                        const key = getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        );
                                        const currentValue =
                                          getInputValue(
                                            currentQuestion.question_id
                                          ) || "";
                                        updateInputValue(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id,
                                          (getInputValue(
                                            currentQuestion.question_id
                                          ) || "") + num
                                        );
                                      }}
                                    />
                                  ))}
                                  <input
                                    type="button"
                                    value="."
                                    onClick={() => {
                                      const key = getQuestionKey(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id
                                      );
                                      const currentValue =
                                        getInputValue(
                                          currentQuestion.question_id
                                        ) || "";
                                      updateInputValue(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id,
                                        (getInputValue(
                                          currentQuestion.question_id
                                        ) || "") + "."
                                      );
                                    }}
                                  />
                                  <input
                                    type="button"
                                    value="-"
                                    onClick={() => {
                                      const key = getQuestionKey(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id
                                      );
                                      const currentValue =
                                        getInputValue(
                                          currentQuestion.question_id
                                        ) || "";
                                      updateInputValue(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id,
                                        (getInputValue(
                                          currentQuestion.question_id
                                        ) || "") + "-"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {currentQuestion.qtype === "NATD" && (
                            <div className="calculator">
                              <div className="display">
                                <input
                                  type="text"
                                  value={getInputValue(
                                    selectedSubject,
                                    currentSectionIndex,
                                    currentQuestion.question_id
                                  )}
                                  readOnly // Make the input read-only to prevent direct editing
                                  placeholder="Enter your answer"
                                />
                              </div>

                              {/* Button for DEL */}
                              <div>
                                <input
                                  type="button"
                                  value="DEL"
                                  onClick={() => {
                                    const key = getQuestionKey(
                                      selectedSubject,
                                      currentSectionIndex,
                                      currentQuestion.question_id
                                    );
                                    const currentValue =
                                      getInputValue(
                                        currentQuestion.question_id
                                      ) || "";
                                    updateInputValue(
                                      selectedSubject,
                                      currentSectionIndex,
                                      currentQuestion.question_id,
                                      currentValue.slice(0, -1)
                                    );
                                  }}
                                />
                              </div>

                              {/* Buttons for numbers */}
                              <div>
                                <div>
                                  {[7, 8, 9].map((num) => (
                                    <input
                                      key={num}
                                      type="button"
                                      value={num}
                                      onClick={() => {
                                        const key = getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        );
                                        const currentValue =
                                          getInputValue(
                                            currentQuestion.question_id
                                          ) || "";
                                        updateInputValue(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id,
                                          (getInputValue(
                                            currentQuestion.question_id
                                          ) || "") + num
                                        );
                                      }}
                                    />
                                  ))}
                                </div>
                                <div>
                                  {[4, 5, 6].map((num) => (
                                    <input
                                      key={num}
                                      type="button"
                                      value={num}
                                      onClick={() => {
                                        const key = getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        );
                                        const currentValue =
                                          getInputValue(
                                            currentQuestion.question_id
                                          ) || "";
                                        updateInputValue(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id,
                                          (getInputValue(
                                            currentQuestion.question_id
                                          ) || "") + num
                                        );
                                      }}
                                    />
                                  ))}
                                </div>
                                <div>
                                  {[1, 2, 3].map((num) => (
                                    <input
                                      key={num}
                                      type="button"
                                      value={num}
                                      onClick={() => {
                                        const key = getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        );
                                        const currentValue =
                                          getInputValue(
                                            currentQuestion.question_id
                                          ) || "";
                                        updateInputValue(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id,
                                          (getInputValue(
                                            currentQuestion.question_id
                                          ) || "") + num
                                        );
                                      }}
                                    />
                                  ))}
                                </div>
                                <div>
                                  {[0].map((num) => (
                                    <input
                                      key={num}
                                      type="button"
                                      value={num}
                                      onClick={() => {
                                        const key = getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        );
                                        const currentValue =
                                          getInputValue(
                                            currentQuestion.question_id
                                          ) || "";
                                        updateInputValue(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id,
                                          (getInputValue(
                                            currentQuestion.question_id
                                          ) || "") + num
                                        );
                                      }}
                                    />
                                  ))}
                                  <input
                                    type="button"
                                    value="."
                                    onClick={() => {
                                      const key = getQuestionKey(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id
                                      );
                                      const currentValue =
                                        getInputValue(
                                          currentQuestion.question_id
                                        ) || "";
                                      updateInputValue(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id,
                                        (getInputValue(
                                          currentQuestion.question_id
                                        ) || "") + "."
                                      );
                                    }}
                                  />
                                  <input
                                    type="button"
                                    value="-"
                                    onClick={() => {
                                      const key = getQuestionKey(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id
                                      );
                                      const currentValue =
                                        getInputValue(
                                          currentQuestion.question_id
                                        ) || "";
                                      updateInputValue(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id,
                                        (getInputValue(
                                          currentQuestion.question_id
                                        ) || "") + "-"
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              {/* Buttons for '.' and '-' */}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  {/* -------------------------------------------buttons Option container-------------- */}
                  <div>
                    <div class="mocktest_conatiner_testwithpalletbuttons">
                      {" "}
                      <div>
                        <button
                          class="Quiz_mockbtns Quiz_mockbtnssubmit"
                          onClick={nextQuestion}
                        >
                          Next
                        </button>
                        <button
                          class="Quiz_clearResponse Quiz_mockbtns Quiz_mockbtnsclear"
                          onClick={clearResponse}
                        >
                          Clear Response
                        </button>
                        {/* <button onClick={markForReview}>Mark For Review</button> */}
                        <button
                          class="quizsave_next previous_next_btn Quiz_mockbtns"
                          onClick={saveAndNext}
                        >
                          Save &amp; Next
                        </button>
                      </div>
                      <div>
                        <button
                          class="Quiz_mockbtns Quiz_mockbtnspre"
                          onClick={previousQuestion}
                        >
                          Previous
                        </button>

                        <button
                          class="Quiz_mockbtns Quiz_mockbtnssubmit"
                          onClick={handlebuttoYes}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {/* <div className="hamburger-menu" onClick={toggleMenu}>
                &#9776; 
              </div> */}

              <div className="hamburger-menu" onClick={toggleMenu}>
                {/* Conditional rendering based on the state */}
                {isMenuOpen ? (
                  <span>&#10005;</span> // Cross mark (✖)
                ) : (
                  <span>&#9776;</span> // Hamburger menu (☰)
                )}
              </div>

              <div className={`numberpalette ${isMenuVisible ? "show" : ""}`}>
                <div class="rightSidebar-topHeader">
                  <img
                    title="Guest"
                    src="/static/media/guesticon.00ad06aaa8a7af8eb6c5.jpg"
                    alt="guesticon"
                  />
                  <p className="Current_test_name">Candidate Name: Guest</p>
                  <p className="Current_test_name">
                    {questionsData[0].Test_Name}
                  </p>
                </div>
                <div className="yoursection_inMockTest">
                  <span>
                    You are viewing{" "}
                    <label htmlFor="">
                      {currentSubject.SubjectName} -{" "}
                      {currentSection.SectionName}
                    </label>
                    {/* <label htmlFor="">{currentSection.SectionName}</label> */}
                  </span>

                  <b className="yoursection_inMockTest_btag">
                    Question Palette
                  </b>
                </div>
                <div className="question-number-palette question-numberpalette question-palette">
                  {currentSection.questions.map((question, index) => {
                    const key = getQuestionKey(
                      selectedSubject,
                      currentSectionIndex,
                      question.question_id
                    );
                    return (
                      <button
                        key={question.question_id}
                        className={`
                    ${index === currentQuestionIndex ? "active" : ""}
                    ${
                      answeredQuestions[key] && !markedForReview[key]
                        ? "answered"
                        : ""
                    }
                    ${viewedQuestions[key] && !responses[key] ? "viewed" : ""}
                    ${
                      markedForReview[key]
                        ? responses[key]
                          ? "marked-answered"
                          : "marked"
                        : ""
                    }
                    ${
                      responses[key] !== undefined && !answeredQuestions[key]
                        ? "answered-red active"
                        : ""
                    }
                  `}
                        onClick={() => goToQuestion(index)}
                      >
                        {index + 1}
                      </button>
                    );
                  })}
                </div>
                <div className="sidebar-footer">
                  <h4 className="sidebar-footer-header">Legend:</h4>
                  <div className="footer-btns">
                    <div className="inst-btns">
                      {/* <img src={grayBox} alt="Not Visited" /> */}
                      <span className="NotVisited">
                        {counts.notVisitedCount}
                      </span>

                      <label htmlFor="">Not Visited</label>
                    </div>
                    <div className="inst-btns">
                      {/* <img src={greenBox} alt="Answered" /> */}
                      <span className="Answered">{answeredCount}</span>
                      <label htmlFor="">Answered</label>
                    </div>
                    <div className="inst-btns">
                      {/* <img src={orangeBox} alt="Not Answered" /> */}
                      <span className="NotAnsweredlegend">
                        {" "}
                        {counts.notAnsweredCount}
                      </span>
                      <label htmlFor="">Not Answered</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="scorecardforexam">
        {/* {showPopup && (
          <div className="popup2">
            <div className="popup-content">
              {remainingTime === 0 ? (
                <div>
                  <p>Your Time is up!</p>
                  <p>Your test is automatically submitted successfully.</p>
                </div>
              ) : (
                <div>
                  <p>The Actual Test time is of 180 mins.</p>
                  <p>Do you want to Continue submiting the test</p>
                </div>
              )}
              <ul class="allscorecountingmock">
                <li>
                  <b>Answered:</b> {answeredCount}
                </li>
                <li>
                  <b>Not Answered:</b> {notAnsweredCount}
                </li>
                <li>
                  <b>Not Visited:</b> {counts.notVisitedCount}
                </li>
              </ul>
              <div className="Jeemains_mocktest_yes_no_buttons_container">
                <button   onClick={handleSubmit}>Yes</button>
                <button  onClick={handlebuttoNo}>No</button>
              </div>
            </div>
          </div>
        )} */}
        <div className="scorecardforexam">
          {showPopup && (
            <div className="popup2">
              <div className="popup-content">
                {remainingTime === 0 ? (
                  <div>
                    <div>
                      <p>Your Time is up!</p>
                      <p>Your test is automatically submitted successfully.</p>
                    </div>
                    {/* <ul class="allscorecountingmock">
                      <li>
                        <b>Answered:</b> {answeredCount}
                      </li>
                      <li>
                        <b>Not Answered:</b> {notAnsweredCount}
                      </li>
                      <li>
                        <b>Not Visited:</b> {counts.notVisitedCount}
                      </li>
                    </ul> */}
                    <div className="Jeemains_mocktest_yes_no_buttons_container">
                      <button
                        title="Press okay and view report"
                        className="MockTest_submit_Yes_btn"
                        // onClick={handleSubmit}
                        onClick={handleShowEvaluvationPopup}
                      >
                        Okay
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      <p>The Actual Test time is of 180 mins.</p>
                      <p>Do you want to Continue submiting the test</p>
                    </div>
                    {/* <ul class="allscorecountingmock">
                      <li>
                        <b>Answered:</b> {answeredCount}
                      </li>
                      <li>
                        <b>Not Answered:</b> {notAnsweredCount}
                      </li>
                      <li>
                        <b>Not Visited:</b> {counts.notVisitedCount}
                      </li>
                    </ul> */}
                    <div className="Jeemains_mocktest_yes_no_buttons_container">
                      <button
                        title="Save Test and Exit"
                        className="MockTest_submit_Yes_btn"
                        // onClick={handleSubmit}
                        onClick={handleShowEvaluvationPopup}
                      >
                        Yes
                      </button>
                      <button
                        title="Return to Test"
                        className="MockTest_submit_No_btn"
                        onClick={handlebuttoNo}
                      >
                        No
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="scorecardforexam">
            {showEvaluvationPopup && (
              <div className="Evaluvationpopup">
                <div className="popupcontentEvaluvation">
                  <p className="Evaluvationsummary">Test Summary</p>
                  <div>
                    <div className="EvaluvationSuccessfully">Your Test Submitted Successfully</div>
                    {/* <p className="EvaluvationsummaryThank">Thank you for submitting your test.The summary of the test given below.</p> */}
                    <div className="EvaluvationsummaryTestName">
                    <div className="EvaluvationsummaryTest">Test Name :</div><div> {questionsData[0].Test_Name}</div>
                    </div>
                    <div>
                      <table>
                        <tr>
                          <th>Total Questions</th>
                          <th>Maximum Marks</th>
                          <th>Total Attempted</th>
                          <th>Left Questions</th>
                          <th>Correct Questions</th>
                          <th>InCorrect Questions</th>
                          <th>Total Time(in min.)</th>
                          <th>Time Taken(in min.)</th>
                          <th>Right Marks</th>
                          <th>Negative Marks</th>
                          <th>Total Marks</th>
                          </tr>
                          <tr>
                          <td>90</td>
                            <td>360</td>
                            <td>{answeredCount}</td>
                            <td>{notAnsweredCount}</td>
                            <td>{rightAnsweredCount}</td>
                            <td>{wrongAnsweredCount}</td>
                            <td>180</td>
                            <td>{formattedTimeSpent}</td>
                            <td>{RightAnsweredMarks}</td>
                            <td>{wrongAnsweredMarks}</td>
                            <td>{totalMarks}</td>
                          </tr>
                      </table>
                      <div>
                        <button onClick={handleSubmit} className="ReportSolutions">View Report/Solutions</button>
                      <button
                          className="mocktestClosebtnPieChartpage"
                          onClick={closePopup}
                        >
                          Close
                        </button> </div>
                    </div>
                  </div>
                   </div>
              </div>
            )}
          </div>
          <div>
            {showPopupscore && (
              <div className="popup3">
                <div className="popup-contentscore mocksPopUpContentScoreContainer">
                  <div className="popup_header">
                    <div className="remaining">
                      <div className="popHeaderLogo ">
                        <img
                          src="https://www.egradtutor.in/static/media/egate%20logo%201.8b1eb31347d60945925f.png"
                          alt="Logo"
                        />
                      </div>

                      <div className="JEE_MAINS_MOCKTEST_HEADING">
                        <h4>{questionsData[0].Test_Name}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="mocktestOverallPiechartsDivContainer">
                    <div className="mocktestdatadiv mocktestOverallPiechartsDiv">
                      <div className="Repostpagepopupclosebutton">
                        <button
                          className="mocktestClosebtnPieChartpage"
                          onClick={closePopup}
                        >
                          Close
                        </button>
                      </div>
                      <div className="downloadscslassname">
                        <div className="mocktestdataheading mocktestPieChartsdataheading">
                          <p className="mocktestHeadingPiechartpage">
                            Test Details
                          </p>
                        </div>

                        {/* <div className="mocktestdataheading">Test Details</div> */}
                        <div className="mocktestdatasubdiv mocktestOverallPiechartsSubDiv">
                          {" "}
                          <div className="mocktestdatasubdiv11 mocksPagePieChartsTestDetailsBC">
                            Test Name:{" "}
                            <span className="mocksTestPieChartspan">
                              {questionsData[0].Test_Name}
                            </span>
                          </div>
                          <div className="mocktestdatasubdiv12 mocksPagePieChartsTestDetailsBC">
                            Subjects:{" "}
                            <span className="mocksTestPieChartspan">
                              {questionsData
                                .map((subject) => subject.SubjectName)
                                .join(", ")}
                            </span>
                          </div>
                          <div className="mocktestdatasubdiv13 mocksPagePieChartsTestDetailsBC">
                            Test Duration:{" "}
                            <span className="mocksTestPieChartspan">
                              180 Mins
                            </span>
                          </div>
                          <div className="mocktestdatasubdiv14 mocksPagePieChartsTestDetailsBC">
                            Total Questions:
                            <span className="mocksTestPieChartspan">90</span>
                          </div>
                          <div className="mocktestdatasubdiv15 mocksPagePieChartsTestDetailsBC">
                            Total Marks:{" "}
                            <span className="mocksTestPieChartspan">300</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Add buttons for Performance and Solutions */}
                    <div className="containerInPerformance mocksPagePieChartsContainerPerform">
                      <div className="button-container">
                        <div className="Progress_solution_container">
                          <button
                            id="performance_solution"
                            className={
                              activeButton === "performance" ? "active" : ""
                            }
                            onClick={handleShowPerformance}
                          >
                            Your Performance
                          </button>
                          <button
                            id="performance_solution"
                            className={
                              activeButton === "solutions" ? "active" : ""
                            }
                            onClick={handleShowSolutions}
                          >
                            Solutions
                          </button>
                        </div>
                      </div>

                      <div>
                        {showPerformance ? (
                          <div className="Chart_container mocksPagePieChartsTestDetailsChatContainerDiv">
                            <div className="download-button mockTestsdownloadbtnDivPicharts">
                              <button
                                className="mockTestsdownloadbtnPicharts"
                                onClick={handleDownloadPdf}
                              >
                                Download PDF{" "}
                                <HiDownload className="mocktestdownloadPiechartsIconn" />
                              </button>
                            </div>

                            <div className="Congratulationsdiv mocksPagePieChartsTestDetailsCongratsDiv">
                              {/* Download Button */}

                              <div className="Congratulationssubdiv mocksPagePieChartsTestDetailsCongratsSubDiv">
                                <div>
                                  <img
                                    src="/static/media/prize.e48700d2bb773b1eda58.jpg"
                                    alt="Congratulations"
                                  />
                                </div>
                                <div className="Mocktest_result_page_scrore_container mockspageResultsScore">
                                  {/* <h2>{message}</h2> */}
                                  <span className="mockscore">
                                    Your score: {totalMarks}
                                  </span>
                                </div>
                              </div>

                              {/* Horizontal bar to display spent time and remaining time */}
                              <div className="Congratulationssubdiv CongratulationsTimeSpent mocksPagePieChartsTestDetailsCongratsSubDiv">
                                <h2 className="mocksPagePieChartsTimeHeading">
                                  Time Spent
                                </h2>
                                <div className="Time_bar_container">
                                  <div className="Time_Spent_Remaining_Time_container">
                                    <div className="Time_Spent">
                                      <p
                                        style={{
                                          backgroundColor: "#e96e6e",
                                          height: "15px",
                                          width: "30px",
                                          margin: "0 10px",
                                        }}
                                      ></p>
                                      <span>Time Spent</span>
                                    </div>
                                    <div className="Remaining_Time">
                                      <p
                                        style={{
                                          backgroundColor:
                                            "rgba(0, 0, 255, 0.44)",
                                          height: "15px",
                                          width: "30px",
                                          margin: "0 10px",
                                        }}
                                      ></p>
                                      <span>Remaining Time</span>
                                    </div>
                                  </div>
                                  {/* Display Total Time, Time Spent, and Remaining Time */}
                                  <div className="Time_spent_container">
                                    {/* <p><span>Total Time:</span> {duration} mins</p> */}
                                    <p>
                                      <span>Time Spent:</span>{" "}
                                      {formattedTimeSpent} mins
                                    </p>
                                    <p>
                                      <span>Remaining Time:</span>{" "}
                                      {remainingTimeDisplay} mins
                                    </p>
                                  </div>

                                  {/* Horizontal Bar representing time spent and remaining time */}
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      height: "30px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        // width: `${(formattedTimeSpent / duration) * 100}%`,
                                        width: `${percentageSpent}%`,
                                        backgroundColor: "#e96e6e",
                                        height: "20px",
                                      }}
                                    ></div>
                                    <div
                                      className="Remaining_time_container"
                                      style={{
                                        // width: `${(remainingTimeDisplay / duration) * 100
                                        //   }%`,
                                        width: `${percentageRemaining}%`,
                                        backgroundColor:
                                          "rgba(0, 0, 255, 0.44)",
                                        height: "20px",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="Chart_container1">
                              {/* Display the Pie Chart */}
                              <div
                                className="pieContainer"
                                // style={{ width: "300px", margin: "auto" }}
                              >
                                <Pie data={pieData} options={pieOptions} />
                                <p>Performance Analysis</p>
                              </div>
                              <div
                                className="circularProgressBarContainer"
                                // style={{ width: "155px", margin: "0 auto" }}
                              >
                                <CircularProgressbar
                                  value={percentage}
                                  text={`${percentage}%`}
                                  styles={buildStyles({
                                    pathColor: `rgba(255, 0, 0, ${
                                      (percentage / 100) * 0.5 + 0.5
                                    })`, // Red color
                                    textColor: "#007bff", // Blue color for text
                                    trailColor: "#ffefef", // Light red color for trail
                                    backgroundColor: "#ffffff",
                                  })}
                                />
                                <p>Your Total Percentage</p>
                              </div>
                            </div>

                            {/* Bar Chart to display time spent on each question */}
                            {/* <ResponsiveContainer width="50%" height={300}>
                            <BarChart
                              data={data}
                              margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name" />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                              <Bar dataKey="Time" fill="#8884d8" barSize={20} />
                            </BarChart>
                          </ResponsiveContainer> */}
                          </div>
                        ) : (
                          <div className="mocktestsolution">
                            {/* Dropdown to select subject */}
                            <div className="Mocktest_view_solution_sections_container">
                              <label htmlFor="subjectDropdown">
                                Select Subject:{" "}
                              </label>
                              <select
                                id="subjectDropdown"
                                value={selectedSubject}
                                onChange={handleSubjectChangemenu}
                              >
                                {questionsData.map((subject, index) => (
                                  <option
                                    key={index}
                                    value={subject.SubjectName}
                                  >
                                    {subject.SubjectName}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {/* Display selected subject's sections and questions */}
                            {/* {selectedSubjectData && (
                            <div>
                              {selectedSubjectData.sections.map((section) => (
                                <div key={section.SectionName}>
                                  <h2 className="Mocktest_reportpage_Solutio_Section-heading">
                                    {section.SectionName}
                                  </h2>
                               
                                  {section.questions.map((question) => {
                                   
                                    const correctAnswers =
                                      question.answer.split(",");

                                    return (
                                      <div
                                        key={question.question_id}
                                        style={{ marginBottom: "20px" }}
                                        className="solutionDiv"
                                      >
                                        <div className="questionDiv">
                                          <strong>
                                            Q{question.question_id}:{" "}
                                          </strong>
                                          <img
                                            src={question.questionImgName}
                                            alt=""
                                          />
                                        </div>
                                        <ul className="optionsUlDiv">
                                          {question.options.map((option) => (
                                            <li
                                              key={option.option_id}
                                              style={{ position: "relative" }}
                                            >
                                              ({option.option_index})
                                              <img
                                                src={option.optionImgName}
                                                alt=""
                                              />
                                            
                                              {correctAnswers.includes(
                                                option.option_index
                                              ) && (
                                                <span
                                                  className="solutions_correct_answer"
                                         
                                                >
                                                  ✔
                                                </span>
                                              )}
                                            </li>
                                          ))}
                                        </ul>
                                        <button
                                          onClick={() =>
                                            handleShowSolution(
                                              question.solution
                                            )
                                          }
                                        >
                                          {showSolution &&
                                          currentSolution === question.solution
                                            ? "Hide Solution"
                                            : "Show Solution"}
                                        </button>
                                        {showSolution &&
                                          currentSolution ===
                                            question.solution && (
                                            <div className="View_solution_container">
                                              <strong>Solution:</strong>{" "}
                                              <img
                                                className="MockTest_ReportPgae_solution_Imgtag"
                                                src={question.solution}
                                                alt=""
                                              />
                                            </div>
                                          )}
                                      </div>
                                    );
                                  })}
                                </div>
                              ))}
                            </div>
                          )} */}
                            {selectedSubjectData && (
                              <div>
                                {selectedSubjectData.sections.map(
                                  (section, sectionIndex) => (
                                    <div key={section.SectionName}>
                                      <h2 className="Mocktest_reportpage_Solutio_Section-heading">
                                        {section.SectionName}
                                      </h2>
                                      {section.questions.map((question) => {
                                        const correctAnswers =
                                          question.answer.split(",");
                                        const key = getQuestionKey(
                                          selectedSubject,
                                          sectionIndex,
                                          question.question_id
                                        );
                                        const userAnswer =
                                          question.qtype === "NATD" ||
                                          question.qtype === "NATI"
                                            ? getInputValue(
                                                selectedSubject,
                                                sectionIndex,
                                                question.question_id
                                              )
                                            : responses[key]; // Retrieve user's stored answer or input value for NATD/NATI

                                        // For NATD questions, check if the user answer is within the correct range
                                        let isIncorrect = false;
                                        if (
                                          question.qtype === "NATD" &&
                                          userAnswer
                                        ) {
                                          const [lowerRange, upperRange] =
                                            correctAnswers
                                              .split("-")
                                              .map((range) =>
                                                parseFloat(range)
                                              );

                                          isIncorrect = !(
                                            parseFloat(userAnswer) >=
                                              lowerRange &&
                                            parseFloat(userAnswer) <= upperRange
                                          );
                                        }

                                        return (
                                          <div
                                            key={question.question_id}
                                            style={{ marginBottom: "20px" }}
                                            className="solutionDiv"
                                          >
                                            <div className="questionDiv">
                                              <strong>
                                                Q{question.question_id}:{" "}
                                              </strong>
                                              <img
                                                src={question.questionImgName}
                                                alt=""
                                              />
                                            </div>

                                            {/* Handling NATD or NATI type questions */}
                                            {question.qtype === "NATD" ||
                                            question.qtype === "NATI" ? (
                                              <div className="nat-answer">
                                                <p>
                                                  <strong>
                                                    Correct Answer:
                                                  </strong>{" "}
                                                  {question.answer}
                                                </p>
                                                <p
                                                  className={
                                                    question.qtype === "NATD" &&
                                                    isIncorrect
                                                      ? "natd-incorrect"
                                                      : ""
                                                  }
                                                >
                                                  <strong>Your Answer:</strong>{" "}
                                                  {userAnswer ||
                                                    "No answer provided"}
                                                </p>
                                              </div>
                                            ) : (
                                              <ul className="optionsUlDiv">
                                                {question.options.map(
                                                  (option) => {
                                                    const isCorrectOption =
                                                      correctAnswers.includes(
                                                        option.option_index
                                                      );
                                                    const isUserAnswer =
                                                      userAnswer &&
                                                      userAnswer.includes(
                                                        option.option_index
                                                      );

                                                    return (
                                                      <li
                                                        key={option.option_id}
                                                        style={{
                                                          position: "relative",
                                                        }}
                                                      >
                                                        ({option.option_index})
                                                        <img
                                                          src={
                                                            option.optionImgName
                                                          }
                                                          alt=""
                                                        />
                                                        {/* Show a green ✔ if the option is correct */}
                                                        {isCorrectOption && (
                                                          <span className="solutions_correct_answer">
                                                            ✔
                                                          </span>
                                                        )}
                                                        {/* Show a red ✘ if the user selected this option but it is incorrect */}
                                                        {isUserAnswer &&
                                                          !isCorrectOption && (
                                                            <span className="incorrect-answer">
                                                              ✘
                                                            </span>
                                                          )}
                                                      </li>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            )}

                                            <button
                                              onClick={() =>
                                                handleShowSolution(
                                                  question.solution
                                                )
                                              }
                                            >
                                              {showSolution &&
                                              currentSolution ===
                                                question.solution
                                                ? "Hide Solution"
                                                : "Show Solution"}
                                            </button>

                                            {showSolution &&
                                              currentSolution ===
                                                question.solution && (
                                                <div className="View_solution_container">
                                                  <strong>Solution:</strong>{" "}
                                                  <img
                                                    className="MockTest_ReportPgae_solution_Imgtag"
                                                    src={question.solution}
                                                    alt=""
                                                  />
                                                </div>
                                              )}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div>
                    <button onClick={closePopup}>Close</button>
                  </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default JeeAdvanceMocktest;
