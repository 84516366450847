import React from "react";
import logo from "./logo/cap.jpeg";
import vision from "./logo/business-vision-and-target-illustration-vector-removebg-preview.png";
import mision from "./logo/3d-minimal-clipboard-with-a-dartboard-and-an-arrow-hit-at-the-center-goal-achievement-3d-illustration-free-png.webp";
import Examheader from "../../../UG/Examheader";
import "./about.css"
import Footer from "../Footer/Footer";
import Footer2 from "../../../UG/Footer2";
const About_us = () => {
  return (
    <div>
      <Examheader />
      <div className="Aboutus_egtnew">
      <div className="about_egtdiv">
        <div className="about_egtdivimg1" >
        <img src={logo} alt="eGRAD Tutor" width="100%" height="100%" className="about_egtdivimg" />
        </div>
      
        <p>
            <h1>ABOUT US</h1>
          eGRADTutor is an Edtech firm started with a vision to blend the
          boundaries in education and take best-in-class courses and teachers
          virtually to every corner of the country. Our courses are developed by
          the alumni of IIT's/IISc with an intent to enhance the student's
          abilities to face some of the toughest exams of the country. Our
          offerings not just provide a deeper understanding of the subjects to
          the students, but they also make them exam ready.
        </p>
      </div>
      <div className="aboutus_vision">
        <div className="papers">
          <img src={vision} alt="eGRAD Tutor"  width="250px"/>
          <p>
            Our vision is to blend the boundaries in education and take
            best-in-class courses and teachers virtually to every corner of the
            country. We act with integrity and humility. We work with energy and
            curiosity. We seek growth and equal opportunity. We believe in
            constant innovation and development.
          </p>
        </div>
        <div className="papers">
          <img src={mision} alt="eGRAD Tutor" width="250px" height="170px"/>
          <p>
            Our mission is to be the most accessible and affordable online
            education provider in the country. We intend to build a virtual
            knowledge pool that strengthens the students and thereby strengthens
            the country in the journey towards growth and innovation.
          </p>
        </div>
      </div>
      </div>
      <Footer2 />
    </div>
  );
};

export default About_us;
