// import { React, useState } from "react";
// import { Link } from "react-router-dom";
// import { FaChevronDown } from "react-icons/fa";
// // ----------------header css------------------------
// import "./header.css";

// // ----------------navbardata_------------------------

// import { NavData } from "./NavData";
// // import { OnlineTestSeries } from "../OTS/OnlineTestSeries";

// const Header = () => {
//   const [showMenu, setshowMenu] = useState(0);

//   const [isExamsOpen, setIsExamsOpen] = useState(false);
//   const [isCoursesOpen, setIsCoursesOpen] = useState(false);

//   const toggleDropdown = () => {
//     setIsExamsOpen(!isExamsOpen);
//   };

//   const CoursestoggleDropdown = () => {
//     setIsCoursesOpen(!isCoursesOpen);
//   };

//   const [onlineTestSeres, setOnlineTestSeres] = useState(false);
//   const [isOtsCoursesOpen, setIsOtsCoursesOpen] = useState(false);

//   const otSeres = () => {
//     setOnlineTestSeres(!onlineTestSeres);
//   };

//   const otsDropdown = () => {
//     setIsOtsCoursesOpen(!isOtsCoursesOpen);
//   };

//   return (
//     <>
//       <div className="ug_header">
//         {NavData.map((NavData, index) => {
//           return (
//             <div className="header" key={index}>
//               <div className="logo-header">
//                 <div className={NavData.logo_img_container}>
//                   <Link to={"/"}>
//                     <img src={NavData.logo} alt="" />
//                   </Link>
//                 </div>

//                 <div className="login_menu">
//                   <a
//                     href="https://online-ug.egradtutor.in"
//                     target="_blank"
//                     className={NavData.login} >
//                     {NavData.btn1}
//                   </a>
//                   <div
//                     className="mobile_menu mobile_menu_non"
//                     onClick={() => setshowMenu(!showMenu)} >
//                     <div
//                       className={showMenu ? "rotate_right  " : "lines "}
//                     ></div>
//                     <div className={showMenu ? "no_lines  " : "lines "}></div>
//                     <div
//                       className={showMenu ? "rotate_left  " : "lines "}
//                     ></div>
//                   </div>
//                   {/* <a href="#"><AiOutlineMenu/></a> */}{" "}
//                 </div>
//               </div>

//               <div className={showMenu ? "navbox  mobile-menu" : "navbox "}>
//                 <ul className={NavData.navbar}>
//                   <li>
//                     <a href="#home" className={NavData.navlist}>
//                       {NavData.link1}
//                     </a>
//                   </li>

//                   <li>
//                     <a href="#exam" className="Exams_dropdown">
//                       {" "}
//                       Exams <FaChevronDown onClick={toggleDropdown} className="FaChevronDown" />
//                     </a>
//                     {isExamsOpen && (
//                       <div className="Exams_dropdown-content">
//                         <Link to="/iitjeeExam">JEE</Link>
//                         <Link to="/NeetExam">NEET</Link>
//                         <Link to="/bitsatexam">BITSAT</Link>
//                         <Link to="/viteeeexam">VITEEE</Link>
//                       </div>
//                     )}
//                   </li>

//                   {/* <li>
//                     <a href="#exam" className={NavData.navlist}>
//                       {NavData.link2}
//                     </a>
//                   </li> */}

//                   <li>
//                     <a href="#courses" className="Exams_dropdown">
//                       {" "}
//                       Courses <FaChevronDown onClick={CoursestoggleDropdown} className="FaChevronDown" />
//                     </a>
//                     {isCoursesOpen && (
//                       <div className="Exams_dropdown-content">
//                           <a href="#" onClick={toggleDropdown}> ONLINE TEST SERIES</a>
//                           {onlineTestSeres && (
//                              <div className="OtsCouersDropDown">
//                              <Link to="/iitjeeExam">JEE</Link>
//                              <Link to="/NeetExam">NEET</Link>
//                              <Link to="/bitsatexam">BITSAT</Link>
//                              <Link to="/viteeeexam">VITEEE</Link>
//                            </div>
//                           )}
//                         <a href="#">ONLINE LIVE VIDEO CLASSES</a>
//                       </div>
//                     )}
//                   </li>
//                   {/* <li>
//                     <a href="#courses" className={NavData.navlist}>
//                       {NavData.link3}
//                     </a>
//                   </li> */}
//                   <li>
//                     <a href="#about" className={NavData.navlist}>
//                       {NavData.link4}
//                     </a>
//                   </li>

//                   <li>
//                     {/* <Link
//                       target="_blank"
//                       className={NavData.navlist}
//                       to={"/contactUs"}
//                     >
//                       {NavData.link5}
//                     </Link> */}
//                     <a
//                       href="/contactUs"
//                       target="_blank"
//                       rel="noopener noreferrer"
//                       className={NavData.navlist}
//                     >
//                       {NavData.link5}
//                     </a>
//                     {/* <a href="#contact" className={NavData.navlist}>
//                       {NavData.link5}
//                     </a> */}
//                   </li>
//                   <li>
//                     {/* <a href="#faq" className={NavData.navlist}>
//                       {NavData.link6}
//                     </a> */}
//                   </li>
//                   {/* <li>
//                     <a href="#" id="ughomepage_dropdown_download_linK" className={NavData.navlist}>
//                       {NavData.link7}
//                       <ul className="ughomepage_dropdown_download">
//                         <li>
//                           <a href="#">{NavData.link7_dp1}</a>
//                         </li>
//                         <li>
//                           <a href="#">{NavData.link7_dp2}</a>
//                         </li>
//                       </ul>
//                     </a>
//                   </li> */}
//                 </ul>
//               </div>
//             </div>
//           );
//         })}
//       </div>
//     </>
//   );
// };

// export default Header;

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import { NavData } from "./NavData";
import "./header.css";
import { IoIosArrowDown } from "react-icons/io";

const Header = () => {
  const examRef = useRef(null);
  const courseRef = useRef(null);
  const headerRef = useRef(null);
  const [isHeaderActive, setIsHeaderActive] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [isExamsOpen, setIsExamsOpen] = useState(false);
  const [isCoursesOpen, setIsCoursesOpen] = useState(false);
  const [isOtsCoursesOpen, setIsOtsCoursesOpen] = useState(false);
  const [isLiveClassesOpen, setIsLiveClassesOpen] = useState(false);
  const handleOutSide = (event) => {
    console.log("Clickkkkkkkkkkd!");
    console.log(event.target, "This is the target");
    // so whenever there is a click on header, event.target contains
    if (headerRef.current && !headerRef.current.contains(event.target)) {
      closeAllDropdowns();
      removeClickListener(); //removing click event
    }
  };
  //
  const addClickListener = () => {
    document.body.addEventListener("click", handleOutSide);
  };
  const removeClickListener = () => {
    document.body.removeEventListener("click", handleOutSide);
  };

  // useEffect(()=>{
  //   document.body.addEventListener('click',handleOutSide)
  //   // if()
  //   return()=>{
  //     document.body.removeEventListener("click",handleOutSide)
  //   }
  // },[])
  const toggleDropdown = (dropdown) => {
    if (dropdown === "exams") {
      setIsExamsOpen(!isExamsOpen);
      setIsCoursesOpen(false);
      setIsLiveClassesOpen(false);
      setIsHeaderActive(true);
    } else if (dropdown === "courses") {
      setIsCoursesOpen(!isCoursesOpen);
      setIsExamsOpen(false);
      setIsLiveClassesOpen(false);
      setIsHeaderActive(true);
    } else if (dropdown === "liveclasses") {
      setIsLiveClassesOpen(!isLiveClassesOpen);
      setIsExamsOpen(false);
      setIsCoursesOpen(false);
      setIsHeaderActive(true);
    }
  };

  const closeAllDropdowns = () => {
    setIsExamsOpen(false);
    setIsCoursesOpen(false);
    setIsLiveClassesOpen(false);
    setIsOtsCoursesOpen(false); // Added to close the ONLINE TEST SERIES dropdown
    setIsHeaderActive(false);
  };
  // if the header is active, i want to call add event listener otherwise remove that
  useEffect(() => {
    if (isHeaderActive) {
      addClickListener();
    }
    return () => {
      removeClickListener();
    };
  }, [isHeaderActive]);

  return (
    <div className="ug_header headerPC " ref={headerRef}>
      {NavData.map((navData, index) => (
        <div className="header" key={index}>
          <div className="logo-header">
            <div className={navData.logo_img_container}>
              <Link to={"/"}>
                <img src={navData.logo} alt="" />
              </Link>
            </div>
            <div className="login_menu">
              <a
                href="https://online-ug.egradtutor.in"
                target="_blank"
                className={navData.login}
              >
                {navData.btn1}
              </a>
              <div
                className="mobile_menu mobile_menu_non"
                onClick={() => setShowMenu(!showMenu)}
              >
                <div className={showMenu ? "rotate_right" : "lines"}></div>
                <div className={showMenu ? "no_lines" : "lines"}></div>
                <div className={showMenu ? "rotate_left" : "lines"}></div>
              </div>
            </div>
          </div>
          <div className={showMenu ? "navbox mobile-menu" : "navbox"}>
            <ul className={navData.navbar}>
              <li>
                <a
                  href="/"
                  className={navData.navlist}
                  onClick={closeAllDropdowns}
                >
                  {navData.link1}
                </a>
              </li>
              <li>
                <a
                  ref={examRef}
                  href="#exam"
                  className="Exams_dropdown "
                  onClick={() => toggleDropdown("exams")}
                  // onClick={()=>{onScrollToExam()}}
                >
                  Exams <FaChevronDown className="FaChevronDown" />
                </a>
                {isExamsOpen && (
                  <div className="Exams_dropdown-content">
                    <Link to="/iitjeeExam">JEE</Link>
                    <Link to="/NeetExam">NEET</Link>
                    <Link to="/bitsatexam">BITSAT</Link>
                    <Link to="/viteeeexam">VITEEE</Link>
                  </div>
                )}
              </li>
              <li>
                <a
                  ref={courseRef}
                  href="#courses"
                  className="Exams_dropdown"
                  onClick={() => toggleDropdown("courses")}
                >
                  Courses <FaChevronDown className="FaChevronDown" />
                </a>
                {isCoursesOpen && (
                  <div className="Exams_dropdown-content">
                    <a href="#" className="otsOlvcsetIsOtsCoursesOpen">
                      ONLINE TEST SERIES{" "}
                    </a>
                    {/* <div
                      className={`OtsCouersDropDown ${
                        isOtsCoursesOpen ? "show" : ""
                      }`}
                    >
                      <Link to="/iitjee_otc">
                        JEE <small>(MAINS & ADVANCED)</small>
                      </Link>
                      <Link to="/neetotc">NEET</Link>
                      <Link to="/bitsatots">BITSAT</Link>
                      <Link to="/VITEEE_OTS_PAGE">VITEEE</Link>
                    </div> */}

                    <a href="#" className="otsOlvcsetIsLiveClassesOpen">
                      ONLINE LIVE VIDEO CLASSES{" "}
                    </a>
                    {/* <div
                      id="LiveClassesDrop_Down"
                      className={`LiveClassesDropDown ${
                        isLiveClassesOpen ? "show" : ""
                      }`}
                    >
                      <Link to="/iitjee_olvc">
                        JEE <small>(MAINS & ADVANCED)</small>
                      </Link>
                      <Link to="/neet_olvc">NEET</Link>
                      <Link to="/bitsat_olvc">BITSAT + VITEEE</Link>
                    </div> */}
                  </div>
                )}
              </li>
              <li>
                <a
                  href="/About_us"
                  className={navData.navlist}
                  onClick={closeAllDropdowns}
                >
                  {navData.link4}
                </a>
              </li>
              <li>
                <a
                  href="/contactUs"
                  rel="noopener noreferrer"
                  className={navData.navlist}
                >
                  {navData.link5}
                </a>
              </li>
              <li>
                <a
                  href="/download"
                  target=""
                  rel="noopener noreferrer"
                  className={navData.navlist}
                >
                  {navData.link7}
                </a>
              </li>

              {/* MOCK BUTTON ADD */}
              {/* <li className="ugMockButtonn"> 
                  <a className="mocks_arrow_ug">
                    Mock Tests <IoIosArrowDown />
                  </a>
                  <div className="UgHeaderMocksDropdown">
                    <div className="UgSubHeaderMocks">
                    <a href="/UgJEE_MandAMockTest" className="UgMockHeader">JEE (Mains & Advanced) </a>
                    <a href="/UgNEET_MockTest" className="UgMockHeader">NEET </a>
                    <a href="/UgBITSAT_MockTest" className="UgMockHeader">BITSAT </a>
                    <a href="/UgVITEEE_MockTest" className="UgMockHeader">VITEEE </a>
                    </div>
                  </div>
                  </li> */}

              <li>
                <a
                  // href="/New_MockTestform_file_Topic_wise"
                  target=""
                  rel="noopener noreferrer"
                  className={navData.navlist}
                >
                  Mock Tests
                </a>
              </li>
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Header;
