import ots from "./courses_img/ots.webp"
import olvc from "./courses_img/olvc2.png"
import qb from "./courses_img/QB.png"


export const Course_data1 = [
    {
        course_bttons_list1: "course_bttons_list1",
        course_title1: "ONLINE TEST SERIES",
        course_pont1_1: "Interface similar to that of the competitive exams",
        course_pont2_1: "Combination of subject/topic wise and full syllabus.",
        course_pont3_1: "Detailed solutions with explanation",
        course_pont4_1: "Performance analysis reports",
        ots_olvc_QB_img1: [ots],
        course_img1: "course_img1",
        Course_box1: "Course_box1",
        c_btn1: "jee",
        small1:"(mains & advanced)",
        c_btn2: "neet",
        c_btn3: "bitsat",
        c_btn4: "viteee",
        c_btn5: "ap-eapcet",
        c_btn6: "ts-eamcet",
        c_btn7: "mhcet",
        c_btn8: "kcet",
        c_btn9: "wbjee",
        c_btn10: "keam",
        c_btn11: "srmjee",
        c_btn12: "aeee",
        c_btn13: "met",
        otsiitlink: "/iitjee_otc",
        otslinkneet:"/neetotc",
        otslinkbitsat: "/bitsatots",    
        otslinkviteee: "/VITEEE_OTS_PAGE",    
        otslinkapeapcet: "/AP-EAPCET_OTS_PAGE",    
        otslinktseamcet: "/TS-EMACET_OTS_PAGE",    
        otslinkmhcet: "/MHCET_OTS_PAGE",    
        otslinkkcet: "/KCET_OTS_PAGE",    
        otslinkwbjee: "/WBJEE_OTS_PAGE",    
        otslinkkeam: "/KEAM_OTS_PAGE",    
        otslinksrmjee: "/SRMJEE_OTS_PAGE",    
        otslinkaeee: "/AEEE_OTS_PAGE",    
        otslinkmet: "/Met_ots",    


    }
]
export const Course_data2 = [

    {
        Course_box2: "Course_box2",
        course_bttons_list2: "course_bttons_list2",
        course_title2: "ONLINE LIVE VIDEO CLASSES",
        course_pont1_2: "Content curated by industry experts",
        course_pont2_2: "Best-in-class user interface",
        course_pont3_2: "Frequent doubt clearing sessions",
        course_pont4_2: "Accessible from anywhere and anytime ",
        ots_olvc_QB_img2: [olvc],
        c_btn2_1: "jee",
        small2:"(mains & advanced)",
        c_btn2_2: "neet",
        course_img2: 'course_img2',
        olvclinkiit: "/iitjee_olvc",
        olvclinkneet: "/neet_olvc",
        c_btn2_3: "bitsat",
        olvclinkbitsat: "/bitsat_olvc",
        c_btn2_4: "viteee",
        olvclinkviteee: "/VITEEE_olvc_PAGE",
    },

]

export const Course_data3 = [
    {
        course_bttons_list3: "course_bttons_list3",
        course_title3: "ONLINE QUESTION BANK",
        course_pont1_3: "Practice questions in the examination interface",
        course_pont2_3: "Volume of questions available across topics",
        course_pont3_3: "Detailed analysis and explanations to the solutions",
        course_pont3_4: "Practice any number of time",
        ots_olvc_QB_img3: [qb],
        course_img3: "course_img3",
        Course_box3: "Course_box3",
        c_btn3_1: "iit-jee",
        small3:"(mains & advanced)",
        c_btn3_2: "neet",
        olvclinkiit:"/iitjee_olvc",
        olvclinkneet:"/bitsatexam"

 

    },

]

