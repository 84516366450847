import React from 'react'
import Header from '../UG/Header'
import { useState } from 'react'
import './GATECSS.css'
import table1 from '../PG/Images/tableimg1.png'
import tabimg from '../PG/Images/tableimg2.png'
import tabimg3 from '../PG/Images/tableimg3.png'
import tabimg4 from '../PG/Images/tableimg4.png'
import logo from '../UG/Images/logo2.jpeg'


import onlinets from '../UG/Images/online test seires.png'
import recordedmc from '../UG/Images/recorded mini class.png'
import livecls from '../UG/Images/live class.png'



// GateExamPage pages
import GateExamPattern from './ExamPageComponent/GateExamPage/GateExamPattern'
import GateEligibility from './ExamPageComponent/GateExamPage/GateEligibility'
import GateSyllabus from './ExamPageComponent/GateExamPage/GateSyllabus'
import GateImportantDates from './ExamPageComponent/GateExamPage/GateImportantDates'
import { Link } from 'react-router-dom'
import Footer from '../UG/Footer2'
import { Nav } from '../UG/Nav'
import { IoMdHome } from "react-icons/io";


export const PGGATE_Exam = () => {
  const [selected, setSelected] = useState(null)
  const toggle2 = (i1) => {
    // return i
    if (selected === i1) {
      return setSelected(null)

    }
    setSelected(i1)
  }
  return (
    <div className=''>
      {/* import logo from '../../logo2.jpeg' */}
      <div>
        <div className="ugexam_header">
          {Nav.map((NavData, index) => {
            return (
              <div className="header ug_exam_header" key={index}>
                <div className={NavData.logo_img_container}>
                  <Link to={"/"}>
                    <img src={NavData.logo} alt="" />
                  </Link>
                </div>

                <div className="exam_login_menu  ">
                  <li>
                    <Link to='/PgHome' className={NavData.navlist} id='exam_login_menu_home'>
                      <IoMdHome /> {NavData.link1}
                    </Link>
                  </li>
                  {/* <a
                    href="https://online-ug.egradtutor.in"
                    target='_blank'
                    className={NavData.login}
                  >
                    {NavData.btn1}
                  </a> */}

                </div>
              </div>

            );
          })}
        </div>
      </div>


      <div className="otsh1" id='Metexam-exampage' >
        <h1 style={{ color: '#000' }}>GATE EXAM</h1>
      </div>

      <div className='FAQCONTENT' id='faq'>
        <div className="wrapper-3 container">


          <div className='sdfsf'>
            <div className='examdiv'>
              <button className='exambtn' id='MetexamLink'><a target='_blank' href="https://gate2025.iitr.ac.in/" >GATE Official Webpage </a>
              </button>
            </div>
            <div className="accordion">
              {sixtoten.map((item, i2) => (
                <div className="item2">
                  <div className="title" id='MetexamLink' onClick={() => toggle2(i2)}>
                    <h2>{item.Qustion}</h2>
                    <span>{selected === i2 ? '-' : '+'}</span>
                  </div>
                  <div className={selected === i2 ? 'content-show2' : 'content2'}>
                    <div>
                      <div className='imagessg'>{item.answer}
                        {/* <img src={item.ansIMG} alt="" /> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            </div>
          </div>

        </div>
        <div class="neetcourses pgexms" id='MetexamLink'>
          <div class="courses_heading">
            <h1>GATE COURSES</h1>
          </div>

          <div class="online_courses">
            <div class="courses1 courses1-img" id='Metexamcourses1'>
              <div class="test_btn ots_btn" id='MetexamLink'>
                <h3 id='Metexamtext'>ONLINE TEST SERIES</h3>
                <img src={onlinets} alt="" />
                <div class="btn-neet">
                  <button id='MetexamLink'>
                    <Link to='/Gate_ots'>Explore <i class="uil uil-angle-right icon"></i></Link></button>
                </div>

              </div>
            </div>

            {/* 
            <div class="courses1">
              <div class="test_btn ots_btn">
                <h3>RECORDED MINI COURSES</h3>
                <img src={recordedmc} alt="" />
                <div class="btn-neet">
                  <button>
                    <Link style={{cursor:"auto"}} to=''>Explore <i class="uil uil-angle-right icon"></i></Link></button>
                </div>
              </div>
            </div> */}

            <div class="courses1" id='Metexamcourses1'>
              <div class="test_btn ots_btn" id='MetexamLink'>
                <h3 id='Metexamtext'>LIVE CLASSES</h3>
                <img src={livecls} alt="" />
                <div class="btn-neet">
                  <button id='MetexamLink'>
                    <Link to='/Gate_OLVC'>Explore <i class="uil uil-angle-right icon"></i></Link></button>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}








const sixtoten = [
  {
    Qustion: "CONDUCTING INSTITUTE",
    answer: "IIT Bombay, IIT Delhi, IIT Guwahati, IIT Kanpur, IIT Kharagpur, IIT Madras, and IIT Roorkee"
  },
  // Exam Pattern ==========================
  {
    Qustion: "EXAM PATTERN",
    answer: [<GateExamPattern />],




  },
  // ELIGIBILITY ==========================================
  {
    Qustion: "ELIGIBILITY",
    answer: [<GateEligibility />],

  },
  // syllabus ================================
  {
    Qustion: "SYLLABUS",
    answer: [<GateSyllabus />]
  },

  {
    Qustion: "IMPORTANT DATES",
    answer: [<GateImportantDates />]
  },
]