import React, { useState, useEffect } from "react";
import questionsData from "./questionsnew.json"; // Import the JSON file
import guesticon from "../asserts/guesticon.jpg";
import prize from "../prize.jpg";
import grayBox from "../asserts/grayBox.png";
import greenBox from "../asserts/greenBox.png";
import orangeBox from "../asserts/orangeBox.png";
import "../mock.css";

function Mocktest_1() {
  const [answers, setAnswers] = useState({});
  const [visitedQuestions, setVisitedQuestions] = useState({});
  const [showScoreCard, setShowScoreCard] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [score, setScore] = useState(0);
  const [remainingTime, setRemainingTime] = useState(3 * 60 * 60);

  useEffect(() => {
    setQuestions(questionsData.sections);
  }, []);

  const handleNextQuestion = () => {
    const currentSection = questions[currentSectionIndex];
    if (currentQuestionIndex < currentSection.questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else if (currentSectionIndex < questions.length - 1) {
      setCurrentSectionIndex((prevIndex) => prevIndex + 1);
      setCurrentQuestionIndex(0);
    } else {
      setCurrentSectionIndex(0);
      setCurrentQuestionIndex(0);
    }
    setSelectedOption(null);
  };

  const handlePrevQuestion = () => {
    // Check if questions array is defined and not empty
    if (questions && questions.length > 0) {
      const currentSection = questions[currentSectionIndex];

      // Check if currentSection exists and currentQuestionIndex is valid
      if (
        currentSection &&
        currentQuestionIndex >= 0 &&
        currentQuestionIndex < currentSection.questions.length
      ) {
        const currentQuestion = currentSection.questions[currentQuestionIndex];

        if (currentQuestion) {
          const currentQuestionKey = `${currentSectionIndex}-${currentQuestionIndex}`;

          if (currentQuestionIndex === 0 && currentSectionIndex > 0) {
            // If it's the first question in the section, move to the previous section
            setCurrentSectionIndex((prevIndex) => prevIndex - 1);
            const prevSection = questions[currentSectionIndex - 1];
            if (prevSection && prevSection.questions.length > 0) {
              setCurrentQuestionIndex(prevSection.questions.length - 1); // Move to the last question of the previous section
              setSelectedOption(null); // Reset selected option for the previous section
            } else {
              console.error("Previous section has no questions.");
            }
          } else if (currentQuestionIndex > 0) {
            // Move to the previous question in the current section only if currentQuestionIndex is not already 0
            setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
          }
        } else {
          console.error("Current question is undefined.");
        }
      } else {
        console.error("Invalid currentSectionIndex or currentQuestionIndex");
      }
    } else {
      console.error("Questions array is not defined or empty.");
    }
  };

  const handleSaveNextQuestion = () => {
    const currentSection = questions[currentSectionIndex];
    const currentQuestion = currentSection.questions[currentQuestionIndex];
    const currentQuestionKey = `${currentSectionIndex}-${currentQuestionIndex}`;
    const selectedOptionIndex = selectedOption;
    const correctOptionIndex = currentQuestion.options.findIndex(
      (opt) => opt.text === currentQuestion.ans
    );

    const isCorrect = selectedOptionIndex === correctOptionIndex;
    console.log(
      `User's answer for question ${currentQuestionKey}: ${
        isCorrect ? "Correct" : "Wrong"
      }`
    );

    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [currentQuestionKey]: selectedOption,
    }));

    handleNextQuestion();
  };

  const clearResponse = () => {
    const updatedQuestions = [...questions];
    const currentSection = updatedQuestions[currentSectionIndex];
    const currentQuestion = currentSection.questions[currentQuestionIndex];
    currentQuestion.selectedOption = null; // Clear the selected option
    setQuestions(updatedQuestions);
    setSelectedOption(null); // Clear the selected option in state

    // Update the answers state to reflect that the current question is not answered
    const updatedAnswers = { ...answers };
    const questionKey = `${currentSectionIndex}-${currentQuestionIndex}`;
    updatedAnswers[questionKey] = null;
    setAnswers(updatedAnswers);
  };

  const handleClosePopup = () => {
    window.location.href = "/bitsatots";
    window.close();
  };

  const handleSubmit = () => {
    let totalScore = 0;

    questions.forEach((section, sectionIndex) => {
      section.questions.forEach((question, questionIndex) => {
        const currentQuestionKey = `${sectionIndex}-${questionIndex}`;
        const selectedOptionIndex = answers[currentQuestionKey];
        if (selectedOptionIndex !== null && selectedOptionIndex !== undefined) {
          const correctOptionIndex = question.options.findIndex(
            (opt) => opt.text === question.ans
          );
          if (selectedOptionIndex === correctOptionIndex) {
            totalScore += question.marks;
          } else {
            totalScore -= 1;
          }
        }
      });
    });

    totalScore = Math.max(totalScore, 0);
    setScore(totalScore);
    setShowScoreCard(true);
  };

  useEffect(() => {
    setQuestions(questionsData.sections);
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (remainingTime === 0) {
      alert("Time's up!");
    }
  }, [remainingTime]);

  const handleOptionSelect = (sectionIndex, questionIndex, optionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[sectionIndex].questions[questionIndex].selectedOption =
      optionIndex;
    setQuestions(updatedQuestions);
    setSelectedOption(optionIndex);
  };

  const calculateCounts = () => {
    let notVisitedCount = 0;
    let answeredCount = 0;
    let notAnsweredCount = 0;
    let rightAnsweredCount = 0;
    let wrongAnsweredCount = 0;

    questions.forEach((section, sectionIndex) => {
      section.questions.forEach((question, questionIndex) => {
        const questionKey = `${sectionIndex}-${questionIndex}`;
        if (!visitedQuestions[questionKey]) {
          notVisitedCount++;
        } else if (answers[questionKey] !== undefined) {
          if (answers[questionKey] !== null) {
            answeredCount++;
            const selectedOptionIndex = answers[questionKey];
            const correctOptionIndex = question.options.findIndex(
              (opt) => opt.text === question.ans
            );
            if (selectedOptionIndex === correctOptionIndex) {
              rightAnsweredCount++;
            } else {
              wrongAnsweredCount++;
            }
          } else {
            notAnsweredCount++;
          }
        } else {
          notAnsweredCount++;
        }
      });
    });

    return {
      notVisitedCount,
      answeredCount,
      notAnsweredCount,
      rightAnsweredCount,
      wrongAnsweredCount,
    };
  };

  const {
    notVisitedCount,
    answeredCount,
    notAnsweredCount,
    rightAnsweredCount,
    wrongAnsweredCount,
  } = calculateCounts();

  const markQuestionAsVisited = (sectionIndex, questionIndex) => {
    const questionKey = `${sectionIndex}-${questionIndex}`;
    setVisitedQuestions((prevVisited) => ({
      ...prevVisited,
      [questionKey]: true,
    }));
  };

  useEffect(() => {
    markQuestionAsVisited(currentSectionIndex, currentQuestionIndex);
  }, [currentSectionIndex, currentQuestionIndex]);

  return (
    <div>
      <div className="mocktestparent_conatiner">
        <div>
          {" "}
          <div className="remaining">
            <div className="bitsatMockHeader">
              <img
                src="https://www.egradtutor.in/static/media/egate%20logo%201.8b1eb31347d60945925f.png"
                alt=""
              />
            </div>
            <h4>
              <b>BITSAT Mock test 1</b>
            </h4>
            <p>
              Time remaining:{" "}
              {`${Math.floor(remainingTime / 3600)}:${Math.floor(
                (remainingTime % 3600) / 60
              )}:${remainingTime % 60}`}
            </p>
          </div>
        </div>
        <div>
          {questions.length > 0 && (
            <div className="mocktest_conatiner_testwithpallet">
              {/* //quizcontainer */}

              <div className="mocktest_conatiner_testwithpalletquizcontainer">
                {questions.map((section, sectionIndex) => (
                  <div
                    key={sectionIndex}
                    className="mocktest_conatiner_testwithpalletquizcontainersub"
                    style={{
                      display:
                        sectionIndex === currentSectionIndex ? "block" : "none",
                    }}
                  >
                    <div className=" sectionbtnmock">
                      {questions.map((section, index) => (
                        <button
                          key={index}
                          onClick={() => {
                            setCurrentSectionIndex(index);
                            setCurrentQuestionIndex(0);
                            setSelectedOption(null);
                          }}
                          className={
                            index === currentSectionIndex ? "active" : ""
                          }
                          disabled={index === currentSectionIndex}
                        >
                          Section {section.name}
                        </button>
                      ))}
                    </div>

                    <div className="mocktest_conatiner_testwithpalletquizcontainersubintopart">
                      <p>
                        Question: {section.questions[currentQuestionIndex].id}
                      </p>
                      <img
                        src={`data:image/png;base64,${section.questions[currentQuestionIndex].question}`}
                        alt={`Question ${section.questions[currentQuestionIndex].id}`}
                      />
                      {section.questions[currentQuestionIndex].options.map(
                        (option, optionIndex) => (
                          <div
                            key={optionIndex}
                            style={{
                              color:
                                submitted &&
                                selectedOption !== optionIndex &&
                                optionIndex ===
                                  section.questions[
                                    currentQuestionIndex
                                  ].options.findIndex(
                                    (opt) =>
                                      opt.text ===
                                      section.questions[currentQuestionIndex]
                                        .ans
                                  )
                                  ? "green"
                                  : submitted && selectedOption !== optionIndex
                                  ? "red"
                                  : "inherit",
                            }}
                            className="optionimginmock"
                          >
                            <label>
                              <input
                                type="radio"
                                name={`option_${section.questions[currentQuestionIndex].id}`}
                                checked={
                                  selectedOption === optionIndex ||
                                  optionIndex ===
                                    section.questions[currentQuestionIndex]
                                      .selectedOption
                                }
                                onChange={() =>
                                  handleOptionSelect(
                                    sectionIndex,
                                    currentQuestionIndex,
                                    optionIndex
                                  )
                                }
                              />

                              <span>({option.text})</span>
                              <img
                                src={`data:image/png;base64,${option.image}`}
                                alt={`Option ${option.label}`}
                              />
                            </label>
                          </div>
                        )
                      )}
                    </div>

                    <div>
                      <div className="mocktest_conatiner_testwithpalletbuttons">
                        {" "}
                        <div>
                          <button
                            className="Quiz_clearResponse Quiz_mockbtns Quiz_mockbtnsclear"
                            onClick={clearResponse}
                          >
                            Clear Response
                          </button>

                          <button
                            className="quizsave_next previous_next_btn Quiz_mockbtns"
                            onClick={handleSaveNextQuestion}
                          >
                            Save & Next
                          </button>
                        </div>
                        <div>
                          <button
                            onClick={handlePrevQuestion}
                            // disabled={currentQuestionIndex === 0}
                            className="Quiz_mockbtns Quiz_mockbtnspre"
                          >
                            Previous
                          </button>
                          <button
                            onClick={handleNextQuestion}
                            // disabled={
                            //   currentQuestionIndex === section.questions.length - 1
                            className="Quiz_mockbtns Quiz_mockbtnsnext"
                            // }
                          >
                            Next
                          </button>
                          <button
                            onClick={handleSubmit}
                            className="Quiz_mockbtns Quiz_mockbtnssubmit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div>
                {/* //number pallet */}
                <div className="numberpalette">
                  <div className="rightSidebar-topHeader">
                    <img title="Guest" src={guesticon} alt="guesticon" />
                    <p>Candidate Name: Guest</p>
                  </div>
                  <div className="question-number-palette question-numberpalette">
                    {questions.flatMap((section, sectionIndex) =>
                      section.questions.map((question, questionIndex) => {
                        const questionKey = `${sectionIndex}-${questionIndex}`;
                        const questionNumber =
                          questions
                            .slice(0, sectionIndex)
                            .reduce(
                              (acc, curr) => acc + curr.questions.length,
                              0
                            ) +
                          questionIndex +
                          1;
                        return (
                          <button
                            key={questionKey} // Unique key based on sectionIndex and questionIndex
                            className={`question-number-button ${
                              answers[questionKey] !== undefined
                                ? answers[questionKey] !== null
                                  ? "answered"
                                  : "notAnswered"
                                : !visitedQuestions[questionKey]
                                ? ""
                                : "notAnswered"
                            }`}
                            onClick={() => {
                              setCurrentSectionIndex(sectionIndex);
                              setCurrentQuestionIndex(questionIndex);
                              setSelectedOption(
                                section.questions[questionIndex].selectedOption
                              );
                              markQuestionAsVisited(
                                sectionIndex,
                                questionIndex
                              );
                            }}
                            disabled={
                              currentSectionIndex === sectionIndex &&
                              currentQuestionIndex === questionIndex
                            }
                          >
                            {questionNumber}
                          </button>
                        );
                      })
                    )}
                  </div>
                  <div className="sidebar-footer">
                    <h4 className="sidebar-footer-header">Legend:</h4>
                    <div className="footer-btns">
                      <div className="inst-btns">
                        {/* <img src={grayBox} alt="Not Visited" /> */}
                        <span className="NotVisited">{notVisitedCount}</span>

                        <label htmlFor="">Not Visited</label>
                      </div>
                      <div className="inst-btns">
                        {/* <img src={greenBox} alt="Answered" /> */}
                        <span className="Answered">{answeredCount}</span>
                        <label htmlFor="">Answered</label>
                      </div>
                      <div className="inst-btns">
                        {/* <img src={orangeBox} alt="Not Answered" /> */}
                        <span className="NotAnsweredlegend">
                          {" "}
                          {notAnsweredCount}
                        </span>
                        <label htmlFor="">Not Answered</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div>
          {showScoreCard && (
            <div className="scorecardforexam">
              <div className="popup">
                <div className="popup-content">
                  <p>You have successfully submitted your test.</p>
                  {score >= 0 ? (
                    <p>
                      <img src={prize} alt="Congratulations" />
                      <span>
                        <h2>Congratulations!</h2>
                        <span className="mockscore">Your score: {score}</span>
                      </span>

                      {/* <span>{score}</span> */}
                    </p>
                  ) : (
                    <p>Your score: {score}</p>
                  )}
                  <ul className="allscorecountingmock">
                    <li>
                      <b>Not Visited:</b> {notVisitedCount}
                    </li>
                    <li>
                      <b>Answered:</b> {answeredCount}
                    </li>
                    <li>
                      <b>Not Answered:</b>
                      {notAnsweredCount}
                    </li>
                    <li>
                      <b>Right Answered:</b>
                      {rightAnsweredCount}
                    </li>
                    <li>
                      <b>Wrong Answered:</b> {wrongAnsweredCount}
                    </li>
                  </ul>

                  <button onClick={handleClosePopup}>Close</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Mocktest_1;
