import React, { useState, useEffect } from 'react';
import viteee from '../asserts/VITEEE.png';
// import './Genralinstructions.css';
import { useNavigate, useParams } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';
import TermsandConditions from "../TermsandConditions.pdf";
import { IoIosArrowBack } from "react-icons/io";

function VITEEEGenralinstructions() {
  const navigate = useNavigate();
  const { examId, mockTestId, mockTestTypeId } = useParams(); // Extract parameters from URL

  const [isChecked, setIsChecked] = useState(false);

  const handleBegin = () => {
    console.log("VITEEE_MOCKTESTID", examId, mockTestId, mockTestTypeId);
    if (isChecked) {
      const url = `/VITEEMocktest_1/${examId}/${mockTestId}/${mockTestTypeId}`;
      navigate(url, { state: { examId, mockTestId, mockTestTypeId} });
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleClick = () => {
    navigate(`/VITEEInstructionPage1/${examId}/${mockTestId}/${mockTestTypeId}`);
  };

  // const handleContextMenu = (e) => {
  //   e.preventDefault();
  // };

  // useEffect(() => {
  //   document.addEventListener('contextmenu', handleContextMenu);

  //   return () => {
  //     document.removeEventListener('contextmenu', handleContextMenu);
  //   };
  // }, []);

  return (
    <div className="Genralinstructions_containercss">
      <div>
        <div className="remaining">
          <div className="bitsatMockHeader">
            <img
              src="https://www.egradtutor.in/static/media/egate%20logo%201.8b1eb31347d60945925f.png"
              alt=""
            />
          </div>
          <h3 className='SubmitFormAndMail_h2'>GENERAL INSTRUCTIONS</h3>
        </div>
      </div>

      <div className='Bitsat_General_instructions_container'>
        <img src={viteee} alt="Logo" className="Genralinstructions_bitsatgi" style={{ display: 'block', margin: 'auto' }} />
        <p className="Genralinstructions_text">
          The VITEEE 2024 question paper will consist of 125 questions and will be divided into five sections. There will be no section-wise time limit in the paper. However, the candidates will have to complete the entire paper in 2 hours 30 minutes duration. No extra time will be provided to the candidates to complete the paper. Candidates will have the freedom to attempt any question/section in VITEEE 2024 as per their preference. They will also have the option to go back to a previously attempted question and edit it within the stipulated time duration of 2 hours 30 minutes. Some points that candidates the candidates need to keep in mind regarding the VITEEE 2024 exam pattern are listed below:
        </p>
        <ul className="Genralinstructions_list">
          <li>The total duration of VITEEE 2024 is 2 hours 30 minutes.</li>
          <li>VITEEE will be held as a computer-based test.</li>
          <li>The exam will comprise multiple-choice questions.</li>
          <li>Each question will comprise four options and only one answer will be correct.</li>
          <li>Candidates can change their answers to a question at any given point.</li>
          <li>For each correct response, one mark will be awarded.</li>
          <li>There is no provision for negative marking in VITEEE 2024.</li>
          <li>There will be no time limit on when a candidate should complete each section of the exam.</li>
          <li>Questions in the VITEEE paper are selected randomly from a large question bank and so each candidate receives a different set of questions.</li>
        </ul>
      </div>

      <div className="Bitsat_accept_terms_conditions_container">
        <input type="checkbox" onChange={handleCheckboxChange} className="checkbox" />
        <span style={{ fontWeight: 500, color: "black" }}>
          I accept all the
          <a href={TermsandConditions} target="_blank" style={{ fontWeight: 900, color: "blue", marginLeft: "0.5rem" }}>
            terms & conditions.
          </a>
        </span>
      </div>

      <div className="intro_next_btn__Jee_Neet_container">
        <div>
          <button className="previous-button" onClick={handleClick}>
            <IoIosArrowBack /> Previous 
          </button>
        </div>
        <button
          className='ug_mocktest_start_button'
          onClick={handleBegin} // Call handleBegin without parentheses
          disabled={!isChecked} // Disable the button if checkbox is not checked
          style={{
            backgroundColor: isChecked ? 'skyblue' : 'white',
            color: isChecked ? 'white' : 'black',
            border: '1px solid #76cbe6', // Optional: Add a border to make the button more visible
            cursor: isChecked ? 'pointer' : 'not-allowed' // Change cursor style based on checkbox state
          }}
        >
          I am ready to begin <AiOutlineArrowRight />
        </button>
      </div>
    </div>
  );
}

export default VITEEEGenralinstructions;
