import React from 'react'
import Homepageimg from "./asserts/Homepageimg.png";
import { IoArrowForwardSharp } from "react-icons/io5";

const Publication_Page_Homecontainer = () => {
  return (
    <>
      <div className="Publication_Page_Homecontainer">
        <div className="Publication_Page_subHomecontainer">
          <div className="Publication_Page_Homecontainer_left">
            <h3>
              eGRADTutor <br />
              Publication
            </h3>

            <button>
              New Arrivals
              <p>
                <IoArrowForwardSharp />
              </p>
            </button>
          </div>
          <div className="Publication_Page_Homecontainer_right">
            <div className="Publication_Page_Homecontainer_right_img">
              <img src={Homepageimg} alt="" />
            </div>
          </div>
        </div>
        {/* <div className="Publication_Page_Homecontainer_boxs">
          <div className="Publication_Page_Homecontainer_box">
            <h3>55K+</h3>
            <p>Books</p>
          </div>
          <div className="Publication_Page_Homecontainer_box">
            <h3>15K+</h3>
            <p>Authors</p>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Publication_Page_Homecontainer
