import React, { useContext, useState, useRef } from "react";
import Faq from "./Faq";
import logo from "./logo2.jpg";
import { Link } from "react-router-dom";
import "./IITJEE_OLVCStyles.css";
import Footer from "./Footer2";
import Examheader from "./Examheader";
import { HiOutlineChatBubbleBottomCenterText } from "react-icons/hi2";
import { PiChatCenteredTextLight } from "react-icons/pi";
import { BsChatLeftTextFill, BsChatLeftText } from "react-icons/bs";
import { BsChatText } from "react-icons/bs";
import "./BitsatOTC.css";
import BITSATimg from "../UG/CARDS/images/BITSATCARD.png";
import { FaShoppingBag } from "react-icons/fa";
import { SiKnowledgebase } from "react-icons/si";

import academic from "../UG/CARDS/images/academic.png";
import analysis from "../UG/CARDS/images/analysis.png";
import performance from "../UG/CARDS/images/NeetperformanceAnalysis.png";
import subjects from "../UG/CARDS/images/learning.png";
import Indiarank from "../UG/CARDS/images/Indiarank.png";

import subjectsd from "../UG/CARDS/images/learning.png";
import answering from "../UG/CARDS/images/answering.png";
import Additional from "../UG/CARDS/images/Additional.png";
import myContext from "../PG/context/MyContext";
import { SlCalender } from "react-icons/sl";
import { MdOutlineVerticalAlignTop } from "react-icons/md";

import MATHEMATICS from "./BitsatOLVC_images/maths.png";
import PHYSICS from "./BitsatOLVC_images/PHYSICS.png";
import CHEMISTRY from "./BitsatOLVC_images/CHEMISTRY.png";
import mpc from "./BitsatOLVC_images/bundle.png";


import english from "./BitsatOLVC_images/english2.png";
import CHALLENGER from "./BitsatOLVC_images/CHALLENGER.png";
import { LuScrollText } from "react-icons/lu";
import { LuFileText } from "react-icons/lu";
import { BiRupee } from "react-icons/bi";
import { PiClipboardTextThin } from "react-icons/pi";
import { MdAddChart } from "react-icons/md";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { WhyOtsCards } from "./why online test series cards/WhyOtsCards";

import './why online test series cards/WhyOtsCards.css'
import bitsatOTsBanner from '../ug_homepage/components/Home_Section/Banners/BITSAT ONLINE TEST - WEB BANNER.jpg'

// const settings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 4, // Display four cards at a time
//   slidesToScroll: 1,
//   autoplay: true,
//   autoplaySpeed: 2000,
//   arrows: true,
// };

// -------------------------
export const BitsatOTC = () => {
  const sliderRef = useRef(null); // Reference to the slider component

  const setting = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplaySpeed: 4000, // Adjust this value for smooth autoplay
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    prevArrow: (
      <button
        className="slick-prev"
        style={{ color: "red" }}
        onClick={() => sliderRef.current.slickPrev()} // Go to previous slide
      >
        Previous
      </button>
    ),
    nextArrow: (
      <button
        className="slick-next"
        onClick={() => sliderRef.current.slickNext()} // Go to next slide
      >
        Next
      </button>
    ),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const context = useContext(myContext);
  const { isVisible, setIsVisible, handleScroll, scrollToTop, scrol } = context;
  const [showscheduledowloaded, setshowscheduledowloaded] = useState(false);
  const scheduledowload = () => {
    setshowscheduledowloaded(true);
  };
  return (
    <>
      {/* import logo from './logo2.jpg' */}
      <Examheader />
      {/* BITSAT - ONLINE TEST SERIES */}

{/* banner section  */}


      <div
        className="otsh1 bitsatOtsBgImages"
        style={{ background: "rgb(245 246 255)", height: "9vh", color: "#000" }}
      >
        <h1 style={{ color: "#000" }} className="bitsatOts_title">
          BITSAT - ONLINE TEST SERIES
        </h1>
      </div>


      <div style={{ padding: "10px 0" }}>
        <Carousel
          autoPlay
          infiniteLoop
          showArrows={false}
          interval={4600}
          showThumbs={false}
          // showIndicators={false}
          showStatus={false}
        >
          <div>
            <img src={bitsatOTsBanner} alt="" />
          </div>
     
          {/* <div>
          <img src={img0} alt="" />
        </div> */}
        </Carousel>
      </div>

      <div className="">
        <div className="jeesection">
          <div className="jeediv">
            <h2 className="bitsatOtsTitles">
              BITSAT - ONLINE TEST SERIES - 2024
            </h2>
            {/* <a href="">COURSE BROCHURE</a> */}
          </div>
          <p className="jeesection_paragraph">
            BITSAT stands for Birla Institute of Technology and Science
            Admission Test. It is an online exam which is conducted for the
            students to take admission into B.E., B. Pharm and M.Sc. programmes
            offered at its three campuses located at Pilani, Goa and Hyderabad.
            Preparing well and attempting the exam successfully is one of the
            greatest desires aspirants. BITSAT Online Test Series provides an
            opportunity to the students to assess their level of learning and
            understanding of the subject before showcasing their prowess in the
            main entrance examination. Our online test series makes your
            understanding stronger and helps you to challenge yourself in an
            environment that resembles the final examination. We at eGRADTutor
            academy are offering previous year question papers and online tests
            series. The online test series interface is similar to the actual
            exam interface and it helps the student to strategize and approach
            the examination.
          </p>
        </div>
      </div>

      {/* WHY ONLINE TEST SERIES? */}
      <div
        className="FEATURES_Bgcolor"
        style={{ background: "rgb(245 246 255)" }}
      >
        <div className="jeesection">
          <h2 className="bitsatOtsTitles">WHY ONLINE TEST SERIES?</h2>
          {/* <ul className="FEATURES_-contant">
            <li style={{ alignItems: "center" }}>
              <span className="card_-img">
                <img width={90} height={90} src={academic} alt="" />
              </span>
              <p>
                BITSAT online test series is crafted by academic experts with
                years of domain knowledge and experience
              </p>
            </li>

            <li style={{ alignItems: "center" }}>
              <span className="card_-img">
                <img width={90} height={90} src={performance} alt="" />
              </span>
              <p>
                With the help of online test series, candidates are able to
                enhance their speed and accuracy of answering questions in the
                examination.
              </p>
            </li>
            <li style={{ alignItems: "center" }}>
              <span className="card_-img">
                <img width={90} height={90} src={analysis} alt="" />
              </span>
              <p>
                You will be receiving an in-depth analysis post your test that
                will help you understand your strong/weak areas.
              </p>
            </li>
            <li style={{ alignItems: "center" }}>
              <span className="card_-img">
                <img width={90} height={90} src={subjects} alt="" />
              </span>
              <p>
                BITSAT online test series lets you have a real-time exam
                experience. You can learn to manage your time better, attempt
                questions with accuracy and precision.
              </p>
            </li>
          </ul> */}

          <WhyOtsCards />

          {/* <Slider {...setting}>
            <div>
              <ul className="FEATURES_-contant">
                <li style={{ alignItems: "center" }}>
                  <span className="card_-img">
                    <img width={90} height={90} src={academic} alt="" />
                  </span>
                  <p>
                    BITSAT online test series is crafted by academic experts
                    with years of domain knowledge and experience
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <ul className="FEATURES_-contant">
                <li style={{ alignItems: "center" }}>
                  <span className="card_-img">
                    <img width={90} height={90} src={performance} alt="" />
                  </span>
                  <p>
                    With the help of online test series, candidates are able to
                    enhance their speed and accuracy of answering questions in
                    the examination.
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <ul className="FEATURES_-contant">
                <li style={{ alignItems: "center" }}>
                  <span className="card_-img">
                    <img width={90} height={90} src={analysis} alt="" />
                  </span>
                  <p>
                    You will be receiving an in-depth analysis post your test
                    that will help you understand your strong/weak areas.
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <ul className="FEATURES_-contant">
                <li style={{ alignItems: "center" }}>
                  <span className="card_-img">
                    <img width={90} height={90} src={subjects} alt="" />
                  </span>
                  <p>
                    BITSAT online test series lets you have a real-time exam
                    experience. You can learn to manage your time better,
                    attempt questions with accuracy and precision.
                  </p>
                </li>
              </ul>
            </div>
          </Slider> */}
          {/* <ul className="bitsatOtscard_container ">
            <li style={{ alignItems: "center" }}>
              <span className="bitsatOtscard_-img">
                <img src={academic} alt="" />
              </span>
              <p>
                BITSAT online test series is crafted by academic experts with
                years of domain knowledge and experience
              </p>
            </li>

            <li style={{ alignItems: "center" }}>
              <span className="bitsatOtscard_-img">
                <img src={performance} alt="" />
              </span>
              <p>
                With the help of online test series, candidates are able to
                enhance their speed and accuracy of answering questions in the
                examination.
              </p>
            </li>
            <li style={{ alignItems: "center" }}>
              <span className="bitsatOtscard_-img">
                <img src={analysis} alt="" />
              </span>
              <p>
                You will be receiving an in-depth analysis post your test that
                will help you understand your strong/weak areas.
              </p>
            </li>
            <li style={{ alignItems: "center" }}>
              <span className="bitsatOtscard_-img">
                <img src={subjects} alt="" />
              </span>
              <p>
                BITSAT online test series lets you have a real-time exam
                experience. You can learn to manage your time better, attempt
                questions with accuracy and precision.
              </p>
            </li>
          </ul> */}
        </div>
      </div>

      <div className="jeesection">
        <h2 className="bitsatOtsTitles">BUY ONLINE TEST SERIES</h2>

        {/* <div>
          <Carousel
            autoPlay
            infiniteLoop
            interval={8000}
            showStatus={false} >
            <div className="bitsatcard-container">

              <div className="bitsat-card">
                <div className="bitsat-card-body">
                  <h5 className="bitsat-card-title">CHALLENGER TEST SERIES</h5>
                  <div className="bitsatCardimage_section">
                    <img
                      className="bitsatCard-imgs"
                      src={CHALLENGER}
                      alt="CHALLENGER"
                    />
                  </div>
                  <ul>
                    <li className="PartTests">
                      <MdAddChart className="SlCalender" />
                      <p className="ChallengerBorder">
                        Total Tests <span>15</span>
                      </p>
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <SlCalender className="SlCalender" /> 01-04-2024 to
                      22-06-2024
                    </li>
                    <li
                      style={{ display: "inline-block", paddingTop: "54px" }}
                    ></li>
                  </ul>
                  <div className="schedule-buy-now">
                    <div className="price-tag">
                      <a
                        href="https://online-ug.egradtutor.in/"
                        target="_blank"
                        id="buy-now-btn"
                      >
                        {" "}
                        Buy Now{" "}
                      </a>
                    </div>
                    <span className="BitsatPriceTag">Price 1999/-</span>
                  </div>
                </div>
              </div>
      
              <div className="bitsat-card">
                <div className="bitsat-card-body">
                  <h5 className="bitsat-card-title">BITSAT OTS BUNDLE</h5>
                  <div className="bitsatCardimage_section">
                    <img className="bitsatCard-imgs" src={mpc} alt="mpc " />
                  </div>
                  <ul>
                    <li className="PartTests">
                      <LuFileText className="SlCalender" />
                      <p className="ChallengerBorder">
                        Total Tests <span>115</span>
                      </p>
                    </li>
                    <li className="PartTests">
                      <PiClipboardTextThin className="SlCalender" />
                      <p className="ChallengerBorder">
                        Subject wise Test <span> 100</span>
                      </p>
                    </li>
                    <li className="PartTests">
                      <MdAddChart className="SlCalender" />
                      <p className="ChallengerBorder">
                        Challenger Full Tests <span>15</span>
                      </p>
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <SlCalender className="SlCalender" /> 01-04-2024 to
                      22-06-2024
                    </li>
                  </ul>
                  <div className="schedule-buy-now">
                    <div className="price-tag">
                      <a
                        href="https://online-ug.egradtutor.in/"
                        target="_blank"
                        id="buy-now-btn"
                      >
                        Buy Now
                      </a>
                    </div>
                    <span className="BitsatPriceTag">Price 2999/-</span>
           
                  </div>
                </div>
              </div>
     
              <div className="bitsat-card">
                <div className="bitsat-card-body">
                  <h5
                    className="bitsat-card-title"
                    title="English, Logical Reasoning, and General Aptitude"
                  >
                    VERBAL; LR & GA - OTS
                  </h5>
                  <div className="bitsatCardimage_section">
                    <img
                      className="bitsatCard-imgs"
                      src={english}
                      alt="english"
                    />
                  </div>
                  <ul>
                    <li
                      className="PartTests"
                      title="English, Logical Reasoning, and General Aptitude"
                    >
                      <MdAddChart className="SlCalender" />
                      <p className="ChallengerBorder">
                        Total Tests <span>25</span>
                      </p>
                    </li>
                    <li className="PartTests">
                      <LuFileText className="SlCalender" />
                      <p className="ChallengerBorder">
                        Part Tests <span> 20</span>
                      </p>
                    </li>
                    <li className="PartTests">
                      <PiClipboardTextThin className="SlCalender" />
                      <p className="ChallengerBorder">
                        Full Tests <span> 5</span>
                      </p>
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <SlCalender className="SlCalender" /> 01-04-2024 to
                      22-06-2024
                    </li>
                  </ul>
                  <div className="schedule-buy-now">
                    <div className="price-tag">
                      <a
                        href="https://online-ug.egradtutor.in/"
                        target="_blank"
                        id="buy-now-btn"
                      >
                        Buy Now
                      </a>
                    </div>
                    <span className="BitsatPriceTag">Price 699/-</span>
              
                  </div>
                </div>
              </div>

     
              <div className="bitsat-card">
                <div className="bitsat-card-body">
                  <h5 className="bitsat-card-title">MATHEMATICS - OTS</h5>
                  <div className="bitsatCardimage_section">
                    <img className="bitsatCard-imgs" src={MATHEMATICS} alt="" />
                  </div>
                  <ul>
                    <li className="PartTests">
                      <MdAddChart className="SlCalender" />
                      <p className="ChallengerBorder">
                        Total Tests <span>25</span>
                      </p>
                    </li>
                    <li className="PartTests">
                      <LuFileText className="SlCalender" />
                      <p className="ChallengerBorder">
                        Part Tests <span> 20</span>
                      </p>
                    </li>
                    <li className="PartTests">
                      <PiClipboardTextThin className="SlCalender" />
                      <p className="ChallengerBorder">
                        Full Tests <span> 5</span>
                      </p>
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <SlCalender className="SlCalender" /> 01-04-2024 to
                      22-06-2024
                    </li>
                  </ul>
                  <div className="schedule-buy-now">
                    <div className="price-tag">
                      <a
                        href="https://online-ug.egradtutor.in/"
                        target="_blank"
                        id="buy-now-btn"
                      >
                        Buy Now
                      </a>
                    </div>
                    <span className="BitsatPriceTag">Price 699/-</span>
             
                  </div>
                </div>
              </div>
            </div>
            <div className="bitsatcard-container">
              {" "}
       
              <div className="bitsat-card">
                <div className="bitsat-card-body">
                  <h5 className="bitsat-card-title">PHYSICS - OTS</h5>
                  <div className="bitsatCardimage_section">
                    <img
                      className="bitsatCard-imgs"
                      src={PHYSICS}
                      alt="PHYSICS"
                    />
                  </div>
                  <ul>
                    <li className="PartTests">
                      <MdAddChart className="SlCalender" />
                      <p className="ChallengerBorder">
                        Total Tests <span>25</span>
                      </p>
                    </li>
                    <li className="PartTests">
                      <LuFileText className="SlCalender" />
                      <p className="ChallengerBorder">
                        Part Tests <span> 20</span>
                      </p>
                    </li>
                    <li className="PartTests">
                      <PiClipboardTextThin className="SlCalender" />
                      <p className="ChallengerBorder">
                        Full Tests <span> 5</span>
                      </p>
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <SlCalender className="SlCalender" /> 01-04-2024 to
                      22-06-2024
                    </li>
                  </ul>

                  <div className="schedule-buy-now">
                    <div className="price-tag">
                      <a
                        href="https://online-ug.egradtutor.in"
                        target="_blank"
                        id="buy-now-btn"
                      >
                        {" "}
                        Buy Now{" "}
                      </a>
                    </div>
                    <span className="BitsatPriceTag">Price 699/-</span>
                  
                  </div>
                </div>
              </div>
             
              <div className="bitsat-card">
                <div className="bitsat-card-body">
                  <h5 className="bitsat-card-title">CHEMISTRY - OTS</h5>
                  <div className="bitsatCardimage_section">
                    <img
                      className="bitsatCard-imgs"
                      src={CHEMISTRY}
                      alt="CHEMISTRY"
                    />
                  </div>
                  <ul>
                    <li className="PartTests">
                      <MdAddChart className="SlCalender" />
                      <p className="ChallengerBorder">
                        Total Tests <span>25</span>
                      </p>
                    </li>
                    <li className="PartTests">
                      <LuFileText className="SlCalender" />
                      <p className="ChallengerBorder">
                        Part Tests <span> 20</span>
                      </p>
                    </li>
                    <li className="PartTests">
                      <PiClipboardTextThin className="SlCalender" />
                      <p className="ChallengerBorder">
                        {" "}
                        Full Tests <span> 5</span>
                      </p>
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <SlCalender className="SlCalender" /> 01-04-2024 to
                      22-06-2024
                    </li>
                  </ul>

                  <div className="schedule-buy-now">
                    <div className="price-tag">
                      <a
                        href="https://online-ug.egradtutor.in/"
                        target="_blank"
                        id="buy-now-btn"
                      >
                        Buy Now
                      </a>
                    </div>
                    <span className="BitsatPriceTag">Price 699/-</span>
                   
                  </div>
                </div>
              </div>
            </div>
          </Carousel>
        </div> */}
        <div className="">
          <Slider ref={sliderRef} {...settings}>
            {/* car1 CHALLENGER TEST SERIES*/}
            <div id="bitsat-card">
              <div className="bitsat-card" id="bitsat-card">
                <div className="bitsat-card-body">
                  <h5 className="bitsat-card-title bitsat-card-title1">
                    BITSAT-OTS
                  </h5>
                  <h5 className="bitsat-card-title ">CHALLENGER TEST SERIES</h5>
                  <div className="bitsatCardimage_section">
                    <img
                      className="bitsatCard-imgs"
                      src={CHALLENGER}
                      alt="CHALLENGER"
                    />
                  </div>
                  <ul>
                    <li className="PartTests">
                      <MdAddChart className="SlCalender" />
                      <p className="ChallengerBorder">
                        Total Tests <span>15</span>
                      </p>
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <SlCalender className="SlCalender" /> 01-04-2024 to
                      22-06-2024
                    </li>
                    <li
                      style={{ display: "inline-block", paddingTop: "54px" }}
                    ></li>
                  </ul>
                  <div className="schedule-buy-now">
                    <div className="price-tag">
                      <a
                        href="https://online-ug.egradtutor.in/"
                        target="_blank"
                        id="buy-now-btn"
                      >
                        {" "}
                        Buy Now{" "}
                      </a>
                    </div>
                    <span className="BitsatPriceTag">Price 1999/-</span>
                    {/* <a href="#" id="schedule-btn"> Schedule </a> */}
                  </div>
                </div>
              </div>
            </div>
            {/* car 2 RANKER BUNDLE */}
            <div id="bitsat-card">
              <div className="bitsat-card" id="bitsat-card">
                <div className="bitsat-card-body">
                  <h5 className="bitsat-card-title bitsat-card-title1">
                    BITSAT-OTS
                  </h5>
                  <h5 className="bitsat-card-title ">RANKER BUNDLE</h5>
                  <div className="bitsatCardimage_section">
                    <img className="bitsatCard-imgs" src={mpc} alt="mpc " />
                  </div>
                  <ul>
                    <li className="PartTests">
                      <LuFileText className="SlCalender" />
                      <p className="ChallengerBorder">
                        Total Tests <span>120</span>
                      </p>
                    </li>
                    <li className="PartTests">
                      <PiClipboardTextThin className="SlCalender" />
                      <p className="ChallengerBorder">
                        Subject wise Test <span>90</span>
                      </p>
                    </li>
                    <li className="PartTests">
                      <MdAddChart className="SlCalender" />
                      <p className="ChallengerBorder">
                        Challenger Full Tests <span>30</span>
                      </p>
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <SlCalender className="SlCalender" /> 01-04-2024 to
                      22-06-2024
                    </li>
                  </ul>
                  <div className="schedule-buy-now">
                    <div className="price-tag">
                      <a
                        href="https://online-ug.egradtutor.in/"
                        target="_blank"
                        id="buy-now-btn"
                      >
                        Buy Now
                      </a>
                    </div>
                    <span className="BitsatPriceTag">Price 2999/-</span>
                    {/* <a href="#" id="schedule-btn">
                      Schedule
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            {/* car 3*/}
            <div id="bitsat-card">
              <div className="bitsat-card" id="bitsat-card">
                <div className="bitsat-card-body">
                  <h5 className="bitsat-card-title bitsat-card-title1">
                    BITSAT-OTS
                  </h5>

                  <h5
                    className="bitsat-card-title"
                    title="English, Logical Reasoning, and General Aptitude"
                  >
                    VERBAL, LR & GA
                  </h5>
                  <div className="bitsatCardimage_section">
                    <img
                      className="bitsatCard-imgs"
                      src={english}
                      alt="english"
                    />
                  </div>
                  <ul>
                    <li
                      className="PartTests"
                      title="English, Logical Reasoning, and General Aptitude"
                    >
                      <MdAddChart className="SlCalender" />
                      <p className="ChallengerBorder">
                        Total Tests <span>15</span>
                      </p>
                    </li>
                    <li className="PartTests">
                      <LuFileText className="SlCalender" />
                      <p className="ChallengerBorder">
                        Part Tests <span> 10</span>
                      </p>
                    </li>
                    <li className="PartTests">
                      <PiClipboardTextThin className="SlCalender" />
                      <p className="ChallengerBorder">
                        Full Tests <span> 5</span>
                      </p>
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <SlCalender className="SlCalender" /> 01-04-2024 to
                      22-06-2024
                    </li>
                  </ul>
                  <div className="schedule-buy-now">
                    <div className="price-tag">
                      <a
                        href="https://online-ug.egradtutor.in/"
                        target="_blank"
                        id="buy-now-btn"
                      >
                        Buy Now
                      </a>
                    </div>
                    <span className="BitsatPriceTag">Price 699/-</span>
                    {/* <a href="#" id="schedule-btn">
                      Schedule
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            {/* car 4*/}
            <div id="bitsat-card">
              <div className="bitsat-card" id="bitsat-card">
                <div className="bitsat-card-body">
                  <h5 className="bitsat-card-title bitsat-card-title1">
                    BITSAT-OTS
                  </h5>

                  <h5 className="bitsat-card-title">MATHEMATICS - OTS</h5>
                  <div className="bitsatCardimage_section">
                    <img className="bitsatCard-imgs" src={MATHEMATICS} alt="" />
                  </div>
                  <ul>
                    <li className="PartTests">
                      <MdAddChart className="SlCalender" />
                      <p className="ChallengerBorder">
                        Total Tests <span>25</span>
                      </p>
                    </li>
                    <li className="PartTests">
                      <LuFileText className="SlCalender" />
                      <p className="ChallengerBorder">
                        Part Tests <span> 20</span>
                      </p>
                    </li>
                    <li className="PartTests">
                      <PiClipboardTextThin className="SlCalender" />
                      <p className="ChallengerBorder">
                        Full Tests <span> 5</span>
                      </p>
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <SlCalender className="SlCalender" /> 01-04-2024 to
                      22-06-2024
                    </li>
                  </ul>
                  <div className="schedule-buy-now">
                    <div className="price-tag">
                      <a
                        href="https://online-ug.egradtutor.in/"
                        target="_blank"
                        id="buy-now-btn"
                      >
                        Buy Now
                      </a>
                    </div>
                    <span className="BitsatPriceTag">Price 699/-</span>
                    {/* <a href="#" id="schedule-btn">
                      Schedule
                    </a> */}
                  </div>
                </div>
              </div>{" "}
            </div>
            {/* card 5 */}
            <div id="bitsat-card">
              <div className="bitsat-card" id="bitsat-card">
                <div className="bitsat-card-body">
                  <h5 className="bitsat-card-title bitsat-card-title1">
                    BITSAT-OTS
                  </h5>

                  <h5 className="bitsat-card-title">PHYSICS</h5>
                  <div className="bitsatCardimage_section">
                    <img
                      className="bitsatCard-imgs"
                      src={PHYSICS}
                      alt="PHYSICS"
                    />
                  </div>
                  <ul>
                    <li className="PartTests">
                      <MdAddChart className="SlCalender" />
                      <p className="ChallengerBorder">
                        Total Tests <span>25</span>
                      </p>
                    </li>
                    <li className="PartTests">
                      <LuFileText className="SlCalender" />
                      <p className="ChallengerBorder">
                        Part Tests <span> 20</span>
                      </p>
                    </li>
                    <li className="PartTests">
                      <PiClipboardTextThin className="SlCalender" />
                      <p className="ChallengerBorder">
                        Full Tests <span> 5</span>
                      </p>
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <SlCalender className="SlCalender" /> 01-04-2024 to
                      22-06-2024
                    </li>
                  </ul>

                  <div className="schedule-buy-now">
                    <div className="price-tag">
                      <a
                        href="https://online-ug.egradtutor.in"
                        target="_blank"
                        id="buy-now-btn"
                      >
                        {" "}
                        Buy Now{" "}
                      </a>
                    </div>
                    <span className="BitsatPriceTag">Price 699/-</span>
                    {/* <a href="#" id="schedule-btn">
                      Schedule
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            {/* car 6*/}
            <div id="bitsat-card">
              <div className="bitsat-card" id="bitsat-card">
                <div className="bitsat-card-body">
                  <h5 className="bitsat-card-title bitsat-card-title1">
                    BITSAT-OTS
                  </h5>

                  <h5 className="bitsat-card-title">CHEMISTRY</h5>
                  <div className="bitsatCardimage_section">
                    <img
                      className="bitsatCard-imgs"
                      src={CHEMISTRY}
                      alt="CHEMISTRY"
                    />
                  </div>
                  <ul>
                    <li className="PartTests">
                      <MdAddChart className="SlCalender" />
                      <p className="ChallengerBorder">
                        Total Tests <span>25</span>
                      </p>
                    </li>
                    <li className="PartTests">
                      <LuFileText className="SlCalender" />
                      <p className="ChallengerBorder">
                        Part Tests <span> 20</span>
                      </p>
                    </li>
                    <li className="PartTests">
                      <PiClipboardTextThin className="SlCalender" />
                      <p className="ChallengerBorder">
                        {" "}
                        Full Tests <span> 5</span>
                      </p>
                    </li>
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <SlCalender className="SlCalender" /> 01-04-2024 to
                      22-06-2024
                    </li>
                  </ul>

                  <div className="schedule-buy-now">
                    <div className="price-tag">
                      <a
                        href="https://online-ug.egradtutor.in/"
                        target="_blank"
                        id="buy-now-btn"
                      >
                        Buy Now
                      </a>
                    </div>
                    <span className="BitsatPriceTag">Price 699/-</span>
                    {/* <a href="#" id="schedule-btn">
                      Schedule
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>

      {/* FEATURES_Bgcolor */}
      <div
        className="FEATURES_Bgcolor FEATURES_Bgcolor_bitsat_p_4"
        style={{ background: "rgb(245 246 255)" }}
      >
        <div className="whyonline_ots whyonline_otsmedia">
          <h2 className="bitsatOtsTitles">FEATURES OF ONLINE TEST SERIES</h2>
          <ul className="FEATUREScontant">
            <li>
              <div className="bitsatFEATUREScontant">
                <div className="bitsatFEATUREScontant_list">
                  <p>Ranker board for every exam.</p>
                  <div className="bitsatFEATUREScontantimg">
                    <img width={90} height={90} src={Indiarank} alt="" />
                  </div>
                </div>

                <div className="bitsatFEATUREScontantlist">
                  <p>Detailed performance analysis for all exams.</p>
                  <div className="bitsatFEATUREScontantimg">
                    <img width={90} height={90} src={subjectsd} alt="" />
                  </div>
                </div>
              </div>
            </li>

            <li>
              <div className="bitsatFEATUREScontant">
                <div className="bitsatFEATUREScontant_list">
                  <p>Developed as per the latest syllabus of BITSAT exam.</p>
                  <div className="bitsatFEATUREScontantimg">
                    <img width={90} height={90} src={answering} alt="" />
                  </div>
                </div>

                <div className="bitsatFEATUREScontantlist">
                  <p>Error free solutions to all questions.</p>
                  <div className="bitsatFEATUREScontantimg">
                    <img width={90} height={90} src={Additional} alt="" />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      {/* <Faq /> */}
      <Footer />
      {isVisible && (
        <span className="UpArrow" onClick={scrollToTop}>
          <a href="#topMove">
            <MdOutlineVerticalAlignTop style={{ fontSize: "30px" }} />
          </a>
        </span>
      )}
    </>
  );
};




export const WhyOtsCards = () => {
  return (
    <div>
         <ul className="WhyOtsCardsContainer">
            <li className="WhyOtsCardsCard">
              <span >
                <img width={90} height={90} src={academic} alt="" />
                {/* <SiKnowledgebase /> */}
              </span>
              <p>
                BITSAT online test series is crafted by academic experts with
                years of domain knowledge and experience
              </p>
            </li>

            <li className="WhyOtsCardsCard"> 
              <span >
              {/* <SiKnowledgebase /> */}
                <img width={90} height={90} src={performance} alt="" />
              </span>
              <p>
                With the help of online test series, candidates are able to
                enhance their speed and accuracy of answering questions in the
                examination.
              </p>
            </li>
            <li className="WhyOtsCardsCard"> 
              <span>
              {/* <SiKnowledgebase /> */}
                <img width={90} height={90} src={analysis} alt="" />
              </span>
              <p>
                You will be receiving an in-depth analysis post your test that
                will help you understand your strong/weak areas.
              </p>
            </li>
            <li className="WhyOtsCardsCard">
              <span>
              {/* <SiKnowledgebase /> */}
                <img width={90} height={90} src={subjects} alt="" />
              </span>
              <p>
                BITSAT online test series lets you have a real-time exam
                experience. You can learn to manage your time better, attempt
                questions with accuracy and precision.
              </p>
            </li>
          </ul>
    </div>
  )
}
