import React from 'react'

function JESTSyllabus() {
  return (
    <div><b>
    <a className='SYLLABUS_pdf' href="https://www.jest.org.in/sites/default/files/JEST-Syllabus.pdf " target='_blank'>Syllabus Information  <i class="fa-solid fa-download"></i></a>
  </b></div>
  )
}

export default JESTSyllabus