import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../../../ug_homepage/components/Footer/Footer'
import { IoMdHome } from "react-icons/io";
import onlinets from "../../infographic/onlinets.png";
import onlinets1 from "../../infographic/image-removebg-preview (10).png";
import new_icon_gif from '../../infographic/new-icon-gif-2.gif'
import { Nav } from '../../Nav';
import Additional from '../../Images/Additional.png'
const VITEEE_MockTest = ({ setSelectedTest }) => {
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);


    const availableRoutes = [
        "/VITEEESubmitFormandmail/1",
        "/VITEEESubmitFormandmail/2",
        "/VITEEESubmitFormandmail/3"
    ];


    const isRouteAvailable = (path) => {
        return availableRoutes.includes(path);
    };

    const handleClick = (path, event) => {
        event.preventDefault(); // Prevent the default link behavior

        if (isRouteAvailable(path)) {
            navigate(path); // Navigate to the route if available
        } else {
            setShowPopup(true); // Show the custom popup if route is not available
        }
    };

    const handleClosePopup = () => {
        setShowPopup(false); // Hide the popup
    };



    const handleTestSelection = (test) => {
        setSelectedTest(test);
        navigate('/VITEEESubmitFormandmail');
    };
    return (
        <>
            <div>
                <div className="ugexam_header">
                    {Nav.map((NavData, index) => {
                        return (
                            <div className="header ug_exam_header" key={index}>
                                <div className={NavData.logo_img_container}>
                                    <Link to={"/"}>

                                        <img src={NavData.logo} alt="" />
                                    </Link>
                                </div>


                                <div className="exam_login_menu  ">
                                    <li>
                                        <Link to='/home' className={NavData.navlist} id='exam_login_menu_home'>
                                            <IoMdHome /> {NavData.link1}
                                        </Link>
                                    </li>
                                    {/* <a
                    href="https://online-ug.egradtutor.in"
                    target='_blank'
                    className={NavData.login}
                  >
                    {NavData.btn1}
                  </a> */}

                                </div>
                            </div>

                        );
                    })}


                </div>
            </div>

            {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <span className="close-popup" onClick={handleClosePopup}>&times;</span>
            <p>This mock test will be available soon.</p>
          </div>
        </div>
      )}

<div className='Mock_Tests_Heading'>
<h2 style={{ color: 'white' }}>VITEEE Mock Test</h2>

</div>


            <div className="jeesection_Neet " id="PracticeMockTest">
                <div className="PracticeMockTest">
                    <div className="PracticeMockTest_Card PMT_1">
                        <span className="mockImg">
                            <img src={Additional} alt="" />
                        </span>
                        <h2>MOCK TEST-1</h2>

                        <div>
                            <p>130 Questions 3 Hour 360 Marks</p>
                        </div>
                        <span className="PracticeMockTest_Container">
                            <a href="/SubmitFormAndMail/1" onClick={(e) => handleClick("/SubmitFormAndMail/1", e)}>Take Test</a>

                        </span>
                    </div>

                    <div className="PracticeMockTest_Card PMT_2">
                        <span className="mockImg">
                            <img src={onlinets1} alt="" />
                        </span>
                        <h2>MOCK TEST-2</h2>

                        <div>
                            <p>130 Questions 3 Hour 360 Marks</p>
                        </div>
                        <span className="PracticeMockTest_Container">
                            <a href="/SubmitFormAndMail/2" onClick={(e) => handleClick("/SubmitFormAndMail/2", e)}>Take Test</a>

                        </span>
                    </div>

                    <div className="PracticeMockTest_Card PMT_3">
                        <span className="mockImg">
                            <img src={onlinets} alt="" />
                        </span>
                        <h2>MOCK TEST-3</h2>

                        <div>
                            <p>130 Questions 3 Hour 360 Marks</p>
                        </div>
                        <span className="PracticeMockTest_Container">
                            <a href="/SubmitFormAndMail/3" onClick={(e) => handleClick("/SubmitFormAndMail/3", e)}>Take Test</a>

                        </span>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default VITEEE_MockTest