import React, { useState, useEffect } from "react";
// import "../NEET/NEET.css";
import { useParams } from "react-router-dom";
import GATE_MockTest_11 from "./JSON_DATA/GATE_MockTest_1.json";
import GATE_MockTest_2 from "./JSON_DATA/GATE_MockTest_2.json";
import GATE_MockTest_3 from "./JSON_DATA/GATE_MockTest_3.json";
import "./OnlineTestSerices_pg.css";
import greenBox from "../../MockTest/asserts/greenBox.png";
import orangeBox from "../../MockTest/asserts/orangeBox.png";
import purpleBox from "../../MockTest/asserts/purpleBox.png";
import purpleTickBox from "../../MockTest/asserts/purpleTickBox.png";
import grayBox from "../../MockTest/asserts/grayBox.png";
import ScientificCalculator from "./ScientificCalculator";

const mockTestsData = {
  1: GATE_MockTest_11,
  2: GATE_MockTest_2,
  3: GATE_MockTest_3,
};

function GATE_MockTest_1() {
  const { mockId } = useParams();
  const [showCalculator, setShowCalculator] = useState(false);
  const questionsData = mockTestsData[mockId];
  const [selectedSubject, setSelectedSubject] = useState(
    questionsData.length > 0 ? questionsData[0].SubjectName : null
  );
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responses, setResponses] = useState({});
  const [viewedQuestions, setViewedQuestions] = useState({});
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [markedForReview, setMarkedForReview] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [remainingTime, setRemainingTime] = useState(2 * 60 * 60 + 30 * 60);
  const [isAutoSubmit, setIsAutoSubmit] = useState(false);
  const [totalMarks, setTotalMarks] = useState(0);
  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
  const [wrongAnswersCount, setWrongAnswersCount] = useState(0);
  const [answeredCount, setAnsweredCount] = useState(0);
  const [notAnsweredCount, setNotAnsweredCount] = useState(0);
  const [showQuestions, setShowQuestions] = useState(false);
  const [hoveredSubject, setHoveredSubject] = useState(null);
  const getQuestionKey = (subject, sectionIndex, questionId) => {
    return `${subject}-${sectionIndex}-${questionId}`;
  };

  // const calculateCounts = () => {
  //   let answeredCount = 0;
  //   let markedForReviewCount = 0;
  //   let notVisitedCount = 0;
  //   let answeredAndMarkedForReview = 0;
  //   let notAnsweredCount = 0;

  //   questionsData.forEach((subject) => {
  //     subject.sections.forEach((section, sectionIndex) => {
  //       section.questions.forEach((question) => {
  //         const key = getQuestionKey(
  //           subject.SubjectName,
  //           sectionIndex,
  //           question.question_id
  //         );

  //         const response = responses[key];
  //         const viewed = viewedQuestions[key];
  //         const markedForReviewStatus = markedForReview[key];

  //         if (response !== undefined && response !== null) {
  //           if (markedForReviewStatus) {
  //             answeredAndMarkedForReview++;
  //           } else {
  //             answeredCount++;
  //           }
  //         } else {
  //           if (markedForReviewStatus) {
  //             markedForReviewCount++;
  //           } else if (!viewed) {
  //             notVisitedCount++;
  //           } else {
  //             notAnsweredCount++;
  //           }
  //         }
  //       });
  //     });
  //   });

  //   return {
  //     answeredCount,
  //     markedForReviewCount,
  //     answeredAndMarkedForReview,
  //     notVisitedCount,
  //     notAnsweredCount,
  //   };
  // };

  // const calculateCounts = () => {
  //   const subjectCounts = {};

  //   questionsData.forEach((subject) => {
  //     const subjectName = subject.SubjectName;
  //     const sectionCounts = {};

  //     subject.sections.forEach((section, sectionIndex) => {
  //       let answeredCount = 0;
  //       let markedForReviewCount = 0;
  //       let answeredAndMarkedForReview = 0;
  //       let notVisitedCount = 0;
  //       let notAnsweredCount = 0;

  //       section.questions.forEach((question) => {
  //         const key = getQuestionKey(
  //           subjectName,
  //           sectionIndex,
  //           question.question_id
  //         );

  //         const response = responses[key];
  //         const viewed = viewedQuestions[key];
  //         const markedForReviewStatus = markedForReview[key];

  //         if (response !== undefined && response !== null) {
  //           if (markedForReviewStatus) {
  //             answeredAndMarkedForReview++;
  //           } else {
  //             answeredCount++;
  //           }
  //         } else {
  //           if (markedForReviewStatus) {
  //             markedForReviewCount++;
  //           } else if (!viewed) {
  //             notVisitedCount++;
  //           } else {
  //             notAnsweredCount++;
  //           }
  //         }
  //       });

  //       sectionCounts[section.SectionName] = {
  //         answeredCount,
  //         markedForReviewCount,
  //         answeredAndMarkedForReview,
  //         notVisitedCount,
  //         notAnsweredCount,
  //       };
  //     subjectCounts[subjectName] = sectionCounts;
  //     });
  //   });

  //   return subjectCounts;
  // };



  const calculateCounts = () => {
    const subjectCounts = {};
    let totalExams = 0;
    let totalAnsweredCount = 0;
    let totalMarkedForReviewCount = 0;
    let totalAnsweredAndMarkedForReview = 0;
    let totalNotVisitedCount = 0;
    let totalNotAnsweredCount = 0;
  
    questionsData.forEach((subject) => {
      const subjectName = subject.SubjectName;
      const sectionCounts = {};
      let subjectTotalQuestions = 0;
      let subjectAnsweredCount = 0;
      let subjectMarkedForReviewCount = 0;
      let subjectAnsweredAndMarkedForReview = 0;
      let subjectNotVisitedCount = 0;
      let subjectNotAnsweredCount = 0;
  
      subject.sections.forEach((section, sectionIndex) => {
        let answeredCount = 0;
        let markedForReviewCount = 0;
        let answeredAndMarkedForReview = 0;
        let notVisitedCount = 0;
        let notAnsweredCount = 0;
  
        section.questions.forEach((question) => {
          const key = getQuestionKey(subjectName, sectionIndex, question.question_id);
          const response = responses[key];
          const viewed = viewedQuestions[key];
          const markedForReviewStatus = markedForReview[key];
  
          if (response !== undefined && response !== null) {
            if (markedForReviewStatus) {
              answeredAndMarkedForReview++;
            } else {
              answeredCount++;
            }
          } else {
            if (markedForReviewStatus) {
              markedForReviewCount++;
            } else if (!viewed) {
              notVisitedCount++;
            } else {
              notAnsweredCount++;
            }
          }
        });
  
        // Update section counts
        sectionCounts[section.SectionName] = {
          answeredCount,
          markedForReviewCount,
          answeredAndMarkedForReview,
          notVisitedCount,
          notAnsweredCount,
          totalQuestions: section.questions.length,
        };
  
        // Update subject totals
        subjectTotalQuestions += section.questions.length;
        subjectAnsweredCount += answeredCount;
        subjectMarkedForReviewCount += markedForReviewCount;
        subjectAnsweredAndMarkedForReview += answeredAndMarkedForReview;
        subjectNotVisitedCount += notVisitedCount;
        subjectNotAnsweredCount += notAnsweredCount;
      });
  
      // Update subject counts
      subjectCounts[subjectName] = {
        totalQuestions: subjectTotalQuestions,
        answeredCount: subjectAnsweredCount,
        markedForReviewCount: subjectMarkedForReviewCount,
        answeredAndMarkedForReview: subjectAnsweredAndMarkedForReview,
        notVisitedCount: subjectNotVisitedCount,
        notAnsweredCount: subjectNotAnsweredCount,
      };
  
      // Update overall totals
      totalExams += subjectTotalQuestions;
      totalAnsweredCount += subjectAnsweredCount;
      totalMarkedForReviewCount += subjectMarkedForReviewCount;
      totalAnsweredAndMarkedForReview += subjectAnsweredAndMarkedForReview;
      totalNotVisitedCount += subjectNotVisitedCount;
      totalNotAnsweredCount += subjectNotAnsweredCount;
    });
  
    return {
      totalExams,
      totalAnsweredCount,
      totalMarkedForReviewCount,
      totalAnsweredAndMarkedForReview,
      totalNotVisitedCount,
      totalNotAnsweredCount,
      subjects: subjectCounts,
    };
  };
  

  const [counts, setCounts] = useState(calculateCounts());

  const getQuestionClassName = (index, key) => {
    let className = "";
    if (index === currentQuestionIndex) className += " active ";
    if (markedForReview[key]) {
      className += responses[key] ? " marked-answered " : " marked ";
    } else if (responses[key]) {
      className += " answered ";
    } else if (viewedQuestions[key]) {
      className += " viewed ";
    } else {
      className += " not-visited ";
    }
    return className.trim();
  };

  const goToQuestion = (index) => {
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentSection.questions[index].question_id
    );
    if (!viewedQuestions[key]) {
      setViewedQuestions((prev) => ({ ...prev, [key]: true }));
      setNotAnsweredCount((prevNotAnsweredCount) => prevNotAnsweredCount + 1); // Increment notAnsweredCount
    }
    setCurrentQuestionIndex(index);
  };

  const moveToQuestion = (newSectionIndex, newQuestionIndex) => {
    const key = getQuestionKey(
      selectedSubject,
      newSectionIndex,
      currentSubject.sections[newSectionIndex].questions[newQuestionIndex]
        .question_id
    );
    if (!viewedQuestions[key]) {
      setViewedQuestions((prev) => ({ ...prev, [key]: true }));
      setNotAnsweredCount((prevNotAnsweredCount) => prevNotAnsweredCount + 1); // Increment notAnsweredCount
    }
    setCurrentSectionIndex(newSectionIndex);
    setCurrentQuestionIndex(newQuestionIndex);
  };

  const handleIconClick = () => {
    setShowCalculator(!showCalculator);
  };

  useEffect(() => {
    let count = 0;
    questionsData.forEach((subject) => {
      subject.sections.forEach((section) => {
        count += section.questions.length;
      });
    });
  }, []);

  const handleOptionChange = (option) => {
    console.log("Selected option:", option);
    if (currentQuestion) {
      const key = getQuestionKey(
        selectedSubject,
        currentSectionIndex,
        currentQuestion.question_id
      );
      console.log("Key:", key);
      setResponses({ ...responses, [key]: option });
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    if (remainingTime === 0) {
      setIsAutoSubmit(true);
      setShowPopup(true);
      // handleAutoSubmit();
    }
  }, [remainingTime]);

  const clearResponse = () => {
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    if (responses[key]) {
      setAnsweredCount((prevAnsweredCount) => prevAnsweredCount - 1);
      setNotAnsweredCount((prevNotAnsweredCount) => prevNotAnsweredCount + 1);
    }

    // Clear the response and ensure the question is marked as "viewed"
    setResponses({ ...responses, [key]: null });
    setAnsweredQuestions((prevAnswered) => ({ ...prevAnswered, [key]: false }));
    setViewedQuestions((prevViewed) => ({ ...prevViewed, [key]: true }));
  };

  const nextQuestion = () => {
    const currentSection = currentSubject.sections[currentSectionIndex];
    if (currentQuestionIndex < currentSection.questions.length - 1) {
      moveToQuestion(currentSectionIndex, currentQuestionIndex + 1);
    } else {
      const currentSubjectIndex = questionsData.findIndex(
        (subject) => subject.SubjectName === selectedSubject
      );
      const nextSectionIndex = currentSectionIndex + 1;
      if (nextSectionIndex < currentSubject.sections.length) {
        setCurrentSectionIndex(nextSectionIndex);
        setCurrentQuestionIndex(0);
      } else if (currentSubjectIndex < questionsData.length - 1) {
        const nextSubject = questionsData[currentSubjectIndex + 1];
        setSelectedSubject(nextSubject.SubjectName);
        setCurrentSectionIndex(0);
        setCurrentQuestionIndex(0);
      }
    }
  };
  const previousQuestion = () => {
    if (currentQuestionIndex > 0) {
      moveToQuestion(currentSectionIndex, currentQuestionIndex - 1);
    } else {
      const currentSubjectIndex = questionsData.findIndex(
        (subject) => subject.SubjectName === selectedSubject
      );
      const prevSectionIndex = currentSectionIndex - 1;

      if (prevSectionIndex >= 0) {
        const prevSection = currentSubject.sections[prevSectionIndex];
        const prevQuestionIndex = prevSection.questions.length - 1;
        const key = getQuestionKey(
          selectedSubject,
          prevSectionIndex,
          prevSection.questions[prevQuestionIndex].question_id
        );

        setCurrentSectionIndex(prevSectionIndex);
        setCurrentQuestionIndex(prevQuestionIndex);

        if (!viewedQuestions[key]) {
          setViewedQuestions((prev) => ({ ...prev, [key]: true }));
          setNotAnsweredCount(
            (prevNotAnsweredCount) => prevNotAnsweredCount + 1
          ); // Increment notAnsweredCount
        }
      } else if (currentSubjectIndex > 0) {
        const prevSubject = questionsData[currentSubjectIndex - 1];
        const lastSectionIndex = prevSubject.sections.length - 1;
        const lastQuestionIndex =
          prevSubject.sections[lastSectionIndex].questions.length - 1;
        const key = getQuestionKey(
          prevSubject.SubjectName,
          lastSectionIndex,
          prevSubject.sections[lastSectionIndex].questions[lastQuestionIndex]
            .question_id
        );

        setSelectedSubject(prevSubject.SubjectName);
        setCurrentSectionIndex(lastSectionIndex);
        setCurrentQuestionIndex(lastQuestionIndex);

        if (!viewedQuestions[key]) {
          setViewedQuestions((prev) => ({ ...prev, [key]: true }));
          setNotAnsweredCount(
            (prevNotAnsweredCount) => prevNotAnsweredCount + 1
          ); // Increment notAnsweredCount
        }
      }
    }
  };

  useEffect(() => {
    const counts = calculateCounts();
    setCounts(counts);
  }, [responses, viewedQuestions, markedForReview]);

  useEffect(() => {
    const counts = calculateCounts();
    setAnsweredCount(counts.answeredCount);
    setNotAnsweredCount(counts.notAnsweredCount);
  }, [responses, viewedQuestions, markedForReview]);

  const saveAndNext = () => {
    const currentSection = currentSubject.sections[currentSectionIndex];
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );
    const currentResponse = responses[key];
    const wasPreviouslyAnswered = answeredQuestions[key];

    // Handle the answer state
    if (currentResponse) {
      if (!wasPreviouslyAnswered) {
        setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
        setNotAnsweredCount((prevNotAnsweredCount) => prevNotAnsweredCount - 1);
        setAnsweredQuestions((prevAnswered) => ({
          ...prevAnswered,
          [key]: true,
        }));
      }
    } else if (wasPreviouslyAnswered) {
      setAnsweredCount((prevAnsweredCount) => prevAnsweredCount - 1);
      setNotAnsweredCount((prevNotAnsweredCount) => prevNotAnsweredCount + 1);
      setAnsweredQuestions((prevAnswered) => ({
        ...prevAnswered,
        [key]: false,
      }));
    }

    // Mark the question as viewed
    setViewedQuestions((prevViewed) => ({ ...prevViewed, [key]: true }));

    // Handle the answer and scoring logic
    if (currentResponse !== undefined) {
      const correctAnswer = currentQuestion.answer;
      const userAnswer = currentResponse;
      const marksForQuestion = parseFloat(currentQuestion.marks);
      let negativeMarks = 0;

      // Determine negative marking based on question type
      if (
        currentQuestion.qtype === "MCQ4" ||
        currentQuestion.qtype === "MSQN" ||
        currentQuestion.qtype === "NATD"
      ) {
        if (marksForQuestion === 1) {
          negativeMarks = 1 / 3;
        } else if (marksForQuestion === 2) {
          negativeMarks = 2 / 3;
        }
      }

      // Calculate scores
      if (userAnswer && correctAnswer === userAnswer) {
        setCorrectAnswersCount((prevCount) => prevCount + 1);
        setTotalMarks((prevTotalMarks) =>
          parseFloat((prevTotalMarks + marksForQuestion).toFixed(2))
        );
      } else if (userAnswer && correctAnswer !== userAnswer) {
        setWrongAnswersCount((prevCount) => prevCount + 1);
        setTotalMarks((prevTotalMarks) =>
          parseFloat((prevTotalMarks - negativeMarks).toFixed(2))
        );
      }

      // Reset "marked for review" if applicable
      if (markedForReview[key]) {
        setMarkedForReview({ ...markedForReview, [key]: false });
      }
    } else {
      // Handle case where no response was provided (e.g., the user cleared their answer)
      setNotAnsweredCount((prevNotAnsweredCount) => prevNotAnsweredCount + 1);
    }

    // Move to the next question
    if (currentQuestionIndex < currentSection.questions.length - 1) {
      moveToQuestion(currentSectionIndex, currentQuestionIndex + 1);
    } else {
      const currentSubjectIndex = questionsData.findIndex(
        (subject) => subject.SubjectName === selectedSubject
      );
      if (currentSubjectIndex < questionsData.length - 1) {
        const nextSubject = questionsData[currentSubjectIndex + 1];
        setSelectedSubject(nextSubject.SubjectName);
        setCurrentSectionIndex(0);
        setCurrentQuestionIndex(0);
      }
    }
  };

  // const goToQuestion = (index) => {
  //   const key = getQuestionKey(
  //     selectedSubject,
  //     currentSectionIndex,
  //     currentSection.questions[index].question_id
  //   );
  //   setViewedQuestions({ ...viewedQuestions, [key]: true });
  //   setCurrentQuestionIndex(index);
  // };

  const handleSubjectChange = (subjectName) => {
    setSelectedSubject(subjectName);
    setCurrentSectionIndex(0);
    setCurrentQuestionIndex(0);
  };

  const handleSectionChange = (index) => {
    setCurrentSectionIndex(index);
    setCurrentQuestionIndex(0);
  };

  const markForReview = () => {
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );
    setMarkedForReview({ ...markedForReview, [key]: true });
    setViewedQuestions({ ...viewedQuestions, [key]: true });
    nextQuestion();
  };
  const handleSubmit = () => {
    setIsAutoSubmit(false);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    window.location.href = "/VITEEE_OTS_PAGE";
    window.close();
  };

  const currentSubject = questionsData.find(
    (subject) => subject.SubjectName === selectedSubject
  );
  const currentSection = currentSubject?.sections[currentSectionIndex];
  const currentQuestion = currentSection?.questions[currentQuestionIndex];

  useEffect(() => {
    // Set the viewed status of the first question of the selected subject to true
    const firstQuestionKey = getQuestionKey(
      selectedSubject,
      0,
      currentSubject.sections[0].questions[0].question_id
    );
    setViewedQuestions({ ...viewedQuestions, [firstQuestionKey]: true });
  }, [selectedSubject]);

  const handleButtonClick = () => {
    setShowQuestions(!showQuestions);
  };

  const handleCheckboxChange = (optionIndex) => {
    const questionKey = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    // Get the current responses for the question
    const currentResponses = responses[questionKey] || [];

    // Toggle the option in the response
    const updatedResponses = currentResponses.includes(optionIndex)
      ? currentResponses.filter((index) => index !== optionIndex)
      : [...currentResponses, optionIndex];

    // Update the state with the new responses
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionKey]: updatedResponses,
    }));
  };

  const handleNatdInputChange = (value) => {
    const questionKey = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    // Update the state with the new value
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionKey]: value,
    }));
  };
  const handleNatdNumberClick = (num) => {
    const questionKey = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    // Get the current value
    const currentValue = responses[questionKey] || "";

    // Append the number or dot to the current value
    const newValue = currentValue + num;

    // Update the state with the new value
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionKey]: newValue,
    }));
  };

  const handleNatdBackspace = () => {
    const questionKey = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    // Get the current value
    const currentValue = responses[questionKey] || "";

    // Remove the last character
    const newValue = currentValue.slice(0, -1);

    // Update the state with the new value
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionKey]: newValue,
    }));
  };
  // Handle arrow button clicks (left and right)
  const handleNatdArrowClick = (direction) => {
    const inputElement = document.getElementById("natdInputField");
  
    if (!inputElement) return;
  
    const cursorPosition = inputElement.selectionStart;
  
    if (direction === "left" && cursorPosition > 0) {
      inputElement.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
    } else if (direction === "right" && cursorPosition < inputElement.value.length) {
      inputElement.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
    }
  
    inputElement.focus();
  };

  const handleClearAll = () => {
    // Implement the logic to clear all input values
    // For example, you might want to clear a state variable:
    handleNatdInputChange(""); // Assuming you have a state variable for the input
  };

  const handleMouseEnter = (subjectName) => {
    setHoveredSubject(subjectName);
  };

  const handleMouseLeave = () => {
    setHoveredSubject(null);
  };

  return (
    <div>
      <div className="Pg_OtsLogo">
        <img
          src="https://www.egradtutor.in/static/media/egate%20logo%201.8b1eb31347d60945925f.png"
          alt=""
        />
      </div>
      <div className="Pg_otsheadin1">
        <p className="Pg_TestName">GATE Mock Test-1</p>
        <div>
          <button onClick={handleButtonClick} className="ViewQuestions">
            <i class="fa-solid fa-align-justify Pg_justify"></i>
            {showQuestions ? "Hide Questions" : "View Questions"}
          </button>
          <p>
            <i class="fa-solid fa-info pg_info"></i>View Instructions
          </p>
        </div>
      </div>
      <div className="pg_otsMaindiv">
        <div style={{ width: "95%" }}>
          <div className="containerpg">
            {selectedSubject && (
              <div>
                {questionsData
                  .filter((subject) => subject.SubjectName === selectedSubject)
                  .map((subject) => (
                    <div key={subject.SubjectName} className="message-body">
                      <div className="arrowpg"></div>
                      <h2 className="subject-title1 selected">
                        {subject.SubjectName}
                      </h2>
                      <div className="i_symbol_mock_exam">
                        <i class="fa-solid fa-info pg_info"></i>
                        <div className="Mock_test_details_structure">
                          <div className="pg_colorboxots_mock">
                            <div className="mock_test_count_details">
                              <img src={greenBox} alt="Not Answered" />
                              <span className="NotAnsweredlegend pg_countspan">
                              {counts.totalAnsweredCount}
                              </span>
                            </div>
                            <label className="mock_paras" htmlFor="">
                              Answered
                            </label>
                          </div>

                          <div className="pg_colorboxots_mock">
                            <div className="mock_test_count_details">
                              <img src={orangeBox} alt="Not Answered" />
                              <span className="NotAnsweredlegend pg_countspan">
                              {counts.totalNotAnsweredCount}
                              </span>
                            </div>
                            <p className="mock_paras">Not Answered</p>
                          </div>

                          <div className="pg_colorboxots_mock">
                            <div className="mock_test_count_details">
                              <img src={grayBox} alt="Not Visited" />
                              <span className="NotVisited pg_countspan">{counts.totalNotVisitedCount}</span>
                            </div>
                            <p className="mock_paras">Not Visited</p>
                          </div>

                          <div className="pg_colorboxots_mock">
                            <div className="mock_test_count_details">
                              <img src={purpleBox} />
                              <span className="marked  pg_countspan">{counts.totalMarkedForReviewCount}</span>
                            </div>
                            <p className="mock_paras">Marked for Review</p>
                          </div>

                          <div className="pg_colorboxots_mock">
                            <div className="mock_test_count_details">
                              <img src={purpleTickBox} />
                              <span className="marked  pg_countspan">{counts.totalAnsweredAndMarkedForReview}</span>
                            </div>
                            <p className="mock_paras">
                              {" "}
                              Answered & Marked for Review(will also be
                              evaluted)
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            <div className="handleIconClick">
              <div onClick={handleIconClick} className="handleIconClick1">
                <i className="fa-solid fa-calculator"></i>
              </div>
              {showCalculator && (
                <ScientificCalculator onClose={handleIconClick} />
              )}
            </div>
          </div>
          <div className="pg_Sectionsdiv">
            <p>Sections</p>
            <p>
              Time remaining:{" "}
              {`${Math.floor(remainingTime / 3600)}:${Math.floor(
                (remainingTime % 3600) / 60
              )}:${remainingTime % 60}`}
            </p>
          </div>
          <div>
            <div className="Pg_sectiondiv">
              {questionsData.map((subject) => (
                <button
                  key={subject.SubjectName}
                  onClick={() => handleSubjectChange(subject.SubjectName)}
                  className={`Pg_section ${
                    selectedSubject === subject.SubjectName ? "active" : ""
                  }`}
                  onMouseEnter={() => handleMouseEnter(subject.SubjectName)}
                  onMouseLeave={handleMouseLeave}
                >
                  {subject.SubjectName}
                  <div className="i_symbol_mock_exam">
                    <i class="fa-solid fa-info pg_info"></i>
                    {hoveredSubject &&
                      Object.keys(counts.subjects)
                        .filter((subjects) => subjects === hoveredSubject) // Filter to show counts for the hovered subject
                        .map((subjectName) => (
                          <div
                            className="Mock_test_details_structure"
                            key={subjectName}
                          >
                                <div>                                
                                  <div className="pg_colorboxots_mock">
                                    <div className="mock_test_count_details">
                                      <img src={greenBox} alt="Not Answered" />
                                      <span className="NotAnsweredlegend pg_countspan">
                                        {counts.subjects[subjectName].answeredCount}
                                      </span>
                                    </div>
                                    <label className="mock_paras" htmlFor="">
                                      Answered
                                    </label>
                                  </div>

                                  <div className="pg_colorboxots_mock">
                                    <div className="mock_test_count_details">
                                      <img src={orangeBox} alt="Not Answered" />
                                      <span className="NotAnsweredlegend pg_countspan">
                                        {counts.subjects[subjectName].notAnsweredCount}
                                      </span>
                                    </div>
                                    <p className="mock_paras">Not Answered</p>
                                  </div>

                                  <div className="pg_colorboxots_mock">
                                    <div className="mock_test_count_details">
                                      <img src={grayBox} alt="Not Visited" />
                                      <span className="NotVisited pg_countspan">
                                        {counts.subjects[subjectName].notVisitedCount}
                                      </span>
                                    </div>
                                    <p className="mock_paras">Not Visited</p>
                                  </div>

                                  <div className="pg_colorboxots_mock">
                                    <div className="mock_test_count_details">
                                      <img src={purpleBox} />
                                      <span className="marked  pg_countspan">
                                        {counts.subjects[subjectName].markedForReviewCount}
                                      </span>
                                    </div>
                                    <p className="mock_paras">
                                      Marked for Review
                                    </p>
                                  </div>

                                  <div className="pg_colorboxots_mock">
                                    <div className="mock_test_count_details">
                                      <img src={purpleTickBox} />
                                      <span className="marked  pg_countspan">
                                        {counts.subjects[subjectName].answeredAndMarkedForReview}
                                      </span>
                                    </div>
                                    <p className="mock_paras">
                                      {" "}
                                      Answered & Marked for Review(will also be
                                      evaluted)
                                    </p>
                                  </div>
                                </div>
                          </div>
                        ))}
                  </div>
                </button>
              ))}
            </div>
          </div>
          <div className="pg_Questiontypediv">
            <p className="pg_Questiontypep">Question Type:MCQ</p>
            <div className="pg_markingdiv">
              <p>Marks for correct answer:1</p>
              <p>Nagative Marks:1/3</p>
            </div>
          </div>
          <div className="pg_quizpageots">
            {selectedSubject && currentQuestion && (
              <div>
                <div className="pg_Questionnodiv">
                  <p className="pg_Questionnop">
                    Question {currentQuestion.question_id}:
                  </p>
                </div>
                <div className="pg_quizpageots">
                  <div className="pg_quizotsQuestion">
                    <img
                      src={currentQuestion.questionImgName}
                      alt={`Question ${currentQuestion.question_id}`}
                    />
                  </div>

                  {/* Conditional rendering based on question type */}
                  <div className="pg_quizotsoptions">
                    {currentQuestion.qtype === "MCQ4" &&
                      currentQuestion.options.map((option) => (
                        <label key={option.option_id} className="option">
                          <input
                            type="radio"
                            id={`option-${option.option_id}`}
                            name={`question-${currentQuestion.question_id}`}
                            checked={
                              responses[
                                getQuestionKey(
                                  selectedSubject,
                                  currentSectionIndex,
                                  currentQuestion.question_id
                                )
                              ] === option.option_index
                            }
                            onChange={() =>
                              handleOptionChange(option.option_index)
                            }
                            aria-labelledby={`option-label-${option.option_id}`}
                          />
                          <span id={`option-label-${option.option_id}`}>
                            {option.option_index}
                          </span>
                          <img
                            src={option.optionImgName}
                            alt={`Option ${option.option_index}`}
                          />
                        </label>
                      ))}

                    {currentQuestion.qtype === "MSQN" &&
                      currentQuestion.options.map((option) => (
                        <label key={option.option_id} className="option">
                          <input
                            type="checkbox"
                            id={`option-${option.option_id}`}
                            name={`question-${currentQuestion.question_id}`}
                            checked={responses[
                              getQuestionKey(
                                selectedSubject,
                                currentSectionIndex,
                                currentQuestion.question_id
                              )
                            ]?.includes(option.option_index)}
                            onChange={() =>
                              handleCheckboxChange(option.option_index)
                            }
                            aria-labelledby={`option-label-${option.option_id}`}
                          />
                          <span id={`option-label-${option.option_id}`}>
                            {option.option_index}
                          </span>
                          <img
                            src={option.optionImgName}
                            alt={`Option ${option.option_index}`}
                          />
                        </label>
                      ))}

                    {currentQuestion.qtype === "NATD" && (
                      <div className="natd-input-container ">
                        <input
                          type="text"
                          value={
                            responses[
                              getQuestionKey(
                                selectedSubject,
                                currentSectionIndex,
                                currentQuestion.question_id
                              )
                            ] || ""
                          }
                          onChange={(e) =>
                            handleNatdInputChange(e.target.value)
                          }
                          className="natd-input"
                          placeholder="Enter your answer"
                          readOnly
                        />
                        <div className="number-pad">
                          <div className="onebuttonrow">
                            {" "}
                            <button onClick={handleNatdBackspace}>
                              Backspace
                            </button>
                          </div>
                          <div className="number-row">
                            <button onClick={() => handleNatdNumberClick("7")}>
                              7
                            </button>
                            <button onClick={() => handleNatdNumberClick("8")}>
                              8
                            </button>
                            <button onClick={() => handleNatdNumberClick("9")}>
                              9
                            </button>
                          </div>
                          <div className="number-row">
                            <button onClick={() => handleNatdNumberClick("4")}>
                              4
                            </button>
                            <button onClick={() => handleNatdNumberClick("5")}>
                              5
                            </button>
                            <button onClick={() => handleNatdNumberClick("6")}>
                              6
                            </button>
                          </div>

                          <div className="number-row">
                            <button onClick={() => handleNatdNumberClick("1")}>
                              1
                            </button>
                            <button onClick={() => handleNatdNumberClick("2")}>
                              2
                            </button>
                            <button onClick={() => handleNatdNumberClick("3")}>
                              3
                            </button>
                          </div>

                          <div className="number-row">
                            <button onClick={() => handleNatdNumberClick("0")}>
                              0
                            </button>
                            <button onClick={() => handleNatdNumberClick(".")}>
                              .
                            </button>
                            <button onClick={() => handleNatdNumberClick("-")}>
                              -
                            </button>
                          </div>
                          <div className="number-row1">
                            <button
                              onClick={() => handleNatdArrowClick("left")}
                            >
                              ←
                            </button>
                            <button
                              onClick={() => handleNatdArrowClick("right")}
                            >
                              →
                            </button>
                          </div>
                          <div className="onebuttonrow">
                            <button onClick={handleClearAll}>Clear All</button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="pg_handlebuttons">
            <div className="pg_handleReview">
              <button onClick={markForReview}>Mark For Review</button>
              <button onClick={clearResponse}>Clear Response</button>
            </div>
            <div className="pg_saveprevious">
              <div className="pg_handleReview">
                {" "}
                <button onClick={previousQuestion}>Previous</button>
              </div>
              <button className="pg_saveandnextots" onClick={saveAndNext}>
                Save &amp; Next
              </button>
            </div>
          </div>
        </div>
        <div className="pg_norightdiv">
          <div class="pg_StudentDetailsots">
            <img
              title="Guest"
              src="/static/media/guesticon.00ad06aaa8a7af8eb6c5.jpg"
              alt="guesticon"
              className="users_profile_imgots"
            />
            <p>Candidate Name: Guest</p>
          </div>

          <div className="pg_legenddiv">
            <div className="pg_colorboxes ">
              {Object.keys(counts.subjects)
                .filter((subjects) => subjects === selectedSubject)
                .map((subjectName) => (
                  <div className="pg_colorboxesdiv" key={subjectName}>
                   
                        <div>
                          <div
                            className="pg_colorboxotssubdiv"
                            style={{ display: "flex", gap: "14px" }}
                          >
                            <div className="pg_colorboxots">
                              <div>
                                <img src={greenBox} alt="Answered" />
                                <span className="Answered pg_countspan">
                                  {counts.subjects[subjectName].answeredCount}
                                </span>
                              </div>
                              <label htmlFor="">Answered</label>
                            </div>
                            <div className="pg_colorboxots">
                              <div style={{ marginBottom: "-1rem" }}>
                                <img src={orangeBox} alt="Not Answered" />
                                <span className="NotAnsweredlegend pg_countspan">
                                  {counts.subjects[subjectName].notAnsweredCount}
                                </span>
                              </div>
                              <label htmlFor="">Not Answered</label>
                            </div>
                          </div>
                          <div
                            className="pg_colorboxotssubdiv"
                            style={{ marginTop: "-1rem" }}
                          >
                            <div className="pg_colorboxots">
                              <div style={{ marginBottom: "-1rem" }}>
                                <img src={grayBox} alt="Not Visited" />
                                <span className="NotVisited pg_countspan">
                                  {counts.subjects[subjectName].notVisitedCount}
                                </span>
                              </div>
                              <label htmlFor="">Not Visited</label>
                            </div>
                            <div className="pg_colorboxots">
                              <div style={{ marginBottom: "-1rem" }}>
                                <img src={purpleBox} alt="Marked for Review" />
                                <span className="marked pg_countspan">
                                  {counts.subjects[subjectName].markedForReviewCount}
                                </span>
                              </div>
                              <p>Marked for Review</p>
                            </div>
                          </div>
                          <div
                            className="pg_colorboxots pg_colorboxes5"
                            style={{ marginTop: "-1rem" }}
                          >
                            <img
                              src={purpleTickBox}
                              alt="Answered & Marked for Review"
                            />
                            <span className="marked-answered pg_markedForReviewCount">
                              {counts.subjects[subjectName].answeredAndMarkedForReview}
                            </span>
                            Answered & Marked for Review (will also be
                            evaluated)
                          </div>
                        </div>
                  </div>
                ))}
            </div>
            <div style={{ width: "100%" }}>
              <div className="Pg_sectiondivno">
                {currentSubject.SubjectName}
              </div>
            </div>
            <div className="pg_divotsnumber">
              <p>Choose a Question</p>
              <div className="pgquestion-number-palette question-numberpalette pgquestion-palette pg_numberpaletdiv">
                {currentSection.questions.map((question, index) => {
                  const key = getQuestionKey(
                    selectedSubject,
                    currentSectionIndex,
                    question.question_id
                  );
                  return (
                    <button
                      key={question.question_id}
                      className={getQuestionClassName(index, key)}
                      onClick={() => goToQuestion(index)}
                    >
                      {index + 1}
                    </button>
                  );
                })}
              </div>
            </div>{" "}
          </div>
          <button
            class="Quiz_mockbtns Quiz_mockbtnssubmit"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="scorecardforexam">
        {showPopup && (
          <div className="popup">
            <div className="popup-content">
              {remainingTime === 0 ? (
                <div>
                  <p>Your Time is up!</p>
                  <p>Your test is automatically submitted successfully.</p>
                </div>
              ) : (
                <p>You have successfully submitted your test.</p>
              )}
              <p>
                <img
                  src="/static/media/prize.e48700d2bb773b1eda58.jpg"
                  alt="Congratulations"
                />
                <span>
                  <h2>Congratulations!</h2>
                  <span class="mockscore">Your score: {totalMarks}</span>
                </span>
              </p>

              <ul class="allscorecountingmock">
                <li>
                  <b>Answered:</b> {answeredCount}
                </li>
                <li>
                  <b>Not Answered:</b> {notAnsweredCount}
                </li>
                <li>
                  <b>Right Answered:</b>
                  {correctAnswersCount}
                </li>
                <li>
                  <b>Wrong Answered:</b> {wrongAnswersCount}
                </li>
              </ul>
              <button onClick={closePopup}>Close</button>
            </div>
          </div>
        )}
      </div>
      {showQuestions && (
        <div className="questionslistpopup">
          {questionsData.map((subject, subjectIndex) => (
            <div
              key={subject.SubjectName}
              className="questionslistpopup-content"
            >
              <h3>{subject.SubjectName}</h3>
              {subject.sections.map((section, sectionIndex) => (
                <div key={sectionIndex} className="Quetionpaperdiv">
                  <ul>
                    {section.questions.map((question, questionIndex) => (
                      <li
                        key={question.question_id}
                        onClick={() => goToQuestion(questionIndex)}
                      >
                        Question {question.question_id}:{" "}
                        <img src={question.questionImgName} alt="" />
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}{" "}
        </div>
      )}
    </div>
  );
}

export default GATE_MockTest_1;
