import React, { useState, useEffect, useRef } from "react";
import "../JEE(MAINS)/Jee_mains.css";
import { LuAlarmClock } from "react-icons/lu";
import { useParams } from "react-router-dom";
import BITSATquestionsData1 from "./BITSATMocktest_1.json";
import BITSATquestionsData2 from "./BITSATMocktest_2.json";
import BITSATquestionsData3 from "./BITSATMocktest_3.json";
import html2canvas from "html2canvas";
import { HiDownload } from "react-icons/hi";
import jsPDF from "jspdf";
import ExtraQuestions1 from "./BITSATExtraquetion_1.json";
import logo from "../JEE(MAINS)/logoegrad.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import html2pdf from "html2pdf.js";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);

const mockTestsData = {
  601: BITSATquestionsData1,
  602: BITSATquestionsData2,
  603: BITSATquestionsData3,
};
const extramockTestsData = {
  601: ExtraQuestions1,
  // 602: ExtraQuestions2,
  // 603: ExtraQuestions3,
};
function BITSATMocktest_1() {
  const { mockTestId } = useParams();
  const questionsData = mockTestsData[mockTestId];
  const extraquestionsData = extramockTestsData[mockTestId];
  const [showExtraQuestions, setShowExtraQuestions] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(
    questionsData.length > 0 ? questionsData[0].SubjectName : null
  );
  const [extraSelectedSubject, setExtraSelectedSubject] = useState(
    extraquestionsData.length > 0 ? extraquestionsData[0].SubjectName : null
  );
  const [activeButton, setActiveButton] = useState("performance");
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responses, setResponses] = useState({});
  const [viewedQuestions, setViewedQuestions] = useState({});
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [markedForReview, setMarkedForReview] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [remainingTime, setRemainingTime] = useState(3 * 60 * 60);
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const duration = 180;
  const getQuestionKey = (subject, sectionIndex, questionId) => {
    return `${subject}-${sectionIndex}-${questionId}`;
  };
  const [showSolution, setShowSolution] = useState(false);
  const [currentSolution, setCurrentSolution] = useState(null);
  const [totalMarks, setTotalMarks] = useState(0);
  const [answeredCount, setAnsweredCount] = useState(0);
  const [notAnsweredCount, setNotAnsweredCount] = useState(0);
  const [extranotAnsweredCount, setExtraNotAnsweredCount] = useState(0);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showPopupscore, setShowPopupscore] = useState(false);
  const [correctAnswersExtraCount, setCorrectAnswersExtraCount] = useState(0);
  const [wrongAnswersExtraCount, setWrongAnswersExtraCount] = useState(0);
  const [totalExtraMarks, setTotalExtraMarks] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [extraPercentage, setExtraPercentage] = useState(0);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showEvaluvationPopup, setShowEvaluvationPopup] = useState(false);
  const toggleMenu = () => {
    if (window.innerWidth <= 600) {
      setIsMenuVisible((prevState) => !prevState);
      setIsMenuOpen((prevState) => !prevState);
    }
  };
  useEffect(() => {
    let count = 0;
    extraquestionsData.forEach((subject) => {
      subject.sections.forEach((section) => {
        count += section.questions.length;
      });
    });
    setExtraNotAnsweredCount(count);
  }, []);

  useEffect(() => {
    let count = 0;
    questionsData.forEach((subject) => {
      subject.sections.forEach((section) => {
        count += section.questions.length;
      });
    });
    setNotAnsweredCount(count);
  }, []);

  const handleOptionChange = (option) => {
    if (currentQuestion && selectedSubject) {
      const key = getQuestionKey(
        selectedSubject,
        currentSectionIndex,
        currentQuestion.question_id
      );

      setResponses({ ...responses, [key]: option });
    }
  };

  const [showPerformance, setShowPerformance] = useState(true);
  const handleShowPerformance = () => {
    setShowPerformance(true);
    setActiveButton("performance");
  };

  const handleShowSolutions = () => {
    setShowPerformance(false);
    setActiveButton("solutions");
  };

  const [isDownloading, setIsDownloading] = useState(false);
  const downloadPDF = () => {
    setIsDownloading(true); // Disable button and hide icon

    const element = document.querySelector(".popup-contentscore");
    const options = {
      margin: 1,
      filename: "Test-report.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // Hide download button before generating PDF
    const downloadButton = document.querySelector(
      ".MockTest_result_Report_download_btn"
    );
    if (downloadButton) {
      downloadButton.style.display = "none";
    }

    html2pdf()
      .from(element)
      .set(options)
      .save()
      .then(() => {
        setIsDownloading(false); // Re-enable button
        if (downloadButton) {
          downloadButton.style.display = "block"; // Show button again
        }
      })
      .catch(() => {
        setIsDownloading(false); // Re-enable button if error occurs
        if (downloadButton) {
          downloadButton.style.display = "block"; // Show button again
        }
      });
  };

  const handleSubjectChangemenu = (event) => {
    setSelectedSubject(event.target.value);
  };
  const handleExtraSubjectChangemenu = (event) => {
    setExtraSelectedSubject(event.target.value);
  };
  const selectedSubjectData = questionsData.find(
    (subject) => subject.SubjectName === selectedSubject
  );
  const extraSelectedSubjectData = extraquestionsData.find(
    (subject) => subject.SubjectName === selectedSubject
  );
  const handleShowSolution = (solution) => {
    // Check if the current solution is the one being clicked
    if (currentSolution === solution) {
      // Toggle the visibility
      setShowSolution(!showSolution);
    } else {
      // Show the new solution
      setCurrentSolution(solution);
      setShowSolution(true);
    }
  };

  const clearResponse = () => {
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );
    setResponses({ ...responses, [key]: null });
    setViewedQuestions({ ...viewedQuestions, [key]: true });
    setAnsweredQuestions({ ...answeredQuestions, [key]: false });
    // setAnsweredCount((prevAnsweredCount) => prevAnsweredCount - 1);
    if (answeredCount > 0) {
      setAnsweredCount((prevAnsweredCount) => prevAnsweredCount - 1);
    }
  };

  const moveToQuestion = (newSectionIndex, newQuestionIndex) => {
    const key = getQuestionKey(
      selectedSubject,
      newSectionIndex,
      currentSubject.sections[newSectionIndex].questions[newQuestionIndex]
        .question_id
    );
    setViewedQuestions({ ...viewedQuestions, [key]: true });
    setCurrentSectionIndex(newSectionIndex);
    setCurrentQuestionIndex(newQuestionIndex);
  };

  const nextQuestion = () => {
    const currentSection = currentSubject.sections[currentSectionIndex];
    if (currentQuestionIndex < currentSection.questions.length - 1) {
      moveToQuestion(currentSectionIndex, currentQuestionIndex + 1);
    } else {
      const currentSubjectIndex = questionsData.findIndex(
        (subject) => subject.SubjectName === selectedSubject
      );
      if (currentSubjectIndex < questionsData.length - 1) {
        const nextSubject = questionsData[currentSubjectIndex + 1];
        setSelectedSubject(nextSubject.SubjectName);
        setCurrentSectionIndex(0);
        setCurrentQuestionIndex(0);
      }
    }
  };

  const previousQuestion = () => {
    if (currentQuestionIndex > 0) {
      moveToQuestion(currentSectionIndex, currentQuestionIndex - 1);
    } else {
      const currentSubjectIndex = questionsData.findIndex(
        (subject) => subject.SubjectName === selectedSubject
      );
      if (currentSubjectIndex > 0) {
        const prevSubject = questionsData[currentSubjectIndex - 1];
        setSelectedSubject(prevSubject.SubjectName);
        const lastSectionIndex = prevSubject.sections.length - 1;
        setCurrentSectionIndex(lastSectionIndex);
        setCurrentQuestionIndex(
          prevSubject.sections[lastSectionIndex].questions.length - 1
        );
      }
    }
  };

  const [sectionBAnsweredCount, setSectionBAnsweredCount] = useState(0);
  const [awardedMarks, setAwardedMarks] = useState({});
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [rightAnsweredCount, setRightAnsweredCount] = useState(0);
  const [wrongAnsweredCount, setWrongAnsweredCount] = useState(0);
  const [inputValues, setInputValues] = useState({});

  const saveAndNext = () => {
    // stopTimer(); // Stop the current timer
    // setIsTimerRunningquestion(false); // Pause timer to prevent immediate start

    if (activeQuestion < questionsData.length - 1) {
      setActiveQuestion((prev) => prev + 1); // Move to next question
      // setIsTimerRunningquestion(true); // Start timer for the new question
    }

    // Retrieve current question details
    const currentSection = currentSubject.sections[currentSectionIndex];
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    let inputValue = getInputValue(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    // Ensure inputValue is a string
    inputValue =
      typeof inputValue === "string" ? inputValue.trim().toLowerCase() : "";

    // Log key and input value for debugging
    console.log(`Key for question ${currentQuestion.question_id}:`, key);
    console.log(
      `Retrieved input value for question ${currentQuestion.question_id}:`,
      inputValue
    );

    // Determine if the question was previously answered
    const wasPreviouslyAnswered = awardedMarks[key] !== undefined;
    const wasPreviouslyCorrect = awardedMarks[key] && awardedMarks[key] > 0;
    const previousMarks = awardedMarks[key] || 0;

    // Check if a response exists for the current question
    if (responses[key] !== undefined || inputValue !== "") {
      // Set the question as answered
      setAnsweredQuestions({ ...answeredQuestions, [key]: true });

      // Extract correct answer and marks from JSON data
      const correctAnswer = currentQuestion.answer?.trim().toLowerCase();
      const marks = currentQuestion.marks;

      console.log("Correct Answer:", correctAnswer);
      console.log("User Response for options selected:", responses[key]);
      console.log("User Response entered value:", inputValue);

      if (marks) {
        const marksArray = marks.split(",");
        const correctMark = parseInt(marksArray[0], 10);
        const wrongMark = -1;

        const userResponse =
          responses[key] !== undefined
            ? responses[key].toString().trim().toLowerCase()
            : "";

        console.log("User Response:", userResponse);

        let isCorrectResponse = false;
        let newMarksAwarded = 0;

        if (
          ["MCQ4", "MCQ5", "MSQ", "MSQN", "NATI"].includes(
            currentQuestion.qtype
          )
        ) {
          isCorrectResponse =
            userResponse === correctAnswer ||
            (inputValue !== "" && inputValue === correctAnswer);
          newMarksAwarded = isCorrectResponse ? correctMark : wrongMark;
        } else if (currentQuestion.qtype === "NATD") {
          const [lowerRange, upperRange] = correctAnswer
            .split("-")
            .map((range) => parseFloat(range));
          console.log("Lower Range:", lowerRange);
          console.log("Upper Range:", upperRange);

          const isWithinRange =
            inputValue !== "" &&
            correctAnswer.includes("-") &&
            inputValue >= lowerRange &&
            inputValue <= upperRange;
          console.log("Is Within Range:", isWithinRange);

          newMarksAwarded = isWithinRange ? correctMark : wrongMark;
        }

        console.log("New Marks Awarded:", newMarksAwarded);

        // Update awarded marks for this question
        awardedMarks[key] = newMarksAwarded;

        // Recalculate total marks from scratch, considering only the best 5 marks in Section B
        const newTotalMarks = calculateCorrectTotalMarks();
        console.log("Updated Total Marks:", newTotalMarks);
        setTotalMarks(Math.max(newTotalMarks, 0));
        // Update counts based on correctness of the response
        if (isCorrectResponse) {
          if (!wasPreviouslyCorrect) {
            setRightAnsweredCount((prevCount) => prevCount + 1);
            if (previousMarks < 0)
              setWrongAnsweredCount((prevCount) => prevCount - 1);
          }
        } else {
          if (wasPreviouslyCorrect) {
            setRightAnsweredCount((prevCount) => prevCount - 1);
          }
          setWrongAnsweredCount((prevCount) => prevCount + 1);
        }

        console.log("Updated awardedMarks:", awardedMarks);
      }

      // Update counts for answered questions
      if (!wasPreviouslyAnswered) {
        setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
        setNotAnsweredCount((prevCount) => prevCount - 1);
      }

      // Increment the count for section-B questions answered
      if (currentSectionIndex === 1) {
        setSectionBAnsweredCount((prevCount) => prevCount + 1);
      }

      // Move to the next question if available, else move to the next section or subject
      if (currentQuestionIndex < currentSection.questions.length - 1) {
        nextQuestion();
      } else {
        const mockTestsArray = Object.values(mockTestsData);
        const currentSubjectIndex = mockTestsArray.findIndex(
          (subject) => subject.SubjectName === selectedSubject
        );
        const nextSectionIndex = currentSectionIndex + 1;

        // Check if there is a next section in the current subject
        if (nextSectionIndex < currentSubject.sections.length) {
          // Move to the next section
          setCurrentSectionIndex(nextSectionIndex);
          setCurrentQuestionIndex(0); // Start from the first question in the next section
        } else {
          if (currentSubjectIndex < mockTestsData.length - 1) {
            const nextSubject = mockTestsData[currentSubjectIndex + 1];
            setSelectedSubject(nextSubject.SubjectName);
            setCurrentSectionIndex(0); // Start from the first section of the next subject
            setCurrentQuestionIndex(0); // Start from the first question in the first section
          }
        }
      }
    } else {
      // If the response is undefined, prompt the user to answer the question before proceeding
      alert("Please answer the current question before proceeding.");
    }
  };

  const calculateCorrectTotalMarks = () => {
    let totalMarks = 0;

    console.log("Awarded Marks:", awardedMarks); // Debugging line to check contents

    // Calculate total marks for sections other than Section B
    for (const key in awardedMarks) {
      if (!key.includes("-1-")) {
        const mark = awardedMarks[key];
        if (typeof mark === "number" && !isNaN(mark)) {
          totalMarks += mark;
        } else {
          console.warn(`Invalid mark found for key ${key}:`, mark);
        }
      }
    }

    console.log("Total Marks after sections other than B:", totalMarks); // Debugging

    // Calculate total marks for Section B
    const sectionBMarks = [];
    for (const key in awardedMarks) {
      if (key.includes("-1-")) {
        const mark = awardedMarks[key];
        if (typeof mark === "number" && !isNaN(mark)) {
          sectionBMarks.push(mark);
        } else {
          console.warn(`Invalid mark found for key ${key}:`, mark);
        }
      }
    }

    console.log("Section B Marks:", sectionBMarks); // Debugging

    // Sort Section B marks in descending order and take the top 4
    sectionBMarks.sort((a, b) => b - a);
    totalMarks += sectionBMarks
      .slice(0, 4)
      .reduce((acc, mark) => acc + mark, 0);

    console.log("Total Marks after including Section B:", totalMarks); // Debugging

    return totalMarks;
  };

  const getInputValue = (selectedSubject, currentSectionIndex, questionId) => {
    // Get the key using the provided parameters
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      questionId
    );
    // console.log("Getting input value for key:", key); // <-- Move this line here

    // Retrieve the input value for the specified key from the inputValues state
    return inputValues[key] || "";
  };

  const goToQuestion = (index) => {
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentSection.questions[index].question_id
    );
    setViewedQuestions({ ...viewedQuestions, [key]: true });
    setCurrentQuestionIndex(index);
  };

  const handleSubjectChange = (subjectName) => {
    setSelectedSubject(subjectName);
    setCurrentSectionIndex(0);
    setCurrentQuestionIndex(0);
  };

  const handleSectionChange = (index) => {
    setCurrentSectionIndex(index);

    setCurrentQuestionIndex(0);
  };

  const calculateCounts = () => {
    let answeredCount = 0;
    let markedForReviewCount = 0;
    let notVisitedCount = 0;

    questionsData.forEach((subject) => {
      subject.sections.forEach((section, sectionIndex) => {
        section.questions.forEach((question) => {
          const key = getQuestionKey(
            subject.SubjectName,
            sectionIndex,
            question.question_id
          );
          if (responses[key] !== undefined) {
            if (responses[key] !== null) {
              answeredCount++;
            } else {
              if (!viewedQuestions[key] && !markedForReview[key]) {
                notVisitedCount++; // If not answered, not marked for review, and not visited yet
              }
            }
          } else {
            if (!viewedQuestions[key] && !markedForReview[key]) {
              notVisitedCount++; // If not answered, not marked for review, and not visited yet
            }
          }
          if (markedForReview[key]) {
            markedForReviewCount++;
          }
        });
      });
    });

    // console.log("BITSAT QUETIONS COUNT ", totalQuestions);
    const notAnsweredCount =
      totalQuestions - answeredCount - markedForReviewCount - notVisitedCount;
    console.log("mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm");
    console.log(
      "notAnsweredCount  totalQuestions - answeredCount - markedForReviewCount - notVisitedCount",
      notAnsweredCount,
      totalQuestions,
      answeredCount,
      markedForReviewCount,
      notVisitedCount
    );
    return {
      answeredCount,
      markedForReviewCount,
      notVisitedCount,
      notAnsweredCount,
    };
  };

  // Calculate counts and then log totalQuestions outside of the function
  // const counts = calculateCounts();

  const totalQuestions = questionsData.reduce(
    (acc, subject) =>
      acc +
      subject.sections.reduce((acc, sec) => acc + sec.questions.length, 0),
    0
  );

  console.log("BITSAT QUESTIONS COUNTSSSS ", totalQuestions); // This is now outside of
  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (remainingTime === 0) {
      setIsAutoSubmit(true);
      setShowPopup(true);
    }
  }, [remainingTime]);
  const handleSubmit = () => {
    const formattedTime = formatTime(spentHours, spentMinutes, spentSeconds);
    setFormattedTimeSpent(formattedTime);
    setIsAutoSubmit(false);
    const counts = calculateCounts();
    const totalQuestionsCount = questionsData.reduce(
      (acc, subject) =>
        acc +
        subject.sections.reduce(
          (acc, section) => acc + section.questions.length,
          0
        ),
      0
    );

    if (counts.answeredCount === totalQuestionsCount) {
      setShowConfirmationPopup(true);
    } else {
      setShowPopup(true); // Directly show score if not all questions answered
    }
  };

  const [countDown, setCountDown] = useState(duration * 60);
  const [timeSpent, setTimeSpent] = useState(0);
  const timerId = useRef(null);

  // Countdown logic
  useEffect(() => {
    if (countDown > 0) {
      timerId.current = setInterval(() => {
        setCountDown((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timerId.current);
    }
  }, [countDown]);
  // Calculate the time spent based on the initial duration and current countdown
  useEffect(() => {
    const initialDurationInSeconds = duration * 60;
    setTimeSpent(initialDurationInSeconds - countDown);
  }, [countDown, duration]);

  // Convert seconds into hours, minutes, and seconds
  const hours = Math.floor(countDown / 3600);
  const minutes = Math.floor((countDown % 3600) / 60);
  const seconds = countDown % 60;

  // Calculate time spent in hours, minutes, and seconds
  const spentHours = Math.floor(timeSpent / 3600);
  const spentMinutes = Math.floor((timeSpent % 3600) / 60);
  const spentSeconds = timeSpent % 60;

  // State variable to store the formatted time spent
  const [formattedTimeSpent, setFormattedTimeSpent] = useState("");

  // Function to format time as HH:MM:SS
  const formatTime = (hours, minutes, seconds) => {
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "00")}:${seconds.toString().padStart(2, "0")}`;
  };

  // Convert total duration to seconds
  const totalDurationInSeconds = duration * 60;
  console.log("HELLOOOOOOOOOOO");
console.log("formattedTimeSpent",formattedTimeSpent);
  // Convert formatted time spent to seconds for remaining time calculation
  const formattedTimeParts = formattedTimeSpent.split(":").map(Number);
  const formattedTimeInSeconds =
    formattedTimeParts[0] * 3600 +
    formattedTimeParts[1] * 60 +
    formattedTimeParts[2];

  // Calculate remaining time display
  const remainingTimeInSeconds = duration * 60 - formattedTimeInSeconds;

  // Convert remaining time back to HH:MM:SS format
  const remainingHours = Math.floor(remainingTimeInSeconds / 3600);
  const remainingMinutes = Math.floor((remainingTimeInSeconds % 3600) / 60);
  const remainingSeconds = remainingTimeInSeconds % 60;

  const remainingTimeDisplay = `${remainingHours
    .toString()
    .padStart(2, "0")}:${remainingMinutes
    .toString()
    .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;

  console.log(
    "TimeSpent,remainingTimedisplay",
    formattedTimeSpent,
    remainingTimeDisplay
  );

  // Calculate percentage of time spent and remaining time
  const percentageSpent =
    (formattedTimeInSeconds / totalDurationInSeconds) * 100;
  const percentageRemaining =
    ((totalDurationInSeconds - formattedTimeInSeconds) /
      totalDurationInSeconds) *
    100;

  console.log("hiiiiiiiiiiiiiiiOVERALL CORRECT");
  console.log(percentageSpent, percentageRemaining);
  const overAllCorrectAnswersCount =
    rightAnsweredCount + correctAnswersExtraCount;
  const overAllWrongAnswersCount = wrongAnsweredCount + wrongAnswersExtraCount;

  console.log(overAllCorrectAnswersCount);
  console.log(overAllWrongAnswersCount);

  // Calculate how many more correct answers are needed to make the count equal to totalQuestions
  let adjustedCorrectAnswers = rightAnsweredCount;
  let remainingCorrectNeeded = totalQuestions - rightAnsweredCount; // How many more correct answers you need

  // Use extra correct answers to replace wrong ones in main questions if needed
  if (remainingCorrectNeeded > 0) {
    // If you have enough extra correct answers to fill the gap, add only what's needed
    if (correctAnswersExtraCount >= remainingCorrectNeeded) {
      adjustedCorrectAnswers += remainingCorrectNeeded; // Take from extra correct answers
    } else {
      // If you don't have enough extra correct answers, add all of them
      adjustedCorrectAnswers += correctAnswersExtraCount;
    }
  }

  // Assign the final correct and wrong answers to variables
  const finalCorrectAnswersCount = adjustedCorrectAnswers;
  const finalWrongAnswersCount = totalQuestions - adjustedCorrectAnswers;

  // PAATERN-1 CORRECT ANSWERS COUNT AND WRONG ANSWERS COUNT ROUND FIGURE TO totalQuestions LENGTH
  const pieData = {
    labels: ["Correct Answers", "Wrong Answers"],
    datasets: [
      {
        label: "Score Distribution",
        data: [overAllCorrectAnswersCount, overAllWrongAnswersCount],
        backgroundColor: ["#4CAF50", "#F44336"], // Colors for correct and wrong answers
        borderColor: ["#4CAF50", "#F44336"],
        borderWidth: 1,
      },
    ],
  };

  const RightAnsweredMarks = overAllCorrectAnswersCount * 3;
  const wrongAnsweredMarks = overAllWrongAnswersCount * 1;
  // Pie Chart Options Configuration
  const pieOptions = {
    plugins: {
      datalabels: {
        color: "#fff", // Data label color
        formatter: (value, context) => {
          const label = context.chart.data.labels[context.dataIndex];
          return `${label}: ${value}`; // Display label and value
        },
      },
      legend: {
        position: "top",
        labels: {
          generateLabels: (chart) => {
            return chart.data.datasets[0].data.map((value, index) => ({
              text: `${chart.data.labels[index]}: ${value}`, // Label with data value
              fillStyle: chart.data.datasets[0].backgroundColor[index],
              hidden: !chart.data.datasets[0].data[index],
              index,
            }));
          },
        },
      },
    },
  };
const overAllTotalObtainedMarks = RightAnsweredMarks - wrongAnsweredMarks;

  const [totalPossibleMarks, setTotalPossibleMarks] = useState(0);
  let OverAllTotalMarks = totalMarks + totalExtraMarks;
  const calculateTotalPossibleMarks = (questionsData) => {
    return questionsData.reduce((total, subject) => {
      return (
        total +
        subject.sections.reduce((sum, section) => {
          return (
            sum +
            section.questions.reduce((questionSum, question) => {
              const marks = question.marks.split(",");
              return questionSum + parseInt(marks[0], 10); // Sum of all possible marks
            }, 0)
          );
        }, 0)
      );
    }, 0);
  };

  const calculateExtraTotalPossibleMarks = (extraquestionsData) => {
    return extraquestionsData.reduce((total, extraSubject) => {
      console.log("Extra Subject:", extraSubject);
      return (
        total +
        extraSubject.sections.reduce((sum, extraSection) => {
          console.log("Extra Section:", extraSection);
          return (
            sum +
            extraSection.questions.reduce((questionSum, question) => {
              console.log("Question:", question);
              const marks = question.marks.split(",");
              if (marks.length > 0) {
                const markValue = parseInt(marks[0], 10);
                console.log("Parsed Mark Value:", markValue);
                return questionSum + (isNaN(markValue) ? 0 : markValue);
              }
              return questionSum;
            }, 0)
          );
        }, 0)
      );
    }, 0);
  };

  const [toatlPassibleExtraMarks, setTotalExtraPossibleMarks] = useState(0);

  useEffect(() => {
    let possibleMarks = 0;

    if (questionsData && questionsData.length > 0) {
      // Calculate possible marks from questionsData with a fixed total of 360
      possibleMarks = calculateTotalPossibleMarks(questionsData);
      setTotalPossibleMarks(possibleMarks);
    }

    console.log("000000000000000000000000000000000000000");
    console.log("questionsData:", questionsData);
    console.log("possibleMarks:", possibleMarks);
  }, [questionsData]);

  useEffect(() => {
    let ExtrapossibleMarks = 0;

    if (extraquestionsData && extraquestionsData.length > 0) {
      // Calculate possible marks from extraquestionsData with a fixed total of 426
      ExtrapossibleMarks = calculateExtraTotalPossibleMarks(extraquestionsData);
      setTotalExtraPossibleMarks(ExtrapossibleMarks);
    }

    console.log("1111111111111111111111111111111111111111111111111");
    console.log("extraquestionsData:", extraquestionsData);
    console.log("ExtrapossibleMarks:", ExtrapossibleMarks);
  }, [extraquestionsData]);

  const overAllTotalPossibleMarks =
    totalPossibleMarks + toatlPassibleExtraMarks;
    const overAllExamTotalMarks = totalPossibleMarks + toatlPassibleExtraMarks;
  useEffect(() => {
    if (totalPossibleMarks > 0) {
      const calculatedPercentage =
        (OverAllTotalMarks / totalPossibleMarks) * 100;
      setPercentage(Math.round(calculatedPercentage * 10) / 10); // Round to 1 decimal place
    }
  }, [OverAllTotalMarks, totalPossibleMarks]);

  useEffect(() => {
    if (overAllTotalPossibleMarks > 0) {
      const calculatedPercentage =
        (OverAllTotalMarks / overAllTotalPossibleMarks) * 100;
      setExtraPercentage(Math.round(calculatedPercentage * 10) / 10); // Round to 1 decimal place
    }
  }, [OverAllTotalMarks, overAllTotalPossibleMarks]);

  console.log("5555555555555555555555555555");
  console.log(
    "totalPossibleMarks,toatlPassibleExtraMarks,overAllTotalPossibleMarks",
    totalPossibleMarks,
    toatlPassibleExtraMarks,
    overAllTotalPossibleMarks
  );

  const length = questionsData.length;
  console.log("Count of questions:", length);

  const extraquestionsDatalength = extraquestionsData.length;
  console.log("Count of extraquestionsDatalength:", extraquestionsDatalength);

  // Combine the lengths of questionsData and extraquestionsData
  const combinedLength = questionsData.length + extraquestionsData.length;
  console.log("Combined count of combinedLength questions:", combinedLength);

  const handleYes = () => {
    const formattedTime = formatTime(spentHours, spentMinutes, spentSeconds);
    setFormattedTimeSpent(formattedTime);
    setIsAutoSubmit(false);
    setShowPopup(false);
    setShowPopupscore(true);
    setShowEvaluvationPopup(false);
  };
  const handleShowEvaluvationPopup = () => {
    const formattedTime = formatTime(spentHours, spentMinutes, spentSeconds);
    setFormattedTimeSpent(formattedTime);
    setShowEvaluvationPopup(true);
    setShowPopup(false);
    setShowPopupscore(false);
    setIsTimerRunning(false);
  };
  const handlebuttonNo = () => {
    setShowPopup(false);
    setIsTimerRunning(true);
  };
  const [isExtraAttempted, setIsExtraAttempted] = useState(false);
  const handleConfirmExtraQuestions = () => {
    setIsExtraAttempted(true);
    setShowExtraQuestions(true);
    setShowConfirmationPopup(false);
  };

  const handleCancelExtraQuestions = () => {
    setIsExtraAttempted(false);
    // setShowPopup(true);
    setShowEvaluvationPopup(true);
    setShowConfirmationPopup(false);
  };
  const closePopup = () => {
    setShowPopup(false);
    window.location.href = "/bitsatots";
    window.close();
  };

  const currentSubject = questionsData.find(
    (subject) => subject.SubjectName === selectedSubject
  );

  const currentSection = currentSubject?.sections[currentSectionIndex];

  const currentQuestion = currentSection?.questions[currentQuestionIndex];

  const counts = calculateCounts();
  useEffect(() => {
    const firstQuestionKey = getQuestionKey(
      selectedSubject,
      0,
      currentSubject.sections[0].questions[0].question_id
    );
    setViewedQuestions({ ...viewedQuestions, [firstQuestionKey]: true });
  }, [selectedSubject]);
  // ------------------------------------------------------------------------------------

  const [selectedExtraSubject, setSelectedExtraSubject] = useState(
    extraquestionsData.length > 0 ? extraquestionsData[0].SubjectName : null
  );

  const [currentExtraSectionIndex, setCurrentExtraSectionIndex] = useState(0);

  const [currentExtraQuestionIndex, setCurrentExtraQuestionIndex] = useState(0);

  const [responsesExtra, setResponsesExtra] = useState({});

  const [viewedExtraQuestions, setViewedExtraQuestions] = useState({});

  const [answeredExtraQuestions, setAnsweredExtraQuestions] = useState({});

  const [markedForReviewExtra, setMarkedForReviewExtra] = useState({});

  const [showPopupExtra, setShowPopupExtra] = useState(false);

  const [remainingTimeExtra, setRemainingTimeExtra] = useState(3 * 60 * 60);
  const [isAutoSubmit, setIsAutoSubmit] = useState(false);
  const getExtraQuestionKey = (subject, sectionIndex, questionId) => {
    return `${subject}-${sectionIndex}-${questionId}`;
  };

  const handleExtraOptionChange = (optionIndex) => {
    setResponsesExtra({
      ...responsesExtra,

      [getExtraQuestionKey(
        selectedExtraSubject,

        currentExtraSectionIndex,

        extraCurrentSubject.sections[currentExtraSectionIndex].questions[
          currentExtraQuestionIndex
        ].question_id
      )]: optionIndex,
    });
  };

  const ExtraClearResponse = () => {
    const key = getExtraQuestionKey(
      selectedExtraSubject,
      currentExtraSectionIndex,
      extraCurrentSection.questions[currentExtraQuestionIndex].question_id
    );

    setResponsesExtra((prevResponses) => ({
      ...prevResponses,
      [key]: null,
    }));

    setViewedExtraQuestions((prevViewedQuestions) => ({
      ...prevViewedQuestions,
      [key]: true,
    }));

    setAnsweredExtraQuestions((prevAnsweredQuestions) => ({
      ...prevAnsweredQuestions,
      [key]: false,
    }));
  };

  const ExtraMoveToQuestion = (newSectionIndex, newQuestionIndex) => {
    const key = getExtraQuestionKey(
      selectedExtraSubject,
      newSectionIndex,
      extraCurrentSubject.sections[newSectionIndex].questions[newQuestionIndex]
        .question_id
    );
    setViewedExtraQuestions({ ...viewedExtraQuestions, [key]: true });
    setCurrentExtraSectionIndex(newSectionIndex);
    setCurrentExtraQuestionIndex(newQuestionIndex);
  };

  const ExtraNextQuestion = () => {
    const key = getExtraQuestionKey(
      selectedExtraSubject,
      currentExtraSectionIndex,
      extraCurrentSection.questions[currentExtraQuestionIndex].question_id
    );
    setViewedExtraQuestions((extraprevViewed) => ({
      ...extraprevViewed,
      [key]: true,
    }));

    const nextQuestionIndex = currentExtraQuestionIndex + 1;

    if (nextQuestionIndex < extraCurrentSection.questions.length) {
      setCurrentExtraQuestionIndex(nextQuestionIndex);
    } else {
      const nextSectionIndex = currentExtraSectionIndex + 1;
      if (nextSectionIndex < extraCurrentSubject.sections.length) {
        setCurrentExtraSectionIndex(nextSectionIndex);
        setCurrentExtraQuestionIndex(0);
      } else {
      }
    }
  };

  const ExtraPreviousQuestion = () => {
    if (currentExtraQuestionIndex > 0) {
      setCurrentExtraQuestionIndex(currentExtraQuestionIndex - 1);
    } else {
      const extraprevSectionIndex = currentExtraSectionIndex - 1;
      if (extraprevSectionIndex >= 0) {
        const extraprevSectionQuestions =
          extraCurrentSubject.sections[extraprevSectionIndex].questions;
        setCurrentExtraSectionIndex(extraprevSectionIndex);
        setCurrentExtraQuestionIndex(extraprevSectionQuestions.length - 1);
      } else {
      }
    }
  };

  //MAINNNNNNNNN
  const ExtraSaveAndNext = () => {
    const key = getExtraQuestionKey(
      selectedExtraSubject,
      currentExtraSectionIndex,
      extraCurrentSection.questions[currentExtraQuestionIndex].question_id
    );

    // Ensure that a response exists before marking the question as answered
    if (responsesExtra[key] === undefined) {
      alert("Please answer the current question before proceeding.");
      return;
    }

    // Mark the question as answered and save updated response
    if (!answeredExtraQuestions[key]) {
      setAnsweredExtraQuestions((extraprevAnswered) => ({
        ...extraprevAnswered,
        [key]: true, // Always mark the question as answered
      }));

      // Decrease the unanswered count since the question is now answered
      setExtraNotAnsweredCount((extraprevCount) => extraprevCount - 1);
    }

    // Get the current question and its correct answer
    const extraCurrentQuestion =
      extraCurrentSection.questions[currentExtraQuestionIndex];
    const correctAnswer = extraCurrentQuestion.answer;
    const [correctMarks, incorrectMarks] = extraCurrentQuestion.marks
      .split(",")
      .map(Number); // Assuming marks are in the format "5,-2"

    if (responsesExtra[key] !== null && responsesExtra[key] !== undefined) {
      // Check if there was a previous answer
      const wasPreviouslyAnswered = answeredExtraQuestions[key];
      const previousAnswerCorrect =
        wasPreviouslyAnswered && responsesExtra[key] === correctAnswer;

      // Determine if the new answer is correct
      const newAnswerCorrect = responsesExtra[key] === correctAnswer;

      if (newAnswerCorrect) {
        // If the new answer is correct
        if (!previousAnswerCorrect) {
          // If the previous answer was incorrect or unanswered, update counts and marks
          setCorrectAnswersExtraCount((prev) => prev + 1);
          setTotalExtraMarks((prev) => prev + correctMarks);

          if (wasPreviouslyAnswered) {
            // If there was a previous answer and it was wrong, revert the penalty
            setWrongAnswersExtraCount((prev) => prev - 1);
            setTotalExtraMarks((prev) => prev + incorrectMarks);
          }
        }
      } else {
        // If the new answer is incorrect
        if (previousAnswerCorrect) {
          // If the previous answer was correct, revert the correct answer marks
          setCorrectAnswersExtraCount((prev) => prev - 1);
          setTotalExtraMarks((prev) => prev - correctMarks);
        }

        // Apply penalty for the incorrect answer
        setWrongAnswersExtraCount((prev) => prev + 1);
        setTotalExtraMarks((prev) => prev - incorrectMarks);
      }

      // Update the record of answered questions
      setAnsweredExtraQuestions((prev) => ({ ...prev, [key]: true }));
    }

    // Move to the next question or section
    const nextQuestionIndex = currentExtraQuestionIndex + 1;

    if (nextQuestionIndex < extraCurrentSection.questions.length) {
      setCurrentExtraQuestionIndex(nextQuestionIndex);
    } else {
      const nextSectionIndex = currentExtraSectionIndex + 1;
      if (nextSectionIndex < extraCurrentSubject.sections.length) {
        setCurrentExtraSectionIndex(nextSectionIndex);
        setCurrentExtraQuestionIndex(0);
      } else {
        // Handle case when all sections and questions are done
        console.log("No more questions left in the extra subject.");
      }
    }
  };

  const getButtonClass = (index, key) => {
    return `
      ${index === currentExtraQuestionIndex ? "active" : ""}
      ${
        answeredExtraQuestions[key] && !markedForReviewExtra[key]
          ? "answered"
          : ""
      }
      ${viewedExtraQuestions[key] && !responsesExtra[key] ? "viewed" : ""}
      ${
        markedForReviewExtra[key]
          ? responsesExtra[key]
            ? "marked-answered"
            : "marked"
          : ""
      }
      ${
        responsesExtra[key] !== undefined && !answeredExtraQuestions[key]
          ? "answered-red active"
          : ""
      }
    `.trim();
  };

  const goToExtraQuestion = (index) => {
    const key = getExtraQuestionKey(
      selectedExtraSubject,
      currentExtraSectionIndex,
      extraCurrentSection.questions[index].question_id
    );
    setViewedExtraQuestions({ ...viewedExtraQuestions, [key]: true });
    setCurrentExtraQuestionIndex(index);
  };

  const handleExtraSubjectChange = (subjectName) => {
    setSelectedExtraSubject(subjectName);
    setCurrentExtraSectionIndex(0);
    setCurrentExtraQuestionIndex(0);
  };

  const handleExtraSectionChange = (index) => {
    setCurrentExtraSectionIndex(index);
    setCurrentExtraQuestionIndex(0);
  };

  const extraCalculateCounts = () => {
    let answeredCount = 0;
    let markedForReviewCount = 0;
    let notVisitedCount = 0;

    // Track all questions
    const allQuestions = [];

    // Collect all questions and their states
    extraquestionsData.forEach((subject) => {
      subject.sections.forEach((section, sectionIndex) => {
        section.questions.forEach((question) => {
          allQuestions.push({
            key: getExtraQuestionKey(
              subject.SubjectName,
              sectionIndex,
              question.question_id
            ),
            answered:
              responsesExtra[
                getExtraQuestionKey(
                  subject.SubjectName,
                  sectionIndex,
                  question.question_id
                )
              ] !== undefined,
            markedForReview:
              markedForReview[
                getExtraQuestionKey(
                  subject.SubjectName,
                  sectionIndex,
                  question.question_id
                )
              ] || false,
            viewed:
              viewedQuestions[
                getExtraQuestionKey(
                  subject.SubjectName,
                  sectionIndex,
                  question.question_id
                )
              ] || false,
          });
        });
      });
    });

    // Calculate counts based on the collected questions
    allQuestions.forEach(({ key, answered, markedForReview, viewed }) => {
      if (answered) {
        if (responsesExtra[key] !== null) {
          answeredCount++;
        }
      } else {
        if (!viewed && !markedForReview) {
          notVisitedCount++;
        }
      }
      if (markedForReview) {
        markedForReviewCount++;
      }
    });

    // Calculate the total number of questions
    const totalQuestions = allQuestions.length;

    // Adjust for default visited but not answered
    const defaultVisitedButNotAnswered = 1; // Assuming 1 question is default visited but not answered

    // The not answered count is total questions minus the ones answered or marked for review
    const extranotAnsweredCount =
      totalQuestions - answeredCount - markedForReviewCount - notVisitedCount;

    if (totalQuestions > 0) {
      const visitedQuestionsCount =
        answeredCount + markedForReviewCount + notVisitedCount;
      if (visitedQuestionsCount < totalQuestions) {
        notVisitedCount =
          totalQuestions - visitedQuestionsCount - defaultVisitedButNotAnswered;
      }
    }
    console.log(
      "extranotAnsweredCount=totalQuestions - answeredCount - markedForReviewCount - notVisitedCount",
      extranotAnsweredCount,
      totalQuestions,
      answeredCount,
      markedForReviewCount,
      notVisitedCount
    );
    return {
      answeredCount,
      markedForReviewCount,
      notVisitedCount,
      extranotAnsweredCount,
    };
  };

  const extratotalQuestions = extraquestionsData.reduce(
    (acc, subject) =>
      acc +
      subject.sections.reduce((acc, sec) => acc + sec.questions.length, 0),
    0
  );

  console.log("extratotalQuestions COUNTSSSSSSSSS", extratotalQuestions);


  const combinedCalculateCounts = () => {
    // Get counts from both functions
    const { answeredCount: answeredCountWithExtra, notAnsweredCount: notAnsweredCountWithExtra } = calculateCounts();
    const { answeredCount: answeredCountExtra, extranotAnsweredCount } = extraCalculateCounts();
  
    // Combine counts
    const combinedAnsweredCount = answeredCountWithExtra + answeredCountExtra;
    const combinedNotAnsweredCount = notAnsweredCountWithExtra + extranotAnsweredCount;
  
    console.log("Combined Answered Count:", combinedAnsweredCount);
    console.log("Combined Not Answered Count:", combinedNotAnsweredCount);
  
    return {
      combinedAnsweredCount,
      combinedNotAnsweredCount,
    };
  };
  
// Call the function and destructure the results
const { combinedAnsweredCount, combinedNotAnsweredCount } = combinedCalculateCounts();

// Now use these variables as needed
console.log("Combined Answered Count:", combinedAnsweredCount);
console.log("Combined Not Answered Count:", combinedNotAnsweredCount);


  


  const handleExtraSubmit = () => {
    // const extraCounts = extraCalculateCounts();
    setShowPopup(true);
  };

  const extraClosePopup = () => {
    setShowPopup(false);
  };

  const extraCurrentSubject = extraquestionsData.find(
    (subject) => subject.SubjectName === selectedExtraSubject
  );

  const extraCurrentSection =
    extraCurrentSubject?.sections[currentExtraSectionIndex];

  const extraCurrentQuestion =
    extraquestionsData[0].sections[0].questions[0].question_id;

  const extraCounts = extraCalculateCounts();

  const totalcountforexam = extratotalQuestions + totalQuestions;

  console.log("totalPossibleMarks", totalPossibleMarks);

  // Log the result
  console.log("Total count for exam:", totalcountforexam);
  // -----------------------------------------------------------------------------------
  console.log(
    selectedExtraSubject &&
      extraCurrentQuestion &&
      extraCurrentQuestion.question_id
  );

  const handleDownloadPdf = () => {
    const elementsToCapture = [
      document.querySelector(".downloadscslassname"),
      document.querySelector(".Congratulationssubdiv"),
      document.querySelector(".CongratulationsTimeSpent"),
      document.querySelector(".Chart_container1 .pieContainer"),
      document.querySelector(".Chart_container1 .circularProgressBarContainer"),
    ];

    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = 210; // A4 page width in mm
    const pageHeight = 297; // A4 page height in mm
    const margin = 10;
    const contentMargin = 10; // Margin between border and content
    const topBottomMargin = 15; // Additional space from top and bottom borders
    let yOffset = margin + topBottomMargin; // Initial offset including top margin

    const textDescriptions = [
      "eGRAD Tutor Mock Test Report",
      "Score Section",
      "Time Spent Analysis",
      "Correct/Wrong Answers Pie Chart",
      "Total Percentage",
    ];

    // Function to load the logo as base64
    const addLogo = (callback) => {
      const image = new Image();
      image.onload = function () {
        const canvas = document.createElement("canvas");
        canvas.width = this.naturalWidth;
        canvas.height = this.naturalHeight;
        canvas.getContext("2d").drawImage(this, 0, 0);
        const base64data = canvas.toDataURL("image/png");

        const logoWidth = 80; // Adjust width of logo
        const logoHeight = 30; // Adjust height of logo
        const logoX = 10; // X position of logo
        const logoY = 10; // Y position of logo

        pdf.addImage(base64data, "PNG", logoX, logoY, logoWidth, logoHeight);

        const padding = 10; // Add some space between the logo and the text
        yOffset = logoY + logoHeight + padding;

        pdf.setFontSize(16);
        pdf.text(textDescriptions[0], margin + contentMargin, yOffset);

        callback();
      };
      image.onerror = function (error) {
        console.error("Error loading logo image:", error);
        callback(); // Proceed even if the logo fails to load
      };
      image.src = logo; // Use the imported image
    };

    const captureAndAddToPdf = (elementIndex) => {
      if (elementIndex >= elementsToCapture.length) {
        pdf.save("charts.pdf");
        return;
      }

      const element = elementsToCapture[elementIndex];
      const description = textDescriptions[elementIndex];
      const scale = element.classList.contains("downloadscslassname") ? 3 : 2;

      if (element.classList.contains("downloadscslassname")) {
        element.style.fontSize = "18px";
      }

      html2canvas(element, { scale: scale, useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        // Calculate image dimensions
        const imgWidth = pageWidth - margin * 2 - 2 * contentMargin;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        // Adjust image height and width if necessary
        let adjustedHeight = imgHeight;
        let adjustedWidth = imgWidth;

        if (
          yOffset + adjustedHeight + contentMargin + margin >
          pageHeight - topBottomMargin
        ) {
          // If content does not fit on the current page, add a new page
          pdf.addPage();
          yOffset = margin + topBottomMargin; // Reset yOffset for the new page
        }

        // Add text description
        pdf.setFontSize(12);
        pdf.text(description, margin + contentMargin, yOffset + contentMargin);
        yOffset += contentMargin + 10;

        // Add image
        pdf.addImage(
          imgData,
          "PNG",
          margin + contentMargin,
          yOffset + contentMargin,
          adjustedWidth,
          adjustedHeight
        );

        // Update yOffset for the next element
        yOffset += adjustedHeight + contentMargin + margin;

        if (element.classList.contains("downloadscslassname")) {
          element.style.fontSize = "";
        }

        captureAndAddToPdf(elementIndex + 1);
      });
    };

    // Start the process by adding the logo and then capturing other elements
    addLogo(() => {
      captureAndAddToPdf(1); // Start capturing elements from index 1
    });
  };

  const [isPaletteVisible, setIsPaletteVisible] = useState(false);

  const togglePaletteVisibility = () => {
    setIsPaletteVisible(!isPaletteVisible);
  };

  const getTotalAnsweredCount = () => {
    // Get the counts objects
    const mainCounts = calculateCounts(); // Returns an object
    const extraCounts1 = extraCalculateCounts(); // Returns an object

    // Extract the answeredCount from both objects
    const totalAnsweredCount =
      mainCounts.answeredCount + extraCounts1.answeredCount;

    return totalAnsweredCount;
  };

  // Usage
  const totalAnsweredCount = getTotalAnsweredCount();

  const getTotalCounts = () => {
    const mainCounts = calculateCounts();
    const extraCounts = extraCalculateCounts();

    const totalNotVisitedCount =
      mainCounts.notVisitedCount + extraCounts.notVisitedCount;

    return {
      totalNotVisitedCount,
    };
  };

  // Usage
  const { totalNotVisitedCount } = getTotalCounts();

  return (
    <div>
      {!showExtraQuestions ? (
        <div>
          <div className="mocktestparent_conatiner">
            {/* -------------------header------- */}

            <div>
              <div className="remaining">
                <div className="bitsatMockHeader">
                  <img
                    src="https://www.egradtutor.in/static/media/egate%20logo%201.8b1eb31347d60945925f.png"
                    alt=""
                  />
                </div>

                <div className="JEE_MAINS_MOCKTEST_HEADING">
                  <h4>{questionsData[0].Test_Name}</h4>
                </div>
              </div>
            </div>

            {/* -------------------interface------- */}

            <div>
              <div className="mocktest_conatiner_testwithpallet">
                <div className="mocktest_conatiner_testwithpalletquizcontainer">
                  <div className="mocktest_conatiner_testwithpalletquizcontainersub">
                    {/* -------------------sectionbtns------- */}

                    <div className="sectionbtnmock">
                      {questionsData.map((subject) => (
                        <button
                          key={subject.SubjectName}
                          onClick={() =>
                            handleSubjectChange(subject.SubjectName)
                          }
                          className={
                            selectedSubject === subject.SubjectName
                              ? "active"
                              : ""
                          }
                        >
                          {subject.SubjectName}
                        </button>
                      ))}
                    </div>

                    <div className="Jee_mains_exam_Q.s_Type_container">
                      <div>
                        <p>Question Type: {currentQuestion.qtype}</p>
                      </div>
                      <div className="Jee_Mains_Timing_container">
                        <p className="Jee_Mains_Timing_paragraph">
                        <LuAlarmClock /> Time Left:
                          {/* {formatTime(remainingTime)} */}
                          {hours.toString().padStart(2, "0")}:
                      {minutes.toString().padStart(2, "0")}:
                      {seconds.toString().padStart(2, "0")}
                         {/* {`${Math.floor(remainingTime / 3600)}:${Math.floor(
                        (remainingTime % 3600) / 60
                      )}:${remainingTime % 60}`} */}
                        </p>
                      </div>
                    </div>

                    <div className="mocktest_conatiner_testwithpalletquizcontainersubintopart">
                      {selectedSubject && currentQuestion && (
                        <div>
                          <p>Question {currentQuestion.question_id}:</p>

                          <img src={currentQuestion.questionImgName} alt="" />

                          <div className="optionimginmock">
                            <p>Options:</p>
                            <div className="optionimginmock">
                              {currentQuestion.options.map((option) => (
                                <label
                                  key={option.option_id}
                                  className="option"
                                >
                                  <input
                                    type="radio"
                                    id={`option-${option.option_id}`}
                                    name={`question-${currentQuestion.question_id}`}
                                    checked={
                                      responses[
                                        getQuestionKey(
                                          selectedSubject,

                                          currentSectionIndex,

                                          currentQuestion.question_id
                                        )
                                      ] === option.option_index
                                    }
                                    onChange={() =>
                                      handleOptionChange(option.option_index)
                                    }
                                  />

                                  <span htmlFor={`option-${option.option_id}`}>
                                    ({option.option_index})
                                  </span>

                                  <img src={option.optionImgName} alt="" />
                                </label>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* -------------------------------------------buttons Option container-------------- */}

                    <div>
                      <div class="mocktest_conatiner_testwithpalletbuttons">
                        {" "}
                        <div>
                          <button
                            class="Quiz_mockbtns Quiz_mockbtnssubmit"
                            onClick={nextQuestion}
                          >
                            Next
                          </button>
                          <button
                            class="Quiz_clearResponse Quiz_mockbtns Quiz_mockbtnsclear"
                            onClick={clearResponse}
                          >
                            Clear Response
                          </button>
                          <button
                            class="quizsave_next previous_next_btn Quiz_mockbtns"
                            onClick={saveAndNext}
                          >
                            Save &amp; Next
                          </button>
                        </div>
                        <div>
                          <button
                            class="Quiz_mockbtns Quiz_mockbtnspre"
                            onClick={previousQuestion}
                          >
                            Previous
                          </button>

                          <button
                            class="Quiz_mockbtns Quiz_mockbtnssubmit"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="hamburger-menu" onClick={toggleMenu}>
                    {/* Conditional rendering based on the state */}
                    {isMenuOpen ? (
                      <span>&#10005;</span> // Cross mark (✖)
                    ) : (
                      <span>&#9776;</span> // Hamburger menu (☰)
                    )}
                  </div>

                  <div
                    className={`numberpalette ${isMenuVisible ? "show" : ""}`}
                  >
                    <div class="rightSidebar-topHeader">
                      <img
                        title="Guest"
                        src="/static/media/guesticon.00ad06aaa8a7af8eb6c5.jpg"
                        alt="guesticon"
                      />
                      <p className="Current_test_name">Candidate Name: Guest</p>
                      <p className="Current_test_name">
                        {questionsData[0].Test_Name}
                      </p>
                    </div>
                    <div className="yoursection_inMockTest">
                      <span>
                        You are viewing{" "}
                        <label htmlFor="">{currentSubject.SubjectName}</label>
                        Section{" "}
                      </span>
                      <b>Question Palette</b>
                    </div>
                    <div className="question-number-palette question-numberpalette question-palette">
                      {currentSection.questions.map((question, index) => {
                        const key = getQuestionKey(
                          selectedSubject,

                          currentSectionIndex,

                          question.question_id
                        );
                        return (
                          <button
                            key={question.question_id}
                            className={`

                    ${index === currentQuestionIndex ? "active" : ""}
                    ${
                      answeredQuestions[key] && !markedForReview[key]
                        ? "answered"
                        : ""
                    }
                    ${viewedQuestions[key] && !responses[key] ? "viewed" : ""}
                    ${
                      markedForReview[key]
                        ? responses[key]
                          ? "marked-answered"
                          : "marked"
                        : ""
                    }
                    ${
                      responses[key] !== undefined && !answeredQuestions[key]
                        ? "answered-red active"
                        : ""
                    }
                  `}
                            onClick={() => goToQuestion(index)}
                          >
                            {index + 1}
                          </button>
                        );
                      })}
                    </div>

                    <div className="sidebar-footer">
                      <h4 className="sidebar-footer-header">Legend:</h4>

                      <div className="footer-btns">
                        <div className="inst-btns">
                          <span className="NotVisited">
                            {counts.notVisitedCount}
                          </span>

                          <label htmlFor="">Not Visited</label>
                        </div>

                        <div className="inst-btns">
                          <span className="Answered">{answeredCount}</span>

                          <label htmlFor="">Answered</label>
                        </div>

                        <div className="inst-btns">
                          <span className="NotAnsweredlegend">
                            {" "}
                            {counts.notAnsweredCount}
                          </span>

                          <label htmlFor="">Not Answered</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="scorecardforexam">
            {showConfirmationPopup && (
              <div className=" popup">
                <div className="popup-content">
                  <p>Do you want to attempt extra questions?</p>
                  <div className="popup-contentbtndiv">
                    <button onClick={handleConfirmExtraQuestions}>Yes</button>
                    {/* <button onClick={handleCancelExtraQuestions}>No</button> */}
                    <button onClick={handleCancelExtraQuestions}>No</button>
                  </div>
                </div>
              </div>
            )}
            {showPopup && (
              <div className="popup2">
                <div className="popup-content">
                  {remainingTime === 0 ? (
                    <div>
                      <div>
                        <p>Your Time is up!</p>
                        <p>
                          Your test is automatically submitted successfully.
                        </p>
                      </div>
                      {/* <ul class="allscorecountingmock">
                        <li>
                          <b>Answered:</b> {answeredCount}
                        </li>
                        <li>
                          <b>Not Answered:</b> {notAnsweredCount}
                        </li>
                        <li>
                          <b>Not Visited:</b> {counts.notVisitedCount}
                        </li>
                      </ul> */}
                      <div className="Jeemains_mocktest_yes_no_buttons_container">
                        <button
                          title="Press okay and view report"
                          className="MockTest_submit_Yes_btn"
                          // onClick={handleYes}
                          onClick={handleShowEvaluvationPopup}
                        >
                          Okay
                        </button>
                      </div>
                     
                    </div>
                  ) : (
                    <div>
                      <div>
                        <p>The Actual Test time is of 180 mins. normal</p>
                        <p>Do you want to Continue submiting the test</p>
                      </div>
                      {/* <ul class="allscorecountingmock">
                        <li>
                          <b>Answered:</b> {totalAnsweredCount}
                        </li>
                        <li>
                          <b>Not Answered:</b> {counts.notAnsweredCount}
                        </li>
                        <li>
                          <b>Not Visited:</b> {counts.notVisitedCount}
                        </li>
                      </ul> */}
                      <div className="Jeemains_mocktest_yes_no_buttons_container">
                        <button
                          title="Save Test and Exit"
                          className="MockTest_submit_Yes_btn"
                          // onClick={handleYes}
                          onClick={handleShowEvaluvationPopup}
                        >
                          Yes
                        </button>
                        <button
                          title="Return to Test"
                          className="MockTest_submit_No_btn"
                          onClick={handlebuttonNo}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
             <div className="scorecardforexam">
                        {showEvaluvationPopup && (
                          <div className="Evaluvationpopup">
                            <div className="popupcontentEvaluvation">
                              <p className="Evaluvationsummary">Test Summary</p>
                              <div>
                                <div className="EvaluvationSuccessfully">
                                  Your Test Submitted Successfully
                                </div>
                                <div className="EvaluvationsummaryTestName">
                                  <div className="EvaluvationsummaryTest">
                                    Test Name :
                                  </div>
                                  <div> {questionsData[0].Test_Name}</div>
                                </div>
                                <div>
                                  <table>
                                    <tr>
                                      <th>Total Questions</th>
                                      <th>Maximum Marks</th>
                                      <th>Total Attempted</th>
                                      <th>Left Questions</th>
                                      <th>Correct Questions</th>
                                      <th>InCorrect Questions</th>
                                      <th>Total Time(in min.)</th>
                                      <th>Time Taken(in min.)</th>
                                      <th>Right Marks</th>
                                      <th>Negative Marks</th>
                                      <th>Total Marks</th>
                                    </tr>
                                    <tr>
                                      <td>{totalQuestions}</td>
                                      <td>{totalPossibleMarks}</td>
                                      <td>{answeredCount}</td>
                                      <td>{notAnsweredCount}</td>
                                      <td>{rightAnsweredCount}</td>
                                      <td>{wrongAnsweredCount}</td>
                                      <td>180</td>
                                      <td>{formattedTimeSpent}</td>
                                      <td>{RightAnsweredMarks}</td>
                                      <td>{wrongAnsweredMarks}</td>
                                      <td>{totalMarks}</td>
                                    </tr>
                                  </table>
                                  <div>
                                    <button
                                    onClick={handleYes}
                                      // onClick={handleSubmit}
                                      className="ReportSolutions"
                                    >
                                      View Report/Solutions
                                    </button>
                                    <button
                                      className="mocktestClosebtnPieChartpage"
                                      onClick={closePopup}
                                    >
                                      Close
                                    </button>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
            <div>
              {showPopupscore && (
                <div className="popup3">
                  <div className="popup-contentscore mocksPopUpContentScoreContainer">
                    <div className="popup_header">
                      <div className="remaining">
                        <div className="popHeaderLogo ">
                          <img
                            src="https://www.egradtutor.in/static/media/egate%20logo%201.8b1eb31347d60945925f.png"
                            alt="Logo"
                          />
                        </div>

                        <div className="JEE_MAINS_MOCKTEST_HEADING">
                          <h4>{currentSection.Test_Name}</h4>
                        </div>
                      </div>
                    </div>
                    <div className="mocktestOverallPiechartsDivContainer">
                      <div className="mocktestdatadiv mocktestOverallPiechartsDiv">
                        <div className="Repostpagepopupclosebutton">
                          <button
                            className="mocktestClosebtnPieChartpage"
                            onClick={closePopup}
                          >
                            Close
                          </button>
                        </div>

                        <div className="downloadscslassname">
                          <div className="mocktestdatasubdiv mocktestOverallPiechartsSubDiv">
                            {" "}
                            <div className="mocktestdatasubdiv11 mocksPagePieChartsTestDetailsBC">
                              Test Name:{" "}
                              <span className="mocksTestPieChartspan">
                                {questionsData[0].Test_Name}
                              </span>
                            </div>
                            <div className="mocktestdatasubdiv12 mocksPagePieChartsTestDetailsBC">
                              Subjects:{" "}
                              <span className="mocksTestPieChartspan">
                                {questionsData
                                  .map((subject) => subject.SubjectName)
                                  .join(", ")}
                              </span>
                            </div>
                            <div className="mocktestdatasubdiv13 mocksPagePieChartsTestDetailsBC">
                              Test Duration:{" "}
                              <span className="mocksTestPieChartspan">
                                180 Mins
                              </span>
                            </div>
                            <div className="mocktestdatasubdiv14 mocksPagePieChartsTestDetailsBC">
                              Total Questions:
                              <span className="mocksTestPieChartspan">90</span>
                            </div>
                            <div className="mocktestdatasubdiv15 mocksPagePieChartsTestDetailsBC">
                              Total Marks:{" "}
                              <span className="mocksTestPieChartspan">300</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Add buttons for Performance and Solutions */}
                      <div className="containerInPerformance mocksPagePieChartsContainerPerform">
                        <div className="button-container">
                          <div className="Progress_solution_container">
                            <button
                              id="performance_solution"
                              className={
                                activeButton === "performance" ? "active" : ""
                              }
                              onClick={handleShowPerformance}
                            >
                              Your Performance
                            </button>
                            <button
                              id="performance_solution"
                              className={
                                activeButton === "solutions" ? "active" : ""
                              }
                              onClick={handleShowSolutions}
                            >
                              Solutions
                            </button>
                          </div>
                        </div>

                        <div>
                          {showPerformance ? (
                            <div className="Chart_container  mocksPagePieChartsTestDetailsChatContainerDiv">
                              <div className="download-button mockTestsdownloadbtnDivPicharts">
                                <button
                                  className="mockTestsdownloadbtnPicharts"
                                  onClick={handleDownloadPdf}
                                >
                                  Download PDF{" "}
                                  <HiDownload className="mocktestdownloadPiechartsIconn" />
                                </button>
                              </div>

                              <div className="Congratulationsdiv mocksPagePieChartsTestDetailsCongratsDiv">
                                {/* Download Button */}

                                <div className="Congratulationssubdiv mocksPagePieChartsTestDetailsCongratsSubDiv">
                                  <div>
                                    <img
                                      src="/static/media/prize.e48700d2bb773b1eda58.jpg"
                                      alt="Congratulations"
                                    />
                                  </div>
                                  <div className="Mocktest_result_page_scrore_container mockspageResultsScore">
                                    {/* <h2>Congratulations!</h2> */}
                                    <span className="mockscore">
                                      OverAllTotalMarks:{OverAllTotalMarks}
                                    </span>
                                  </div>
                                </div>

                                {/* Horizontal bar to display spent time and remaining time */}
                                <div className="Congratulationssubdiv CongratulationsTimeSpent mocksPagePieChartsTestDetailsCongratsSubDiv">
                                  <h2 className="mocksPagePieChartsTimeHeading">
                                    Time Spent
                                  </h2>
                                  <div className="Time_bar_container">
                                    <div className="Time_Spent_Remaining_Time_container">
                                      <div className="Time_Spent">
                                        <p
                                          style={{
                                            backgroundColor: "#e96e6e",
                                            height: "15px",
                                            width: "30px",
                                            margin: "0 10px",
                                          }}
                                        ></p>
                                        <span>Time Spent</span>
                                      </div>
                                      <div className="Remaining_Time">
                                        <p
                                          style={{
                                            backgroundColor:
                                              "rgba(0, 0, 255, 0.44)",
                                            height: "15px",
                                            width: "30px",
                                            margin: "0 10px",
                                          }}
                                        ></p>
                                        <span>Remaining Time</span>
                                      </div>
                                    </div>
                                    {/* Display Total Time, Time Spent, and Remaining Time */}
                                    <div className="Time_spent_container">
                                      {/* <p><span>Total Time:</span> {duration} mins</p> */}
                                      <p>
                                        <span>Time Spent:</span>{" "}
                                        {formattedTimeSpent} mins
                                      </p>
                                      <p>
                                        <span>Remaining Time:</span>{" "}
                                        {remainingTimeDisplay} mins
                                      </p>
                                    </div>

                                    {/* Horizontal Bar representing time spent and remaining time */}
                                    <div
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          // width: `${(formattedTimeSpent / duration) * 100}%`,
                                          width: `${percentageSpent}%`,
                                          backgroundColor: "#e96e6e",
                                          height: "20px",
                                        }}
                                      ></div>
                                      <div
                                        className="Remaining_time_container"
                                        style={{
                                          // width: `${(remainingTimeDisplay / duration) * 100
                                          //   }%`,
                                          width: `${percentageRemaining}%`,
                                          backgroundColor:
                                            "rgba(0, 0, 255, 0.44)",
                                          height: "20px",
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="Chart_container1">
                                {/* Display the Pie Chart */}
                                <div
                                  className="pieContainer"
                                  // style={{ width: "300px", margin: "auto" }}
                                >
                                  <Pie data={pieData} options={pieOptions} />
                                  <p>Performance Analysis</p>
                                </div>
                                <div
                                  className="circularProgressBarContainer"
                                  // style={{ width: "155px", margin: "0 auto" }}
                                >
                                  <CircularProgressbar
                                    value={percentage}
                                    text={`${percentage}%`}
                                    styles={buildStyles({
                                      pathColor: `rgba(255, 0, 0, ${
                                        (percentage / 100) * 0.5 + 0.5
                                      })`, // Red color
                                      textColor: "#007bff", // Blue color for text
                                      trailColor: "#ffefef", // Light red color for trail
                                      backgroundColor: "#ffffff",
                                    })}
                                  />
                                  <p
                                    style={{
                                      textAlign: "center",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Your Total Percentage
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="mocktestsolution">
                              {/* Dropdown to select subject */}
                              <div className="Mocktest_view_solution_sections_container">
                                <label htmlFor="subjectDropdown">
                                  Select Subject:{" "}
                                </label>
                                <select
                                  id="subjectDropdown"
                                  value={selectedSubject}
                                  onChange={handleSubjectChangemenu}
                                >
                                  {questionsData.map((subject, index) => (
                                    <option
                                      key={index}
                                      value={subject.SubjectName}
                                    >
                                      {subject.SubjectName}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              {/* Display selected subject's sections and questions */}
                              {selectedSubjectData && (
                                <div>
                                  {selectedSubjectData.sections.map(
                                    (section, sectionIndex) => (
                                      <div key={section.SectionName}>
                                        <h2 className="Mocktest_reportpage_Solutio_Section-heading">
                                          {section.SectionName}
                                        </h2>
                                        {section.questions.map((question) => {
                                          const correctAnswers =
                                            question.answer.split(",");
                                          const key = getQuestionKey(
                                            selectedSubject,
                                            sectionIndex,
                                            question.question_id
                                          );
                                          const userAnswer =
                                            question.qtype === "NATD" ||
                                            question.qtype === "NATI"
                                              ? getInputValue(
                                                  selectedSubject,
                                                  sectionIndex,
                                                  question.question_id
                                                )
                                              : responses[key]; // Retrieve user's stored answer or input value for NATD/NATI

                                          // For NATD questions, check if the user answer is within the correct range
                                          let isIncorrect = false;
                                          if (
                                            question.qtype === "NATD" &&
                                            userAnswer
                                          ) {
                                            const [lowerRange, upperRange] =
                                              correctAnswers
                                                .split("-")
                                                .map((range) =>
                                                  parseFloat(range)
                                                );

                                            isIncorrect = !(
                                              parseFloat(userAnswer) >=
                                                lowerRange &&
                                              parseFloat(userAnswer) <=
                                                upperRange
                                            );
                                          }

                                          return (
                                            <div
                                              key={question.question_id}
                                              style={{ marginBottom: "20px" }}
                                              className="solutionDiv"
                                            >
                                              <div className="questionDiv">
                                                <strong>
                                                  Q{question.question_id}:{" "}
                                                </strong>
                                                <img
                                                  src={question.questionImgName}
                                                  alt=""
                                                />
                                              </div>

                                              {/* Handling NATD or NATI type questions */}
                                              {question.qtype === "NATD" ||
                                              question.qtype === "NATI" ? (
                                                <div className="nat-answer">
                                                  <p>
                                                    <strong>
                                                      Correct Answer:
                                                    </strong>{" "}
                                                    {question.answer}
                                                  </p>
                                                  <p
                                                    className={
                                                      question.qtype ===
                                                        "NATD" && isIncorrect
                                                        ? "natd-incorrect"
                                                        : ""
                                                    }
                                                  >
                                                    <strong>
                                                      Your Answer:
                                                    </strong>{" "}
                                                    {userAnswer ||
                                                      "No answer provided"}
                                                  </p>
                                                </div>
                                              ) : (
                                                <ul className="optionsUlDiv">
                                                  {question.options.map(
                                                    (option) => {
                                                      const isCorrectOption =
                                                        correctAnswers.includes(
                                                          option.option_index
                                                        );
                                                      const isUserAnswer =
                                                        userAnswer &&
                                                        userAnswer.includes(
                                                          option.option_index
                                                        );

                                                      return (
                                                        <li
                                                          key={option.option_id}
                                                          style={{
                                                            position:
                                                              "relative",
                                                          }}
                                                        >
                                                          ({option.option_index}
                                                          )
                                                          <img
                                                            src={
                                                              option.optionImgName
                                                            }
                                                            alt=""
                                                          />
                                                          {/* Show a green ✔ if the option is correct */}
                                                          {isCorrectOption && (
                                                            <span className="solutions_correct_answer">
                                                              ✔
                                                            </span>
                                                          )}
                                                          {/* Show a red ✘ if the user selected this option but it is incorrect */}
                                                          {isUserAnswer &&
                                                            !isCorrectOption && (
                                                              <span className="incorrect-answer">
                                                                ✘
                                                              </span>
                                                            )}
                                                        </li>
                                                      );
                                                    }
                                                  )}
                                                </ul>
                                              )}

                                              <button
                                                onClick={() =>
                                                  handleShowSolution(
                                                    question.solution
                                                  )
                                                }
                                              >
                                                {showSolution &&
                                                currentSolution ===
                                                  question.solution
                                                  ? "Hide Solution"
                                                  : "Show Solution"}
                                              </button>

                                              {showSolution &&
                                                currentSolution ===
                                                  question.solution && (
                                                  <div className="View_solution_container">
                                                    <strong>Solution:</strong>{" "}
                                                    <img
                                                      className="MockTest_ReportPgae_solution_Imgtag"
                                                      src={question.solution}
                                                      alt=""
                                                    />
                                                  </div>
                                                )}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div>
                      <button onClick={closePopup}>Close</button>
                    </div> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="mocktestparent_conatiner">
            {/* -------------------header------- */}

            <div>
              <div className="remaining">
                <div className="bitsatMockHeader">
                  <img
                    src="https://www.egradtutor.in/static/media/egate%20logo%201.8b1eb31347d60945925f.png"
                    alt=""
                  />
                </div>

                <div className="JEE_MAINS_MOCKTEST_HEADING">
                  <h4>{questionsData[0].Test_Name}</h4>
                </div>
              </div>
            </div>

            {/* -------------------interface------- */}

            <div>
              <div className="mocktest_conatiner_testwithpallet">
                <div className="mocktest_conatiner_testwithpalletquizcontainer">
                  <div className="mocktest_conatiner_testwithpalletquizcontainersub">
                    {/* -------------------sectionbtns------- */}

                    <div className="sectionbtnmock">
                      {extraquestionsData.map((subject) => (
                        <button
                          key={subject.SubjectName}
                          onClick={() =>
                            handleExtraSubjectChange(subject.SubjectName)
                          }
                          className={
                            selectedExtraSubject === subject.SubjectName
                              ? "active"
                              : ""
                          }
                        >
                          {subject.SubjectName}
                        </button>
                      ))}
                    </div>

                    <div className="Jee_mains_exam_Q.s_Type_container">
                      <div>
                        <p>Question Type: {currentQuestion.qtype}</p>
                      </div>
                      <div className="Jee_Mains_Timing_container">
                        <p className="Jee_Mains_Timing_paragraph">
                          Time remaining:{" "}
                          {`${Math.floor(remainingTime / 3600)}:${Math.floor(
                            (remainingTime % 3600) / 60
                          )}:${remainingTime % 60}`}
                        </p>
                        {/* <p>Time Spent:{spentHours.toString().padStart(2, "0")}
                                  :{spentMinutes.toString().padStart(2, "0")}:
                                  {spentSeconds.toString().padStart(2, "0")}</p> */}
                      </div>
                    </div>
                    <h2>Extra Questions</h2>

                    <div className="mocktest_conatiner_testwithpalletquizcontainersubintopart">
                      {selectedExtraSubject && extraCurrentQuestion && (
                        <div>
                          <p>Question: {currentExtraQuestionIndex + 1}</p>

                          <img
                            src={
                              extraCurrentSubject.sections[
                                currentExtraSectionIndex
                              ].questions[currentExtraQuestionIndex]
                                .questionImgName
                            }
                            alt={`Question ${extraCurrentQuestion}`}
                          />

                          <div className="optionimginmock">
                            <p>Options:</p>
                            {extraCurrentSubject.sections[
                              currentExtraSectionIndex
                            ].questions[currentExtraQuestionIndex].options.map(
                              (option) => (
                                <label
                                  key={option.option_id}
                                  className="option"
                                >
                                  <input
                                    type="radio"
                                    id={`option-${option.option_id}`}
                                    name={`question-${extraCurrentQuestion}`}
                                    checked={
                                      responsesExtra[
                                        getExtraQuestionKey(
                                          selectedExtraSubject,
                                          currentExtraSectionIndex,
                                          extraCurrentSubject.sections[
                                            currentExtraSectionIndex
                                          ].questions[currentExtraQuestionIndex]
                                            .question_id
                                        )
                                      ] === option.option_index
                                    }
                                    onChange={() =>
                                      handleExtraOptionChange(
                                        option.option_index
                                      )
                                    }
                                  />
                                  <span htmlFor={`option-${option.option_id}`}>
                                    ({option.option_index})
                                  </span>
                                  <img
                                    src={option.optionImgName}
                                    alt={`Option ${option.option_index}`}
                                  />
                                </label>
                              )
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    {/* -------------------------------------------buttons Option container-------------- */}

                    <div>
                      <div class="mocktest_conatiner_testwithpalletbuttons">
                        {" "}
                        <div>
                          <button
                            class="Quiz_mockbtns Quiz_mockbtnssubmit"
                            onClick={ExtraNextQuestion}
                          >
                            Next
                          </button>
                          <button
                            class="Quiz_clearResponse Quiz_mockbtns Quiz_mockbtnsclear"
                            onClick={ExtraClearResponse}
                          >
                            Clear Response
                          </button>
                          <button
                            class="quizsave_next previous_next_btn Quiz_mockbtns"
                            onClick={ExtraSaveAndNext}
                          >
                            Save &amp; Next
                          </button>
                        </div>
                        <div>
                          <button
                            class="Quiz_mockbtns Quiz_mockbtnspre"
                            onClick={ExtraPreviousQuestion}
                          >
                            Previous
                          </button>

                          <button
                            class="Quiz_mockbtns Quiz_mockbtnssubmit"
                            onClick={handleExtraSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="hamburger-menu" onClick={toggleMenu}>
                    {/* Conditional rendering based on the state */}
                    {isMenuOpen ? (
                      <span>&#10005;</span> // Cross mark (✖)
                    ) : (
                      <span>&#9776;</span> // Hamburger menu (☰)
                    )}
                  </div>

                  <div
                    className={`numberpalette ${isMenuVisible ? "show" : ""}`}
                  >
                    <div class="rightSidebar-topHeader">
                      <img
                        title="Guest"
                        src="/static/media/guesticon.00ad06aaa8a7af8eb6c5.jpg"
                        alt="guesticon"
                      />

                      <p className="Current_test_name">Candidate Name: Guest</p>
                      <p className="Current_test_name">
                        {questionsData[0].Test_Name}
                      </p>
                    </div>
                    <div className="yoursection_inMockTest">
                      <span>
                        You are viewing{" "}
                        <label htmlFor="">Extra Questions</label>
                        Section{" "}
                      </span>
                      <b>Question Palette</b>
                    </div>
                    <div className="question-number-palette question-numberpalette question-palette">
                      {extraCurrentSection &&
                        extraCurrentSection.questions &&
                        extraCurrentSection.questions.map((question, index) => {
                          const key = getExtraQuestionKey(
                            selectedExtraSubject,
                            currentExtraSectionIndex,
                            question.question_id
                          );

                          return (
                            <button
                              key={question.question_id}
                              className={getButtonClass(index, key)}
                              onClick={() => goToExtraQuestion(index)}
                            >
                              {index + 1}
                            </button>
                          );
                        })}
                    </div>

                    <div className="sidebar-footer">
                      <h4 className="sidebar-footer-header">Legend:extra</h4>

                      <div className="footer-btns">
                        <div className="inst-btns">
                          <span className="NotVisited">
                            {extraCounts.notVisitedCount}
                          </span>

                          <label htmlFor="">Not Visited</label>
                        </div>

                        <div className="inst-btns">
                          <span className="Answered">
                            {extraCounts.answeredCount}
                          </span>

                          <label htmlFor="">Answered</label>
                        </div>

                        <div className="inst-btns">
                          {/* <img src={orangeBox} alt="Not Answered" /> */}

                          <span className="NotAnsweredlegend">
                            {" "}
                            {extraCounts.extranotAnsweredCount}
                          </span>

                          <label htmlFor="">Not Answered</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="scorecardforexam">
           
            {showPopup && (
              <div className="popup2">
                <div className="popup-content">
                  {remainingTime === 0 ? (
                    <div>
                      <div>
                        <p>Your Time is up!</p>
                        <p>
                          Your test is automatically submitted successfully.
                        </p>
                      </div>
                      {/* <ul class="allscorecountingmock">
                        <li>
                          <b>Answered:</b> {answeredCount}
                        </li>
                        <li>
                          <b>Not Answered:</b> {extranotAnsweredCount}
                        </li>
                        <li>
                          <b>Not Visited:</b> {counts.notVisitedCount}
                        </li>
                      </ul> */}
                      <div className="Jeemains_mocktest_yes_no_buttons_container">
                        <button
                          title="Press okay and view report"
                          className="MockTest_submit_Yes_btn"
                          // onClick={handleYes}
                          onClick={handleShowEvaluvationPopup}
                        >
                          Okay
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div>
                        <p>The Actual Test time is of 180 mins.extra</p>
                        <p>Do you want to Continue submiting the test</p>
                      </div>
                      {/* <ul class="allscorecountingmock">
                        <li>
                          <b>Answered:</b> {totalAnsweredCount}
                        </li>
                        <li>
                          <b>Not Answered:</b> {extranotAnsweredCount}
                        </li>
                        <li>
                          <b>Not Visited:</b> {totalNotVisitedCount}
                        </li>
                      </ul> */}
                      <div className="Jeemains_mocktest_yes_no_buttons_container">
                        <button
                          title="Save Test and Exit"
                          className="MockTest_submit_Yes_btn"
                          // onClick={handleYes}
                          onClick={handleShowEvaluvationPopup}
                        >
                          Yes
                        </button>
                        <button
                          title="Return to Test"
                          className="MockTest_submit_No_btn"
                          onClick={handlebuttonNo}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
  <div className="scorecardforexam">
        {showEvaluvationPopup && (
          <div className="Evaluvationpopup">
            <div className="popupcontentEvaluvation">
              <p className="Evaluvationsummary">Test Summary</p>
              <div>
                <div className="EvaluvationSuccessfully">
                  Your Test Submitted Successfully
                </div>
                <div className="EvaluvationsummaryTestName">
                  <div className="EvaluvationsummaryTest">Test Name :</div>
                  <div> {questionsData[0].Test_Name}</div>
                </div>
                <div>
                  <table>
                    <tr>
                      <th>Total Questions</th>
                      <th>Maximum Marks</th>
                      <th>Total Attempted</th>
                      <th>Left Questions</th>
                      <th>Correct Questions</th>
                      <th>InCorrect Questions</th>
                      <th>Total Time(in min.)</th>
                      <th>Time Taken(in min.)</th>
                      <th>Right Marks</th>
                      <th>Negative Marks</th>
                      <th>Total Marks</th>
                    </tr>
                    <tr>
                      <td>{totalcountforexam}</td>
                      <td>{overAllExamTotalMarks}</td>
                      <td>{combinedAnsweredCount}</td>
                      <td>{combinedNotAnsweredCount}</td>
                      <td>{overAllCorrectAnswersCount}</td>
                      <td>{overAllWrongAnswersCount}</td>
                      <td>180</td>
                      <td>{formattedTimeSpent}</td>
                      <td>{RightAnsweredMarks}</td>
                      <td>{wrongAnsweredMarks}</td>
                      <td>{overAllTotalObtainedMarks}</td>
                    </tr>
                  </table>
                  <div>
                    <button 
                    onClick={handleYes}
                    // onClick={handleSubmit} 
                    className="ReportSolutions">
                      View Report/Solutions
                    </button>
                    <button
                      className="mocktestClosebtnPieChartpage"
                      onClick={closePopup}
                    >
                      Close
                    </button>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
            <div>
              {showPopupscore && (
                <div className="popup3">
                  <div className="popup-contentscore mocksPopUpContentScoreContainer">
                    <div className="popup_header">
                      <div className="remaining">
                        <div className="popHeaderLogo ">
                          <img
                            src="https://www.egradtutor.in/static/media/egate%20logo%201.8b1eb31347d60945925f.png"
                            alt="Logo"
                          />
                        </div>

                        <div className="JEE_MAINS_MOCKTEST_HEADING">
                          <h4>{currentSection.Test_Name}</h4>
                        </div>
                      </div>
                    </div>
                    <div className="mocktestOverallPiechartsDivContainer">
                      <div className="mocktestdatadiv mocktestOverallPiechartsDiv">
                        <div className="Repostpagepopupclosebutton">
                          <button
                            className="mocktestClosebtnPieChartpage"
                            onClick={closePopup}
                          >
                            Close
                          </button>
                        </div>

                        <div className="downloadscslassname">
                          <div className="mocktestdatasubdiv mocktestOverallPiechartsSubDiv">
                            {" "}
                            <div className="mocktestdatasubdiv11 mocksPagePieChartsTestDetailsBC">
                              Test Name:{" "}
                              <span className="mocksTestPieChartspan">
                                {questionsData[0].Test_Name}
                              </span>
                            </div>
                            <div className="mocktestdatasubdiv12 mocksPagePieChartsTestDetailsBC">
                              Subjects:{" "}
                              <span className="mocksTestPieChartspan">
                                {questionsData
                                  .map((subject) => subject.SubjectName)
                                  .join(", ")}
                              </span>
                            </div>
                            <div className="mocktestdatasubdiv13 mocksPagePieChartsTestDetailsBC">
                              Test Duration:{" "}
                              <span className="mocksTestPieChartspan">
                                180 Mins
                              </span>
                            </div>
                            <div className="mocktestdatasubdiv14 mocksPagePieChartsTestDetailsBC">
                              Total Questions:
                              <span className="mocksTestPieChartspan">90</span>
                            </div>
                            <div className="mocktestdatasubdiv15 mocksPagePieChartsTestDetailsBC">
                              Total Marks:{" "}
                              <span className="mocksTestPieChartspan">300</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Add buttons for Performance and Solutions */}
                      <div className="containerInPerformance mocksPagePieChartsContainerPerform">
                        <div className="button-container">
                          <div className="Progress_solution_container">
                            <button
                              id="performance_solution"
                              className={
                                activeButton === "performance" ? "active" : ""
                              }
                              onClick={handleShowPerformance}
                            >
                              Your Performance
                            </button>
                            <button
                              id="performance_solution"
                              className={
                                activeButton === "solutions" ? "active" : ""
                              }
                              onClick={handleShowSolutions}
                            >
                              Solutions
                            </button>
                          </div>
                        </div>

                        <div>
                          {showPerformance ? (
                            <div className="Chart_container mocksPagePieChartsTestDetailsChatContainerDiv">
                              <div className="download-button mockTestsdownloadbtnDivPicharts">
                                <button
                                  className="mockTestsdownloadbtnPicharts"
                                  onClick={handleDownloadPdf}
                                >
                                  Download PDF{" "}
                                  <HiDownload className="mocktestdownloadPiechartsIconn" />
                                </button>
                              </div>

                              <div className="Congratulationsdiv mocksPagePieChartsTestDetailsCongratsDiv">
                                {/* Download Button */}

                                <div className="Congratulationssubdiv mocksPagePieChartsTestDetailsCongratsSubDiv">
                                  <div>
                                    <img
                                      src="/static/media/prize.e48700d2bb773b1eda58.jpg"
                                      alt="Congratulations"
                                    />
                                  </div>
                                  <div className="Mocktest_result_page_scrore_container mockspageResultsScore">
                                    {/* <h2>Congratulations!</h2> */}
                                    <span className="mockscore">
                                      OverAllTotalMarks:{OverAllTotalMarks}
                                    </span>
                                  </div>
                                </div>

                                {/* Horizontal bar to display spent time and remaining time */}
                                <div className="Congratulationssubdiv CongratulationsTimeSpent mocksPagePieChartsTestDetailsCongratsSubDiv">
                                  <h2 className="mocksPagePieChartsTimeHeading">
                                    Time Spent
                                  </h2>
                                  <div className="Time_bar_container">
                                    <div className="Time_Spent_Remaining_Time_container">
                                      <div className="Time_Spent">
                                        <p
                                          style={{
                                            backgroundColor: "#e96e6e",
                                            height: "15px",
                                            width: "30px",
                                            margin: "0 10px",
                                          }}
                                        ></p>
                                        <span>Time Spent</span>
                                      </div>
                                      <div className="Remaining_Time">
                                        <p
                                          style={{
                                            backgroundColor:
                                              "rgba(0, 0, 255, 0.44)",
                                            height: "15px",
                                            width: "30px",
                                            margin: "0 10px",
                                          }}
                                        ></p>
                                        <span>Remaining Time</span>
                                      </div>
                                    </div>
                                    {/* Display Total Time, Time Spent, and Remaining Time */}
                                    <div className="Time_spent_container">
                                      {/* <p><span>Total Time:</span> {duration} mins</p> */}
                                      <p>
                                        <span>Time Spent:</span>{" "}
                                        {formattedTimeSpent} mins
                                      </p>
                                      <p>
                                        <span>Remaining Time:</span>{" "}
                                        {remainingTimeDisplay} mins
                                      </p>
                                    </div>

                                    {/* Horizontal Bar representing time spent and remaining time */}
                                    <div
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        height: "30px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          // width: `${(formattedTimeSpent / duration) * 100}%`,
                                          width: `${percentageSpent}%`,
                                          backgroundColor: "#e96e6e",
                                          height: "20px",
                                        }}
                                      ></div>
                                      <div
                                        className="Remaining_time_container"
                                        style={{
                                          // width: `${(remainingTimeDisplay / duration) * 100
                                          //   }%`,
                                          width: `${percentageRemaining}%`,
                                          backgroundColor:
                                            "rgba(0, 0, 255, 0.44)",
                                          height: "20px",
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="Chart_container1">
                                {/* Display the Pie Chart */}
                                <div
                                  className="pieContainer"
                                  // style={{ width: "300px", margin: "auto" }}
                                >
                                  <Pie data={pieData} options={pieOptions} />
                                  <p>Performance Analysis</p>
                                </div>
                                <div
                                  className="circularProgressBarContainer"
                                  // style={{ width: "155px", margin: "0 auto" }}
                                >
                                  <CircularProgressbar
                                    value={extraPercentage}
                                    text={`${extraPercentage}%`}
                                    styles={buildStyles({
                                      pathColor: `rgba(255, 0, 0, ${
                                        (extraPercentage / 100) * 0.5 + 0.5
                                      })`, // Red color
                                      textColor: "#007bff", // Blue color for text
                                      trailColor: "#ffefef", // Light red color for trail
                                      backgroundColor: "#ffffff",
                                    })}
                                  />
                                  <p
                                    style={{
                                      textAlign: "center",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Your Total Percentage
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="mocktestsolution">
                              {/* Dropdown to select subject */}
                              <div className="Mocktest_view_solution_sections_container">
                                <label htmlFor="subjectDropdown">
                                  Select Subject:{" "}
                                </label>
                                <select
                                  id="subjectDropdown"
                                  value={selectedSubject}
                                  onChange={handleSubjectChangemenu}
                                >
                                  {questionsData.map((subject, index) => (
                                    <option
                                      key={index}
                                      value={subject.SubjectName}
                                    >
                                      {subject.SubjectName}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              {/* Display selected subject's sections and questions */}
                              {selectedSubjectData && (
                                <div>
                                  {selectedSubjectData.sections.map(
                                    (section, sectionIndex) => (
                                      <div key={section.SectionName}>
                                        <h2 className="Mocktest_reportpage_Solutio_Section-heading">
                                          {section.SectionName}
                                        </h2>
                                        {section.questions.map((question) => {
                                          const correctAnswers =
                                            question.answer.split(",");
                                          const key = getQuestionKey(
                                            selectedSubject,
                                            sectionIndex,
                                            question.question_id
                                          );
                                          const userAnswer =
                                            question.qtype === "NATD" ||
                                            question.qtype === "NATI"
                                              ? getInputValue(
                                                  selectedSubject,
                                                  sectionIndex,
                                                  question.question_id
                                                )
                                              : responses[key]; // Retrieve user's stored answer or input value for NATD/NATI

                                          // For NATD questions, check if the user answer is within the correct range
                                          let isIncorrect = false;
                                          if (
                                            question.qtype === "NATD" &&
                                            userAnswer
                                          ) {
                                            const [lowerRange, upperRange] =
                                              correctAnswers
                                                .split("-")
                                                .map((range) =>
                                                  parseFloat(range)
                                                );

                                            isIncorrect = !(
                                              parseFloat(userAnswer) >=
                                                lowerRange &&
                                              parseFloat(userAnswer) <=
                                                upperRange
                                            );
                                          }

                                          return (
                                            <div
                                              key={question.question_id}
                                              style={{ marginBottom: "20px" }}
                                              className="solutionDiv"
                                            >
                                              <div className="questionDiv">
                                                <strong>
                                                  Q{question.question_id}:{" "}
                                                </strong>
                                                <img
                                                  src={question.questionImgName}
                                                  alt=""
                                                />
                                              </div>

                                              {/* Handling NATD or NATI type questions */}
                                              {question.qtype === "NATD" ||
                                              question.qtype === "NATI" ? (
                                                <div className="nat-answer">
                                                  <p>
                                                    <strong>
                                                      Correct Answer:
                                                    </strong>{" "}
                                                    {question.answer}
                                                  </p>
                                                  <p
                                                    className={
                                                      question.qtype ===
                                                        "NATD" && isIncorrect
                                                        ? "natd-incorrect"
                                                        : ""
                                                    }
                                                  >
                                                    <strong>
                                                      Your Answer:
                                                    </strong>{" "}
                                                    {userAnswer ||
                                                      "No answer provided"}
                                                  </p>
                                                </div>
                                              ) : (
                                                <ul className="optionsUlDiv">
                                                  {question.options.map(
                                                    (option) => {
                                                      const isCorrectOption =
                                                        correctAnswers.includes(
                                                          option.option_index
                                                        );
                                                      const isUserAnswer =
                                                        userAnswer &&
                                                        userAnswer.includes(
                                                          option.option_index
                                                        );

                                                      return (
                                                        <li
                                                          key={option.option_id}
                                                          style={{
                                                            position:
                                                              "relative",
                                                          }}
                                                        >
                                                          ({option.option_index}
                                                          )
                                                          <img
                                                            src={
                                                              option.optionImgName
                                                            }
                                                            alt=""
                                                          />
                                                          {/* Show a green ✔ if the option is correct */}
                                                          {isCorrectOption && (
                                                            <span className="solutions_correct_answer">
                                                              ✔
                                                            </span>
                                                          )}
                                                          {/* Show a red ✘ if the user selected this option but it is incorrect */}
                                                          {isUserAnswer &&
                                                            !isCorrectOption && (
                                                              <span className="incorrect-answer">
                                                                ✘
                                                              </span>
                                                            )}
                                                        </li>
                                                      );
                                                    }
                                                  )}
                                                </ul>
                                              )}

                                              <button
                                                onClick={() =>
                                                  handleShowSolution(
                                                    question.solution
                                                  )
                                                }
                                              >
                                                {showSolution &&
                                                currentSolution ===
                                                  question.solution
                                                  ? "Hide Solution"
                                                  : "Show Solution"}
                                              </button>

                                              {showSolution &&
                                                currentSolution ===
                                                  question.solution && (
                                                  <div className="View_solution_container">
                                                    <strong>Solution:</strong>{" "}
                                                    <img
                                                      className="MockTest_ReportPgae_solution_Imgtag"
                                                      src={question.solution}
                                                      alt=""
                                                    />
                                                  </div>
                                                )}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                              <div>
                                {isExtraAttempted === true ? (
                                  <div>
                                    <h1>Extra Questions</h1>
                                    <div className="Mocktest_view_solution_sections_container">
                                      <label htmlFor="subjectDropdown">
                                        Select Subject:
                                      </label>
                                      <select
                                        id="subjectDropdown"
                                        value={extraSelectedSubject}
                                        onChange={handleExtraSubjectChangemenu}
                                      >
                                        {extraquestionsData.map(
                                          (subject, index) => (
                                            <option
                                              key={index}
                                              value={subject.SubjectName}
                                            >
                                              {subject.SubjectName}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>

                                    {/* Display selected subject's sections and questions */}
                                    {extraSelectedSubjectData && (
                                      <div>
                                        {extraSelectedSubjectData.sections.map(
                                          (section, sectionIndex) => (
                                            <div key={section.SectionName}>
                                              <h2 className="Mocktest_reportpage_Solutio_Section-heading">
                                                {section.SectionName}
                                              </h2>
                                              {section.questions.map(
                                                (question) => {
                                                  const correctAnswers =
                                                    question.answer.split(",");
                                                  const key = getQuestionKey(
                                                    selectedSubject,
                                                    sectionIndex,
                                                    question.question_id
                                                  );
                                                  const userAnswer =
                                                    question.qtype === "NATD" ||
                                                    question.qtype === "NATI"
                                                      ? getInputValue(
                                                          selectedSubject,
                                                          sectionIndex,
                                                          question.question_id
                                                        )
                                                      : responsesExtra[key]; // Retrieve user's stored answer or input value for NATD/NATI

                                                  // For NATD questions, check if the user answer is within the correct range
                                                  let isIncorrect = false;
                                                  if (
                                                    question.qtype === "NATD" &&
                                                    userAnswer
                                                  ) {
                                                    const [
                                                      lowerRange,
                                                      upperRange,
                                                    ] = correctAnswers
                                                      .split("-")
                                                      .map((range) =>
                                                        parseFloat(range)
                                                      );

                                                    isIncorrect = !(
                                                      parseFloat(userAnswer) >=
                                                        lowerRange &&
                                                      parseFloat(userAnswer) <=
                                                        upperRange
                                                    );
                                                  }

                                                  return (
                                                    <div
                                                      key={question.question_id}
                                                      style={{
                                                        marginBottom: "20px",
                                                      }}
                                                      className="solutionDiv"
                                                    >
                                                      <div className="questionDiv">
                                                        <strong>
                                                          Q
                                                          {question.question_id}
                                                          :{" "}
                                                        </strong>
                                                        <img
                                                          src={
                                                            question.questionImgName
                                                          }
                                                          alt=""
                                                        />
                                                      </div>

                                                      {/* Handling NATD or NATI type questions */}
                                                      {question.qtype ===
                                                        "NATD" ||
                                                      question.qtype ===
                                                        "NATI" ? (
                                                        <div className="nat-answer">
                                                          <p>
                                                            <strong>
                                                              Correct Answer:
                                                            </strong>{" "}
                                                            {question.answer}
                                                          </p>
                                                          <p
                                                            className={
                                                              question.qtype ===
                                                                "NATD" &&
                                                              isIncorrect
                                                                ? "natd-incorrect"
                                                                : ""
                                                            }
                                                          >
                                                            <strong>
                                                              Your Answer:
                                                            </strong>{" "}
                                                            {userAnswer ||
                                                              "No answer provided"}
                                                          </p>
                                                        </div>
                                                      ) : (
                                                        <ul className="optionsUlDiv">
                                                          {question.options.map(
                                                            (option) => {
                                                              const isCorrectOption =
                                                                correctAnswers.includes(
                                                                  option.option_index
                                                                );
                                                              const isUserAnswer =
                                                                userAnswer &&
                                                                userAnswer.includes(
                                                                  option.option_index
                                                                );

                                                              return (
                                                                <li
                                                                  key={
                                                                    option.option_id
                                                                  }
                                                                  style={{
                                                                    position:
                                                                      "relative",
                                                                  }}
                                                                >
                                                                  (
                                                                  {
                                                                    option.option_index
                                                                  }
                                                                  )
                                                                  <img
                                                                    src={
                                                                      option.optionImgName
                                                                    }
                                                                    alt=""
                                                                  />
                                                                  {/* Show a green ✔ if the option is correct */}
                                                                  {isCorrectOption && (
                                                                    <span className="solutions_correct_answer">
                                                                      ✔
                                                                    </span>
                                                                  )}
                                                                  {/* Show a red ✘ if the user selected this option but it is incorrect */}
                                                                  {isUserAnswer &&
                                                                    !isCorrectOption && (
                                                                      <span className="incorrect-answer">
                                                                        ✘
                                                                      </span>
                                                                    )}
                                                                </li>
                                                              );
                                                            }
                                                          )}
                                                        </ul>
                                                      )}

                                                      <button
                                                        onClick={() =>
                                                          handleShowSolution(
                                                            question.solution
                                                          )
                                                        }
                                                      >
                                                        {showSolution &&
                                                        currentSolution ===
                                                          question.solution
                                                          ? "Hide Solution"
                                                          : "Show Solution"}
                                                      </button>

                                                      {showSolution &&
                                                        currentSolution ===
                                                          question.solution && (
                                                          <div className="View_solution_container">
                                                            <strong>
                                                              Solution:
                                                            </strong>{" "}
                                                            <img
                                                              className="MockTest_ReportPgae_solution_Imgtag"
                                                              src={
                                                                question.solution
                                                              }
                                                              alt=""
                                                            />
                                                          </div>
                                                        )}
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div> </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div>
                      <button onClick={closePopup}>Close</button>
                    </div> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

    
    </div>
  );
}

export default BITSATMocktest_1;
