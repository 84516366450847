// import logo from "./logo/egradtutor_logo.png";
import logo from "./logo/egate logo 1.png";

export const NavData = [
  {
    // --------------------logo------------------
    logo_img_container: " logo_img_container",
    logo: [logo],

    // ---------------------navbar classes-----------------

    navbar: "navbarug",
    navlist: "navlist",
    login: "uglogin_btn",

    // ---------------------navbar links-----------------

    link1: "Home",
    link2: "Exams",
    link3: "Courses",
    link4: "About Us",
    link5: "Contact Us",
    link6: "FAQs",
    link7: "Downloads",
    link7_dp1: "PYQ Papers",
    link7_dp2: "Worksheet",

    // ---------------------navbar login-----------------
    login_btn: "login_btn",
    btn1: "Login/Registration",
  },
];
