import React, { useState } from "react";
import "../SubmitFormAndMail.css";
import '../ResponsiveForMockExams.css'
import { useNavigate } from "react-router-dom";
import { Nav } from "../../components/UG/Nav";
import { Link } from "react-router-dom";
import { IoMdHome } from "react-icons/io";
import Footer from "../../components/ug_homepage/components/Footer/Footer";
 import banners_image_one from '../../components/UG/Images/web-banners-14.jpg';
// Mock Data for Exams and Mock Tests
const examMockTests = {
  "JEE Mains": {
    id: 1,
    mockTests: [
      { id: 101, name: "Mock Test-1" },
      { id: 102, name: "Mock Test-2" },
      { id: 103, name: "Mock Test-3" },
    ],
  },
  "JEE Advanced": {
    id: 2,
    mockTests: [
      { id: 201, name: "Mock Test-1" },
      { id: 202, name: "Mock Test-2" },
      { id: 203, name: "Mock Test-3" },
    ],
  },
  NEET: {
    id: 3,
    mockTests: [
      { id: 301, name: "Mock Test-1" },
      { id: 302, name: "Mock Test-2" },
      { id: 303, name: "Mock Test-3" },
    ],
  },
  BITSAT: {
    id: 4,
    mockTests: [
      { id: 401, name: "Mock Test-1" },
      { id: 402, name: "Mock Test-2" },
      { id: 403, name: "Mock Test-3" },
    ],
  },
  VITEEE: {
    id: 5,
    mockTests: [
      { id: 501, name: "Mock Test-1" },
      { id: 502, name: "Mock Test-2" },
      { id: 503, name: "Mock Test-3" },
    ],
  },
};
 
const StudentFormandMailjee = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    studentName: "",
    contactNumber: "",
    email: "",
    selectedExam: "",
    selectedMockTest: "",
  });
  const [selectedExamId, setSelectedExamId] = useState(null);
  const [selectedMockTestId, setSelectedMockTestId] = useState(null);
 
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
 
  //   if (name === "selectedExam") {
  //     const exam = examMockTests[value];
  //     setSelectedExamId(exam ? exam.id : null);
  //     setSelectedMockTestId(null); // Reset mock test ID when exam changes
  //   } else if (name === "selectedMockTest") {
  //     const exam = examMockTests[formData.selectedExam];
  //     const mockTest = exam
  //       ? exam.mockTests.find((mockTest) => mockTest.name === value)
  //       : null;
  //     setSelectedMockTestId(mockTest ? mockTest.id : null);
  //   }
  // };
 
  const handleChange = (e) => {
    const { name, value } = e.target;
 
    // Define validation patterns and length checks
    const namePattern = /^[A-Za-z\s!@#$%^&*()_+={}\[\]|\\:;\"'<>,.?/-]*$/;
    const numberPattern = /^\d{0,10}$/; // Allows up to 10 digits
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation
 
    // Update form data based on the field name
    if (name === "studentName") {
      // Validate studentName input
      if (namePattern.test(value)) {
        setFormData({ ...formData, [name]: value });
      } else {
        // Optionally show an error or handle invalid input
        console.log("Invalid input for studentName.");
      }
    } else if (name === "contactNumber") {
      // Validate contactNumber input
      if (numberPattern.test(value)) {
        setFormData({ ...formData, [name]: value });
      } else {
        // Optionally show an error or handle invalid input
        console.log("Invalid input for contactNumber. Only 10 digits allowed.");
      }
    } else {
      // For other fields (e.g., selectedExam and selectedMockTest)
      setFormData({ ...formData, [name]: value });
 
      if (name === "selectedExam") {
        const exam = examMockTests[value];
        setSelectedExamId(exam ? exam.id : null);
        setSelectedMockTestId(null); // Reset mock test ID when exam changes
      } else if (name === "selectedMockTest") {
        const exam = examMockTests[formData.selectedExam];
        const mockTest = exam
          ? exam.mockTests.find((mockTest) => mockTest.name === value)
          : null;
        setSelectedMockTestId(mockTest ? mockTest.id : null);
      }
    }
  };
 
 
  // const handleSubmit = (e) => {
  //   e.preventDefault();
 
  //   // Get the selected exam and mock test IDs
  //   const selectedExam = formData.selectedExam;
  //   const selectedMockTest = formData.selectedMockTest;
 
  //   const exam = examMockTests[selectedExam];
  //   const examId = exam ? exam.id : null;
  //   const mockTestId =
  //     exam && selectedMockTest
  //       ? exam.mockTests.find((mockTest) => mockTest.name === selectedMockTest)
  //           ?.id
  //       : null;
 
  //   // Log the IDs to the console
  //   console.log("Form Data on Submit:", formData);
  //   console.log("Selected Exam ID:", examId);
  //   console.log("Selected Mock Test ID:", mockTestId);
 
  //   // Create a FormData object from the form data
  //   const data = new FormData(e.target);
 
  //   fetch("https://formspree.io/f/xwperaen", {
  //     method: "POST",
  //     body: data,
  //     headers: {
  //       Accept: "application/json",
  //     },
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         console.log("Form submitted successfully!");
 
  //         // Reset form
  //         setFormData({
  //           studentName: "",
  //           contactNumber: "",
  //           email: "",
  //           selectedExam: "",
  //           selectedMockTest: "",
  //         });
  //         setSelectedExamId(null);
  //         setSelectedMockTestId(null);
 
  //         // Redirect to the page based on selected exam and mock test
  //         if (examId === 1 && mockTestId === 101) || (examId === 1 && mockTestId === 102) ||(examId === 1 && mockTestId === 103)  {
  //           navigate(`/Instructionjee`);
  //         } else if (examId === 2 && mockTestId === 201) {
  //           navigate(`/InstructionjeeAdvance`);
  //         } else if (examId === 2 && mockTestId === 202) {
  //           navigate(`/InstructionjeeAdvance2`);
  //         } else if (examId === 2 && mockTestId === 203) {
  //           navigate(`/InstructionjeeAdvance3`);
  //         } else if (examId === 3 && mockTestId === 301) {
  //           navigate(`/NEETInstructionPage`);
  //         } else if (examId === 3 && mockTestId === 302) {
  //           navigate(`/NEETInstructionPage2`);
  //         } else if (examId === 3 && mockTestId === 303) {
  //           navigate(`/NEETInstructionPage3`);
  //         } else if (examId === 4 && mockTestId === 401) {
  //           navigate(`/InstructionPage`);
  //         } else if (examId === 4 && mockTestId === 402) {
  //           navigate(`/InstructionPage2`);
  //         } else if (examId === 4 && mockTestId === 403) {
  //           navigate(`/InstructionPage3`);
  //         } else if (examId === 5 && mockTestId === 501) {
  //           navigate(`/VITEEInstructionPage1`);
  //         } else if (examId === 5 && mockTestId === 502) {
  //           navigate(`/VITEEEInstructionsPage2`);
  //         } else if (examId === 5 && mockTestId === 503) {
  //           navigate(`/VITEEEInstructionsPage3`);
  //         } else {
  //           console.error("No valid redirect path found.");
  //         }
  //       } else {
  //         console.error("There was an error submitting the form.");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Form submission error:", error);
  //     });
  // };
 
  const handleSubmit = (e) => {
    e.preventDefault();
 
    // Extract form values
    const selectedExam = formData.selectedExam;
    const selectedMockTest = formData.selectedMockTest;
 
    // Get exam and mock test IDs
    const exam = examMockTests[selectedExam];
    const examId = exam ? exam.id : null;
    const mockTestId =
      exam && selectedMockTest
        ? exam.mockTests.find((mockTest) => mockTest.name === selectedMockTest)
            ?.id
        : null;
 
    // Log the IDs to the console
    console.log("Form Data on Submit:", formData);
    console.log("Selected Exam ID:", examId);
    console.log("Selected Mock Test ID:", mockTestId);
 
    // Create a FormData object from the form data
    const data = new FormData(e.target);
 
    fetch("https://formspree.io/f/xwperaen", {
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log("Form submitted successfully!");
 
          // Reset form
          setFormData({
            studentName: "",
            contactNumber: "",
            email: "",
            selectedExam: "",
            selectedMockTest: "",
          });
          setSelectedExamId(null);
          setSelectedMockTestId(null);
 
          // Determine redirect URL based on examId and mockTestId
          let redirectUrl = null;
          if (examId === 1) {
            if (mockTestId === 101 || mockTestId === 102 || mockTestId === 103) {
              redirectUrl = `/Instructionjee/${examId}/${mockTestId}`;
            } else {
              console.error("No valid redirect path found for examId 1 and mockTestId.");
            }
          } else if (examId === 2) {
            if (mockTestId === 201 || mockTestId === 202 || mockTestId === 203) {
              redirectUrl = `/InstructionjeeAdvance/${examId}/${mockTestId}`;
            } else {
              console.error("No valid redirect path found for examId 2 and mockTestId.");
            }
          } else if (examId === 3) {
            if (mockTestId === 301 || mockTestId === 302 || mockTestId === 303) {
              redirectUrl = `/NEETInstructionPage/${examId}/${mockTestId}`;
            } else {
              console.error("No valid redirect path found for examId 3 and mockTestId.");
            }
          } else if (examId === 4) {
            if (mockTestId === 401 || mockTestId === 402 || mockTestId === 403) {
              redirectUrl = `/InstructionPage/${examId}/${mockTestId}`;
            } else {
              console.error("No valid redirect path found for examId 4 and mockTestId.");
            }
          } else if (examId === 5) {
            if (mockTestId === 501 || mockTestId === 502 || mockTestId === 503) {
              redirectUrl = `/VITEEInstructionPage1/${examId}/${mockTestId}`;
            } else {
              console.error("No valid redirect path found for examId 5 and mockTestId.");
            }
          } else {
            console.error("No valid examId found.");
          }
 
          // Open the URL in a new window with fullscreen or specified dimensions
          if (redirectUrl) {
            const screenWidth = window.screen.width;
            const screenHeight = window.screen.height;
 
            window.open(
              redirectUrl,
              "_blank",
              `width=${screenWidth},height=${screenHeight},fullscreen=yes`
            );
          }
        } else {
          console.error("There was an error submitting the form.");
        }
      })
      .catch((error) => {
        console.error("Form submission error:", error);
      });
 
      console.log("doremonnnnnnnnn",examId,mockTestId);
  };
 
 
  return (
    <div>
      <div className="mock_test_header_container">
        <div className="mock_test_header_container">
          {Nav.map((NavData, index) => {
            return (
              <div className="header ug_exam_header" key={index}>
                <div className={NavData.logo_img_container}>
                  <Link to={"/"}>
                    <img src={NavData.logo} alt="" />
                  </Link>
                </div>
 
                <div className="exam_login_menu">
                  <li>
                    <Link
                      to="/home"
                      className={NavData.navlist}
                      id="exam_login_menu_home"
                    >
                      <IoMdHome /> {NavData.link1}
                    </Link>
                  </li>
                </div>
              </div>
            );
          })}
        </div>
      </div>
<div className="Mock_test_form_and_banners_container">
 
      <div className="Banner_container">
        <img src = {banners_image_one} />
      </div>
 
      <div className="SubmitFormAndMail_container">
        <div className="SubmitFormAndMail_header1">
          <div>
            <h2>MOCK TEST</h2>
          </div>
 
          <div>
            {Nav.map((NavData, index) => (
              <div key={index} className="Mock_test_header_item">
                <img src={NavData.logo} alt="" />
              </div>
            ))}
          </div>
        </div>
 
       
        <form onSubmit={handleSubmit} className="studentformmocktest">
          <div className="form-group">
            <label className="SubmitFormAndMail_label">Select Exam:</label>
            <select
              className="SubmitFormAndMail_input"
              name="selectedExam"
              value={formData.selectedExam}
              onChange={handleChange}
              required
            >
              <option value="">Select an Exam</option>
              {Object.keys(examMockTests).map((exam) => (
                <option key={examMockTests[exam].id} value={exam}>
                  {exam}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label className="SubmitFormAndMail_label">Select Mock Test:</label>
            <select
              className="SubmitFormAndMail_input"
              name="selectedMockTest"
              value={formData.selectedMockTest}
              onChange={handleChange}
              required
            >
              <option value="">Select a Mock Test</option>
              {formData.selectedExam &&
                examMockTests[formData.selectedExam].mockTests.map(
                  (mockTest) => (
                    <option key={mockTest.id} value={mockTest.name}>
                      {mockTest.name}
                    </option>
                  )
                )}
            </select>
          </div>
          <div className="form-group">
            <label className="SubmitFormAndMail_label">Student Name:</label>
            <input
              className="SubmitFormAndMail_input"
              type="text"
              name="studentName"
              placeholder="Enter Name"
              value={formData.studentName}
              onChange={handleChange}
              required
 
            />
          </div>
          <div className="form-group">
            <label className="SubmitFormAndMail_label">Contact Number:</label>
            <input
              className="SubmitFormAndMail_input"
              type="number"
              placeholder="Enter Mobile No"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label className="SubmitFormAndMail_label">Email:</label>
            <input
              className="SubmitFormAndMail_input"
              type="email"
              name="email"
              placeholder="Enter Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="submit_btn_container">
            <button
              className="mock_test_new_submit_button SubmitFormAndMail_submitButton"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
 
        {/* Display the selected exam and mock test IDs */}
        {/* {selectedExamId && (
          <div className="selected-info">
            <p>Selected Exam ID: {selectedExamId}</p>
            {selectedMockTestId && <p>Selected Mock Test ID: {selectedMockTestId}</p>}
          </div>
        )} */}
      </div>
</div>
 
      <Footer />
    </div>
  );
};
 
export default StudentFormandMailjee;