
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Jee_mains.css";
import Jee_mains_mocktest1 from "./Jee_mains.json";
import Jee_mains_mocktest2 from "./Jee_mains_mocktest2.json";
import Jee_mains_mocktest3 from "./Jee_mains_mocktest3.json";
import { useParams } from "react-router-dom";
import { LuAlarmClock } from "react-icons/lu";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import html2pdf from 'html2pdf.js';
import { FaDownload } from "react-icons/fa";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

// Register necessary components for Chart.js
ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);

const mockTestsData = {
  101: Jee_mains_mocktest1,
  102: Jee_mains_mocktest2,
  103: Jee_mains_mocktest3,
};

function JeeAdvanceMocktest() {
  const { mockTestId } = useParams();
  const questionsData = mockTestsData[mockTestId];
  const [selectedSubject, setSelectedSubject] = useState(
    questionsData.length > 0 ? questionsData[0].SubjectName : null
  );
  const [inputValues, setInputValues] = useState({});
  const updateInputValue = (
    selectedSubject,
    currentSectionIndex,
    questionId,
    num
  ) => {
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      questionId
    );
    const currentValue = getInputValue(
      selectedSubject,
      currentSectionIndex,
      questionId
    );

    let newValue = currentValue; // Initialize newValue with currentValue

    // If 'DEL' button is clicked and current value is not empty, remove the last character
    if (num === "DEL" && currentValue.length > 0) {
      newValue = currentValue.slice(0, -1);
    } else if (num !== "DEL") {
      // Only concatenate num if it's not "DEL"
      // Concatenate the new number to the current value for other cases
      newValue += num;
    }

    // Update input values with the new value
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [key]: newValue,
    }));
  };

  const getInputValue = (selectedSubject, currentSectionIndex, questionId) => {
    // Get the key using the provided parameters
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      questionId
    );
    // console.log("Getting input value for key:", key); // <-- Move this line here

    // Retrieve the input value for the specified key from the inputValues state
    return inputValues[key] || "";
  };
  const [showSolution, setShowSolution] = useState(false);
  const [currentSolution, setCurrentSolution] = useState("");
  const handleShowSolution = (solution) => {
    setCurrentSolution(solution);
    setShowSolution(true);
  };
  const [isTimerRunning, setIsTimerRunning] = useState(true); // State to manage timer running
  const [spentTime, setSpentTime] = useState(0); // Track spent time
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responses, setResponses] = useState({});
  const [viewedQuestions, setViewedQuestions] = useState({});
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [markedForReview, setMarkedForReview] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupscore, setShowPopupscore] = useState(false);
  const [remainingTime, setRemainingTime] = useState(3 * 60 * 60);
  const [isAutoSubmit, setIsAutoSubmit] = useState(false);
  const getQuestionKey = (subject, sectionIndex, questionId, inputValue) => {
    return `${subject}-${sectionIndex}-${questionId}-${inputValue}`;
  };
  const [awardedMarks, setAwardedMarks] = useState({});
  const [rightAnsweredCount, setRightAnsweredCount] = useState(0);
  const [wrongAnsweredCount, setWrongAnsweredCount] = useState(0);
  const [sectionBAnsweredCount, setSectionBAnsweredCount] = useState(0);
  const [totalMarks, setTotalMarks] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
  const [wrongAnswersCount, setWrongAnswersCount] = useState(0);
  const [answeredCount, setAnsweredCount] = useState(0);
  const [notAnsweredCount, setNotAnsweredCount] = useState(0);
  const [activeQuestion, setActiveQuestion] = useState(0); // Current question index
  const [questionTimes, setQuestionTimes] = useState(
    Array(questionsData.length).fill(0) // Initialize time spent on each question as 0
  );

  const [isTimerRunningquestion, setIsTimerRunningquestion] = useState(false); // State to track timer status
  const [timer, setTimer] = useState(null);
  // const [notVisitedCount,setNotVisitedCount] = useState(0)
  const [counts, setCounts] = useState({
    answeredCount: 0,
    markedForReviewCount: 0,
    notVisitedCount: 0,
    notAnsweredCount: 0,
  });
  useEffect(() => {
    if (isTimerRunningquestion) {
      startTimer();
    }

    return () => stopTimer(); // Cleanup timer on unmount or when question changes
  }, [activeQuestion, isTimerRunningquestion]);
  const startTimer = () => {
    stopTimer(); // Ensure any existing timer is stopped
    setTimer(
      setInterval(() => {
        setQuestionTimes((prevTimes) => {
          const newTimes = [...prevTimes];
          newTimes[activeQuestion] += 1; // Increment time for the active question
          return newTimes;
        });
      }, 1000) // Increment every second
    );
  };
   const totalTime = 180;
const remainingTimedisplay = totalTime - spentTime;
  const stopTimer = () => {
    if (timer) {
      clearInterval(timer);
      setTimer(null);
    }
  };

  useEffect(() => {
    let count = 0;
    questionsData.forEach((subject) => {
      subject.sections.forEach((section) => {
        count += section.questions.length;
      });
    });
    setNotAnsweredCount(count);
  }, []);
  const handleOptionChange = (option) => {
    console.log("Selected option:", option);
    if (currentQuestion) {
      const key = getQuestionKey(
        selectedSubject,
        currentSectionIndex,
        currentQuestion.question_id
      );
      console.log("Key:", key);
      setResponses({ ...responses, [key]: option });
    }
  };
  useEffect(() => {
    let timer;
    if (isTimerRunning) {
      timer = setInterval(() => {
        setRemainingTime((prev) => (prev > 0 ? prev - 1 : 0));
        setSpentTime((prev) => prev + 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isTimerRunning]);

  // Function to clear response for the current question
  const clearResponse = () => {
    // Construct the key for the current question
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    // Get the current input value
    const valueToClear = getInputValue(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    // Log the current value before clearing it
    console.log("Value before clearing:", valueToClear);

    // Call updateInputValue to clear the input value
    updateInputValue(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id,
      "" // Pass an empty string to clear the input value
    );

    // Log the updated input values after clearing
    console.log("Updated input values:", inputValues);

    setInputValues({
      ...inputValues,
      [key]: [], // Clear other related values if needed
    });

    // Clear response, mark the question as viewed but not answered, and decrement answered count
    setResponses({ ...responses, [key]: [] }); // Set response to an empty array
    // Mark the question as viewed but not answered, and decrement answered count
    setViewedQuestions({ ...viewedQuestions, [key]: true });
    setAnsweredQuestions({ ...answeredQuestions, [key]: false });
    // setAnsweredCount((prevAnsweredCount) => prevAnsweredCount - 1);
    // Decrease the count only if the question was previously answered
    if (answeredCount > 0) {
      setAnsweredCount((prevAnsweredCount) => prevAnsweredCount - 1);
    }
  };

  const moveToQuestion = (newSectionIndex, newQuestionIndex) => {
    const key = getQuestionKey(
      selectedSubject,
      newSectionIndex,
      currentSubject.sections[newSectionIndex].questions[newQuestionIndex]
        .question_id
    );
    setViewedQuestions({ ...viewedQuestions, [key]: true });
    setCurrentSectionIndex(newSectionIndex);
    setCurrentQuestionIndex(newQuestionIndex);
    stopTimer(); // Stop current timer
    setActiveQuestion(newQuestionIndex); // Set the clicked question as active
    setIsTimerRunningquestion(true); // Start timer for the new active question
  };

  const nextQuestion = () => {
    const currentSection = currentSubject.sections[currentSectionIndex];
    if (currentQuestionIndex < currentSection.questions.length - 1) {
      moveToQuestion(currentSectionIndex, currentQuestionIndex + 1);
    } else {
      const currentSubjectIndex = questionsData.findIndex(
        (subject) => subject.SubjectName === selectedSubject
      );
      const nextSectionIndex = currentSectionIndex + 1;
      if (nextSectionIndex < currentSubject.sections.length) {
        setCurrentSectionIndex(nextSectionIndex);
        setCurrentQuestionIndex(0);
      } else if (currentSubjectIndex < questionsData.length - 1) {
        const nextSubject = questionsData[currentSubjectIndex + 1];
        setSelectedSubject(nextSubject.SubjectName);
        setCurrentSectionIndex(0);
        setCurrentQuestionIndex(0);
      }
    }
  };

  const previousQuestion = () => {
    if (currentQuestionIndex > 0) {
      moveToQuestion(currentSectionIndex, currentQuestionIndex - 1);
    } else {
      const currentSubjectIndex = questionsData.findIndex(
        (subject) => subject.SubjectName === selectedSubject
      );
      const prevSectionIndex = currentSectionIndex - 1;
      if (prevSectionIndex >= 0) {
        setCurrentSectionIndex(prevSectionIndex);
        const prevSection = currentSubject.sections[prevSectionIndex];
        setCurrentQuestionIndex(prevSection.questions.length - 1);
      } else if (currentSubjectIndex > 0) {
        const prevSubject = questionsData[currentSubjectIndex - 1];
        setSelectedSubject(prevSubject.SubjectName);
        const lastSectionIndex = prevSubject.sections.length - 1;
        setCurrentSectionIndex(lastSectionIndex);
        setCurrentQuestionIndex(
          prevSubject.sections[lastSectionIndex].questions.length - 1
        );
      }
    }
  };

  //with natd range
  // const saveAndNext = () => {
  //   // Retrieve current question details
  //   const currentSection = currentSubject.sections[currentSectionIndex];
  //   const key = getQuestionKey(
  //     selectedSubject,
  //     currentSectionIndex,
  //     currentQuestion.question_id
  //   );
  //   const inputValue = getInputValue(
  //     selectedSubject,
  //     currentSectionIndex,
  //     currentQuestion.question_id
  //   )
  //     ?.trim()
  //     .toLowerCase();

  //   // Log key and input value for debugging
  //   // console.log(`Key for question ${currentQuestion.question_id}:`, key);
  //   // console.log(
  //   //   `Retrieved input value for question ${currentQuestion.question_id}:`,
  //   //   inputValue
  //   // );

  //   // Check if a response exists for the current question
  //   if (responses[key] !== undefined || inputValue !== "") {
  //     // Set the question as answered
  //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });

  //     // Extract correct answer and marks from JSON data
  //     const correctAnswer = currentQuestion.answer?.trim().toLowerCase(); // Assuming answer is in lowercase
  //     const marks = currentQuestion.marks;

  //     // console.log("Correct Answer:", correctAnswer);
  //     // console.log("User Response for options selected:", responses[key]);
  //     // console.log("User Response entered value:", inputValue);

  //     // Check if the marks are defined
  //     if (marks) {
  //       const marksArray = marks.split(",");
  //       const correctMark = parseInt(marksArray[0], 10);
  //       const wrongMark = parseInt(marksArray[1], 10);

  //       // Compare user response with correct answer
  //       const isCorrectResponse =
  //         // For Numeric Answer
  //         (!isNaN(inputValue) &&
  //           !isNaN(correctAnswer) &&
  //           parseFloat(inputValue) === parseFloat(correctAnswer)) ||
  //         // For Numeric Answer with Range
  //         (inputValue !== "" &&
  //           correctAnswer.includes("-") &&
  //           inputValue >= parseInt(correctAnswer.split("-")[0]) &&
  //           inputValue <= parseInt(correctAnswer.split("-")[1]));

  //       if (isCorrectResponse) {
  //         // Increment correct answers count and update total marks
  //         // console.log("Response is correct.");
  //         setCorrectAnswersCount((prevCount) => prevCount + 1);
  //         console.log("Response is correct.",correctAnswersCount);
  //         setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
  //         console.log("Marks Added:", correctMark);
  //       } else {
  //         // Increment wrong answers count and subtract marks for incorrect answer
  //         // console.log("Response is incorrect.");
  //         setWrongAnswersCount((prevCount) => prevCount + 1);
  //         console.log("Response is incorrect.",wrongAnswersCount);
  //         setTotalMarks((prevTotalMarks) =>
  //           Math.max(0, prevTotalMarks - wrongMark)
  //         ); // Subtract marks for incorrect answer, ensuring total marks don't go below 0
  //         console.log("Marks Deducted:", wrongMark);
  //       }
  //     }

  //     // Update counts for answered and not answered questions
  //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
  //     if (!answeredQuestions[key]) {
  //       setNotAnsweredCount((prevCount) => prevCount - 1);
  //     }

  //     // Move to the next question if available, else move to the next section or subject
  //     if (currentQuestionIndex < currentSection.questions.length - 1) {
  //       nextQuestion();
  //     } else {
  //       const currentSubjectIndex = questionsData.findIndex(
  //         (subject) => subject.SubjectName === selectedSubject
  //       );
  //       if (currentSubjectIndex < questionsData.length - 1) {
  //         const nextSubject = questionsData[currentSubjectIndex + 1];
  //         setSelectedSubject(nextSubject.SubjectName);
  //         setCurrentSectionIndex(0);
  //         setCurrentQuestionIndex(0);
  //       }
  //     }
  //   } else {
  //     // If the response is undefined, prompt the user to answer the question before proceeding
  //     alert("Please answer the current question before proceeding.");
  //   }
  // };

  const saveAndNext = () => {
    stopTimer(); // Stop the current timer
    setIsTimerRunningquestion(false); // Pause timer to prevent immediate start
    if (activeQuestion < questionsData.length - 1) {
      setActiveQuestion((prev) => prev + 1); // Move to next question
      setIsTimerRunningquestion(true); // Start timer for the new question
    }

    // Retrieve current question details
    const currentSection = currentSubject.sections[currentSectionIndex];
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );
    const inputValue = getInputValue(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    )
      ?.trim()
      .toLowerCase();

    // Log key and input value for debugging
    console.log(`Key for question ${currentQuestion.question_id}:`, key);
    console.log(
      `Retrieved input value for question ${currentQuestion.question_id}:`,
      inputValue
    );

    // Determine if the question was previously answered
    const wasPreviouslyAnswered = awardedMarks[key] !== undefined;
    const wasPreviouslyCorrect = awardedMarks[key] && awardedMarks[key] > 0;
    const previousMarks = awardedMarks[key] || 0;

    // Check if a response exists for the current question
    if (responses[key] !== undefined || inputValue !== "") {
      // Set the question as answered
      setAnsweredQuestions({ ...answeredQuestions, [key]: true });

      // Extract correct answer and marks from JSON data
      const correctAnswer = currentQuestion.answer?.trim().toLowerCase();
      const marks = currentQuestion.marks;

      console.log("Correct Answer:", correctAnswer);
      console.log("User Response for options selected:", responses[key]);
      console.log("User Response entered value:", inputValue);

      if (marks) {
        const marksArray = marks.split(",");
        const correctMark = parseInt(marksArray[0], 10);
        const wrongMark = -1;

        const userResponse =
          responses[key] !== undefined
            ? responses[key].toString().trim().toLowerCase()
            : "";

        console.log("User Response:", userResponse);

        let isCorrectResponse = false;
        let newMarksAwarded = 0;

        if (
          ["MCQ4", "MCQ5", "MSQ", "MSQN", "NATI"].includes(
            currentQuestion.qtype
          )
        ) {
          isCorrectResponse =
            userResponse === correctAnswer ||
            (inputValue !== "" && inputValue === correctAnswer);
          newMarksAwarded = isCorrectResponse ? correctMark : wrongMark;
        } else if (currentQuestion.qtype === "NATD") {
          const [lowerRange, upperRange] = correctAnswer
            .split("-")
            .map((range) => parseFloat(range));
          console.log("Lower Range:", lowerRange);
          console.log("Upper Range:", upperRange);

          const isWithinRange =
            inputValue !== "" &&
            correctAnswer.includes("-") &&
            inputValue >= lowerRange &&
            inputValue <= upperRange;
          console.log("Is Within Range:", isWithinRange);

          newMarksAwarded = isWithinRange ? correctMark : wrongMark;
        }

        console.log("New Marks Awarded:", newMarksAwarded);

        // Update awarded marks for this question
        awardedMarks[key] = newMarksAwarded;

        // Recalculate total marks from scratch, considering only the best 5 marks in Section B
        const newTotalMarks = calculateCorrectTotalMarks();
        console.log("Updated Total Marks:", newTotalMarks);
        setTotalMarks(Math.max(newTotalMarks, 0));
        const calculatedPercentage = calculatePercentage(newTotalMarks);
        setPercentage(calculatedPercentage);
        console.log("Updated Percentage:", calculatedPercentage);
        // Update counts based on correctness of the response
        if (isCorrectResponse) {
          if (!wasPreviouslyCorrect) {
            setRightAnsweredCount((prevCount) => prevCount + 1);
            if (previousMarks < 0)
              setWrongAnsweredCount((prevCount) => prevCount - 1);
          }
        } else {
          if (wasPreviouslyCorrect) {
            setRightAnsweredCount((prevCount) => prevCount - 1);
          }
          setWrongAnsweredCount((prevCount) => prevCount + 1);
        }

        console.log("Updated awardedMarks:", awardedMarks);
      }

      // Update counts for answered questions
      if (!wasPreviouslyAnswered) {
        setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
        setNotAnsweredCount((prevCount) => prevCount - 1);
      }

      // Increment the count for section-B questions answered
      if (currentSectionIndex === 1) {
        setSectionBAnsweredCount((prevCount) => prevCount + 1);
      }

      // Move to the next question if available, else move to the next section or subject
      if (currentQuestionIndex < currentSection.questions.length - 1) {
        nextQuestion();
      } else {
        const currentSubjectIndex = mockTestsData.findIndex(
          (subject) => subject.SubjectName === selectedSubject
        );
        const nextSectionIndex = currentSectionIndex + 1;

        // Check if there is a next section in the current subject
        if (nextSectionIndex < currentSubject.sections.length) {
          // Move to the next section
          setCurrentSectionIndex(nextSectionIndex);
          setCurrentQuestionIndex(0); // Start from the first question in the next section
        } else {
          if (currentSubjectIndex < mockTestsData.length - 1) {
            const nextSubject = mockTestsData[currentSubjectIndex + 1];
            setSelectedSubject(nextSubject.SubjectName);
            setCurrentSectionIndex(0); // Start from the first section of the next subject
            setCurrentQuestionIndex(0); // Start from the first question in the first section
          }
        }
      }
    }
    else {
      // If the response is undefined, prompt the user to answer the question before proceeding
      alert("Please answer the current question before proceeding.");
    }
  };

  const calculateCorrectTotalMarks = () => {
    let totalMarks = 0;

    console.log("Awarded Marks:", awardedMarks); // Debugging line to check contents

    // Calculate total marks for sections other than Section B
    for (const key in awardedMarks) {
      if (!key.includes("-1-")) {
        const mark = awardedMarks[key];
        if (typeof mark === "number" && !isNaN(mark)) {
          totalMarks += mark;
        } else {
          console.warn(`Invalid mark found for key ${key}:`, mark);
        }
      }
    }

    console.log("Total Marks after sections other than B:", totalMarks); // Debugging

    // Calculate total marks for Section B
    const sectionBMarks = [];
    for (const key in awardedMarks) {
      if (key.includes("-1-")) {
        const mark = awardedMarks[key];
        if (typeof mark === "number" && !isNaN(mark)) {
          sectionBMarks.push(mark);
        } else {
          console.warn(`Invalid mark found for key ${key}:`, mark);
        }
      }
    }

    console.log("Section B Marks:", sectionBMarks); // Debugging

    // Sort Section B marks in descending order and take the top 4
    sectionBMarks.sort((a, b) => b - a);
    totalMarks += sectionBMarks
      .slice(0, 4)
      .reduce((acc, mark) => acc + mark, 0);

    console.log("Total Marks after including Section B:", totalMarks); // Debugging

    return totalMarks;
  };

  const calculatePercentage = (totalMarks) => {
    const totalPossibleMarks = 300; // Define the total possible marks
    const percentage = (totalMarks / totalPossibleMarks) * 100; // Calculate the percentage
    return percentage.toFixed(2); // Return percentage rounded to 2 decimal places
  };

  // Use useEffect to update total marks and percentage when needed
  useEffect(() => {
    if (Object.keys(awardedMarks).length > 0) {
      const totalMarksCalculated = calculateCorrectTotalMarks();
      setTotalMarks(totalMarksCalculated); // Update state with total marks

      const calculatedPercentage = calculatePercentage(totalMarksCalculated);
      setPercentage(calculatedPercentage); // Update state with the calculated percentage

      console.log("User's Total Marks:", totalMarksCalculated);
      console.log("User's Percentage:", calculatedPercentage);
    }
  }, [awardedMarks]); // Only run this effect when `awardedMarks` changes

  const goToQuestion = (index) => {
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentSection.questions[index].question_id
    );
    if (
      selectedSubject &&
      currentSectionIndex !== undefined &&
      currentQuestion.question_id
    ) {
      const key = `${selectedSubject}-${currentSectionIndex}-${currentQuestion.question_id}`;
      console.log("Generated Key:", key); // Debugging
    } else {
      console.error("Error generating key: Missing values in key parts.");
    }
    setViewedQuestions({ ...viewedQuestions, [key]: true });
    setCurrentQuestionIndex(index);
  };

  const handleSubjectChange = (subjectName) => {
    setSelectedSubject(subjectName);
    setCurrentSectionIndex(0);
    setCurrentQuestionIndex(0);
  };

  const handleSectionChange = (index) => {
    setCurrentSectionIndex(index);
    setCurrentQuestionIndex(0);
  };

  const calculateCounts = () => {
    let answeredCount = 0;
    let markedForReviewCount = 0;
    let notVisitedCount = 0;

    questionsData.forEach((subject) => {
      subject.sections.forEach((section, sectionIndex) => {
        section.questions.forEach((question) => {
          const key = getQuestionKey(
            subject.SubjectName,
            sectionIndex,
            question.question_id
          );
          if (responses[key] !== undefined) {
            if (responses[key] !== null) {
              answeredCount++;
            } else {
              if (!viewedQuestions[key] && !markedForReview[key]) {
                notVisitedCount++; // If not answered, not marked for review, and not visited yet
              }
            }
          } else {
            if (!viewedQuestions[key] && !markedForReview[key]) {
              notVisitedCount++; // If not answered, not marked for review, and not visited yet
            }
          }
          if (markedForReview[key]) {
            markedForReviewCount++;
          }
        });
      });
    });

    const totalQuestions = questionsData.reduce(
      (acc, subject) =>
        acc +
        subject.sections.reduce((acc, sec) => acc + sec.questions.length, 0),
      0
    );

    const notAnsweredCount =
      totalQuestions - answeredCount - markedForReviewCount - notVisitedCount;

    setCounts({
      answeredCount,
      markedForReviewCount,
      notVisitedCount,
      notAnsweredCount,
    });
  };

  const handleSubmit = () => {
    setIsAutoSubmit(false);
    setShowPopupscore(true);
  };

  const handlebuttoNo = () => {
    setShowPopup(false);
    setIsTimerRunning(true);
  };

  const handlebuttoYes = () => {
    setShowPopup(true);
    setShowPopupscore(false);
    setIsTimerRunning(false);
  };

  const closePopup = () => {
    setShowPopup(false);
    setShowPopupscore(false);
    window.location.href = "/bitsatots";
    window.close();
  };

  const currentSubject = questionsData.find(
    (subject) => subject.SubjectName === selectedSubject
  );
  const currentSection = currentSubject?.sections[currentSectionIndex];
  const currentQuestion = currentSection?.questions[currentQuestionIndex];

  useEffect(() => {
    calculateCounts();
  }, []);

  useEffect(() => {
    // Set the viewed status of the first question of the selected subject to true
    const firstQuestionKey = getQuestionKey(
      selectedSubject,
      0,
      currentSubject.sections[0].questions[0].question_id
    );
    setViewedQuestions({ ...viewedQuestions, [firstQuestionKey]: true });
  }, [selectedSubject]);
  const handleCheckboxChange = (optionIndex) => {
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    const selectedOptions = responses[key] || [];
    const selectedIndex = selectedOptions.indexOf(optionIndex);

    if (selectedIndex === -1) {
      // If option is not selected, add it to the array
      setResponses({
        ...responses,
        [key]: [...selectedOptions, optionIndex],
      });
    } else {
      // If option is already selected, remove it from the array
      selectedOptions.splice(selectedIndex, 1);
      setResponses({
        ...responses,
        [key]: selectedOptions,
      });
    }
  };
  const Title = () => {
    if (mockTestId === 101) {
      return (
        <h4>
          <b>JEE Mains Mock Test 1</b>
        </h4>
      );
    } else if (mockTestId === 102) {
      return (
        <h4>
          <b>JEE Mains Mock Test 2</b>
        </h4>
      );
    } else if (mockTestId === 103) {
      return (
        <h4>
          <b>JEE Mains Mock Test 3</b>
        </h4>
      );
    } else {
      return <h4>{/* <b>Mock Test</b> */}</h4>;
    }
  };

  const pieData = {
    labels: ["Correct Answers", "Wrong Answers"],
    datasets: [
      {
        label: "Score Distribution",
        data: [rightAnsweredCount, wrongAnsweredCount],
        backgroundColor: ["#4CAF50", "#F44336"], // Colors for correct and wrong answers
        borderColor: ["#4CAF50", "#F44336"],
        borderWidth: 1,
      },
    ],
  };
  // Pie Chart Options Configuration
  const pieOptions = {
    plugins: {
      datalabels: {
        color: "#fff", // Data label color
        formatter: (value, context) => {
          const label = context.chart.data.labels[context.dataIndex];
          return `${label}: ${value}`; // Display label and value
        },
      },
      legend: {
        position: "top",
        labels: {
          generateLabels: (chart) => {
            return chart.data.datasets[0].data.map((value, index) => ({
              text: `${chart.data.labels[index]}: ${value}`, // Label with data value
              fillStyle: chart.data.datasets[0].backgroundColor[index],
              hidden: !chart.data.datasets[0].data[index],
              index,
            }));
          },
        },
      },
    },
  };

  // Calculate time in HH:MM:SS format
  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const data = questionTimes.map((time, index) => ({
    name: `Q${index + 1}`,
    Time: time,
  }));
  const [showPerformance, setShowPerformance] = useState(true);
  const handleShowPerformance = () => {
    setShowPerformance(true);
  };

  const handleShowSolutions = () => {
    setShowPerformance(false);
  };

  const [isDownloading, setIsDownloading] = useState(false);
  const downloadPDF = () => {
    setIsDownloading(true); // Disable button and hide icon

    const element = document.querySelector('.popup-contentscore');
    const options = {
      margin: 1,
      filename: 'Test-report.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    // Hide download button before generating PDF
    const downloadButton = document.querySelector('.MockTest_result_Report_download_btn');
    if (downloadButton) {
      downloadButton.style.display = 'none';
    }

    html2pdf().from(element).set(options).save().then(() => {
      setIsDownloading(false); // Re-enable button
      if (downloadButton) {
        downloadButton.style.display = 'block'; // Show button again
      }
    }).catch(() => {
      setIsDownloading(false); // Re-enable button if error occurs
      if (downloadButton) {
        downloadButton.style.display = 'block'; // Show button again
      }
    });
  };

  return (
    <div>
      <div className="mocktestparent_conatiner">
        {/* -------------------header------- */}
        <div>
          <div className="remaining">
            <div className="bitsatMockHeader">
              <img
                src="https://www.egradtutor.in/static/media/egate%20logo%201.8b1eb31347d60945925f.png"
                alt=""
              />
            </div>

            <div className="JEE_MAINS_MOCKTEST_HEADING">
              <h4>{currentSection.Test_Name}</h4>
            </div>

            {/* <h4>{Jee_mains_mocktest1.Title}</h4> */}
          </div>
        </div>
        {/* -------------------interface------- */}
        <div>
          <div className="mocktest_conatiner_testwithpallet">
            <div className="mocktest_conatiner_testwithpalletquizcontainer quizPagewatermark">
              <div className="mocktest_conatiner_testwithpalletquizcontainersub ">
                {/* -------------------sectionbtns------- */}
                <div className="sectionbtnmock">
                  <div className="Jee_Mains_Exam_Subject_names_container">
                    {questionsData.map((subject) => (
                      <button
                        key={subject.SubjectName}
                        onClick={() => handleSubjectChange(subject.SubjectName)}
                        className={
                          selectedSubject === subject.SubjectName
                            ? "active"
                            : ""
                        }
                      >
                        {subject.SubjectName}
                      </button>
                    ))}
                  </div>
                </div>

                <div className="sectionbtnmock_jee">
                  {selectedSubject && (
                    <div className="section-selection">
                      {currentSubject.sections.map((section, index) => (
                        <button
                          key={section.SectionName}
                          onClick={() => handleSectionChange(index)}
                          className={
                            currentSectionIndex === index ? "active" : ""
                          }
                        >
                          {section.SectionName}
                        </button>
                      ))}
                    </div>
                  )}





                </div>
                <div className="Jee_mains_exam_Q.s_Type_container">
                  <div>
                    <p>Question Type: {currentQuestion.qtype}</p>
                  </div>
                  <div className="Jee_Mains_Timing_container">
                    <p className="Jee_Mains_Timing_paragraph">
                      <LuAlarmClock /> Time Left: {formatTime(remainingTime)}
                      {/* {`${Math.floor(remainingTime / 3600)}:${Math.floor(
                        (remainingTime % 3600) / 60
                      )}:${remainingTime % 60}`} */}
                    </p>
                  </div>
                </div>
                <div className="Jee_mains_exam_questions_btn_container">
                  <div className="mocktest_conatiner_testwithpalletquizcontainersubintopart">
                    {selectedSubject && currentQuestion && (
                      <div className="Jee_mains_Questions&_options_container">
                        <p>Question {currentQuestion.question_id}:</p>
                        <img src={currentQuestion.questionImgName} alt="" />

                        <div className="optionimginmock">
                          {(currentQuestion.qtype === "MCQ4" ||
                            currentQuestion.qtype === "MCQ5") && (
                            <div className="optionimginmock">
                              {/* <p>Options:</p> */}
                              {currentQuestion.options.map((option) => (
                                <label
                                  key={option.option_id}
                                  className="option"
                                >
                                  <input
                                    type="radio"
                                    id={`option-${option.option_id}`}
                                    name={`question-${currentQuestion.question_id}`}
                                    checked={
                                      responses[
                                        getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        )
                                      ] === option.option_index
                                    }
                                    onChange={() =>
                                      handleOptionChange(option.option_index)
                                    }
                                  />
                                  <span htmlFor={`option-${option.option_id}`}>
                                    ({option.option_index})
                                  </span>
                                  <img src={option.optionImgName} alt="" />
                                </label>
                              ))}
                            </div>
                          )}
                          {(currentQuestion.qtype === "MSQ" ||
                            currentQuestion.qtype === "MSQN") && (
                            <div className="optionimginmock">
                              {currentQuestion.options.map((option) => (
                                <label
                                  key={option.option_id}
                                  className="option"
                                >
                                  <input
                                    type="checkbox"
                                    id={`option-${option.option_id}`}
                                    checked={
                                      responses[
                                        getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        )
                                      ] &&
                                      responses[
                                        getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        )
                                      ].includes(option.option_index)
                                    }
                                    onChange={() =>
                                      handleCheckboxChange(option.option_index)
                                    }
                                  />
                                  <span htmlFor={`option-${option.option_id}`}>
                                    ({option.option_index})
                                  </span>
                                  <img src={option.optionImgName} alt="" />
                                </label>
                              ))}
                            </div>
                          )}

                          {currentQuestion.qtype === "NATI" && (
                            <div className="calculator">
                              <div className="display">
                                <input
                                  type="text"
                                  value={getInputValue(
                                    selectedSubject,
                                    currentSectionIndex,
                                    currentQuestion.question_id
                                  )}
                                  readOnly // Make the input read-only to prevent direct editing
                                  placeholder="Enter your answer NATI"
                                />
                              </div>

                              {/* Button for DEL */}
                              <div>
                                <input
                                  type="button"
                                  value="DEL"
                                  onClick={() => {
                                    const key = getQuestionKey(
                                      selectedSubject,
                                      currentSectionIndex,
                                      currentQuestion.question_id
                                    );
                                    const currentValue =
                                      getInputValue(
                                        currentQuestion.question_id
                                      ) || "";
                                    updateInputValue(
                                      selectedSubject,
                                      currentSectionIndex,
                                      currentQuestion.question_id,
                                      (getInputValue(
                                        currentQuestion.question_id
                                      ) || "") + "DEL"
                                    );
                                  }}
                                />
                              </div>

                              {/* Buttons for numbers */}
                              <div>
                                <div>
                                  {[7, 8, 9].map((num) => (
                                    <input
                                      key={num}
                                      type="button"
                                      value={num}
                                      onClick={() => {
                                        const key = getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        );
                                        const currentValue =
                                          getInputValue(
                                            currentQuestion.question_id
                                          ) || "";
                                        updateInputValue(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id,
                                          (getInputValue(
                                            currentQuestion.question_id
                                          ) || "") + num
                                        );
                                      }}
                                    />
                                  ))}
                                </div>
                                <div>
                                  {[4, 5, 6].map((num) => (
                                    <input
                                      key={num}
                                      type="button"
                                      value={num}
                                      onClick={() => {
                                        const key = getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        );
                                        const currentValue =
                                          getInputValue(
                                            currentQuestion.question_id
                                          ) || "";
                                        updateInputValue(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id,
                                          (getInputValue(
                                            currentQuestion.question_id
                                          ) || "") + num
                                        );
                                      }}
                                    />
                                  ))}
                                </div>
                                <div>
                                  {[1, 2, 3].map((num) => (
                                    <input
                                      key={num}
                                      type="button"
                                      value={num}
                                      onClick={() => {
                                        const key = getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        );
                                        const currentValue =
                                          getInputValue(
                                            currentQuestion.question_id
                                          ) || "";
                                        updateInputValue(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id,
                                          (getInputValue(
                                            currentQuestion.question_id
                                          ) || "") + num
                                        );
                                      }}
                                    />
                                  ))}
                                </div>
                                <div>
                                  {[0].map((num) => (
                                    <input
                                      key={num}
                                      type="button"
                                      value={num}
                                      onClick={() => {
                                        const key = getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        );
                                        const currentValue =
                                          getInputValue(
                                            currentQuestion.question_id
                                          ) || "";
                                        updateInputValue(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id,
                                          (getInputValue(
                                            currentQuestion.question_id
                                          ) || "") + num
                                        );
                                      }}
                                    />
                                  ))}
                                  <input
                                    type="button"
                                    value="."
                                    onClick={() => {
                                      const key = getQuestionKey(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id
                                      );
                                      const currentValue =
                                        getInputValue(
                                          currentQuestion.question_id
                                        ) || "";
                                      updateInputValue(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id,
                                        (getInputValue(
                                          currentQuestion.question_id
                                        ) || "") + "."
                                      );
                                    }}
                                  />
                                  <input
                                    type="button"
                                    value="-"
                                    onClick={() => {
                                      const key = getQuestionKey(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id
                                      );
                                      const currentValue =
                                        getInputValue(
                                          currentQuestion.question_id
                                        ) || "";
                                      updateInputValue(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id,
                                        (getInputValue(
                                          currentQuestion.question_id
                                        ) || "") + "-"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {currentQuestion.qtype === "NATD" && (
                            <div className="calculator">
                              <div className="display">
                                <input
                                  type="text"
                                  value={getInputValue(
                                    selectedSubject,
                                    currentSectionIndex,
                                    currentQuestion.question_id
                                  )}
                                  readOnly // Make the input read-only to prevent direct editing
                                  placeholder="Enter your answer NATD"
                                />
                              </div>

                              {/* Button for DEL */}
                              <div>
                                <input
                                  type="button"
                                  value="DEL"
                                  onClick={() => {
                                    const key = getQuestionKey(
                                      selectedSubject,
                                      currentSectionIndex,
                                      currentQuestion.question_id
                                    );
                                    const currentValue =
                                      getInputValue(
                                        currentQuestion.question_id
                                      ) || "";
                                    updateInputValue(
                                      selectedSubject,
                                      currentSectionIndex,
                                      currentQuestion.question_id,
                                      currentValue.slice(0, -1)
                                    );
                                  }}
                                />
                              </div>

                              {/* Buttons for numbers */}
                              <div>
                                <div>
                                  {[7, 8, 9].map((num) => (
                                    <input
                                      key={num}
                                      type="button"
                                      value={num}
                                      onClick={() => {
                                        const key = getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        );
                                        const currentValue =
                                          getInputValue(
                                            currentQuestion.question_id
                                          ) || "";
                                        updateInputValue(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id,
                                          (getInputValue(
                                            currentQuestion.question_id
                                          ) || "") + num
                                        );
                                      }}
                                    />
                                  ))}
                                </div>
                                <div>
                                  {[4, 5, 6].map((num) => (
                                    <input
                                      key={num}
                                      type="button"
                                      value={num}
                                      onClick={() => {
                                        const key = getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        );
                                        const currentValue =
                                          getInputValue(
                                            currentQuestion.question_id
                                          ) || "";
                                        updateInputValue(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id,
                                          (getInputValue(
                                            currentQuestion.question_id
                                          ) || "") + num
                                        );
                                      }}
                                    />
                                  ))}
                                </div>
                                <div>
                                  {[1, 2, 3].map((num) => (
                                    <input
                                      key={num}
                                      type="button"
                                      value={num}
                                      onClick={() => {
                                        const key = getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        );
                                        const currentValue =
                                          getInputValue(
                                            currentQuestion.question_id
                                          ) || "";
                                        updateInputValue(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id,
                                          (getInputValue(
                                            currentQuestion.question_id
                                          ) || "") + num
                                        );
                                      }}
                                    />
                                  ))}
                                </div>
                                <div>
                                  {[0].map((num) => (
                                    <input
                                      key={num}
                                      type="button"
                                      value={num}
                                      onClick={() => {
                                        const key = getQuestionKey(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id
                                        );
                                        const currentValue =
                                          getInputValue(
                                            currentQuestion.question_id
                                          ) || "";
                                        updateInputValue(
                                          selectedSubject,
                                          currentSectionIndex,
                                          currentQuestion.question_id,
                                          (getInputValue(
                                            currentQuestion.question_id
                                          ) || "") + num
                                        );
                                      }}
                                    />
                                  ))}
                                  <input
                                    type="button"
                                    value="."
                                    onClick={() => {
                                      const key = getQuestionKey(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id
                                      );
                                      const currentValue =
                                        getInputValue(
                                          currentQuestion.question_id
                                        ) || "";
                                      updateInputValue(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id,
                                        (getInputValue(
                                          currentQuestion.question_id
                                        ) || "") + "."
                                      );
                                    }}
                                  />
                                  <input
                                    type="button"
                                    value="-"
                                    onClick={() => {
                                      const key = getQuestionKey(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id
                                      );
                                      const currentValue =
                                        getInputValue(
                                          currentQuestion.question_id
                                        ) || "";
                                      updateInputValue(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id,
                                        (getInputValue(
                                          currentQuestion.question_id
                                        ) || "") + "-"
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              {/* Buttons for '.' and '-' */}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  {/* -------------------------------------------buttons Option container-------------- */}
                  <div>
                    <div class="mocktest_conatiner_testwithpalletbuttons">
                      {" "}
                      <div>
                        <button
                          class="Quiz_mockbtns Quiz_mockbtnssubmit"
                          onClick={nextQuestion}
                        >
                          Next
                        </button>
                        <button
                          class="Quiz_clearResponse Quiz_mockbtns Quiz_mockbtnsclear"
                          onClick={clearResponse}
                        >
                          Clear Response
                        </button>
                        {/* <button onClick={markForReview}>Mark For Review</button> */}
                        <button
                          class="quizsave_next previous_next_btn Quiz_mockbtns"
                          onClick={saveAndNext}
                        >
                          Save &amp; Next
                        </button>
                      </div>
                      <div>
                        <button
                          class="Quiz_mockbtns Quiz_mockbtnspre"
                          onClick={previousQuestion}
                        >
                          Previous
                        </button>

                        <button
                          class="Quiz_mockbtns Quiz_mockbtnssubmit"
                          onClick={handlebuttoYes}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="numberpalette">
                <div class="rightSidebar-topHeader">
                  <img
                    title="Guest"
                    src="/static/media/guesticon.00ad06aaa8a7af8eb6c5.jpg"
                    alt="guesticon"
                  />
                  <p className="Current_test_name">Candidate Name: Guest</p>
                  <p className="Current_test_name">
                    {currentSection.Test_Name}
                  </p>
                </div>
                <div className="yoursection_inMockTest">
                  <span>
                    You are viewing{" "}
                    <label htmlFor="">
                      {currentSubject.SubjectName} -{" "}
                      {currentSection.SectionName}
                    </label>
                    {/* <label htmlFor="">{currentSection.SectionName}</label> */}
                  </span>

                  <b className="yoursection_inMockTest_btag">
                    Question Palette
                  </b>
                </div>
                <div className="question-number-palette question-numberpalette question-palette">
                  {currentSection.questions.map((question, index) => {
                    const key = getQuestionKey(
                      selectedSubject,
                      currentSectionIndex,
                      question.question_id
                    );
                    return (
                      <button
                        key={question.question_id}
                        className={`
                    ${index === currentQuestionIndex ? "active" : ""}
                    ${
                      answeredQuestions[key] && !markedForReview[key]
                        ? "answered"
                        : ""
                    }
                    ${viewedQuestions[key] && !responses[key] ? "viewed" : ""}
                    ${
                      markedForReview[key]
                        ? responses[key]
                          ? "marked-answered"
                          : "marked"
                        : ""
                    }
                    ${
                      responses[key] !== undefined && !answeredQuestions[key]
                        ? "answered-red active"
                        : ""
                    }
                  `}
                        onClick={() => goToQuestion(index)}
                      >
                        {index + 1}
                      </button>
                    );
                  })}
                </div>
                <div className="sidebar-footer">
                  <h4 className="sidebar-footer-header">Legend:</h4>
                  <div className="footer-btns">
                    <div className="inst-btns">
                      {/* <img src={grayBox} alt="Not Visited" /> */}
                      <span className="NotVisited">
                        {counts.notVisitedCount}
                      </span>

                      <label htmlFor="">Not Visited</label>
                    </div>
                    <div className="inst-btns">
                      {/* <img src={greenBox} alt="Answered" /> */}
                      <span className="Answered">{answeredCount}</span>
                      <label htmlFor="">Answered</label>
                    </div>
                    <div className="inst-btns">
                      {/* <img src={orangeBox} alt="Not Answered" /> */}
                      <span className="NotAnsweredlegend">
                        {" "}
                        {notAnsweredCount}
                      </span>
                      <label htmlFor="">Not Answered</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="scorecardforexam">
        {showPopup && (
          <div className="popup2">
            <div className="popup-content">
              {remainingTime === 0 ? (
                <div>
                  <p>Your Time is up!</p>
                  <p>Your test is automatically submitted successfully.</p>
                </div>
              ) : (
                <div>
                  <p>The Actual Test time is of 180 mins.</p>
                  <p>Do you want to Continue submiting the test</p>
                </div>
              )}
              <ul class="allscorecountingmock">
                <li>
                  <b>Answered:</b> {answeredCount}
                </li>
                <li>
                  <b>Not Answered:</b> {notAnsweredCount}
                </li>
                <li>
                  <b>Not Visited:</b> {counts.notVisitedCount}
                </li>
              </ul>
              <div>
                <button onClick={handleSubmit}>Yes</button>
                <button onClick={handlebuttoNo}>No</button>
              </div>
            </div>
          </div>
        )}
        <div>
          {showPopupscore && (
            <div className="popup2">
              <div className="popup-contentscore">
                <div className="popup_header">
                  <div className="remaining">
                    <div className="popHeaderLogo">
                      <img
                        src="https://www.egradtutor.in/static/media/egate%20logo%201.8b1eb31347d60945925f.png"
                        alt="Logo"
                      />
                    </div>

                    <div className="JEE_MAINS_MOCKTEST_HEADING">
                      <h4>{currentSection.Test_Name}</h4>
                    </div>
                  </div>
                </div>
                <div className="mocktestdatadiv">
                  <div className="mocktestdataheading">Test Details</div>
                  <div className="mocktestdatasubdiv">
                    {" "}
                    <div>Test Name:{currentSection.Test_Name}</div>
                    <div>Subjects:{questionsData[0].SubjectName}</div>
                    <div>Total Time: 180 mins</div>
                    <div>Total Questions: 90</div>
                    <div>Total Marks: 300</div>
                  </div>
                </div>
                {/* Add buttons for Performance and Solutions */}
                <div>
                <div className="button-container">
                  <button onClick={handleShowPerformance}>
                    Your Performance
                  </button>
                  <button onClick={handleShowSolutions}>Solutions</button>
                </div>

                <div>
                  {showPerformance ? (
                    <div className="Chart_container">
                      <div className="Congratulationsdiv">
                        {/* Download Button */}
          <div className="download-button">
          <button
        className="MockTest_result_Report_download_btn"
        onClick={downloadPDF}
        disabled={isDownloading}
      >
        <FaDownload />
        {/* {isDownloading ? 'Generating Report...' : 'Download Report'} */}
      </button>
          </div>
                        <div className="Congratulationssubdiv">
                          <div>
                            <img
                              src="/static/media/prize.e48700d2bb773b1eda58.jpg"
                              alt="Congratulations"
                            />
                          </div>
                          <div>
                            <h2>Congratulations!</h2>
                            <span className="mockscore">
                              Your score: {totalMarks}
                            </span>
                          </div>
                        </div>

                        {/* Horizontal bar to display spent time and remaining time */}
                        <div className="Congratulationssubdiv CongratulationsTimeSpent">
                          <p>Time Spent</p>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              marginTop: "20px",
                            }}
                          >
                            {/* Display Total Time, Time Spent, and Remaining Time */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p>Total Time: {totalTime} mins</p>
                              <p>Time Spent: {spentTime} mins</p>
                              <p>Remaining Time: {remainingTimedisplay} mins</p>
                            </div>

                            {/* Horizontal Bar representing time spent and remaining time */}
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                height: "30px",
                                marginTop: "10px",
                              }}
                            >
                              <div
                                style={{
                                  width: `${(spentTime / totalTime) * 100}%`,
                                  backgroundColor: "red",
                                }}
                              ></div>
                              <div
                                style={{
                                  width: `${
                                    (remainingTime / totalTime) * 100
                                  }%`,
                                  backgroundColor: "blue",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="Chart_container1">
                        {/* Display the Pie Chart */}
                        <div style={{ width: "300px", margin: "auto" }}>
                          <Pie data={pieData} options={pieOptions} />
                        </div>
                        <div style={{ width: "200px", margin: "0 auto" }}>
                          <CircularProgressbar
                            value={percentage}
                            text={`${percentage}%`}
                            styles={buildStyles({
                              pathColor: `rgba(255, 0, 0, ${
                                (percentage / 100) * 0.5 + 0.5
                              })`, // Red color
                              textColor: "#007bff", // Blue color for text
                              trailColor: "#ffefef", // Light red color for trail
                              backgroundColor: "#ffffff",
                            })}
                          />
                          <p style={{ textAlign: "center", marginTop: "10px" }}>
                            Your Total Percentage
                          </p>
                        </div>
                      </div>

                      {/* Bar Chart to display time spent on each question */}
                      <ResponsiveContainer width="50%" height={300}>
                        <BarChart
                          data={data}
                          margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="Time" fill="#8884d8" barSize={20} />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  ) : (
                    <div className="mocktestsolution">
                      {questionsData[0].sections.map((section) => (
                        <div key={section.SectionName}>
                          <h2>{section.SectionName}</h2>
                          <h3>{section.Title}</h3>
                          {section.questions.map((question) => (
                            <div
                              key={question.question_id}
                              style={{ marginBottom: "20px" }}
                            >
                              <p>
                                <strong>Q{question.question_id}: </strong>
                                <img src={question.questionImgName} alt="" />
                              </p>
                              <ul>
                                {question.options.map((option) => (
                                  <li key={option.option_id}>
                                    {option.option_index}.{" "}
                                    <img src={option.optionImgName} alt="" />
                                  </li>
                                ))}
                              </ul>
                              <button
                                onClick={() =>
                                  handleShowSolution(question.solution)
                                }
                              >
                                Show Solution
                              </button>
                              {showSolution &&
                                currentSolution === question.solution && (
                                  <div>
                                    <p>
                                      <strong>Solution:</strong>{" "}
                                      <img src={question.solution} alt="" />
                                    </p>
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                </div>
                <button onClick={closePopup}>Close</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default JeeAdvanceMocktest;
