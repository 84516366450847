import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function RazorpayForm() {
    const [formData, setFormData] = useState({
      name: '',
      contactNumber: '',
      collegeName: '',
      postalAddress: '',
    });
  
    const navigate = useNavigate(); // Initialize the useNavigate hook
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      // Log the form data
      console.log('Form Data:', formData);
  
      // Redirect to the Razorpay payment page
      window.location.href = 'https://rzp.io/l/IXwlc5DnlX';
    };
  return (
    <div className='razorpay-form'>
      <h2>Razorpay Form</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>

        <div>
          <label htmlFor="contactNumber">Contact Number:</label>
          <input
            type="text"
            id="contactNumber"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleInputChange}
            required
          />
        </div>

        <div>
          <label htmlFor="collegeName">College Name:</label>
          <input
            type="text"
            id="collegeName"
            name="collegeName"
            value={formData.collegeName}
            onChange={handleInputChange}
            required
          />
        </div>

        <div>
          <label htmlFor="postalAddress">Postal Address:</label>
          <input
            type="text"
            id="postalAddress"
            name="postalAddress"
            value={formData.postalAddress}
            onChange={handleInputChange}
            required
          />
        </div>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default RazorpayForm;
