import React from 'react'
import { MdDownload } from "react-icons/md";



const GateSyllabus = () => {
    return (
        <div>
            <table className='examtbl'>
                <tr>
                    <th>DEPARTMENT</th>
                    <th>URL LINKS</th>
                </tr>
                <tr>
                    <td>AEROSPACE ENGINEERING(AE)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_AE_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Agricultural Engineering(AG)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_AG_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Architecture and Planning(AR)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_AR_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Biomedical Engineering(BM)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_BM_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Biotechnology(BT)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_BT_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Civil Engineering(CE)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_CE_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Chemical Engineering(CH)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_CH_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Computer Science & Information Technology(CS)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_CS_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Chemistry(CY)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_CY_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Data Science & Artificial Intelligence(DA)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_DA_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Electronics & Communication Engineering(EC)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_EC_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Electrical Engineering(EE)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_EE_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Environmental Science & Engineering(ES)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_ES_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Ecology and Evolution(EY)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_EY_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Geomatics Engineering(GE)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_GE_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Geology & Geophysics(GG)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_GG_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Instrumentation Engineering(IN)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_IN_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Mathematics(MA)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_MA_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Mechanical Engineering(ME)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_ME_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Mining Engineering(MN)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_MN_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Metallurgical Engineering(MT)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_MT_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Naval Architecture & Marine Engineering(NM)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_NM_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Petroleum Engineering(PE)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_PE_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Physics(PH)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_PH_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Production & Industrial Engineering(PI)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_PI_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Statistics(ST)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_ST_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Textile Engineering & Fibre Science(TF)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_TF_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Engineering Sciences(XE)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_XE_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Humanities & Social Sciences(XH)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_XH_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
                <tr>
                    <td>Life Sciences(XL)</td>
                    <td><a className='SYLLABUS_pdf' href="https://gate2025.iitr.ac.in/doc/2025/GATE%20_XL_2025_Syllabus.pdf" target='_blank'>Syllabus Information  <span> <MdDownload /></span></a></td>
                </tr>
            </table>
        </div>
    )
}

export default GateSyllabus