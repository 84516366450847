import React from 'react'
import { MdDownload } from "react-icons/md";
function Met_Syllabus() {
  return (
    <div> 
       <b>
    <a className='SYLLABUS_pdf' href= "https://www.manipal.edu/content/dam/manipal/mu/documents/Admissions/adm2024/btech_met_syllabus_2024_v1.pdf" target='_blank'>MET Syllabus <span> <MdDownload /> </span></a>
  </b></div>
  )
}

export default Met_Syllabus