
// // import React, { useState, useEffect } from "react";
// // import "./Jee_advance.css";
// // import JEEquestionsData from "./Jee_advance.json";


// // function Jee_advance_1() {
// //   // const [value, setValue] = useState("");
// //   const [selectedSubject, setSelectedSubject] = useState(
// //     JEEquestionsData.length > 0 ? JEEquestionsData[0].SubjectName : null
// //   );
// //   const [inputValues, setInputValues] = useState({});

// //   const updateInputValue = (
// //     selectedSubject,
// //     currentSectionIndex,
// //     questionId,
// //     num
// //   ) => {
// //     const key = getQuestionKey(
// //       selectedSubject,
// //       currentSectionIndex,
// //       questionId
// //     );
// //     const currentValue = getInputValue(
// //       selectedSubject,
// //       currentSectionIndex,
// //       questionId
// //     );

// //     let newValue = currentValue; // Initialize newValue with currentValue

// //     // If 'DEL' button is clicked and current value is not empty, remove the last character
// //     if (num === "DEL" && currentValue.length > 0) {
// //       newValue = currentValue.slice(0, -1);
// //     } else if (num !== "DEL") {
// //       // Only concatenate num if it's not "DEL"
// //       // Concatenate the new number to the current value for other cases
// //       newValue += num;
// //     }

// //     // Update input values with the new value
// //     setInputValues((prevInputValues) => ({
// //       ...prevInputValues,
// //       [key]: newValue,
// //     }));
// //   };

// //   const getInputValue = (selectedSubject, currentSectionIndex, questionId) => {
// //     // Get the key using the provided parameters
// //     const key = getQuestionKey(
// //       selectedSubject,
// //       currentSectionIndex,
// //       questionId
// //     );
// //     // console.log("Getting input value for key:", key); // <-- Move this line here

// //     // Retrieve the input value for the specified key from the inputValues state
// //     return inputValues[key] || "";
// //   };

// //   const moveToNextQuestion = (
// //     selectedSubject,
// //     currentSectionIndex,
// //     questionId
// //   ) => {
// //     // Clear/reset the input value when moving to the next question
// //     setInputValues({
// //       ...inputValues,
// //       [questionId]: "",
// //       [currentSectionIndex]: "",
// //       [selectedSubject]: "",
// //     });
// //   };

// //   const returnToPreviousQuestion = (
// //     selectedSubject,
// //     currentSectionIndex,
// //     questionId
// //   ) => {
// //     // Find the index of the current question in the section
// //     const sectionIndex = JEEquestionsData.findIndex(
// //       (subject) => subject.SubjectName === selectedSubject
// //     );
// //     const section = JEEquestionsData[sectionIndex];

// //     const questionIndex = section.questions.findIndex(
// //       (question) => question.question_id === questionId
// //     );

// //     if (questionIndex > 0) {
// //       // If the current question is not the first question in the section
// //       // Navigate to the previous question
// //       const previousQuestion = section.questions[questionIndex - 1];
// //       // Update the input value with the previous question's value
// //       setInputValues(
// //         getInputValue(
// //           selectedSubject,
// //           currentSectionIndex,
// //           previousQuestion.question_id
// //         )
// //       );
// //     } else {
// //       // If the current question is the first question in the section,
// //       // Navigate to the last question of the previous section
// //       const previousSectionIndex = sectionIndex - 1;
// //       if (previousSectionIndex >= 0) {
// //         const previousSection = JEEquestionsData[previousSectionIndex];
// //         const lastQuestionIndex = previousSection.questions.length - 1;
// //         const lastQuestion = previousSection.questions[lastQuestionIndex];
// //         // Update the input value with the last question's value of the previous section
// //         setInputValues(
// //           getInputValue(
// //             selectedSubject,
// //             previousSectionIndex,
// //             lastQuestion.question_id
// //           )
// //         );
// //       } else {
// //         // If there is no previous section, do nothing
// //         console.log(
// //           "This is the first question, there is no previous question."
// //         );
// //       }
// //     }
// //   };

// //   const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
// //   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
// //   const [responses, setResponses] = useState({});
// //   const [viewedQuestions, setViewedQuestions] = useState({});
// //   const [answeredQuestions, setAnsweredQuestions] = useState({});
// //   const [markedForReview, setMarkedForReview] = useState({});
// //   const [showPopup, setShowPopup] = useState(false);
// //   const [remainingTime, setRemainingTime] = useState(3 * 60 * 60);
// //   const [isAutoSubmit, setIsAutoSubmit] = useState(false);
// //   const getQuestionKey = (subject, sectionIndex, questionId, inputValue) => {
// //     return `${subject}-${sectionIndex}-${questionId}-${inputValue}`;
// //   };

// //   const [totalMarks, setTotalMarks] = useState(0);
// //   const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
// //   const [wrongAnswersCount, setWrongAnswersCount] = useState(0);
// //   const [answeredCount, setAnsweredCount] = useState(0);
// //   const [notAnsweredCount, setNotAnsweredCount] = useState(0);
// //   useEffect(() => {
// //     let count = 0;
// //     JEEquestionsData.forEach((subject) => {
// //       subject.sections.forEach((section) => {
// //         count += section.questions.length;
// //       });
// //     });
// //     setNotAnsweredCount(count);
// //   }, []);
// //   const handleOptionChange = (option) => {
// //     console.log("Selected option:", option);
// //     if (currentQuestion) {
// //       const key = getQuestionKey(
// //         selectedSubject,
// //         currentSectionIndex,
// //         currentQuestion.question_id
// //       );
// //       console.log("Key:", key);
// //       setResponses({ ...responses, [key]: option });
// //     }
// //   };

// //   useEffect(() => {
// //     const timer = setInterval(() => {
// //       setRemainingTime((prevTime) => {
// //         if (prevTime <= 0) {
// //           clearInterval(timer);
// //           return 0;
// //         }
// //         return prevTime - 1;
// //       });
// //     }, 1000);

// //     // Cleanup the interval on component unmount
// //     return () => clearInterval(timer);
// //   }, []);
// //   useEffect(() => {
// //     if (remainingTime === 0) {
// //       setIsAutoSubmit(true);
// //       setShowPopup(true);
// //       // handleAutoSubmit();
// //     }
// //   }, [remainingTime]);

// //   // Function to clear response for the current question
// //   const clearResponse = () => {
// //     // Construct the key for the current question
// //     const key = getQuestionKey(
// //       selectedSubject,
// //       currentSectionIndex,
// //       currentQuestion.question_id
// //     );

// //     // Get the current input value
// //     const valueToClear = getInputValue(
// //       selectedSubject,
// //       currentSectionIndex,
// //       currentQuestion.question_id
// //     );

// //     // Log the current value before clearing it
// //     console.log("Value before clearing:", valueToClear);

// //     // Call updateInputValue to clear the input value
// //     updateInputValue(
// //       selectedSubject,
// //       currentSectionIndex,
// //       currentQuestion.question_id,
// //       "" // Pass an empty string to clear the input value
// //     );

// //     // Log the updated input values after clearing
// //     console.log("Updated input values:", inputValues);

// //     // Other operations in clearResponse function...
// //     // setInputValues({
// //     //   ...inputValues,
// //     //   [currentQuestion.question_id]: "", // Clear input value in state
// //     //   [currentSectionIndex]: "", // Clear other related values if needed
// //     //   [selectedSubject]: "", // Clear other related values if needed
// //     // });
// //     setInputValues({
// //       ...inputValues,
// //       [key]: [], // Clear other related values if needed
// //     });

// //     // Clear response, mark the question as viewed but not answered, and decrement answered count
// //     setResponses({ ...responses, [key]: [] }); // Set response to an empty array

// //     // Mark the question as viewed but not answered, and decrement answered count
// //     setViewedQuestions({ ...viewedQuestions, [key]: true });
// //     setAnsweredQuestions({ ...answeredQuestions, [key]: false });
// //     // setAnsweredCount((prevAnsweredCount) => prevAnsweredCount - 1);
// //     // Decrease the count only if the question was previously answered
// //     if (answeredCount > 0) {
// //       setAnsweredCount((prevAnsweredCount) => prevAnsweredCount - 1);
// //     }
// //   };

// //   const moveToQuestion = (newSectionIndex, newQuestionIndex) => {
// //     const key = getQuestionKey(
// //       selectedSubject,
// //       newSectionIndex,
// //       currentSubject.sections[newSectionIndex].questions[newQuestionIndex]
// //         .question_id
// //     );
// //     setViewedQuestions({ ...viewedQuestions, [key]: true });
// //     setCurrentSectionIndex(newSectionIndex);
// //     setCurrentQuestionIndex(newQuestionIndex);
// //   };

// //   const nextQuestion = () => {
// //     const currentSection = currentSubject.sections[currentSectionIndex];
// //     if (currentQuestionIndex < currentSection.questions.length - 1) {
// //       moveToQuestion(currentSectionIndex, currentQuestionIndex + 1);
// //     } else {
// //       const currentSubjectIndex = JEEquestionsData.findIndex(
// //         (subject) => subject.SubjectName === selectedSubject
// //       );
// //       const nextSectionIndex = currentSectionIndex + 1;
// //       if (nextSectionIndex < currentSubject.sections.length) {
// //         setCurrentSectionIndex(nextSectionIndex);
// //         setCurrentQuestionIndex(0);
// //       } else if (currentSubjectIndex < JEEquestionsData.length - 1) {
// //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
// //         setSelectedSubject(nextSubject.SubjectName);
// //         setCurrentSectionIndex(0);
// //         setCurrentQuestionIndex(0);
// //       }
// //     }
// //   };

// //   const previousQuestion = () => {
// //     if (currentQuestionIndex > 0) {
// //       moveToQuestion(currentSectionIndex, currentQuestionIndex - 1);
// //     } else {
// //       const currentSubjectIndex = JEEquestionsData.findIndex(
// //         (subject) => subject.SubjectName === selectedSubject
// //       );
// //       const prevSectionIndex = currentSectionIndex - 1;
// //       if (prevSectionIndex >= 0) {
// //         setCurrentSectionIndex(prevSectionIndex);
// //         const prevSection = currentSubject.sections[prevSectionIndex];
// //         setCurrentQuestionIndex(prevSection.questions.length - 1);
// //       } else if (currentSubjectIndex > 0) {
// //         const prevSubject = JEEquestionsData[currentSubjectIndex - 1];
// //         setSelectedSubject(prevSubject.SubjectName);
// //         const lastSectionIndex = prevSubject.sections.length - 1;
// //         setCurrentSectionIndex(lastSectionIndex);
// //         setCurrentQuestionIndex(
// //           prevSubject.sections[lastSectionIndex].questions.length - 1
// //         );
// //       }
// //     }
// //   };

// //   const saveAndNext = () => {
// //     // Retrieve current question details
// //     const currentSection = currentSubject.sections[currentSectionIndex];
// //     const key = getQuestionKey(
// //       selectedSubject,
// //       currentSectionIndex,
// //       currentQuestion.question_id
// //     );
// //     const inputValue = getInputValue(selectedSubject, currentSectionIndex, currentQuestion.question_id)?.trim().toLowerCase();
   
// //     // Log key and input value for debugging
// //     console.log(`Key for question ${currentQuestion.question_id}:`, key);
// //     console.log(`Retrieved input value for question ${currentQuestion.question_id}:`, inputValue);
   
// //     // Check if a response exists for the current question
// //     if (responses[key] !== undefined || inputValue !== "") {
// //       // Set the question as answered
// //       setAnsweredQuestions({ ...answeredQuestions, [key]: true });
   
// //       // Extract correct answer and marks from JSON data
// //       const correctAnswer = currentQuestion.answer?.trim().toLowerCase(); // Assuming answer is in lowercase
// //       const marks = currentQuestion.marks;
   
// //       console.log("Correct Answer:", correctAnswer);
// //       console.log("User Response for options selected:", responses[key]);
// //       console.log("User Response entered value:", inputValue);
   
// //       // Check if the marks are defined
// //       if (marks) {
// //         const marksArray = marks.split(",");
// //         const correctMark = parseInt(marksArray[0], 10);
// //         const wrongMark = parseInt(marksArray[1], 10);
   
// //         // Compare user response with correct answer
// //         const isCorrectResponse =
// //           // For Numeric Answer
// //           !isNaN(inputValue) && !isNaN(correctAnswer) && parseFloat(inputValue) === parseFloat(correctAnswer) ||
// //           // For Numeric Answer with Range
// //           (inputValue !== "" && correctAnswer.includes("-") &&
// //             inputValue >= parseInt(correctAnswer.split("-")[0]) &&
// //             inputValue <= parseInt(correctAnswer.split("-")[1]));
   
// //         if (isCorrectResponse) {
// //           // Increment correct answers count and update total marks
// //           console.log("Response is correct.");
// //           setCorrectAnswersCount((prevCount) => prevCount + 1);
// //           setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
// //           console.log("Marks Added:", correctMark);
// //         } else {
// //           // Increment wrong answers count and subtract marks for incorrect answer
// //           console.log("Response is incorrect.");
// //           setWrongAnswersCount((prevCount) => prevCount + 1);
// //           setTotalMarks((prevTotalMarks) => Math.max(0, prevTotalMarks - wrongMark)); // Subtract marks for incorrect answer, ensuring total marks don't go below 0
// //           console.log("Marks Deducted:", wrongMark);
// //         }
// //       }
   
// //       // Update counts for answered and not answered questions
// //       setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
// //       if (!answeredQuestions[key]) {
// //         setNotAnsweredCount((prevCount) => prevCount - 1);
// //       }
   
// //       // Move to the next question if available, else move to the next section or subject
// //       if (currentQuestionIndex < currentSection.questions.length - 1) {
// //         nextQuestion();
// //       } else {
// //         const currentSubjectIndex = JEEquestionsData.findIndex(
// //           (subject) => subject.SubjectName === selectedSubject
// //         );
// //         if (currentSubjectIndex < JEEquestionsData.length - 1) {
// //           const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
// //           setSelectedSubject(nextSubject.SubjectName);
// //           setCurrentSectionIndex(0);
// //           setCurrentQuestionIndex(0);
// //         }
// //       }
// //     } else {
// //       // If the response is undefined, prompt the user to answer the question before proceeding
// //       alert("Please answer the current question before proceeding.");
// //     }
// //   };

// //   // const saveAndNext = () => {
// //   //   // Retrieve current question details
// //   //   const currentSection = currentSubject.sections[currentSectionIndex];
// //   //   const key = getQuestionKey(
// //   //     selectedSubject,
// //   //     currentSectionIndex,
// //   //     currentQuestion.question_id
// //   //   );
// //   //   const inputValue = getInputValue(selectedSubject, currentSectionIndex, currentQuestion.question_id)?.trim().toLowerCase();
  
// //   //   // Log key and input value for debugging
// //   //   console.log(`Key for question ${currentQuestion.question_id}:`, key);
// //   //   console.log(`Retrieved input value for question ${currentQuestion.question_id}:`, inputValue);
  
// //   //   // Check if a response exists for the current question
// //   //   if (responses[key] !== undefined || inputValue !== "") {
// //   //     // Set the question as answered
// //   //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
  
// //   //     // Extract correct answer and marks from JSON data
// //   //     const correctAnswer = currentQuestion.answer?.trim().toLowerCase(); // Assuming answer is in lowercase
// //   //     const marks = currentQuestion.marks;
  
// //   //     console.log("Correct Answer:", correctAnswer);
// //   //     console.log("User Response for options selected:", responses[key]);
// //   //     console.log("User Response entered value:", inputValue);
  
// //   //     // Check if the marks are defined
// //   //     if (marks) {
// //   //       const marksArray = marks.split(",");
// //   //       const correctMark = parseInt(marksArray[0], 10);
// //   //       const wrongMark = parseInt(marksArray[1], 10);
  
// //   //       // Ensure responses[key] is a string before using trim and toLowerCase
// //   //       const userResponse = responses[key] !== undefined ? responses[key].toString().trim().toLowerCase() : "";
  
// //   //       // Compare user response with correct answer
// //   //       const isCorrectResponse =
// //   //         userResponse === correctAnswer || (inputValue !== "" && inputValue === correctAnswer);
  
// //   //       if (isCorrectResponse) {
// //   //         // Increment correct answers count and update total marks
// //   //         console.log("Response is correct.");
// //   //         setCorrectAnswersCount((prevCount) => prevCount + 1);
// //   //         setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
// //   //         console.log("Marks Added:", correctMark);
// //   //       } else {
// //   //         // Increment wrong answers count and subtract marks for incorrect answer
// //   //         console.log("Response is incorrect.");
// //   //         setWrongAnswersCount((prevCount) => prevCount + 1);
// //   //         setTotalMarks((prevTotalMarks) => Math.max(0, prevTotalMarks - wrongMark)); // Subtract marks for incorrect answer, ensuring total marks don't go below 0
// //   //         console.log("Marks Deducted:", wrongMark);
// //   //       }
// //   //     }
  
// //   //     // Update counts for answered and not answered questions
// //   //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
// //   //     if (!answeredQuestions[key]) {
// //   //       setNotAnsweredCount((prevCount) => prevCount - 1);
// //   //     }
  
// //   //     // Move to the next question if available, else move to the next section or subject
// //   //     if (currentQuestionIndex < currentSection.questions.length - 1) {
// //   //       nextQuestion();
// //   //     } else {
// //   //       const currentSubjectIndex = JEEquestionsData.findIndex(
// //   //         (subject) => subject.SubjectName === selectedSubject
// //   //       );
// //   //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
// //   //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
// //   //         setSelectedSubject(nextSubject.SubjectName);
// //   //         setCurrentSectionIndex(0);
// //   //         setCurrentQuestionIndex(0);
// //   //       }
// //   //     }
// //   //   } else {
// //   //     // If the response is undefined, prompt the user to answer the question before proceeding
// //   //     alert("Please answer the current question before proceeding.");
// //   //   }
// //   // };
  

  

// //   // const saveAndNext = () => {
// //   //   // Retrieve current question details
// //   //   const currentSection = currentSubject.sections[currentSectionIndex];
// //   //   const key = getQuestionKey(
// //   //     selectedSubject,
// //   //     currentSectionIndex,
// //   //     currentQuestion.question_id
// //   //   );
// //   //   const inputValue = getInputValue(selectedSubject, currentSectionIndex, currentQuestion.question_id)?.trim().toLowerCase();
  
// //   //   // Log key and input value for debugging
// //   //   console.log(`Key for question ${currentQuestion.question_id}:`, key);
// //   //   console.log(`Retrieved input value for question ${currentQuestion.question_id}:`, inputValue);
  
// //   //   // Check if a response exists for the current question
// //   //   if (responses[key] !== undefined || inputValue !== "") {
// //   //     // Set the question as answered
// //   //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
  
// //   //     // Extract correct answer and marks from JSON data
// //   //     const correctAnswer = currentQuestion.answer?.trim().toLowerCase(); // Assuming answer is in lowercase
// //   //     const marks = currentQuestion.marks;
  
// //   //     console.log("Correct Answer:", correctAnswer);
// //   //     console.log("User Response for options selected:", responses[key]);
// //   //     console.log("User Response entered value:", inputValue);
  
// //   //     // Check if the marks are defined
// //   //     if (marks) {
// //   //       const marksArray = marks.split(",");
// //   //       const correctMark = parseInt(marksArray[0], 10);
// //   //       const wrongMark = parseInt(marksArray[1], 10);
  
// //   //       // Ensure responses[key] is a string before using trim and toLowerCase
// //   //       const userResponse = responses[key] !== undefined ? responses[key].toString().trim().toLowerCase() : "";
  
// //   //       // Compare user response with correct answer
// //   //       const isCorrectResponse =
// //   //         userResponse === correctAnswer || (inputValue !== "" && inputValue === correctAnswer);
  
// //   //       if (isCorrectResponse) {
// //   //         // Increment correct answers count and update total marks
// //   //         console.log("Response is correct.");
// //   //         setCorrectAnswersCount((prevCount) => prevCount + 1);
// //   //         setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
// //   //         console.log("Marks Added:", correctMark);
// //   //       } else {
// //   //         // Increment wrong answers count and subtract marks for incorrect answer
// //   //         console.log("Response is incorrect.");
// //   //         setWrongAnswersCount((prevCount) => prevCount + 1);
// //   //         setTotalMarks((prevTotalMarks) => prevTotalMarks - wrongMark); // Subtract marks for incorrect answer
// //   //         console.log("Marks Deducted:", wrongMark);
// //   //       }
// //   //     }
  
// //   //     // Update counts for answered and not answered questions
// //   //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
// //   //     if (!answeredQuestions[key]) {
// //   //       setNotAnsweredCount((prevCount) => prevCount - 1);
// //   //     }
  
// //   //     // Move to the next question if available, else move to the next section or subject
// //   //     if (currentQuestionIndex < currentSection.questions.length - 1) {
// //   //       nextQuestion();
// //   //     } else {
// //   //       const currentSubjectIndex = JEEquestionsData.findIndex(
// //   //         (subject) => subject.SubjectName === selectedSubject
// //   //       );
// //   //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
// //   //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
// //   //         setSelectedSubject(nextSubject.SubjectName);
// //   //         setCurrentSectionIndex(0);
// //   //         setCurrentQuestionIndex(0);
// //   //       }
// //   //     }
// //   //   } else {
// //   //     // If the response is undefined, prompt the user to answer the question before proceeding
// //   //     alert("Please answer the current question before proceeding.");
// //   //   }
// //   // };
  

  

// //   // const saveAndNext = () => {
// //   //   // Retrieve current question details
// //   //   const currentSection = currentSubject.sections[currentSectionIndex];
// //   //   const key = getQuestionKey(
// //   //     selectedSubject,
// //   //     currentSectionIndex,
// //   //     currentQuestion.question_id
// //   //   );
// //   //   const inputValue = getInputValue(selectedSubject, currentSectionIndex, currentQuestion.question_id)?.trim().toLowerCase();
  
// //   //   // Log key and input value for debugging
// //   //   console.log(`Key for question ${currentQuestion.question_id}:`, key);
// //   //   console.log(`Retrieved input value for question ${currentQuestion.question_id}:`, inputValue);
  
// //   //   // Check if a response exists for the current question
// //   //   if (responses[key] !== undefined || inputValue !== "") {
// //   //     // Set the question as answered
// //   //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
  
// //   //     // Extract correct answer and marks from JSON data
// //   //     const correctAnswer = currentQuestion.answer?.trim().toLowerCase(); // Assuming answer is in lowercase
// //   //     const marks = currentQuestion.marks;
  
// //   //     console.log("Correct Answer:", correctAnswer);
// //   //     console.log("User Response for options selected:", responses[key]);
// //   //     console.log("User Response entered value:", inputValue);
  
// //   //     // Check if the marks are defined
// //   //     if (marks) {
// //   //       const marksArray = marks.split(",");
// //   //       const correctMark = parseInt(marksArray[0], 10);
// //   //       const wrongMark = parseInt(marksArray[1], 10);
  
// //   //       // Compare user response with correct answer
// //   //       const isCorrectResponse =
// //   //         (responses[key] !== undefined && responses[key].trim().toLowerCase() === correctAnswer) ||
// //   //         (inputValue !== "" && inputValue === correctAnswer);
  
// //   //       if (isCorrectResponse) {
// //   //         // Increment correct answers count and update total marks
// //   //         console.log("Response is correct.");
// //   //         setCorrectAnswersCount((prevCount) => prevCount + 1);
// //   //         setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
// //   //         console.log("Marks Added:", correctMark);
// //   //       } else {
// //   //         // Increment wrong answers count and subtract marks for incorrect answer
// //   //         console.log("Response is incorrect.");
// //   //         setWrongAnswersCount((prevCount) => prevCount + 1);
// //   //         setTotalMarks((prevTotalMarks) => prevTotalMarks - wrongMark); // Subtract marks for incorrect answer
// //   //         console.log("Marks Deducted:", wrongMark);
// //   //       }
// //   //     }
  
// //   //     // Update counts for answered and not answered questions
// //   //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
// //   //     if (!answeredQuestions[key]) {
// //   //       setNotAnsweredCount((prevCount) => prevCount - 1);
// //   //     }
  
// //   //     // Move to the next question if available, else move to the next section or subject
// //   //     if (currentQuestionIndex < currentSection.questions.length - 1) {
// //   //       nextQuestion();
// //   //     } else {
// //   //       const currentSubjectIndex = JEEquestionsData.findIndex(
// //   //         (subject) => subject.SubjectName === selectedSubject
// //   //       );
// //   //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
// //   //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
// //   //         setSelectedSubject(nextSubject.SubjectName);
// //   //         setCurrentSectionIndex(0);
// //   //         setCurrentQuestionIndex(0);
// //   //       }
// //   //     }
// //   //   } else {
// //   //     // If the response is undefined, prompt the user to answer the question before proceeding
// //   //     alert("Please answer the current question before proceeding.");
// //   //   }
// //   // };
// //   // const saveAndNext = () => {
// //   //   // Retrieve current question details
// //   //   const currentSection = currentSubject.sections[currentSectionIndex];
// //   //   const key = getQuestionKey(
// //   //     selectedSubject,
// //   //     currentSectionIndex,
// //   //     currentQuestion.question_id
// //   //   );
// //   //   const inputValue = getInputValue(currentQuestion.question_id)?.trim().toLowerCase();
  
// //   //   // Log input value for debugging
// //   //   console.log(`Retrieved input value for question ${currentQuestion.question_id}:`, inputValue);
  
// //   //   // Check if a response exists for the current question
// //   //   if (responses[key] !== undefined || inputValue !== undefined) {
// //   //     // Set the question as answered
// //   //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
  
// //   //     // Extract correct answer and marks from JSON data
// //   //     const correctAnswer = currentQuestion.answer?.trim().toLowerCase(); // Assuming answer is in lowercase
// //   //     const marks = currentQuestion.marks;
  
// //   //     console.log("Correct Answer:", correctAnswer);
// //   //     console.log("User Response for options selected:", responses[key]);
// //   //     console.log("User Response entered value:", inputValue);
  
// //   //     // Check if the marks are defined
// //   //     if (marks) {
// //   //       const marksArray = marks.split(",");
// //   //       const correctMark = parseInt(marksArray[0], 10);
// //   //       const wrongMark = parseInt(marksArray[1], 10);
  
// //   //       // Compare user response with correct answer
// //   //       const isCorrectResponse =
// //   //         (responses[key] !== undefined && responses[key].trim().toLowerCase() === correctAnswer) ||
// //   //         (inputValue !== undefined && inputValue === correctAnswer);
  
// //   //       if (isCorrectResponse) {
// //   //         // Increment correct answers count and update total marks
// //   //         console.log("Response is correct.");
// //   //         setCorrectAnswersCount((prevCount) => prevCount + 1);
// //   //         setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
// //   //         console.log("Marks Added:", correctMark);
// //   //       } else {
// //   //         // Increment wrong answers count and subtract marks for incorrect answer
// //   //         console.log("Response is incorrect.");
// //   //         setWrongAnswersCount((prevCount) => prevCount + 1);
// //   //         setTotalMarks((prevTotalMarks) => prevTotalMarks - wrongMark); // Subtract marks for incorrect answer
// //   //         console.log("Marks Deducted:", wrongMark);
// //   //       }
// //   //     }
  
// //   //     // Update counts for answered and not answered questions
// //   //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
// //   //     if (!answeredQuestions[key]) {
// //   //       setNotAnsweredCount((prevCount) => prevCount - 1);
// //   //     }
  
// //   //     // Move to the next question if available, else move to the next section or subject
// //   //     if (currentQuestionIndex < currentSection.questions.length - 1) {
// //   //       nextQuestion();
// //   //     } else {
// //   //       const currentSubjectIndex = JEEquestionsData.findIndex(
// //   //         (subject) => subject.SubjectName === selectedSubject
// //   //       );
// //   //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
// //   //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
// //   //         setSelectedSubject(nextSubject.SubjectName);
// //   //         setCurrentSectionIndex(0);
// //   //         setCurrentQuestionIndex(0);
// //   //       }
// //   //     }
// //   //   } else if (inputValue !== undefined) {
// //   //     // Retrieve input value for the current question
// //   //     console.log(`Question: ${currentQuestion.question_id}`);
// //   //     console.log(`Input Value: ${inputValue}`);
// //   //   } else {
// //   //     // If the response is undefined, prompt the user to answer the question before proceeding
// //   //     alert("Please answer the current question before proceeding.");
// //   //   }
// //   // };
  
  
// //   // const saveAndNext = () => {
// //   //   // Retrieve current question details
// //   //   const currentSection = currentSubject.sections[currentSectionIndex];
// //   //   const key = getQuestionKey(
// //   //     selectedSubject,
// //   //     currentSectionIndex,
// //   //     currentQuestion.question_id
// //   //   );
// //   //   const inputValue = getInputValue(currentQuestion.question_id);
  
// //   //   // Check if a response exists for the current question
// //   //   if (responses[key] !== undefined || inputValue !== undefined) {
// //   //     // Set the question as answered
// //   //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
  
// //   //     // Extract correct answer and marks from JSON data
// //   //     const correctAnswer = currentQuestion.answer?.toLowerCase(); // Assuming answer is in lowercase
// //   //     const marks = currentQuestion.marks;
  
// //   //     console.log("Correct Answer:", correctAnswer);
// //   //     console.log("User Response for options selected:", responses[key]);
// //   //     console.log("User Response entered value:", inputValue);
  
// //   //     // Check if the marks are defined
// //   //     if (marks) {
// //   //       const marksArray = marks.split(",");
// //   //       const correctMark = parseInt(marksArray[0], 10);
// //   //       const wrongMark = parseInt(marksArray[1], 10);
  
// //   //       // Compare user response with correct answer
// //   //       if (
// //   //         (responses[key] !== undefined &&
// //   //           responses[key] === correctAnswer) ||
// //   //         (inputValue !== undefined && inputValue.toLowerCase() === correctAnswer)
// //   //       ) {
// //   //         // Increment correct answers count and update total marks
// //   //         console.log("Response is correct.");
// //   //         setCorrectAnswersCount((prevCount) => prevCount + 1);
// //   //         setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
// //   //         console.log("Marks Added:", correctMark);
// //   //       } else {
// //   //         // Increment wrong answers count and subtract marks for incorrect answer
// //   //         console.log("Response is incorrect.");
// //   //         setWrongAnswersCount((prevCount) => prevCount + 1);
// //   //         setTotalMarks((prevTotalMarks) => prevTotalMarks - wrongMark); // Subtract marks for incorrect answer
// //   //         console.log("Marks Deducted:", wrongMark);
// //   //       }
// //   //     }
  
// //   //     // Update counts for answered and not answered questions
// //   //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
// //   //     if (!answeredQuestions[key]) {
// //   //       setNotAnsweredCount((prevCount) => prevCount - 1);
// //   //     }
  
// //   //     // Move to the next question if available, else move to the next section or subject
// //   //     if (currentQuestionIndex < currentSection.questions.length - 1) {
// //   //       nextQuestion();
// //   //     } else {
// //   //       const currentSubjectIndex = JEEquestionsData.findIndex(
// //   //         (subject) => subject.SubjectName === selectedSubject
// //   //       );
// //   //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
// //   //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
// //   //         setSelectedSubject(nextSubject.SubjectName);
// //   //         setCurrentSectionIndex(0);
// //   //         setCurrentQuestionIndex(0);
// //   //       }
// //   //     }
// //   //   } else if (inputValue) {
// //   //     // Retrieve input value for the current question
// //   //     console.log(`Question: ${currentQuestion.question_id}`);
// //   //     console.log(`Input Value: ${inputValue}`);
// //   //   } else {
// //   //     // If the response is undefined, prompt the user to answer the question before proceeding
// //   //     alert("Please answer the current question before proceeding.");
// //   //   }
// //   // };
  
// //   // const saveAndNext = () => {
// //   //   // Retrieve current question details
// //   //   const currentSection = currentSubject.sections[currentSectionIndex];
// //   //   const key = getQuestionKey(
// //   //     selectedSubject,
// //   //     currentSectionIndex,
// //   //     currentQuestion.question_id
// //   //   );
// //   //   const inputValue = getInputValue(currentQuestion.question_id);
 
// //   //   // Check if a response exists for the current question
// //   //   if (responses[key] || inputValue !== undefined) {
// //   //     // Set the question as answered
// //   //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
 
// //   //     // Extract correct answer and marks from JSON data
// //   //     const correctAnswer = currentQuestion.answer?.toLowerCase(); // Assuming answer is in lowercase
// //   //     const marks = currentQuestion.marks;
 
// //   //     console.log("Correct Answer:", correctAnswer);
// //   //     console.log("User Response:", responses[key]);
// //   //     console.log("User Response:", inputValues);
 
// //   //     // Check if the marks are defined
// //   //     if (marks) {
// //   //       const marksArray = marks.split(",");
// //   //       const correctMark = parseInt(marksArray[0], 10);
// //   //       const wrongMark = parseInt(marksArray[1], 10);
 
// //   //       // Compare user response with correct answer
// //   //       if (responses[key] === correctAnswer || inputValue === correctAnswer) {
// //   //         // Increment correct answers count and update total marks
// //   //         console.log("Response is correct.");
// //   //         setCorrectAnswersCount((prevCount) => prevCount + 1);
// //   //         setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
// //   //         console.log("Marks Added:", correctMark);
// //   //       } else {
// //   //         // Increment wrong answers count and subtract marks for incorrect answer
// //   //         console.log("Response is incorrect.");
// //   //         setWrongAnswersCount((prevCount) => prevCount + 1);
// //   //         setTotalMarks((prevTotalMarks) => prevTotalMarks - wrongMark); // Subtract marks for incorrect answer
// //   //         console.log("Marks Deducted:", wrongMark);
// //   //       }
// //   //     }
 
// //   //     // Update counts for answered and not answered questions
// //   //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
// //   //     if (!answeredQuestions[key]) {
// //   //       setNotAnsweredCount((prevCount) => prevCount - 1);
// //   //     }
 
// //   //     // Move to the next question if available, else move to the next section or subject
// //   //     if (currentQuestionIndex < currentSection.questions.length - 1) {
// //   //       nextQuestion();
// //   //     } else {
// //   //       const currentSubjectIndex = JEEquestionsData.findIndex(
// //   //         (subject) => subject.SubjectName === selectedSubject
// //   //       );
// //   //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
// //   //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
// //   //         setSelectedSubject(nextSubject.SubjectName);
// //   //         setCurrentSectionIndex(0);
// //   //         setCurrentQuestionIndex(0);
// //   //       }
// //   //     }
// //   //   } else if (inputValue) {
// //   //     // Retrieve input value for the current question
// //   //     console.log(`Question: ${currentQuestion.question_id}`);
// //   //     console.log(`Input Value: ${inputValue}`);
// //   //   } else {
// //   //     // If the response is undefined, prompt the user to answer the question before proceeding
// //   //     alert("Please answer the current question before proceeding.");
// //   //   }
// //   // };
// //   // const saveAndNext = () => {
// //   //   const key = getQuestionKey(
// //   //     selectedSubject,
// //   //     currentSectionIndex,
// //   //     currentQuestion.question_id
// //   //   );
// //   //   const inputValue = getInputValue(currentQuestion.question_id);

// //   //   if (inputValue || responses[key] || inputValues[key] !== undefined) {
// //   //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
// //   //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
// //   //     if (!answeredQuestions[key]) {
// //   //       setNotAnsweredCount((prevCount) => prevCount - 1);
// //   //     }
// //   //     if (currentQuestionIndex < currentSection.questions.length - 1) {
// //   //       nextQuestion();
// //   //     } else {
// //   //       const currentSubjectIndex = JEEquestionsData.findIndex(
// //   //         (subject) => subject.SubjectName === selectedSubject
// //   //       );
// //   //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
// //   //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
// //   //         setSelectedSubject(nextSubject.SubjectName);
// //   //         setCurrentSectionIndex(0);
// //   //         setCurrentQuestionIndex(0);
// //   //       }
// //   //     }
// //   //   } else if (!inputValue) {
// //   //     console.log(`Question: ${currentQuestion.question_id}`);
// //   //     console.log(`Input Value: ${inputValue}`);
// //   //   } else {
// //   //     alert("Please answer the current question before proceeding.");
// //   //   }
// //   // };

// //   // const saveAndNext = () => {
// //   //   // Retrieve current question details
// //   //   const currentSection = currentSubject.sections[currentSectionIndex];
// //   //   const key = getQuestionKey(
// //   //     selectedSubject,
// //   //     currentSectionIndex,
// //   //     currentQuestion.question_id
// //   //   );
// //   //   const inputValue = getInputValue(currentQuestion.question_id);
// //   //   // console.log(inputValues[key])
// //   //   // Check if a response exists for the current question
// //   //   if (responses[key] || inputValues[key] !== undefined) {
// //   //     // Set the question as answered
// //   //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });

// //   //     // Check if the response is not null
// //   //     if (responses[key] || inputValues[key] !== null) {
// //   //       // Check if the response matches the correct answer
// //   //       const correctAnswer = currentQuestion.answer;
// //   //       if (
// //   //         (correctAnswer && correctAnswer === responses[key]) ||
// //   //         inputValues[key]
// //   //       ) {
// //   //         // Increment correct answers count and update total marks
// //   //         setCorrectAnswersCount((prevCount) => prevCount + 1);
// //   //         const marks = currentQuestion.marks.split(",")[0];
// //   //         setTotalMarks(
// //   //           (prevTotalMarks) => prevTotalMarks + parseInt(marks, 10)
// //   //         );
// //   //         console.log(correctAnswer === inputValues[key] ? true : false);
// //   //       } else {
// //   //         // Increment wrong answers count
// //   //         setWrongAnswersCount((prevCount) => prevCount + 1);
// //   //       }
// //   //     }
// //   //     // Update counts for answered and not answered questions
// //   //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
// //   //     if (!answeredQuestions[key]) {
// //   //       setNotAnsweredCount((prevCount) => prevCount - 1);
// //   //     }

// //   //     // Move to the next question if available, else move to the next section or subject
// //   //     if (currentQuestionIndex < currentSection.questions.length - 1) {
// //   //       nextQuestion();
// //   //     } else {
// //   //       const currentSubjectIndex = JEEquestionsData.findIndex(
// //   //         (subject) => subject.SubjectName === selectedSubject
// //   //       );
// //   //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
// //   //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
// //   //         setSelectedSubject(nextSubject.SubjectName);
// //   //         setCurrentSectionIndex(0);
// //   //         setCurrentQuestionIndex(0);
// //   //       }
// //   //     }
// //   //   } else if (inputValue) {
// //   //     // Retrieve input value for the current question

// //   //     // Log current question and input value
// //   //     console.log(`Question: ${currentQuestion.question_id}`);
// //   //     console.log(`Input Value: ${inputValue}`);
// //   //   } else {
// //   //     // If the response is undefined, prompt the user to answer the question before proceeding
// //   //     alert("Please answer the current question before proceeding.");
// //   //   }
// //   // };

// //   const goToQuestion = (index) => {
// //     const key = getQuestionKey(
// //       selectedSubject,
// //       currentSectionIndex,
// //       currentSection.questions[index].question_id
// //     );
// //     setViewedQuestions({ ...viewedQuestions, [key]: true });
// //     setCurrentQuestionIndex(index);
// //   };

// //   const handleSubjectChange = (subjectName) => {
// //     setSelectedSubject(subjectName);
// //     setCurrentSectionIndex(0);
// //     setCurrentQuestionIndex(0);
// //   };

// //   const handleSectionChange = (index) => {
// //     setCurrentSectionIndex(index);
// //     setCurrentQuestionIndex(0);
// //   };

// //   const calculateCounts = () => {
// //     let answeredCount = 0;
// //     let markedForReviewCount = 0;
// //     let notVisitedCount = 0;

// //     JEEquestionsData.forEach((subject) => {
// //       subject.sections.forEach((section, sectionIndex) => {
// //         section.questions.forEach((question) => {
// //           const key = getQuestionKey(
// //             subject.SubjectName,
// //             sectionIndex,
// //             question.question_id
// //           );
// //           if (responses[key] !== undefined) {
// //             if (responses[key] !== null) {
// //               answeredCount++;
// //             } else {
// //               if (!viewedQuestions[key] && !markedForReview[key]) {
// //                 notVisitedCount++; // If not answered, not marked for review, and not visited yet
// //               }
// //             }
// //           } else {
// //             if (!viewedQuestions[key] && !markedForReview[key]) {
// //               notVisitedCount++; // If not answered, not marked for review, and not visited yet
// //             }
// //           }
// //           if (markedForReview[key]) {
// //             markedForReviewCount++;
// //           }
// //         });
// //       });
// //     });

// //     const totalQuestions = JEEquestionsData.reduce(
// //       (acc, subject) =>
// //         acc +
// //         subject.sections.reduce((acc, sec) => acc + sec.questions.length, 0),
// //       0
// //     );

// //     const notAnsweredCount =
// //       totalQuestions - answeredCount - markedForReviewCount - notVisitedCount;

// //     return {
// //       answeredCount,
// //       markedForReviewCount,
// //       notVisitedCount,
// //       notAnsweredCount,
// //     };
// //   };

// //   const handleSubmit = () => {
// //     setIsAutoSubmit(false);
// //     setShowPopup(true);
// //   };

// //   const closePopup = () => {
// //     setShowPopup(false);
// //     window.location.href = "/bitsatots";
// //     window.close();
// //   };

// //   const currentSubject = JEEquestionsData.find(
// //     (subject) => subject.SubjectName === selectedSubject
// //   );
// //   const currentSection = currentSubject?.sections[currentSectionIndex];
// //   const currentQuestion = currentSection?.questions[currentQuestionIndex];

// //   const counts = calculateCounts();

// //   useEffect(() => {
// //     // Set the viewed status of the first question of the selected subject to true
// //     const firstQuestionKey = getQuestionKey(
// //       selectedSubject,
// //       0,
// //       currentSubject.sections[0].questions[0].question_id
// //     );
// //     setViewedQuestions({ ...viewedQuestions, [firstQuestionKey]: true });
// //   }, [selectedSubject]);
// //   const handleCheckboxChange = (optionIndex) => {
// //     const key = getQuestionKey(
// //       selectedSubject,
// //       currentSectionIndex,
// //       currentQuestion.question_id
// //     );

// //     const selectedOptions = responses[key] || [];
// //     const selectedIndex = selectedOptions.indexOf(optionIndex);

// //     if (selectedIndex === -1) {
// //       // If option is not selected, add it to the array
// //       setResponses({
// //         ...responses,
// //         [key]: [...selectedOptions, optionIndex],
// //       });
// //     } else {
// //       // If option is already selected, remove it from the array
// //       selectedOptions.splice(selectedIndex, 1);
// //       setResponses({
// //         ...responses,
// //         [key]: selectedOptions,
// //       });
// //     }

// //     // Log the selected index
// //     // console.log("Selected Index:", optionIndex);
// //   };

// //   return (
// //     <div>
// //       <div className="mocktestparent_conatiner">
// //         {/* -------------------header------- */}
// //         <div>
// //           <div className="remaining">
// //             <div className="bitsatMockHeader">
// //               <img
// //                 src="https://www.egradtutor.in/static/media/egate%20logo%201.8b1eb31347d60945925f.png"
// //                 alt=""
// //               />
// //             </div>

// //             <h4>
// //               <b>JEE Advance Mock Test 1</b>
// //             </h4>

// //             <p>
// //               Time remaining:{" "}
// //               {`${Math.floor(remainingTime / 3600)}:${Math.floor(
// //                 (remainingTime % 3600) / 60
// //               )}:${remainingTime % 60}`}
// //             </p>
// //           </div>
// //         </div>
// //         {/* -------------------interface------- */}

// //         <div>
// //           <div className="mocktest_conatiner_testwithpallet">
// //             <div className="mocktest_conatiner_testwithpalletquizcontainer">
// //               <div className="mocktest_conatiner_testwithpalletquizcontainersub">
// //                 {/* -------------------sectionbtns------- */}
// //                 <div className="sectionbtnmock">
// //                   {JEEquestionsData.map((subject) => (
// //                     <button
// //                       key={subject.SubjectName}
// //                       onClick={() => handleSubjectChange(subject.SubjectName)}
// //                       className={
// //                         selectedSubject === subject.SubjectName ? "active" : ""
// //                       }
// //                     >
// //                       {subject.SubjectName}
// //                     </button>
// //                   ))}
// //                 </div>
// //                 <div className="sectionbtnmock">
// //                   {selectedSubject && (
// //                     <div className="section-selection">
// //                       {currentSubject.sections.map((section, index) => (
// //                         <button
// //                           key={section.SectionName}
// //                           onClick={() => handleSectionChange(index)}
// //                           className={
// //                             currentSectionIndex === index ? "active" : ""
// //                           }
// //                         >
// //                           {section.SectionName}
// //                         </button>
// //                       ))}
// //                     </div>
// //                   )}
// //                 </div>
// //                 <div className="mocktest_conatiner_testwithpalletquizcontainersubintopart">
// //                   {selectedSubject && currentQuestion && (
// //                     <div>
// //                       <p>Question {currentQuestion.question_id}:</p>
// //                       <img src={currentQuestion.questionImgName} alt="" />

// //                       <div className="optionimginmock">
// //                         {(currentQuestion.qtype === "MCQ4" ||
// //                           currentQuestion.qtype === "MCQ5") && (
// //                           <div className="optionimginmock">
// //                             <p>Options:</p>
// //                             {currentQuestion.options.map((option) => (
// //                               <label key={option.option_id} className="option">
// //                                 <input
// //                                   type="radio"
// //                                   id={`option-${option.option_id}`}
// //                                   name={`question-${currentQuestion.question_id}`}
// //                                   checked={
// //                                     responses[
// //                                       getQuestionKey(
// //                                         selectedSubject,
// //                                         currentSectionIndex,
// //                                         currentQuestion.question_id
// //                                       )
// //                                     ] === option.option_index
// //                                   }
// //                                   onChange={() =>
// //                                     handleOptionChange(option.option_index)
// //                                   }
// //                                 />
// //                                 <span htmlFor={`option-${option.option_id}`}>
// //                                   ({option.option_index})
// //                                 </span>
// //                                 <img src={option.optionImgName} alt="" />
// //                               </label>
// //                             ))}
// //                           </div>
// //                         )}
// //                         {(currentQuestion.qtype === "MSQ" ||
// //                           currentQuestion.qtype === "MSQN") && (
// //                           <div className="optionimginmock">
// //                             <p>Options:</p>
// //                             {currentQuestion.options.map((option) => (
// //                               <label key={option.option_id} className="option">
// //                                 <input
// //                                   type="checkbox"
// //                                   id={`option-${option.option_id}`}
// //                                   // name={`question-${currentQuestion.question_id}`}
// //                                   checked={
// //                                     responses[
// //                                       getQuestionKey(
// //                                         selectedSubject,
// //                                         currentSectionIndex,
// //                                         currentQuestion.question_id
// //                                       )
// //                                     ] &&
// //                                     responses[
// //                                       getQuestionKey(
// //                                         selectedSubject,
// //                                         currentSectionIndex,
// //                                         currentQuestion.question_id
// //                                       )
// //                                     ].includes(option.option_index)
// //                                   }
// //                                   onChange={() =>
// //                                     handleCheckboxChange(option.option_index)
// //                                   }
// //                                 />
// //                                 <span htmlFor={`option-${option.option_id}`}>
// //                                   ({option.option_index})
// //                                 </span>
// //                                 <img src={option.optionImgName} alt="" />
// //                               </label>
// //                             ))}
// //                           </div>
// //                         )}

// //                         {currentQuestion.qtype === "NATI" && (
// //                           <div className="calculator">
// //                             <div className="display">
// //                               <input
// //                                 type="text"
// //                                 value={getInputValue(
// //                                   selectedSubject,
// //                                   currentSectionIndex,
// //                                   currentQuestion.question_id
// //                                 )}
// //                                 readOnly // Make the input read-only to prevent direct editing
// //                                 placeholder="Enter your answer NATI"
// //                               />
// //                             </div>

// //                             {/* Button for DEL */}
// //                             <div>
// //                               <input
// //                                 type="button"
// //                                 value="DEL"
// //                                 onClick={() => {
// //                                   const key = getQuestionKey(
// //                                     selectedSubject,
// //                                     currentSectionIndex,
// //                                     currentQuestion.question_id
// //                                   );
// //                                   const currentValue =
// //                                     getInputValue(
// //                                       currentQuestion.question_id
// //                                     ) || "";
// //                                   updateInputValue(
// //                                     selectedSubject,
// //                                     currentSectionIndex,
// //                                     currentQuestion.question_id,
// //                                     (getInputValue(
// //                                       currentQuestion.question_id
// //                                     ) || "") + "DEL"
// //                                   );
// //                                 }}
// //                               />
// //                             </div>

// //                             {/* Buttons for numbers */}
// //                             <div>
// //                               <div>
// //                                 {[7, 8, 9].map((num) => (
// //                                   <input
// //                                     key={num}
// //                                     type="button"
// //                                     value={num}
// //                                     // onClick={() =>
// //                                     //   updateInputValue(
// //                                     //     currentQuestion.question_id,
// //                                     //     (getInputValue(
// //                                     //       currentQuestion.question_id
// //                                     //     ) || "") + num
// //                                     //   )
// //                                     // }
// //                                     onClick={() => {
// //                                       const key = getQuestionKey(
// //                                         selectedSubject,
// //                                         currentSectionIndex,
// //                                         currentQuestion.question_id
// //                                       );
// //                                       const currentValue =
// //                                         getInputValue(
// //                                           currentQuestion.question_id
// //                                         ) || "";
// //                                       updateInputValue(
// //                                         selectedSubject,
// //                                         currentSectionIndex,
// //                                         currentQuestion.question_id,
// //                                         (getInputValue(
// //                                           currentQuestion.question_id
// //                                         ) || "") + num
// //                                       );
// //                                     }}
// //                                   />
// //                                 ))}
// //                               </div>
// //                               <div>
// //                                 {[4, 5, 6].map((num) => (
// //                                   <input
// //                                     key={num}
// //                                     type="button"
// //                                     value={num}
// //                                     onClick={() => {
// //                                       const key = getQuestionKey(
// //                                         selectedSubject,
// //                                         currentSectionIndex,
// //                                         currentQuestion.question_id
// //                                       );
// //                                       const currentValue =
// //                                         getInputValue(
// //                                           currentQuestion.question_id
// //                                         ) || "";
// //                                       updateInputValue(
// //                                         selectedSubject,
// //                                         currentSectionIndex,
// //                                         currentQuestion.question_id,
// //                                         (getInputValue(
// //                                           currentQuestion.question_id
// //                                         ) || "") + num
// //                                       );
// //                                     }}
// //                                   />
// //                                 ))}
// //                               </div>
// //                               <div>
// //                                 {[1, 2, 3].map((num) => (
// //                                   <input
// //                                     key={num}
// //                                     type="button"
// //                                     value={num}
// //                                     onClick={() => {
// //                                       const key = getQuestionKey(
// //                                         selectedSubject,
// //                                         currentSectionIndex,
// //                                         currentQuestion.question_id
// //                                       );
// //                                       const currentValue =
// //                                         getInputValue(
// //                                           currentQuestion.question_id
// //                                         ) || "";
// //                                       updateInputValue(
// //                                         selectedSubject,
// //                                         currentSectionIndex,
// //                                         currentQuestion.question_id,
// //                                         (getInputValue(
// //                                           currentQuestion.question_id
// //                                         ) || "") + num
// //                                       );
// //                                     }}
// //                                   />
// //                                 ))}
// //                               </div>
// //                               <div>
// //                                 {[0].map((num) => (
// //                                   <input
// //                                     key={num}
// //                                     type="button"
// //                                     value={num}
// //                                     onClick={() => {
// //                                       const key = getQuestionKey(
// //                                         selectedSubject,
// //                                         currentSectionIndex,
// //                                         currentQuestion.question_id
// //                                       );
// //                                       const currentValue =
// //                                         getInputValue(
// //                                           currentQuestion.question_id
// //                                         ) || "";
// //                                       updateInputValue(
// //                                         selectedSubject,
// //                                         currentSectionIndex,
// //                                         currentQuestion.question_id,
// //                                         (getInputValue(
// //                                           currentQuestion.question_id
// //                                         ) || "") + num
// //                                       );
// //                                     }}
// //                                   />
// //                                 ))}
// //                                 <input
// //                                   type="button"
// //                                   value="."
// //                                   onClick={() => {
// //                                     const key = getQuestionKey(
// //                                       selectedSubject,
// //                                       currentSectionIndex,
// //                                       currentQuestion.question_id
// //                                     );
// //                                     const currentValue =
// //                                       getInputValue(
// //                                         currentQuestion.question_id
// //                                       ) || "";
// //                                     updateInputValue(
// //                                       selectedSubject,
// //                                       currentSectionIndex,
// //                                       currentQuestion.question_id,
// //                                       (getInputValue(
// //                                         currentQuestion.question_id
// //                                       ) || "") + "."
// //                                     );
// //                                   }}
// //                                 />
// //                                 <input
// //                                   type="button"
// //                                   value="-"
// //                                   onClick={() => {
// //                                     const key = getQuestionKey(
// //                                       selectedSubject,
// //                                       currentSectionIndex,
// //                                       currentQuestion.question_id
// //                                     );
// //                                     const currentValue =
// //                                       getInputValue(
// //                                         currentQuestion.question_id
// //                                       ) || "";
// //                                     updateInputValue(
// //                                       selectedSubject,
// //                                       currentSectionIndex,
// //                                       currentQuestion.question_id,
// //                                       (getInputValue(
// //                                         currentQuestion.question_id
// //                                       ) || "") + "-"
// //                                     );
// //                                   }}
// //                                 />
// //                               </div>
// //                             </div>

// //                             {/* Buttons for '.' and '-' */}
// //                           </div>
// //                         )}
// //                         {currentQuestion.qtype === "NATD" && (
// //                           <div className="calculator">
// //                             <div className="display">
// //                               <input
// //                                 type="text"
// //                                 value={getInputValue(
// //                                   selectedSubject,
// //                                   currentSectionIndex,
// //                                   currentQuestion.question_id
// //                                 )}
// //                                 readOnly // Make the input read-only to prevent direct editing
// //                                 placeholder="Enter your answer NATD"
// //                               />
// //                             </div>

// //                             {/* Button for DEL */}
// //                             <div>
// //                               <input
// //                                 type="button"
// //                                 value="DEL"
// //                                 onClick={() => {
// //                                   const key = getQuestionKey(
// //                                     selectedSubject,
// //                                     currentSectionIndex,
// //                                     currentQuestion.question_id
// //                                   );
// //                                   const currentValue =
// //                                     getInputValue(
// //                                       currentQuestion.question_id
// //                                     ) || "";
// //                                   updateInputValue(
// //                                     selectedSubject,
// //                                     currentSectionIndex,
// //                                     currentQuestion.question_id,
// //                                     currentValue.slice(0, -1)
// //                                   );
// //                                 }}
// //                               />
// //                             </div>

// //                             {/* Buttons for numbers */}
// //                             <div>
// //                               <div>
// //                                 {[7, 8, 9].map((num) => (
// //                                   <input
// //                                     key={num}
// //                                     type="button"
// //                                     value={num}
// //                                     // onClick={() =>
// //                                     //   updateInputValue(
// //                                     //     currentQuestion.question_id,
// //                                     //     (getInputValue(
// //                                     //       currentQuestion.question_id
// //                                     //     ) || "") + num
// //                                     //   )
// //                                     // }
// //                                     onClick={() => {
// //                                       const key = getQuestionKey(
// //                                         selectedSubject,
// //                                         currentSectionIndex,
// //                                         currentQuestion.question_id
// //                                       );
// //                                       const currentValue =
// //                                         getInputValue(
// //                                           currentQuestion.question_id
// //                                         ) || "";
// //                                       updateInputValue(
// //                                         selectedSubject,
// //                                         currentSectionIndex,
// //                                         currentQuestion.question_id,
// //                                         (getInputValue(
// //                                           currentQuestion.question_id
// //                                         ) || "") + num
// //                                       );
// //                                     }}
// //                                   />
// //                                 ))}
// //                               </div>
// //                               <div>
// //                                 {[4, 5, 6].map((num) => (
// //                                   <input
// //                                     key={num}
// //                                     type="button"
// //                                     value={num}
// //                                     onClick={() => {
// //                                       const key = getQuestionKey(
// //                                         selectedSubject,
// //                                         currentSectionIndex,
// //                                         currentQuestion.question_id
// //                                       );
// //                                       const currentValue =
// //                                         getInputValue(
// //                                           currentQuestion.question_id
// //                                         ) || "";
// //                                       updateInputValue(
// //                                         selectedSubject,
// //                                         currentSectionIndex,
// //                                         currentQuestion.question_id,
// //                                         (getInputValue(
// //                                           currentQuestion.question_id
// //                                         ) || "") + num
// //                                       );
// //                                     }}
// //                                   />
// //                                 ))}
// //                               </div>
// //                               <div>
// //                                 {[1, 2, 3].map((num) => (
// //                                   <input
// //                                     key={num}
// //                                     type="button"
// //                                     value={num}
// //                                     onClick={() => {
// //                                       const key = getQuestionKey(
// //                                         selectedSubject,
// //                                         currentSectionIndex,
// //                                         currentQuestion.question_id
// //                                       );
// //                                       const currentValue =
// //                                         getInputValue(
// //                                           currentQuestion.question_id
// //                                         ) || "";
// //                                       updateInputValue(
// //                                         selectedSubject,
// //                                         currentSectionIndex,
// //                                         currentQuestion.question_id,
// //                                         (getInputValue(
// //                                           currentQuestion.question_id
// //                                         ) || "") + num
// //                                       );
// //                                     }}
// //                                   />
// //                                 ))}
// //                               </div>
// //                               <div>
// //                                 {[0].map((num) => (
// //                                   <input
// //                                     key={num}
// //                                     type="button"
// //                                     value={num}
// //                                     onClick={() => {
// //                                       const key = getQuestionKey(
// //                                         selectedSubject,
// //                                         currentSectionIndex,
// //                                         currentQuestion.question_id
// //                                       );
// //                                       const currentValue =
// //                                         getInputValue(
// //                                           currentQuestion.question_id
// //                                         ) || "";
// //                                       updateInputValue(
// //                                         selectedSubject,
// //                                         currentSectionIndex,
// //                                         currentQuestion.question_id,
// //                                         (getInputValue(
// //                                           currentQuestion.question_id
// //                                         ) || "") + num
// //                                       );
// //                                     }}
// //                                   />
// //                                 ))}
// //                                 <input
// //                                   type="button"
// //                                   value="."
// //                                   onClick={() => {
// //                                     const key = getQuestionKey(
// //                                       selectedSubject,
// //                                       currentSectionIndex,
// //                                       currentQuestion.question_id
// //                                     );
// //                                     const currentValue =
// //                                       getInputValue(
// //                                         currentQuestion.question_id
// //                                       ) || "";
// //                                     updateInputValue(
// //                                       selectedSubject,
// //                                       currentSectionIndex,
// //                                       currentQuestion.question_id,
// //                                       (getInputValue(
// //                                         currentQuestion.question_id
// //                                       ) || "") + "."
// //                                     );
// //                                   }}
// //                                 />
// //                                 <input
// //                                   type="button"
// //                                   value="-"
// //                                   onClick={() => {
// //                                     const key = getQuestionKey(
// //                                       selectedSubject,
// //                                       currentSectionIndex,
// //                                       currentQuestion.question_id
// //                                     );
// //                                     const currentValue =
// //                                       getInputValue(
// //                                         currentQuestion.question_id
// //                                       ) || "";
// //                                     updateInputValue(
// //                                       selectedSubject,
// //                                       currentSectionIndex,
// //                                       currentQuestion.question_id,
// //                                       (getInputValue(
// //                                         currentQuestion.question_id
// //                                       ) || "") + "-"
// //                                     );
// //                                   }}
// //                                 />
// //                               </div>
// //                             </div>

// //                             {/* Buttons for '.' and '-' */}
// //                           </div>
// //                         )}
// //                       </div>
// //                     </div>
// //                   )}
// //                 </div>

// //                 {/* -------------------------------------------buttons Option container-------------- */}
// //                 <div>
// //                   <div class="mocktest_conatiner_testwithpalletbuttons">
// //                     {" "}
// //                     <div>
// //                       <button
// //                         class="Quiz_clearResponse Quiz_mockbtns Quiz_mockbtnsclear"
// //                         onClick={clearResponse}
// //                       >
// //                         Clear Response
// //                       </button>
// //                       {/* <button onClick={markForReview}>Mark For Review</button> */}
// //                       <button
// //                         class="quizsave_next previous_next_btn Quiz_mockbtns"
// //                         onClick={saveAndNext}
// //                       >
// //                         Save &amp; Next
// //                       </button>
// //                     </div>
// //                     <div>
// //                       <button
// //                         class="Quiz_mockbtns Quiz_mockbtnspre"
// //                         onClick={previousQuestion}
// //                       >
// //                         Previous
// //                       </button>
// //                       <button
// //                         class="Quiz_mockbtns Quiz_mockbtnsnext"
// //                         onClick={nextQuestion}
// //                       >
// //                         Next
// //                       </button>
// //                       <button
// //                         class="Quiz_mockbtns Quiz_mockbtnssubmit"
// //                         onClick={handleSubmit}
// //                       >
// //                         Submit
// //                       </button>
// //                     </div>
// //                   </div>
// //                 </div>
// //               </div>
// //             </div>
// //             <div>
// //               <div className="numberpalette">
// //                 <div class="rightSidebar-topHeader">
// //                   <img
// //                     title="Guest"
// //                     src="/static/media/guesticon.00ad06aaa8a7af8eb6c5.jpg"
// //                     alt="guesticon"
// //                   />
// //                   <p>Candidate Name: Guest</p>
// //                 </div>
// //                 <div className="yoursection_inMockTest">
// //                   <span>
// //                     You are viewing{" "}
// //                     <label htmlFor="">{currentSubject.SubjectName}</label>
// //                     Section{" "}
// //                   </span>
// //                   <b>Question Palette</b>
// //                 </div>
// //                 <div className="question-number-palette question-numberpalette question-palette">
// //                   {currentSection.questions.map((question, index) => {
// //                     const key = getQuestionKey(
// //                       selectedSubject,
// //                       currentSectionIndex,
// //                       question.question_id
// //                     );
// //                     return (
// //                       <button
// //                         key={question.question_id}
// //                         className={`
// //                     ${index === currentQuestionIndex ? "active" : ""}
// //                     ${
// //                       answeredQuestions[key] && !markedForReview[key]
// //                         ? "answered"
// //                         : ""
// //                     }
// //                     ${viewedQuestions[key] && !responses[key] ? "viewed" : ""}
// //                     ${
// //                       markedForReview[key]
// //                         ? responses[key]
// //                           ? "marked-answered"
// //                           : "marked"
// //                         : ""
// //                     }
// //                     ${
// //                       responses[key] !== undefined && !answeredQuestions[key]
// //                         ? "answered-red active"
// //                         : ""
// //                     }
// //                   `}
// //                         onClick={() => goToQuestion(index)}
// //                       >
// //                         {index + 1}
// //                       </button>
// //                     );
// //                   })}
// //                 </div>
// //                 <div className="sidebar-footer">
// //                   <h4 className="sidebar-footer-header">Legend:</h4>
// //                   <div className="footer-btns">
// //                     <div className="inst-btns">
// //                       {/* <img src={grayBox} alt="Not Visited" /> */}
// //                       <span className="NotVisited">
// //                         {counts.notVisitedCount}
// //                       </span>

// //                       <label htmlFor="">Not Visited</label>
// //                     </div>
// //                     <div className="inst-btns">
// //                       {/* <img src={greenBox} alt="Answered" /> */}
// //                       <span className="Answered">{answeredCount}</span>
// //                       <label htmlFor="">Answered</label>
// //                     </div>
// //                     <div className="inst-btns">
// //                       {/* <img src={orangeBox} alt="Not Answered" /> */}
// //                       <span className="NotAnsweredlegend">
// //                         {" "}
// //                         {notAnsweredCount}
// //                       </span>
// //                       <label htmlFor="">Not Answered</label>
// //                     </div>
// //                   </div>
// //                 </div>
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //       <div className="scorecardforexam">
// //         {showPopup && (
// //           <div className="popup">
// //             <div className="popup-content">
// //               {remainingTime === 0 ? (
// //                 <div>
// //                   <p>Your Time is up!</p>
// //                   <p>Your test is automatically submitted successfully.</p>
// //                 </div>
// //               ) : (
// //                 <p>You have successfully submitted your test.</p>
// //               )}
// //               <p>
// //                 <img
// //                   src="/static/media/prize.e48700d2bb773b1eda58.jpg"
// //                   alt="Congratulations"
// //                 />
// //                 <span>
// //                   <h2>Congratulations!</h2>
// //                   <span class="mockscore">Your score: {totalMarks}</span>
// //                 </span>
// //               </p>

// //               <ul class="allscorecountingmock">
// //                 <li>
// //                   <b>Answered:</b> {answeredCount}
// //                 </li>
// //                 <li>
// //                   <b>Not Answered:</b> {notAnsweredCount}
// //                 </li>
// //                 <li>
// //                   <b>Right Answered:</b>
// //                   {correctAnswersCount}
// //                 </li>
// //                 <li>
// //                   <b>Wrong Answered:</b> {wrongAnswersCount}
// //                 </li>
// //               </ul>
// //               <button onClick={closePopup}>Close</button>
// //             </div>
// //           </div>
// //         )}
// //       </div>
// //     </div>
// //   );
// // }

// // export default Jee_advance_1;

// import React, { useState, useEffect } from "react";
// import "./Jee_advance.css";
// import JEEquestionsData from "./Jee_advance.json";


// function Jee_advance_1() {
//   // const [value, setValue] = useState("");
//   const [selectedSubject, setSelectedSubject] = useState(
//     JEEquestionsData.length > 0 ? JEEquestionsData[0].SubjectName : null
//   );
//   const [inputValues, setInputValues] = useState({});

//   const updateInputValue = (
//     selectedSubject,
//     currentSectionIndex,
//     questionId,
//     num
//   ) => {
//     const key = getQuestionKey(
//       selectedSubject,
//       currentSectionIndex,
//       questionId
//     );
//     const currentValue = getInputValue(
//       selectedSubject,
//       currentSectionIndex,
//       questionId
//     );

//     let newValue = currentValue; // Initialize newValue with currentValue

//     // If 'DEL' button is clicked and current value is not empty, remove the last character
//     if (num === "DEL" && currentValue.length > 0) {
//       newValue = currentValue.slice(0, -1);
//     } else if (num !== "DEL") {
//       // Only concatenate num if it's not "DEL"
//       // Concatenate the new number to the current value for other cases
//       newValue += num;
//     }

//     // Update input values with the new value
//     setInputValues((prevInputValues) => ({
//       ...prevInputValues,
//       [key]: newValue,
//     }));
//   };

//   const getInputValue = (selectedSubject, currentSectionIndex, questionId) => {
//     // Get the key using the provided parameters
//     const key = getQuestionKey(
//       selectedSubject,
//       currentSectionIndex,
//       questionId
//     );
//     // console.log("Getting input value for key:", key); // <-- Move this line here

//     // Retrieve the input value for the specified key from the inputValues state
//     return inputValues[key] || "";
//   };

//   const moveToNextQuestion = (
//     selectedSubject,
//     currentSectionIndex,
//     questionId
//   ) => {
//     // Clear/reset the input value when moving to the next question
//     setInputValues({
//       ...inputValues,
//       [questionId]: "",
//       [currentSectionIndex]: "",
//       [selectedSubject]: "",
//     });
//   };

//   const returnToPreviousQuestion = (
//     selectedSubject,
//     currentSectionIndex,
//     questionId
//   ) => {
//     // Find the index of the current question in the section
//     const sectionIndex = JEEquestionsData.findIndex(
//       (subject) => subject.SubjectName === selectedSubject
//     );
//     const section = JEEquestionsData[sectionIndex];

//     const questionIndex = section.questions.findIndex(
//       (question) => question.question_id === questionId
//     );

//     if (questionIndex > 0) {
//       // If the current question is not the first question in the section
//       // Navigate to the previous question
//       const previousQuestion = section.questions[questionIndex - 1];
//       // Update the input value with the previous question's value
//       setInputValues(
//         getInputValue(
//           selectedSubject,
//           currentSectionIndex,
//           previousQuestion.question_id
//         )
//       );
//     } else {
//       // If the current question is the first question in the section,
//       // Navigate to the last question of the previous section
//       const previousSectionIndex = sectionIndex - 1;
//       if (previousSectionIndex >= 0) {
//         const previousSection = JEEquestionsData[previousSectionIndex];
//         const lastQuestionIndex = previousSection.questions.length - 1;
//         const lastQuestion = previousSection.questions[lastQuestionIndex];
//         // Update the input value with the last question's value of the previous section
//         setInputValues(
//           getInputValue(
//             selectedSubject,
//             previousSectionIndex,
//             lastQuestion.question_id
//           )
//         );
//       } else {
//         // If there is no previous section, do nothing
//         console.log(
//           "This is the first question, there is no previous question."
//         );
//       }
//     }
//   };

//   const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//   const [responses, setResponses] = useState({});
//   const [viewedQuestions, setViewedQuestions] = useState({});
//   const [answeredQuestions, setAnsweredQuestions] = useState({});
//   const [markedForReview, setMarkedForReview] = useState({});
//   const [showPopup, setShowPopup] = useState(false);
//   const [remainingTime, setRemainingTime] = useState(3 * 60 * 60);
//   const [isAutoSubmit, setIsAutoSubmit] = useState(false);
//   const getQuestionKey = (subject, sectionIndex, questionId, inputValue) => {
//     return `${subject}-${sectionIndex}-${questionId}-${inputValue}`;
//   };

//   const [totalMarks, setTotalMarks] = useState(0);
//   const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
//   const [wrongAnswersCount, setWrongAnswersCount] = useState(0);
//   const [answeredCount, setAnsweredCount] = useState(0);
//   const [notAnsweredCount, setNotAnsweredCount] = useState(0);
//   useEffect(() => {
//     let count = 0;
//     JEEquestionsData.forEach((subject) => {
//       subject.sections.forEach((section) => {
//         count += section.questions.length;
//       });
//     });
//     setNotAnsweredCount(count);
//   }, []);
//   const handleOptionChange = (option) => {
//     console.log("Selected option:", option);
//     if (currentQuestion) {
//       const key = getQuestionKey(
//         selectedSubject,
//         currentSectionIndex,
//         currentQuestion.question_id
//       );
//       console.log("Key:", key);
//       setResponses({ ...responses, [key]: option });
//     }
//   };

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setRemainingTime((prevTime) => {
//         if (prevTime <= 0) {
//           clearInterval(timer);
//           return 0;
//         }
//         return prevTime - 1;
//       });
//     }, 1000);

//     // Cleanup the interval on component unmount
//     return () => clearInterval(timer);
//   }, []);
//   useEffect(() => {
//     if (remainingTime === 0) {
//       setIsAutoSubmit(true);
//       setShowPopup(true);
//       // handleAutoSubmit();
//     }
//   }, [remainingTime]);

//   // Function to clear response for the current question
//   const clearResponse = () => {
//     // Construct the key for the current question
//     const key = getQuestionKey(
//       selectedSubject,
//       currentSectionIndex,
//       currentQuestion.question_id
//     );

//     // Get the current input value
//     const valueToClear = getInputValue(
//       selectedSubject,
//       currentSectionIndex,
//       currentQuestion.question_id
//     );

//     // Log the current value before clearing it
//     console.log("Value before clearing:", valueToClear);

//     // Call updateInputValue to clear the input value
//     updateInputValue(
//       selectedSubject,
//       currentSectionIndex,
//       currentQuestion.question_id,
//       "" // Pass an empty string to clear the input value
//     );

//     // Log the updated input values after clearing
//     console.log("Updated input values:", inputValues);

//     // Other operations in clearResponse function...
//     // setInputValues({
//     //   ...inputValues,
//     //   [currentQuestion.question_id]: "", // Clear input value in state
//     //   [currentSectionIndex]: "", // Clear other related values if needed
//     //   [selectedSubject]: "", // Clear other related values if needed
//     // });
//     setInputValues({
//       ...inputValues,
//       [key]: [], // Clear other related values if needed
//     });

//     // Clear response, mark the question as viewed but not answered, and decrement answered count
//     setResponses({ ...responses, [key]: [] }); // Set response to an empty array

//     // Mark the question as viewed but not answered, and decrement answered count
//     setViewedQuestions({ ...viewedQuestions, [key]: true });
//     setAnsweredQuestions({ ...answeredQuestions, [key]: false });
//     // setAnsweredCount((prevAnsweredCount) => prevAnsweredCount - 1);
//     // Decrease the count only if the question was previously answered
//     if (answeredCount > 0) {
//       setAnsweredCount((prevAnsweredCount) => prevAnsweredCount - 1);
//     }
//   };

//   const moveToQuestion = (newSectionIndex, newQuestionIndex) => {
//     const key = getQuestionKey(
//       selectedSubject,
//       newSectionIndex,
//       currentSubject.sections[newSectionIndex].questions[newQuestionIndex]
//         .question_id
//     );
//     setViewedQuestions({ ...viewedQuestions, [key]: true });
//     setCurrentSectionIndex(newSectionIndex);
//     setCurrentQuestionIndex(newQuestionIndex);
//   };

//   const nextQuestion = () => {
//     const currentSection = currentSubject.sections[currentSectionIndex];
//     if (currentQuestionIndex < currentSection.questions.length - 1) {
//       moveToQuestion(currentSectionIndex, currentQuestionIndex + 1);
//     } else {
//       const currentSubjectIndex = JEEquestionsData.findIndex(
//         (subject) => subject.SubjectName === selectedSubject
//       );
//       const nextSectionIndex = currentSectionIndex + 1;
//       if (nextSectionIndex < currentSubject.sections.length) {
//         setCurrentSectionIndex(nextSectionIndex);
//         setCurrentQuestionIndex(0);
//       } else if (currentSubjectIndex < JEEquestionsData.length - 1) {
//         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
//         setSelectedSubject(nextSubject.SubjectName);
//         setCurrentSectionIndex(0);
//         setCurrentQuestionIndex(0);
//       }
//     }
//   };

//   const previousQuestion = () => {
//     if (currentQuestionIndex > 0) {
//       moveToQuestion(currentSectionIndex, currentQuestionIndex - 1);
//     } else {
//       const currentSubjectIndex = JEEquestionsData.findIndex(
//         (subject) => subject.SubjectName === selectedSubject
//       );
//       const prevSectionIndex = currentSectionIndex - 1;
//       if (prevSectionIndex >= 0) {
//         setCurrentSectionIndex(prevSectionIndex);
//         const prevSection = currentSubject.sections[prevSectionIndex];
//         setCurrentQuestionIndex(prevSection.questions.length - 1);
//       } else if (currentSubjectIndex > 0) {
//         const prevSubject = JEEquestionsData[currentSubjectIndex - 1];
//         setSelectedSubject(prevSubject.SubjectName);
//         const lastSectionIndex = prevSubject.sections.length - 1;
//         setCurrentSectionIndex(lastSectionIndex);
//         setCurrentQuestionIndex(
//           prevSubject.sections[lastSectionIndex].questions.length - 1
//         );
//       }
//     }
//   };
//    // Declare state variables for previous answers and inputs
//   //  const [previousAnswers, setPreviousAnswers] = useState({});
//   //  const [previousInputs, setPreviousInputs] = useState({});
   
  
// // working without repeating except checkboxes
//   // const saveAndNext = () => {
//   //   // Retrieve current question details
//   //   const currentSection = currentSubject.sections[currentSectionIndex];
//   //   const key = getQuestionKey(
//   //     selectedSubject,
//   //     currentSectionIndex,
//   //     currentQuestion.question_id
//   //   );
//   //   const inputValue = getInputValue(selectedSubject, currentSectionIndex, currentQuestion.question_id)?.trim().toLowerCase();
  
//   //   // Log key and input value for debugging
//   //   console.log(`Key for question ${currentQuestion.question_id}:`, key);
//   //   console.log(`Retrieved input value for question ${currentQuestion.question_id}:`, inputValue);
  
//   //   // Check if a response exists for the current question
//   //   if (responses[key] !== undefined || inputValue !== "") {
//   //     // Only proceed if the answer has changed or is new
//   //     if (responses[key] !== previousAnswers[key] || inputValue !== previousInputs[key]) {
//   //       // Store the latest answer and input value
//   //       setPreviousAnswers({ ...previousAnswers, [key]: responses[key] });
//   //       setPreviousInputs({ ...previousInputs, [key]: inputValue });
  
//   //       // Set the question as answered
//   //       setAnsweredQuestions({ ...answeredQuestions, [key]: true });
  
//   //       // Extract correct answer and marks from JSON data
//   //       const correctAnswer = currentQuestion.answer?.trim().toLowerCase(); // Assuming answer is in lowercase
//   //       const marks = currentQuestion.marks;
  
//   //       console.log("Correct Answer:", correctAnswer);
//   //       console.log("User Response for options selected:", responses[key]);
//   //       console.log("User Response entered value:", inputValue);
  
//   //       // Check if the marks are defined
//   //       if (marks) {
//   //         const marksArray = marks.split(",");
//   //         const correctMark = parseInt(marksArray[0], 10);
//   //         const wrongMark = parseInt(marksArray[1], 10);
  
//   //         // Ensure responses[key] is a string before using trim and toLowerCase
//   //         const userResponse = responses[key] !== undefined ? responses[key].toString().trim().toLowerCase() : "";
  
//   //         // Compare user response with correct answer
//   //         const isCorrectResponse =
//   //           userResponse === correctAnswer || (inputValue !== "" && inputValue === correctAnswer);
  
//   //         if (isCorrectResponse) {
//   //           // Increment correct answers count and update total marks only if the answer has changed
//   //           console.log("Response is correct.");
//   //           if (responses[key] !== previousAnswers[key] || inputValue !== previousInputs[key]) {
//   //             setCorrectAnswersCount((prevCount) => prevCount + 1);
//   //             setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
//   //             console.log("Marks Added:", correctMark);
//   //           }
//   //         } else {
//   //           // Increment wrong answers count and subtract marks only if the answer has changed
//   //           console.log("Response is incorrect.");
//   //           if (responses[key] !== previousAnswers[key] || inputValue !== previousInputs[key]) {
//   //             setWrongAnswersCount((prevCount) => prevCount + 1);
//   //             setTotalMarks((prevTotalMarks) => Math.max(0, prevTotalMarks - wrongMark)); // Subtract marks for incorrect answer, ensuring total marks don't go below 0
//   //             console.log("Marks Deducted:", wrongMark);
//   //           }
//   //         }
//   //       }
  
//   //       // Update counts for answered and not answered questions
//   //       setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
//   //       if (!answeredQuestions[key]) {
//   //         setNotAnsweredCount((prevCount) => prevCount - 1);
//   //       }
//   //     }
  
//   //     // Move to the next question if available, else move to the next section or subject
//   //     if (currentQuestionIndex < currentSection.questions.length - 1) {
//   //       nextQuestion();
//   //     } else {
//   //       const currentSubjectIndex = JEEquestionsData.findIndex(
//   //         (subject) => subject.SubjectName === selectedSubject
//   //       );
//   //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
//   //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
//   //         setSelectedSubject(nextSubject.SubjectName);
//   //         setCurrentSectionIndex(0);
//   //         setCurrentQuestionIndex(0);
//   //       }
//   //     }
//   //   } else {
//   //     // If the response is undefined, prompt the user to answer the question before proceeding
//   //     alert("Please answer the current question before proceeding.");
//   //   }
//   // };
  
  
//   //with natd range
//   const saveAndNext = () => {
//     // Retrieve current question details
//     const currentSection = currentSubject.sections[currentSectionIndex];
//     const key = getQuestionKey(
//       selectedSubject,
//       currentSectionIndex,
//       currentQuestion.question_id
//     );
//     const inputValue = getInputValue(selectedSubject, currentSectionIndex, currentQuestion.question_id)?.trim().toLowerCase();
   
//     // Log key and input value for debugging
//     console.log(`Key for question ${currentQuestion.question_id}:`, key);
//     console.log(`Retrieved input value for question ${currentQuestion.question_id}:`, inputValue);
   
//     // Check if a response exists for the current question
//     if (responses[key] !== undefined || inputValue !== "") {
//       // Set the question as answered
//       setAnsweredQuestions({ ...answeredQuestions, [key]: true });
   
//       // Extract correct answer and marks from JSON data
//       const correctAnswer = currentQuestion.answer?.trim().toLowerCase(); // Assuming answer is in lowercase
//       const marks = currentQuestion.marks;
   
//       console.log("Correct Answer:", correctAnswer);
//       console.log("User Response for options selected:", responses[key]);
//       console.log("User Response entered value:", inputValue);
   
//       // Check if the marks are defined
//       if (marks) {
//         const marksArray = marks.split(",");
//         const correctMark = parseInt(marksArray[0], 10);
//         const wrongMark = parseInt(marksArray[1], 10);
   
//         // Compare user response with correct answer
//         const isCorrectResponse =
//           // For Numeric Answer
//           !isNaN(inputValue) && !isNaN(correctAnswer) && parseFloat(inputValue) === parseFloat(correctAnswer) ||
//           // For Numeric Answer with Range
//           (inputValue !== "" && correctAnswer.includes("-") &&
//             inputValue >= parseInt(correctAnswer.split("-")[0]) &&
//             inputValue <= parseInt(correctAnswer.split("-")[1]));
   
//         if (isCorrectResponse) {
//           // Increment correct answers count and update total marks
//           console.log("Response is correct.");
//           setCorrectAnswersCount((prevCount) => prevCount + 1);
//           setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
//           console.log("Marks Added:", correctMark);
//         } else {
//           // Increment wrong answers count and subtract marks for incorrect answer
//           console.log("Response is incorrect.");
//           setWrongAnswersCount((prevCount) => prevCount + 1);
//           setTotalMarks((prevTotalMarks) => Math.max(0, prevTotalMarks - wrongMark)); // Subtract marks for incorrect answer, ensuring total marks don't go below 0
//           console.log("Marks Deducted:", wrongMark);
//         }
//       }
   
//       // Update counts for answered and not answered questions
//       setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
//       if (!answeredQuestions[key]) {
//         setNotAnsweredCount((prevCount) => prevCount - 1);
//       }
   
//       // Move to the next question if available, else move to the next section or subject
//       if (currentQuestionIndex < currentSection.questions.length - 1) {
//         nextQuestion();
//       } else {
//         const currentSubjectIndex = JEEquestionsData.findIndex(
//           (subject) => subject.SubjectName === selectedSubject
//         );
//         if (currentSubjectIndex < JEEquestionsData.length - 1) {
//           const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
//           setSelectedSubject(nextSubject.SubjectName);
//           setCurrentSectionIndex(0);
//           setCurrentQuestionIndex(0);
//         }
//       }
//     } else {
//       // If the response is undefined, prompt the user to answer the question before proceeding
//       alert("Please answer the current question before proceeding.");
//     }
//   };
//   //with out natd range
//   // const saveAndNext = () => {
//   //   // Retrieve current question details
//   //   const currentSection = currentSubject.sections[currentSectionIndex];
//   //   const key = getQuestionKey(
//   //     selectedSubject,
//   //     currentSectionIndex,
//   //     currentQuestion.question_id
//   //   );
//   //   const inputValue = getInputValue(selectedSubject, currentSectionIndex, currentQuestion.question_id)?.trim().toLowerCase();
  
//   //   // Log key and input value for debugging
//   //   console.log(`Key for question ${currentQuestion.question_id}:`, key);
//   //   console.log(`Retrieved input value for question ${currentQuestion.question_id}:`, inputValue);
  
//   //   // Check if a response exists for the current question
//   //   if (responses[key] !== undefined || inputValue !== "") {
//   //     // Set the question as answered
//   //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
  
//   //     // Extract correct answer and marks from JSON data
//   //     const correctAnswer = currentQuestion.answer?.trim().toLowerCase(); // Assuming answer is in lowercase
//   //     const marks = currentQuestion.marks;
  
//   //     console.log("Correct Answer:", correctAnswer);
//   //     console.log("User Response for options selected:", responses[key]);
//   //     console.log("User Response entered value:", inputValue);
  
//   //     // Check if the marks are defined
//   //     if (marks) {
//   //       const marksArray = marks.split(",");
//   //       const correctMark = parseInt(marksArray[0], 10);
//   //       const wrongMark = parseInt(marksArray[1], 10);
  
//   //       // Ensure responses[key] is a string before using trim and toLowerCase
//   //       const userResponse = responses[key] !== undefined ? responses[key].toString().trim().toLowerCase() : "";
  
//   //       // Compare user response with correct answer
//   //       const isCorrectResponse =
//   //         userResponse === correctAnswer || (inputValue !== "" && inputValue === correctAnswer);
  
//   //       if (isCorrectResponse) {
//   //         // Increment correct answers count and update total marks
//   //         console.log("Response is correct.");
//   //         setCorrectAnswersCount((prevCount) => prevCount + 1);
//   //         setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
//   //         console.log("Marks Added:", correctMark);
//   //       } else {
//   //         // Increment wrong answers count and subtract marks for incorrect answer
//   //         console.log("Response is incorrect.");
//   //         setWrongAnswersCount((prevCount) => prevCount + 1);
//   //         setTotalMarks((prevTotalMarks) => Math.max(0, prevTotalMarks - wrongMark)); // Subtract marks for incorrect answer, ensuring total marks don't go below 0
//   //         console.log("Marks Deducted:", wrongMark);
//   //       }
//   //     }
  
//   //     // Update counts for answered and not answered questions
//   //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
//   //     if (!answeredQuestions[key]) {
//   //       setNotAnsweredCount((prevCount) => prevCount - 1);
//   //     }
  
//   //     // Move to the next question if available, else move to the next section or subject
//   //     if (currentQuestionIndex < currentSection.questions.length - 1) {
//   //       nextQuestion();
//   //     } else {
//   //       const currentSubjectIndex = JEEquestionsData.findIndex(
//   //         (subject) => subject.SubjectName === selectedSubject
//   //       );
//   //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
//   //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
//   //         setSelectedSubject(nextSubject.SubjectName);
//   //         setCurrentSectionIndex(0);
//   //         setCurrentQuestionIndex(0);
//   //       }
//   //     }
//   //   } else {
//   //     // If the response is undefined, prompt the user to answer the question before proceeding
//   //     alert("Please answer the current question before proceeding.");
//   //   }
//   // };
// //   const saveAndNext = () => {
// //     // Retrieve current question details
// //     const currentSection = currentSubject.sections[currentSectionIndex];
// //     const key = getQuestionKey(
// //         selectedSubject,
// //         currentSectionIndex,
// //         currentQuestion.question_id
// //     );
// //     const inputValue = getInputValue(
// //         selectedSubject,
// //         currentSectionIndex,
// //         currentQuestion.question_id
// //     )?.trim().toLowerCase();

// //     // Log key and input value for debugging
// //     console.log(`Key for question ${currentQuestion.question_id}:`, key);
// //     console.log(
// //         `Retrieved input value for question ${currentQuestion.question_id}:`,
// //         inputValue
// //     );

// //     // Check if a response exists for the current question
// //     if (responses[key] !== undefined || inputValue !== "") {
// //         // Set the question as answered
// //         setAnsweredQuestions({ ...answeredQuestions, [key]: true });

// //         // Extract correct answer and marks from JSON data
// //         const correctAnswer = currentQuestion.answer?.trim().toLowerCase(); // Assuming answer is in lowercase
// //         const marks = currentQuestion.marks;

// //         console.log("Correct Answer:", correctAnswer);
// //         console.log("User Response for options selected:", responses[key]);
// //         console.log("User Response entered value:", inputValue);

// //         // Check if the marks are defined
// //         if (marks) {
// //             const marksArray = marks.split(",");
// //             const correctMark = parseInt(marksArray[0], 10);
// //             const wrongMark = parseInt(marksArray[1], 10);

// //             // Ensure responses[key] is a string before using trim and toLowerCase
// //             const userResponse =
// //                 responses[key] !== undefined
// //                     ? responses[key].toString().trim().toLowerCase()
// //                     : "";

// //             // Compare user response with correct answer
// //             const isCorrectResponse =
// //                 userResponse === correctAnswer ||
// //                 (inputValue !== "" && inputValue === correctAnswer);

// //             if (isCorrectResponse) {
// //                 // Increment correct answers count and update total marks
// //                 console.log("Response is correct.");
// //                 setCorrectAnswersCount((prevCount) => prevCount + 1);
// //                 setTotalMarks((prevTotalMarks) => {
// //                     // Subtract previous marks for this question
// //                     prevTotalMarks -= responses[key] === correctAnswer ? correctMark : wrongMark;
// //                     // Add marks for correct answer
// //                     return prevTotalMarks + correctMark;
// //                 });
// //                 console.log("Marks Added:", correctMark);
// //             } else {
// //                 // Increment wrong answers count and subtract marks for incorrect answer
// //                 console.log("Response is incorrect.");
// //                 setWrongAnswersCount((prevCount) => prevCount + 1);
// //                 setTotalMarks((prevTotalMarks) => {
// //                     // Subtract previous marks for this question
// //                     prevTotalMarks -= responses[key] === correctAnswer ? correctMark : wrongMark;
// //                     // Subtract marks for incorrect answer, ensuring total marks don't go below 0
// //                     return Math.max(0, prevTotalMarks - wrongMark);
// //                 });
// //                 console.log("Marks Deducted:", wrongMark);
// //             }
// //         }

// //         // Update counts for answered and not answered questions
// //         setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
// //         if (!answeredQuestions[key]) {
// //             setNotAnsweredCount((prevCount) => prevCount - 1);
// //         }

// //         // Move to the next question if available, else move to the next section or subject
// //         if (currentQuestionIndex < currentSection.questions.length - 1) {
// //             nextQuestion();
// //         } else {
// //             const currentSubjectIndex = JEEquestionsData.findIndex(
// //                 (subject) => subject.SubjectName === selectedSubject
// //             );
// //             if (currentSubjectIndex < JEEquestionsData.length - 1) {
// //                 const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
// //                 setSelectedSubject(nextSubject.SubjectName);
// //                 setCurrentSectionIndex(0);
// //                 setCurrentQuestionIndex(0);
// //             }
// //         }

// //         // Display the total score
// //         console.log("Total Score:", totalMarks); // Assuming you want to log the total score
// //         // You can also update any UI element displaying the total score
// //     } else {
// //         // If the response is undefined, prompt the user to answer the question before proceeding
// //         alert("Please answer the current question before proceeding.");
// //     }
// // };



  

//   // const saveAndNext = () => {
//   //   // Retrieve current question details
//   //   const currentSection = currentSubject.sections[currentSectionIndex];
//   //   const key = getQuestionKey(
//   //     selectedSubject,
//   //     currentSectionIndex,
//   //     currentQuestion.question_id
//   //   );
//   //   const inputValue = getInputValue(selectedSubject, currentSectionIndex, currentQuestion.question_id)?.trim().toLowerCase();
  
//   //   // Log key and input value for debugging
//   //   console.log(`Key for question ${currentQuestion.question_id}:`, key);
//   //   console.log(`Retrieved input value for question ${currentQuestion.question_id}:`, inputValue);
  
//   //   // Check if a response exists for the current question
//   //   if (responses[key] !== undefined || inputValue !== "") {
//   //     // Set the question as answered
//   //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
  
//   //     // Extract correct answer and marks from JSON data
//   //     const correctAnswer = currentQuestion.answer?.trim().toLowerCase(); // Assuming answer is in lowercase
//   //     const marks = currentQuestion.marks;
  
//   //     console.log("Correct Answer:", correctAnswer);
//   //     console.log("User Response for options selected:", responses[key]);
//   //     console.log("User Response entered value:", inputValue);
  
//   //     // Check if the marks are defined
//   //     if (marks) {
//   //       const marksArray = marks.split(",");
//   //       const correctMark = parseInt(marksArray[0], 10);
//   //       const wrongMark = parseInt(marksArray[1], 10);
  
//   //       // Ensure responses[key] is a string before using trim and toLowerCase
//   //       const userResponse = responses[key] !== undefined ? responses[key].toString().trim().toLowerCase() : "";
  
//   //       // Compare user response with correct answer
//   //       const isCorrectResponse =
//   //         userResponse === correctAnswer || (inputValue !== "" && inputValue === correctAnswer);
  
//   //       if (isCorrectResponse) {
//   //         // Increment correct answers count and update total marks
//   //         console.log("Response is correct.");
//   //         setCorrectAnswersCount((prevCount) => prevCount + 1);
//   //         setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
//   //         console.log("Marks Added:", correctMark);
//   //       } else {
//   //         // Increment wrong answers count and subtract marks for incorrect answer
//   //         console.log("Response is incorrect.");
//   //         setWrongAnswersCount((prevCount) => prevCount + 1);
//   //         setTotalMarks((prevTotalMarks) => prevTotalMarks - wrongMark); // Subtract marks for incorrect answer
//   //         console.log("Marks Deducted:", wrongMark);
//   //       }
//   //     }
  
//   //     // Update counts for answered and not answered questions
//   //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
//   //     if (!answeredQuestions[key]) {
//   //       setNotAnsweredCount((prevCount) => prevCount - 1);
//   //     }
  
//   //     // Move to the next question if available, else move to the next section or subject
//   //     if (currentQuestionIndex < currentSection.questions.length - 1) {
//   //       nextQuestion();
//   //     } else {
//   //       const currentSubjectIndex = JEEquestionsData.findIndex(
//   //         (subject) => subject.SubjectName === selectedSubject
//   //       );
//   //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
//   //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
//   //         setSelectedSubject(nextSubject.SubjectName);
//   //         setCurrentSectionIndex(0);
//   //         setCurrentQuestionIndex(0);
//   //       }
//   //     }
//   //   } else {
//   //     // If the response is undefined, prompt the user to answer the question before proceeding
//   //     alert("Please answer the current question before proceeding.");
//   //   }
//   // };
  

  

//   // const saveAndNext = () => {
//   //   // Retrieve current question details
//   //   const currentSection = currentSubject.sections[currentSectionIndex];
//   //   const key = getQuestionKey(
//   //     selectedSubject,
//   //     currentSectionIndex,
//   //     currentQuestion.question_id
//   //   );
//   //   const inputValue = getInputValue(selectedSubject, currentSectionIndex, currentQuestion.question_id)?.trim().toLowerCase();
  
//   //   // Log key and input value for debugging
//   //   console.log(`Key for question ${currentQuestion.question_id}:`, key);
//   //   console.log(`Retrieved input value for question ${currentQuestion.question_id}:`, inputValue);
  
//   //   // Check if a response exists for the current question
//   //   if (responses[key] !== undefined || inputValue !== "") {
//   //     // Set the question as answered
//   //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
  
//   //     // Extract correct answer and marks from JSON data
//   //     const correctAnswer = currentQuestion.answer?.trim().toLowerCase(); // Assuming answer is in lowercase
//   //     const marks = currentQuestion.marks;
  
//   //     console.log("Correct Answer:", correctAnswer);
//   //     console.log("User Response for options selected:", responses[key]);
//   //     console.log("User Response entered value:", inputValue);
  
//   //     // Check if the marks are defined
//   //     if (marks) {
//   //       const marksArray = marks.split(",");
//   //       const correctMark = parseInt(marksArray[0], 10);
//   //       const wrongMark = parseInt(marksArray[1], 10);
  
//   //       // Compare user response with correct answer
//   //       const isCorrectResponse =
//   //         (responses[key] !== undefined && responses[key].trim().toLowerCase() === correctAnswer) ||
//   //         (inputValue !== "" && inputValue === correctAnswer);
  
//   //       if (isCorrectResponse) {
//   //         // Increment correct answers count and update total marks
//   //         console.log("Response is correct.");
//   //         setCorrectAnswersCount((prevCount) => prevCount + 1);
//   //         setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
//   //         console.log("Marks Added:", correctMark);
//   //       } else {
//   //         // Increment wrong answers count and subtract marks for incorrect answer
//   //         console.log("Response is incorrect.");
//   //         setWrongAnswersCount((prevCount) => prevCount + 1);
//   //         setTotalMarks((prevTotalMarks) => prevTotalMarks - wrongMark); // Subtract marks for incorrect answer
//   //         console.log("Marks Deducted:", wrongMark);
//   //       }
//   //     }
  
//   //     // Update counts for answered and not answered questions
//   //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
//   //     if (!answeredQuestions[key]) {
//   //       setNotAnsweredCount((prevCount) => prevCount - 1);
//   //     }
  
//   //     // Move to the next question if available, else move to the next section or subject
//   //     if (currentQuestionIndex < currentSection.questions.length - 1) {
//   //       nextQuestion();
//   //     } else {
//   //       const currentSubjectIndex = JEEquestionsData.findIndex(
//   //         (subject) => subject.SubjectName === selectedSubject
//   //       );
//   //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
//   //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
//   //         setSelectedSubject(nextSubject.SubjectName);
//   //         setCurrentSectionIndex(0);
//   //         setCurrentQuestionIndex(0);
//   //       }
//   //     }
//   //   } else {
//   //     // If the response is undefined, prompt the user to answer the question before proceeding
//   //     alert("Please answer the current question before proceeding.");
//   //   }
//   // };
//   // const saveAndNext = () => {
//   //   // Retrieve current question details
//   //   const currentSection = currentSubject.sections[currentSectionIndex];
//   //   const key = getQuestionKey(
//   //     selectedSubject,
//   //     currentSectionIndex,
//   //     currentQuestion.question_id
//   //   );
//   //   const inputValue = getInputValue(currentQuestion.question_id)?.trim().toLowerCase();
  
//   //   // Log input value for debugging
//   //   console.log(`Retrieved input value for question ${currentQuestion.question_id}:`, inputValue);
  
//   //   // Check if a response exists for the current question
//   //   if (responses[key] !== undefined || inputValue !== undefined) {
//   //     // Set the question as answered
//   //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
  
//   //     // Extract correct answer and marks from JSON data
//   //     const correctAnswer = currentQuestion.answer?.trim().toLowerCase(); // Assuming answer is in lowercase
//   //     const marks = currentQuestion.marks;
  
//   //     console.log("Correct Answer:", correctAnswer);
//   //     console.log("User Response for options selected:", responses[key]);
//   //     console.log("User Response entered value:", inputValue);
  
//   //     // Check if the marks are defined
//   //     if (marks) {
//   //       const marksArray = marks.split(",");
//   //       const correctMark = parseInt(marksArray[0], 10);
//   //       const wrongMark = parseInt(marksArray[1], 10);
  
//   //       // Compare user response with correct answer
//   //       const isCorrectResponse =
//   //         (responses[key] !== undefined && responses[key].trim().toLowerCase() === correctAnswer) ||
//   //         (inputValue !== undefined && inputValue === correctAnswer);
  
//   //       if (isCorrectResponse) {
//   //         // Increment correct answers count and update total marks
//   //         console.log("Response is correct.");
//   //         setCorrectAnswersCount((prevCount) => prevCount + 1);
//   //         setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
//   //         console.log("Marks Added:", correctMark);
//   //       } else {
//   //         // Increment wrong answers count and subtract marks for incorrect answer
//   //         console.log("Response is incorrect.");
//   //         setWrongAnswersCount((prevCount) => prevCount + 1);
//   //         setTotalMarks((prevTotalMarks) => prevTotalMarks - wrongMark); // Subtract marks for incorrect answer
//   //         console.log("Marks Deducted:", wrongMark);
//   //       }
//   //     }
  
//   //     // Update counts for answered and not answered questions
//   //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
//   //     if (!answeredQuestions[key]) {
//   //       setNotAnsweredCount((prevCount) => prevCount - 1);
//   //     }
  
//   //     // Move to the next question if available, else move to the next section or subject
//   //     if (currentQuestionIndex < currentSection.questions.length - 1) {
//   //       nextQuestion();
//   //     } else {
//   //       const currentSubjectIndex = JEEquestionsData.findIndex(
//   //         (subject) => subject.SubjectName === selectedSubject
//   //       );
//   //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
//   //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
//   //         setSelectedSubject(nextSubject.SubjectName);
//   //         setCurrentSectionIndex(0);
//   //         setCurrentQuestionIndex(0);
//   //       }
//   //     }
//   //   } else if (inputValue !== undefined) {
//   //     // Retrieve input value for the current question
//   //     console.log(`Question: ${currentQuestion.question_id}`);
//   //     console.log(`Input Value: ${inputValue}`);
//   //   } else {
//   //     // If the response is undefined, prompt the user to answer the question before proceeding
//   //     alert("Please answer the current question before proceeding.");
//   //   }
//   // };
  
  
//   // const saveAndNext = () => {
//   //   // Retrieve current question details
//   //   const currentSection = currentSubject.sections[currentSectionIndex];
//   //   const key = getQuestionKey(
//   //     selectedSubject,
//   //     currentSectionIndex,
//   //     currentQuestion.question_id
//   //   );
//   //   const inputValue = getInputValue(currentQuestion.question_id);
  
//   //   // Check if a response exists for the current question
//   //   if (responses[key] !== undefined || inputValue !== undefined) {
//   //     // Set the question as answered
//   //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
  
//   //     // Extract correct answer and marks from JSON data
//   //     const correctAnswer = currentQuestion.answer?.toLowerCase(); // Assuming answer is in lowercase
//   //     const marks = currentQuestion.marks;
  
//   //     console.log("Correct Answer:", correctAnswer);
//   //     console.log("User Response for options selected:", responses[key]);
//   //     console.log("User Response entered value:", inputValue);
  
//   //     // Check if the marks are defined
//   //     if (marks) {
//   //       const marksArray = marks.split(",");
//   //       const correctMark = parseInt(marksArray[0], 10);
//   //       const wrongMark = parseInt(marksArray[1], 10);
  
//   //       // Compare user response with correct answer
//   //       if (
//   //         (responses[key] !== undefined &&
//   //           responses[key] === correctAnswer) ||
//   //         (inputValue !== undefined && inputValue.toLowerCase() === correctAnswer)
//   //       ) {
//   //         // Increment correct answers count and update total marks
//   //         console.log("Response is correct.");
//   //         setCorrectAnswersCount((prevCount) => prevCount + 1);
//   //         setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
//   //         console.log("Marks Added:", correctMark);
//   //       } else {
//   //         // Increment wrong answers count and subtract marks for incorrect answer
//   //         console.log("Response is incorrect.");
//   //         setWrongAnswersCount((prevCount) => prevCount + 1);
//   //         setTotalMarks((prevTotalMarks) => prevTotalMarks - wrongMark); // Subtract marks for incorrect answer
//   //         console.log("Marks Deducted:", wrongMark);
//   //       }
//   //     }
  
//   //     // Update counts for answered and not answered questions
//   //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
//   //     if (!answeredQuestions[key]) {
//   //       setNotAnsweredCount((prevCount) => prevCount - 1);
//   //     }
  
//   //     // Move to the next question if available, else move to the next section or subject
//   //     if (currentQuestionIndex < currentSection.questions.length - 1) {
//   //       nextQuestion();
//   //     } else {
//   //       const currentSubjectIndex = JEEquestionsData.findIndex(
//   //         (subject) => subject.SubjectName === selectedSubject
//   //       );
//   //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
//   //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
//   //         setSelectedSubject(nextSubject.SubjectName);
//   //         setCurrentSectionIndex(0);
//   //         setCurrentQuestionIndex(0);
//   //       }
//   //     }
//   //   } else if (inputValue) {
//   //     // Retrieve input value for the current question
//   //     console.log(`Question: ${currentQuestion.question_id}`);
//   //     console.log(`Input Value: ${inputValue}`);
//   //   } else {
//   //     // If the response is undefined, prompt the user to answer the question before proceeding
//   //     alert("Please answer the current question before proceeding.");
//   //   }
//   // };
  
//   // const saveAndNext = () => {
//   //   // Retrieve current question details
//   //   const currentSection = currentSubject.sections[currentSectionIndex];
//   //   const key = getQuestionKey(
//   //     selectedSubject,
//   //     currentSectionIndex,
//   //     currentQuestion.question_id
//   //   );
//   //   const inputValue = getInputValue(currentQuestion.question_id);
 
//   //   // Check if a response exists for the current question
//   //   if (responses[key] || inputValue !== undefined) {
//   //     // Set the question as answered
//   //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
 
//   //     // Extract correct answer and marks from JSON data
//   //     const correctAnswer = currentQuestion.answer?.toLowerCase(); // Assuming answer is in lowercase
//   //     const marks = currentQuestion.marks;
 
//   //     console.log("Correct Answer:", correctAnswer);
//   //     console.log("User Response:", responses[key]);
//   //     console.log("User Response:", inputValues);
 
//   //     // Check if the marks are defined
//   //     if (marks) {
//   //       const marksArray = marks.split(",");
//   //       const correctMark = parseInt(marksArray[0], 10);
//   //       const wrongMark = parseInt(marksArray[1], 10);
 
//   //       // Compare user response with correct answer
//   //       if (responses[key] === correctAnswer || inputValue === correctAnswer) {
//   //         // Increment correct answers count and update total marks
//   //         console.log("Response is correct.");
//   //         setCorrectAnswersCount((prevCount) => prevCount + 1);
//   //         setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
//   //         console.log("Marks Added:", correctMark);
//   //       } else {
//   //         // Increment wrong answers count and subtract marks for incorrect answer
//   //         console.log("Response is incorrect.");
//   //         setWrongAnswersCount((prevCount) => prevCount + 1);
//   //         setTotalMarks((prevTotalMarks) => prevTotalMarks - wrongMark); // Subtract marks for incorrect answer
//   //         console.log("Marks Deducted:", wrongMark);
//   //       }
//   //     }
 
//   //     // Update counts for answered and not answered questions
//   //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
//   //     if (!answeredQuestions[key]) {
//   //       setNotAnsweredCount((prevCount) => prevCount - 1);
//   //     }
 
//   //     // Move to the next question if available, else move to the next section or subject
//   //     if (currentQuestionIndex < currentSection.questions.length - 1) {
//   //       nextQuestion();
//   //     } else {
//   //       const currentSubjectIndex = JEEquestionsData.findIndex(
//   //         (subject) => subject.SubjectName === selectedSubject
//   //       );
//   //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
//   //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
//   //         setSelectedSubject(nextSubject.SubjectName);
//   //         setCurrentSectionIndex(0);
//   //         setCurrentQuestionIndex(0);
//   //       }
//   //     }
//   //   } else if (inputValue) {
//   //     // Retrieve input value for the current question
//   //     console.log(`Question: ${currentQuestion.question_id}`);
//   //     console.log(`Input Value: ${inputValue}`);
//   //   } else {
//   //     // If the response is undefined, prompt the user to answer the question before proceeding
//   //     alert("Please answer the current question before proceeding.");
//   //   }
//   // };
//   // const saveAndNext = () => {
//   //   const key = getQuestionKey(
//   //     selectedSubject,
//   //     currentSectionIndex,
//   //     currentQuestion.question_id
//   //   );
//   //   const inputValue = getInputValue(currentQuestion.question_id);

//   //   if (inputValue || responses[key] || inputValues[key] !== undefined) {
//   //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
//   //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
//   //     if (!answeredQuestions[key]) {
//   //       setNotAnsweredCount((prevCount) => prevCount - 1);
//   //     }
//   //     if (currentQuestionIndex < currentSection.questions.length - 1) {
//   //       nextQuestion();
//   //     } else {
//   //       const currentSubjectIndex = JEEquestionsData.findIndex(
//   //         (subject) => subject.SubjectName === selectedSubject
//   //       );
//   //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
//   //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
//   //         setSelectedSubject(nextSubject.SubjectName);
//   //         setCurrentSectionIndex(0);
//   //         setCurrentQuestionIndex(0);
//   //       }
//   //     }
//   //   } else if (!inputValue) {
//   //     console.log(`Question: ${currentQuestion.question_id}`);
//   //     console.log(`Input Value: ${inputValue}`);
//   //   } else {
//   //     alert("Please answer the current question before proceeding.");
//   //   }
//   // };

//   // const saveAndNext = () => {
//   //   // Retrieve current question details
//   //   const currentSection = currentSubject.sections[currentSectionIndex];
//   //   const key = getQuestionKey(
//   //     selectedSubject,
//   //     currentSectionIndex,
//   //     currentQuestion.question_id
//   //   );
//   //   const inputValue = getInputValue(currentQuestion.question_id);
//   //   // console.log(inputValues[key])
//   //   // Check if a response exists for the current question
//   //   if (responses[key] || inputValues[key] !== undefined) {
//   //     // Set the question as answered
//   //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });

//   //     // Check if the response is not null
//   //     if (responses[key] || inputValues[key] !== null) {
//   //       // Check if the response matches the correct answer
//   //       const correctAnswer = currentQuestion.answer;
//   //       if (
//   //         (correctAnswer && correctAnswer === responses[key]) ||
//   //         inputValues[key]
//   //       ) {
//   //         // Increment correct answers count and update total marks
//   //         setCorrectAnswersCount((prevCount) => prevCount + 1);
//   //         const marks = currentQuestion.marks.split(",")[0];
//   //         setTotalMarks(
//   //           (prevTotalMarks) => prevTotalMarks + parseInt(marks, 10)
//   //         );
//   //         console.log(correctAnswer === inputValues[key] ? true : false);
//   //       } else {
//   //         // Increment wrong answers count
//   //         setWrongAnswersCount((prevCount) => prevCount + 1);
//   //       }
//   //     }
//   //     // Update counts for answered and not answered questions
//   //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
//   //     if (!answeredQuestions[key]) {
//   //       setNotAnsweredCount((prevCount) => prevCount - 1);
//   //     }

//   //     // Move to the next question if available, else move to the next section or subject
//   //     if (currentQuestionIndex < currentSection.questions.length - 1) {
//   //       nextQuestion();
//   //     } else {
//   //       const currentSubjectIndex = JEEquestionsData.findIndex(
//   //         (subject) => subject.SubjectName === selectedSubject
//   //       );
//   //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
//   //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
//   //         setSelectedSubject(nextSubject.SubjectName);
//   //         setCurrentSectionIndex(0);
//   //         setCurrentQuestionIndex(0);
//   //       }
//   //     }
//   //   } else if (inputValue) {
//   //     // Retrieve input value for the current question

//   //     // Log current question and input value
//   //     console.log(`Question: ${currentQuestion.question_id}`);
//   //     console.log(`Input Value: ${inputValue}`);
//   //   } else {
//   //     // If the response is undefined, prompt the user to answer the question before proceeding
//   //     alert("Please answer the current question before proceeding.");
//   //   }
//   // };

//   const goToQuestion = (index) => {
//     const key = getQuestionKey(
//       selectedSubject,
//       currentSectionIndex,
//       currentSection.questions[index].question_id
//     );
//     setViewedQuestions({ ...viewedQuestions, [key]: true });
//     setCurrentQuestionIndex(index);
//   };

//   const handleSubjectChange = (subjectName) => {
//     setSelectedSubject(subjectName);
//     setCurrentSectionIndex(0);
//     setCurrentQuestionIndex(0);
//   };

//   const handleSectionChange = (index) => {
//     setCurrentSectionIndex(index);
//     setCurrentQuestionIndex(0);
//   };

//   const calculateCounts = () => {
//     let answeredCount = 0;
//     let markedForReviewCount = 0;
//     let notVisitedCount = 0;

//     JEEquestionsData.forEach((subject) => {
//       subject.sections.forEach((section, sectionIndex) => {
//         section.questions.forEach((question) => {
//           const key = getQuestionKey(
//             subject.SubjectName,
//             sectionIndex,
//             question.question_id
//           );
//           if (responses[key] !== undefined) {
//             if (responses[key] !== null) {
//               answeredCount++;
//             } else {
//               if (!viewedQuestions[key] && !markedForReview[key]) {
//                 notVisitedCount++; // If not answered, not marked for review, and not visited yet
//               }
//             }
//           } else {
//             if (!viewedQuestions[key] && !markedForReview[key]) {
//               notVisitedCount++; // If not answered, not marked for review, and not visited yet
//             }
//           }
//           if (markedForReview[key]) {
//             markedForReviewCount++;
//           }
//         });
//       });
//     });

//     const totalQuestions = JEEquestionsData.reduce(
//       (acc, subject) =>
//         acc +
//         subject.sections.reduce((acc, sec) => acc + sec.questions.length, 0),
//       0
//     );

//     const notAnsweredCount =
//       totalQuestions - answeredCount - markedForReviewCount - notVisitedCount;

//     return {
//       answeredCount,
//       markedForReviewCount,
//       notVisitedCount,
//       notAnsweredCount,
//     };
//   };

//   const handleSubmit = () => {
//     setIsAutoSubmit(false);
//     setShowPopup(true);
//   };

//   const closePopup = () => {
//     setShowPopup(false);
//     window.location.href = "/bitsatots";
//     window.close();
//   };

//   const currentSubject = JEEquestionsData.find(
//     (subject) => subject.SubjectName === selectedSubject
//   );
//   const currentSection = currentSubject?.sections[currentSectionIndex];
//   const currentQuestion = currentSection?.questions[currentQuestionIndex];

//   const counts = calculateCounts();

//   useEffect(() => {
//     // Set the viewed status of the first question of the selected subject to true
//     const firstQuestionKey = getQuestionKey(
//       selectedSubject,
//       0,
//       currentSubject.sections[0].questions[0].question_id
//     );
//     setViewedQuestions({ ...viewedQuestions, [firstQuestionKey]: true });
//   }, [selectedSubject]);
//   const handleCheckboxChange = (optionIndex) => {
//     const key = getQuestionKey(
//       selectedSubject,
//       currentSectionIndex,
//       currentQuestion.question_id
//     );

//     const selectedOptions = responses[key] || [];
//     const selectedIndex = selectedOptions.indexOf(optionIndex);

//     if (selectedIndex === -1) {
//       // If option is not selected, add it to the array
//       setResponses({
//         ...responses,
//         [key]: [...selectedOptions, optionIndex],
//       });
//     } else {
//       // If option is already selected, remove it from the array
//       selectedOptions.splice(selectedIndex, 1);
//       setResponses({
//         ...responses,
//         [key]: selectedOptions,
//       });
//     }

//     // Log the selected index
//     // console.log("Selected Index:", optionIndex);
//   };

//   return (
//     <div>
//       <div className="mocktestparent_conatiner">
//         {/* -------------------header------- */}
//         <div>
//           <div className="remaining">
//             <div className="bitsatMockHeader">
//               <img
//                 src="https://www.egradtutor.in/static/media/egate%20logo%201.8b1eb31347d60945925f.png"
//                 alt=""
//               />
//             </div>

//             <h4>
//               <b>JEE Mains Mock Test 1</b>
//             </h4>

//             <p>
//               Time remaining:{" "}
//               {`${Math.floor(remainingTime / 3600)}:${Math.floor(
//                 (remainingTime % 3600) / 60
//               )}:${remainingTime % 60}`}
//             </p>
//           </div>
//         </div>
//         {/* -------------------interface------- */}

//         <div>
//           <div className="mocktest_conatiner_testwithpallet">
//             <div className="mocktest_conatiner_testwithpalletquizcontainer">
//               <div className="mocktest_conatiner_testwithpalletquizcontainersub">
//                 {/* -------------------sectionbtns------- */}
//                 <div className="sectionbtnmock">
//                   {JEEquestionsData.map((subject) => (
//                     <button
//                       key={subject.SubjectName}
//                       onClick={() => handleSubjectChange(subject.SubjectName)}
//                       className={
//                         selectedSubject === subject.SubjectName ? "active" : ""
//                       }
//                     >
//                       {subject.SubjectName}
//                     </button>
//                   ))}
//                 </div>
//                 <div className="sectionbtnmock">
//                   {selectedSubject && (
//                     <div className="section-selection">
//                       {currentSubject.sections.map((section, index) => (
//                         <button
//                           key={section.SectionName}
//                           onClick={() => handleSectionChange(index)}
//                           className={
//                             currentSectionIndex === index ? "active" : ""
//                           }
//                         >
//                           {section.SectionName}
//                         </button>
//                       ))}
//                     </div>
//                   )}
//                 </div>
//                 <div className="mocktest_conatiner_testwithpalletquizcontainersubintopart">
//                   {selectedSubject && currentQuestion && (
//                     <div>
//                       <p>Question {currentQuestion.question_id}:</p>
//                       <img src={currentQuestion.questionImgName} alt="" />

//                       <div className="optionimginmock">
//                         {(currentQuestion.qtype === "MCQ4" ||
//                           currentQuestion.qtype === "MCQ5") && (
//                           <div className="optionimginmock">
//                             <p>Options:</p>
//                             {currentQuestion.options.map((option) => (
//                               <label key={option.option_id} className="option">
//                                 <input
//                                   type="radio"
//                                   id={`option-${option.option_id}`}
//                                   name={`question-${currentQuestion.question_id}`}
//                                   checked={
//                                     responses[
//                                       getQuestionKey(
//                                         selectedSubject,
//                                         currentSectionIndex,
//                                         currentQuestion.question_id
//                                       )
//                                     ] === option.option_index
//                                   }
//                                   onChange={() =>
//                                     handleOptionChange(option.option_index)
//                                   }
//                                 />
//                                 <span htmlFor={`option-${option.option_id}`}>
//                                   ({option.option_index})
//                                 </span>
//                                 <img src={option.optionImgName} alt="" />
//                               </label>
//                             ))}
//                           </div>
//                         )}
//                         {(currentQuestion.qtype === "MSQ" ||
//                           currentQuestion.qtype === "MSQN") && (
//                           <div className="optionimginmock">
//                             <p>Options:</p>
//                             {currentQuestion.options.map((option) => (
//                               <label key={option.option_id} className="option">
//                                 <input
//                                   type="checkbox"
//                                   id={`option-${option.option_id}`}
//                                   // name={`question-${currentQuestion.question_id}`}
//                                   checked={
//                                     responses[
//                                       getQuestionKey(
//                                         selectedSubject,
//                                         currentSectionIndex,
//                                         currentQuestion.question_id
//                                       )
//                                     ] &&
//                                     responses[
//                                       getQuestionKey(
//                                         selectedSubject,
//                                         currentSectionIndex,
//                                         currentQuestion.question_id
//                                       )
//                                     ].includes(option.option_index)
//                                   }
//                                   onChange={() =>
//                                     handleCheckboxChange(option.option_index)
//                                   }
//                                 />
//                                 <span htmlFor={`option-${option.option_id}`}>
//                                   ({option.option_index})
//                                 </span>
//                                 <img src={option.optionImgName} alt="" />
//                               </label>
//                             ))}
//                           </div>
//                         )}

//                         {currentQuestion.qtype === "NATI" && (
//                           <div className="calculator">
//                             <div className="display">
//                               <input
//                                 type="text"
//                                 value={getInputValue(
//                                   selectedSubject,
//                                   currentSectionIndex,
//                                   currentQuestion.question_id
//                                 )}
//                                 readOnly // Make the input read-only to prevent direct editing
//                                 placeholder="Enter your answer NATI"
//                               />
//                             </div>

//                             {/* Button for DEL */}
//                             <div>
//                               <input
//                                 type="button"
//                                 value="DEL"
//                                 onClick={() => {
//                                   const key = getQuestionKey(
//                                     selectedSubject,
//                                     currentSectionIndex,
//                                     currentQuestion.question_id
//                                   );
//                                   const currentValue =
//                                     getInputValue(
//                                       currentQuestion.question_id
//                                     ) || "";
//                                   updateInputValue(
//                                     selectedSubject,
//                                     currentSectionIndex,
//                                     currentQuestion.question_id,
//                                     (getInputValue(
//                                       currentQuestion.question_id
//                                     ) || "") + "DEL"
//                                   );
//                                 }}
//                               />
//                             </div>

//                             {/* Buttons for numbers */}
//                             <div>
//                               <div>
//                                 {[7, 8, 9].map((num) => (
//                                   <input
//                                     key={num}
//                                     type="button"
//                                     value={num}
//                                     // onClick={() =>
//                                     //   updateInputValue(
//                                     //     currentQuestion.question_id,
//                                     //     (getInputValue(
//                                     //       currentQuestion.question_id
//                                     //     ) || "") + num
//                                     //   )
//                                     // }
//                                     onClick={() => {
//                                       const key = getQuestionKey(
//                                         selectedSubject,
//                                         currentSectionIndex,
//                                         currentQuestion.question_id
//                                       );
//                                       const currentValue =
//                                         getInputValue(
//                                           currentQuestion.question_id
//                                         ) || "";
//                                       updateInputValue(
//                                         selectedSubject,
//                                         currentSectionIndex,
//                                         currentQuestion.question_id,
//                                         (getInputValue(
//                                           currentQuestion.question_id
//                                         ) || "") + num
//                                       );
//                                     }}
//                                   />
//                                 ))}
//                               </div>
//                               <div>
//                                 {[4, 5, 6].map((num) => (
//                                   <input
//                                     key={num}
//                                     type="button"
//                                     value={num}
//                                     onClick={() => {
//                                       const key = getQuestionKey(
//                                         selectedSubject,
//                                         currentSectionIndex,
//                                         currentQuestion.question_id
//                                       );
//                                       const currentValue =
//                                         getInputValue(
//                                           currentQuestion.question_id
//                                         ) || "";
//                                       updateInputValue(
//                                         selectedSubject,
//                                         currentSectionIndex,
//                                         currentQuestion.question_id,
//                                         (getInputValue(
//                                           currentQuestion.question_id
//                                         ) || "") + num
//                                       );
//                                     }}
//                                   />
//                                 ))}
//                               </div>
//                               <div>
//                                 {[1, 2, 3].map((num) => (
//                                   <input
//                                     key={num}
//                                     type="button"
//                                     value={num}
//                                     onClick={() => {
//                                       const key = getQuestionKey(
//                                         selectedSubject,
//                                         currentSectionIndex,
//                                         currentQuestion.question_id
//                                       );
//                                       const currentValue =
//                                         getInputValue(
//                                           currentQuestion.question_id
//                                         ) || "";
//                                       updateInputValue(
//                                         selectedSubject,
//                                         currentSectionIndex,
//                                         currentQuestion.question_id,
//                                         (getInputValue(
//                                           currentQuestion.question_id
//                                         ) || "") + num
//                                       );
//                                     }}
//                                   />
//                                 ))}
//                               </div>
//                               <div>
//                                 {[0].map((num) => (
//                                   <input
//                                     key={num}
//                                     type="button"
//                                     value={num}
//                                     onClick={() => {
//                                       const key = getQuestionKey(
//                                         selectedSubject,
//                                         currentSectionIndex,
//                                         currentQuestion.question_id
//                                       );
//                                       const currentValue =
//                                         getInputValue(
//                                           currentQuestion.question_id
//                                         ) || "";
//                                       updateInputValue(
//                                         selectedSubject,
//                                         currentSectionIndex,
//                                         currentQuestion.question_id,
//                                         (getInputValue(
//                                           currentQuestion.question_id
//                                         ) || "") + num
//                                       );
//                                     }}
//                                   />
//                                 ))}
//                                 <input
//                                   type="button"
//                                   value="."
//                                   onClick={() => {
//                                     const key = getQuestionKey(
//                                       selectedSubject,
//                                       currentSectionIndex,
//                                       currentQuestion.question_id
//                                     );
//                                     const currentValue =
//                                       getInputValue(
//                                         currentQuestion.question_id
//                                       ) || "";
//                                     updateInputValue(
//                                       selectedSubject,
//                                       currentSectionIndex,
//                                       currentQuestion.question_id,
//                                       (getInputValue(
//                                         currentQuestion.question_id
//                                       ) || "") + "."
//                                     );
//                                   }}
//                                 />
//                                 <input
//                                   type="button"
//                                   value="-"
//                                   onClick={() => {
//                                     const key = getQuestionKey(
//                                       selectedSubject,
//                                       currentSectionIndex,
//                                       currentQuestion.question_id
//                                     );
//                                     const currentValue =
//                                       getInputValue(
//                                         currentQuestion.question_id
//                                       ) || "";
//                                     updateInputValue(
//                                       selectedSubject,
//                                       currentSectionIndex,
//                                       currentQuestion.question_id,
//                                       (getInputValue(
//                                         currentQuestion.question_id
//                                       ) || "") + "-"
//                                     );
//                                   }}
//                                 />
//                               </div>
//                             </div>

//                             {/* Buttons for '.' and '-' */}
//                           </div>
//                         )}
//                         {currentQuestion.qtype === "NATD" && (
//                           <div className="calculator">
//                             <div className="display">
//                               <input
//                                 type="text"
//                                 value={getInputValue(
//                                   selectedSubject,
//                                   currentSectionIndex,
//                                   currentQuestion.question_id
//                                 )}
//                                 readOnly // Make the input read-only to prevent direct editing
//                                 placeholder="Enter your answer NATD"
//                               />
//                             </div>

//                             {/* Button for DEL */}
//                             <div>
//                               <input
//                                 type="button"
//                                 value="DEL"
//                                 onClick={() => {
//                                   const key = getQuestionKey(
//                                     selectedSubject,
//                                     currentSectionIndex,
//                                     currentQuestion.question_id
//                                   );
//                                   const currentValue =
//                                     getInputValue(
//                                       currentQuestion.question_id
//                                     ) || "";
//                                   updateInputValue(
//                                     selectedSubject,
//                                     currentSectionIndex,
//                                     currentQuestion.question_id,
//                                     currentValue.slice(0, -1)
//                                   );
//                                 }}
//                               />
//                             </div>

//                             {/* Buttons for numbers */}
//                             <div>
//                               <div>
//                                 {[7, 8, 9].map((num) => (
//                                   <input
//                                     key={num}
//                                     type="button"
//                                     value={num}
//                                     // onClick={() =>
//                                     //   updateInputValue(
//                                     //     currentQuestion.question_id,
//                                     //     (getInputValue(
//                                     //       currentQuestion.question_id
//                                     //     ) || "") + num
//                                     //   )
//                                     // }
//                                     onClick={() => {
//                                       const key = getQuestionKey(
//                                         selectedSubject,
//                                         currentSectionIndex,
//                                         currentQuestion.question_id
//                                       );
//                                       const currentValue =
//                                         getInputValue(
//                                           currentQuestion.question_id
//                                         ) || "";
//                                       updateInputValue(
//                                         selectedSubject,
//                                         currentSectionIndex,
//                                         currentQuestion.question_id,
//                                         (getInputValue(
//                                           currentQuestion.question_id
//                                         ) || "") + num
//                                       );
//                                     }}
//                                   />
//                                 ))}
//                               </div>
//                               <div>
//                                 {[4, 5, 6].map((num) => (
//                                   <input
//                                     key={num}
//                                     type="button"
//                                     value={num}
//                                     onClick={() => {
//                                       const key = getQuestionKey(
//                                         selectedSubject,
//                                         currentSectionIndex,
//                                         currentQuestion.question_id
//                                       );
//                                       const currentValue =
//                                         getInputValue(
//                                           currentQuestion.question_id
//                                         ) || "";
//                                       updateInputValue(
//                                         selectedSubject,
//                                         currentSectionIndex,
//                                         currentQuestion.question_id,
//                                         (getInputValue(
//                                           currentQuestion.question_id
//                                         ) || "") + num
//                                       );
//                                     }}
//                                   />
//                                 ))}
//                               </div>
//                               <div>
//                                 {[1, 2, 3].map((num) => (
//                                   <input
//                                     key={num}
//                                     type="button"
//                                     value={num}
//                                     onClick={() => {
//                                       const key = getQuestionKey(
//                                         selectedSubject,
//                                         currentSectionIndex,
//                                         currentQuestion.question_id
//                                       );
//                                       const currentValue =
//                                         getInputValue(
//                                           currentQuestion.question_id
//                                         ) || "";
//                                       updateInputValue(
//                                         selectedSubject,
//                                         currentSectionIndex,
//                                         currentQuestion.question_id,
//                                         (getInputValue(
//                                           currentQuestion.question_id
//                                         ) || "") + num
//                                       );
//                                     }}
//                                   />
//                                 ))}
//                               </div>
//                               <div>
//                                 {[0].map((num) => (
//                                   <input
//                                     key={num}
//                                     type="button"
//                                     value={num}
//                                     onClick={() => {
//                                       const key = getQuestionKey(
//                                         selectedSubject,
//                                         currentSectionIndex,
//                                         currentQuestion.question_id
//                                       );
//                                       const currentValue =
//                                         getInputValue(
//                                           currentQuestion.question_id
//                                         ) || "";
//                                       updateInputValue(
//                                         selectedSubject,
//                                         currentSectionIndex,
//                                         currentQuestion.question_id,
//                                         (getInputValue(
//                                           currentQuestion.question_id
//                                         ) || "") + num
//                                       );
//                                     }}
//                                   />
//                                 ))}
//                                 <input
//                                   type="button"
//                                   value="."
//                                   onClick={() => {
//                                     const key = getQuestionKey(
//                                       selectedSubject,
//                                       currentSectionIndex,
//                                       currentQuestion.question_id
//                                     );
//                                     const currentValue =
//                                       getInputValue(
//                                         currentQuestion.question_id
//                                       ) || "";
//                                     updateInputValue(
//                                       selectedSubject,
//                                       currentSectionIndex,
//                                       currentQuestion.question_id,
//                                       (getInputValue(
//                                         currentQuestion.question_id
//                                       ) || "") + "."
//                                     );
//                                   }}
//                                 />
//                                 <input
//                                   type="button"
//                                   value="-"
//                                   onClick={() => {
//                                     const key = getQuestionKey(
//                                       selectedSubject,
//                                       currentSectionIndex,
//                                       currentQuestion.question_id
//                                     );
//                                     const currentValue =
//                                       getInputValue(
//                                         currentQuestion.question_id
//                                       ) || "";
//                                     updateInputValue(
//                                       selectedSubject,
//                                       currentSectionIndex,
//                                       currentQuestion.question_id,
//                                       (getInputValue(
//                                         currentQuestion.question_id
//                                       ) || "") + "-"
//                                     );
//                                   }}
//                                 />
//                               </div>
//                             </div>

//                             {/* Buttons for '.' and '-' */}
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                   )}
//                 </div>

//                 {/* -------------------------------------------buttons Option container-------------- */}
//                 <div>
//                   <div class="mocktest_conatiner_testwithpalletbuttons">
//                     {" "}
//                     <div>
//                       <button
//                         class="Quiz_clearResponse Quiz_mockbtns Quiz_mockbtnsclear"
//                         onClick={clearResponse}
//                       >
//                         Clear Response
//                       </button>
//                       {/* <button onClick={markForReview}>Mark For Review</button> */}
//                       <button
//                         class="quizsave_next previous_next_btn Quiz_mockbtns"
//                         onClick={saveAndNext}
//                       >
//                         Save &amp; Next
//                       </button>
//                     </div>
//                     <div>
//                       <button
//                         class="Quiz_mockbtns Quiz_mockbtnspre"
//                         onClick={previousQuestion}
//                       >
//                         Previous
//                       </button>
//                       <button
//                         class="Quiz_mockbtns Quiz_mockbtnsnext"
//                         onClick={nextQuestion}
//                       >
//                         Next
//                       </button>
//                       <button
//                         class="Quiz_mockbtns Quiz_mockbtnssubmit"
//                         onClick={handleSubmit}
//                       >
//                         Submit
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div>
//               <div className="numberpalette">
//                 <div class="rightSidebar-topHeader">
//                   <img
//                     title="Guest"
//                     src="/static/media/guesticon.00ad06aaa8a7af8eb6c5.jpg"
//                     alt="guesticon"
//                   />
//                   <p>Candidate Name: Guest</p>
//                 </div>
//                 <div className="yoursection_inMockTest">
//                   <span>
//                     You are viewing{" "}
//                     <label htmlFor="">{currentSubject.SubjectName}</label>
//                     Section{" "}
//                   </span>
//                   <b>Question Palette</b>
//                 </div>
//                 <div className="question-number-palette question-numberpalette question-palette">
//                   {currentSection.questions.map((question, index) => {
//                     const key = getQuestionKey(
//                       selectedSubject,
//                       currentSectionIndex,
//                       question.question_id
//                     );
//                     return (
//                       <button
//                         key={question.question_id}
//                         className={`
//                     ${index === currentQuestionIndex ? "active" : ""}
//                     ${
//                       answeredQuestions[key] && !markedForReview[key]
//                         ? "answered"
//                         : ""
//                     }
//                     ${viewedQuestions[key] && !responses[key] ? "viewed" : ""}
//                     ${
//                       markedForReview[key]
//                         ? responses[key]
//                           ? "marked-answered"
//                           : "marked"
//                         : ""
//                     }
//                     ${
//                       responses[key] !== undefined && !answeredQuestions[key]
//                         ? "answered-red active"
//                         : ""
//                     }
//                   `}
//                         onClick={() => goToQuestion(index)}
//                       >
//                         {index + 1}
//                       </button>
//                     );
//                   })}
//                 </div>
//                 <div className="sidebar-footer">
//                   <h4 className="sidebar-footer-header">Legend:</h4>
//                   <div className="footer-btns">
//                     <div className="inst-btns">
//                       {/* <img src={grayBox} alt="Not Visited" /> */}
//                       <span className="NotVisited">
//                         {counts.notVisitedCount}
//                       </span>

//                       <label htmlFor="">Not Visited</label>
//                     </div>
//                     <div className="inst-btns">
//                       {/* <img src={greenBox} alt="Answered" /> */}
//                       <span className="Answered">{answeredCount}</span>
//                       <label htmlFor="">Answered</label>
//                     </div>
//                     <div className="inst-btns">
//                       {/* <img src={orangeBox} alt="Not Answered" /> */}
//                       <span className="NotAnsweredlegend">
//                         {" "}
//                         {notAnsweredCount}
//                       </span>
//                       <label htmlFor="">Not Answered</label>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="scorecardforexam">
//         {showPopup && (
//           <div className="popup">
//             <div className="popup-content">
//               {remainingTime === 0 ? (
//                 <div>
//                   <p>Your Time is up!</p>
//                   <p>Your test is automatically submitted successfully.</p>
//                 </div>
//               ) : (
//                 <p>You have successfully submitted your test.</p>
//               )}
//               <p>
//                 <img
//                   src="/static/media/prize.e48700d2bb773b1eda58.jpg"
//                   alt="Congratulations"
//                 />
//                 <span>
//                   <h2>Congratulations!</h2>
//                   <span class="mockscore">Your score: {totalMarks}</span>
//                 </span>
//               </p>

//               <ul class="allscorecountingmock">
//                 <li>
//                   <b>Answered:</b> {answeredCount}
//                 </li>
//                 <li>
//                   <b>Not Answered:</b> {notAnsweredCount}
//                 </li>
//                 <li>
//                   <b>Right Answered:</b>
//                   {correctAnswersCount}
//                 </li>
//                 <li>
//                   <b>Wrong Answered:</b> {wrongAnswersCount}
//                 </li>
//               </ul>
//               <button onClick={closePopup}>Close</button>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Jee_advance_1;


import React, { useState, useEffect } from "react";
import "./Jee_advance.css";
import JEEquestionsData from "./Jee_advance.json";


function Jee_advance_1() {
  const [selectedSubject, setSelectedSubject] = useState(
    JEEquestionsData.length > 0 ? JEEquestionsData[0].SubjectName : null
  );
  const [awardedMarks, setAwardedMarks] = useState({});
const [sectionBAnsweredCount, setSectionBAnsweredCount] = useState(0);
  const [inputValues, setInputValues] = useState({});
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responses, setResponses] = useState({});
  const [viewedQuestions, setViewedQuestions] = useState({});
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [markedForReview, setMarkedForReview] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [remainingTime, setRemainingTime] = useState(3 * 60 * 60);
  const [isAutoSubmit, setIsAutoSubmit] = useState(false);
  const getQuestionKey = (subject, sectionIndex, questionId, inputValue) => {
    return `${subject}-${sectionIndex}-${questionId}-${inputValue}`;
  };

  const [totalMarks, setTotalMarks] = useState(0);
  // const [rightAnsweredCount, setrightAnsweredCount] = useState(0);
  // const [wrongAnsweredCount, setwrongAnsweredCount] = useState(0);
  const [answeredCount, setAnsweredCount] = useState(0);
  const [notAnsweredCount, setNotAnsweredCount] = useState(0);
const [rightAnsweredCount, setRightAnsweredCount] = useState(0);
const [wrongAnsweredCount, setWrongAnsweredCount] = useState(0);
  const updateInputValue = (
    selectedSubject,
    currentSectionIndex,
    questionId,
    num
  ) => {
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      questionId
    );
    const currentValue = getInputValue(
      selectedSubject,
      currentSectionIndex,
      questionId
    );

    let newValue = currentValue; // Initialize newValue with currentValue

    // If 'DEL' button is clicked and current value is not empty, remove the last character
    if (num === "DEL" && currentValue.length > 0) {
      newValue = currentValue.slice(0, -1);
    } else if (num !== "DEL") {
      // Only concatenate num if it's not "DEL"
      // Concatenate the new number to the current value for other cases
      newValue += num;
    }

    // Update input values with the new value
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [key]: newValue,
    }));
  };

  const getInputValue = (selectedSubject, currentSectionIndex, questionId) => {
    // Get the key using the provided parameters
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      questionId
    );
    // console.log("Getting input value for key:", key); // <-- Move this line here

    // Retrieve the input value for the specified key from the inputValues state
    return inputValues[key] || "";
  };

  const moveToNextQuestion = (
    selectedSubject,
    currentSectionIndex,
    questionId
  ) => {
    // Clear/reset the input value when moving to the next question
    setInputValues({
      ...inputValues,
      [questionId]: "",
      [currentSectionIndex]: "",
      [selectedSubject]: "",
    });
  };

  const returnToPreviousQuestion = (
    selectedSubject,
    currentSectionIndex,
    questionId
  ) => {
    // Find the index of the current question in the section
    const sectionIndex = JEEquestionsData.findIndex(
      (subject) => subject.SubjectName === selectedSubject
    );
    const section = JEEquestionsData[sectionIndex];

    const questionIndex = section.questions.findIndex(
      (question) => question.question_id === questionId
    );

    if (questionIndex > 0) {
      // If the current question is not the first question in the section
      // Navigate to the previous question
      const previousQuestion = section.questions[questionIndex - 1];
      // Update the input value with the previous question's value
      setInputValues(
        getInputValue(
          selectedSubject,
          currentSectionIndex,
          previousQuestion.question_id
        )
      );
    } else {
      // If the current question is the first question in the section,
      // Navigate to the last question of the previous section
      const previousSectionIndex = sectionIndex - 1;
      if (previousSectionIndex >= 0) {
        const previousSection = JEEquestionsData[previousSectionIndex];
        const lastQuestionIndex = previousSection.questions.length - 1;
        const lastQuestion = previousSection.questions[lastQuestionIndex];
        // Update the input value with the last question's value of the previous section
        setInputValues(
          getInputValue(
            selectedSubject,
            previousSectionIndex,
            lastQuestion.question_id
          )
        );
      } else {
        // If there is no previous section, do nothing
        console.log(
          "This is the first question, there is no previous question."
        );
      }
    }
  };


  useEffect(() => {
    let count = 0;
    JEEquestionsData.forEach((subject) => {
      subject.sections.forEach((section) => {
        count += section.questions.length;
      });
    });
    setNotAnsweredCount(count);
  }, []);
  const handleOptionChange = (option) => {
    console.log("Selected option:", option);
    if (currentQuestion) {
      const key = getQuestionKey(
        selectedSubject,
        currentSectionIndex,
        currentQuestion.question_id
      );
      console.log("Key:", key);
      setResponses({ ...responses, [key]: option });
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    if (remainingTime === 0) {
      setIsAutoSubmit(true);
      setShowPopup(true);
      // handleAutoSubmit();
    }
  }, [remainingTime]);

  // Function to clear response for the current question
  const clearResponse = () => {
    // Construct the key for the current question
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    // Get the current input value
    const valueToClear = getInputValue(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    // Log the current value before clearing it
    console.log("Value before clearing:", valueToClear);

    // Call updateInputValue to clear the input value
    updateInputValue(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id,
      "" // Pass an empty string to clear the input value
    );

    // Log the updated input values after clearing
    console.log("Updated input values:", inputValues);

    // Other operations in clearResponse function...
    // setInputValues({
    //   ...inputValues,
    //   [currentQuestion.question_id]: "", // Clear input value in state
    //   [currentSectionIndex]: "", // Clear other related values if needed
    //   [selectedSubject]: "", // Clear other related values if needed
    // });
    setInputValues({
      ...inputValues,
      [key]: [], // Clear other related values if needed
    });

    // Clear response, mark the question as viewed but not answered, and decrement answered count
    setResponses({ ...responses, [key]: [] }); // Set response to an empty array

    // Mark the question as viewed but not answered, and decrement answered count
    setViewedQuestions({ ...viewedQuestions, [key]: true });
    setAnsweredQuestions({ ...answeredQuestions, [key]: false });
    // setAnsweredCount((prevAnsweredCount) => prevAnsweredCount - 1);
    // Decrease the count only if the question was previously answered
    if (answeredCount > 0) {
      setAnsweredCount((prevAnsweredCount) => prevAnsweredCount - 1);
    }
  };

  const moveToQuestion = (newSectionIndex, newQuestionIndex) => {
    const key = getQuestionKey(
      selectedSubject,
      newSectionIndex,
      currentSubject.sections[newSectionIndex].questions[newQuestionIndex]
        .question_id
    );
    setViewedQuestions({ ...viewedQuestions, [key]: true });
    setCurrentSectionIndex(newSectionIndex);
    setCurrentQuestionIndex(newQuestionIndex);
  };

  const nextQuestion = () => {
    const currentSection = currentSubject.sections[currentSectionIndex];
    if (currentQuestionIndex < currentSection.questions.length - 1) {
      moveToQuestion(currentSectionIndex, currentQuestionIndex + 1);
    } else {
      const currentSubjectIndex = JEEquestionsData.findIndex(
        (subject) => subject.SubjectName === selectedSubject
      );
      const nextSectionIndex = currentSectionIndex + 1;
      if (nextSectionIndex < currentSubject.sections.length) {
        setCurrentSectionIndex(nextSectionIndex);
        setCurrentQuestionIndex(0);
      } else if (currentSubjectIndex < JEEquestionsData.length - 1) {
        const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
        setSelectedSubject(nextSubject.SubjectName);
        setCurrentSectionIndex(0);
        setCurrentQuestionIndex(0);
      }
    }
  };

  const previousQuestion = () => {
    if (currentQuestionIndex > 0) {
      moveToQuestion(currentSectionIndex, currentQuestionIndex - 1);
    } else {
      const currentSubjectIndex = JEEquestionsData.findIndex(
        (subject) => subject.SubjectName === selectedSubject
      );
      const prevSectionIndex = currentSectionIndex - 1;
      if (prevSectionIndex >= 0) {
        setCurrentSectionIndex(prevSectionIndex);
        const prevSection = currentSubject.sections[prevSectionIndex];
        setCurrentQuestionIndex(prevSection.questions.length - 1);
      } else if (currentSubjectIndex > 0) {
        const prevSubject = JEEquestionsData[currentSubjectIndex - 1];
        setSelectedSubject(prevSubject.SubjectName);
        const lastSectionIndex = prevSubject.sections.length - 1;
        setCurrentSectionIndex(lastSectionIndex);
        setCurrentQuestionIndex(
          prevSubject.sections[lastSectionIndex].questions.length - 1
        );
      }
    }
  };
   // Declare state variables for previous answers and inputs
  //  const [previousAnswers, setPreviousAnswers] = useState({});
  //  const [previousInputs, setPreviousInputs] = useState({});
   
  
// working without repeating except checkboxes
  // const saveAndNext = () => {
  //   // Retrieve current question details
  //   const currentSection = currentSubject.sections[currentSectionIndex];
  //   const key = getQuestionKey(
  //     selectedSubject,
  //     currentSectionIndex,
  //     currentQuestion.question_id
  //   );
  //   const inputValue = getInputValue(selectedSubject, currentSectionIndex, currentQuestion.question_id)?.trim().toLowerCase();
  
  //   // Log key and input value for debugging
  //   console.log(`Key for question ${currentQuestion.question_id}:`, key);
  //   console.log(`Retrieved input value for question ${currentQuestion.question_id}:`, inputValue);
  
  //   // Check if a response exists for the current question
  //   if (responses[key] !== undefined || inputValue !== "") {
  //     // Only proceed if the answer has changed or is new
  //     if (responses[key] !== previousAnswers[key] || inputValue !== previousInputs[key]) {
  //       // Store the latest answer and input value
  //       setPreviousAnswers({ ...previousAnswers, [key]: responses[key] });
  //       setPreviousInputs({ ...previousInputs, [key]: inputValue });
  
  //       // Set the question as answered
  //       setAnsweredQuestions({ ...answeredQuestions, [key]: true });
  
  //       // Extract correct answer and marks from JSON data
  //       const correctAnswer = currentQuestion.answer?.trim().toLowerCase(); // Assuming answer is in lowercase
  //       const marks = currentQuestion.marks;
  
  //       console.log("Correct Answer:", correctAnswer);
  //       console.log("User Response for options selected:", responses[key]);
  //       console.log("User Response entered value:", inputValue);
  
  //       // Check if the marks are defined
  //       if (marks) {
  //         const marksArray = marks.split(",");
  //         const correctMark = parseInt(marksArray[0], 10);
  //         const wrongMark = parseInt(marksArray[1], 10);
  
  //         // Ensure responses[key] is a string before using trim and toLowerCase
  //         const userResponse = responses[key] !== undefined ? responses[key].toString().trim().toLowerCase() : "";
  
  //         // Compare user response with correct answer
  //         const isCorrectResponse =
  //           userResponse === correctAnswer || (inputValue !== "" && inputValue === correctAnswer);
  
  //         if (isCorrectResponse) {
  //           // Increment correct answers count and update total marks only if the answer has changed
  //           console.log("Response is correct.");
  //           if (responses[key] !== previousAnswers[key] || inputValue !== previousInputs[key]) {
  //             setrightAnsweredCount((prevCount) => prevCount + 1);
  //             setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
  //             console.log("Marks Added:", correctMark);
  //           }
  //         } else {
  //           // Increment wrong answers count and subtract marks only if the answer has changed
  //           console.log("Response is incorrect.");
  //           if (responses[key] !== previousAnswers[key] || inputValue !== previousInputs[key]) {
  //             setwrongAnsweredCount((prevCount) => prevCount + 1);
  //             setTotalMarks((prevTotalMarks) => Math.max(0, prevTotalMarks - wrongMark)); // Subtract marks for incorrect answer, ensuring total marks don't go below 0
  //             console.log("Marks Deducted:", wrongMark);
  //           }
  //         }
  //       }
  
  //       // Update counts for answered and not answered questions
  //       setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
  //       if (!answeredQuestions[key]) {
  //         setNotAnsweredCount((prevCount) => prevCount - 1);
  //       }
  //     }
  
  //     // Move to the next question if available, else move to the next section or subject
  //     if (currentQuestionIndex < currentSection.questions.length - 1) {
  //       nextQuestion();
  //     } else {
  //       const currentSubjectIndex = JEEquestionsData.findIndex(
  //         (subject) => subject.SubjectName === selectedSubject
  //       );
  //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
  //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
  //         setSelectedSubject(nextSubject.SubjectName);
  //         setCurrentSectionIndex(0);
  //         setCurrentQuestionIndex(0);
  //       }
  //     }
  //   } else {
  //     // If the response is undefined, prompt the user to answer the question before proceeding
  //     alert("Please answer the current question before proceeding.");
  //   }
  // };
  
  
  //with natd range
  // const saveAndNext = () => {
  //   // Retrieve current question details
  //   const currentSection = currentSubject.sections[currentSectionIndex];
  //   const key = getQuestionKey(
  //     selectedSubject,
  //     currentSectionIndex,
  //     currentQuestion.question_id
  //   );
  //   const inputValue = getInputValue(selectedSubject, currentSectionIndex, currentQuestion.question_id)?.trim().toLowerCase();
  
  //   // Log key and input value for debugging
  //   console.log(`Key for question ${currentQuestion.question_id}:`, key);
  //   console.log(`Retrieved input value for question ${currentQuestion.question_id}:`, inputValue);
  
  //   // Check if a response exists for the current question
  //   if (responses[key] !== undefined || inputValue !== "") {
  //     // Set the question as answered
  //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
  
  //     // Extract correct answer and marks from JSON data
  //     const correctAnswer = currentQuestion.answer?.trim().toLowerCase(); // Assuming answer is in lowercase
  //     const marks = currentQuestion.marks;
  
  //     console.log("Correct Answer:", correctAnswer);
  //     console.log("User Response for options selected:", responses[key]);
  //     console.log("User Response entered value:", inputValue);
  
  //     // Check if the marks are defined
  //     if (marks) {
  //       const marksArray = marks.split(",");
  //       const correctMark = parseInt(marksArray[0], 10);
  //       const wrongMark = -1; // Deduct 1 mark for wrong answers
  
  //       // Ensure responses[key] is a string before using trim and toLowerCase
  //       const userResponse = responses[key] !== undefined ? responses[key].toString().trim().toLowerCase() : "";
  
  //       console.log("User Response:", userResponse);
  
  //       // Function to calculate awarded marks
  //       const calculateMarks = (isCorrectResponse, correctMark, wrongMark) => {
  //         return isCorrectResponse ? correctMark : wrongMark;
  //       };
  
  //       // Check the question type
  //       let isCorrectResponse = false;
  //       let newMarksAwarded = 0;
  //       let wasPreviouslyCorrect = awardedMarks[key] && awardedMarks[key] > 0;
  
  //       if (["MCQ4", "MCQ5", "MSQ", "MSQN", "NATI"].includes(currentQuestion.qtype)) {
  //         // Code for MCQs, MSQs, and NATI
  //         isCorrectResponse =
  //           userResponse === correctAnswer || (inputValue !== "" && inputValue === correctAnswer);
  
  //         newMarksAwarded = calculateMarks(isCorrectResponse, correctMark, wrongMark);
  
  //         // Update total marks
  //         const previousMarks = awardedMarks[key] || 0;
  //         setTotalMarks(prevTotalMarks => {
  //           const newTotalMarks = prevTotalMarks - previousMarks + newMarksAwarded;
  //           return Math.max(newTotalMarks, 0); // Ensure total marks do not go negative
  //         });
  
  //         // Update counts based on whether the response was correct or incorrect
  //         if (isCorrectResponse) {
  //           if (!wasPreviouslyCorrect) {
  //             setRightAnsweredCount(prevCount => prevCount + 1);
  //             if (previousMarks < 0) setWrongAnsweredCount(prevCount => prevCount - 1); // Adjust wrong answer count if it was previously wrong
  //           }
  //         } else {
  //           if (wasPreviouslyCorrect) {
  //             setRightAnsweredCount(prevCount => prevCount - 1);
  //           }
  //           setWrongAnsweredCount(prevCount => prevCount + 1); // Increment wrong answer count
  //         }
  
  //         // Update awarded marks for this question
  //         awardedMarks[key] = newMarksAwarded;
  
  //         console.log("Marks Awarded:", newMarksAwarded);
  
  //       } else if (currentQuestion.qtype === "NATD") {
  //         // Numeric Answer Type with range (NATD)
  //         const [lowerRange, upperRange] = correctAnswer.split("-").map(range => parseFloat(range));
  
  //         console.log("Lower Range:", lowerRange);
  //         console.log("Upper Range:", upperRange);
  
  //         const isWithinRange = (inputValue !== "" && correctAnswer.includes("-") &&
  //           inputValue >= lowerRange && inputValue <= upperRange);
  
  //         console.log("Is Within Range:", isWithinRange);
  
  //         newMarksAwarded = calculateMarks(isWithinRange, correctMark, wrongMark);
  
  //         // Update total marks
  //         const previousMarks = awardedMarks[key] || 0;
  //         setTotalMarks(prevTotalMarks => {
  //           const newTotalMarks = prevTotalMarks - previousMarks + newMarksAwarded;
  //           return Math.max(newTotalMarks, 0); // Ensure total marks do not go negative
  //         });
  
  //         // Update counts based on whether the response was correct or incorrect
  //         if (isWithinRange) {
  //           if (!wasPreviouslyCorrect) {
  //             setRightAnsweredCount(prevCount => prevCount + 1);
  //             if (previousMarks < 0) setWrongAnsweredCount(prevCount => prevCount - 1); // Adjust wrong answer count if it was previously wrong
  //           }
  //         } else {
  //           if (wasPreviouslyCorrect) {
  //             setRightAnsweredCount(prevCount => prevCount - 1);
  //           }
  //           setWrongAnsweredCount(prevCount => prevCount + 1); // Increment wrong answer count
  //         }
  
  //         // Update awarded marks for this question
  //         awardedMarks[key] = newMarksAwarded;
  
  //         console.log("Marks Awarded for NATD:", newMarksAwarded);
  //       }
  
  //       // Log updated total marks
  //       console.log("Updated Total Marks:", totalMarks);
  
  //       // Check if there was an error in the calculation
  //       if (totalMarks !== calculateCorrectTotalMarks()) {
  //         console.error("Total marks mismatch detected! Expected:", calculateCorrectTotalMarks(), "Got:", totalMarks);
  //       }
  //     }
  
  //     // Update counts for answered questions
  //     if (!answeredQuestions[key]) {
  //       setAnsweredCount(prevAnsweredCount => prevAnsweredCount + 1);
  //       setNotAnsweredCount(prevCount => prevCount - 1);
  //     }
  
  //     // Increment the count for section-B questions answered
  //     if (currentSectionIndex === 1) {
  //       setSectionBAnsweredCount(prevCount => prevCount + 1);
  //     }
  
  //     // Move to the next question if available, else move to the next section or subject
  //     if (currentQuestionIndex < currentSection.questions.length - 1) {
  //       nextQuestion();
  //     } else {
  //       const currentSubjectIndex = JEEquestionsData.findIndex(
  //         subject => subject.SubjectName === selectedSubject
  //       );
  //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
  //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
  //         setSelectedSubject(nextSubject.SubjectName);
  //         setCurrentSectionIndex(0);
  //         setCurrentQuestionIndex(0);
  //       }
  //     }
  //   } else {
  //     // If the response is undefined, prompt the user to answer the question before proceeding
  //     alert("Please answer the current question before proceeding.");
  //   }
  // };
  
  // const calculateCorrectTotalMarks = () => {
  //   // Implement the logic to calculate the correct total marks based on all awarded marks
  //   return Object.values(awardedMarks).reduce((acc, mark) => acc + mark, 0);
  // };
  
  //main sindhu
  // const saveAndNext = () => {
  //   // Retrieve current question details
  //   const currentSection = currentSubject.sections[currentSectionIndex];
  //   const key = getQuestionKey(
  //     selectedSubject,
  //     currentSectionIndex,
  //     currentQuestion.question_id
  //   );
  //   const inputValue = getInputValue(selectedSubject, currentSectionIndex, currentQuestion.question_id)?.trim().toLowerCase();
  
  //   // Log key and input value for debugging
  //   console.log(`Key for question ${currentQuestion.question_id}:`, key);
  //   console.log(`Retrieved input value for question ${currentQuestion.question_id}:`, inputValue);
  
  //   // Determine if the question was previously answered
  //   const wasPreviouslyAnswered = awardedMarks[key] !== undefined;
  //   const wasPreviouslyCorrect = awardedMarks[key] && awardedMarks[key] > 0;
  //   const previousMarks = awardedMarks[key] || 0;
  
  //   // Check if a response exists for the current question
  //   if (responses[key] !== undefined || inputValue !== "") {
  //     // Set the question as answered
  //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
  
  //     // Extract correct answer and marks from JSON data
  //     const correctAnswer = currentQuestion.answer?.trim().toLowerCase();
  //     const marks = currentQuestion.marks;
  
  //     console.log("Correct Answer:", correctAnswer);
  //     console.log("User Response for options selected:", responses[key]);
  //     console.log("User Response entered value:", inputValue);
  
  //     if (marks) {
  //       const marksArray = marks.split(",");
  //       const correctMark = parseInt(marksArray[0], 10);
  //       const wrongMark = -1;
  
  //       const userResponse = responses[key] !== undefined ? responses[key].toString().trim().toLowerCase() : "";
  
  //       console.log("User Response:", userResponse);
  
  //       let isCorrectResponse = false;
  //       let newMarksAwarded = 0;
  
  //       if (["MCQ4", "MCQ5", "MSQ", "MSQN", "NATI"].includes(currentQuestion.qtype)) {
  //         isCorrectResponse = userResponse === correctAnswer || (inputValue !== "" && inputValue === correctAnswer);
  //         newMarksAwarded = isCorrectResponse ? correctMark : wrongMark;
  //       } else if (currentQuestion.qtype === "NATD") {
  //         const [lowerRange, upperRange] = correctAnswer.split("-").map(range => parseFloat(range));
  //         console.log("Lower Range:", lowerRange);
  //         console.log("Upper Range:", upperRange);
  
  //         const isWithinRange = inputValue !== "" && correctAnswer.includes("-") &&
  //           inputValue >= lowerRange && inputValue <= upperRange;
  //         console.log("Is Within Range:", isWithinRange);
  
  //         newMarksAwarded = isWithinRange ? correctMark : wrongMark;
  //       }
  
  //       console.log("New Marks Awarded:", newMarksAwarded);
  
  //       // Update total marks
  //       const newTotalMarks = totalMarks - previousMarks + newMarksAwarded;
  //       setTotalMarks(Math.max(newTotalMarks, 0));
  
  //       // Update counts based on correctness of the response
  //       if (isCorrectResponse) {
  //         if (!wasPreviouslyCorrect) {
  //           setRightAnsweredCount(prevCount => prevCount + 1);
  //           if (previousMarks < 0) setWrongAnsweredCount(prevCount => prevCount - 1);
  //         }
  //       } else {
  //         if (wasPreviouslyCorrect) {
  //           setRightAnsweredCount(prevCount => prevCount - 1);
  //         }
  //         setWrongAnsweredCount(prevCount => prevCount + 1);
  //       }
  
  //       // Update awarded marks for this question
  //       awardedMarks[key] = newMarksAwarded;
  
  //       console.log("Updated awardedMarks:", awardedMarks);
  
  //       // Log updated total marks
  //       console.log("Updated Total Marks:", newTotalMarks);
  
  //       // Check if there was an error in the calculation
  //       if (newTotalMarks !== calculateCorrectTotalMarks()) {
  //         console.error("Total marks mismatch detected! Expected:", calculateCorrectTotalMarks(), "Got:", newTotalMarks);
  //       }
  //     }
  
  //     // Update counts for answered questions
  //     if (!wasPreviouslyAnswered) {
  //       setAnsweredCount(prevAnsweredCount => prevAnsweredCount + 1);
  //       setNotAnsweredCount(prevCount => prevCount - 1);
  //     }
  
  //     // Increment the count for section-B questions answered
  //     if (currentSectionIndex === 1) {
  //       setSectionBAnsweredCount(prevCount => prevCount + 1);
  //     }
  
  //     // Move to the next question if available, else move to the next section or subject
  //     if (currentQuestionIndex < currentSection.questions.length - 1) {
  //       nextQuestion();
  //     } else {
  //       const currentSubjectIndex = JEEquestionsData.findIndex(
  //         subject => subject.SubjectName === selectedSubject
  //       );
  //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
  //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
  //         setSelectedSubject(nextSubject.SubjectName);
  //         setCurrentSectionIndex(0);
  //         setCurrentQuestionIndex(0);
  //       }
  //     }
  //   } else {
  //     // If the response is undefined, prompt the user to answer the question before proceeding
  //     alert("Please answer the current question before proceeding.");
  //   }
  // };
  
  // const calculateCorrectTotalMarks = () => {
  //   return Object.values(awardedMarks).reduce((acc, mark) => acc + mark, 0);
  // };
  
  
  // const saveAndNext = () => {
  //   // Retrieve current question details
  //   const currentSection = currentSubject.sections[currentSectionIndex];
  //   const key = getQuestionKey(
  //     selectedSubject,
  //     currentSectionIndex,
  //     currentQuestion.question_id
  //   );
  //   const inputValue = getInputValue(selectedSubject, currentSectionIndex, currentQuestion.question_id)?.trim().toLowerCase();
  
  //   // Log key and input value for debugging
  //   console.log(`Key for question ${currentQuestion.question_id}:`, key);
  //   console.log(`Retrieved input value for question ${currentQuestion.question_id}:`, inputValue);
  
  //   // Determine if the question was previously answered
  //   const wasPreviouslyAnswered = awardedMarks[key] !== undefined;
  
  //   // Check if a response exists for the current question
  //   if (responses[key] !== undefined || inputValue !== "") {
  //     // Set the question as answered
  //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
  
  //     // Move to the next question if available, else move to the next section or subject
  //     if (currentQuestionIndex < currentSection.questions.length - 1) {
  //       nextQuestion();
  //     } else {
  //       const currentSubjectIndex = JEEquestionsData.findIndex(
  //         subject => subject.SubjectName === selectedSubject
  //       );
  //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
  //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
  //         setSelectedSubject(nextSubject.SubjectName);
  //         setCurrentSectionIndex(0);
  //         setCurrentQuestionIndex(0);
  //       }
  //     }
  //   } else {
  //     // If the response is undefined, prompt the user to answer the question before proceeding
  //     alert("Please answer the current question before proceeding.");
  //   }
  // }; 

  


  //all types
  // const saveAndNext = () => {
  //   // Retrieve current question details
  //   const currentSection = currentSubject.sections[currentSectionIndex];
  //   const currentQuestion = currentSection.questions[currentQuestionIndex];
  //   const key = getQuestionKey(selectedSubject, currentSectionIndex, currentQuestion.question_id);
  
  //   // Get the user's response for the current question
  //   // const userResponse = responses[key];
  //   const userResponse = responses[key] !== undefined ? responses[key].toString().trim().toLowerCase() : "";
  //   const userInput = inputValues[key];
  
  //   // Get the correct answer and marks for the current question
  //   const correctAnswer = currentQuestion.answer;
  //   const marks = currentQuestion.marks.split(',').map(Number);
  
  //   // Check if the response matches the correct answer
  //   let isResponseCorrect = false;
  
  //   if (Array.isArray(userResponse)) {
  //     // For multiple-select questions
  //     const userResponseSet = new Set(userResponse);
  //     const correctAnswerSet = new Set(correctAnswer);
  //     isResponseCorrect = isEqualSets(userResponseSet, correctAnswerSet);
  //   } else {
  //     // For single-select questions or numeric input questions
  //     isResponseCorrect = userResponse === correctAnswer || (userInput && userInput.trim().toLowerCase() === correctAnswer);
  //   }
  
  //   // Log user response, correct answer, and comparison result
  //   console.log('User Response:', userResponse);
  //   console.log('Correct Answer:', correctAnswer);
  //   console.log('Is Response Correct:', isResponseCorrect);
  
  //   // Log user response status and assign marks accordingly
  //   if (isResponseCorrect) {
  //     console.log('User Response Status: Correct');
  //     // Remaining logic...
  //     setSectionMarks(prevSectionMarks => ({
  //       ...prevSectionMarks,
  //       [`${selectedSubject}-${currentSection.SectionName}`]: (prevSectionMarks[`${selectedSubject}-${currentSection.SectionName}`] || 0) + marks[0]
  //     }));
  //     setSubjectMarks(prevSubjectMarks => ({
  //       ...prevSubjectMarks,
  //       [selectedSubject]: (prevSubjectMarks[selectedSubject] || 0) + marks[0]
  //     }));
  //     setTotalMarks(prevTotalMarks => prevTotalMarks + marks[0]);
      
  //   } else {
  //     console.log('User Response Status: Wrong');
  //     // Remaining logic...
  //        // If the response is incorrect, subtract marks for incorrect answer
  //        setSectionMarks(prevSectionMarks => ({
  //         ...prevSectionMarks,
  //         [`${selectedSubject}-${currentSection.SectionName}`]: (prevSectionMarks[`${selectedSubject}-${currentSection.SectionName}`] || 0) - marks[1]
  //       }));
  //       setSubjectMarks(prevSubjectMarks => ({
  //         ...prevSubjectMarks,
  //         [selectedSubject]: (prevSubjectMarks[selectedSubject] || 0) - marks[1]
  //       }));
  //       setTotalMarks(prevTotalMarks => prevTotalMarks - marks[1]);
  //   }
  
  //   // Move to the next question
  //   nextQuestion();
  // };
  
  // const saveAndNext = () => {
  //   // Retrieve current question details
  //   const currentSection = currentSubject.sections[currentSectionIndex];
  //   const currentQuestion = currentSection.questions[currentQuestionIndex];
  //   const key = getQuestionKey(selectedSubject, currentSectionIndex, currentQuestion.question_id);
  
  //   // Get the user's response for the current question
  //   const userResponse = responses[key] !== undefined ? responses[key].toString().trim().toLowerCase() : "";
  //   const userInput = inputValues[key] !== undefined ? inputValues[key].toString().trim().toLowerCase() : "";
  
  //   // Get the correct answer and marks for the current question
  //   const correctAnswer = currentQuestion.answer;
  //   const marks = currentQuestion.marks.split(',').map(Number);
  
  //   // Check if the response matches the correct answer
  //   let isResponseCorrect = false;
  
  //   if (Array.isArray(userResponse)) {
  //     // For multiple-select questions
  //     const userResponseSet = new Set(userResponse);
  //     const correctAnswerSet = new Set(correctAnswer);
  //     isResponseCorrect = isEqualSets(userResponseSet, correctAnswerSet);
  //   } else {
  //     if (currentQuestion.qtype === 'NATD' || currentQuestion.qtype === 'NATI') {
  //       // Numeric Answer Type - Decimal or Integer
  //       const userResponseNum = parseFloat(userResponse);
  //       const userInputNum = parseFloat(userInput);
        
  //       if (correctAnswer.includes('-')) {
  //         // Correct answer is a range
  //         const [lowerBound, upperBound] = correctAnswer.split('-').map(Number);
  //         isResponseCorrect = (userResponseNum >= lowerBound && userResponseNum <= upperBound) ||
  //                             (userInputNum >= lowerBound && userInputNum <= upperBound);
  //       } else {
  //         // Correct answer is a single value
  //         const correctAnswerNum = parseFloat(correctAnswer);
  //         isResponseCorrect = userResponseNum === correctAnswerNum || userInputNum === correctAnswerNum;
  //       }
  //     } else {
  //       // For single-select questions or other types
  //       isResponseCorrect = userResponse === correctAnswer || userInput === correctAnswer;
  //     }
  //   }
  
  //   // Log user response, correct answer, and comparison result
  //   console.log('User Response:', userResponse);
  //   console.log('User userInput Response:', userInput);
  //   console.log('Correct Answer:', correctAnswer);
  //   console.log('Is Response Correct:', isResponseCorrect);
  
  //   // Log user response status and assign marks accordingly
  //   if (isResponseCorrect) {
  //     console.log('User Response Status: Correct');
  //     setSectionMarks(prevSectionMarks => ({
  //       ...prevSectionMarks,
  //       [`${selectedSubject}-${currentSection.SectionName}`]: (prevSectionMarks[`${selectedSubject}-${currentSection.SectionName}`] || 0) + marks[0]
  //     }));
  //     setSubjectMarks(prevSubjectMarks => ({
  //       ...prevSubjectMarks,
  //       [selectedSubject]: (prevSubjectMarks[selectedSubject] || 0) + marks[0]
  //     }));
  //     setTotalMarks(prevTotalMarks => prevTotalMarks + marks[0]);
  //   } else {
  //     console.log('User Response Status: Wrong');
  //     setSectionMarks(prevSectionMarks => ({
  //       ...prevSectionMarks,
  //       [`${selectedSubject}-${currentSection.SectionName}`]: (prevSectionMarks[`${selectedSubject}-${currentSection.SectionName}`] || 0) - marks[1]
  //     }));
  //     setSubjectMarks(prevSubjectMarks => ({
  //       ...prevSubjectMarks,
  //       [selectedSubject]: (prevSubjectMarks[selectedSubject] || 0) - marks[1]
  //     }));
  //     setTotalMarks(prevTotalMarks => prevTotalMarks - marks[1]);
  //   }
  
  //   // Move to the next question
  //   nextQuestion();
  // };
  
  const saveAndNext = () => {
    // Retrieve current question details
    const currentSection = currentSubject.sections[currentSectionIndex];
    const currentQuestion = currentSection.questions[currentQuestionIndex];
    const key = getQuestionKey(selectedSubject, currentSectionIndex, currentQuestion.question_id);
  
    // Get the user's response for the current question
    const userResponse = responses[key] !== undefined ? responses[key].toString().trim().toLowerCase() : "";
    const userInput = inputValues[key] !== undefined ? inputValues[key].toString().trim().toLowerCase() : "";
  
    // Get the correct answer and marks for the current question
    const correctAnswer = currentQuestion.answer;
    const marks = currentQuestion.marks.split(',').map(Number);
  
    // Check if the response matches the correct answer
    let isResponseCorrect = false;
  
    if (Array.isArray(userResponse)) {
      // For multiple-select questions
      const userResponseSet = new Set(userResponse);
      const correctAnswerSet = new Set(correctAnswer);
      isResponseCorrect = isEqualSets(userResponseSet, correctAnswerSet);
    } else {
      if (currentQuestion.qtype === 'NATD' || currentQuestion.qtype === 'NATI') {
        // Numeric Answer Type - Decimal or Integer
        const userResponseNum = parseFloat(userResponse);
        const userInputNum = parseFloat(userInput);
        
        if (correctAnswer.includes('-')) {
          // Correct answer is a range
          const [lowerBound, upperBound] = correctAnswer.split('-').map(Number);
          isResponseCorrect = (userResponseNum >= lowerBound && userResponseNum <= upperBound) ||
                              (userInputNum >= lowerBound && userInputNum <= upperBound);
        } else {
          // Correct answer is a single value
          const correctAnswerNum = parseFloat(correctAnswer);
          isResponseCorrect = userResponseNum === correctAnswerNum || userInputNum === correctAnswerNum;
        }
      } else {
        // For single-select questions or other types
        isResponseCorrect = userResponse === correctAnswer || userInput === correctAnswer;
      }
    }
  
    // Log user response, correct answer, and comparison result
    console.log('User Response:', userResponse);
    console.log('User userInput Response:', userInput);
    console.log('Correct Answer:', correctAnswer);
    console.log('Is Response Correct:', isResponseCorrect);
  
    // Update answered questions state
    setAnsweredQuestions(prevAnsweredQuestions => ({
      ...prevAnsweredQuestions,
      [key]: true,
    }));
  
    // Update responses state
    setResponses(prevResponses => ({
      ...prevResponses,
      [key]: userResponse !== "" ? userResponse : userInput,
    }));
  
    // Log user response status and assign marks accordingly
    if (isResponseCorrect) {
      console.log('User Response Status: Correct');
      setSectionMarks(prevSectionMarks => ({
        ...prevSectionMarks,
        [`${selectedSubject}-${currentSection.SectionName}`]: (prevSectionMarks[`${selectedSubject}-${currentSection.SectionName}`] || 0) + marks[0]
      }));
      setSubjectMarks(prevSubjectMarks => ({
        ...prevSubjectMarks,
        [selectedSubject]: (prevSubjectMarks[selectedSubject] || 0) + marks[0]
      }));
      setTotalMarks(prevTotalMarks => prevTotalMarks + marks[0]);
    } else {
      console.log('User Response Status: Wrong');
      setSectionMarks(prevSectionMarks => ({
        ...prevSectionMarks,
        [`${selectedSubject}-${currentSection.SectionName}`]: (prevSectionMarks[`${selectedSubject}-${currentSection.SectionName}`] || 0) - marks[1]
      }));
      setSubjectMarks(prevSubjectMarks => ({
        ...prevSubjectMarks,
        [selectedSubject]: (prevSubjectMarks[selectedSubject] || 0) - marks[1]
      }));
      setTotalMarks(prevTotalMarks => prevTotalMarks - marks[1]);
    }
  
    // Move to the next question
    nextQuestion();
  };
  

  
  // Function to compare two sets for equality
  const isEqualSets = (set1, set2) => {
    if (set1.size !== set2.size) return false;
    for (const item of set1) {
      if (!set2.has(item)) return false;
    }
    return true;
  };
  
  
  
  
  
  
  
  
  
  
  //with out natd range
  // const saveAndNext = () => {
  //   // Retrieve current question details
  //   const currentSection = currentSubject.sections[currentSectionIndex];
  //   const key = getQuestionKey(
  //     selectedSubject,
  //     currentSectionIndex,
  //     currentQuestion.question_id
  //   );
  //   const inputValue = getInputValue(selectedSubject, currentSectionIndex, currentQuestion.question_id)?.trim().toLowerCase();
  
  //   // Log key and input value for debugging
  //   console.log(`Key for question ${currentQuestion.question_id}:`, key);
  //   console.log(`Retrieved input value for question ${currentQuestion.question_id}:`, inputValue);
  
  //   // Check if a response exists for the current question
  //   if (responses[key] !== undefined || inputValue !== "") {
  //     // Set the question as answered
  //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
  
  //     // Extract correct answer and marks from JSON data
  //     const correctAnswer = currentQuestion.answer?.trim().toLowerCase(); // Assuming answer is in lowercase
  //     const marks = currentQuestion.marks;
  
  //     console.log("Correct Answer:", correctAnswer);
  //     console.log("User Response for options selected:", responses[key]);
  //     console.log("User Response entered value:", inputValue);
  
  //     // Check if the marks are defined
  //     if (marks) {
  //       const marksArray = marks.split(",");
  //       const correctMark = parseInt(marksArray[0], 10);
  //       const wrongMark = parseInt(marksArray[1], 10);
  
  //       // Ensure responses[key] is a string before using trim and toLowerCase
  //       const userResponse = responses[key] !== undefined ? responses[key].toString().trim().toLowerCase() : "";
  
  //       // Compare user response with correct answer
  //       const isCorrectResponse =
  //         userResponse === correctAnswer || (inputValue !== "" && inputValue === correctAnswer);
  
  //       if (isCorrectResponse) {
  //         // Increment correct answers count and update total marks
  //         console.log("Response is correct.");
  //         setrightAnsweredCount((prevCount) => prevCount + 1);
  //         setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
  //         console.log("Marks Added:", correctMark);
  //       } else {
  //         // Increment wrong answers count and subtract marks for incorrect answer
  //         console.log("Response is incorrect.");
  //         setwrongAnsweredCount((prevCount) => prevCount + 1);
  //         setTotalMarks((prevTotalMarks) => Math.max(0, prevTotalMarks - wrongMark)); // Subtract marks for incorrect answer, ensuring total marks don't go below 0
  //         console.log("Marks Deducted:", wrongMark);
  //       }
  //     }
  
  //     // Update counts for answered and not answered questions
  //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
  //     if (!answeredQuestions[key]) {
  //       setNotAnsweredCount((prevCount) => prevCount - 1);
  //     }
  
  //     // Move to the next question if available, else move to the next section or subject
  //     if (currentQuestionIndex < currentSection.questions.length - 1) {
  //       nextQuestion();
  //     } else {
  //       const currentSubjectIndex = JEEquestionsData.findIndex(
  //         (subject) => subject.SubjectName === selectedSubject
  //       );
  //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
  //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
  //         setSelectedSubject(nextSubject.SubjectName);
  //         setCurrentSectionIndex(0);
  //         setCurrentQuestionIndex(0);
  //       }
  //     }
  //   } else {
  //     // If the response is undefined, prompt the user to answer the question before proceeding
  //     alert("Please answer the current question before proceeding.");
  //   }
  // };
//   const saveAndNext = () => {
//     // Retrieve current question details
//     const currentSection = currentSubject.sections[currentSectionIndex];
//     const key = getQuestionKey(
//         selectedSubject,
//         currentSectionIndex,
//         currentQuestion.question_id
//     );
//     const inputValue = getInputValue(
//         selectedSubject,
//         currentSectionIndex,
//         currentQuestion.question_id
//     )?.trim().toLowerCase();

//     // Log key and input value for debugging
//     console.log(`Key for question ${currentQuestion.question_id}:`, key);
//     console.log(
//         `Retrieved input value for question ${currentQuestion.question_id}:`,
//         inputValue
//     );

//     // Check if a response exists for the current question
//     if (responses[key] !== undefined || inputValue !== "") {
//         // Set the question as answered
//         setAnsweredQuestions({ ...answeredQuestions, [key]: true });

//         // Extract correct answer and marks from JSON data
//         const correctAnswer = currentQuestion.answer?.trim().toLowerCase(); // Assuming answer is in lowercase
//         const marks = currentQuestion.marks;

//         console.log("Correct Answer:", correctAnswer);
//         console.log("User Response for options selected:", responses[key]);
//         console.log("User Response entered value:", inputValue);

//         // Check if the marks are defined
//         if (marks) {
//             const marksArray = marks.split(",");
//             const correctMark = parseInt(marksArray[0], 10);
//             const wrongMark = parseInt(marksArray[1], 10);

//             // Ensure responses[key] is a string before using trim and toLowerCase
//             const userResponse =
//                 responses[key] !== undefined
//                     ? responses[key].toString().trim().toLowerCase()
//                     : "";

//             // Compare user response with correct answer
//             const isCorrectResponse =
//                 userResponse === correctAnswer ||
//                 (inputValue !== "" && inputValue === correctAnswer);

//             if (isCorrectResponse) {
//                 // Increment correct answers count and update total marks
//                 console.log("Response is correct.");
//                 setrightAnsweredCount((prevCount) => prevCount + 1);
//                 setTotalMarks((prevTotalMarks) => {
//                     // Subtract previous marks for this question
//                     prevTotalMarks -= responses[key] === correctAnswer ? correctMark : wrongMark;
//                     // Add marks for correct answer
//                     return prevTotalMarks + correctMark;
//                 });
//                 console.log("Marks Added:", correctMark);
//             } else {
//                 // Increment wrong answers count and subtract marks for incorrect answer
//                 console.log("Response is incorrect.");
//                 setwrongAnsweredCount((prevCount) => prevCount + 1);
//                 setTotalMarks((prevTotalMarks) => {
//                     // Subtract previous marks for this question
//                     prevTotalMarks -= responses[key] === correctAnswer ? correctMark : wrongMark;
//                     // Subtract marks for incorrect answer, ensuring total marks don't go below 0
//                     return Math.max(0, prevTotalMarks - wrongMark);
//                 });
//                 console.log("Marks Deducted:", wrongMark);
//             }
//         }

//         // Update counts for answered and not answered questions
//         setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
//         if (!answeredQuestions[key]) {
//             setNotAnsweredCount((prevCount) => prevCount - 1);
//         }

//         // Move to the next question if available, else move to the next section or subject
//         if (currentQuestionIndex < currentSection.questions.length - 1) {
//             nextQuestion();
//         } else {
//             const currentSubjectIndex = JEEquestionsData.findIndex(
//                 (subject) => subject.SubjectName === selectedSubject
//             );
//             if (currentSubjectIndex < JEEquestionsData.length - 1) {
//                 const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
//                 setSelectedSubject(nextSubject.SubjectName);
//                 setCurrentSectionIndex(0);
//                 setCurrentQuestionIndex(0);
//             }
//         }

//         // Display the total score
//         console.log("Total Score:", totalMarks); // Assuming you want to log the total score
//         // You can also update any UI element displaying the total score
//     } else {
//         // If the response is undefined, prompt the user to answer the question before proceeding
//         alert("Please answer the current question before proceeding.");
//     }
// };



  

  // const saveAndNext = () => {
  //   // Retrieve current question details
  //   const currentSection = currentSubject.sections[currentSectionIndex];
  //   const key = getQuestionKey(
  //     selectedSubject,
  //     currentSectionIndex,
  //     currentQuestion.question_id
  //   );
  //   const inputValue = getInputValue(selectedSubject, currentSectionIndex, currentQuestion.question_id)?.trim().toLowerCase();
  
  //   // Log key and input value for debugging
  //   console.log(`Key for question ${currentQuestion.question_id}:`, key);
  //   console.log(`Retrieved input value for question ${currentQuestion.question_id}:`, inputValue);
  
  //   // Check if a response exists for the current question
  //   if (responses[key] !== undefined || inputValue !== "") {
  //     // Set the question as answered
  //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
  
  //     // Extract correct answer and marks from JSON data
  //     const correctAnswer = currentQuestion.answer?.trim().toLowerCase(); // Assuming answer is in lowercase
  //     const marks = currentQuestion.marks;
  
  //     console.log("Correct Answer:", correctAnswer);
  //     console.log("User Response for options selected:", responses[key]);
  //     console.log("User Response entered value:", inputValue);
  
  //     // Check if the marks are defined
  //     if (marks) {
  //       const marksArray = marks.split(",");
  //       const correctMark = parseInt(marksArray[0], 10);
  //       const wrongMark = parseInt(marksArray[1], 10);
  
  //       // Ensure responses[key] is a string before using trim and toLowerCase
  //       const userResponse = responses[key] !== undefined ? responses[key].toString().trim().toLowerCase() : "";
  
  //       // Compare user response with correct answer
  //       const isCorrectResponse =
  //         userResponse === correctAnswer || (inputValue !== "" && inputValue === correctAnswer);
  
  //       if (isCorrectResponse) {
  //         // Increment correct answers count and update total marks
  //         console.log("Response is correct.");
  //         setrightAnsweredCount((prevCount) => prevCount + 1);
  //         setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
  //         console.log("Marks Added:", correctMark);
  //       } else {
  //         // Increment wrong answers count and subtract marks for incorrect answer
  //         console.log("Response is incorrect.");
  //         setwrongAnsweredCount((prevCount) => prevCount + 1);
  //         setTotalMarks((prevTotalMarks) => prevTotalMarks - wrongMark); // Subtract marks for incorrect answer
  //         console.log("Marks Deducted:", wrongMark);
  //       }
  //     }
  
  //     // Update counts for answered and not answered questions
  //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
  //     if (!answeredQuestions[key]) {
  //       setNotAnsweredCount((prevCount) => prevCount - 1);
  //     }
  
  //     // Move to the next question if available, else move to the next section or subject
  //     if (currentQuestionIndex < currentSection.questions.length - 1) {
  //       nextQuestion();
  //     } else {
  //       const currentSubjectIndex = JEEquestionsData.findIndex(
  //         (subject) => subject.SubjectName === selectedSubject
  //       );
  //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
  //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
  //         setSelectedSubject(nextSubject.SubjectName);
  //         setCurrentSectionIndex(0);
  //         setCurrentQuestionIndex(0);
  //       }
  //     }
  //   } else {
  //     // If the response is undefined, prompt the user to answer the question before proceeding
  //     alert("Please answer the current question before proceeding.");
  //   }
  // };
  

  

  // const saveAndNext = () => {
  //   // Retrieve current question details
  //   const currentSection = currentSubject.sections[currentSectionIndex];
  //   const key = getQuestionKey(
  //     selectedSubject,
  //     currentSectionIndex,
  //     currentQuestion.question_id
  //   );
  //   const inputValue = getInputValue(selectedSubject, currentSectionIndex, currentQuestion.question_id)?.trim().toLowerCase();
  
  //   // Log key and input value for debugging
  //   console.log(`Key for question ${currentQuestion.question_id}:`, key);
  //   console.log(`Retrieved input value for question ${currentQuestion.question_id}:`, inputValue);
  
  //   // Check if a response exists for the current question
  //   if (responses[key] !== undefined || inputValue !== "") {
  //     // Set the question as answered
  //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
  
  //     // Extract correct answer and marks from JSON data
  //     const correctAnswer = currentQuestion.answer?.trim().toLowerCase(); // Assuming answer is in lowercase
  //     const marks = currentQuestion.marks;
  
  //     console.log("Correct Answer:", correctAnswer);
  //     console.log("User Response for options selected:", responses[key]);
  //     console.log("User Response entered value:", inputValue);
  
  //     // Check if the marks are defined
  //     if (marks) {
  //       const marksArray = marks.split(",");
  //       const correctMark = parseInt(marksArray[0], 10);
  //       const wrongMark = parseInt(marksArray[1], 10);
  
  //       // Compare user response with correct answer
  //       const isCorrectResponse =
  //         (responses[key] !== undefined && responses[key].trim().toLowerCase() === correctAnswer) ||
  //         (inputValue !== "" && inputValue === correctAnswer);
  
  //       if (isCorrectResponse) {
  //         // Increment correct answers count and update total marks
  //         console.log("Response is correct.");
  //         setrightAnsweredCount((prevCount) => prevCount + 1);
  //         setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
  //         console.log("Marks Added:", correctMark);
  //       } else {
  //         // Increment wrong answers count and subtract marks for incorrect answer
  //         console.log("Response is incorrect.");
  //         setwrongAnsweredCount((prevCount) => prevCount + 1);
  //         setTotalMarks((prevTotalMarks) => prevTotalMarks - wrongMark); // Subtract marks for incorrect answer
  //         console.log("Marks Deducted:", wrongMark);
  //       }
  //     }
  
  //     // Update counts for answered and not answered questions
  //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
  //     if (!answeredQuestions[key]) {
  //       setNotAnsweredCount((prevCount) => prevCount - 1);
  //     }
  
  //     // Move to the next question if available, else move to the next section or subject
  //     if (currentQuestionIndex < currentSection.questions.length - 1) {
  //       nextQuestion();
  //     } else {
  //       const currentSubjectIndex = JEEquestionsData.findIndex(
  //         (subject) => subject.SubjectName === selectedSubject
  //       );
  //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
  //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
  //         setSelectedSubject(nextSubject.SubjectName);
  //         setCurrentSectionIndex(0);
  //         setCurrentQuestionIndex(0);
  //       }
  //     }
  //   } else {
  //     // If the response is undefined, prompt the user to answer the question before proceeding
  //     alert("Please answer the current question before proceeding.");
  //   }
  // };
  // const saveAndNext = () => {
  //   // Retrieve current question details
  //   const currentSection = currentSubject.sections[currentSectionIndex];
  //   const key = getQuestionKey(
  //     selectedSubject,
  //     currentSectionIndex,
  //     currentQuestion.question_id
  //   );
  //   const inputValue = getInputValue(currentQuestion.question_id)?.trim().toLowerCase();
  
  //   // Log input value for debugging
  //   console.log(`Retrieved input value for question ${currentQuestion.question_id}:`, inputValue);
  
  //   // Check if a response exists for the current question
  //   if (responses[key] !== undefined || inputValue !== undefined) {
  //     // Set the question as answered
  //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
  
  //     // Extract correct answer and marks from JSON data
  //     const correctAnswer = currentQuestion.answer?.trim().toLowerCase(); // Assuming answer is in lowercase
  //     const marks = currentQuestion.marks;
  
  //     console.log("Correct Answer:", correctAnswer);
  //     console.log("User Response for options selected:", responses[key]);
  //     console.log("User Response entered value:", inputValue);
  
  //     // Check if the marks are defined
  //     if (marks) {
  //       const marksArray = marks.split(",");
  //       const correctMark = parseInt(marksArray[0], 10);
  //       const wrongMark = parseInt(marksArray[1], 10);
  
  //       // Compare user response with correct answer
  //       const isCorrectResponse =
  //         (responses[key] !== undefined && responses[key].trim().toLowerCase() === correctAnswer) ||
  //         (inputValue !== undefined && inputValue === correctAnswer);
  
  //       if (isCorrectResponse) {
  //         // Increment correct answers count and update total marks
  //         console.log("Response is correct.");
  //         setrightAnsweredCount((prevCount) => prevCount + 1);
  //         setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
  //         console.log("Marks Added:", correctMark);
  //       } else {
  //         // Increment wrong answers count and subtract marks for incorrect answer
  //         console.log("Response is incorrect.");
  //         setwrongAnsweredCount((prevCount) => prevCount + 1);
  //         setTotalMarks((prevTotalMarks) => prevTotalMarks - wrongMark); // Subtract marks for incorrect answer
  //         console.log("Marks Deducted:", wrongMark);
  //       }
  //     }
  
  //     // Update counts for answered and not answered questions
  //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
  //     if (!answeredQuestions[key]) {
  //       setNotAnsweredCount((prevCount) => prevCount - 1);
  //     }
  
  //     // Move to the next question if available, else move to the next section or subject
  //     if (currentQuestionIndex < currentSection.questions.length - 1) {
  //       nextQuestion();
  //     } else {
  //       const currentSubjectIndex = JEEquestionsData.findIndex(
  //         (subject) => subject.SubjectName === selectedSubject
  //       );
  //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
  //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
  //         setSelectedSubject(nextSubject.SubjectName);
  //         setCurrentSectionIndex(0);
  //         setCurrentQuestionIndex(0);
  //       }
  //     }
  //   } else if (inputValue !== undefined) {
  //     // Retrieve input value for the current question
  //     console.log(`Question: ${currentQuestion.question_id}`);
  //     console.log(`Input Value: ${inputValue}`);
  //   } else {
  //     // If the response is undefined, prompt the user to answer the question before proceeding
  //     alert("Please answer the current question before proceeding.");
  //   }
  // };
  
  
  // const saveAndNext = () => {
  //   // Retrieve current question details
  //   const currentSection = currentSubject.sections[currentSectionIndex];
  //   const key = getQuestionKey(
  //     selectedSubject,
  //     currentSectionIndex,
  //     currentQuestion.question_id
  //   );
  //   const inputValue = getInputValue(currentQuestion.question_id);
  
  //   // Check if a response exists for the current question
  //   if (responses[key] !== undefined || inputValue !== undefined) {
  //     // Set the question as answered
  //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
  
  //     // Extract correct answer and marks from JSON data
  //     const correctAnswer = currentQuestion.answer?.toLowerCase(); // Assuming answer is in lowercase
  //     const marks = currentQuestion.marks;
  
  //     console.log("Correct Answer:", correctAnswer);
  //     console.log("User Response for options selected:", responses[key]);
  //     console.log("User Response entered value:", inputValue);
  
  //     // Check if the marks are defined
  //     if (marks) {
  //       const marksArray = marks.split(",");
  //       const correctMark = parseInt(marksArray[0], 10);
  //       const wrongMark = parseInt(marksArray[1], 10);
  
  //       // Compare user response with correct answer
  //       if (
  //         (responses[key] !== undefined &&
  //           responses[key] === correctAnswer) ||
  //         (inputValue !== undefined && inputValue.toLowerCase() === correctAnswer)
  //       ) {
  //         // Increment correct answers count and update total marks
  //         console.log("Response is correct.");
  //         setrightAnsweredCount((prevCount) => prevCount + 1);
  //         setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
  //         console.log("Marks Added:", correctMark);
  //       } else {
  //         // Increment wrong answers count and subtract marks for incorrect answer
  //         console.log("Response is incorrect.");
  //         setwrongAnsweredCount((prevCount) => prevCount + 1);
  //         setTotalMarks((prevTotalMarks) => prevTotalMarks - wrongMark); // Subtract marks for incorrect answer
  //         console.log("Marks Deducted:", wrongMark);
  //       }
  //     }
  
  //     // Update counts for answered and not answered questions
  //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
  //     if (!answeredQuestions[key]) {
  //       setNotAnsweredCount((prevCount) => prevCount - 1);
  //     }
  
  //     // Move to the next question if available, else move to the next section or subject
  //     if (currentQuestionIndex < currentSection.questions.length - 1) {
  //       nextQuestion();
  //     } else {
  //       const currentSubjectIndex = JEEquestionsData.findIndex(
  //         (subject) => subject.SubjectName === selectedSubject
  //       );
  //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
  //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
  //         setSelectedSubject(nextSubject.SubjectName);
  //         setCurrentSectionIndex(0);
  //         setCurrentQuestionIndex(0);
  //       }
  //     }
  //   } else if (inputValue) {
  //     // Retrieve input value for the current question
  //     console.log(`Question: ${currentQuestion.question_id}`);
  //     console.log(`Input Value: ${inputValue}`);
  //   } else {
  //     // If the response is undefined, prompt the user to answer the question before proceeding
  //     alert("Please answer the current question before proceeding.");
  //   }
  // };
  
  // const saveAndNext = () => {
  //   // Retrieve current question details
  //   const currentSection = currentSubject.sections[currentSectionIndex];
  //   const key = getQuestionKey(
  //     selectedSubject,
  //     currentSectionIndex,
  //     currentQuestion.question_id
  //   );
  //   const inputValue = getInputValue(currentQuestion.question_id);
 
  //   // Check if a response exists for the current question
  //   if (responses[key] || inputValue !== undefined) {
  //     // Set the question as answered
  //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
 
  //     // Extract correct answer and marks from JSON data
  //     const correctAnswer = currentQuestion.answer?.toLowerCase(); // Assuming answer is in lowercase
  //     const marks = currentQuestion.marks;
 
  //     console.log("Correct Answer:", correctAnswer);
  //     console.log("User Response:", responses[key]);
  //     console.log("User Response:", inputValues);
 
  //     // Check if the marks are defined
  //     if (marks) {
  //       const marksArray = marks.split(",");
  //       const correctMark = parseInt(marksArray[0], 10);
  //       const wrongMark = parseInt(marksArray[1], 10);
 
  //       // Compare user response with correct answer
  //       if (responses[key] === correctAnswer || inputValue === correctAnswer) {
  //         // Increment correct answers count and update total marks
  //         console.log("Response is correct.");
  //         setrightAnsweredCount((prevCount) => prevCount + 1);
  //         setTotalMarks((prevTotalMarks) => prevTotalMarks + correctMark); // Add marks for correct answer
  //         console.log("Marks Added:", correctMark);
  //       } else {
  //         // Increment wrong answers count and subtract marks for incorrect answer
  //         console.log("Response is incorrect.");
  //         setwrongAnsweredCount((prevCount) => prevCount + 1);
  //         setTotalMarks((prevTotalMarks) => prevTotalMarks - wrongMark); // Subtract marks for incorrect answer
  //         console.log("Marks Deducted:", wrongMark);
  //       }
  //     }
 
  //     // Update counts for answered and not answered questions
  //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
  //     if (!answeredQuestions[key]) {
  //       setNotAnsweredCount((prevCount) => prevCount - 1);
  //     }
 
  //     // Move to the next question if available, else move to the next section or subject
  //     if (currentQuestionIndex < currentSection.questions.length - 1) {
  //       nextQuestion();
  //     } else {
  //       const currentSubjectIndex = JEEquestionsData.findIndex(
  //         (subject) => subject.SubjectName === selectedSubject
  //       );
  //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
  //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
  //         setSelectedSubject(nextSubject.SubjectName);
  //         setCurrentSectionIndex(0);
  //         setCurrentQuestionIndex(0);
  //       }
  //     }
  //   } else if (inputValue) {
  //     // Retrieve input value for the current question
  //     console.log(`Question: ${currentQuestion.question_id}`);
  //     console.log(`Input Value: ${inputValue}`);
  //   } else {
  //     // If the response is undefined, prompt the user to answer the question before proceeding
  //     alert("Please answer the current question before proceeding.");
  //   }
  // };
  // const saveAndNext = () => {
  //   const key = getQuestionKey(
  //     selectedSubject,
  //     currentSectionIndex,
  //     currentQuestion.question_id
  //   );
  //   const inputValue = getInputValue(currentQuestion.question_id);

  //   if (inputValue || responses[key] || inputValues[key] !== undefined) {
  //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });
  //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
  //     if (!answeredQuestions[key]) {
  //       setNotAnsweredCount((prevCount) => prevCount - 1);
  //     }
  //     if (currentQuestionIndex < currentSection.questions.length - 1) {
  //       nextQuestion();
  //     } else {
  //       const currentSubjectIndex = JEEquestionsData.findIndex(
  //         (subject) => subject.SubjectName === selectedSubject
  //       );
  //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
  //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
  //         setSelectedSubject(nextSubject.SubjectName);
  //         setCurrentSectionIndex(0);
  //         setCurrentQuestionIndex(0);
  //       }
  //     }
  //   } else if (!inputValue) {
  //     console.log(`Question: ${currentQuestion.question_id}`);
  //     console.log(`Input Value: ${inputValue}`);
  //   } else {
  //     alert("Please answer the current question before proceeding.");
  //   }
  // };

  // const saveAndNext = () => {
  //   // Retrieve current question details
  //   const currentSection = currentSubject.sections[currentSectionIndex];
  //   const key = getQuestionKey(
  //     selectedSubject,
  //     currentSectionIndex,
  //     currentQuestion.question_id
  //   );
  //   const inputValue = getInputValue(currentQuestion.question_id);
  //   // console.log(inputValues[key])
  //   // Check if a response exists for the current question
  //   if (responses[key] || inputValues[key] !== undefined) {
  //     // Set the question as answered
  //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });

  //     // Check if the response is not null
  //     if (responses[key] || inputValues[key] !== null) {
  //       // Check if the response matches the correct answer
  //       const correctAnswer = currentQuestion.answer;
  //       if (
  //         (correctAnswer && correctAnswer === responses[key]) ||
  //         inputValues[key]
  //       ) {
  //         // Increment correct answers count and update total marks
  //         setrightAnsweredCount((prevCount) => prevCount + 1);
  //         const marks = currentQuestion.marks.split(",")[0];
  //         setTotalMarks(
  //           (prevTotalMarks) => prevTotalMarks + parseInt(marks, 10)
  //         );
  //         console.log(correctAnswer === inputValues[key] ? true : false);
  //       } else {
  //         // Increment wrong answers count
  //         setwrongAnsweredCount((prevCount) => prevCount + 1);
  //       }
  //     }
  //     // Update counts for answered and not answered questions
  //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
  //     if (!answeredQuestions[key]) {
  //       setNotAnsweredCount((prevCount) => prevCount - 1);
  //     }

  //     // Move to the next question if available, else move to the next section or subject
  //     if (currentQuestionIndex < currentSection.questions.length - 1) {
  //       nextQuestion();
  //     } else {
  //       const currentSubjectIndex = JEEquestionsData.findIndex(
  //         (subject) => subject.SubjectName === selectedSubject
  //       );
  //       if (currentSubjectIndex < JEEquestionsData.length - 1) {
  //         const nextSubject = JEEquestionsData[currentSubjectIndex + 1];
  //         setSelectedSubject(nextSubject.SubjectName);
  //         setCurrentSectionIndex(0);
  //         setCurrentQuestionIndex(0);
  //       }
  //     }
  //   } else if (inputValue) {
  //     // Retrieve input value for the current question

  //     // Log current question and input value
  //     console.log(`Question: ${currentQuestion.question_id}`);
  //     console.log(`Input Value: ${inputValue}`);
  //   } else {
  //     // If the response is undefined, prompt the user to answer the question before proceeding
  //     alert("Please answer the current question before proceeding.");
  //   }
  // };

  const goToQuestion = (index) => {
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentSection.questions[index].question_id
    );
    setViewedQuestions({ ...viewedQuestions, [key]: true });
    setCurrentQuestionIndex(index);
  };

  const handleSubjectChange = (subjectName) => {
    setSelectedSubject(subjectName);
    setCurrentSectionIndex(0);
    setCurrentQuestionIndex(0);
  };

  const handleSectionChange = (index) => {
    setCurrentSectionIndex(index);
    setCurrentQuestionIndex(0);
  };

  const calculateCounts = () => {
    let answeredCount = 0;
    let markedForReviewCount = 0;
    let notVisitedCount = 0;

    JEEquestionsData.forEach((subject) => {
      subject.sections.forEach((section, sectionIndex) => {
        section.questions.forEach((question) => {
          const key = getQuestionKey(
            subject.SubjectName,
            sectionIndex,
            question.question_id
          );
          if (responses[key] !== undefined) {
            if (responses[key] !== null) {
              answeredCount++;
            } else {
              if (!viewedQuestions[key] && !markedForReview[key]) {
                notVisitedCount++; // If not answered, not marked for review, and not visited yet
              }
            }
          } else {
            if (!viewedQuestions[key] && !markedForReview[key]) {
              notVisitedCount++; // If not answered, not marked for review, and not visited yet
            }
          }
          if (markedForReview[key]) {
            markedForReviewCount++;
          }
        });
      });
    });

    const totalQuestions = JEEquestionsData.reduce(
      (acc, subject) =>
        acc +
        subject.sections.reduce((acc, sec) => acc + sec.questions.length, 0),
      0
    );

    const notAnsweredCount =
      totalQuestions - answeredCount - markedForReviewCount - notVisitedCount;

    return {
      answeredCount,
      markedForReviewCount,
      notVisitedCount,
      notAnsweredCount,
    };
  };

  const handleSubmit = () => {
    setIsAutoSubmit(false);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    window.location.href = "/bitsatots";
    window.close();
  };

  const currentSubject = JEEquestionsData.find(
    (subject) => subject.SubjectName === selectedSubject
  );
  const currentSection = currentSubject?.sections[currentSectionIndex];
  const currentQuestion = currentSection?.questions[currentQuestionIndex];

  const counts = calculateCounts();

  useEffect(() => {
    // Set the viewed status of the first question of the selected subject to true
    const firstQuestionKey = getQuestionKey(
      selectedSubject,
      0,
      currentSubject.sections[0].questions[0].question_id
    );
    setViewedQuestions({ ...viewedQuestions, [firstQuestionKey]: true });
  }, [selectedSubject]);
  const handleCheckboxChange = (optionIndex) => {
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    const selectedOptions = responses[key] || [];
    const selectedIndex = selectedOptions.indexOf(optionIndex);

    if (selectedIndex === -1) {
      // If option is not selected, add it to the array
      setResponses({
        ...responses,
        [key]: [...selectedOptions, optionIndex],
      });
    } else {
      // If option is already selected, remove it from the array
      selectedOptions.splice(selectedIndex, 1);
      setResponses({
        ...responses,
        [key]: selectedOptions,
      });
    }

    // Log the selected index
    // console.log("Selected Index:", optionIndex);
  };

  // const [selectedSubject, setSelectedSubject] = useState(null);
  // const [responses, setResponses] = useState({});
  const [sectionMarks, setSectionMarks] = useState({});
  const [subjectMarks, setSubjectMarks] = useState({});
  // const [totalMarks, setTotalMarks] = useState(0);

  // Function to calculate marks
  // const calculateMarks = () => {
  //   let totalMarks = 0;

  //   // Iterate over subjects
  //   JEEquestionsData.forEach((subject) => {
  //     let subjectMarks = 0;

  //     // Iterate over sections
  //     subject.sections.forEach((section) => {
  //       let sectionMarks = 0;

  //       // Iterate over questions in the section
  //       section.questions.forEach((question) => {
  //         const key = getQuestionKey(
  //           subject.SubjectName,
  //           section.SectionName,
  //           question.question_id
  //         );

  //         // Check if the question is answered
  //         if (responses[key] !== undefined) {
  //           // Compare the response with the correct answer
  //           const correctAnswer = question.answer; // Assuming each question has an 'answer' property
  //           const response = responses[key];
  //           if (response === correctAnswer) {
  //             const marks = question.marks.split(',').map(Number); // Convert marks string to array
  //             sectionMarks += marks[0]; // Add marks for correct answer
  //           } else {
  //             const marks = question.marks.split(',').map(Number); // Convert marks string to array
  //             sectionMarks -= marks[1]; // Subtract marks for incorrect answer
  //           }
  //         }
  //       });

  //       // Store section-wise marks
  //       setSectionMarks((prevSectionMarks) => ({
  //         ...prevSectionMarks,
  //         [`${subject.SubjectName}-${section.SectionName}`]: sectionMarks,
  //       }));

  //       // Add section marks to subject marks
  //       subjectMarks += sectionMarks;
  //     });

  //     // Store subject-wise marks
  //     setSubjectMarks((prevSubjectMarks) => ({
  //       ...prevSubjectMarks,
  //       [subject.SubjectName]: subjectMarks,
  //     }));

  //     // Increment total marks
  //     totalMarks += subjectMarks;
  //   });

  //   // Store total marks
  //   setTotalMarks(totalMarks);
  // };
  // const calculateMarks = () => {
  //   let totalMarks = 0;
  
  //   // Iterate over subjects
  //   JEEquestionsData.forEach((subject) => {
  //     let subjectMarks = 0;
  
  //     // Iterate over sections
  //     subject.sections.forEach((section) => {
  //       let sectionMarks = 0;
  
  //       // Iterate over questions in the section
  //       section.questions.forEach((question) => {
  //         const key = getQuestionKey(
  //           subject.SubjectName,
  //           section.SectionName,
  //           question.question_id
  //         );
  
  //         // Check if the question is answered
  //         if (responses[key] !== undefined || inputValues[key] !== undefined) {
  //           // Get the correct answer
  //           const correctAnswer = question.answer;
  
  //           // Check if the selected option matches the correct answer
  //           if (responses[key] === correctAnswer) {
  //             const marks = question.marks.split(',').map(Number);
  //             sectionMarks += marks[0]; // Add marks for correct answer
  //           } else if (inputValues[key] === correctAnswer) {
  //             const marks = question.marks.split(',').map(Number);
  //             sectionMarks += marks[0]; // Add marks for correct input value
  //           } else {
  //             const marks = question.marks.split(',').map(Number);
  //             sectionMarks -= marks[1]; // Subtract marks for incorrect response or input value
  //           }
  //         }
  //       });
  
  //       // Store section-wise marks
  //       setSectionMarks((prevSectionMarks) => ({
  //         ...prevSectionMarks,
  //         [`${subject.SubjectName}-${section.SectionName}`]: sectionMarks,
  //       }));
  
  //       // Add section marks to subject marks
  //       subjectMarks += sectionMarks;
  //     });
  
  //     // Store subject-wise marks
  //     setSubjectMarks((prevSubjectMarks) => ({
  //       ...prevSubjectMarks,
  //       [subject.SubjectName]: subjectMarks,
  //     }));
  
  //     // Increment total marks
  //     totalMarks += subjectMarks;
  //   });
  
  //   // Store total marks
  //   setTotalMarks(totalMarks);
  // };


  const calculateMarks = () => {
    let totalMarks = 0;
  
    // Iterate over subjects
    JEEquestionsData.forEach((subject) => {
      let subjectMarks = 0;
  
      // Iterate over sections
      subject.sections.forEach((section) => {
        let sectionMarks = 0;
  
        // Iterate over questions in the section
        section.questions.forEach((question) => {
          const key = getQuestionKey(
            subject.SubjectName,
            section.SectionName,
            question.question_id
          );
  
          // Check if the question is answered
          if (responses[key] !== undefined || inputValues[key] !== undefined) {
            // Get the correct answer
            const correctAnswer = question.answer;
  
            // Check if the selected option matches the correct answer
            let isResponseCorrect = false;
  
            if (Array.isArray(responses[key])) {
              // For multiple-select questions
              const userResponseSet = new Set(responses[key]);
              const correctAnswerSet = new Set(correctAnswer);
              isResponseCorrect = isEqualSets(userResponseSet, correctAnswerSet);
            } else {
              // For single-select questions or numeric input questions
              const userResponse = responses[key] !== undefined ? responses[key].toString().trim().toLowerCase() : "";
              isResponseCorrect = userResponse === correctAnswer || (inputValues[key] !== undefined && inputValues[key].trim().toLowerCase() === correctAnswer);
            }
  
            if (isResponseCorrect) {
              const marks = question.marks.split(',').map(Number);
              sectionMarks += marks[0]; // Add marks for correct answer
            } else {
              const marks = question.marks.split(',').map(Number);
              sectionMarks -= marks[1]; // Subtract marks for incorrect response or input value
            }
          }
        });
  
        // Store section-wise marks
        setSectionMarks((prevSectionMarks) => ({
          ...prevSectionMarks,
          [`${subject.SubjectName}-${section.SectionName}`]: sectionMarks,
        }));
  
        // Add section marks to subject marks
        subjectMarks += sectionMarks;
      });
  
      // Store subject-wise marks
      setSubjectMarks((prevSubjectMarks) => ({
        ...prevSubjectMarks,
        [subject.SubjectName]: subjectMarks,
      }));
  
      // Increment total marks
      totalMarks += subjectMarks;
    });
  
    // Store total marks
    setTotalMarks(totalMarks);
  };
  
  // Call calculateMarks function on mount
  useEffect(() => {
    calculateMarks();
  }, []);


  return (
    <div>
      <div className="mocktestparent_conatiner">
        {/* -------------------header------- */}
        <div>
          <div className="remaining">
            <div className="bitsatMockHeader">
              <img
                src="https://www.egradtutor.in/static/media/egate%20logo%201.8b1eb31347d60945925f.png"
                alt=""
              />
            </div>

            <h4>
              <b>JEE Mains Mock Test 1</b>
            </h4>

            <p>
              Time remaining:{" "}
              {`${Math.floor(remainingTime / 3600)}:${Math.floor(
                (remainingTime % 3600) / 60
              )}:${remainingTime % 60}`}
            </p>
          </div>
        </div>
        {/* -------------------interface------- */}

        <div>
          <div className="mocktest_conatiner_testwithpallet">
            <div className="mocktest_conatiner_testwithpalletquizcontainer">
              <div className="mocktest_conatiner_testwithpalletquizcontainersub">
                {/* -------------------sectionbtns------- */}
                <div className="sectionbtnmock">
                  {JEEquestionsData.map((subject) => (
                    <button
                      key={subject.SubjectName}
                      onClick={() => handleSubjectChange(subject.SubjectName)}
                      className={
                        selectedSubject === subject.SubjectName ? "active" : ""
                      }
                    >
                      {subject.SubjectName}
                    </button>
                  ))}
                </div>
                <div className="sectionbtnmock">
                  {selectedSubject && (
                    <div className="section-selection">
                      {currentSubject.sections.map((section, index) => (
                        <button
                          key={section.SectionName}
                          onClick={() => handleSectionChange(index)}
                          className={
                            currentSectionIndex === index ? "active" : ""
                          }
                        >
                          {section.SectionName}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
                <div className="mocktest_conatiner_testwithpalletquizcontainersubintopart">
                  {selectedSubject && currentQuestion && (
                    <div>
                      <p>Question {currentQuestion.question_id}:</p>
                      <img src={currentQuestion.questionImgName} alt="" />

                      <div className="optionimginmock">
                        {(currentQuestion.qtype === "MCQ4" ||
                          currentQuestion.qtype === "MCQ5") && (
                          <div className="optionimginmock">
                            <p>Options:</p>
                            {currentQuestion.options.map((option) => (
                              <label key={option.option_id} className="option">
                                <input
                                  type="radio"
                                  id={`option-${option.option_id}`}
                                  name={`question-${currentQuestion.question_id}`}
                                  checked={
                                    responses[
                                      getQuestionKey(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id
                                      )
                                    ] === option.option_index
                                  }
                                  onChange={() =>
                                    handleOptionChange(option.option_index)
                                  }
                                />
                                <span htmlFor={`option-${option.option_id}`}>
                                  ({option.option_index})
                                </span>
                                <img src={option.optionImgName} alt="" />
                              </label>
                            ))}
                          </div>
                        )}
                        {(currentQuestion.qtype === "MSQ" ||
                          currentQuestion.qtype === "MSQN") && (
                          <div className="optionimginmock">
                            <p>Options:</p>
                            {currentQuestion.options.map((option) => (
                              <label key={option.option_id} className="option">
                                <input
                                  type="checkbox"
                                  id={`option-${option.option_id}`}
                                  // name={`question-${currentQuestion.question_id}`}
                                  checked={
                                    responses[
                                      getQuestionKey(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id
                                      )
                                    ] &&
                                    responses[
                                      getQuestionKey(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id
                                      )
                                    ].includes(option.option_index)
                                  }
                                  onChange={() =>
                                    handleCheckboxChange(option.option_index)
                                  }
                                />
                                <span htmlFor={`option-${option.option_id}`}>
                                  ({option.option_index})
                                </span>
                                <img src={option.optionImgName} alt="" />
                              </label>
                            ))}
                          </div>
                        )}

                        {currentQuestion.qtype === "NATI" && (
                          <div className="calculator">
                            <div className="display">
                              <input
                                type="text"
                                value={getInputValue(
                                  selectedSubject,
                                  currentSectionIndex,
                                  currentQuestion.question_id
                                )}
                                readOnly // Make the input read-only to prevent direct editing
                                placeholder="Enter your answer NATI"
                              />
                            </div>

                            {/* Button for DEL */}
                            <div>
                              <input
                                type="button"
                                value="DEL"
                                onClick={() => {
                                  const key = getQuestionKey(
                                    selectedSubject,
                                    currentSectionIndex,
                                    currentQuestion.question_id
                                  );
                                  const currentValue =
                                    getInputValue(
                                      currentQuestion.question_id
                                    ) || "";
                                  updateInputValue(
                                    selectedSubject,
                                    currentSectionIndex,
                                    currentQuestion.question_id,
                                    (getInputValue(
                                      currentQuestion.question_id
                                    ) || "") + "DEL"
                                  );
                                }}
                              />
                            </div>

                            {/* Buttons for numbers */}
                            <div>
                              <div>
                                {[7, 8, 9].map((num) => (
                                  <input
                                    key={num}
                                    type="button"
                                    value={num}
                                    // onClick={() =>
                                    //   updateInputValue(
                                    //     currentQuestion.question_id,
                                    //     (getInputValue(
                                    //       currentQuestion.question_id
                                    //     ) || "") + num
                                    //   )
                                    // }
                                    onClick={() => {
                                      const key = getQuestionKey(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id
                                      );
                                      const currentValue =
                                        getInputValue(
                                          currentQuestion.question_id
                                        ) || "";
                                      updateInputValue(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id,
                                        (getInputValue(
                                          currentQuestion.question_id
                                        ) || "") + num
                                      );
                                    }}
                                  />
                                ))}
                              </div>
                              <div>
                                {[4, 5, 6].map((num) => (
                                  <input
                                    key={num}
                                    type="button"
                                    value={num}
                                    onClick={() => {
                                      const key = getQuestionKey(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id
                                      );
                                      const currentValue =
                                        getInputValue(
                                          currentQuestion.question_id
                                        ) || "";
                                      updateInputValue(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id,
                                        (getInputValue(
                                          currentQuestion.question_id
                                        ) || "") + num
                                      );
                                    }}
                                  />
                                ))}
                              </div>
                              <div>
                                {[1, 2, 3].map((num) => (
                                  <input
                                    key={num}
                                    type="button"
                                    value={num}
                                    onClick={() => {
                                      const key = getQuestionKey(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id
                                      );
                                      const currentValue =
                                        getInputValue(
                                          currentQuestion.question_id
                                        ) || "";
                                      updateInputValue(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id,
                                        (getInputValue(
                                          currentQuestion.question_id
                                        ) || "") + num
                                      );
                                    }}
                                  />
                                ))}
                              </div>
                              <div>
                                {[0].map((num) => (
                                  <input
                                    key={num}
                                    type="button"
                                    value={num}
                                    onClick={() => {
                                      const key = getQuestionKey(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id
                                      );
                                      const currentValue =
                                        getInputValue(
                                          currentQuestion.question_id
                                        ) || "";
                                      updateInputValue(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id,
                                        (getInputValue(
                                          currentQuestion.question_id
                                        ) || "") + num
                                      );
                                    }}
                                  />
                                ))}
                                <input
                                  type="button"
                                  value="."
                                  onClick={() => {
                                    const key = getQuestionKey(
                                      selectedSubject,
                                      currentSectionIndex,
                                      currentQuestion.question_id
                                    );
                                    const currentValue =
                                      getInputValue(
                                        currentQuestion.question_id
                                      ) || "";
                                    updateInputValue(
                                      selectedSubject,
                                      currentSectionIndex,
                                      currentQuestion.question_id,
                                      (getInputValue(
                                        currentQuestion.question_id
                                      ) || "") + "."
                                    );
                                  }}
                                />
                                <input
                                  type="button"
                                  value="-"
                                  onClick={() => {
                                    const key = getQuestionKey(
                                      selectedSubject,
                                      currentSectionIndex,
                                      currentQuestion.question_id
                                    );
                                    const currentValue =
                                      getInputValue(
                                        currentQuestion.question_id
                                      ) || "";
                                    updateInputValue(
                                      selectedSubject,
                                      currentSectionIndex,
                                      currentQuestion.question_id,
                                      (getInputValue(
                                        currentQuestion.question_id
                                      ) || "") + "-"
                                    );
                                  }}
                                />
                              </div>
                            </div>

                            {/* Buttons for '.' and '-' */}
                          </div>
                        )}
                        {currentQuestion.qtype === "NATD" && (
                          <div className="calculator">
                            <div className="display">
                              <input
                                type="text"
                                value={getInputValue(
                                  selectedSubject,
                                  currentSectionIndex,
                                  currentQuestion.question_id
                                )}
                                readOnly // Make the input read-only to prevent direct editing
                                placeholder="Enter your answer NATD"
                              />
                            </div>

                            {/* Button for DEL */}
                            <div>
                              <input
                                type="button"
                                value="DEL"
                                onClick={() => {
                                  const key = getQuestionKey(
                                    selectedSubject,
                                    currentSectionIndex,
                                    currentQuestion.question_id
                                  );
                                  const currentValue =
                                    getInputValue(
                                      currentQuestion.question_id
                                    ) || "";
                                  updateInputValue(
                                    selectedSubject,
                                    currentSectionIndex,
                                    currentQuestion.question_id,
                                    currentValue.slice(0, -1)
                                  );
                                }}
                              />
                            </div>

                            {/* Buttons for numbers */}
                            <div>
                              <div>
                                {[7, 8, 9].map((num) => (
                                  <input
                                    key={num}
                                    type="button"
                                    value={num}
                                    // onClick={() =>
                                    //   updateInputValue(
                                    //     currentQuestion.question_id,
                                    //     (getInputValue(
                                    //       currentQuestion.question_id
                                    //     ) || "") + num
                                    //   )
                                    // }
                                    onClick={() => {
                                      const key = getQuestionKey(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id
                                      );
                                      const currentValue =
                                        getInputValue(
                                          currentQuestion.question_id
                                        ) || "";
                                      updateInputValue(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id,
                                        (getInputValue(
                                          currentQuestion.question_id
                                        ) || "") + num
                                      );
                                    }}
                                  />
                                ))}
                              </div>
                              <div>
                                {[4, 5, 6].map((num) => (
                                  <input
                                    key={num}
                                    type="button"
                                    value={num}
                                    onClick={() => {
                                      const key = getQuestionKey(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id
                                      );
                                      const currentValue =
                                        getInputValue(
                                          currentQuestion.question_id
                                        ) || "";
                                      updateInputValue(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id,
                                        (getInputValue(
                                          currentQuestion.question_id
                                        ) || "") + num
                                      );
                                    }}
                                  />
                                ))}
                              </div>
                              <div>
                                {[1, 2, 3].map((num) => (
                                  <input
                                    key={num}
                                    type="button"
                                    value={num}
                                    onClick={() => {
                                      const key = getQuestionKey(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id
                                      );
                                      const currentValue =
                                        getInputValue(
                                          currentQuestion.question_id
                                        ) || "";
                                      updateInputValue(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id,
                                        (getInputValue(
                                          currentQuestion.question_id
                                        ) || "") + num
                                      );
                                    }}
                                  />
                                ))}
                              </div>
                              <div>
                                {[0].map((num) => (
                                  <input
                                    key={num}
                                    type="button"
                                    value={num}
                                    onClick={() => {
                                      const key = getQuestionKey(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id
                                      );
                                      const currentValue =
                                        getInputValue(
                                          currentQuestion.question_id
                                        ) || "";
                                      updateInputValue(
                                        selectedSubject,
                                        currentSectionIndex,
                                        currentQuestion.question_id,
                                        (getInputValue(
                                          currentQuestion.question_id
                                        ) || "") + num
                                      );
                                    }}
                                  />
                                ))}
                                <input
                                  type="button"
                                  value="."
                                  onClick={() => {
                                    const key = getQuestionKey(
                                      selectedSubject,
                                      currentSectionIndex,
                                      currentQuestion.question_id
                                    );
                                    const currentValue =
                                      getInputValue(
                                        currentQuestion.question_id
                                      ) || "";
                                    updateInputValue(
                                      selectedSubject,
                                      currentSectionIndex,
                                      currentQuestion.question_id,
                                      (getInputValue(
                                        currentQuestion.question_id
                                      ) || "") + "."
                                    );
                                  }}
                                />
                                <input
                                  type="button"
                                  value="-"
                                  onClick={() => {
                                    const key = getQuestionKey(
                                      selectedSubject,
                                      currentSectionIndex,
                                      currentQuestion.question_id
                                    );
                                    const currentValue =
                                      getInputValue(
                                        currentQuestion.question_id
                                      ) || "";
                                    updateInputValue(
                                      selectedSubject,
                                      currentSectionIndex,
                                      currentQuestion.question_id,
                                      (getInputValue(
                                        currentQuestion.question_id
                                      ) || "") + "-"
                                    );
                                  }}
                                />
                              </div>
                            </div>

                            {/* Buttons for '.' and '-' */}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {/* -------------------------------------------buttons Option container-------------- */}
                <div>
                  <div class="mocktest_conatiner_testwithpalletbuttons">
                    {" "}
                    <div>
                      <button
                        class="Quiz_clearResponse Quiz_mockbtns Quiz_mockbtnsclear"
                        onClick={clearResponse}
                      >
                        Clear Response
                      </button>
                      {/* <button onClick={markForReview}>Mark For Review</button> */}
                      <button
                        class="quizsave_next previous_next_btn Quiz_mockbtns"
                        onClick={saveAndNext}
                      >
                        Save &amp; Next
                      </button>
                    </div>
                    <div>
                      <button
                        class="Quiz_mockbtns Quiz_mockbtnspre"
                        onClick={previousQuestion}
                      >
                        Previous
                      </button>
                      <button
                        class="Quiz_mockbtns Quiz_mockbtnsnext"
                        onClick={nextQuestion}
                      >
                        Next
                      </button>
                      <button
                        class="Quiz_mockbtns Quiz_mockbtnssubmit"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="numberpalette">
                <div class="rightSidebar-topHeader">
                  <img
                    title="Guest"
                    src="/static/media/guesticon.00ad06aaa8a7af8eb6c5.jpg"
                    alt="guesticon"
                  />
                  <p>Candidate Name: Guest</p>
                </div>
                <div className="yoursection_inMockTest">
                  <span>
                    You are viewing{" "}
                    <label htmlFor="">{currentSubject.SubjectName}</label>
                    Section{" "}
                  </span>
                  <b>Question Palette</b>
                </div>
                <div className="question-number-palette question-numberpalette question-palette">
                  {/* {currentSection.questions.map((question, index) => {
                    const key = getQuestionKey(
                      selectedSubject,
                      currentSectionIndex,
                      question.question_id
                    );
                    return (
                      <button
                        key={question.question_id}
                        className={`
                    ${index === currentQuestionIndex ? "active" : ""}
                    ${
                      answeredQuestions[key] && !markedForReview[key]
                        ? "answered"
                        : ""
                    }
                    ${viewedQuestions[key] && !responses[key] ? "viewed" : ""}
                    ${
                      markedForReview[key]
                        ? responses[key]
                          ? "marked-answered"
                          : "marked"
                        : ""
                    }
                    ${
                      responses[key] !== undefined && !answeredQuestions[key]
                        ? "answered-red active"
                        : ""
                    }
                  `}
                        onClick={() => goToQuestion(index)}
                      >
                        {index + 1}
                      </button>
                    );
                  })} */}
                    {/* // Button rendering code */}
  {currentSection.questions.map((question, index) => {
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      question.question_id
    );
    return (
      <button
        key={question.question_id}
        className={`
          ${index === currentQuestionIndex ? "active" : ""}
          ${answeredQuestions[key] && !markedForReview[key] ? "answered" : ""}
          ${viewedQuestions[key] && !responses[key] ? "viewed" : ""}
          ${markedForReview[key] ? (responses[key] ? "marked-answered" : "marked") : ""}
          ${responses[key] !== undefined && !answeredQuestions[key] ? "answered-red active" : ""}
        `}
        onClick={() => goToQuestion(index)}
      >
        {index + 1}
      </button>
    );
  })}
                </div>
                <div className="sidebar-footer">
                  <h4 className="sidebar-footer-header">Legend:</h4>
                  <div className="footer-btns">
                    <div className="inst-btns">
                      {/* <img src={grayBox} alt="Not Visited" /> */}
                      <span className="NotVisited">
                        {counts.notVisitedCount}
                      </span>

                      <label htmlFor="">Not Visited</label>
                    </div>
                    <div className="inst-btns">
                      {/* <img src={greenBox} alt="Answered" /> */}
                      <span className="Answered">{answeredCount}</span>
                      <label htmlFor="">Answered</label>
                    </div>
                    <div className="inst-btns">
                      {/* <img src={orangeBox} alt="Not Answered" /> */}
                      <span className="NotAnsweredlegend">
                        {" "}
                        {notAnsweredCount}
                      </span>
                      <label htmlFor="">Not Answered</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="scorecardforexam">
      {showPopup && (
  <div className="popup">
    <div className="popup-content">
      {remainingTime === 0 ? (
        <div>
          <p>Your Time is up!</p>
          <p>Your test is automatically submitted successfully.</p>
        </div>
      ) : (
        <p>You have successfully submitted your test.</p>
      )}
      <p>
        <img
          src="/static/media/prize.e48700d2bb773b1eda58.jpg"
          alt="Congratulations"
        />
        <span>
          <h2>Congratulations!</h2>
          <span className="mockscore">Your total score: {totalMarks}</span>
          <div>
          {subjectMarks && (
     <ul className="allscorecountingmock">
              {Object.entries(subjectMarks).map(([subject, marks]) => (
                <li key={subject}>
                  <div className="allscorecountingmock"><b>{subject}:</b> {marks}</div>
                </li>
              ))}
            </ul>
          )}
          </div>

          <div>
          {sectionMarks && (
            <ul className="allscorecountingmock">
              {Object.entries(sectionMarks).map(([section, marks]) => (
                <li key={section}>
                  <div className="allscorecountingmock"><b>{section}:</b> {marks}</div>
                </li>
              ))}
            </ul>
          )}
          </div>
         
          
        </span>
      </p>

      <ul className="allscorecountingmock">
        <li>
          <b>Answered:</b> {answeredCount}
        </li>
        <li>
          <b>Not Answered:</b> {notAnsweredCount}
        </li>
        <li>
          <b>Right Answered:</b> {rightAnsweredCount}
        </li>
        <li>
          <b>Wrong Answered:</b> {wrongAnsweredCount}
        </li>
      </ul>
      <button onClick={closePopup}>Close</button>
    </div>
  </div>
)}

      </div>
    </div>
  );
}

export default Jee_advance_1;
