import React,{useState,useEffect} from 'react';
import { useNavigate} from "react-router-dom";

import { IoIosArrowBack } from "react-icons/io";
import Instructions_image from  '../../../Images/IITJAM_INSTRUCTIONS_IMAGE.png';
import NewCandidateImage_iitJam_exam from '../../../Images/NewCandidateImage_iitJam_exam.jpg';
import { Link, useParams } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';

const IITJAM_Geology_General_Instructions = () => {
    const [isChecked, setIsChecked] = useState(false);
    const navigate = useNavigate();

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
      };
      const handleBegin = () => {
        navigate(``)
      };
    const { mockId } = useParams();
  return (
    <div className='IITJAM_instruction_page_Container'>

        <div className='Instructions_page_left_container'>
        <div className='IITJAM_instruction_page_heading'> 
            <h1 className='IIT_jam_heading'>Other Important Instructions</h1>
        </div>

        <div className='All_instructions_container'>
            <div className='instructions_headingTwo_container'>
            <h2 className='instructions_headingTwo'>Paper-specific Instructions
            Geology (GG)</h2>
            </div>
          
          <div className='IITJAM_Instructions_order_list_container'>
            <ul className='IITJAM_Instructions_order_list'>
                <li className='IITJAM_Instructions_list_tag'>This test paper has a total of 60 questions carrying 100 marks. The entire question paper is divided into three <span>sections, A, B and C</span>. All sections are compulsory. Questions in each section are of different types.</li>
                <li className='IITJAM_Instructions_list_tag'><span> Section – A </span> contains <span>Multiple Choice Questions (MCQ).</span> Each MCQ type question has four choices out of which only one choice is the correct answer. This section has 30 Questions and carry a total of 50 marks. Q.1 – Q.10 carry 1 mark each and Questions Q.11 – Q.30 carry 2 marks each.</li>
                <li className='IITJAM_Instructions_list_tag'><span> Section – B </span>contains <span>Multiple Select Questions (MSQ).</span> Each MSQ type question has four choices, out of which there may be one or more than one choice(s) that are correct. The candidate gets full credit only if all the correct choices are selected and no incorrect choice(s) are selected. This section has 10 Questions and carry a total of 20 marks. Questions Q.1 – Q.10 in this section carry 2 marks each.</li>
                <li className='IITJAM_Instructions_list_tag'> <span>Section – C </span> contains <span>Numerical Answer Type (NAT) </span>questions. For these questions, the answer is a real number which needs to be entered using the virtual numerical keypad on the monitor. No choices will be shown for these type of questions. This section has 20 Questions and carry a total of 30 marks. Q.1 – Q.10 carry 1 mark each and Questions Q.11 – Q.20 carry 2 marks each.</li>
                <li className='IITJAM_Instructions_list_tag'>5. In all sections, questions not answered and saved will result in zero mark. <span>In Section – A</span>(MCQ), wrong answer will result in <span> NEGATIVE </span> marks. For all 1 mark questions, 1/3 marks will be deducted for each wrong answer. For all 2 marks questions, 2/3 marks will be deducted for each wrong answer. In <span>Section – B </span>(MSQ), there is <span>NO NEGATIVE</span> and <span>NO PARTIAL </span>marking provision. There is<span> NO NEGATIVE</span> marking in <span>Section – C </span>(NAT) as well.</li>
                <li className='IITJAM_Instructions_list_tag'>. A Virtual Scientific Calculator is provided on your computer screen. Any other calculators, charts, graph sheets, tables, smart watches, watches, bags, pouches, Bluetooth devices, mobile phones or electronic gadgets are NOT allowed inside the examination hall</li>

                <li className='IITJAM_Instructions_list_tag'>You can use the scribble pad provided to you at the examination center for all your rough work. Write your name and registration number on the scribble pad before using it. The scribble pad MUST be returned to the invigilators after the end of the examination.</li>
                <li className='IITJAM_Instructions_list_tag'> Some useful data/notation required for your paper can be viewed by clicking on the Useful Data link that appears on top right hand side of the screen. The same information is also given below for your convenience.</li>
            </ul>
            <div className='Instructions_page_image_container'>
                <img src = {Instructions_image} />
                
                </div>
          </div>
            </div>

            <div className="Instructions_checkbox_container">
            <div>
            <input type="checkbox" onChange={handleCheckboxChange} className="checkbox" />

            </div>
            <div>
            <span style={{ fontWeight: 500, color: "black" }}>
          I have read and understood the instructions. All computer hardware
            allotted to me are in proper working condition. I declare that I am
            not in possession of / not wearing / not carrying any prohibited
            gadget like mobile phone, Bluetooth devices, etc. / any prohibited
            material with me into the Examination Hall. I agree that in case of
            not adhering to the instructions, I shall be liable to be debarred
            from this Test and/or to disciplinary action, which may include ban
            from future Tests / Examinations.
          </span>
            </div>
         
        </div>

        <div className="Instructions_page_buttons_container">
          <button className="Instruction_page_pervious_btn">
            <Link to={`/IIT_JAM_Geology_Instructions/${mockId}`}>
              <IoIosArrowBack /> Previous
            </Link>
          </button>
          <div className="intro_next_btn_container">
          {isChecked ? (
            <button className="disabled-link gn_next_btn_bull" onClick={handleBegin}>
              I am ready to begin <AiOutlineArrowRight /></button>
          ) : (
            <div>
              <span className="disabled-link gn_next_btn_bull ">
                I am ready to begin <AiOutlineArrowRight />
              </span>
            </div>
          )}
        </div>
        </div>




        </div>
        <div className='Instructions_page_right_container'>
            <img src = {NewCandidateImage_iitJam_exam} />
        </div>
    </div>
  )
}

export default IITJAM_Geology_General_Instructions


