// import React, { useState, useEffect } from 'react';
// import data from './Downloads_file_JeeMain_table_data.js';
// import Examheader from '../../../UG/Examheader.jsx';
// import { FaDownload } from "react-icons/fa";
// import { IoIosArrowForward } from "react-icons/io";
// import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
// import { MdKeyboardDoubleArrowRight } from "react-icons/md";
// // Your existing imports

// const IitjeeDownloadPage_new = () => {
//   // const rowsPerPage = 15; 
//   // const [currentPage, setCurrentPage] = useState(1);

//   const years = Object.keys(data);
//   const [selectedYear, setSelectedYear] = useState('2024');
//   const [selectedShift, setSelectedShift] = useState(null);
//   const [openMonth, setOpenMonth] = useState('JANUARY');
//   const [visibleShift, setVisibleShift] = useState(null);

//   // Function to find default shift
//   const findDefaultShift = (year) => {
//     const months = data[year][0].months;
//     if (months.length > 0) {
//       const defaultMonth = months.find(month => month.month.toUpperCase() === 'JANUARY');
//       if (defaultMonth && defaultMonth.data.length > 0) {
//         return defaultMonth.data.find(shift => shift.shift === '27th Jan - Shift-1');
//       }
//     }
//     return null;
//   };

//   // Effect to set default selected shift based on initial year
//   useEffect(() => {
//     if (data[selectedYear]) {
//       const defaultShift = findDefaultShift(selectedYear);
//       if (defaultShift) {
//         setSelectedShift(defaultShift);
//         setVisibleShift(defaultShift);
//       } else {
//         console.log("No default shift found for the selected year.");
//       }
//     }
//   }, [selectedYear]);

//   // Function to handle year click
//   const handleYearClick = (year) => {
//     if (year === selectedYear) {
//       setOpenMonth(openMonth === 'JANUARY' ? null : 'JANUARY');
//     } else {
//       setSelectedYear(year);
//       setSelectedShift(null);
//       setOpenMonth('JANUARY');
//     }
//   };

//   // Function to handle shift click
//   const handleShiftClick = (shift) => {
//     setSelectedShift(selectedShift === shift ? null : shift);
//     if (selectedYear) {
//       setVisibleShift(shift);
//     }
//   };

//   // Function to handle month click
//   const handleMonthClick = (month) => {
//     setOpenMonth(openMonth === month ? null : month);
//   };

//   // Calculate total pages
//   // const totalPages = Math.ceil((visibleShift?.items.length || 0) / rowsPerPage);

//   // Get current page data
//   // const getCurrentPageData = () => {
//   //   if (!visibleShift) return [];
//   //   const startIndex = (currentPage - 1) * rowsPerPage;
//   //   const endIndex = startIndex + rowsPerPage;
//   //   return visibleShift.items.slice(startIndex, endIndex);
//   // };

//   // Handle page change
//   // const handlePageChange = (page) => {
//   //   setCurrentPage(page);
//   // };

//   return (
//     <div>
//       <Examheader />
//       <h1 className='DownloadPage_heading'>JEE MAINS SOLVED PAPERS</h1>

//       <div className='Downloads_page_sub_container'>
//         <div className='Main_layout'>
//           <div className='Left_container'>
//             <div className='Year_selection_container'>
//               <h2 className='Year_wise_pdfs_heading'>YEAR WISE PDFS</h2>
//               <ul className='List_of_years_container'>
//                 {years.map((year) => (
//                   <li
//                     key={year}
//                     onClick={() => handleYearClick(year)}
//                     style={{ cursor: 'pointer', fontWeight: selectedYear === year ? 'bold' : 'normal' }}
//                   >
//                     {year}
//                   </li>
//                 ))}
//               </ul>
//             </div>

//             {selectedYear && (
//               <div className='Months_selected_container'>
//                 <h4 className='Session_container_heading'>SESSIONS & SHIFTS</h4>
//                 <ul className='Shift_container_heading'>
//                   {data[selectedYear][0].months.map((month, monthIndex) => (
//                     <li className="Session_Names" key={monthIndex}>
//                       <div
//                         onClick={() => handleMonthClick(month.month)}
//                         style={{ cursor: 'pointer', fontWeight: 'bold' }}
//                       >
//                         <IoIosArrowForward /> {month.month} SHIFTS
//                       </div>
//                       <ul
//                         className='Session_shift_names'
//                         style={{ display: openMonth === month.month ? 'block' : 'none' }}
//                       >
//                         {month.data.map((shift, shiftIndex) => (
//                           <li
//                             key={shiftIndex}
//                             onClick={() => handleShiftClick(shift)}
//                             style={{ cursor: 'pointer', fontWeight: visibleShift === shift ? 'bold' : 'normal' }}
//                           >
//                             {shift.shift}
//                           </li>
//                         ))}
//                       </ul>
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//             )}
//           </div>

//           {visibleShift && (
//             <div className='Table_container'>
//               <h4>JEE MAIN COMPLETE PAPER WITH VIDEO SOLUTION {selectedYear} {visibleShift.shift}</h4>
//               <div className='Pdfs_Links_Container'>
//                 <a className="Pdf_anchor_tag" href={`/${visibleShift.Maths_pdf}`}>MATHS PDF <FaDownload /></a>
//                 <a className="Pdf_anchor_tag" href={`/${visibleShift.Physics_pdf}`}>Physics PDF <FaDownload /></a>
//                 <a className="Pdf_anchor_tag" href={`/${visibleShift.Chemistry_pdf}`}>CHEMISTRY PDF <FaDownload /></a>
//               </div>

//               <table className="table-container">
//                 <thead>
//                   <tr className='Table_rows_container'>
//                     <th>S.No</th>
//                     <th>MATHEMATICS LINK</th>
//                     <th>PHYSICS LINK</th>
//                     <th>CHEMISTRY LINK</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {visibleShift.items.map((item, itemIndex) => (
//                     <tr className="Table_data_container" key={itemIndex}>
//                       <td>{item.sNo}</td>
//                       <td><button>{item.MathsLink}
//                         </button></td>
//                       <td><button>{item.PhysicsLink}</button></td>
//                       <td><button>{item.ChemistryLink}</button></td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>


//               {/* Pagination Controls */}
//               {/* <div className="pagination-controls">
//               <MdOutlineKeyboardDoubleArrowLeft />

//                 {Array.from({ length: totalPages }, (_, index) => (
                 
//                   <button className='Pagenation_button'
//                     key={index + 1}
//                     onClick={() => handlePageChange(index + 1)}
//                     style={{ fontWeight: currentPage === index + 1 ? 'bold' : 'normal' }}
//                   >
//                     {index + 1}
//                   </button>
//                 ))}
//               <MdKeyboardDoubleArrowRight />

//               </div> */}

//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default IitjeeDownloadPage_new;







// pending functionality working code just adjust css


// import React, { useState, useEffect } from 'react';
// import data from './Downloads_file_JeeMain_table_data.js';
// import Examheader from '../../../UG/Examheader.jsx';
// import { FaDownload } from "react-icons/fa";
// import { IoIosArrowForward } from "react-icons/io";
// import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
// import { MdKeyboardDoubleArrowRight } from "react-icons/md";
// // Your existing imports

// const IitjeeDownloadPage_new = () => {
//   // const rowsPerPage = 15; 
//   // const [currentPage, setCurrentPage] = useState(1);

//   const years = Object.keys(data);
//   const [selectedYear, setSelectedYear] = useState('2024');
//   const [selectedShift, setSelectedShift] = useState(null);
//   const [openYear, setOpenYear] = useState('2024');
//   const [openMonth, setOpenMonth] = useState(null);
//   const [visibleShift, setVisibleShift] = useState(null);

//   // Function to find default shift
//   const findDefaultShift = (year) => {
//     const months = data[year][0].months;
//     if (months.length > 0) {
//       const defaultMonth = months.find(month => month.month.toUpperCase() === 'JANUARY');
//       if (defaultMonth && defaultMonth.data.length > 0) {
//         return defaultMonth.data.find(shift => shift.shift === '27th Jan - Shift-1');
//       }
//     }
//     return null;
//   };

//   // Effect to set default selected shift based on initial year
//   useEffect(() => {
//     if (data[selectedYear]) {
//       const defaultShift = findDefaultShift(selectedYear);
//       if (defaultShift) {
//         setSelectedShift(defaultShift);
//         setVisibleShift(defaultShift);
//       } else {
//         console.log("No default shift found for the selected year.");
//       }
//     }
//   }, [selectedYear]);

//   // Function to handle year click
//   const handleYearClick = (year) => {
//     setOpenYear(openYear === year ? null : year);
//     setSelectedYear(year);
//     setOpenMonth(null); // Reset the open month when year changes
//   };

//   // Function to handle shift click
//   const handleShiftClick = (shift) => {
//     setSelectedShift(selectedShift === shift ? null : shift);
//     if (selectedYear) {
//       setVisibleShift(shift);
//     }
//   };

//   // Function to handle month click
//   const handleMonthClick = (month) => {
//     setOpenMonth(openMonth === month ? null : month);
//   };

//   // Calculate total pages
//   // const totalPages = Math.ceil((visibleShift?.items.length || 0) / rowsPerPage);

//   // Get current page data
//   // const getCurrentPageData = () => {
//   //   if (!visibleShift) return [];
//   //   const startIndex = (currentPage - 1) * rowsPerPage;
//   //   const endIndex = startIndex + rowsPerPage;
//   //   return visibleShift.items.slice(startIndex, endIndex);
//   // };

//   // Handle page change
//   // const handlePageChange = (page) => {
//   //   setCurrentPage(page);
//   // };

//   return (
//     <div>
//       <Examheader />
//       <h1 className='DownloadPage_heading'>JEE MAINS SOLVED PAPERS</h1>

//       <div className='Downloads_page_sub_container'>
//         <div className='Main_layout'>
//           <div className='Left_container'>
//             <div className='Year_selection_container'>
//               <h2 className='Year_wise_pdfs_heading'>YEAR WISE PDFS</h2>
//               <ul className='List_of_years_container'>
//                 {years.map((year) => (
//                   <li key={year}>
//                     <div
//                       onClick={() => handleYearClick(year)}
//                       style={{ cursor: 'pointer', fontWeight: selectedYear === year ? 'bold' : 'normal' }}
//                     >
//                       {year}
//                     </div>
//                     {openYear === year && (
//                       <div className='Months_selected_container'>
//                         <h4 className='Session_container_heading'>SESSIONS & SHIFTS</h4>
//                         <ul className='Shift_container_heading'>
//                           {data[year][0].months.map((month, monthIndex) => (
//                             <li className="Session_Names" key={monthIndex}>
//                               <div
//                                 onClick={() => handleMonthClick(month.month)}
//                                 style={{ cursor: 'pointer', fontWeight: 'bold' }}
//                               >
//                                 <IoIosArrowForward /> {month.month} SHIFTS
//                               </div>
//                               <ul
//                                 className='Session_shift_names'
//                                 style={{ display: openMonth === month.month ? 'block' : 'none' }}
//                               >
//                                 {month.data.map((shift, shiftIndex) => (
//                                   <li
//                                     key={shiftIndex}
//                                     onClick={() => handleShiftClick(shift)}
//                                     style={{ cursor: 'pointer', fontWeight: visibleShift === shift ? 'bold' : 'normal' }}
//                                   >
//                                     {shift.shift}
//                                   </li>
//                                 ))}
//                               </ul>
//                             </li>
//                           ))}
//                         </ul>
//                       </div>
//                     )}
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           </div>

//           {visibleShift && (
//             <div className='Table_container'>
//               <h4>JEE MAIN COMPLETE PAPER WITH VIDEO SOLUTION {selectedYear} {visibleShift.shift}</h4>
//               <div className='Pdfs_Links_Container'>
//                 <a className="Pdf_anchor_tag" href={`/${visibleShift.Maths_pdf}`}>MATHS PDF <FaDownload /></a>
//                 <a className="Pdf_anchor_tag" href={`/${visibleShift.Physics_pdf}`}>Physics PDF <FaDownload /></a>
//                 <a className="Pdf_anchor_tag" href={`/${visibleShift.Chemistry_pdf}`}>CHEMISTRY PDF <FaDownload /></a>
//               </div>

//               <table className="table-container">
//                 <thead>
//                   <tr className='Table_rows_container'>
//                     <th>S.No</th>
//                     <th>MATHEMATICS LINK</th>
//                     <th>PHYSICS LINK</th>
//                     <th>CHEMISTRY LINK</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {visibleShift.items.map((item, itemIndex) => (
//                     <tr className="Table_data_container" key={itemIndex}>
//                       <td>{item.sNo}</td>
//                       <td><button>{item.MathsLink}</button></td>
//                       <td><button>{item.PhysicsLink}</button></td>
//                       <td><button>{item.ChemistryLink}</button></td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>

//               {/* Pagination Controls */}
//               {/* <div className="pagination-controls">
//                 <MdOutlineKeyboardDoubleArrowLeft />

//                 {Array.from({ length: totalPages }, (_, index) => (
//                   <button className='Pagenation_button'
//                     key={index + 1}
//                     onClick={() => handlePageChange(index + 1)}
//                     style={{ fontWeight: currentPage === index + 1 ? 'bold' : 'normal' }}
//                   >
//                     {index + 1}
//                   </button>
//                 ))}
//                 <MdKeyboardDoubleArrowRight />
//               </div> */}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default IitjeeDownloadPage_new;

// end



import React, { useState, useEffect } from 'react';
import data from './Downloads_file_JeeMain_table_data.js';
import Examheader from '../../../UG/Examheader.jsx';
import { FaDownload } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import './styles/Downloads.css';
import './styles/New_downloads_file.css';
import Footer2 from '../../../UG/Footer2.jsx';

const IitjeeDownloadPage = () => {
  const years = Object.keys(data);
  const [selectedYear, setSelectedYear] = useState('2024');
  const [selectedShift, setSelectedShift] = useState(null);
  const [openYear, setOpenYear] = useState('2024');
  const [openMonth, setOpenMonth] = useState(null);
  const [visibleShift, setVisibleShift] = useState(null);

  const findDefaultShift = (year) => {
    const months = data[year][0].months;
    if (months.length > 0) {
      const defaultMonth = months.find(month => month.month.toUpperCase() === 'JANUARY');
      if (defaultMonth && defaultMonth.data.length > 0) {
        return defaultMonth.data.find(shift => shift.shift === '27th Jan - Shift-1');
      }
    }
    return null;
  };

  useEffect(() => {
    if (data[selectedYear]) {
      const defaultShift = findDefaultShift(selectedYear);
      if (defaultShift) {
        setSelectedShift(defaultShift);
        setVisibleShift(defaultShift);
      } else {
        console.log("No default shift found for the selected year.");
      }
    }
  }, [selectedYear]);

  const handleYearClick = (year) => {
    setOpenYear(openYear === year ? null : year);
    setSelectedYear(year);
    setOpenMonth(null);
  };

  const handleShiftClick = (shift) => {
    setSelectedShift(selectedShift === shift ? null : shift);
    if (selectedYear) {
      setVisibleShift(shift);
    }
  };

  const handleMonthClick = (month) => {
    setOpenMonth(openMonth === month ? null : month);
  };

  return (
    <div>
      <Examheader />
      <h1 className='DownloadPage_heading'>JEE MAINS SOLVED PAPERS</h1>

      <div className='Downloads_page_sub_container'>
        <div className='Main_layout'>







          <div className='Left_container'>
              <h2 className='Year_wise_pdfs_heading'>YEAR WISE PDFS</h2>
              <ul className='List_of_years_container'>
                {years.map((year) => (
                  <li key={year} style={{ marginBottom: '1rem' }}>
                    <button className = "Downloads_page_year" onClick={() => handleYearClick(year)} >
                      {year}
                    </button>
                    {openYear === year && (
             <div className='Months_selected_container'>
             <h4 className='Session_container_heading'>SESSIONS & SHIFTS</h4>
             <ul className='Shift_container_heading'>
               {data[year][0].months.map((month, monthIndex) => (
                 <li className="Session_Names" key={monthIndex} style={{ marginTop: '0.5rem' }}>
                   <div
                     onClick={() => handleMonthClick(month.month)}
                     className='Month_Button'
                   >
                    <p className='DownloadPage_Months_button'> <IoIosArrowForward /> {month.month} </p>
                   </div>
                   {openMonth === month.month && (
                     <div className='Session_shift_names'>
                       {month.data.map((shift, shiftIndex) => (
                         <p
                           key={shiftIndex}
                           onClick={() => handleShiftClick(shift)}
                         >
                           {shift.shift}
                         </p>
                       ))}
                     </div>
                   )}
                 </li>
               ))}
             </ul>
           </div>
           
                 
                    )}
                  </li>
                ))}
              </ul>
          </div>










          

          {visibleShift && (
            <div className='Table_container' style={{ }}>
              <h4>JEE MAIN COMPLETE PAPER WITH VIDEO SOLUTION {selectedYear} {visibleShift.shift}</h4>
              <div className='Pdfs_Links_Container' style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <a className="Pdf_anchor_tag" href={`/${visibleShift.Maths_pdf}`} style={{ display: 'block', marginBottom: '0.5rem' }}>MATHS PDF <FaDownload /></a>
                <a className="Pdf_anchor_tag" href={`/${visibleShift.Physics_pdf}`} style={{ display: 'block', marginBottom: '0.5rem' }}>Physics PDF <FaDownload /></a>
                <a className="Pdf_anchor_tag" href={`/${visibleShift.Chemistry_pdf}`} style={{ display: 'block', marginBottom: '0.5rem' }}>CHEMISTRY PDF <FaDownload /></a>
              </div>

              <table className="table-container" style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr className='Table_rows_container'>
                    <th style={{  padding: '8px', background: "#376893"}}>S.No</th>
                    <th style={{  padding: '8px', background: "#376893" }}>MATHEMATICS LINK</th>
                    <th style={{  padding: '8px', background: "#376893" }}>PHYSICS LINK</th>
                    <th style={{  padding: '8px', background: "#376893" }}>CHEMISTRY LINK</th>
                  </tr>
                </thead>
                <tbody>
                  {visibleShift.items.map((item, itemIndex) => (
                    <tr className="Table_data_container" key={itemIndex}>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.sNo}</td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}><button>{item.MathsLink}</button></td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}><button>{item.PhysicsLink}</button></td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}><button>{item.ChemistryLink}</button></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <Footer2 />

   
    </div>
  );
};

export default IitjeeDownloadPage;




