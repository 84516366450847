import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoMdHome } from "react-icons/io";
import onlinets1 from '../../../../UG/infographic/image-removebg-preview (10).png';
import onlinets from '../../../../UG/infographic/onlinets.png';
import { Nav } from '../../../../UG/Nav';
import Footer from "../../../../UG/Footer2";
import './PG_Mock_Tests_card_styles.css';
import { Link } from 'react-router-dom';
import './Pg_Mock_Tests_popup.css';


const PG_GATE_Mock_Tests_static_page = () => {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
// List of available routes
const availableRoutes = [
  '/StudentFormandMailGate_ME/1',
  '/StudentFormandMailGate_ME/2',
  '/StudentFormandMailGate_ME/3',
  '/StudentFormandMailGate_AE/1',
  '/StudentFormandMailGate_AE/2',
  '/StudentFormandMailGate_AE/3',
  '/StudentFormandMailGate_XE/1',
  '/StudentFormandMailGate_XE/2',
  '/StudentFormandMailGate_XE/3',
  '/StudentFormandMail_GATE_Physics/1',
  '/StudentFormandMail_GATE_Physics/2',
  '/StudentFormandMail_GATE_Physics/3',
  '/StudentFormandMailGate_MATHS/1',
  '/StudentFormandMailGate_MATHS/2',
  '/StudentFormandMailGate_MATHS/3',
  '/StudentFormandMailGate_DA/1',
  '/StudentFormandMailGate_DA/2',
  '/StudentFormandMailGate_DA/3',
  '/StudentFormandMailGate_MT/1',
  '/StudentFormandMailGate_MT/2',
  '/StudentFormandMailGate_MT/3',

];

const isRouteAvailable = (path) => {
  return availableRoutes.includes(path);
};

const handleClick = (path, event) => {
  event.preventDefault(); // Prevent the default link behavior

  if (isRouteAvailable(path)) {
    navigate(path); // Navigate to the route if available
  } else {
    setShowPopup(true); // Show the custom popup if route is not available
  }
};

const handleClosePopup = () => {
  setShowPopup(false); // Hide the popup
};


  return (
    <div>

{showPopup && (
        <div className="popup">
          <div className="popup-content">
            <span className="close-popup" onClick={handleClosePopup}>&times;</span>
            <p>This mock test will be available soon.</p>
          </div>
        </div>
      )}
      
 
<div>
        <div className="ugexam_header">
          {Nav.map((NavData, index) => {
            return (
              <div className="header ug_exam_header" key={index}>
                <div className={NavData.logo_img_container}>
                  <Link to={"/"}>
 
                    <img src={NavData.logo} alt="" />
                  </Link>
                </div>
 
 
                <div className="exam_login_menu  ">
                  <li>
                    <Link to='/PgHome' className={NavData.navlist} id='exam_login_menu_home'>
                      <IoMdHome /> {NavData.link1}
                    </Link>
                  </li>
                  {/* <a
                    href="https://online-ug.egradtutor.in"
                    target='_blank'
                    className={NavData.login}
                  >
                    {NavData.btn1}
                  </a> */}
 
                </div>
              </div>
 
            );
          })}
 
        </div>
      </div>
 



      <div>
 
        {/* GATE MOCK TESTS CONTAINER */}
 
        <div className='Mock_Tests_Heading'>
    <h2>GATE MOCK TESTS</h2>
 
    </div>
 
<div className="jeesection " id="PracticeMockTest">
 
   
        <div className="MockTests_sub_heading">
        <h3> MECHANICAL ENGINEERING</h3>
       
 
        </div>

      <div className="PracticeMockTest">
      <div className="PracticeMockTest_Card PMT_1">
        <span className="mockImg">
          <img src={onlinets1} alt="" />
        </span>
        <h2>ME MOCK TEST-1</h2>

        <div>
          <p>65 Questions 3 Hour 100 Marks</p>
        </div>
        <span className="PracticeMockTest_Container">
          <a href="/StudentFormandMailGate_ME/1" onClick={(e) => handleClick('/StudentFormandMailGate_ME/1', e)}>
            Take Test
          </a>
        </span>
      </div>

      <div className="PracticeMockTest_Card PMT_2">
        <span className="mockImg">
          <img src={onlinets1} alt="" />
        </span>
        <h2>ME MOCK TEST-2</h2>

        <div>
          <p>65 Questions 3 Hour 100 Marks</p>
        </div>
        <span className="PracticeMockTest_Container">
          <a href="/StudentFormandMailGate_ME/2" onClick={(e) => handleClick('/StudentFormandMailGate_ME/2', e)}>
            Take Test
          </a>
        </span>
      </div>

      <div className="PracticeMockTest_Card PMT_3">
        <span className="mockImg">
          <img src={onlinets1} alt="" />
        </span>
        <h2>ME MOCK TEST-3</h2>

        <div>
          <p>65 Questions 3 Hour 100 Marks</p>
        </div>
        <span className="PracticeMockTest_Container">
          <a href="/StudentFormandMailGate_ME/3" onClick={(e) => handleClick('/StudentFormandMailGate_ME/3', e)}>
            Take Test
          </a>
        </span>
      </div>
    </div>
     
      </div>
 
 
 
 
      {/* AE SUBJECT MOCK TESTS */}
 
      <div className="jeesection" id="PracticeMockTest">
      <div className="MockTests_sub_heading">
        <h3>AEROSPACE ENGINEERING</h3>
      </div>
      <div className="PracticeMockTest">
        <div className="PracticeMockTest_Card PMT_1">
          <span className="mockImg">
            <img src={onlinets} alt="" />
          </span>
          <h2>AE MOCK TEST-1</h2>
          <div>
            <p>65 Questions 3 Hour 100 Marks</p>
          </div>
          <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMailGate_AE/1" onClick={(e) => handleClick('/StudentFormandMailGate_AE/1', e)}>
              Take Test
            </a>
          </span>
        </div>

        <div className="PracticeMockTest_Card PMT_2">
          <span className="mockImg">
            <img src={onlinets} alt="" />
          </span>
          <h2>AE MOCK TEST-2</h2>
          <div>
            <p>65 Questions 3 Hour 100 Marks</p>
          </div>
          <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMailGate_AE/2" onClick={(e) => handleClick('/StudentFormandMailGate_AE/2', e)}>
              Take Test
            </a>
          </span>
        </div>

        <div className="PracticeMockTest_Card PMT_3">
          <span className="mockImg">
            <img src={onlinets} alt="" />
          </span>
          <h2>AE MOCK TEST-3</h2>
          <div>
            <p>65 Questions 3 Hour 100 Marks</p>
          </div>
          <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMailGate_AE/3" onClick={(e) => handleClick('/StudentFormandMailGate_AE/3', e)}>
              Take Test
            </a>
          </span>
        </div>
      </div>
    </div>
 
 
   {/* GATE XE SUBJECT MOCK TESTS */}
 
   <div className="jeesection" id="PracticeMockTest">
      <div className="MockTests_sub_heading">
        <h3>ENGINEERING SCIENCE</h3>
      </div>
      <div className="PracticeMockTest">
        <div className="PracticeMockTest_Card PMT_1">
          <span className="mockImg">
            <img src={onlinets1} alt="" />
          </span>
          <h2>GATE XE MOCK TEST-1</h2>
          <div>
            <p>65 Questions 3 Hour 100 Marks</p>
          </div>
          <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMailGate_XE/1" onClick={(e) => handleClick('/StudentFormandMailGate_XE/1', e)}>
              Take Test
            </a>
          </span>
        </div>

        <div className="PracticeMockTest_Card PMT_2">
          <span className="mockImg">
            <img src={onlinets1} alt="" />
          </span>
          <h2>GATE XE MOCK TEST-2</h2>
          <div>
            <p>65 Questions 3 Hour 100 Marks</p>
          </div>
          <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMailGate_XE/2" onClick={(e) => handleClick('/StudentFormandMailGate_XE/2', e)}>
              Take Test
            </a>
          </span>
        </div>

        <div className="PracticeMockTest_Card PMT_3">
          <span className="mockImg">
            <img src={onlinets1} alt="" />
          </span>
          <h2>GATE XE MOCK TEST-3</h2>
          <div>
            <p>65 Questions 3 Hour 100 Marks</p>
          </div>
          <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMailGate_XE3" onClick={(e) => handleClick('/StudentFormandMailGate_XE3', e)}>
              Take Test
            </a>
          </span>
        </div>
      </div>
    </div>
 
 
 
   {/* PHYSICS SUBJECT MOCK TESTS */}
 
   <div className="jeesection" id="PracticeMockTest">
      <div className="MockTests_sub_heading">
        <h3>PHYSICS</h3>
      </div>
      <div className="PracticeMockTest">
        <div className="PracticeMockTest_Card PMT_1">
          <span className="mockImg">
            <img src={onlinets} alt="" />
          </span>
          <h2>PHYSICS MOCK TEST-1</h2>
          <div>
            <p>65 Questions 3 Hour 100 Marks</p>
          </div>
          <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_GATE_Physics/1" onClick={(e) => handleClick('/StudentFormandMail_GATE_Physics/1', e)}>
              Take Test
            </a>
          </span>
        </div>

        <div className="PracticeMockTest_Card PMT_2">
          <span className="mockImg">
            <img src={onlinets} alt="" />
          </span>
          <h2>PHYSICS MOCK TEST-2</h2>
          <div>
            <p>65 Questions 3 Hour 100 Marks</p>
          </div>
          <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_GATE_Physics/2" onClick={(e) => handleClick('/StudentFormandMail_GATE_Physics/2', e)}>
              Take Test
            </a>
          </span>
        </div>

        <div className="PracticeMockTest_Card PMT_3">
          <span className="mockImg">
            <img src={onlinets} alt="" />
          </span>
          <h2>PHYSICS MOCK TEST-3</h2>
          <div>
            <p>65 Questions 3 Hour 100 Marks</p>
          </div>
          <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMail_GATE_Physics/3" onClick={(e) => handleClick('/StudentFormandMail_GATE_Physics/3', e)}>
              Take Test
            </a>
          </span>
        </div>
      </div>
    </div>
 
   {/* MATHEMATICS SUBJECT MOCK TESTS */}
 
   <div className="jeesection" id="PracticeMockTest">
      <div className="MockTests_sub_heading">
        <h3>MATHEMATICS</h3>
      </div>
      <div className="PracticeMockTest">
        <div className="PracticeMockTest_Card PMT_1">
          <span className="mockImg">
            <img src={onlinets1} alt="MATHEMATICS MOCK TEST 1" />
          </span>
          <h2>MATHEMATICS MOCK TEST-1</h2>
          <div>
            <p>65 Questions 3 Hour 100 Marks</p>
          </div>
          <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMailGate_MATHS/1" onClick={(e) => handleClick('/StudentFormandMailGate_MATHS/1', e)}>
              Take Test
            </a>
          </span>
        </div>

        <div className="PracticeMockTest_Card PMT_2">
          <span className="mockImg">
            <img src={onlinets1} alt="MATHEMATICS MOCK TEST 2" />
          </span>
          <h2>MATHEMATICS MOCK TEST-2</h2>
          <div>
            <p>65 Questions 3 Hour 100 Marks</p>
          </div>
          <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMailGate_MATHS/2" onClick={(e) => handleClick('/StudentFormandMailGate_MATHS/2', e)}>
              Take Test
            </a>
          </span>
        </div>

        <div className="PracticeMockTest_Card PMT_3">
          <span className="mockImg">
            <img src={onlinets1} alt="MATHEMATICS MOCK TEST 3" />
          </span>
          <h2>MATHEMATICS MOCK TEST-3</h2>
          <div>
            <p>65 Questions 3 Hour 100 Marks</p>
          </div>
          <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMailGate_MATHS/3" onClick={(e) => handleClick('/StudentFormandMailGate_MATHS/3', e)}>
              Take Test
            </a>
          </span>
        </div>
      </div>
    </div>
 
 
         {/* DA SUBJECT MOCK TESTS */}
 
         <div className="jeesection" id="PracticeMockTest">
      <div className="MockTests_sub_heading">
        <h3>DATA ANALYSIS</h3>
      </div>
      <div className="PracticeMockTest">
        <div className="PracticeMockTest_Card PMT_1">
          <span className="mockImg">
            <img src={onlinets} alt="DATA ANALYSIS MOCK TEST 1" />
          </span>
          <h2>DA MOCK TEST-1</h2>
          <div>
            <p>65 Questions 3 Hour 100 Marks</p>
          </div>
          <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMailGate_DA/1" onClick={(e) => handleClick('/StudentFormandMailGate_DA/1', e)}>
              Take Test
            </a>
          </span>
        </div>

        <div className="PracticeMockTest_Card PMT_2">
          <span className="mockImg">
            <img src={onlinets} alt="DATA ANALYSIS MOCK TEST 2" />
          </span>
          <h2>DA MOCK TEST-2</h2>
          <div>
            <p>65 Questions 3 Hour 100 Marks</p>
          </div>
          <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMailGate_DA/2" onClick={(e) => handleClick('/StudentFormandMailGate_DA/2', e)}>
              Take Test
            </a>
          </span>
        </div>

        <div className="PracticeMockTest_Card PMT_3">
          <span className="mockImg">
            <img src={onlinets} alt="DATA ANALYSIS MOCK TEST 3" />
          </span>
          <h2>DA MOCK TEST-3</h2>
          <div>
            <p>65 Questions 3 Hour 100 Marks</p>
          </div>
          <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMailGate_DA/3" onClick={(e) => handleClick('/StudentFormandMailGate_DA/3', e)}>
              Take Test
            </a>
          </span>
        </div>
      </div>
    </div>
 
              {/* MT SUBJECT MOCK TESTS */}
              <div className="jeesection" id="PracticeMockTest">
      <div className="MockTests_sub_heading">
        <h3>MATERIAL SCIENCE AND ENGINEERING</h3>
      </div>
      <div className="PracticeMockTest">
        <div className="PracticeMockTest_Card PMT_1">
          <span className="mockImg">
            <img src={onlinets1} alt="MATERIAL SCIENCE MOCK TEST 1" />
          </span>
          <h2>MT MOCK TEST-1</h2>
          <div>
            <p>65 Questions 3 Hour 100 Marks</p>
          </div>
          <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMailGate_MT/1" onClick={(e) => handleClick('/StudentFormandMailGate_MT/1', e)}>
              Take Test
            </a>
          </span>
        </div>

        <div className="PracticeMockTest_Card PMT_2">
          <span className="mockImg">
            <img src={onlinets1} alt="MATERIAL SCIENCE MOCK TEST 2" />
          </span>
          <h2>MT MOCK TEST-2</h2>
          <div>
            <p>65 Questions 3 Hour 100 Marks</p>
          </div>
          <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMailGate_MT/2" onClick={(e) => handleClick('/StudentFormandMailGate_MT/2', e)}>
              Take Test
            </a>
          </span>
        </div>

        <div className="PracticeMockTest_Card PMT_3">
          <span className="mockImg">
            <img src={onlinets1} alt="MATERIAL SCIENCE MOCK TEST 3" />
          </span>
          <h2>MT MOCK TEST-3</h2>
          <div>
            <p>65 Questions 3 Hour 100 Marks</p>
          </div>
          <span className="PracticeMockTest_Container">
            <a href="/StudentFormandMailGate_MT/3" onClick={(e) => handleClick('/StudentFormandMailGate_MT/3', e)}>
              Take Test
            </a>
          </span>
        </div>
      </div>
    </div>
 
      </div>
 
 
 
 
 
 
 
 
{/* just for card designing */}
 
 
<div>
 
{/* GATE MOCK TESTS CONTAINER */}
 
<div className='Mock_Tests_Heading'>
<h2>GATE PRACTICE MOCK TESTS</h2>
 
</div>
 
<div className="jeesection " id="PracticeMockTest">
 
 
<div className="MockTests_sub_heading">
<h3> MECHANICAL ENGINEERING</h3>
 
 
</div>
 
 
<div className="PracticeMockTest">
  <div className="PracticeMockTest_Card_container">
    <div className="mockImg_two">
      <img src={onlinets1} alt="" />
    </div>
 
    <div className='Mock_test_heading'>
    <h2>ME MOCK TEST-1</h2>
 
    </div>
 
    <div className='mock_test_discription_container'>
      <p>Questions : <span>65 Questions </span></p>
      <p>Time (mins) : <span>3 Hour</span></p>
      <p>Maximum Marks : <span>100 Marks</span></p>
    </div>
    <div className="PracticeMockTest_Container_two">
      <Link to="/StudentFormandMailGate_ME/1">Take Test</Link>
    </div>
  </div>
 
  <div className="PracticeMockTest_Card_container">
    <div className="mockImg_two">
      <img src={onlinets1} alt="" />
    </div>
 
    <div className='Mock_test_heading'>
    <h2>ME MOCK TEST-2</h2>
 
    </div>
 
    <div className='mock_test_discription_container'>
      <p>Questions : <span>65 Questions </span></p>
      <p>Time (mins) : <span>3 Hour</span></p>
      <p>Maximum Marks : <span>100 Marks</span></p>
    </div>
    <div className="PracticeMockTest_Container_two">
      <Link to="/StudentFormandMailGate_ME/2">Take Test</Link>
    </div>
  </div>
 
  <div className="PracticeMockTest_Card_container">
    <div className="mockImg_two">
      <img src={onlinets1} alt="" />
    </div>
 
    <div className='Mock_test_heading'>
    <h2>ME MOCK TEST-2</h2>
 
    </div>
 
    <div className='mock_test_discription_container'>
      <p>Questions : <span>65 Questions </span></p>
      <p>Time (mins) : <span>3 Hour</span></p>
      <p>Maximum Marks : <span>100 Marks</span></p>
    </div>
    <div className="PracticeMockTest_Container_two">
      <Link to="/StudentFormandMailGate_ME/3">Take Test</Link>
    </div>
  </div>
 
 
</div>
 
</div>
 
 
 
</div>
 
 
 
 
 
      <Footer />
 
    </div>
  )
}
 
export default PG_GATE_Mock_Tests_static_page
 