

// Downloads_file_JeeMain_table_data.js
const data = {


    // 2024 YEAR DATA IN DOWNLOADS PAGE FOR JEE MAIN EXAM
    "2024": [
        {
            "year": 2024,
            "months": [
                {
                    "month": "FEBRUARY",
                    "data": [
                        {
                            "shift": "27th Feb - Shift-1",
                            "ME_pdf": '',
                            "AE_pdf": '',
                            "XE_pdf": '',
                            "Physics_pdf": '',
                            "Maths_pdf": '',
                            "Data_Analysis_pdf": '',
                            "MT_pdf": '',
                            "items": [
                                { "sNo": 1, "ME_Link": "ME_1", "AE_Link": "AE Link", "XE_Link": "XE Link","Physics_Link": "Physics Link", "Maths_Link": "Maths Link","Data_Analysis_Link": "Data Analysis Link", "MT_Link": "MT Link", },

                                { "sNo": 2, "ME_Link": "ME_1", "AE_Link": "AE Link", "XE_Link": "XE Link","Physics_Link": "Physics Link", "Maths_Link": "Maths Link","Data_Analysis_Link": "Data Analysis Link", "MT_Link": "MT Link", },


                                { "sNo": 3, "ME_Link": "ME_1", "AE_Link": "AE Link", "XE_Link": "XE Link","Physics_Link": "Physics Link", "Maths_Link": "Maths Link","Data_Analysis_Link": "Data Analysis Link", "MT_Link": "MT Link", },

                                { "sNo": 4, "ME_Link": "ME_1", "AE_Link": "AE Link", "XE_Link": "XE Link","Physics_Link": "Physics Link", "Maths_Link": "Maths Link","Data_Analysis_Link": "Data Analysis Link", "MT_Link": "MT Link", },


                                { "sNo": 5, "ME_Link": "ME_1", "AE_Link": "AE Link", "XE_Link": "XE Link","Physics_Link": "Physics Link", "Maths_Link": "Maths Link","Data_Analysis_Link": "Data Analysis Link", "MT_Link": "MT Link", },
                                { "sNo": 6, "ME_Link": "ME_1", "AE_Link": "AE Link", "XE_Link": "XE Link","Physics_Link": "Physics Link", "Maths_Link": "Maths Link","Data_Analysis_Link": "Data Analysis Link", "MT_Link": "MT Link", },

                                { "sNo": 7, "ME_Link": "ME_1", "AE_Link": "AE Link", "XE_Link": "XE Link","Physics_Link": "Physics Link", "Maths_Link": "Maths Link","Data_Analysis_Link": "Data Analysis Link", "MT_Link": "MT Link", },

                                { "sNo": 8, "ME_Link": "ME_1", "AE_Link": "AE Link", "XE_Link": "XE Link","Physics_Link": "Physics Link", "Maths_Link": "Maths Link","Data_Analysis_Link": "Data Analysis Link", "MT_Link": "MT Link", },

                                { "sNo": 9, "ME_Link": "ME_1", "AE_Link": "AE Link", "XE_Link": "XE Link","Physics_Link": "Physics Link", "Maths_Link": "Maths Link","Data_Analysis_Link": "Data Analysis Link", "MT_Link": "MT Link", },

                                { "sNo": 10, "ME_Link": "ME_1", "AE_Link": "AE Link", "XE_Link": "XE Link","Physics_Link": "Physics Link", "Maths_Link": "Maths Link","Data_Analysis_Link": "Data Analysis Link", "MT_Link": "MT Link", },

                            ]
                        },

                        {
                            "shift": "27th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                // Add up to 30 items here
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "29th Feb - Shift-1",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                // Add up to 30 items here
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "29th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                // Add up to 30 items here
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "30th Feb - Shift-1",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                // Add up to 30 items here
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },


                        {
                            "shift": "30th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "31th Feb - Shift-1",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "31th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "1st Feb - Shift-1",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "1st Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },
                     
                    ]
                },
              
            ]
        }
    ],

    // 2023 YEAR DATA IN DOWNLOADS PAGE FOR JEE MAIN EXAM

    "2023": [
        {
            "year": 2023,
            "months": [
                {
                    "month": "FEBRUARY",
                    "data": [
                        {
                            "shift": "24th Feb - Shift-1",
                            "Maths_pdf": '',
                            "Physics_pdf": '',
                            "Chemistry_pdf": '',
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "24th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                // Add up to 30 items here
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "25th Feb - Shift-1",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                // Add up to 30 items here
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "25th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                // Add up to 30 items here
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "29th Feb - Shift-1",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                // Add up to 30 items here
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },


                        {
                            "shift": "29th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "30th Feb - Shift-1",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "30th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "31st Feb - Shift-1",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "31st Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        }
                    ]
                },
            
            ]
        }
    ],



    // 2022 YEAR DATA IN DOWNLOADS PAGE FOR JEE MAIN EXAM

    "2022": [
        {
            "year": 2022,
            "months": [
                {
                    "month": "FEBRUARY",
                    "data": [
                        {
                            "shift": "24th Feb - Shift-1",
                            "Maths_pdf": '',
                            "Physics_pdf": '',
                            "Chemistry_pdf": '',
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "24th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                // Add up to 30 items here
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "25th Feb - Shift-1",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                // Add up to 30 items here
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "25th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                // Add up to 30 items here
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "26th Feb - Shift-1",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                // Add up to 30 items here
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },


                        {
                            "shift": "26th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "27th Feb - Shift-1",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "27th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "28th Feb - Shift-1",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "28th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },
                        {
                            "shift": "29th Feb - Shift-1",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },
                        {
                            "shift": "29th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        }
                    ]
                },
            
            ]
        }
    ],
    // 2021 YEAR DATA IN DOWNLOADS PAGE FOR JEE MAIN EXAM


    "2021": [
        {
            "year": 2021,
            "months": [
                {
                    "month": "FEBRUARY",
                    "data": [
                        {
                            "shift": "24th Feb - Shift-1",
                            "Maths_pdf": '',
                            "Physics_pdf": '',
                            "Chemistry_pdf": '',
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "24th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                // Add up to 30 items here
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift":"25th Feb - Shift-1",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                // Add up to 30 items here
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "25th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                // Add up to 30 items here
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "26th Feb - Shift-1",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                // Add up to 30 items here
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },


                        {
                            "shift": "26th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "16th Feb - Shift-1",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "16th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "17th Feb - Shift-1",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "17th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },
                        {
                            "shift": "18th Feb - Shift-1",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },
                        {
                            "shift": "18th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        }
                    ]
                },
             
            ]
        }
    ],

    // 2020 YEAR DATA IN DOWNLOADS PAGE FOR JEE MAIN EXAM

    "2020": [
        {
            "year": 2020,
            "months": [
                {
                    "month": "FEBRUARY",
                    "data": [
                        {
                            "shift": "7th Feb - Shift-1",
                            "Maths_pdf": '',
                            "Physics_pdf": '',
                            "Chemistry_pdf":'',
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "7th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                // Add up to 30 items here
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "8th Feb - Shift-1",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                // Add up to 30 items here
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "8th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                // Add up to 30 items here
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },

                        {
                            "shift": "9th Feb - Shift-1",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

                                { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
                                // Add up to 30 items here
                                { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },


                        {
                            "shift": "9th Feb - Shift-2",
                            "items": [
                                { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
                                // Add up to 30 items here
                                { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
                            ]
                        },
                   
                    ]
                },
            
            ]
        }
    ],
    // 2019 YEAR DATA IN DOWNLOADS PAGE FOR JEE MAIN EXAM

    // "2019": [
    //     {
    //         "year": 2019,
    //         "months": [
    //             {
    //                 "month": "JANUARY",
    //                 "data": [
    //                     {
    //                         "shift": "9th Jan - Shift-1",
    //                         "Maths_pdf": '',
    //                         "Physics_pdf": '',
    //                         "Chemistry_pdf":'',
    //                         "items": [
    //                             { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

    //                             { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

    //                             { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

    //                             { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" },
    //                             { "sNo": 11, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" },
    //                             { "sNo": 12, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" },
    //                             { "sNo": 13, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" },
    //                             { "sNo": 14, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" },
    //                             { "sNo": 15, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" },
    //                             { "sNo": 16, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" },
    //                             { "sNo": 17, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" },
    //                             { "sNo": 18, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" },
    //                             { "sNo": 19, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" },
    //                             { "sNo": 20, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" },
    //                             { "sNo": 21, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" },
    //                             { "sNo": 22, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" },
    //                             { "sNo": 23, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" },
    //                             { "sNo": 24, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" },
    //                             { "sNo": 25, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" },
    //                             { "sNo": 26, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" },
    //                             { "sNo": 27, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" },
    //                             { "sNo": 28, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" },
    //                             { "sNo": 29, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" },
    //                             { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" },

    //                         ]
    //                     },

    //                     {
    //                         "shift": "9th Jan - Shift-2",
    //                         "items": [
    //                             { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
    //                             { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

    //                             { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

    //                             { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

    //                             { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             // Add up to 30 items here
    //                             { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
    //                         ]
    //                     },

    //                     {
    //                         "shift": "10th Jan - Shift-1",
    //                         "items": [
    //                             { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
    //                             { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

    //                             { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

    //                             { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

    //                             { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             // Add up to 30 items here
    //                             { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
    //                         ]
    //                     },

    //                     {
    //                         "shift": "10th Jan - Shift-2",
    //                         "items": [
    //                             { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
    //                             { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

    //                             { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

    //                             { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

    //                             { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             // Add up to 30 items here
    //                             { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
    //                         ]
    //                     },

    //                     {
    //                         "shift": "11th Jan - Shift-1",
    //                         "items": [
    //                             { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
    //                             { "sNo": 2, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

    //                             { "sNo": 3, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

    //                             { "sNo": 4, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },

    //                             { "sNo": 5, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             { "sNo": 6, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             { "sNo": 7, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             { "sNo": 8, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             { "sNo": 9, "MathsLink": "MATHS_LINK_2", "PhysicsLink": "PHYSICS_LINK_2", "ChemistryLink": "CHEMISTRY_LINK_2" },
    //                             // Add up to 30 items here
    //                             { "sNo": 10, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
    //                         ]
    //                     },


    //                     {
    //                         "shift": "11th Jan - Shift-2",
    //                         "items": [
    //                             { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
    //                             // Add up to 30 items here
    //                             { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
    //                         ]
    //                     },

    //                     {
    //                         "shift": "12th Jan - Shift-1",
    //                         "items": [
    //                             { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
    //                             // Add up to 30 items here
    //                             { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
    //                         ]
    //                     },

    //                     {
    //                         "shift": "12th Jan - Shift-2",
    //                         "items": [
    //                             { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },
    //                             // Add up to 30 items here
    //                             { "sNo": 30, "MathsLink": "MATHS_LINK_30", "PhysicsLink": "PHYSICS_LINK_30", "ChemistryLink": "CHEMISTRY_LINK_30" }
    //                         ]
    //                     },

                    
    //                 ]
    //             },
    //             {
    //                 "month": "APRIL",
    //                 "data": [
    //                     {
    //                         "shift": "8th Apr - Shift-1",
    //                         "items": [
    //                             { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 2, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 3, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 4, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 5, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 6, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 7, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 8, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 9, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 10, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                         ]
    //                     },
    //                     // Repeat for Shift 2 to Shift 10
    //                     {
    //                         "shift": "8th Apr - Shift-2",
    //                         "items": [
    //                             { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 2, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 3, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 4, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 5, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 6, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 7, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 8, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 9, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 10, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                         ]
    //                     },

    //                     {
    //                         "shift": "9th Apr - Shift-1",
    //                         "items": [
    //                             { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 2, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 3, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 4, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 5, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 6, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 7, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 8, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 9, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 10, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                         ]
    //                     },

    //                     {
    //                         "shift": "9th Apr - Shift-2",
    //                         "items": [
    //                             { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 2, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 3, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 4, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 5, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 6, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 7, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 8, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 9, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 10, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                         ]
    //                     },

    //                     {
    //                         "shift":"10th Apr - Shift-1",
    //                         "items": [
    //                             { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 2, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 3, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 4, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 5, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 6, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 7, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 8, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 9, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 10, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                         ]
    //                     },

    //                     {
    //                         "shift": "10th Apr - Shift-2",
    //                         "items": [
    //                             { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 2, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 3, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 4, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 5, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 6, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 7, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 8, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 9, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 10, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                         ]
    //                     },

    //                     {
    //                         "shift":"12th Apr - Shift-1",
    //                         "items": [
    //                             { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 2, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 3, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 4, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 5, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 6, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 7, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 8, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 9, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 10, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                         ]
    //                     },

    //                     {
    //                         "shift":"12th Apr - Shift-2",
    //                         "items": [
    //                             { "sNo": 1, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 2, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 3, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 4, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 5, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 6, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 7, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 8, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 9, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                             { "sNo": 10, "MathsLink": "MATHS_LINK_1", "PhysicsLink": "PHYSICS_LINK_1", "ChemistryLink": "CHEMISTRY_LINK_1" },

    //                         ]
    //                     },
                     

    //                     // Repeat up to 10 shifts
    //                 ]
    //             }
    //         ]
    //     }
    // ],
};

export default data;
