import React, { useState } from 'react';
import '../SubmitFormAndMail.css';
import logo from '../../egate logo 1.png';
import { useNavigate, useParams } from 'react-router-dom';
const SubmitFormAndMailNEET = () => {
  const navigate = useNavigate();
  const { mockId } = useParams();
  const [showAlert, setShowAlert] = useState(false);
  const [formData, setFormData] = useState({
    studentName: '',
    contactNumber: '',
    email: '',
    // address: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'studentName') {
      const filteredValue = value.replace(/[^a-zA-Z\s/.]/g, ''); // Only letters and spaces
      setFormData({ ...formData, [name]: filteredValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a FormData object from the form data
    const data = new FormData(e.target);

    fetch('https://formspree.io/f/xleqkgde', {
        method: 'POST',
        body: data,
        headers: {
            'Accept': 'application/json'
        }
    })
    .then(response => {
        if (response.ok) {
            setShowAlert(true);

            // Reset form
            setFormData({
                studentName: '',
                contactNumber: '',
                email: '',
                // address: ''
            });

            // Close alert after 3 seconds
            setTimeout(() => {
              setShowAlert(false);
              setTimeout(() => {
                  window.location.href = '/neetotc'; 
              }, 500);
          }, 3000);

            const screenWidth = window.screen.width;
            const screenHeight = window.screen.height;

            // Open new window to InstructionPage
            const newWinRef = window.open(`/NEETInstructionPage/${mockId}`, 'InstructionPage', `width=${screenWidth},height=${screenHeight},fullscreen=yes`);

            if (!newWinRef) {
                alert('Please allow popups for this website');
            }
        } else {
            alert('There was an error submitting the form.');
        }
    })
    .catch(error => {
        alert('There was an error submitting the form.');
        console.error('Form submission error:', error);
    });
};
  


  return (
    <div className="SubmitFormAndMail_container">
      <div className="SubmitFormAndMail_header">
        <img src={logo} alt="Logo" className="SubmitFormAndMail_logo" />
        <h2>NEET MOCK TEST</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className='SubmitFormAndMail_label'>
            Student Name:
            <input className='SubmitFormAndMail_input'
              type="text"
              placeholder='Enter Name'
              name="studentName"
              value={formData.studentName}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div className="form-group">
          <label className='SubmitFormAndMail_label'>
            Contact Number:
            <input className='SubmitFormAndMail_input'
              type="number"
              placeholder='Enter Mobile No'
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div className="form-group">
          <label className='SubmitFormAndMail_label'>
            Email:
            <input className='SubmitFormAndMail_input'
              type="email"
              name="email"
              placeholder='Enter E-Mail'
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        {/* <div className="form-group">
          <label className='SubmitFormAndMail_label'>
            Address:
            <input className='SubmitFormAndMail_input'
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </label>
        </div> */}
        <div className="form-group">
        <button type="submit" className='SubmitFormAndMail_button'>Submit</button>
       
          </div> 
      </form>
    </div>
  );
};

export default SubmitFormAndMailNEET;
